import type { BaseStoreTypeWithHistory } from '@/store/modules/datacenter';

export interface HistoryState {
  show: boolean;
  item?: DisplayField | null;
  modelName?: ModelName;
  requestSettings?: HistoryRequestSettings;
}

export interface HistoryRequestSettings {
  selectedId: string;
  storeType: BaseStoreTypeWithHistory;
}

const history = reactive<HistoryState>({
  show: false,
  item: undefined,
  modelName: undefined,
  requestSettings: undefined,
});

export function useFieldHistory(): HistoryState {
  // @ts-expect-error - Type instantiation is excessively deep...
  return history;
}
