import {
  areasPopulateConfig,
  bookingPopulateConfig,
  internalEmailPopulateConfig,
  ongoingTripPopulateConfig,
  stationsPopulateConfig,
  openIssuesPopulateConfig,
  fleetProductPopulateConfig,
} from '@/lib/helpers/populate';
import StoreModule from '@/store/core/store-module';
import { vehicleFields } from '@/models/fields';
import store from '@/store';

import type { ApiSchema } from '#/core-api';
import type {
  PopulatedField,
  PopulatedInjectedField,
  PopulatedFieldArray,
} from '@/store/core/populate';
import type { Get, Post, Patch } from '@/store/types/core-api';

declare global {
  /**
   * Populated Vehicle
   */
  type Vehicle = DeepMerge<
    ApiSchema['cross_models.EntityVehicle'],
    {
      id: string;
      area?: PopulatedField<
        ApiSchema['entity.Vehicle']['area_id'],
        ApiSchema['area.ServiceableArea']['label']
      >;
      soft_unlock_timeout?: PopulatedField<
        ApiSchema['entity.Vehicle']['area_id'],
        ApiSchema['area.ServiceableArea']['soft_unlock_timeout']
      >;
      ongoing_trip?: PopulatedField<
        ApiSchema['entity.Vehicle']['id'],
        ApiSchema['rental.Trip']['id']
      >;
      ongoing_trip_started_at?: PopulatedField<
        ApiSchema['entity.Vehicle']['id'],
        ApiSchema['rental.Trip']['started_at']
      >;
      current_booking?: PopulatedField<
        ApiSchema['entity.Vehicle']['id'],
        ApiSchema['rental.Booking']['id']
      >;
      booked_by?: PopulatedField<
        ApiSchema['entity.Vehicle']['id'],
        ApiSchema['rental.Booking']['user_id']
      >;
      used_by?: PopulatedField<
        ApiSchema['entity.Vehicle']['id'],
        ApiSchema['rental.Trip']['user_id']
      >;
      extra_properties?: {
        last_maintenance_state_updater?: PopulatedInjectedField<
          NonNullable<
            ApiSchema['entity.Vehicle']['extra_properties']
          >['last_maintenance_state_updater'],
          ApiSchema['user.User']['email']
        >;
      };
      lost_info?: {
        search_history?: (Omit<ApiSchema['entity.SearchHistory'], 'user_id'> & {
          user_id?: PopulatedInjectedField<
            ApiSchema['entity.SearchHistory']['user_id'],
            { user: ApiSchema['user.User']['email'] }
          >;
        })[];
      };
      open_issues?: PopulatedFieldArray<
        ApiSchema['entity.Vehicle']['id'],
        Pick<ApiSchema['issue.Issue'], 'id' | 'label' | 'severity'>
      >;
    }
  >;
}

const vehicleScopes = ['Vehicles', 'Map', 'Dashboard'] as const;

export interface VehicleStoreTypes {
  VEHICLES: [
    Get<'/entities/vehicles/populate', Vehicle[], typeof vehicleScopes>,
    Patch<'/entities/vehicles'>,
  ];
  VEHICLES_COUNT: [
    Get<'/entities/vehicles/populate/count', void, typeof vehicleScopes>,
  ];
  VEHICLE: [
    Get<'/entities/vehicles/{Id}', Vehicle>,
    Patch<'/entities/vehicles/{Id}'>,
  ];
  VEHICLE_LOST_STATUS: [Post<'/entities/vehicles/{Id}/set_lost_status'>];
  VEHICLE_MAINTENANCE_STATE: [
    Post<'/entities/vehicles/{Id}/action/set_maintenance_state'>,
  ];
  VEHICLE_SYNC: [Post<'/entities/vehicles/{Id}/action/sync'>];
  VEHICLE_LOCK: [Post<'/entities/vehicles/{Id}/action/lock'>];
  VEHICLE_UNLOCK: [Post<'/entities/vehicles/{Id}/action/unlock'>];
  VEHICLE_PLAY: [Post<'/entities/vehicles/{Id}/action/play'>];
  VEHICLE_UNLOCK_BATTERY: [
    Post<'/entities/vehicles/{Id}/action/unlock_battery'>,
  ];
  VEHICLE_UPDATE_FIRMWARE: [
    Post<'/entities/vehicles/{Id}/action/update_firmware'>,
  ];
  VEHICLE_SET_ASSISTANCE: [Post<'/entities/vehicles/{Id}/action/set_assist'>];
  VEHICLE_DEPLOY: [Post<'/entities/vehicles/{Id}/action/deploy'>];
  VEHICLE_UPLOAD_FILES: [Post<'/entities/vehicles/{Id}/action/upload_files'>];
}

// Get the 'zoov01' product version id to eliminate them from the query
function filterOutZoov01Query(): MongoQuery {
  const productVersions = store.getters['FLEET_PRODUCT_VERSIONS'];
  const zoov01ProductVersion = (productVersions ?? []).find(
    ({ name }) => name === 'zoov01'
  );

  if (zoov01ProductVersion?.id) {
    return {
      query: {
        product_version_id: { $ne: zoov01ProductVersion?.id },
      },
    };
  }

  return {};
}

const vehiclesModule = new StoreModule<VehicleStoreTypes>();
vehiclesModule
  /*
   * Vehicles
   */
  .createResourceAndAction({
    type: 'VEHICLES',
    requestEndPoint: '/entities/vehicles/populate',
    scopes: vehicleScopes,
    withGlobalUsageQuery: true,
    requestOptions: () => {
      return {
        freeze: true,
        params: {
          fields: vehicleFields,
          ...filterOutZoov01Query(),
        },
      };
    },
    populate: [
      areasPopulateConfig({
        withWarehouse: true,
        withSoftUnlockTimeout: true,
      }),
      stationsPopulateConfig(),
      ongoingTripPopulateConfig({
        dataField: 'id',
      }),
      openIssuesPopulateConfig({
        dataField: 'id',
      }),
    ],
    transform: data => data.vehicles,
  })
  .createResourceAndAction({
    type: 'VEHICLES_COUNT',
    requestEndPoint: '/entities/vehicles/populate/count',
    scopes: vehicleScopes,
    withGlobalUsageQuery: true,
    requestOptions: () => {
      return {
        params: {
          ...filterOutZoov01Query(),
        },
      };
    },
  })
  .createResourceAndAction({
    type: 'VEHICLE',
    requestEndPoint: '/entities/vehicles/:id',
    transform: data => data.vehicle,
    populate: [
      areasPopulateConfig({
        withWarehouse: true,
        withSoftUnlockTimeout: true,
      }),
      stationsPopulateConfig(),
      ongoingTripPopulateConfig({
        dataField: 'id',
      }),
      bookingPopulateConfig({
        dataField: 'id',
      }),
      internalEmailPopulateConfig({
        dataField: 'user_id',
        onCollectionNames: ['lost_info.search_history'],
        asKey: 'user',
        inject: true,
        name: 'bike_search_history',
      }),
      fleetProductPopulateConfig({
        dataField: 'id',
      }),
    ],
  })
  .createAction({
    type: 'VEHICLE',
    requestEndPoint: '/entities/vehicles/:id',
    requestMethod: 'PATCH',
  })
  .createAction({
    type: 'VEHICLES',
    requestEndPoint: '/entities/vehicles',
    requestMethod: 'PATCH',
  })
  .createAction({
    type: 'VEHICLE_LOST_STATUS',
    requestEndPoint: '/entities/vehicles/:id/set_lost_status',
    requestMethod: 'POST',
  })
  .createAction({
    type: 'VEHICLE_MAINTENANCE_STATE',
    requestEndPoint: '/entities/vehicles/:id/action/set_maintenance_state',
    requestMethod: 'POST',
  })
  .createAction({
    type: 'VEHICLE_SYNC',
    requestEndPoint: '/entities/vehicles/:id/action/sync',
    requestMethod: 'POST',
    longRequest: true,
  })
  .createAction({
    type: 'VEHICLE_LOCK',
    requestEndPoint: '/entities/vehicles/:id/action/lock',
    requestMethod: 'POST',
    longRequest: true,
  })
  .createAction({
    type: 'VEHICLE_UNLOCK',
    requestEndPoint: '/entities/vehicles/:id/action/unlock',
    requestMethod: 'POST',
    longRequest: true,
  })
  .createAction({
    type: 'VEHICLE_PLAY',
    requestEndPoint: '/entities/vehicles/:id/action/play',
    requestMethod: 'POST',
    longRequest: true,
  })
  .createAction({
    type: 'VEHICLE_UNLOCK_BATTERY',
    requestEndPoint: '/entities/vehicles/:id/action/unlock_battery',
    requestMethod: 'POST',
    longRequest: true,
  })
  .createAction({
    type: 'VEHICLE_UPDATE_FIRMWARE',
    requestEndPoint: '/entities/vehicles/:id/action/update_firmware',
    requestMethod: 'POST',
    longRequest: true,
  })
  .createAction({
    type: 'VEHICLE_SET_ASSISTANCE',
    requestEndPoint: '/entities/vehicles/:id/action/set_assist',
    requestMethod: 'POST',
    longRequest: true,
  })
  .createAction({
    type: 'VEHICLE_DEPLOY',
    requestEndPoint: '/entities/vehicles/:id/action/deploy',
    requestMethod: 'POST',
    longRequest: true,
  })
  .createAction({
    type: 'VEHICLE_UPLOAD_FILES',
    requestEndPoint: '/entities/vehicles/:id/action/upload_files',
    requestMethod: 'POST',
    longRequest: true,
  });

export default vehiclesModule;
