import {
  FastenerMaintenanceState,
  VehicleMaintenanceState,
  TicketTarget,
} from '#/core-api/enums';

import type { StationMaintenanceState } from '#/core-api/enums';
import type { RouteName } from '@/router/routes';

/**
 * Get the vue-router route name based on the target model
 * @param targetModel - the issues’ target model
 */
export function issueTargetModelRouteName(
  targetModel: TicketTarget
): RouteName {
  switch (targetModel) {
    case TicketTarget.Vehicle:
      return 'Vehicle';
    case TicketTarget.Parking:
      return 'Fastener';
    case TicketTarget.Station:
      return 'Station';
    case TicketTarget.User:
      return 'User';
    case TicketTarget.Ticket:
      return 'Issue';
    case TicketTarget.Trip:
      return 'Trip';
    case TicketTarget.Rental:
      return 'Rental';
    case TicketTarget.Benefit:
      return 'Benefit';
    case TicketTarget.Offer:
      return 'Offer';
    case TicketTarget.Area:
      return 'Area';
    default:
      const _: TicketTarget.NotSet | undefined = targetModel;
      return '4xx Error';
  }
}

/**
 * Get the target name based on the target enum
 * @param targetModel - the issues’ target model
 */
export function getIssueTargetModelName(
  targetModel?: TicketTarget
): string | null {
  switch (targetModel) {
    default:
    case TicketTarget.NotSet:
      return null;
    case TicketTarget.Vehicle:
      return 'vehicle';
    case TicketTarget.Parking:
      return 'fastener';
    case TicketTarget.Station:
      return 'station';
    case TicketTarget.User:
      return 'user';
    case TicketTarget.Trip:
      return 'trip';
    case TicketTarget.Rental:
      return 'rental';
    case TicketTarget.Benefit:
      return 'benefit';
    case TicketTarget.Offer:
      return 'offer';
    case TicketTarget.Area:
      return 'area';
  }
}

/**
 * Check whether the given target can have issues based on its maintenance state
 * @param targetModel - The issues’ target
 * @param maintenanceState - The target’s maintenance state
 */
export function canHaveOpenIssue<TargetModel extends TicketTarget>(
  targetModel: TargetModel,
  maintenanceState?: TargetModel extends TicketTarget.Vehicle
    ? VehicleMaintenanceState
    : FastenerMaintenanceState | StationMaintenanceState
): boolean {
  if (!maintenanceState) return false;
  switch (targetModel) {
    default:
    case TicketTarget.Vehicle:
      return (
        maintenanceState !== VehicleMaintenanceState.MaintenanceStateInOrder &&
        maintenanceState !==
          VehicleMaintenanceState.MaintenanceStateInTruckForReallocation &&
        maintenanceState !==
          VehicleMaintenanceState.MaintenanceStateInWarehouseExit &&
        maintenanceState !== VehicleMaintenanceState.MaintenanceStateToSetup
      );
    case TicketTarget.Parking:
    case TicketTarget.Station:
      return (
        maintenanceState !== FastenerMaintenanceState.NotSet &&
        maintenanceState !== FastenerMaintenanceState.InField
      );
  }
}

export const vehicleIssueReopenMaintenanceStateList = [
  VehicleMaintenanceState.MaintenanceStateInOrderNeedsMaintenance,
  VehicleMaintenanceState.MaintenanceStateOutOfOrderNeedsMaintenance,
  VehicleMaintenanceState.MaintenanceStateOutOfOrderNeedsWarehouse,
  VehicleMaintenanceState.MaintenanceStateInTruckToWarehouse,
  VehicleMaintenanceState.MaintenanceStateInWarehouseEntry,
  VehicleMaintenanceState.MaintenanceStateInWarehouse_1,
  VehicleMaintenanceState.MaintenanceStateInWarehouse_2,
  VehicleMaintenanceState.MaintenanceStateInWarehouse_3,
  VehicleMaintenanceState.MaintenanceStateInWarehouse_4,
  VehicleMaintenanceState.MaintenanceStateInWarehousePreExit,
  VehicleMaintenanceState.MaintenanceStateToRecycle,
  VehicleMaintenanceState.MaintenanceStateRecycled,
  VehicleMaintenanceState.MaintenanceStateToDeploy,
];

export const vehicleIssueCloseMaintenanceStateList = [
  VehicleMaintenanceState.MaintenanceStateInOrder,
  VehicleMaintenanceState.MaintenanceStateInTruckForReallocation,
  VehicleMaintenanceState.MaintenanceStateInWarehousePreExit,
  VehicleMaintenanceState.MaintenanceStateInWarehouseExit,
  VehicleMaintenanceState.MaintenanceStateRecycled,
  VehicleMaintenanceState.MaintenanceStateToSetup,
  VehicleMaintenanceState.MaintenanceStateToDeploy,
];

export const fastenerIssueReopenMaintenanceStateList = [
  FastenerMaintenanceState.NeedMaintenanceInOrder,
  FastenerMaintenanceState.NeedMaintenanceOutOfOrder,
  FastenerMaintenanceState.UnderMaintenance,
];

export const fastenerIssueCloseMaintenanceStateList = [
  FastenerMaintenanceState.InField,
];

export type ChangeIssueStatusBaseActionData = {
  maintenance_state?:
    | VehicleMaintenanceState
    | FastenerMaintenanceState
    | StationMaintenanceState;
};

/**
 * Goes through all i18n locales of the object and uppercases their content
 */
export function uppercaseI18nKeys(
  i18nObject?: Record<string, string>
): Record<string, string> | undefined {
  if (!i18nObject) return;

  Object.entries(i18nObject).forEach(
    ([key, value]) => (i18nObject[key] = value ? value.toUpperCase() : '')
  );

  return i18nObject;
}
