<template lang="pug">
ZInputDatePicker.ZActionControlInputDatePicker(
  v-show="canShow"
  ref="componentRef"
  v-model="modelValue"
  :style="style"
  :label="label"
  :rules="rules"
  :color="color"
  :disabled="isDisabled"
  :hint="hint"
  :placeholder="placeholder"
  :mask="mask"
  :messages="message"
  :hide-details="areDetailsHidden"
  :min-width="width"
  :max-width="width"
  :append-icon="icon || 'mdi-calendar'"
  :menu-props="{ lazy: true, offsetY: true, fullWidth: true }"
  :text-field-props="{ readonly: true }"
  :date-picker-props="{ showWeek: true, fullWidth: true, noTitle: true }"
  :allowed-dates="allowedDates"
  :max-date="maxDate"
  :min-date="minDate"
  :birthdate="!isDateTime"
  :time-picker="isDateTime"
  v-bind="additionalProps"
)
</template>

<script setup lang="ts">
import { useActionControl } from './useActionControl';

import ZInputDatePicker from '@/components/ui/molecules/ZInputDatePicker.vue';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Data = any;

interface ZActionControlInputDatePickerProps {
  /**
   * The control object
   */
  control: ActionControl<'date', keyof Data, Data>;
  /**
   * The parent action 'data' object
   */
  data: Data;
  /**
   * The control’s value
   * @model
   */
  value?: string;
}

const props = withDefaults(defineProps<ZActionControlInputDatePickerProps>(), {
  value: undefined,
});

const {
  modelValue,
  generate,
  canShow,
  style,
  label,
  rules,
  color,
  icon,
  isDisabled,
  hint,
  placeholder,
  mask,
  message,
  areDetailsHidden,
  additionalProps,
} = useActionControl(props);

const componentRef = ref<TemplateRef | null>(null);

const { width: windowWidth } = useWindowSize();
const breakDateMenu = computed(() => windowWidth.value < 432);
const width = computed(() => {
  return breakDateMenu.value ? 'calc(100vw - 56px)' : '368px';
});

const maxDate = computed(() => generate(props.control.maxDate));
const minDate = computed(() => generate(props.control.minDate));
const isDateTime = computed(() => props.control.dateTime);

/**
 * Compute allowed dates based on control settings
 * @param date - The date to check
 */
function allowedDates(date: string): boolean {
  const todayStringDate = new Date().toISOString().substring(0, 10);
  return (
    (props.control.onlyFutureDates ? date >= todayStringDate : true) &&
    (props.control.allowedDates?.(date, props.data) ?? true)
  );
}

function focus(): void {
  componentRef.value?.focus?.();
}

function resetValidation(): void {
  componentRef.value?.resetValidation?.();
}

defineExpose({
  /**
   * Focus the input
   */
  focus,
  /**
   * Reset input validation
   */
  resetValidation,
});
</script>
