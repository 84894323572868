import { version } from '@@/package.json';

import { useServiceWorker } from '@/composables';

export interface UseServiceWorkerPromptReturn {
  /**
   * Show whether a new update is available
   */
  showUpdateAvailable: Ref<boolean>;
  /**
   * Whether the update should be open
   */
  showUpdatePrompt: Ref<boolean>;
  /**
   * Whether the service worker is updating
   */
  isUpdatingServiceWorker: Ref<boolean>;
  /**
   * Whether the service worker is successfull
   */
  isUpdateSuccess: Ref<boolean>;
  /**
   * Trigger the service worker update
   */
  updateServiceWorker: () => void;
  /**
   * Toggle the update prompt
   */
  toggleUpdatePrompt: () => void;
  /*
   * New version available
   */
  newVersionAvailable: Ref<string>;
}

const showUpdatePrompt = ref(false);
const isUpdatingServiceWorker = ref(false);
const isUpdateSuccess = ref(false);
const showUpdateAvailable = ref(false);

export function useServiceWorkerPrompt(): UseServiceWorkerPromptReturn {
  const { isUpdateAvailable, registration, newVersionAvailable } =
    useServiceWorker();

  function updateServiceWorker(): void {
    if (registration.value?.waiting) {
      registration.value.waiting?.postMessage('skipWaiting');
    }
  }
  function toggleUpdatePrompt(): void {
    showUpdatePrompt.value = !showUpdatePrompt.value;
  }

  watchImmediate(isUpdateAvailable, _isUpdateAvailable => {
    if (!_isUpdateAvailable) return;

    // show upgrade dialog
    showUpdatePrompt.value = true;
    showUpdateAvailable.value = true;

    // listen to controller change to reload if webapp version is outdated
    isUpdatingServiceWorker.value = false;

    navigator.serviceWorker.addEventListener('controllerchange', () => {
      // if version of the webapp is already up to date, we only needed to
      // activate the service worker but do not need to reload the page
      if (newVersionAvailable.value === version) {
        isUpdatingServiceWorker.value = false;
        isUpdateSuccess.value = true;
        showUpdateAvailable.value = false;

        setTimeout(() => {
          showUpdatePrompt.value = false;

          setTimeout(() => {
            isUpdateSuccess.value = false;
          }, 500);
        }, 1000);
        return;
      }

      if (isUpdatingServiceWorker.value) return;

      isUpdatingServiceWorker.value = true;
      window.location.reload();
    });
  });

  return {
    showUpdateAvailable,
    showUpdatePrompt,
    isUpdatingServiceWorker,
    updateServiceWorker,
    toggleUpdatePrompt,
    isUpdateSuccess,
    newVersionAvailable,
  };
}
