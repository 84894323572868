/* eslint-disable @typescript-eslint/no-explicit-any */
import { distance } from '@turf/distance';

import {
  BikeMaintenanceState,
  BikeStatus,
  LockedStatus,
  LostInfoStatus,
  PowerSavingMode,
} from '#/core-api/enums';

import store from '@/store';
import { appConfig } from '@/config';
import { delayFromNow } from '@/lib/utils';
import {
  bikeLostStatus,
  bikePowerSavingMode,
} from '@/models/bike/mappers/display/bike';
import { lteSignalBadge } from '@/lib/helpers/lte';
import { getBatteryIcon, getBatteryColor } from '@/models/battery/helpers';
import { defineAction, defineControl } from '@/types/actions';

import type { ApiSchema } from '#/core-api';
import type { UseI18nReturn } from '@/composables/plugins';

const WAREHOUSE_RADIUS = 200; // meters

/**
 * Check whether a bike is in a warehouse based on its maintenance state
 * @param maintenanceState - The bike’s maintenance state
 */
export function isInWarehouse(maintenanceState: BikeMaintenanceState): boolean {
  return (
    maintenanceState === BikeMaintenanceState.InWarehouseLvl1 ||
    maintenanceState === BikeMaintenanceState.InWarehouseLvl2 ||
    maintenanceState === BikeMaintenanceState.InWarehouseExitZone ||
    maintenanceState === BikeMaintenanceState.InWarehouseLvl1Plus ||
    maintenanceState === BikeMaintenanceState.InWarehouseLvl3 ||
    maintenanceState === BikeMaintenanceState.EntryZone ||
    maintenanceState === BikeMaintenanceState.Recycled ||
    maintenanceState === BikeMaintenanceState.ToRecycle ||
    maintenanceState === BikeMaintenanceState.PreExitZone
  );
}

/**
 * Check whether a bike is located in a warehouse based on their coordinates
 * @param bikeCoordinates - The bike’s coordinates
 * @param warehouseCoordinates - The warehouse’s coordinates
 */
export function isLocalizedInWarehouse(
  bikeCoordinates?: Coordinates | number[],
  warehouseCoordinates?: Coordinates | number[]
): boolean {
  // Compute distance between bike and warehouse
  const distanceToWarehouse =
    warehouseCoordinates && bikeCoordinates
      ? distance(warehouseCoordinates, bikeCoordinates)
      : Infinity;

  return distanceToWarehouse <= WAREHOUSE_RADIUS / 1000;
}

/**
 * Check whether a bike is in a truck based on its maintenance state
 * @param maintenanceState - The bike’s maintenance state
 */
export function isInTruck(maintenanceState: BikeMaintenanceState): boolean {
  return (
    maintenanceState === BikeMaintenanceState.InTruckForReallocation ||
    maintenanceState === BikeMaintenanceState.InTruckToWarehouse
  );
}

function createComparator(order: any) {
  return function compare(x: any, y: any) {
    if (order.indexOf(x) < order.indexOf(y)) return -1;
    else if (order.indexOf(x) > order.indexOf(y)) return 1;
    else return 0;
  };
}

const order = [
  BikeMaintenanceState.NotSet,
  BikeMaintenanceState.Recycled,
  BikeMaintenanceState.ToRecycle,
  BikeMaintenanceState.InWarehouseLvl1,
  BikeMaintenanceState.InWarehouseLvl1Plus,
  BikeMaintenanceState.InWarehouseLvl2,
  BikeMaintenanceState.InWarehouseLvl3,
  BikeMaintenanceState.EntryZone,
  BikeMaintenanceState.InWarehouseExitZone,
  BikeMaintenanceState.InTruckToWarehouse,
  BikeMaintenanceState.InTruckForReallocation,
  BikeMaintenanceState.InField,
  BikeMaintenanceState.NeedWarehouse,
  BikeMaintenanceState.NeedFieldMaintenance,
];
export const compareMaintenanceState = createComparator(order);

/**
 * Determines whether a bike can be safely unlocked based on its maintenance state
 * @param maintenanceState - The bike’s maintenance state
 */
export function canBeUnlocked(maintenanceState: BikeMaintenanceState): boolean {
  return (
    maintenanceState === BikeMaintenanceState.InTruckLvl1 ||
    maintenanceState === BikeMaintenanceState.InTruckLvl2 ||
    maintenanceState === BikeMaintenanceState.InTruckToWarehouse ||
    maintenanceState === BikeMaintenanceState.InTruckForReallocation
  );
}

/**
 * Check whether a bike needs maintenance, based on its maintenance state
 * @param maintenanceState - The bike’s maintenance state
 */
export function needFieldOrWarehouse(
  maintenanceState: BikeMaintenanceState
): boolean {
  return (
    maintenanceState === BikeMaintenanceState.NeedFieldMaintenance ||
    maintenanceState === BikeMaintenanceState.NeedWarehouse
  );
}

/**
 * Check whether a bike is outside based on its maintenance state
 * @param maintenanceState - The bike’s maintenance state
 */
export function bikeIsOutside(maintenanceState: BikeMaintenanceState): boolean {
  return (
    maintenanceState === BikeMaintenanceState.InField ||
    needFieldOrWarehouse(maintenanceState)
  );
}

/**
 * Determines whether a bike should ping based on its maintenance state
 * @param maintenanceState - The bike’s maintenance state
 */
export function bikeShouldPing(
  maintenanceState: BikeMaintenanceState
): boolean {
  return (
    maintenanceState === BikeMaintenanceState.InWarehouseExitZone ||
    maintenanceState === BikeMaintenanceState.InTruckForReallocation ||
    bikeIsOutside(maintenanceState)
  );
}

/**
 * Set the bike’s location with an API call
 * @param serialNumber - The bike’s serial number
 * @param coordinates - The new coordinates
 */
export function setBikeLocation(
  serialNumber: number,
  coordinates: [number, number]
): Promise<any> {
  return store.dispatch('POST_BIKE_LOCATION', {
    serial_number: serialNumber,
    data: {
      feature: {
        type: 'Point',
        coordinates: coordinates,
      },
    },
  });
}

/**
 * Check whether a warning should be raised for an unlocked bike
 * @param lockInfo – The bike’s `lock_info` object
 * @param bikeStatus – The bike’s status
 * @param lastEndedTripTime – The last ended trip "ended_at" timestamp
 * @param softUnlockTimeout – The delay before a soft unlocked bike re-locks itself, in seconds. Defaults to the timeout defined in appConfig
 */
export function unlockWarning(
  lockInfo: ApiSchema['bike.LockInfo'],
  bikeStatus: BikeStatus,
  lastEndedTripTime: string,
  softUnlockTimeout?: number
): boolean {
  if (!lockInfo) return false;
  const lastUpdatedAt = lockInfo.last_updated?._last_updated_at;
  const lockInfoStatus = lockInfo.status;
  const softUnlockTimeoutInMs = softUnlockTimeout
    ? softUnlockTimeout * 1000
    : appConfig.lastLockInfoWarningLimit;

  const lastUpdatedAtDate = new Date(lastUpdatedAt ?? '').getTime();
  const lastEndedTripDate = new Date(lastEndedTripTime).getTime();

  const delayFromLastEndedTrip = lastUpdatedAtDate - lastEndedTripDate;
  const delayFromLastUpdate = new Date().getTime() - lastUpdatedAtDate;

  const isUnlockedAndNotInTrip =
    bikeStatus !== BikeStatus.InUse &&
    lockInfoStatus === LockedStatus.Unlocked &&
    delayFromLastEndedTrip > 0;

  const isSoftUnlockedAndTimeoutExceeded =
    lockInfoStatus === LockedStatus.SoftUnlocked &&
    delayFromLastUpdate > softUnlockTimeoutInMs;

  if (isUnlockedAndNotInTrip || isSoftUnlockedAndTimeoutExceeded) {
    return true;
  }

  return false;
}

/**
 * Determines whether a warning should be raised based on the last update time of a bike
 * @param value - The last update time
 * @param threshold - The threshold after which the warning should be raised, in milliseconds
 * @param maintenanceState - The bike’s maintenance state
 */
export function lastUpdateWarning(
  value: string | { _last_updated_at: string } | undefined,
  threshold: number = Infinity,
  maintenanceState?: BikeMaintenanceState
): boolean {
  if (!value) return false;
  const lastUpdatedAt =
    typeof value === 'string' ? value : value._last_updated_at;
  const delay = delayFromNow(lastUpdatedAt);
  const shouldPing = !!maintenanceState && bikeShouldPing(maintenanceState);
  return (
    shouldPing &&
    threshold !== null &&
    threshold !== undefined &&
    delay >= threshold
  );
}

/**
 * Compute a high-order priority for a bike, based on several criteria.
 * 0. No priority
 * 1. Highest priority: unlocked and in-field bikes while not in trip
 * 2. In search bikes
 * 3. – _Not computed here_ –
 * 4. Bikes that have not been in trip for a long time
 * 5. Bikes that need to be sent to the warehouse
 * 6. Bikes that need field maintenance
 */
export function computePriority(bike: Bike): number {
  const bikeLostInfo = bike.lost_info ?? {};
  // priority sign only on bikes that are not lost
  if (bikeLostInfo.status !== LostInfoStatus.Lost) {
    // highest priority, not in trip bikes with a lock_info.status unlock
    // and in_field, need_field_maintenance or need_warehouse
    if (
      bikeIsOutside(bike.maintenance_state ?? BikeMaintenanceState.NotSet) &&
      unlockWarning(
        bike.lock_info ?? {},
        bike.status ?? BikeStatus.NotSet,
        bike.metadata?.last_ended_trip_time ?? '',
        bike.soft_unlock_timeout?.__data
      )
    ) {
      return 1;
    } else if (bikeLostInfo.status === LostInfoStatus.InSearch) {
      return 2;
    } else if (bike.maintenance_state === BikeMaintenanceState.NeedWarehouse) {
      return 5;
    } else if (
      bike.maintenance_state === BikeMaintenanceState.NeedFieldMaintenance
    ) {
      return 6;
    } else if (!bike.out_of_order) {
      // in order, in free floating, but with a last-ended-trip of a long time ago
      if (
        bike.status === BikeStatus.Available && // only 'available' bikes, not booked, in trip, nor paused
        !bike.station_id && // not in station = free floating
        bike.metadata
      ) {
        for (let i = 2; i >= 0; i--) {
          const noTripLimit = appConfig.noTripSince[i] * 60 * 60 * 1000;
          if (
            bike.metadata.last_ended_trip_time &&
            delayFromNow(bike.metadata.last_ended_trip_time) > noTripLimit &&
            bike.metadata.last_maintenance_state_update &&
            delayFromNow(bike.metadata.last_maintenance_state_update) >
              noTripLimit
          ) {
            return 4 + 0.1 * (i + 1);
          }
        }
      }
    }
  }
  return 0;
}

type BikeLostInfo = NonNullable<Bike['lost_info']>;

/**
 * Returns a badge based on the bike’s lost status
 * @param lostInfo - The bike’s lost info field
 */
function lostStatusBikeBadge(
  lostInfo: BikeLostInfo,
  t: UseI18nReturn['t']
): Badge {
  return {
    text: lostStatusAndSearchText(lostInfo, t),
    color: bikeLostStatus(lostInfo.status ?? LostInfoStatus.NotSet).class ?? '',
    darkText: lostInfo.status === LostInfoStatus.InSearch,
    relative: true,
  };
}

function bikePowerSavingBadge(mode: PowerSavingMode): Badge {
  return {
    icon: bikePowerSavingMode(mode).icon,
    color: 'transparent', // badge bg color
    iconColor: bikePowerSavingMode(mode).class as string,
    relative: true,
    dense: true,
  };
}

/**
 * Compute the bike badge
 */
export function bikeBadge(
  bike: Bike | undefined,
  t: UseI18nReturn['t']
): Badge {
  if (!bike) return {};

  const lostInfo = bike.lost_info ?? {};
  const lwm2mInfo = bike.lwm2m_info ?? {};
  const powerSavingMode = bike.power_saving?.mode;

  return lostInfo.status === LostInfoStatus.InSearch ||
    lostInfo.status === LostInfoStatus.Lost
    ? lostStatusBikeBadge(lostInfo, t)
    : powerSavingMode === PowerSavingMode.ModeDeepSleep
      ? bikePowerSavingBadge(powerSavingMode)
      : lteSignalBadge(lwm2mInfo);
}

/**
 * Compute the battery badge for the given state of charge
 * @param soc - State of charge
 * @param loading - Shows a loader as the badge
 * @param darkText - Whether to use dark badge text
 * @returns {import('../lib/utils/format-content').Badge} The badge properties
 */
export function batteryBadge(
  soc?: number,
  loading?: boolean,
  darkText?: boolean
): Badge & { loading?: boolean; darkText?: boolean } {
  return {
    loading,
    icon: getBatteryIcon(soc),
    color: 'transparent', // badge bg color
    iconColor: getBatteryColor(soc),
    text: !soc && soc !== 0 ? '' : String(soc),
    darkText,
    relative: true,
    dense: true,
  };
}

/**
 * Return the bike’s lost status or "in search", which displays the search counts if any.
 * @param lostInfo - The bike’s lost info field
 * @param maxSearches - The maximum number of searches allowed for a bike
 */
export function lostStatusAndSearchText(
  lostInfo: BikeLostInfo,
  t: UseI18nReturn['t'],
  maxSearches = 3
): string {
  const counts = Array.isArray(lostInfo.search_history)
    ? lostInfo.search_history.length
    : 0;

  const append =
    lostInfo.status === LostInfoStatus.InSearch && counts
      ? `: ${counts}/${maxSearches}`
      : '';

  return (
    t(bikeLostStatus(lostInfo.status ?? LostInfoStatus.NotSet).message) + append
  );
}

type SetLostStatusActionData<T extends boolean = false> = T extends true
  ? { lost_status: LostInfoStatus }
  : Record<string, never>;

/**
 * Get actions settings for a "set lost status" action.
 */
export function getSetLostStatusActionSettings<
  T extends LostInfoStatus | undefined,
>({
  text = 'bikes__set_lost_status',
  color = 'primary',
  icon = 'mdi-map-marker-off',
  btnProps = {},
  dialogContent = '',
  condition = true,
  serialNumber,
  currentStatus,
  targetStatus,
  isListItem = false,
  onSuccess = () => {},
}: {
  text?: ActionSettings['text'];
  color?: ActionSettings['color'];
  icon?: ActionSettings['icon'];
  btnProps?: ActionSettings['btnProps'];
  dialogContent?: ActionSettings['dialogContent'];
  condition?: boolean;
  serialNumber: number;
  targetStatus?: T;
  currentStatus?: LostInfoStatus;
  isListItem?: boolean;
  onSuccess?: (
    self: ActionSelf<
      SetLostStatusActionData<T extends LostInfoStatus ? true : false>
    >
  ) => void;
}): ActionSettings<
  SetLostStatusActionData<T extends LostInfoStatus ? true : false>
> {
  return defineAction<
    SetLostStatusActionData<T extends LostInfoStatus ? true : false>
  >({
    text,
    color,
    icon,
    btnProps,
    isListItem,
    dialogContent,
    hidden: !condition,
    onSuccess,
    successMessage: (self: any) => {
      let extraText = '';
      switch (targetStatus || self.data.lost_status) {
        case 1:
          extraText = ' 🎉';
          break;
        case 2:
          extraText = `<br/>(${self.$t(
            'bikes__set_lost_status_new_history_entry'
          )})`;
          break;
      }
      return (
        self.$t('bikes__set_lost_status_success_message', {
          status: self.$t(
            bikeLostStatus(targetStatus || self.data.lost_status).message
          ),
        }) + extraText
      );
    },
    execFunc: async (self: any) => {
      return await to(
        store.dispatch('POST_BIKE_LOST_STATUS', {
          serial_number: serialNumber,
          data: {
            status: targetStatus || self.data.lost_status,
          },
        })
      );
    },
    onCancel: () => {
      store.dispatch('POST_BIKE_LOST_STATUS', {
        cancel: true,
      });
    },
    controls: targetStatus
      ? []
      : [
          defineControl({
            controlType: 'radioGroup',
            key: 'lost_status',
            value: currentStatus,
            required: true,
            type: Number,
            radios: [
              LostInfoStatus.Tracked,
              LostInfoStatus.InSearch,
              LostInfoStatus.Lost,
            ].map(value => ({
              label: bikeLostStatus(value).message,
              value,
            })),
          }),
        ],
  });
}
