import {
  OfferType,
  RuleType,
  OfferObtentionMethodType,
  TargetType,
} from '#/core-api/enums';

import { OfferCategory } from '@/models/offer/enums';
import { formatCurrencyNumber } from '@/lib/utils';
import { useI18n } from '@/composables/plugins';

import type { ApiSchema } from '#/core-api';

const { t, tc, locale } = useI18n();

export enum RuleCategory {
  Advanced = 0,
  ChargeMinutes = 1,
  ChargeUnlock = 2,
  GiveFreeMinutes = 3,
  GiveFreeUnlocks = 4,
  LimitTrips = 5,
  LimitUserMaxTrips = 6,
  LimitTripsAndUserMaxTrips = 7,
  FreeRiding = 8,
  FreeUnlock = 9,
}

export type PaymentRule = ApiSchema['payment.Rule'];

export interface RuleCategoryDisplayProperties {
  value: RuleCategory;
  type: 'advanced' | 'charge' | 'bonus' | 'limitation';
  name: string;
  genericText: string;
  text: (rule: PaymentRule) => string;
  color: string;
  icon: string;
}

/**
 * Compute the number of minutes of the refill cycle period
 * @returns The number of minutes
 */
export function getMinutesInRefillCycle(refillCycle?: Duracron | null): number {
  let minutes = 0;

  if (!refillCycle) return 0;

  if (refillCycle.cron && refillCycle.cron.length > 0) {
    switch (refillCycle.cron) {
      case '0 0 * * *':
        return 24 * 60;
      case '0 0 * * 0':
        return 7 * 24 * 60;
      case '0 0 1 * *':
        return 31 * 24 * 60;
      case '0 0 1 1 *':
        return 365 * 24 * 60;
      default:
        return 0;
    }
  }

  if (refillCycle.duration && refillCycle.duration !== 0) {
    minutes += refillCycle.duration;
  }

  if (refillCycle.durationMonths && refillCycle.durationMonths !== 0) {
    minutes += refillCycle.durationMonths * 31 * 24 * 60;
  }

  return minutes;
}

/**
 * Smartly categorize rules based on their properties and the rules refill cycle
 * @param refillCycle - The rules refill cycle
 * @returns The rule category
 */
export function computeOfferRuleCategory(
  rule: PaymentRule,
  refillCycle?: Duracron | null
): RuleCategory {
  const minutesInRulesRefillCycle = getMinutesInRefillCycle(refillCycle);
  // Two categories: rule with a price and rule without (= bonus)
  // Payable rule
  if (rule.price) {
    // Rule must be of repeated type
    if (rule.type === RuleType.Repeated) {
      // Charged X€ for Y minutes
      if (rule.duration) {
        return RuleCategory.ChargeMinutes;
      } else {
        return RuleCategory.ChargeUnlock;
      }
    }
  }
  // Bonus
  else {
    // Rule of repeated type
    if (rule.type === RuleType.Repeated) {
      if (rule.quantity) {
        // Bonus rule
        if (rule.duration) {
          if (
            rule.duration === 1 &&
            minutesInRulesRefillCycle &&
            rule.quantity >= minutesInRulesRefillCycle
          ) {
            return RuleCategory.FreeRiding; // free 24/7
          } else {
            return RuleCategory.GiveFreeMinutes;
          }
        } else {
          // At least 1 unlock per second is given, so we have all free unlocks
          if (
            minutesInRulesRefillCycle &&
            rule.quantity >= minutesInRulesRefillCycle * 60
          ) {
            return RuleCategory.FreeUnlock;
          } else {
            return RuleCategory.GiveFreeUnlocks;
          }
        }
      }
    } else if (rule.type === RuleType.TripConstraint) {
      if (rule.quantity) {
        if (rule.user_maximum) {
          return RuleCategory.LimitTripsAndUserMaxTrips;
        } else {
          return RuleCategory.LimitTrips;
        }
      } else if (rule.user_maximum) {
        return RuleCategory.LimitUserMaxTrips;
      }
    }
  }
  // None of the above
  return RuleCategory.Advanced;
}

export function mapOfferToRuleCategories(offerType: OfferType): RuleCategory[] {
  switch (offerType) {
    case OfferType.SharingPricing:
      return [
        RuleCategory.ChargeMinutes,
        RuleCategory.ChargeUnlock,
        RuleCategory.Advanced,
      ];
    case OfferType.Discount:
      return [
        RuleCategory.GiveFreeMinutes,
        RuleCategory.GiveFreeUnlocks,
        RuleCategory.LimitTrips,
        RuleCategory.LimitUserMaxTrips,
        RuleCategory.Advanced,
      ];
    default:
      return [RuleCategory.Advanced];
  }
}

/**
 * Format a rule category to be displayed in the Rules control of an action
 */
export function formatCategory(
  category: RuleCategory
): RuleCategoryDisplayProperties {
  switch (category) {
    default:
    case RuleCategory.Advanced:
      return {
        value: category,
        type: 'advanced',
        name: t('offers__rule_advanced_behavior'),
        genericText: t('offers__rule_generic_text__rule'),
        text: () => t('offers__rule_advanced_behavior_text'),
        color: 'disabled_lighter',
        icon: 'mdi-abacus',
      };
    case RuleCategory.ChargeMinutes:
      return {
        value: category,
        type: 'charge',
        name: t('offers__rule_charge_minutes'),
        genericText: t('offers__rule_generic_text__rule'),
        text: (rule: PaymentRule) =>
          rule.price && rule.price.amount && rule.duration
            ? tc('offers__rule_charge_minutes_text', rule.duration, {
                price: formatCurrencyNumber(
                  locale,
                  rule.price.amount,
                  rule.price.currency
                ),
                minutes: rule.duration,
              })
            : '',
        color: 'warning_alt1',
        icon: 'mdi-clock',
      };
    case RuleCategory.ChargeUnlock:
      return {
        value: category,
        type: 'charge',
        name: t('offers__rule_charge_unlock'),
        genericText: t('offers__rule_generic_text__rule'),
        text: (rule: PaymentRule) =>
          rule.price && rule.price.amount
            ? t('offers__rule_charge_unlock_text', {
                price: formatCurrencyNumber(
                  locale,
                  rule.price.amount,
                  rule.price.currency
                ),
              })
            : '',
        color: 'warning_alt1',
        icon: 'mdi-lock-open-variant',
      };
    case RuleCategory.GiveFreeMinutes:
      return {
        value: category,
        type: 'bonus',
        name: t('offers__rule_give_free_minutes'),
        genericText: t('offers__rule_generic_text__bonus'),
        text: (rule: PaymentRule) =>
          rule.quantity && rule.duration
            ? tc(
                'offers__rule_give_free_minutes_text',
                rule.quantity * rule.duration,
                { minutes: rule.quantity * rule.duration }
              )
            : '',
        color: 'success',
        icon: 'mdi-clock',
      };
    case RuleCategory.GiveFreeUnlocks:
      return {
        value: category,
        type: 'bonus',
        name: t('offers__rule_give_free_unlocks'),
        genericText: t('offers__rule_generic_text__bonus'),
        text: (rule: PaymentRule) =>
          rule.quantity
            ? tc('offers__rule_give_free_unlocks_text', rule.quantity, {
                unlocks: rule.quantity,
              })
            : '',
        color: 'success',
        icon: 'mdi-lock-open-variant',
      };
    case RuleCategory.LimitTrips:
      return {
        value: category,
        type: 'limitation',
        name: t('offers__rule_limit_trips'),
        genericText: t('offers__rule_generic_text__rule'),
        text: (rule: PaymentRule) =>
          rule.quantity
            ? tc('offers__rule_limit_trips_text', rule.quantity, {
                trips: rule.quantity,
              })
            : '',
        color: 'warning',
        icon: 'mdi-counter',
      };
    case RuleCategory.LimitUserMaxTrips:
      return {
        value: category,
        type: 'limitation',
        name: t('offers__rule_limit_user_max_trips'),
        genericText: t('offers__rule_generic_text__rule'),
        text: (rule: PaymentRule) =>
          rule.user_maximum
            ? tc('offers__rule_limit_user_max_trips_text', rule.user_maximum, {
                trips: rule.user_maximum,
              })
            : '',
        color: 'warning',
        icon: 'mdi-target-account',
      };
    case RuleCategory.LimitTripsAndUserMaxTrips:
      return {
        value: category,
        type: 'limitation',
        name: t('offers__rule_limit_trips_and_user_max_trips'),
        genericText: t('offers__rule_generic_text__rule'),
        text: (rule: PaymentRule) =>
          rule.quantity && rule.user_maximum
            ? tc('offers__rule_limit_trips_text', rule.quantity, {
                trips: rule.quantity,
              }) +
              '\n' +
              tc('offers__rule_limit_user_max_trips_text', rule.user_maximum, {
                trips: rule.user_maximum,
              })
            : '',
        color: 'warning',
        icon: 'mdi-counter',
      };
    case RuleCategory.FreeRiding:
      return {
        value: category,
        type: 'bonus',
        name: t('offers__rule_free_riding'),
        genericText: t('offers__rule_generic_text__bonus'),
        text: () => t('offers__rule_free_riding_text'),
        color: 'success_alt1',
        icon: 'mdi-sale',
      };
    case RuleCategory.FreeUnlock:
      return {
        value: category,
        type: 'bonus',
        name: t('offers__rule_free_unlock'),
        genericText: t('offers__rule_generic_text__bonus'),
        text: () => t('offers__rule_free_unlock_text'),
        color: 'success_alt1',
        icon: 'svg:lock_open_sale',
      };
  }
}

enum BenefitValidityDuracronPreset {
  TwentyFourHours = 0,
  TomorrowTwentyFourHours = 1,
  OffPeakHours = 2,
  Weekend = 3,
  ThreeDays = 4,
  FiveDays = 5,
  SevenDays = 6,
  Month = 7,
  Year = 8,
  CurrentDay = 9,
  CurrentWeek = 10,
  CurrentMonth = 11,
  CurrentYear = 12,
}

/**
 * Benefit validity presets for the Duracron component in offer creation
 */
export const benefitValidityPresets = computed<DuracronPreset[]>(() => [
  {
    label: t('duracron__preset_24h_label'),
    value: BenefitValidityDuracronPreset.TwentyFourHours,
    duration: 86400, // 24 hours, in seconds
  },
  // {
  //   label: t('duracron__preset_tomorrow_24h_label'),
  //   value: BenefitValidityDuracronPreset.TomorrowTwentyFourHours,
  //   cron: '0 0 * * *',
  //   duration: 86400, // 24 hours, in seconds
  // },
  // {
  //   label: t('duracron__preset_off_peak_hours_label'),
  //   value: BenefitValidityDuracronPreset.OffPeakHours,
  //   cron: '0 11 * * * ',
  //   duration: 18000, // 5 hours, in seconds
  // },
  // {
  //   label: t('duracron__preset_three_days_label'),
  //   value: BenefitValidityDuracronPreset.ThreeDays,
  //   duration: 259200, // 72 hours, in seconds
  // },
  // {
  //   label: t('duracron__preset__five_days_label'),
  //   value: BenefitValidityDuracronPreset.FiveDays,
  //   duration: 432000, // 120 hours, in seconds
  // },
  {
    label: t('duracron__preset__seven_days_label'),
    value: BenefitValidityDuracronPreset.SevenDays,
    duration: 604800, // 168 hours, in seconds
  },
  {
    label: t('duracron__preset__month_label'),
    value: BenefitValidityDuracronPreset.Month,
    durationMonths: 1,
  },
  {
    label: t('duracron__preset__year_label'),
    value: BenefitValidityDuracronPreset.Year,
    durationMonths: 12,
  },
  // {
  //   label: t('duracron__preset__current_day_label'),
  //   value: BenefitValidityDuracronPreset.CurrentDay,
  //   cron: '0 0 * * *',
  // },
  // {
  //   label: t('duracron__preset__current_week_label'),
  //   value: BenefitValidityDuracronPreset.CurrentWeek,
  //   cron: '0 0 * * 1',
  // },
  // {
  //   label: t('duracron__preset__current_month_label'),
  //   value: BenefitValidityDuracronPreset.CurrentMonth,
  //   cron: '0 0 1 * *',
  // },
  // {
  //   label: t('duracron__preset__current_year_label'),
  //   value: BenefitValidityDuracronPreset.CurrentYear,
  //   cron: '0 0 1 1 *',
  // },
  {
    label: t('duracron__preset_weekend_label'),
    value: BenefitValidityDuracronPreset.Weekend,
    cron: '0 0 * * 6',
    duration: 172800, // 48 hours, in seconds
  },
]);

enum RulesRefillCycleDuracronPreset {
  Daily = 0,
  Weekly = 1,
  Monthly = 2,
  Yearly = 3,
}
/**
 * Rules refill cycle presets for the Duracron component in offer creation
 */
export const rulesRefillCyclePresets = computed<DuracronPreset[]>(() => [
  {
    label: t('duracron__preset__daily_label'),
    value: RulesRefillCycleDuracronPreset.Daily,
    cron: '0 0 * * *',
  },
  {
    label: t('duracron__preset__weekly_label'),
    value: RulesRefillCycleDuracronPreset.Weekly,
    cron: '0 0 * * 0',
  },
  {
    label: t('duracron__preset__monthly_label'),
    value: RulesRefillCycleDuracronPreset.Monthly,
    cron: '0 0 1 * *',
  },
  {
    label: t('duracron__preset__yearly_label'),
    value: RulesRefillCycleDuracronPreset.Yearly,
    cron: '0 0 1 1 *',
  },
]);

export type CategoryPreset = {
  label: string;
  value: OfferCategory;
};

/**
 * Category presets for offer creation
 */
export const categoryPresets = computed<CategoryPreset[]>(() => [
  {
    label: t('offers__category__pass'),
    value: OfferCategory.Pass,
  },
  {
    label: t('offers__category__subscription'),
    value: OfferCategory.Subscription,
  },
  {
    label: t('offers__category__coupon'),
    value: OfferCategory.Coupon,
  },
  {
    label: t('offers__category__pay_as_you_go'),
    value: OfferCategory.PayAsYouGo,
  },
]);

/**
 * Determine if an offer needs to create a rental when assigned to a user, based on:
 * @param offerType - The offer type
 * @param obtentionMethodType - The offer obtention method type
 */
export function needsRentalCreation(
  offerType?: OfferType,
  obtentionMethodType?: OfferObtentionMethodType
): boolean {
  return (
    offerType === OfferType.LeasingPricing ||
    obtentionMethodType === OfferObtentionMethodType.SingleDeferredCapture
  );
}

/**
 * Returns a category based the obtention method type, type and target type
 * @param obtentionMethodType - The offer obtention method type
 * @param type - The offer type
 * @param target - The offer target type
 */
export function getCategoryFromOfferConfig(
  obtentionMethodType?: OfferObtentionMethodType,
  targetTypes: TargetType[] = [],
  type?: OfferType
): OfferCategory {
  switch (obtentionMethodType) {
    case OfferObtentionMethodType.SingleDeferredCapture:
      if (targetTypes.includes(TargetType.User)) {
        return OfferCategory.Pass;
      }
      break;
    case OfferObtentionMethodType.Subscription:
      if (targetTypes.includes(TargetType.User)) {
        return OfferCategory.Subscription;
      }
      break;
    case OfferObtentionMethodType.Manual:
      if (targetTypes.includes(TargetType.User)) {
        return OfferCategory.Coupon;
      }
      if (
        targetTypes.includes(TargetType.Area) &&
        type === OfferType.SharingPricing
      ) {
        return OfferCategory.PayAsYouGo;
      }
      break;
    default:
      return OfferCategory.Uncategorized;
  }

  return OfferCategory.Uncategorized;
}

/**
 * Returns the offer obtention method type, target type and type based on the offer category
 * @param category - The offer category
 */
export function getOfferConfigFromCategory(category: OfferCategory): {
  obtentionMethodType?: OfferObtentionMethodType;
  targetType?: TargetType;
  type?: OfferType;
} | null {
  switch (category) {
    case OfferCategory.Pass:
      return {
        obtentionMethodType: OfferObtentionMethodType.SingleDeferredCapture,
        targetType: TargetType.User,
        type: undefined,
      };
    case OfferCategory.Subscription:
      return {
        obtentionMethodType: OfferObtentionMethodType.Subscription,
        targetType: TargetType.User,
        type: undefined,
      };
    case OfferCategory.Coupon:
      return {
        obtentionMethodType: OfferObtentionMethodType.Manual,
        targetType: TargetType.User,
        type: undefined,
      };
    case OfferCategory.PayAsYouGo:
      return {
        obtentionMethodType: OfferObtentionMethodType.Manual,
        targetType: TargetType.Area,
        type: OfferType.SharingPricing,
      };
    default:
      return null;
  }
}

/**
 * Return an offer or benefit updated its computed category
 * @param offerOrBenefit - The offer or benefit
 */
export function addOfferCategory(
  offerOrBenefit: ApiSchema['payment.Offer'] | ApiSchema['payment.Benefit']
): Offer | Benefit {
  const targetTypes =
    'target' in offerOrBenefit && offerOrBenefit.target?.target_type
      ? [offerOrBenefit.target?.target_type]
      : offerOrBenefit.allowed_targets;

  const category = getCategoryFromOfferConfig(
    offerOrBenefit.obtention_method_type,
    targetTypes,
    offerOrBenefit.type
  );

  return {
    ...offerOrBenefit,
    id: offerOrBenefit.id!,
    category,
  };
}

/**
 * Return the hint for an offer type
 */
export function getOfferTypeHint(type: OfferType): string {
  switch (type) {
    case OfferType.SharingPricing:
      return t('offers__hint__sharing_pricing');
    case OfferType.LeasingPricing:
      return t('offers__hint__leasing_pricing');
    case OfferType.Discount:
      return t('offers__hint__discount');
    default:
      return '';
  }
}

/**
 * Whether an offer can be given multiple times to the same target
 */
export function isMultiplePerTargetOffer(
  allowedTargets?: TargetType[],
  obtentionMethodType?: OfferObtentionMethodType
): boolean {
  const isTargetUserOnly =
    allowedTargets?.length === 1 && allowedTargets.includes(TargetType.User);

  return (
    isTargetUserOnly &&
    (obtentionMethodType === OfferObtentionMethodType.Manual ||
      obtentionMethodType === OfferObtentionMethodType.SingleImmediateCapture)
  );
}
