import { toValue } from '@vueuse/core';

import { useFormatDuration } from '@/composables/useFormatDuration';
import { delayFromNow, isDefaultMongoDate } from '@/lib/utils';

import type { FormattedDuration } from '@/composables/useFormatDuration';
import type { MaybeRefOrGetter } from '@vueuse/core';

export interface UseFormatLastUpdatedDelay {
  /**
   * The formatted delay
   */
  formattedDelay: ComputedRef<FormattedDuration | null>;
  /**
   * A method to update manually the computed delay, useful to refresh the delay that is computed with Date.now() which is not reactive
   */
  update: () => void;
}

/**
 * Format a `last updated` date to a human readable delay
 * @param lastUpdated - Last updated date
 */
export function useFormatLastUpdatedDelay(
  lastUpdated: MaybeRefOrGetter<LastUpdatedDate | undefined>
): UseFormatLastUpdatedDelay {
  const lastUpdatedTimestamp = computed(() => {
    const value = toValue(lastUpdated);
    return typeof value === 'object' && !!value
      ? value?._last_updated_at
      : value;
  });

  const delayInSeconds = ref();

  function computeDelay(): void {
    if (
      !lastUpdatedTimestamp.value ||
      isDefaultMongoDate(lastUpdatedTimestamp.value)
    ) {
      delayInSeconds.value = 0;
    }
    delayInSeconds.value = Math.floor(
      delayFromNow(lastUpdatedTimestamp.value) / 1000
    );
  }

  watchImmediate(lastUpdatedTimestamp, computeDelay);

  const { formattedDuration } = useFormatDuration(delayInSeconds, {
    rounded: true,
    abv: true,
    dense: true,
  });

  const formattedDelay = computed(() => {
    if (!lastUpdatedTimestamp.value) return null;
    return formattedDuration.value;
  });

  function update(): void {
    computeDelay();
  }

  return {
    formattedDelay,
    update,
  };
}
