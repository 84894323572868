<template lang="pug">
VTooltip(
  v-bind="{ ...props }"
  :disabled="isTouchDevice || disabled"
  :open-delay="openDelay"
  :close-delay="0"
  :color="color"
  :tag="tag"
  :content-class="contentClass"
)
  template(#activator)
    slot(name="activator")
  slot
</template>

<style lang="stylus">
.ZTooltip__content
  z-index 999 !important
  box-shadow none
  border-radius 10px

  &.ZTooltip__content--hint
    background-color $color-disabled_alt1
    color alpha(black, 0.75)

  .inline-hint-color
    .theme--light &,
    &.theme--light,
    .theme--light
      color $color-disabled_alt1 !important

  &.ZTooltip__content--opaque
    opacity 1 !important
</style>

<script setup lang="ts">
import { check } from '@/lib/utils';

export interface ZTooltipProps {
  /**
   * Whether the tooltip should be displayed at the top.
   */
  top?: boolean;
  /**
   * Whether the tooltip should be displayed at the left.
   */
  left?: boolean;
  /**
   * Whether the tooltip should be displayed at the right.
   */
  right?: boolean;
  /**
   * Whether the tooltip should be displayed at the bottom.
   */
  bottom?: boolean;
  /**
   * The nudge bottom value.
   */
  nudgeBottom?: string | number;
  /**
   * The activator element.
   */
  activator?: HTMLElement | null;
  /**
   * Whether the tooltip is disabled.
   */
  disabled?: boolean;
  /**
   * The value of the tooltip.
   */
  value?: boolean;
  /**
   * Whether the tooltip is a hint.
   */
  hint?: boolean;
  /**
   * The color of the tooltip.
   */
  color?: string;
  /**
   * Whether the tooltip is opaque.
   */
  opaque?: boolean;
  /**
   * The tag of the tooltip.
   */
  tag?: string;
  /**
   * The open delay of the tooltip
   */
  openDelay?: number;
}

const props = withDefaults(defineProps<ZTooltipProps>(), {
  top: false,
  left: false,
  right: false,
  bottom: false,
  nudgeBottom: 0,
  activator: null,
  disabled: false,
  value: false,
  hint: false,
  color: '',
  opaque: false,
  tag: 'span',
  openDelay: 0,
});

const isTouchDevice = check.hasTouch();

const contentClass = computed(() => {
  return [
    'ZTooltip__content',
    ...(props.hint ? ['ZTooltip__content--hint'] : []),
    ...(props.opaque ? ['ZTooltip__content--opaque'] : []),
  ].join(' ');
});
</script>
