import StoreModule from '@/store/core/store-module';
import { bikeFields } from '@/models/fields';
import {
  internalEmailPopulateConfig,
  fleetProductPopulateConfig,
  areasPopulateConfig,
  stationsPopulateConfig,
  openIssuesPopulateConfig,
  bookingPopulateConfig,
  ongoingTripPopulateConfig,
} from '@/lib/helpers/populate';
import { ProductVersion } from '@/enums/product';

import type { ApiSchema } from '#/core-api';
import type {
  PopulatedField,
  PopulatedFieldArray,
  PopulatedInjectedField,
} from '@/store/core/populate';
import type { FleetProductPopulatedType } from '@/lib/helpers/populate';
import type { Get, Post, Patch } from '@/store/types/core-api';
import type { OverridePayload } from '@/store/types/helpers/store-types';

declare global {
  /**
   * Populated Bike
   */
  type Bike = DeepMerge<
    ApiSchema['bike.Bike'],
    {
      serial_number: number;
      open_issues?: PopulatedFieldArray<
        ApiSchema['bike.Bike']['serial_number'],
        Pick<ApiSchema['issue.Issue'], 'id' | 'label' | 'severity'>
      >;
      station?: PopulatedField<
        ApiSchema['bike.Bike']['station_id'],
        ApiSchema['station.Station']['label']
      >;
      ongoing_trip?: PopulatedField<
        ApiSchema['bike.Bike']['serial_number'],
        ApiSchema['rental.Trip']['id']
      >;
      ongoing_trip_started_at?: PopulatedField<
        ApiSchema['bike.Bike']['serial_number'],
        ApiSchema['rental.Trip']['started_at']
      >;
      ongoing_trip_metadata?: PopulatedField<
        ApiSchema['bike.Bike']['serial_number'],
        ApiSchema['rental.Trip']['metadata']
      >;
      used_by?: PopulatedField<
        ApiSchema['bike.Bike']['serial_number'],
        ApiSchema['rental.Trip']['user_id']
      >;
      area?: PopulatedField<
        ApiSchema['bike.Bike']['area_id'],
        ApiSchema['area.ServiceableArea']['label']
      >;
      warehouse?: PopulatedField<
        ApiSchema['bike.Bike']['area_id'],
        ApiSchema['area.ServiceableArea']['warehouse']
      >;
      soft_unlock_timeout?: PopulatedField<
        ApiSchema['bike.Bike']['area_id'],
        ApiSchema['area.ServiceableArea']['soft_unlock_timeout']
      >;
      current_booking?: PopulatedField<
        ApiSchema['bike.Bike']['serial_number'],
        ApiSchema['rental.Booking']['id']
      >;
      current_booking_booked_at?: PopulatedField<
        ApiSchema['bike.Bike']['serial_number'],
        ApiSchema['rental.Booking']['booked_at']
      >;
      booked_by?: PopulatedField<
        ApiSchema['bike.Bike']['serial_number'],
        ApiSchema['rental.Booking']['user_id']
      >;
      metadata?: {
        last_maintenance_state_updater?: PopulatedInjectedField<
          NonNullable<ApiSchema['bike.Bike']['metadata']>[string],
          ApiSchema['user.User']['email']
        >;
      };
      scan_info?: {
        last_scan_by?: PopulatedField<
          NonNullable<ApiSchema['bike.Bike']['scan_info']>['user_id'],
          ApiSchema['user.User']['email']
        >;
      };
      lost_info?: {
        search_history?: (Omit<ApiSchema['bike.SearchHistory'], 'user_id'> & {
          user_id?: PopulatedInjectedField<
            ApiSchema['bike.SearchHistory']['user_id'],
            { user: ApiSchema['user.User']['email'] }
          >;
        })[];
      };
      notes?: (ApiSchema['user.Note'] & {
        creator: PopulatedField<
          ApiSchema['user.Note']['created_by'],
          ApiSchema['user.User']['email']
        >;
      })[];
    } & FleetProductPopulatedType<ApiSchema['bike.Bike']['serial_number']>
  >;
}

const bikesScopes = ['Bikes', 'Map', 'Dashboard'] as const;

type ActionPathParamsOverride = {
  id: string;
  serial_number: never;
};
type ExecPathParamsOverride = {
  id: string;
  serial_number: never;
  action: never;
};

export interface BikeStoreTypes {
  BIKES: [Get<'/bikes', Bike[], typeof bikesScopes>];
  BIKES_COUNT: [Get<'/bikes/count', void, typeof bikesScopes>];
  BIKES_AUTODIAG_DEFINITION: [Get<'/bikes/autodiag_definition/errors'>];
  BIKE: [Get<'/bikes/{SerialNumber}', Bike>];
  BIKE_MAINTENANCE_STATE: [Post<'/bikes/{SerialNumber}/set_maintenance_state'>];
  BIKE_REQUIRED_ROLE: [Post<'/bikes/{SerialNumber}/set_required_role'>];
  BIKE_LOCATION: [Post<'/bikes/{SerialNumber}/set_location'>];
  BIKE_LOST_STATUS: [Post<'/bikes/{SerialNumber}/set_lost_status'>];
  BIKE_AREA: [Post<'/bikes/{SerialNumber}/set_bike_area'>];
  BIKE_LOCK_INFO: [Post<'/bikes/{SerialNumber}/set_lock_info'>];
  BIKE_SET_STATUS: [Post<'/bikes/{SerialNumber}/set_status'>];
  BIKE_AVAILABILITY: [Patch<'/bikes/{SerialNumber}/set_availability'>];
  BIKE_ACCESSIBILITY: [Patch<'/bikes/{SerialNumber}/set_accessibility'>];
  BIKE_PULL: [Post<'/bikes/{SerialNumber}/pull'>];
  BIKE_INFO: [Post<'/bikes/{SerialNumber}/set_bike_info'>];
  BIKE_WRITE_BIKE_STATE: [Post<'/bikes/{SerialNumber}/write_bike_state'>];
  BIKE_EXEC: [
    OverridePayload<
      Post<'/bikes/{SerialNumber}/exec/{Action}'>,
      ActionPathParamsOverride
    >,
  ];
  BIKE_UPDATE_FIRMWARE: [
    OverridePayload<
      Post<'/bikes/{SerialNumber}/exec/{Action}'>,
      ExecPathParamsOverride
    >,
  ];
  BIKE_PRODUCT_CONFIG_UPDATE: [
    OverridePayload<
      Post<'/bikes/{SerialNumber}/exec/{Action}'>,
      ExecPathParamsOverride
    >,
  ];
  BIKE_SYNC: [
    OverridePayload<
      Post<'/bikes/{SerialNumber}/sync'>,
      ActionPathParamsOverride
    >,
  ];
  BIKE_PING: [
    OverridePayload<
      Post<'/bikes/{SerialNumber}/ping'>,
      ActionPathParamsOverride
    >,
  ];
  BIKE_PRODUCT_REBOOT: [
    OverridePayload<
      Post<'/bikes/{SerialNumber}/exec/{Action}'>,
      ExecPathParamsOverride
    >,
  ];
  BIKE_REGISTER_UPDATE: [
    OverridePayload<
      Post<'/bikes/{SerialNumber}/exec/{Action}'>,
      ExecPathParamsOverride
    >,
  ];
}

const bikesModule = new StoreModule<BikeStoreTypes>();
bikesModule
  /*
   * Bikes
   */
  .createResourceAndAction({
    type: 'BIKES',
    requestEndPoint: '/bikes',
    scopes: bikesScopes,
    withGlobalUsageQuery: true,
    requestOptions: () => ({
      freeze: true,
      params: {
        fields: bikeFields,
        query: { product: { $ne: ProductVersion.Zoov01 } },
      },
    }),
    populate: [
      openIssuesPopulateConfig({
        dataField: 'serial_number',
      }),
      stationsPopulateConfig(),
      ongoingTripPopulateConfig({
        dataField: 'serial_number',
      }),
      internalEmailPopulateConfig({
        dataField: 'scan_info.user_id',
        asKey: 'last_scan_by',
      }),
      areasPopulateConfig({
        withSoftUnlockTimeout: true,
      }),
    ],
    transform: data => data.bikes,
  })
  .createResourceAndAction({
    type: 'BIKES_COUNT',
    requestEndPoint: '/bikes/count',
    scopes: bikesScopes,
    withGlobalUsageQuery: true,
    requestOptions: () => ({
      params: {
        query: { product: { $ne: ProductVersion.Zoov01 } },
      },
    }),
  })
  .createResourceAndAction({
    type: 'BIKES_AUTODIAG_DEFINITION',
    requestEndPoint: '/bikes/autodiag_definition/errors',
  })
  .createResourceAndAction({
    type: 'BIKE',
    requestEndPoint: '/bikes/:serial_number',
    transform: data => data.bike,
    populate: [
      fleetProductPopulateConfig({
        dataField: 'serial_number',
      }),
      ongoingTripPopulateConfig({
        dataField: 'serial_number',
      }),
      stationsPopulateConfig(),
      bookingPopulateConfig({
        dataField: 'serial_number',
        responseFields: [
          { key: 'id', as: 'current_booking' },
          { key: 'booked_at', as: 'current_booking_booked_at' },
          { key: 'user_id', as: 'booked_by' },
        ],
      }),
      openIssuesPopulateConfig({
        dataField: 'serial_number',
      }),
      internalEmailPopulateConfig({
        dataField: 'metadata.last_maintenance_state_updater',
        asKey: 'email',
        inject: true,
        name: 'last_maintenance_state_updater',
      }),
      internalEmailPopulateConfig({
        dataField: 'scan_info.user_id',
        asKey: 'email',
        inject: true,
        name: 'scan_info',
      }),
      internalEmailPopulateConfig({
        dataField: 'user_id',
        onCollectionNames: ['lost_info.search_history'],
        asKey: 'user',
        inject: true,
        name: 'bike_search_history',
      }),
      internalEmailPopulateConfig({
        dataField: 'created_by',
        onCollectionNames: ['notes'],
      }),
      areasPopulateConfig({
        withWarehouse: true,
        withSoftUnlockTimeout: true,
      }),
    ],
  })
  .createAction({
    type: 'BIKE_INFO',
    requestEndPoint: '/bikes/:serial_number/set_bike_info',
    requestMethod: 'POST',
    permission: 'gateway.bike.set_bike_info',
  })
  .createAction({
    type: 'BIKE_MAINTENANCE_STATE',
    requestEndPoint: '/bikes/:serial_number/set_maintenance_state',
    requestMethod: 'POST',
    permission: 'gateway.bike.set_maintenance_state',
  })
  .createAction({
    type: 'BIKE_REQUIRED_ROLE',
    requestEndPoint: '/bikes/:serial_number/set_required_role',
    requestMethod: 'POST',
    permission: 'gateway.bike.set_required_role',
  })
  .createAction({
    type: 'BIKE_LOCATION',
    requestEndPoint: '/bikes/:serial_number/set_location',
    requestMethod: 'POST',
    permission: 'gateway.bike.set_location',
  })
  .createAction({
    type: 'BIKE_LOST_STATUS',
    requestEndPoint: '/bikes/:serial_number/set_lost_status',
    requestMethod: 'POST',
    permission: 'gateway.bike.set_lost_status',
  })
  .createAction({
    type: 'BIKE_AREA',
    requestEndPoint: '/bikes/:serial_number/set_bike_area',
    requestMethod: 'POST',
    permission: 'gateway.bike.set_bike_area',
  })
  .createAction({
    type: 'BIKE_LOCK_INFO',
    requestEndPoint: '/bikes/:serial_number/set_lock_info',
    requestMethod: 'POST',
    permission: 'gateway.bike.set_lock_info',
  })
  .createAction({
    type: 'BIKE_SET_STATUS',
    requestEndPoint: '/bikes/:serial_number/set_status',
    requestMethod: 'POST',
    permission: 'gateway.bike.set_status',
  })
  .createAction({
    type: 'BIKE_AVAILABILITY',
    requestEndPoint: '/bikes/:serial_number/set_availability',
    requestMethod: 'PATCH',
    permission: 'gateway.bike.set_availability',
  })
  .createAction({
    type: 'BIKE_ACCESSIBILITY',
    requestEndPoint: '/bikes/:serial_number/set_accessibility',
    requestMethod: 'PATCH',
    permission: 'gateway.bike.set_accessibility',
  })
  // Pull a bike from its fastener
  .createAction({
    type: 'BIKE_PULL',
    requestEndPoint: '/bikes/:serial_number/pull',
    requestMethod: 'POST',
    permission: 'gateway.bike.pull',
  })
  /*
   * Exec actions
   * (using :id is simpler for shared logic)
   */
  .createAction({
    type: 'BIKE_EXEC',
    requestEndPoint: '/bikes/:id/exec/:action',
    requestMethod: 'POST',
    permission: 'gateway.bike.exec',
    longRequest: true,
  })
  .createAction({
    type: 'BIKE_UPDATE_FIRMWARE',
    requestEndPoint: '/bikes/:id/exec/firmware_update',
    requestMethod: 'POST',
    longRequest: true,
  })
  // "execs" (not really execs but treated as execs)
  .createAction({
    type: 'BIKE_SYNC',
    requestEndPoint: '/bikes/:id/sync',
    requestMethod: 'POST',
    permission: 'gateway.bike.sync',
    longRequest: true,
  })
  .createAction({
    type: 'BIKE_PING',
    requestEndPoint: '/bikes/:id/ping',
    requestMethod: 'POST',
    permission: 'gateway.bike.ping',
    longRequest: true,
  })
  .createAction({
    type: 'BIKE_WRITE_BIKE_STATE',
    requestEndPoint: '/bikes/:serial_number/write_bike_state',
    requestMethod: 'POST',
    permission: 'gateway.bike.write_bike_state',
  })
  .createAction({
    type: 'BIKE_PRODUCT_CONFIG_UPDATE',
    requestEndPoint: '/bikes/:id/exec/product_config/0/update',
    requestMethod: 'POST',
    permission: 'gateway.bike.exec',
  })
  .createAction({
    type: 'BIKE_PRODUCT_REBOOT',
    requestEndPoint: '/bikes/:id/exec/firmware_main/0/reboot',
    requestMethod: 'POST',
    permission: 'gateway.bike.exec',
  })
  .createAction({
    type: 'BIKE_REGISTER_UPDATE',
    requestEndPoint: '/bikes/:id/exec/register_update',
    requestMethod: 'POST',
    permission: 'gateway.bike.exec',
  });

export default bikesModule;
