import convert from 'convert-units';

import { computeStartOfBinning } from '@/lib/utils';

import type { Unit } from 'convert-units';

export type Binning =
  | 'minutes'
  | 'hours'
  | 'days'
  | 'weeks'
  | 'months'
  | 'years';

const unitMap = {
  m: { unit: 'min', label: 'minute', binning: 'minutes' },
  h: { unit: 'h', label: 'hour', binning: 'hours' },
  d: { unit: 'd', label: 'day', binning: 'days' },
  w: { unit: 'week', label: 'week', binning: 'days' },
  M: { unit: 'month', label: 'month', binning: 'months' },
  y: { unit: 'year', label: 'year', binning: 'years' },
} satisfies Record<
  'm' | 'h' | 'd' | 'w' | 'M' | 'y',
  { unit: string; label: string; binning: Binning }
>;

interface ProcessedTime {
  period: string;
  mode: string;
  count: number;
  value: number;
  binning: Binning;
  from?: number;
}

// Round timestamp up and down to the given binning
export default function processTime(
  name?: string,
  excludeCurrent?: boolean
): ProcessedTime {
  const match = (name ?? '').match(/([0-9]{1,2})(m|h|d|w|M|y)/);
  const value = Number(match?.[1]);
  const { unit, label, binning } = unitMap[match?.[2] as keyof typeof unitMap];
  const out: ProcessedTime = {
    period: label,
    mode: excludeCurrent ? 'past' : 'last',
    count: value,
    value: convert(value)
      .from(unit as Unit)
      .to('ms'),
    binning,
  };
  if (binning === 'months') {
    // in the case of month, converting value directly may not result to round
    // numbers because of the peculiar number of days in months.
    const nowTimestamp = Date.now();
    const subtractVal =
      (unit === 'year' ? 12 : 1) * value - (excludeCurrent ? 0 : 1);

    out.from = computeStartOfBinning(
      nowTimestamp,
      subtractVal,
      binning
    ).valueOf();
  }
  return out;
}
