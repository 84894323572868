/**
 * Handler that transforms an input to fixed number with given decimals
 * @param value - The input value
 * @param decimals - The number of decimals to handle, defaults to 2
 */
export default function formatPaymentInput(
  value?: string | number,
  decimals = 2
): string | undefined {
  if (value === undefined || value === null) return undefined;

  const replaced = Number(String(value).replace(/\./g, ''));
  return !Number.isNaN(replaced)
    ? (replaced / 10 ** decimals).toFixed(decimals)
    : String(value);
}
