import { OfferPolicyType } from '#/core-api/enums';

import { defaultEnumDisplayProperties } from '@/lib/utils';

export function offerPolicyType(type?: OfferPolicyType): DisplayProperties {
  switch (type) {
    case OfferPolicyType.NotSet:
      return {
        message: 'offer_policies__undefined_type',
        icon: 'mdi-help',
        class: 'secondary',
      };
    case OfferPolicyType.BenefitsLimit:
      return {
        message: 'offer_policies__benefits_limit',
      };
    case OfferPolicyType.BenefitsPerTargetLimit:
      return {
        message: 'offer_policies__benefits_per_target_limit',
      };
    default:
      return defaultEnumDisplayProperties(type);
  }
}
