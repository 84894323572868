<template lang="pug">
div(
  ref="scrollableRef"
  :style="scrollableStyle"
  :class="className"
)
  slot
</template>

<style lang="stylus">
.ZScrollable
  overflow auto

  &.ZScrollable--touchOverflowScrolling
    -webkit-overflow-scrolling touch

  &.ZScrollable--absolute
    position absolute

  &.ZScrollable--relative
    position relative

  &.ZScrollable--withTransition
    transition all 0.2s ease

  &.ZScrollable--prevent
    overflow hidden

  for n in 0 1 2 3 4
    &.ZScrollable--toolbars--{n}
      with-toolbar(n)

scrollbar-theme('.ZScrollable')
</style>

<script setup lang="ts">
import { hasUnits } from '@/lib/utils';

type Inset = 'top' | 'left' | 'right' | 'bottom';

export interface ZScrollableProps {
  /**
   * Top inset
   */
  top?: string | number;
  /**
   * Left inset
   */
  left?: string | number;
  /**
   * Right inset
   */
  right?: string | number;
  /**
   * Bottom inset
   */
  bottom?: string | number;
  /**
   * Disable scrollable
   */
  off?: boolean;
  /**
   *  Keep applying these insets to the scrollable even when it is toggled off
   * by {@link ZScrollableProps['off']} prop
   */
  keep?: Inset[];
  /**
   * Use relative positioning
   */
  relative?: boolean;
  /**
   * Height of the scrollable area
   */
  height?: string | null;
  /**
   * Enable auto positioning
   */
  auto?: boolean;
  /**
   * Number of toolbars
   */
  toolbars?: string | number;
  /**
   * Prevent touch overflow scrolling
   */
  preventTouchOverflowScrolling?: boolean;
  /**
   * Prevent scrolling
   */
  prevent?: boolean;
}

const props = withDefaults(defineProps<ZScrollableProps>(), {
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  off: false,
  keep: () => [],
  relative: false,
  height: null,
  auto: false,
  toolbars: 0,
  preventTouchOverflowScrolling: false,
  prevent: false,
});

const emit = defineEmits<{
  (name: 'scroll', e: Event): void;
  (name: 'mousewheel'): void;
}>();

const scrollableRef = ref<HTMLElement | null>(null);

const withTransition = ref(false);

const scrollableStyle = computed(() => {
  if (props.auto) return {};

  const insets: Inset[] = ['top', 'left', 'right', 'bottom'];

  return insets.reduce<Record<string, string | number>>(
    (style, property) => {
      const cssProperty = props.off ? `margin-${property}` : property;
      if (props.off && !props.keep.includes(property)) return style;

      const value = props[property];
      style[cssProperty] = hasUnits(value) ? value : `${value}px`;

      return style;
    },
    {
      height: props.height ?? '',
    }
  );
});

const className = computed(() => ({
  ZScrollable: !props.off,
  'ZScrollable--relative': props.relative,
  'ZScrollable--absolute': !props.relative,
  'ZScrollable--withTransition': withTransition.value,
  'ZScrollable--touchOverflowScrolling': !props.preventTouchOverflowScrolling,
  'ZScrollable--prevent': props.prevent,
  [`ZScrollable--toolbars--${props.toolbars}`]: props.auto,
}));

function registerTransition(
  newVal: number | string,
  oldVal: number | string
): void {
  if (Number(newVal) < Number(oldVal)) withTransition.value = true;
  else withTransition.value = false;
}

useEventListener(scrollableRef, 'scroll', e => emit('scroll', e));
useEventListener(
  scrollableRef,
  'onwheel' in document
    ? 'wheel'
    : 'onmousewheel' in document
    ? 'mousewheel'
    : 'DOMMouseScroll',
  () => emit('mousewheel'),
  { passive: true }
);

watch(() => props.top, registerTransition);
watch(() => props.bottom, registerTransition);
watch(() => props.left, registerTransition);
watch(() => props.right, registerTransition);

function scrollTo(top: number, left: number): void {
  if (scrollableRef.value) {
    scrollableRef.value.scrollTop = top;
    scrollableRef.value.scrollLeft = left;
  }
}

function scrollIntoView(params: boolean | ScrollIntoViewOptions): void {
  scrollableRef.value?.scrollIntoView(params || {});
}

defineExpose({ scrollTo, scrollIntoView });
</script>
