import Color from 'color';

import { keysOf } from '@/lib/utils';

const baseTheme = {
  primary: '#23AFF3',
  secondary: '#DCF4FF',
  accent: '#1D013B',
  error: '#FF5566',
  info: '#2D8ADA',
  success: '#78DA61',
  warning: '#F2C700',
  disabled: '#8899AA',
  other: '#6369D1',
  angels: '#A900E7',
  // alternative shades 1
  primary_alt1: '#2d8ada', // original
  secondary_alt1: '#AAE6FF',
  accent_alt1: '#42033D',
  error_alt1: '#D30C7B',
  info_alt1: '#1BA5ED', // original
  success_alt1: '#00F3BB',
  warning_alt1: '#FF9F40',
  disabled_alt1: '#D8CBAD',
  other_alt1: '#9966FF',
  // alternative shades 2
  error_alt2: '#A40E4C',
  error_alt3: '#E72D36',
  other_alt2: '#EAA2D0',

  // fifteen control rebranding (used for login page, loader and main app toolbar)
  fifteen_control: '#2B98B6',
  android: '#3ddc84',
} as const;

type BaseColor = keyof typeof baseTheme;
type ShadeColor =
  | `${BaseColor}_lighter`
  | `${BaseColor}_darker`
  | `${BaseColor}_lighter2`
  | `${BaseColor}_darker2`;

export type ThemeColor = BaseColor | ShadeColor;

const shadesTheme = keysOf(baseTheme).reduce<Record<ShadeColor, string>>(
  (shades, key) => {
    const value = baseTheme[key];
    shades[`${key}_lighter`] = Color(value).lighten(0.2).hex();
    shades[`${key}_darker`] = Color(value).darken(0.2).hex();
    shades[`${key}_lighter2`] = Color(value).lighten(0.4).hex();
    shades[`${key}_darker2`] = Color(value).darken(0.4).hex();
    return shades;
  },
  {} as Record<ShadeColor, string>
);

const theme: Record<ColorString, string> = {
  ...baseTheme,
  ...shadesTheme,
};

export default theme;
