export const fields = [
  'state_of_charge',
  'state_of_charge_from_bike',
  'state_of_charge_estimated',
  'state_of_charge_from_battery',
  'state_of_health',
  'serial_number',
  'manufacturer_id',
  'manufacturing_date',
  'firmware_version',
  'category',
  'last_updated',
  'status',
  'battery_life',
  'voltage',
] satisfies ModelField<'bike.Battery'>[];
