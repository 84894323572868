import theme from '@/themes';

import type * as CSS from 'csstype';

export const lightMapStyle =
  'mapbox://styles/benbirota/cloedt2qk002i01phb89w75ru';
export const darkMapStyle =
  'mapbox://styles/benbirota/cloedsu7t002j01pi04fhadkx';
export const satelliteMapStyle =
  'mapbox://styles/benbirota/cloedsyen002701r063hw1635';
export const defaultLocateZoom = 18;
export const defaultPosition = [2.161192, 47.385762, 5]; // France
export const defaultFitBounds = [
  [2.490782, 51.078043],
  [-5.139994, 48.448787],
  [2.536398, 42.33261],
  [8.232366, 48.96655],
]; // France
export const geocoderResultsLimit = 6;
export const clearLocalStorageEntries = [
  'mapbox.eventData.uuid:YmVuYmlyb3Rh',
  'mapbox.eventData:YmVuYmlyb3Rh',
];

// for dataset API access
export const userName = 'benbirota';
export const endpoint = 'https://api.mapbox.com/datasets/v1/';

/**
 * Paint properties for generic text displayed on the map
 */
export const textPaint: mapboxgl.SymbolPaint = {
  'text-color': ['case', ['get', '_dark'], '#fff', theme.accent],
  'text-halo-color': ['case', ['get', '_dark'], theme.accent, '#fff'],
  'text-halo-width': 0.75,
  'text-halo-blur': 0.5,
};

/**
 * Generate a set of styles for Mapbox draw tools based on a given color
 * @param color - Color to use for the styles
 */
export function getDrawStyles(color: CSS.Property.Color): mapboxgl.Layer[] {
  return [
    {
      id: 'gl-draw-polygon-fill-inactive',
      type: 'fill',
      filter: [
        'all',
        ['==', 'active', 'false'],
        ['==', '$type', 'Polygon'],
        ['!=', 'mode', 'static'],
      ],
      paint: {
        'fill-color': color,
        'fill-outline-color': color,
        'fill-opacity': 0.1,
      },
    },
    {
      id: 'gl-draw-polygon-fill-active',
      type: 'fill',
      filter: ['all', ['==', 'active', 'true'], ['==', '$type', 'Polygon']],
      paint: {
        'fill-color': color,
        'fill-outline-color': color,
        'fill-opacity': 0.1,
      },
    },
    {
      id: 'gl-draw-polygon-midpoint',
      type: 'circle',
      filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'midpoint']],
      paint: {
        'circle-radius': 3,
        'circle-color': color,
      },
    },
    {
      id: 'gl-draw-polygon-stroke-inactive',
      type: 'line',
      filter: [
        'all',
        ['==', 'active', 'false'],
        ['==', '$type', 'Polygon'],
        ['!=', 'mode', 'static'],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': color,
        'line-width': 2,
      },
    },
    {
      id: 'gl-draw-polygon-stroke-active',
      type: 'line',
      filter: ['all', ['==', 'active', 'true'], ['==', '$type', 'Polygon']],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': color,
        'line-dasharray': [0.2, 2],
        'line-width': 2,
      },
    },
    {
      id: 'gl-draw-line-inactive',
      type: 'line',
      filter: [
        'all',
        ['==', 'active', 'false'],
        ['==', '$type', 'LineString'],
        ['!=', 'mode', 'static'],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': color,
        'line-width': 2,
      },
    },
    {
      id: 'gl-draw-line-active',
      type: 'line',
      filter: ['all', ['==', '$type', 'LineString'], ['==', 'active', 'true']],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': color,
        'line-dasharray': [0.2, 2],
        'line-width': 2,
      },
    },
    {
      id: 'gl-draw-polygon-and-line-vertex-stroke-inactive',
      type: 'circle',
      filter: [
        'all',
        ['==', 'meta', 'vertex'],
        ['==', '$type', 'Point'],
        ['!=', 'mode', 'static'],
      ],
      paint: {
        'circle-radius': 5,
        'circle-color': '#fff',
      },
    },
    {
      id: 'gl-draw-polygon-and-line-vertex-inactive',
      type: 'circle',
      filter: [
        'all',
        ['==', 'meta', 'vertex'],
        ['==', '$type', 'Point'],
        ['!=', 'mode', 'static'],
      ],
      paint: {
        'circle-radius': 3,
        'circle-color': color,
      },
    },
    {
      id: 'gl-draw-point-point-stroke-inactive',
      type: 'circle',
      filter: [
        'all',
        ['==', 'active', 'false'],
        ['==', '$type', 'Point'],
        ['==', 'meta', 'feature'],
        ['!=', 'mode', 'static'],
      ],
      paint: {
        'circle-radius': 5,
        'circle-opacity': 1,
        'circle-color': '#fff',
      },
    },
    {
      id: 'gl-draw-point-inactive',
      type: 'circle',
      filter: [
        'all',
        ['==', 'active', 'false'],
        ['==', '$type', 'Point'],
        ['==', 'meta', 'feature'],
        ['!=', 'mode', 'static'],
      ],
      paint: {
        'circle-radius': 3,
        'circle-color': color,
      },
    },
    {
      id: 'gl-draw-point-stroke-active',
      type: 'circle',
      filter: [
        'all',
        ['==', '$type', 'Point'],
        ['==', 'active', 'true'],
        ['!=', 'meta', 'midpoint'],
      ],
      paint: {
        'circle-radius': 7,
        'circle-color': '#fff',
      },
    },
    {
      id: 'gl-draw-point-active',
      type: 'circle',
      filter: [
        'all',
        ['==', '$type', 'Point'],
        ['!=', 'meta', 'midpoint'],
        ['==', 'active', 'true'],
      ],
      paint: {
        'circle-radius': 5,
        'circle-color': color,
      },
    },
    {
      id: 'gl-draw-polygon-fill-static',
      type: 'fill',
      filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Polygon']],
      paint: {
        'fill-color': color,
        'fill-outline-color': color,
        'fill-opacity': 0.1,
      },
    },
    {
      id: 'gl-draw-polygon-stroke-static',
      type: 'line',
      filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Polygon']],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': color,
        'line-width': 2,
      },
    },
    {
      id: 'gl-draw-line-static',
      type: 'line',
      filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'LineString']],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': color,
        'line-width': 2,
      },
    },
    {
      id: 'gl-draw-point-static',
      type: 'circle',
      filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Point']],
      paint: {
        'circle-radius': 5,
        'circle-color': color,
      },
    },
  ];
}
