import { BenefitStatus, OfferObtentionMethodType } from '#/core-api/enums';

import StoreModule from '@/store/core/store-module';
import { rfidProductFields, userFields, apiKeyFields } from '@/models/fields';
import { gdprProneFields } from '@/lib/helpers/gdpr';
import { exclude } from '@/lib/utils';
import {
  internalEmailPopulateConfig,
  inUsePopulateConfig,
  userEmailPopulateConfig,
  scopePopulateConfig,
} from '@/lib/helpers/populate';
import { stripeConfig } from '@/config';
import { useAuth } from '@/composables';
import { usePrivileges } from '@/composables/plugins';

import type { ApiSchema } from '#/core-api';
import type {
  PopulatedField,
  PopulatedFieldArray,
  PopulatedInjectedField,
} from '@/store/core/populate';
import type { Get, Post, Patch, Delete } from '@/store/types/core-api';

declare global {
  /**
   * Populated User
   */
  type User = DeepMerge<
    ApiSchema['user.User'],
    {
      id: string;
      ongoing_trip?: PopulatedField<
        ApiSchema['user.User']['id'],
        ApiSchema['rental.Trip']['id']
      >;
      ongoing_trip_started_at?: PopulatedField<
        ApiSchema['user.User']['id'],
        ApiSchema['rental.Trip']['started_at']
      >;
      /** @deprecated use `used_vehicle` instead */
      used_bike?: PopulatedField<
        ApiSchema['user.User']['id'],
        ApiSchema['rental.Trip']['bike_serial_number']
      >;
      used_vehicle?: PopulatedField<
        ApiSchema['user.User']['id'],
        ApiSchema['rental.Trip']['vehicle_id']
      >;
      areas?: DeepMerge<
        ApiSchema['user.User_AreaUpdate'],
        {
          label?: PopulatedField<
            ApiSchema['user.User_AreaUpdate']['id'],
            ApiSchema['area.ServiceableArea']['label']
          >;
        }
      >[];
      notes?: (ApiSchema['user.Note'] & {
        creator: PopulatedField<
          ApiSchema['user.Note']['created_by'],
          ApiSchema['user.User']['email']
        >;
      })[];
      referrer?: PopulatedField<
        ApiSchema['user.User']['referred_by'],
        ApiSchema['user.User']['email']
      >;
      scopes?: PopulatedField<
        ArrayElement<ApiSchema['user.User']['scope_ids']>,
        ApiSchema['auth.Scope']['name']
      >;
      rfid_tags?: DeepMerge<
        ApiSchema['user.RfidTag'],
        {
          rfid_product_id?: PopulatedInjectedField<
            ApiSchema['user.RfidTag']['rfid_product_id'],
            { card: ApiSchema['user.RfidProduct']['card'] }
          >;
        }
      >[];
      subscriptions?: PopulatedFieldArray<
        ApiSchema['user.User']['id'],
        Pick<ApiSchema['payment.Benefit'], 'id' | 'name' | 'type' | 'status'>
      >;
    }
  >;

  /**
   * The logged in User
   */
  type LoginUser = DeepMerge<ApiSchema['user.User'], { id: string }>;

  /**
   * Enhanced ApiKey shape
   */
  type ApiKey = ApiSchema['user.APIKey'] & {
    id: string;
    accesses?: { method: 'GET' | 'POST' | 'PATCH' | 'DELETE'; route: string }[];
  };
}

const gdprSafeUserFields = exclude(userFields, gdprProneFields);
const usersScopes = [
  'Users',
  'DashboardAllUsers',
  'DashboardNewUsers',
] as const;

export interface UserStoreTypes {
  USERS: [Get<'/users', User[], typeof usersScopes>, Post<'/users'>];
  USERS_COUNT: [Get<'/users/count', void, typeof usersScopes>];
  USER: [Get<'/users/{ID}', User>, Patch<'/users/{ID}'>];
  LOGIN_USER: [Get<'/users/{ID}', LoginUser>];
  USER_PERMANENT_OTP_SESSION: [
    Get<'/auth/permanent_otp_session', string>,
    Post<'/auth/permanent_otp_session', string>,
    Delete<'/auth/permanent_otp_session'>,
  ];
  USER_EDIT_ROLES: [Patch<'/users/{ID}/roles'>];
  USER_SET_ROLE: [Post<'/users/{ID}/set_role'>];
  USER_UNSET_ROLE: [Post<'/users/{ID}/unset_role'>];
  USER_SET_PASSWORD: [Post<'/users/{ID}/set_password'>];
  USER_SETTINGS: [Post<'/users/{ID}/set_settings'>];
  USER_UPDATE_EMAIL: [Post<'/users/{ID}/update_email'>];
  USER_SET_STATUS: [Post<'/users/{ID}/set_status'>];
  USER_REVOKE_TOKENS: [Post<'/users/{ID}/revoke_tokens'>];
  USER_SET_PHONE: [Post<'/users/{ID}/set_phone'>];
  USER_ANONYMIZE: [Delete<'/users/{ID}/anonymize'>];
  USER_REFERRAL_INFO: [Get<'/users/{ID}/referral_info'>];
  USER_CUSTOMER_INFO: [
    Get<'/users/{ID}/customer_info', ApiSchema['payment.Customer']>,
  ];
  RFID_PRODUCTS: [Get<'/rfid/products', ApiSchema['user.RfidProduct'][]>];
  RFID_PRODUCTS_COUNT: [Get<'/rfid/products/count'>];
  USER_RFID_TAG: [Post<'/users/{ID}/rfid/tag'>, Delete<'/users/{ID}/rfid/tag'>];
  USER_RFID_SESSION: [Post<'/users/{ID}/rfid/session'>];
  USER_PAYMENT_METHOD: [
    Post<'/users/{ID}/payment_method'>,
    Delete<'/users/{ID}/payment_method/{PaymentMethodID}'>,
  ];
  USER_SET_DEFAULT_PAYMENT_METHOD: [
    Post<'/users/{ID}/set_default_payment_method'>,
  ];
  USER_API_KEYS: [
    Get<'/users/api_keys', ApiKey[]>,
    Post<'/users/{ID}/api_keys'>,
    Patch<'/users/api_keys/{ID}'>,
    Delete<'/users/api_keys/{ID}'>,
  ];
  USER_API_KEYS_COUNT: [Get<'/users/api_keys/count'>];
}

const usersModule = new StoreModule<UserStoreTypes>();
usersModule
  .createResourceAndAction({
    type: 'USERS',
    requestEndPoint: '/users',
    permission: 'gateway.user.list',
    scopes: usersScopes,
    requestOptions: () => {
      const { isGranted } = usePrivileges();
      return {
        freeze: true,
        params: {
          fields: isGranted('gateway.user.read.gdpr_fields')
            ? userFields
            : gdprSafeUserFields,
        },
      };
    },
    populate: [inUsePopulateConfig()],
    transform: data => data.users,
  })
  .createResourceAndAction({
    type: 'USERS_COUNT',
    requestEndPoint: '/users/count',
    scopes: [...usersScopes, 'DailyMetricsAll', 'DailyMetricsBefore'],
  })
  .createResourceAndAction({
    type: 'USER',
    requestEndPoint: '/users/:id',
    transform: data => data.user,
    populate: [
      inUsePopulateConfig(),
      {
        permission: 'gateway.user.read.customer_fields',
        dataField: 'id',
        dataType: String,
        actionType: 'GET_AREAS',
        queryField: 'id',
        responseFields: [{ key: 'label', as: 'label' }],
        onCollectionNames: ['areas'],
        cacheTimeout: 24 * 60 * 60 * 1000, // areas are unlikely to change
      },
      internalEmailPopulateConfig({
        dataField: 'created_by',
        onCollectionNames: ['notes'],
      }),
      userEmailPopulateConfig({
        dataField: 'referred_by',
        asKey: 'referrer',
        inject: true,
      }),
      {
        dataField: 'id',
        dataType: String,
        actionType: 'GET_BENEFITS',
        queryField: 'target.id',
        responseFields: [
          { key: ['id', 'name', 'status', 'type'], as: 'subscriptions' },
        ],
        additionalQuery: {
          obtention_method_type: OfferObtentionMethodType.Subscription,
          status: {
            $in: [
              BenefitStatus.Active,
              BenefitStatus.NeedsPayment,
              BenefitStatus.Paused,
              BenefitStatus.PendingValidation,
            ],
          },
        },
        responseIsArray: true,
        noCache: true,
      },
      scopePopulateConfig(),
      {
        dataField: 'rfid_product_id',
        onCollectionNames: ['rfid_tags'],
        dataType: String,
        actionType: 'GET_RFID_PRODUCTS',
        queryField: 'id',
        responseFields: [{ key: 'card', inject: true }],
        cacheTimeout: 24 * 60 * 60 * 1000, // rfid products are unlikely to change
      },
    ],
  })
  .createResourceAndAction({
    type: 'LOGIN_USER',
    requestEndPoint: '/users/:id',
    requestOptions: () => {
      const { userId } = useAuth();
      return {
        id: userId.value,
      };
    },
    transform: data => data.user,
  })
  .createResourceAndAction({
    type: 'USER_PERMANENT_OTP_SESSION',
    requestMethod: 'GET',
    requestEndPoint: '/auth/permanent_otp_session',
    transform: data => data.code,
  })
  .createAction({
    type: 'USER_PERMANENT_OTP_SESSION',
    requestEndPoint: '/auth/permanent_otp_session',
    requestMethod: 'POST',
    transform: data => data.code,
  })
  .createAction({
    type: 'USER_PERMANENT_OTP_SESSION',
    requestEndPoint: '/auth/permanent_otp_session',
    requestMethod: 'DELETE',
  })
  .createAction({
    type: 'USER_EDIT_ROLES',
    requestEndPoint: '/users/:id/roles',
    requestMethod: 'PATCH',
  })
  .createAction({
    type: 'USER_SET_ROLE',
    requestEndPoint: '/users/:id/set_role',
    requestMethod: 'POST',
    permission: 'gateway.user.set_role',
  })
  .createAction({
    type: 'USER_UNSET_ROLE',
    requestEndPoint: '/users/:id/unset_role',
    requestMethod: 'POST',
    permission: 'gateway.user.unset_role',
  })
  .createAction({
    type: 'USER_SET_PASSWORD',
    requestEndPoint: '/users/:id/set_password',
    requestMethod: 'POST',
    permission: 'gateway.user.set_password',
  })
  .createAction({
    type: 'USERS',
    requestEndPoint: '/users',
    requestMethod: 'POST',
    permission: 'gateway.user.add',
  })
  .createAction({
    type: 'USER',
    requestEndPoint: '/users/:id',
    requestMethod: 'PATCH',
    permission: 'gateway.user.patch',
  })
  .createAction({
    type: 'USER_SETTINGS',
    requestEndPoint: '/users/:id/set_settings',
    requestMethod: 'POST',
    permission: 'gateway.user.set_settings',
  })
  .createAction({
    type: 'USER_UPDATE_EMAIL',
    requestEndPoint: '/users/:id/update_email',
    requestMethod: 'POST',
    permission: 'gateway.user.update_email',
  })
  .createAction({
    type: 'USER_SET_STATUS',
    requestEndPoint: '/users/:id/set_status',
    requestMethod: 'POST',
    permission: 'gateway.user.set_status',
  })
  .createAction({
    type: 'USER_REVOKE_TOKENS',
    requestEndPoint: '/users/:id/revoke_tokens',
    requestMethod: 'POST',
    permission: 'gateway.user.revoke_tokens',
  })
  .createAction({
    type: 'USER_SET_PHONE',
    requestEndPoint: '/users/:id/set_phone',
    requestMethod: 'POST',
    permission: 'gateway.user.set_phone',
  })
  .createAction({
    type: 'USER_ANONYMIZE',
    requestEndPoint: '/users/:id/anonymize',
    requestMethod: 'DELETE',
    permission: 'gateway.user.anonymize',
  })
  /*
   * Referral info
   */
  .createResourceAndAction({
    type: 'USER_REFERRAL_INFO',
    requestEndPoint: '/users/:id/referral_info',
    permission: 'gateway.user.get_referral_info',
    transform: data => data.data,
  })
  /*
   * Customer info
   */
  .createResourceAndAction({
    type: 'USER_CUSTOMER_INFO',
    requestEndPoint: '/users/:id/customer_info',
    permission: 'gateway.user.get_customer_info',
    transform: data => data.customer,
  })
  // RFID
  .createResourceAndAction({
    type: 'RFID_PRODUCTS',
    requestEndPoint: '/rfid/products',
    permission: 'gateway.rfid.get_products',
    requestOptions: () => ({
      params: {
        fields: rfidProductFields,
      },
    }),
    transform: data => data.rfid_products,
  })
  .createResourceAndAction({
    type: 'RFID_PRODUCTS_COUNT',
    requestEndPoint: '/rfid/products/count',
  })
  .createAction({
    type: 'USER_RFID_TAG',
    requestEndPoint: '/users/:id/rfid/tag',
    requestMethod: 'POST',
    permission: 'gateway.user.add_rfid_tag',
  })
  .createAction({
    type: 'USER_RFID_TAG',
    requestEndPoint: '/users/:id/rfid/tag',
    requestMethod: 'DELETE',
    permission: 'gateway.user.remove_rfid_tag',
  })
  .createAction({
    type: 'USER_RFID_SESSION',
    requestEndPoint: '/users/:id/rfid/session',
    requestMethod: 'POST',
    permission: 'gateway.user.create_rfid_session',
  })
  // payment methods
  .createAction({
    type: 'USER_PAYMENT_METHOD',
    requestEndPoint: '/users/:id/payment_method',
    requestMethod: 'POST',
    permission: 'gateway.user.add_payment_method',
    longRequest: true,
    requestOptions: () => ({
      timeout: stripeConfig.timeout,
    }),
  })
  .createAction({
    type: 'USER_PAYMENT_METHOD',
    requestEndPoint: '/users/:id/payment_method/:payment_method_id',
    requestMethod: 'DELETE',
    permission: 'gateway.user.remove_payment_method',
  })
  .createAction({
    type: 'USER_SET_DEFAULT_PAYMENT_METHOD',
    requestEndPoint: '/users/:id/set_default_payment_method',
    requestMethod: 'POST',
    permission: 'gateway.user.set_default_payment_method',
  })
  .createResourceAndAction({
    type: 'USER_API_KEYS',
    requestEndPoint: '/users/api_keys',
    permission: 'gateway.user.get_api_keys',
    requestOptions: () => ({
      params: {
        fields: apiKeyFields,
      },
    }),
    transform: data => data.api_keys,
  })
  .createResourceAndAction({
    type: 'USER_API_KEYS_COUNT',
    requestEndPoint: '/users/api_keys/count',
  })
  .createAction({
    type: 'USER_API_KEYS',
    requestEndPoint: '/users/:id/api_keys',
    requestMethod: 'POST',
    permission: 'gateway.user.create_api_keys',
    transform: data => data.key,
  })
  .createAction({
    type: 'USER_API_KEYS',
    requestEndPoint: '/users/api_keys/:id',
    requestMethod: 'PATCH',
    permission: 'gateway.user.update_api_keys',
  })
  .createAction({
    type: 'USER_API_KEYS',
    requestEndPoint: '/users/api_keys/:id',
    requestMethod: 'DELETE',
    permission: 'gateway.user.delete_api_keys',
  });

export default usersModule;
