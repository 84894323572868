/*
 * the vue-router object of our app
 */

import Vue from 'vue';
import VueRouter from 'vue-router';

import routes from '@/router/routes';
import { beforeEachGuard } from '@/router/guards';

import type { Route } from 'vue-router';

Vue.use(VueRouter);

const _push = VueRouter.prototype.push;
VueRouter.prototype.push = async function (to): Promise<Route> {
  try {
    return await _push.bind(this)(to);
  } catch {
    return this.currentRoute;
  }
};

const _replace = VueRouter.prototype.replace;
VueRouter.prototype.replace = async function (to): Promise<Route> {
  try {
    return await _replace.bind(this)(to);
  } catch {
    return this.currentRoute;
  }
};

// the router instance
const router = new VueRouter({
  mode: 'history', // enable browser navigation
  routes,
});

// global navigation guard
router.beforeEach(beforeEachGuard);

export default router;
