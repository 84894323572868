export default {
  /**
   * Checks if the device has touch capabilities
   */
  hasTouch(): boolean {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
  },
  /**
   * Checks if the device is an iOS device
   */
  isIOS(): boolean {
    return (
      window.navigator && /iphone|ipad|ipod/i.test(window.navigator.userAgent)
    );
  },
  /**
   * Checks if the app is running in standalone mode
   */
  isStandalone(): boolean {
    return 'standalone' in window.navigator && !!window.navigator.standalone;
  },
  /**
   * Checks if the device supports WebRTC
   */
  hasWebRTC(): boolean {
    return (
      window.navigator &&
      window.navigator.mediaDevices &&
      !!window.navigator.mediaDevices.getUserMedia
    );
  },
  /**
   * Checks if the app is the ZoovMaintenance app
   * TODO: remove when deprecate is fully complete
   */
  isMaintenanceApp(): boolean {
    return (
      window.navigator && /ZoovMaintenance/.test(window.navigator.userAgent)
    );
  },
  /**
   * Checks if the device is an Android device
   */
  isAndroid(): boolean {
    return window.navigator && /android/i.test(window.navigator.userAgent);
  },
  /**
   * Checks if the browser is Firefox
   */
  isFirefox(): boolean {
    return window.navigator && /firefox/i.test(window.navigator.userAgent);
  },
  /**
   * Check if the device is a mobile device
   */
  isMobile(): boolean {
    return this.hasTouch() && (this.isAndroid() || this.isIOS());
  },
};
