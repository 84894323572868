import { register } from 'register-service-worker';
import { version } from '@@/package.json';

export interface UseServiceWorkerReturn {
  /*
   * Whether the service worker is registered
   */
  isRegistered: Ref<boolean>;
  /*
   * Whether the service worker is ready to serve the app
   */
  isReady: Ref<boolean>;
  /*
   * Whether the service worker is cached for offline use
   */
  isCached: Ref<boolean>;
  /*
   * Whether an update is available
   */
  isUpdateAvailable: Ref<boolean>;
  /*
   * Whether the user is offline
   */
  isOffline: Ref<boolean>;
  /*
   * Error message of the service worker
   */
  errorMessage: Ref<Error | null>;
  /*
   * The registration instance
   */
  registration: Ref<ServiceWorkerRegistration | null>;
  /*
   * The new version of Control available
   */
  newVersionAvailable: Ref<string>;
}

export function useServiceWorker(): UseServiceWorkerReturn {
  const isRegistered = ref(false);
  const isReady = ref(false);
  const isCached = ref(false);
  const isUpdateAvailable = ref(false);
  const isOffline = ref(false);
  const errorMessage = ref<Error | null>(null);
  const registration = ref<ServiceWorkerRegistration | null>(null);
  const newVersionAvailable = ref(version);

  function askSw(
    message: string,
    serviceWorker?: ServiceWorker | null
  ): Promise<string> {
    return new Promise(function (resolve, reject) {
      // Create a Message Channel
      const messageChannel = new MessageChannel();
      // Handler for receiving message reply from service worker
      messageChannel.port1.onmessage = function (event) {
        if (event.data.error) {
          reject(event.data.error);
        } else {
          resolve(event.data);
        }
      };
      // If serviceWorker is passed, we use it to post a message,
      // otherwise we use the current service worker via its controller
      const controller = serviceWorker
        ? serviceWorker
        : navigator.serviceWorker.controller;
      // Send message to service worker along with port for reply
      if (controller) {
        controller.postMessage(message, [messageChannel.port2]);
      }
    });
  }

  async function registered(
    _registration: ServiceWorkerRegistration
  ): Promise<void> {
    isRegistered.value = true;
    registration.value = _registration;
  }

  async function ready(): Promise<void> {
    isReady.value = true;
  }

  async function cached(
    _registration: ServiceWorkerRegistration
  ): Promise<void> {
    isCached.value = true;
    registration.value = _registration;
  }

  async function updated(
    _registration: ServiceWorkerRegistration
  ): Promise<void> {
    const waitingSwVersion: string = await askSw(
      'version',
      _registration.waiting
    );

    isUpdateAvailable.value = true;
    newVersionAvailable.value = waitingSwVersion;
    registration.value = _registration;
  }

  async function offline(): Promise<void> {
    isOffline.value = true;
  }

  async function error(_error: Error): Promise<void> {
    errorMessage.value = _error;
  }

  if (import.meta.env.MODE === 'production') {
    register('/sw.js', {
      registered,
      ready,
      cached,
      updated,
      offline,
      error,
    });
  }

  return {
    isRegistered,
    isReady,
    isCached,
    isUpdateAvailable,
    isOffline,
    errorMessage,
    registration,
    newVersionAvailable,
  };
}
