export enum ProductVersion {
  None = 0,
  Zoov01 = 1,
  Zoov02 = 2,
  Edock01 = 3,
  Zoov03 = 4,
  Flex = 5,
  Proto = 6,
  Fusion2 = 7,
}
