// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type PartialActionSettings = Partial<ActionSettings<any>>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyMethod = (...args: any[]) => any;

function mergeMethods(
  methodA?: AnyMethod | null,
  methodB?: AnyMethod | null
): AnyMethod | undefined {
  if (!methodA && !methodB) return;
  if (!methodA) return methodB!;
  if (!methodB) return methodA;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return function mergedCalls(...args: any[]) {
    const responseA = methodA(...args);
    const responseB = methodB(...args);
    return responseA ?? responseB;
  };
}

/**
 * Merge two action settings into one, the second one having priority over the first one.
 * The action controls are added together, the synchronous hooks are called in order.
 * @param actionSettingsA - the first action settings
 * @param actionSettingsB - the second action settings
 */
export default function mergeActionSettings(
  actionSettingsA: PartialActionSettings,
  actionSettingsB: PartialActionSettings
): PartialActionSettings {
  // merge settings
  const settings: PartialActionSettings = {
    ...actionSettingsA,
    ...actionSettingsB,
  };
  // merge controls
  settings.controls = [
    ...(actionSettingsA.controls ?? []),
    ...(actionSettingsB.controls ?? []),
  ];
  // merge synchronous hooks
  settings.onCancel = mergeMethods(
    actionSettingsA.onCancel,
    actionSettingsB.onCancel
  );
  settings.onSuccess = mergeMethods(
    actionSettingsA.onSuccess,
    actionSettingsB.onSuccess
  );
  settings.onError = mergeMethods(
    actionSettingsA.onError,
    actionSettingsB.onError
  );
  settings.onInput = mergeMethods(
    actionSettingsA.onInput,
    actionSettingsB.onInput
  );
  settings.onChangeStep = mergeMethods(
    actionSettingsA.onChangeStep,
    actionSettingsB.onChangeStep
  );
  settings.onDrop = mergeMethods(
    actionSettingsA.onDrop,
    actionSettingsB.onDrop
  );
  settings.onOpen = mergeMethods(
    actionSettingsA.onOpen,
    actionSettingsB.onOpen
  );
  settings.onEnd = mergeMethods(
    actionSettingsA.onEnd,
    actionSettingsB.onEnd /**/
  );
  return settings;
}
