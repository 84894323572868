/* eslint-disable @typescript-eslint/no-explicit-any */
import clone from 'fast-copy';

import store from '@/store';
import { apiConfig } from '@/config';
import { useNotification } from '@/composables';
import { usePrivileges } from '@/composables/plugins';

import type { ActionType } from '@/store';

export interface PopulateConfig {
  /**
   * Label to identify the populate config, useless in the populate logic itself but used
   * in the parent store-module core action to select populate configs
   */
  name?: string;
  /**
   * Permission required to perform the populate call
   */
  permission?: string;
  /**
   * Origin response field. Use __self__ to use the raw response
   * _e.g._ `{ has_more: boolean, stations: [] }` > `stations`
   * @default __self__
   */
  dataName?: string;
  /**
   * Origin item field on which populate
   * _e.g._ to populate products (bikes) for a fastener, it would be `products`
   */
  onCollectionNames?: string[];
  /**
   * Origin field identifier used in MongoDB query to populate the `onCollectionNames` field
   * It could be an object key, or __self__ if the `onCollectionNames` is not an array of objects
   * _e.g._ `id` or `serial_number`
   */
  dataField?: string;
  /**
   * `dataField` data type
   */
  dataType: StringConstructor | NumberConstructor;
  /**
   * Type of the GET action.
   */
  actionType: ActionType<'GET'>;
  /**
   * Field to query in the target item.
   * _e.g._ to populate user, use id it will build a MongoDB query like: `{ id: { $in: [<dataField>, <dataField>] }`
   */
  queryField: string;
  /**
   * Target response field. Use __self__ to use the raw response
   * @default __self__
   */
  responseDataName?: string;
  /**
   * Timeout of the cache in ms
   */
  cacheTimeout?: number;
  /**
   * Fields to fetch for the populated field
   */
  responseFields: {
    /**
     * Key(s) picked from the response field
     */
    key: string | string[];
    /**
     * Alias for the response field
     */
    as?: string;
    /**
     * Inject the populated data into an existing field.
     * _e.g._ replace `{  area: "<area_id>"}` to `{ area: { __injected: 'label', label: '', __originalData: "<area_id>", __populated: true } }`
     */
    inject?: boolean;
  }[];
  /**
   * Additional Mongo query for the populate action
   */
  additionalQuery?: any;
  /**
   * Prevent Vuex store caching
   */
  noCache?: boolean;
  /*
   * Sort response
   */
  sortBy?: string;
  /**
   * Instead of building a `query` querystring parameter, use the `dataField` value as a path parameter
   * _.e.g._ if true, the URL will be : /bikes/<dataField>, else it will be : /bikes?query={<queryField>:{$in: <dataField>}}
   */
  asPathParameter?: boolean;
  /**
   * Extra condition of population on every item of the origin resource data
   */
  condition?: (originItem: any, originData: any) => boolean;
  /**
   * Keep the response in array format
   */
  responseIsArray?: boolean;
  /**
   * Whether the populate call is made to a /count endpoint
   */
  isCount?: boolean;
}

interface PopulatedFieldBase<OriginalData = any> {
  __originalData: OriginalData;
  __loading?: boolean;
  __error?: Error;
  __empty?: boolean;
  __linkParams?: any;
}

/**
 * Structure of a populated field.
 * @extends PopulatedFieldBase
 * @template OriginalData - Original data type
 * @template PopulatedData - Populated data type
 * @example PopulatedField<ApiSchema['rental.Rental']['user_id'], ApiSchema['user.User']['email']>;
 */
export type PopulatedField<
  OriginalData = any,
  PopulatedData = any,
> = PopulatedFieldBase<OriginalData> & {
  __data?: PopulatedData;
};

/**
 * Structure of a populated field that injects data into the original one.
 * @extends PopulatedFieldBase
 * @template OriginalData - Original data type
 * @template InjectedData - Injected data type
 * @example PopulatedInjectedField<ApiSchema['rental.Rental']['user_id'], { user: ApiSchema['user.User']['email'] }>;
 */
export type PopulatedInjectedField<
  OriginalData = any,
  InjectedData = Record<string, any>,
> = PopulatedFieldBase<OriginalData> & {
  __injected: keyof InjectedData;
} & InjectedData;

/**
 * Structure of a populated field as array.
 * @extends PopulatedFieldBase
 * @template OriginalData - Original data type
 * @template InjectedData - Injected data type
 * @example PopulatedFieldArray<ApiSchema['user.User']['user_id'], { benefits: ApiSchema['payment.Benefit'] }>;
 */
export type PopulatedFieldArray<
  OriginalData = any,
  PopulatedData = any,
> = Partial<PopulatedFieldBase<OriginalData>> &
  Array<PopulatedField<OriginalData, PopulatedData>>;

/**
 * Get a field value of an object (item)
 * @param item - Object on which to get the field value
 * @param field - Field name of the wanted value
 */
function getValue(item: any, field: PopulateConfig['dataField']): any {
  const value = (field ?? '')
    .replace('__self__', '')
    .split('.')
    .filter(key => key)
    .reduce((cleanedItem, key) => cleanedItem?.[key], item);

  return [null, undefined].includes(value)
    ? value
    : value.__originalData || value;
}

/**
 * Add a field to an object, and set it a value. This function includes processing / clean of the field
 * @param item - Item on which add a value
 * @param field - Field name to add on the item
 * @param value - Value to set the field name
 */
function setValue(item: any, field: string | undefined, value: any): void {
  (field ?? '')
    .replace('__self__', '')
    .split('.')
    .filter(key => key)
    .reduce((updatedItem, key, i, array) => {
      if (i === array.length - 1 && updatedItem) updatedItem[key] = value;
      else if (typeof updatedItem[key] !== 'object') updatedItem[key] = {};
      return updatedItem?.[key];
    }, item);
}

/**
 * Format the populated field value
 * @param item - Original item data
 * @param loading - Populate loading state
 * @param error - Error
 * @param empty - Whether if the populate response is empty or not
 * @returns Formatted populated value
 */
function preparePopulatedFieldValue(
  originalData: any,
  loading?: boolean,
  error?: Error,
  empty?: boolean
): PopulatedField {
  const _originalData =
    typeof originalData === 'object'
      ? originalData
      : { __originalData: originalData, __populated: true };

  return Object.assign(
    _originalData || { __populated: true },
    loading ? { __loading: true } : { __loading: false },
    error ? { __error: error } : {},
    !loading && empty ? { __empty: true } : {}
  );
}

/**
 * Build the populate request query, as a $in filter MongoDB query, based on a field and array of values
 * @param queryField - Field to query
 * @param queryArray - Array of values of the $in
 * @param additionalQuery - Additional MongoDB query
 * @returns Stringified MongoDB query
 */
function createRequestQuery(
  queryField: string,
  queryArray: string[] | number[],
  additionalQuery: MongoQuery = {}
): string | null {
  if (!queryField) return null;
  return JSON.stringify({
    [queryField]: { $in: queryArray },
    ...additionalQuery,
  });
}

/**
 * Prepare the populate request, then make the populate call
 * @param resource
 * @param populateConfig
 */
function populateCall(
  resource: any,
  populateConfig: PopulateConfig
): Promise<{
  populateConfig: PopulateConfig;
  error?: Error;
  response?: any;
}> {
  try {
    // shorthands
    const {
      permission,
      dataName = '__self__',
      onCollectionNames,
      dataField,
      dataType,
      asPathParameter,
      actionType,
      queryField,
      responseFields,
      additionalQuery,
      sortBy,
      cacheTimeout,
      noCache,
      condition,
      isCount,
    } = populateConfig;

    const { isGranted } = usePrivileges();

    // Check if user has the granted privileges for the populate call
    if (!isGranted(permission)) {
      // resolve without API call, with empty response
      return Promise.resolve({ populateConfig });
    }

    // Select data on which we want to perform populate call
    const baseOriginResourceData =
      dataName === '__self__' ? resource : resource[dataName];
    let originResourceData: any[] = baseOriginResourceData;

    // Check if data is defined, otherwise do nothing
    if (!originResourceData) {
      return Promise.resolve({ populateConfig });
    }

    // If data is not an array, wrap it
    if (!Array.isArray(originResourceData)) {
      originResourceData = [originResourceData];
    }

    // iterate over the onCollectionNames and flatten nested arrays of data
    if (onCollectionNames) {
      onCollectionNames.forEach(collectionName => {
        originResourceData = originResourceData.reduce(
          (flattenOriginResourceData, item) => {
            const collection = getValue(item, collectionName);
            return collection && Array.isArray(collection)
              ? flattenOriginResourceData.concat(collection)
              : flattenOriginResourceData;
          },
          []
        );
      });
    }

    // Remove empty string or null or undefined values
    originResourceData = originResourceData.filter(item => {
      return !['', null, undefined].includes(getValue(item, dataField));
    });

    const queryArray = originResourceData
      // Filter-out queries when condition not is fulfilled (if given) for each item
      .filter(item => !condition || condition(item, baseOriginResourceData))
      // Aggregate into one single request to avoid multiple requests and potential chain reaction
      .reduce<string[]>((queryArrayAccumulator, item) => {
        // Get the item’s dataField value and coerce it into the required type
        const value = getValue(item, dataField);
        const queryValues = Array.isArray(value) ? value : [value];
        queryValues.forEach(queryValue => {
          queryArrayAccumulator.push(
            asPathParameter ? queryValue : dataType(queryValue)
          );
        });
        return queryArrayAccumulator;
      }, []);

    if (queryArray.length) {
      const queryArrayUnique = [...new Set(queryArray)];

      // Create query as a string
      const query = createRequestQuery(
        queryField,
        queryArrayUnique,
        additionalQuery
      );

      // Filter out `__self__` fields
      const fields = (responseFields || [])
        .reduce<string[]>((fields, entry) => fields.concat(entry.key), [])
        .concat(queryField)
        .filter(field => field && field !== '__self__')
        .join(',');

      const queryParams = asPathParameter
        ? {}
        : {
            ...(query && { query }),
            ...(fields && { fields }),
            ...(sortBy && { sort: sortBy }),
            limit: apiConfig.populateResponseLimit,
          };

      // If we use input values as param, we need to make multiple API calls
      // thus we wrap our call in a promise.all and unwrap the response
      return Promise.all(
        (asPathParameter || isCount
          ? queryArrayUnique
          : [/* at least one item, the value is irrelevant here */ ':-)']
        ).map(value => {
          const asPathParameterOptions: Record<string, string> = {};
          if (asPathParameter) asPathParameterOptions[queryField] = value;

          const requestOptions = {
            ...asPathParameterOptions,
            params: queryParams,
            preventStore: true,
            preventPopulate: true,
          };

          return (noCache ? store : store.cache)
            .dispatch(actionType, requestOptions, {
              timeout: cacheTimeout,
            })
            .then((response: any) => {
              if (asPathParameter || isCount) {
                // make sure the response includes the queryField value,
                // so that it can be matched in populate process later
                return {
                  [queryField]: value,
                  ...response,
                };
              } else {
                return response;
              }
            })
            .catch((error: Error) => {
              // if an error occurred, clear cache so that call can be retriggered
              if (!noCache) {
                store.cache.delete(actionType, requestOptions);
              }
              throw error;
            });
        })
      )
        .then(response => {
          return {
            populateConfig,
            // unwrap response if not in param mode
            response: asPathParameter ? response : response[0],
          };
        })
        .catch(error => ({ populateConfig, error }));
    } else {
      // resolve without API call, with empty response
      return Promise.resolve({ populateConfig });
    }
  } catch (error) {
    return Promise.resolve({
      populateConfig,
      error,
    } as {
      populateConfig: PopulateConfig;
      error: Error;
    });
  }
}

/**
 *
 * @param resource - Origin request response to populate
 * @param populate - Array of populate config
 */
export async function populateResource(
  resource: any,
  populateConfigs: PopulateConfig[],
  loadingPlaceholder?: boolean
): Promise<any> {
  const { notify } = useNotification();

  // Work on a deep copy of the resource to avoid unwanted side effects on the original one
  let clonedResource = clone(resource);

  const populateCallResponses = loadingPlaceholder
    ? // Fake data population for loading placeholder
      populateConfigs.map(populateConfig => {
        const responseDataName = populateConfig.responseDataName ?? '__self__';

        return {
          populateConfig,
          response:
            responseDataName !== '__self__' ? { [responseDataName]: '' } : '',
          error: undefined,
        };
      })
    : await Promise.all(
        populateConfigs.map(populateConfig =>
          populateCall(clonedResource, populateConfig)
        )
      );

  populateCallResponses.forEach(({ populateConfig, error, response }) => {
    if (error) {
      console.warn('populate', error);
      notify({
        type: 'error',
        service: 'populate',
        message:
          'Failed to populate data: ' +
          (populateConfig.responseFields || [])
            .map(({ key, as }) => '`' + key + (as ? '` as `' + as : '') + '`')
            .join(', ') +
          ' via ' +
          populateConfig.actionType,
      });
    }

    const {
      dataName = '__self__',
      onCollectionNames,
      dataType,
      dataField,
      queryField,
      responseDataName = '__self__',
      responseFields,
      responseIsArray,
      condition,
    } = populateConfig;

    const baseOriginResourceData =
      dataName === '__self__' ? clonedResource : clonedResource[dataName];
    let originResourceData: any[] = baseOriginResourceData;
    if (!originResourceData) return;

    const isOriginResourceDataArray = Array.isArray(originResourceData);
    // Wrap resource data into array for processing
    if (!isOriginResourceDataArray) {
      originResourceData = [originResourceData];
    }

    // assign new data to the resource
    originResourceData = originResourceData.map(originResourceItem => {
      (responseFields || []).forEach(responseField => {
        const newEntryName =
          responseField.as ||
          (Array.isArray(responseField.key)
            ? responseField.key.join('_')
            : responseField.key);

        const targetField = responseField.inject ? dataField : newEntryName;

        // only if response is a new entry (not injected) or if value exists
        if (
          !responseField.inject ||
          getValue(originResourceItem, targetField)
        ) {
          const isResponseEmpty =
            // response should not be flagged as empty when it is not targeted
            // by populate processing due to a condition on the data
            (!condition ||
              condition(originResourceItem, baseOriginResourceData)) &&
            (!response ||
              (responseDataName !== '__self__' && !response[responseDataName]));

          if (!onCollectionNames) {
            setValue(
              originResourceItem,
              targetField,
              preparePopulatedFieldValue(
                getValue(originResourceItem, dataField),
                loadingPlaceholder,
                error,
                isResponseEmpty
              )
            );
          } else {
            onCollectionNames.forEach(collectionName => {
              const collection: any[] = getValue(
                originResourceItem,
                collectionName
              );
              if (!collection) return;

              collection.forEach(collectionItem => {
                setValue(
                  collectionItem,
                  targetField,
                  preparePopulatedFieldValue(
                    getValue(collectionItem, dataField),
                    loadingPlaceholder,
                    error,
                    isResponseEmpty
                  )
                );
              });
            });
          }
        }

        if (error || !response) return;

        let responseDataCollection: any[] =
          responseDataName === '__self__'
            ? response
            : response[responseDataName];
        if (!responseDataCollection) return;

        const isResponseDataCollectionArray = Array.isArray(
          responseDataCollection
        );
        if (
          !isResponseDataCollectionArray &&
          Object.keys(responseDataCollection).length === 0
        ) {
          return;
        }

        if (!isResponseDataCollectionArray) {
          responseDataCollection = [responseDataCollection];
        }

        /**
         * Operate on item or on item[onCollectionNames] if onCollectionNames is provided.
         * This would corresponds to an other array of items on which we performed
         * populating (see populateCall function), or even nested arrays of object
         * @param item - Item to mutate
         * @param onCollectionNameIndex - Index of the on collection name array
         */
        function traverseAndMutate(
          item: any,
          onCollectionNameIndex: number
        ): void {
          let mutateAggregation = [item];
          const collectionName = onCollectionNames
            ? onCollectionNames[onCollectionNameIndex]
            : null;
          if (collectionName) {
            mutateAggregation = getValue(item, collectionName);
          }

          if (!mutateAggregation) return;

          // add `newEntryName` entry to item
          mutateAggregation.forEach(aggregationItem => {
            if (onCollectionNames?.[onCollectionNameIndex + 1]) {
              // if we populated on nested arrays of object, re-operate `mutate` function
              traverseAndMutate(aggregationItem, onCollectionNameIndex + 1);
            } else {
              // otherwise add new entry to item
              // data field of item is __self__
              const aggregationItemDataField = getValue(
                aggregationItem,
                dataField
              );

              // handle the case when our item data field is an array or not by wrapping
              const isAggregationItemDataFieldArray = Array.isArray(
                aggregationItemDataField
              );
              const iterableAggregationItemDataField =
                isAggregationItemDataFieldArray
                  ? aggregationItemDataField
                  : [aggregationItemDataField];

              const newEntry: any[] = [];

              iterableAggregationItemDataField.forEach(
                aggregationItemDataFieldElement => {
                  responseDataCollection.forEach(responseData => {
                    // handle nested mongo queryFields such as 'a.b.c'
                    const responseDataField = getValue(
                      responseData,
                      queryField
                    );

                    // get value handling the case of subsequent inject (__originalData already defined)
                    let aggregationItemDataFieldElementValue =
                      aggregationItemDataFieldElement;
                    let alreadyInjected = false;
                    if (aggregationItemDataFieldElement?.__originalData) {
                      aggregationItemDataFieldElementValue =
                        aggregationItemDataFieldElement.__originalData;
                      alreadyInjected = true;
                    }

                    // response data field value must match aggregated item data field value in the coerced type.
                    // if it is an array, aggregated item data field value must be found in this array.
                    if (
                      responseDataField ===
                        dataType(aggregationItemDataFieldElementValue) ||
                      (Array.isArray(responseDataField) &&
                        responseDataField.includes(
                          dataType(aggregationItemDataFieldElementValue)
                        ))
                    ) {
                      // Format data if we have multiple responseField keys
                      const data = Array.isArray(responseField.key)
                        ? responseField.key.reduce<Record<string, string>>(
                            (acc, key) => {
                              setValue(acc, key, getValue(responseData, key));
                              return acc;
                            },
                            {}
                          )
                        : getValue(responseData, responseField.key);

                      const preparedOutput = alreadyInjected
                        ? aggregationItemDataFieldElement
                        : {
                            __originalData:
                              aggregationItemDataFieldElementValue,
                            __populated: true,
                          };

                      if (responseField.inject) {
                        const pushedData = { __injected: newEntryName };
                        setValue(pushedData, newEntryName, data);
                        newEntry.push({
                          ...pushedData,
                          ...preparedOutput,
                        });
                      } else {
                        newEntry.push({
                          __data: data,
                          ...preparedOutput,
                        });
                      }
                    }
                  });
                }
              );

              // If aggregation item data field is not an array, make new entry not an array
              // but if response is flagged as array, keep the array
              if (newEntry.length) {
                const output =
                  isAggregationItemDataFieldArray || responseIsArray
                    ? newEntry
                    : newEntry[0];
                output.__populated = true;

                setValue(
                  aggregationItem,
                  responseField.inject ? dataField : newEntryName,
                  output
                );
              }
            }
          });
        }

        // Perform the mutation on the item
        traverseAndMutate(originResourceItem, 0);
      });

      return originResourceItem;
    });

    // Unwrap if data was not an array
    if (!isOriginResourceDataArray) {
      originResourceData = originResourceData[0];
    }

    // Assign data to our resource
    if (dataName === '__self__') {
      clonedResource = originResourceData;
    } else {
      clonedResource[dataName] = originResourceData;
    }
  });

  return clonedResource;
}
