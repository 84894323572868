interface StatusAction {
  text: string;
  function: () => void | Promise<void>;
}

interface AppStatus {
  show: boolean;
  icon: string;
  content: string;
  progress: number;
  color: string;
  progressColor: string;
  action?: StatusAction;
}

const statusState = reactive<AppStatus>({
  show: false,
  icon: '',
  content: '',
  progress: Infinity,
  color: '',
  progressColor: '',
  action: undefined,
});

export function useAppStatus(): AppStatus {
  return statusState;
}
