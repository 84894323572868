/* eslint-disable @typescript-eslint/no-explicit-any */

import { PolicyPolicyType } from '#/core-api/enums';

import type { ThemeColor } from '@/themes';
import type { MapArea } from '@/models/area/map/types';

// Unallowed area is just a string (encoded polyline)
type UnallowedArea = ArrayElement<Area['geo_areas_unallowed']>;

// Area policy is an object with a policy type, max speed and encoded polyline
type AreaPolicy = ArrayElement<Area['policies']>;

// Intervention zone is an object with an encoded polyline and a label
export type InterventionZone = ArrayElement<
  NonNullable<Area['extra_properties']>['intervention_zones']
>;

type SubAreaMap = {
  'unallowed-area': UnallowedArea;
  'area-policy': AreaPolicy;
  'intervention-zone': InterventionZone;
};

export type SubArea = {
  [U in keyof SubAreaMap]: { type: U; data: SubAreaMap[U] };
}[keyof SubAreaMap];

export type ManageSubAreaActionMode = 'add' | 'edit' | 'remove';

export type SubAreaType = keyof SubAreaMap;

export function getAreaSubAreas(
  area: Area,
  type: SubAreaType
): SubArea[] | null {
  switch (type) {
    case 'unallowed-area':
      return (area.geo_areas_unallowed ?? []).map(data => ({
        type,
        data,
      }));
    case 'area-policy':
      return (area.policies ?? []).map(data => ({ type, data }));
    case 'intervention-zone':
      return (area.extra_properties?.intervention_zones ?? []).map(data => ({
        type,
        data,
      }));
    default:
      return null;
  }
}

export function getSelectedAreas(areas: Area[], ids: string[]): Area[] {
  return !Array.isArray(ids) // check if array, also checks if not null or undefined
    ? []
    : !ids.length // no id selected <=> all areas
      ? areas
      : areas.filter(area => ids.some(id => id === area.id));
}

export function areaPolicyText(policy: ArrayElement<Area['policies']>): string {
  switch (policy.type) {
    default:
    case PolicyPolicyType.NotSet:
      return JSON.stringify(policy);
    case PolicyPolicyType.SpeedLimit:
      // Convert the speed from bike max_speed unit (0.1 km/h) to km/h
      return `${(policy.max_speed ?? 0) / 10} km/h`;
  }
}

export function getSubAreaEncodedPolyline(subArea: SubArea): string {
  switch (subArea.type) {
    case 'unallowed-area':
      return subArea.data;
    case 'area-policy':
      return subArea?.data.encoded_coordinates as string;
    case 'intervention-zone':
      return subArea?.data.encoded_polyline as string;
  }
}

export function getSubAreaActionColor(type: SubAreaType): ThemeColor {
  switch (type) {
    default:
    case 'unallowed-area':
      return 'error';
    case 'area-policy':
      return 'warning_alt1';
    case 'intervention-zone':
      return 'success_darker2';
  }
}

export function getSubAreaActionIcon(
  type: SubAreaType,
  actionMode: ManageSubAreaActionMode
): string {
  switch (type) {
    default:
    case 'unallowed-area':
      return `svg:unallowed_shape_${actionMode}`;
    case 'area-policy':
      return `svg:policy_shape_${actionMode}`;
    case 'intervention-zone':
      return `svg:intervention_zone_${actionMode}`;
  }
}

export function buildSubArea(
  type: SubAreaType,
  encodedPolyline: string,
  data: Record<string, any> & SubAreaMap[SubAreaType]
): SubArea {
  switch (type) {
    default:
    case 'unallowed-area':
      return { type, data: encodedPolyline };
    case 'area-policy':
      return {
        type,
        data: {
          encoded_coordinates: encodedPolyline,
          max_speed: data.max_speed,
          type: data.type,
        },
      };
    case 'intervention-zone':
      return {
        type,
        data: {
          encoded_polyline: encodedPolyline,
          label: data.label,
        },
      };
  }
}

export function buildSubAreasPayload(
  subAreas: SubArea['data'][],
  type: SubAreaType
): Record<string, any> {
  switch (type) {
    case 'unallowed-area':
      return {
        geo_areas_unallowed: subAreas,
      };
    case 'area-policy':
      return {
        policies: subAreas,
      };
    case 'intervention-zone':
      return {
        extra_properties: {
          intervention_zones: subAreas,
        },
      };
    default:
      return {};
  }
}

export function getAreasInterventionZones(
  areas: Area[] | MapArea[]
): (InterventionZone & {
  areaId: string;
})[] {
  return areas.flatMap(area =>
    (area.extra_properties?.intervention_zones ?? []).map(interventionZone => ({
      ...interventionZone,
      areaId: area.id,
    }))
  );
}

export function generateInterventionZoneId(
  areaId: string,
  index: number
): string {
  return `area-${areaId}-intervention-zone-${index}`;
}
