import StoreModule from '@/store/core/store-module';
import { debugConfig, mapboxConfig } from '@/config';
import { parseJwt } from '@/lib/utils';

import type { Get } from '@/store/types/core-api';

interface LoginData {
  id?: string;
  email?: string;
  roles?: string[];
  token?: string;
}

export interface LoginStoreTypes {
  APP_LOGGED_IN: [{ data: boolean }];
  LOGIN_DATA: [{ data: LoginData }];
  LOGIN: [
    {
      method: 'POST';
      payload: { data: { email: string; password: string } };
      response: { token: string };
      data: string;
    },
  ];
  REFRESH_TOKEN: [
    {
      method: 'POST';
      response: { token: string };
      data: string;
    },
  ];
  LOGOUT: [{ method: 'POST' }];
  USER_TOKEN: [
    {
      method: 'GET';
      response: { token: string };
      data: string;
    },
  ];
  AUTH_STATUS: [
    {
      method: 'GET';
      response: { is_authenticated: boolean };
      data: boolean;
    },
  ];
  AUTH_SCOPES: [Get<'/auth/scopes', Scope[]>];
  AUTH_SCOPE: [Get<'/auth/scopes/{Id}'>];
  AUTH_PRIVILEGES: [Get<'/auth/privileges', Privilege[]>];
}

const loginModule = new StoreModule<LoginStoreTypes>();
loginModule
  .createResource({
    type: 'APP_LOGGED_IN',
    defaultValue: debugConfig.bypassLogin,
  })
  .createResource({
    type: 'LOGIN_DATA',
    defaultValue: debugConfig.bypassLogin ? debugConfig.defaultLoginData : {},
  })
  .createAction({
    type: 'LOGIN',
    needsAuth: false,
    requestApiConfig: {
      endpoint: '/api',
    },
    transform: data => data.token,
    requestEndPoint: '/auth/login',
    requestMethod: 'POST',
    onSuccess(token, context) {
      context.commit('LOGIN_DATA', {
        data: { ...parseJwt(token), token },
      });
      context.commit('APP_LOGGED_IN', { data: true });
    },
  })
  .createAction({
    type: 'REFRESH_TOKEN',
    needsAuth: false,
    requestApiConfig: {
      endpoint: '/api',
    },
    transform: data => data.token,
    requestMethod: 'POST',
    requestEndPoint: '/auth/refresh',
    onSuccess(token, context) {
      context.commit('LOGIN_DATA', {
        data: { ...parseJwt(token), token },
      });
      context.commit('APP_LOGGED_IN', { data: true });
    },
  })
  .createAction({
    type: 'LOGOUT',
    requestApiConfig: {
      endpoint: '/api',
    },
    requestEndPoint: '/auth/logout',
    requestMethod: 'POST',
    onSuccess(_, context) {
      // delete localStorage mapbox entry
      mapboxConfig.clearLocalStorageEntries.forEach(key => {
        localStorage.removeItem(key);
      });

      // reset store data regarding login
      context.commit('APP_LOGGED_IN', { data: false });
      context.commit('LOGIN_DATA', { data: {} });
    },
  })
  .createAction({
    type: 'USER_TOKEN',
    needsAuth: false,
    requestApiConfig: {
      endpoint: '/api',
    },
    transform: data => data.token,
    requestEndPoint: '/auth/token',
    onSuccess: async (token, context) => {
      context.commit('LOGIN_DATA', {
        data: { ...parseJwt(token), token },
      });
    },
  })
  .createAction({
    type: 'AUTH_STATUS',
    requestEndPoint: '/auth/status',
    requestApiConfig: {
      endpoint: '/api',
    },
    needsAuth: false,
    transform: data => data.is_authenticated,
  })
  .createResourceAndAction({
    type: 'AUTH_SCOPES',
    defaultValue: () => [],
    requestEndPoint: '/auth/scopes',
    transform: data => data.scopes,
  })
  .createResourceAndAction({
    type: 'AUTH_SCOPE',
    defaultValue: () => ({}),
    requestEndPoint: '/auth/scopes/:id',
  })
  .createResourceAndAction({
    type: 'AUTH_PRIVILEGES',
    defaultValue: () => [],
    requestEndPoint: '/auth/privileges',
    transform: data => data.data,
  });

export default loginModule;
