import { PolicyPolicyType, ServiceableAreaStatus } from '#/core-api/enums';

import { defaultEnumDisplayProperties } from '@/lib/utils';

export function areaPolicyType(type?: PolicyPolicyType): DisplayProperties {
  switch (type) {
    case PolicyPolicyType.NotSet:
      return {
        message: 'areas__policy__type_not_set',
        class: 'secondary',
      };
    case PolicyPolicyType.SpeedLimit:
      return {
        message: 'areas__policy__speed_limit',
        class: 'warning_alt1',
        icon: 'mdi-speedometer-slow',
      };
    default:
      return defaultEnumDisplayProperties(type);
  }
}

export function areaStatus(status?: ServiceableAreaStatus): DisplayProperties {
  switch (status) {
    case ServiceableAreaStatus.NotSet:
      return {
        message: 'areas__status__not_set',
        class: 'secondary',
        icon: 'mdi-help-circle',
      };
    case ServiceableAreaStatus.Active:
      return {
        message: 'areas__status__active',
        class: 'primary',
        icon: 'mdi-check-circle',
      };
    case ServiceableAreaStatus.Inactive:
      return {
        message: 'areas__status__inactive',
        class: 'disabled',
        icon: 'mdi-close-circle',
      };
    case ServiceableAreaStatus.Deleted:
      return {
        message: 'areas__status__deleted',
        class: 'error',
        icon: 'mdi-delete-circle',
      };
    default:
      return defaultEnumDisplayProperties(status);
  }
}
