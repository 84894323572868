import { PaymentMethodType } from '#/core-api/enums';

/**
 * Return the credit card brand formatted (icon, class and message) based on its name
 * @param name - Brand name, as in Stripe API
 * @see https://stripe.com/docs/api/payment_methods/object#payment_method_object-card-brand
 */
export function creditCardBrand(name?: string): DisplayProperties {
  switch (name) {
    case 'visa':
      return {
        message: 'Visa',
        icon: 'svg:fds__visa',
        class: 'self-color',
      };
    case 'amex':
      return {
        message: 'American Express',
        icon: 'svg:fds__american_express',
        class: 'self-color',
      };
    case 'mastercard':
      return {
        message: 'MasterCard',
        icon: 'svg:fds__mastercard',
        class: 'self-color',
      };
    case 'unionpay':
      return {
        message: 'Union Pay',
        icon: 'svg:fds__unionpay',
        class: 'self-color',
      };
    case 'diners':
      return {
        message: 'Diners Club',
        icon: 'svg:fds__diners_club',
        class: 'self-color',
      };
    case 'jcb':
      return {
        message: 'JCB',
        icon: 'svg:fds__jcb',
        class: 'self-color',
      };
    case 'discover':
      return {
        message: 'Discover',
        icon: 'svg:fds__discover',
        class: 'self-color',
      };
    case 'unknown':
    default:
      return {
        message: 'Unknown',
      };
  }
}

export function creditCardType(type?: PaymentMethodType): DisplayProperties {
  switch (type) {
    case PaymentMethodType.Card:
      return {
        message: 'Card',
      };
    case PaymentMethodType.Paypal:
      return {
        message: 'PayPal',
        icon: 'svg:paypal',
      };
    case PaymentMethodType.GooglePay:
      return {
        message: 'Google Pay',
        icon: 'svg:google_pay',
      };
    case PaymentMethodType.ApplePay:
      return {
        message: 'Apple Pay',
        icon: 'svg:apple_pay',
      };
    case PaymentMethodType.Unknown:
    default:
      return {
        message: 'Unknown',
      };
  }
}
