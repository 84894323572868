import dateFormat from 'dateformat';
import { circle } from '@turf/circle';
import { distance } from '@turf/distance';
import { midpoint } from '@turf/midpoint';
import { notNullish } from '@fifteen/shared-lib';

import theme from '@/themes';
import { appConfig } from '@/config';
import { computeBounds, hasCoordinates } from '@/lib/utils';
import { useQueryParameter, useAlert } from '@/composables';
import { useMapbox } from '@/composables/map';
import store from '@/store';

import type { ApiSchema } from '#/core-api';
import type { Route } from 'vue-router';
import type { MapVehicle } from '@/models/vehicle/map/types';

const vehicleFields = [
  'maintenance_state',
  'id',
  'is_reserved',
  'is_rented',
  'vehicle_state',
  'location',
] satisfies ModelField<'entity.Vehicle'>[];

type VehicleHistoryItem = DatacenterHistoryItem<
  Pick<
    ApiSchema['entity.Vehicle'] & { id: string },
    (typeof vehicleFields)[number]
  >
>;

interface VehicleUpdate {
  id: string;
  time: string;
  timestamp: string;
  prevTimestamp: string;
  historyItem: VehicleHistoryItem;
}

const basePeriod = appConfig.vehicleHistoryOnMapPeriod;
const emptyPolygon: GeoBundleFeature<GeoJSON.Polygon> = {
  type: 'Feature',
  geometry: { type: 'Polygon', coordinates: [] },
  properties: { id: 'vehicle-history-hole' },
};
const period = ref(basePeriod);
const loading = ref(false);
const limit = ref(15);
const selectedVehicleUpdate = ref<VehicleUpdate | null>(null);
const _isActive = ref(false);
const items = computed<VehicleHistoryItem[]>(() =>
  (store.getters['ENTITY_HISTORY']('Map')?.items ?? [])
    // API can return null items, so we must filter them out to avoid runtime errors
    .filter(notNullish)
);
const count = computed(() => items.value.length);

let _mapFitBounds: (
  coordinates: Coordinates[],
  options?: { animate: boolean }
) => Promise<void> = async () => {};

function clearData(): void {
  store.commit('ENTITY_HISTORY', { scope: 'Map', data: null });
  store.dispatch('GET_ENTITY_HISTORY', { cancel: 'silent' });
}

async function selectVehicleUpdate(id: string): Promise<void> {
  await nextTick();
  const historyIndex = Number(id.replace(/^vehicle-history-update-/, ''));
  const historyItem = items.value[historyIndex];
  const prevTimestamp =
    historyIndex > 0
      ? items.value[historyIndex - 1]?.timestamp
      : new Date().toISOString();
  selectedVehicleUpdate.value = {
    id,
    time: dateFormat(historyItem.timestamp, 'HH:MM:ss'),
    timestamp: historyItem.timestamp,
    prevTimestamp,
    historyItem,
  };
}

watch(_isActive, newVal => {
  if (!newVal) {
    selectedVehicleUpdate.value = null;
    // reset period
    period.value = basePeriod;
  }
});

export interface UseVehicleHistoryReturn {
  /**
   * The vehicle fields collected from vehicle history
   */
  vehicleFields: string[];
  /**
   * Whether the vehicle history is active.
   */
  isActive: Ref<boolean>;
  /**
   * Whether the vehicle history is loading.
   */
  loading: Ref<boolean>;
  /**
   * The current period of the vehicle history.
   */
  period: Ref<number>;
  /**
   * The limit of the vehicle history.
   */
  limit: Ref<number>;
  /**
   * Number of fetched vehicle history items.
   */
  count: ComputedRef<number>;
  /**
   * The vehicles in the vehicle history.
   */
  vehicles: Ref<Vehicle[]>;
  /**
   * The geo bundles in the vehicle history.
   */
  geoBundles: ComputedRef<GeoBundle[]>;
  /**
   * The selected vehicle update in the vehicle history.
   */
  selectedVehicleUpdate: Ref<VehicleUpdate | null>;
  /**
   * The route to view the selected vehicle in history.
   */
  viewInHistoryRoute: ComputedRef<Route | null>;
  /**
   * The route to view all vehicles in history.
   */
  viewAllInHistoryRoute: ComputedRef<Route | null>;
  /**
   * The coordinates of the selected vehicle.
   */
  selectedVehicleUpdateCoordinates: ComputedRef<Coordinates | null>;
  /**
   * Select a vehicle update.
   */
  selectVehicleUpdate: (id: string) => void;
  /**
   * Add a period to the vehicle history.
   */
  addPeriod: () => void;
  /**
   * Fit the bounds of the vehicle history.
   */
  fitBounds: () => void;
  /**
   * Get the vehicle history data.
   */
  fetchData: () => Promise<void>;
  /**
   * Clear the vehicle history data.
   */
  clearData: () => void;
}

/**
 * Use vehicle history on map.
 * @param vehicle - The vehicle on which to display the history.
 * @param mapFitBounds - The methods to fit the bounds of the map.
 */
export function useVehicleHistory(
  vehicle: Ref<MapVehicle | null | undefined>,
  mapFitBounds?: (
    coordinates: Coordinates[],
    options?: { animate: boolean }
  ) => Promise<void>
): UseVehicleHistoryReturn {
  const router = useRouter();
  const alert = useAlert();
  const { mapboxgl } = useMapbox();

  const id = computed(() => vehicle.value?.id);
  if (mapFitBounds) _mapFitBounds = mapFitBounds;

  const isActive = useQueryParameter({
    name: 'history',
    type: Boolean,
    default: false,
    active: () => !!id.value,
    get: () => _isActive.value,
    set: value => (_isActive.value = value),
  });

  const vehicles = computed<MapVehicle[]>(() => {
    if (!items.value.length) return vehicle.value ? [vehicle.value] : [];
    return items.value
      .map((item, index) => {
        const time = dateFormat(item.timestamp, 'HH:MM:ss');
        return {
          ...item.collected,
          _featureProperties: {
            id: `vehicle-history-update-${index}`,
            type: 'vehicle-history-update',
            text: time,
          },
        } satisfies MapVehicle;
      })
      .filter(hasCoordinates);
  });

  const vehiclesCoordinates = computed(() =>
    vehicles.value.reduce<Coordinates[]>((_coordinates, vehicle) => {
      if (vehicle.location?.coordinates) {
        _coordinates.push(vehicle.location.coordinates);
      }
      return _coordinates;
    }, [])
  );

  const holeCircleCoordinates = computed(() => {
    if (
      !isActive.value ||
      !mapboxgl.value ||
      vehiclesCoordinates.value.length <= 1
    ) {
      return [];
    }

    const bounds = computeBounds(mapboxgl.value, vehiclesCoordinates.value);
    const swPoint = [bounds._sw.lng, bounds._sw.lat];
    const nePoint = [bounds._ne.lng, bounds._ne.lat];
    try {
      // The used turf methods bellow can throw if coordinates are improper
      const center = midpoint(swPoint, nePoint);
      const radius = Math.max((1150 * distance(swPoint, nePoint)) / 2, 5);
      const circleGeojson = circle(center, radius, {
        steps: 64,
        units: 'meters',
      });
      return circleGeojson.geometry.coordinates[0];
    } catch {
      return [];
    }
  });

  const holeGeojson = computed(() => {
    if (
      !isActive.value ||
      !mapboxgl.value ||
      vehiclesCoordinates.value.length <= 1
    ) {
      return emptyPolygon;
    }

    const feature: GeoBundleFeature<GeoJSON.Polygon> = {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-180, -90],
            [-180, 90],
            [180, 90],
            [180, -90],
            [-180, -90],
          ],
          holeCircleCoordinates.value,
        ],
      },
      properties: { id: 'vehicle-history-hole' },
    };
    return feature;
  });

  const geoBundles = computed<GeoBundle[]>(() => [
    {
      id: 'vehicle-history-links',
      source: {
        type: 'geojson',
        data: {
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: _isActive.value ? vehiclesCoordinates.value : [],
          },
          properties: { id: 'vehicle-history-links' },
        },
      },
      layers: [
        {
          type: 'line',
          layout: {
            'line-join': 'round',
            'line-cap': 'round',
          },
          paint: {
            'line-color': theme.other,
            'line-opacity': 1,
            'line-width': 2,
            'line-dasharray': [0.2, 1.8],
          },
        },
      ],
    },
    {
      id: 'vehicle-history-focus',
      source: {
        type: 'geojson',
        data: holeGeojson.value,
      },
      layers: [
        {
          type: 'fill',
          paint: {
            'fill-color': 'rgb(14, 14, 0)',
            'fill-opacity': ['case', ['get', '_dark'], 0.35, 0.15],
          },
        },
      ],
    },
  ]);

  const selectedVehicleUpdateCoordinates = computed(() => {
    const historyItem = selectedVehicleUpdate.value?.historyItem;
    if (!historyItem) return null;
    const coordinates = historyItem.collected.location?.coordinates;
    return coordinates ?? null;
  });
  const viewInHistoryRoute = computed(() => {
    if (!selectedVehicleUpdate.value || !id.value) return null;
    const fromDate = selectedVehicleUpdate.value.timestamp;
    const toDate = selectedVehicleUpdate.value.prevTimestamp;
    const newRoute = {
      name: 'Vehicle.Subsection',
      params: {
        id: String(id.value),
        subsection: 'history',
      },
      query: {
        range: [fromDate, toDate].join(',').replace(/:/g, '_'),
      },
    };
    return router.match(newRoute);
  });

  const viewAllInHistoryRoute = computed(() => {
    if (!id.value) return null;
    const now = Date.now();
    const fromDate = new Date(now - period.value).toISOString();
    const toDate = new Date(now).toISOString();
    const newRoute = {
      name: 'Vehicle.Subsection',
      params: {
        id: String(id.value),
        subsection: 'history',
      },
      query: {
        range: [fromDate, toDate].join(',').replace(/:/g, '_'),
      },
    };
    return router.match(newRoute);
  });

  function fitBounds(): void {
    _mapFitBounds(holeCircleCoordinates.value, {
      animate: true,
    });
  }

  async function fetchData(): Promise<void> {
    if (!id.value) return;
    loading.value = true;
    const now = Date.now();
    const [error] = await to(
      store.dispatch('GET_ENTITY_HISTORY', {
        id: id.value,
        scope: 'Map',
        params: {
          page: 1,
          limit: limit.value,
          from: new Date(now - period.value).toISOString(),
          to: new Date(now).toISOString(),
          mode: 'diff',
          fields: ['location'],
          collect: vehicleFields,
        },
      })
    );
    if (error) alert.error = error;
    loading.value = false;
    fitBounds();
  }

  function addPeriod(): void {
    period.value += basePeriod;
    fetchData();
  }

  return {
    vehicleFields,
    isActive,
    loading,
    period,
    limit,
    count,
    vehicles,
    geoBundles,
    selectedVehicleUpdate,
    viewInHistoryRoute,
    viewAllInHistoryRoute,
    selectedVehicleUpdateCoordinates,
    selectVehicleUpdate,
    addPeriod,
    fitBounds,
    fetchData,
    clearData,
  };
}
