<template lang="pug">
.ZInputPhoneNumber
  VAutocomplete(
    ref="countryCodeAutocompleteRef"
    v-model="countryCode"
    :label="label"
    :items="countries"
    :disabled="disabled"
    :color="color"
    dense
    :filter="autocompleteFilter"
    @input="resetPhoneNumberInput"
  )
    template(#selection="{ item }")
      .ZInputPhoneNumber__select__container
        img.ZInputPhoneNumber__select__flag(
          :src="`/img/icons/country-flag/${item.value}.svg`"
        )
        .ZInputPhoneNumber__select__country {{ item.value }}
        .ZInputPhoneNumber__select__code ({{ item.code }})
    template(#item="{ item }")
      .ZInputPhoneNumber__select__container.ZInputPhoneNumber__select__container--list
        img.ZInputPhoneNumber__select__flag(
          :src="`/img/icons/country-flag/${item.value}.svg`"
        )
        .ZInputPhoneNumber__select__country {{ item.value }}
        .ZInputPhoneNumber__select__code ({{ item.code }})

  VTextField.ZInputPhoneNumber__input(
    ref="phoneNumberInputRef"
    v-model="phoneInputValue"
    :rules="phoneNumberRules"
    :hint="hint"
    :messages="messages"
    :disabled="disabled"
    :color="color"
    :mask="phoneNumberMask"
    validate-on-blur
    @keydown.enter="blur"
    @blur="updateModelValue"
  )
</template>

<style lang="stylus">
.ZInputPhoneNumber
  display flex

  .v-input.v-text-field.v-autocomplete
    flex 0
    margin-right 12px

    .v-text-field__details
      display none

    .v-input__control > .v-input__slot
      min-width 100px
      width max-content

    .v-select__selections
      > input
        width 0

.ZInputPhoneNumber__select__container
  display flex
  align-items center
  display flex
  gap 4px

  &--list
    gap 2px
    font-size 12px
    color: $colors.grey.darken-1

    .ZInputPhoneNumber__select__country
      font-weight bold

.theme--dark
  .ZInputPhoneNumber__select__container--list
    color: $colors.grey.lighten-2

.ZInputPhoneNumber__select__flag
  width 26px
  margin-right 8px

.ZInputPhoneNumber__select__code
  width max-content
</style>

<script setup lang="ts">
import examples from 'libphonenumber-js/examples.mobile.json';
import {
  default as parse,
  getExampleNumber,
  getCountries,
  getCountryCallingCode,
  isValidNumberForRegion,
  Metadata,
} from 'libphonenumber-js';

import { useVModelProxy } from '@/composables';
import { useI18n } from '@/composables/plugins';
import { requiredRule } from '@/lib/helpers/rules';

import type { CountryCode as Alpha2CountryCode } from 'libphonenumber-js';

interface ZInputPhoneNumberProps {
  /**
   * The phone number value
   * @model
   */
  value?: string;
  /**
   * The label of the input
   */
  label?: string;
  /**
   * Check validity of the phone number
   */
  checkNumberValidity?: boolean;
  /**
   * Validate using the ENTER keyboard input
   */
  validateOnEnterKey?: boolean;
  /**
   * Disable the input
   */
  disabled?: boolean;
  /**
   * Color of the input
   */
  color?: string;
  /**
   * Make the input required
   */
  required?: boolean;
  /**
   * Pass additional rules to the input
   */
  rules?: VuetifyRule[];
  /**
   * The hint text, passed to the v-autocomplete
   */
  hint?: string;
  /**
   * The messages, passed to the v-autocomplete
   */
  messages?: string | string[];
}

const props = withDefaults(defineProps<ZInputPhoneNumberProps>(), {
  value: '',
  label: '',
  checkNumberValidity: true,
  validateOnEnterKey: true,
  disabled: false,
  color: 'primary',
  required: false,
  rules: () => [],
  hint: '',
  messages: '',
});

defineEmits<{
  (name: 'update:value', value: string | undefined): void;
}>();

const { t } = useI18n();

const countryCodeAutocompleteRef = ref();
const phoneNumberInputRef = ref();

const countryCode = ref<Alpha2CountryCode>();
const phoneInputValue = ref('');
const countries = ref(
  getCountries().map(country => ({
    value: country,
    code: `+${getCountryCallingCode(country)}`,
  }))
);

const modelValue = useVModelProxy({ props });

/**
 * Update the model value based on country code and phone number input
 */
function updateModelValue(): void {
  const parsedNumber = parse(phoneInputValue.value, countryCode.value);
  modelValue.value = parsedNumber?.number ?? '';
}

/**
 * Set the phone number and country code based on the model value
 */
function setPhoneNumberAndCountryCode(): void {
  const parsedNumber = parse(modelValue.value);
  phoneInputValue.value = parsedNumber?.nationalNumber ?? '';
  if (parsedNumber?.country) countryCode.value = parsedNumber?.country;
}

watchImmediate(modelValue, setPhoneNumberAndCountryCode);

const phoneNumberMask = computed(() => {
  if (!countryCode.value) return '';
  const exampleNumber = getExampleNumber(
    countryCode.value,
    examples
  )?.formatNational();
  if (!exampleNumber) return '';

  // Generate the mask from the example number
  const mask = exampleNumber?.replace(/^0/, '').replace(/[0-9]/g, '#');
  const numberOfDigitsInMask = mask?.match(/#/g)?.length ?? 0;

  // The example may be correct, but not enough to generate a mask that cover all possible numbers.
  // Indeed, some countries such as Austria (AT) supports phone numbers from 4 to 13 digits !
  // Get the maximum possible length from libphonenumber-js’s metadata
  const metadata = new Metadata();
  metadata.selectNumberingPlan(countryCode.value);
  const maxLength =
    metadata.numberingPlan
      ?.possibleLengths()
      // Order the length in reverse and get the first element which is the maximum length
      .sort((a, b) => (b > a ? 1 : b < a ? -1 : 0))[0] ?? numberOfDigitsInMask;

  // Add the remaining digits to the mask to reach the maximum length
  return mask?.concat('#'.repeat(maxLength - numberOfDigitsInMask));
});

const phoneNumberRules = computed<VuetifyRule[]>(() => [
  ...(props.required ? [requiredRule] : []),
  v => {
    return (
      !countryCode.value ||
      !props.checkNumberValidity ||
      !v ||
      isValidNumberForRegion(v, countryCode.value) ||
      t('rules__invalid_phone_number')
    );
  },
  ...props.rules,
]);

function autocompleteFilter(
  item: { value: string; code: string },
  queryText: string
): boolean {
  const escapedQuery = queryText.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  const regex = new RegExp(escapedQuery, 'i');
  return regex.test(item.code) || regex.test(item.value);
}

useEventListener('keypress', handleKeypress);

/**
 * Handle the keypress event and validate the phone number on Enter key
 * @param event - The keypress event
 */
function handleKeypress(event: KeyboardEvent): void {
  if (!props.validateOnEnterKey) return;
  if (event.key === 'Enter') updateModelValue();
}

/**
 * Reset the phone number input
 */
function resetPhoneNumberInput(): void {
  phoneNumberInputRef.value?.reset();
}

/**
 * Focus the phone number input
 */
function focus(): void {
  phoneNumberInputRef.value.focus();
}

/**
 * Blur the phone number input
 */
function blur(): void {
  phoneNumberInputRef.value.blur();
}

watch(countryCode, focus);

function resetValidation(): void {
  phoneNumberInputRef.value?.resetValidation?.();
}

defineExpose({
  /**
   * Focus the phone number input
   */
  focus,
  /**
   * Reset input validation
   */
  resetValidation,
});
</script>
