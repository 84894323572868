/*
 * This file defines preferences and its defaults
 */
import { appConfig, apiConfig } from '.';

import store from '@/store';
import { capitalize } from '@/lib/utils';
import {
  bikePriority,
  bikePriorityList,
} from '@/models/bike/mappers/display/bike';
import { useNavigation } from '@/composables';

/**
 * Convert hours to milliseconds
 * @param hours - number of hours
 */
function hToMs(hours: number): number {
  return hours * 60 * 60 * 1000;
}

export type PreferenceKey =
  | 'lang' /** @deprecated */
  | 'locale'
  | 'themeDark'
  | 'qrSounds'
  | 'mapDirectionsApp'
  | 'mapBatteryLevel'
  | 'mapBatterySwappingThreshold'
  | 'latLngOrder'
  | 'persistentFilters'
  | 'rowsPerPage' /** @deprecated */
  | 'pageSize'
  | 'bikesDefaultOpenTab'
  | 'bikesToCheckReallocationThreshold'
  | 'globalAreaUsage'
  | 'defaultLandingPage'
  | 'displayedBikesPriority' /** @deprecated */
  | 'displayedVehiclesPriority'
  | 'mapLwm2mUpdateAlert'
  | 'developerMode';

type HasIncorrectKey = false extends {
  [Key in PreferenceKey]: Key extends `${'preference' | 'preset'}:${string}`
    ? false
    : true;
}[PreferenceKey]
  ? true
  : false;
/**
 * A Typescript error will be thrown here if one of the key is incorrect.
 * Check that no key in {@link PreferenceKey} starts with 'preference:' or 'preset:'.
 */
const _hasIncorrectKey: HasIncorrectKey = false;

export interface PreferenceGroup {
  /**
   * Group name
   */
  name: string;
  /**
   * Group color
   */
  color?: string;
  /**
   * Show a description based on key
   */
  description?: boolean;
  /**
   * A permission to check whether the group can be shown in user settings
   */
  permission?: string;
  /**
   * Preference items
   */
  items: AnyPreferenceItem[];
}

export interface PreferenceDivider {
  /**
   * Show a divider between groups
   */
  divider: true;
}

export type PreferenceType = 'switch' | 'radio' | 'select' | 'slider' | 'none';

export type PreferenceValue<TypeT extends PreferenceType> =
  TypeT extends 'switch'
    ? boolean
    : TypeT extends 'radio' | 'none'
      ? boolean | string | number
      : TypeT extends 'slider'
        ? number
        : TypeT extends 'select'
          ? string | number
          : never;

export interface BasePreferenceItem<
  TypeT extends PreferenceType,
  ValueT = PreferenceValue<TypeT>,
> {
  /**
   * The preference unique key, which will be used to access its value
   */
  key: PreferenceKey;
  /**
   * A key that will be also available and stored on user, serving as a migration purpose
   */
  migrateKey?: string;
  /**
   * The preference default value, can be build with a factory. Providing a default is mandatory.
   */
  default: MaybeFactory<ValueT>;
  /**
   * A permission to check whether the preference can be shown in user settings
   */
  permission?: string;
  /**
   * The type of the preference
   */
  type?: TypeT;
  /**
   * Additional styles that are used in the shown preference in user settings
   */
  actionStyle?: string;
  /**
   * Whether the app should reload when this preference changes
   */
  reload?: boolean;
  /**
   * Whether the preference is disabled
   */
  disabled?: boolean;
  /**
   * A different color to use for the preference display
   */
  color?: Color;
}

type MaybeFactory<T> = (() => T) | T;

export interface PreferenceOption<ValueT> {
  text?: string | number;
  i18nKey?: string | I18nArgs;
  value: ValueT;
  color?: Color;
  icon?: string;
}

export type PreferenceItem<TypeT extends PreferenceType> = TypeT extends
  | 'switch'
  | 'none'
  ? BasePreferenceItem<TypeT, PreferenceValue<'switch' | 'none'>>
  : TypeT extends 'radio'
    ? BasePreferenceItem<TypeT, PreferenceValue<'radio'>> & {
        items?: MaybeFactory<PreferenceOption<PreferenceValue<'radio'>>[]>;
        preventItemsTranslation?: boolean;
      }
    : TypeT extends 'select'
      ? BasePreferenceItem<TypeT, PreferenceValue<'select'>> & {
          items?: MaybeFactory<PreferenceOption<PreferenceValue<'select'>>[]>;
          multiple?: boolean;
          selectAll?: boolean;
          selectAllText?: string;
          preventItemsTranslation?: boolean;
        }
      : TypeT extends 'slider'
        ? BasePreferenceItem<TypeT, PreferenceValue<'slider'>> & {
            min?: number;
            max?: number;
            step?: number;
            map?: number[];
            thumbLabels?: (string | number)[];
            thumb?: string;
            thumbSize?: number;
            tickLabels?: (string | number)[];
          }
        : never;

export type AnyPreferenceItem = PreferenceItem<PreferenceType>;

const appPreferences = {
  name: 'application',
  items: [
    {
      // Use alternate dark theme for eye comfort
      key: 'themeDark',
      type: 'switch',
      default: false,
    },
    {
      key: 'lang',
      default: 'en',
      type: 'select',
      // TODO: Swap key and migrateKey when all users have migrated
      migrateKey: 'locale',
      items: appConfig.availableLocales.map(locale => ({
        text: locale,
        value: locale,
      })),
    },
    {
      // Allow QR-code reader to emit a sound on scan
      key: 'qrSounds',
      type: 'switch',
      default: true,
    },
    {
      // Choose global area usage used by default
      key: 'globalAreaUsage',
      type: 'select',
      multiple: true,
      selectAll: true,
      selectAllText: 'All areas',
      items: () => {
        const areas = store.getters['AREAS']('App');
        return (areas ?? []).map(({ id, label }) => ({
          text: label ?? '',
          value: id ?? '',
        }));
      },
      default: '*',
      preventItemsTranslation: true,
    },
    {
      // Choose preferred page to be displayed by default after login
      key: 'defaultLandingPage',
      type: 'select',
      items: () => {
        const { items: navigationItems } = useNavigation();
        return navigationItems.value.map(({ displayedName, path }) => ({
          text: displayedName,
          value: path,
        }));
      },
      default: () => {
        const { items: navigationItems } = useNavigation();
        return (
          navigationItems.value.find(
            ({ name }) => name === appConfig.loginDefaultRedirectRoute
          )?.path ?? navigationItems.value[0]?.path
        );
      },
      preventItemsTranslation: true,
    },
  ],
} as const satisfies PreferenceGroup;

const mapBatteryLabels = ['Off', 2, 3, 4, 6, 8, 12];
const mapPreferences = {
  name: 'map',
  items: [
    {
      // Choose to which app you want to be redirected for map directions
      key: 'mapDirectionsApp',
      type: 'radio',
      items: [
        { text: 'Google Maps', value: 'gmaps' },
        { text: 'Waze', value: 'waze' },
      ],
      default: 'gmaps',
      actionStyle: 'min-width: 130px',
      preventItemsTranslation: true,
    },
    {
      // Display all bikes battery levels or only low battery
      key: 'mapBatteryLevel',
      type: 'slider',
      default: 1,
      actionStyle: 'min-width: 120px',
      thumbLabels: new Array(101).fill(0).map((_, i) => `${i}%`),
      thumb: 'always',
      thumbSize: 36,
      max: 100,
    },
    {
      // Threshold for bikes battery level to be considered as "to swap"
      key: 'mapBatterySwappingThreshold',
      type: 'slider',
      default: 20,
      actionStyle: 'min-width: 120px',
      thumbLabels: new Array(101).fill(0).map((_, i) => `${i}%`),
      thumb: 'always',
      thumbSize: 36,
      max: 100,
    },
    {
      // Select bikes priority on map displayed with a rhombus sign
      key: 'displayedBikesPriority',
      // TODO: Swap key and migrateKey when all users have migrated
      migrateKey: 'displayedVehiclesPriority',
      type: 'select',
      default: '1,4,5,6',
      multiple: true,
      items: bikePriorityList.map(value => ({
        i18nKey: bikePriority(value).message,
        icon: bikePriority(value).icon,
        color: bikePriority(value).class,
        value: String(value),
      })),
      actionStyle: 'min-width: 140px',
    },
    {
      // Display alert near battery level if last update is greater (in hours)
      key: 'mapLwm2mUpdateAlert',
      type: 'slider',
      max: 6,
      step: 1,
      map: [Infinity, 2, 3, 4, 6, 8, 12].map(hToMs), // in ms
      tickLabels: mapBatteryLabels,
      thumbLabels: mapBatteryLabels,
      permission: 'admin.bike.lwm2m_update_alert',
      default: Infinity,
      actionStyle: 'min-width: 120px',
    },
  ],
} as const satisfies PreferenceGroup;

const dataPreferences = {
  name: 'data',
  items: [
    {
      // Choose which order you prefer for location coordinates
      key: 'latLngOrder',
      type: 'radio',
      items: [
        { text: 'Lat - Lng (Google)', value: true },
        { text: 'Lng - Lat (GeoJSON)', value: false },
      ],
      default: true,
      actionStyle: 'min-width: 100px',
      preventItemsTranslation: true,
    },
    {
      // Remember filters when navigating across sections
      key: 'persistentFilters',
      type: 'switch',
      default: true,
    },
    {
      // Number of items per page displayed in data tables (the smaller, the faster)
      key: 'rowsPerPage',
      // TODO: Swap key and migrateKey when all users have migrated
      migrateKey: 'pageSize',
      type: 'select',
      items: appConfig.pageSizeOptions.map(v => ({
        text: String(v),
        value: v,
      })),
      default: apiConfig.responseLimit,
      actionStyle: 'min-width: 120px',
    },
  ],
} as const satisfies PreferenceGroup;

const bikesPreferences = {
  name: 'bikes',
  items: [
    {
      // Choose preferred tab to be open by default when displaying a bike
      key: 'bikesDefaultOpenTab',
      type: 'select',
      items: [
        'issues',
        'data',
        'board_info',
        'bookings',
        'trips',
        'history',
      ].map(name => ({
        text: name.split('_').map(capitalize).join(' '),
        value: name,
      })),
      default: 'issues',
      reload: true,
      preventItemsTranslation: true,
    },
    {
      // Bikes to check + reallocation filter, no-trip-since threshold can be parametrized here
      key: 'bikesToCheckReallocationThreshold',
      type: 'select',
      items: appConfig.noTripSince.map(value => ({
        text: value + 'h',
        value,
      })),
      default: appConfig.noTripSince[0],
    },
  ],
} as const satisfies PreferenceGroup;

const sensibleZonePreferences = {
  name: 'sensible zone',
  color: 'error',
  description: true,
  permission: 'admin.preferences.sensible_zone',
  items: [
    {
      key: 'developerMode',
      type: 'switch',
      default: false,
    },
  ],
} as const satisfies PreferenceGroup;

export const syncPreferences: PreferenceGroup[] = [
  appPreferences,
  mapPreferences,
  dataPreferences,
  bikesPreferences,
];

export const asyncPreferences = [
  { key: 'globalAreaUsage', type: 'select' },
  { key: 'defaultLandingPage', type: 'select' },
  { key: 'displayedBikesPriority', type: 'select' },
  { key: 'mapLwm2mUpdateAlert', type: 'slider' },
] as const;

type PreferenceItems =
  | (typeof appPreferences)['items'][number]
  | (typeof mapPreferences)['items'][number]
  | (typeof dataPreferences)['items'][number]
  | (typeof bikesPreferences)['items'][number]
  | (typeof sensibleZonePreferences)['items'][number];

type Item<Key extends PreferenceKey> = Extract<
  PreferenceItems,
  { key: Key } | { migrateKey: Key }
>;

export type PreferenceValueType<Key extends PreferenceKey> =
  Item<Key>['type'] extends 'radio' | 'select'
    ? 'items' extends keyof Item<Key>
      ? Item<Key>['items'] extends () => infer R
        ? 'value' extends keyof ArrayElement<R>
          ? ArrayElement<R>['value']
          : never
        : 'value' extends keyof ArrayElement<Item<Key>['items']>
          ? ArrayElement<Item<Key>['items']>['value']
          : never
      : never
    : PreferenceValue<Item<Key>['type']>;

export type PreferenceConfig = PreferenceGroup | PreferenceDivider;

export const availablePreferences: PreferenceConfig[] = [
  appPreferences,
  mapPreferences,
  dataPreferences,
  bikesPreferences,
  { divider: true },
  sensibleZonePreferences,
];
