/**
 * Picks specific keys from an object.
 * @param obj - The input object.
 * @param keys - An array of keys to pick from the object.
 * @returns A new object containing only the selected key-value pairs.
 */
export default function objectPickKeys(
  obj: Record<string, unknown>,
  keys: string[]
): Pick<typeof obj, (typeof keys)[number]> {
  return keys.reduce<Pick<typeof obj, (typeof keys)[number]>>((result, key) => {
    if (obj.hasOwnProperty(key)) {
      result[key] = obj[key];
    }
    return result;
  }, {});
}
