<template lang="pug">
VTextField.ZActionControlInput(
  v-show="canShow"
  ref="componentRef"
  v-model="modelValue"
  :style="style"
  :class="classes"
  :label="label"
  :rules="rules"
  :color="color"
  :disabled="isDisabled"
  :hint="hint"
  :messages="message"
  :hide-details="areDetailsHidden"
  v-bind="{ ...additionalProps, ...attributes }"
  :autocapitalize="autocapitalize"
  :placeholder="placeholder"
  :mask="mask"
  :type="control.password && !showPassword ? 'password' : 'text'"
  clearable
)
  template(#append)
    template(v-if="control.password")
      VIcon.ZActionControlInput__icon--copy(
        v-if="showPassword"
        @click="showPassword && copyInputValue(control)"
      ) mdi-content-copy
      VIcon.ZActionControlInput__icon--eye(
        @click="showPassword = !showPassword"
      ) {{ eyeIcon }}
    template(v-if="appendIcon")
      ZIcon(:class="appendIconClasses") {{ appendIcon }}
</template>

<style lang="stylus">
.ZActionControlInput--hidePassword
  input
    -webkit-text-security disc

.ZActionControlInput__icon--copy
  margin-right 8px
  margin-left 4px
  cursor pointer

.ZActionControlInput__icon--eye
  cursor pointer
</style>

<script setup lang="ts">
import { useActionControl } from './useActionControl';

import { useI18n } from '@/composables/plugins';
import { useAlert } from '@/composables';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Data = any;

interface ZActionControlInputProps {
  /**
   * The control object
   */
  control: ActionControl<'input', keyof Data, Data>;
  /**
   * The parent action 'data' object
   */
  data: Data;
  /**
   * The control’s value
   * @model
   */
  value?: string | number;
}

const props = withDefaults(defineProps<ZActionControlInputProps>(), {
  value: '',
});

const {
  modelValue,
  generate,
  canShow,
  style,
  label,
  rules,
  color,
  isDisabled,
  hint,
  placeholder,
  mask,
  message,
  areDetailsHidden,
  additionalProps,
} = useActionControl(props);

const componentRef = ref<TemplateRef | null>(null);

const { t } = useI18n();
const alert = useAlert();

const showPassword = ref(false);
const eyeIcon = computed(() =>
  showPassword.value ? 'mdi-eye' : 'mdi-eye-off'
);
const classes = computed(() => ({
  'ZActionControlInput--hidePassword': props.control.password && !showPassword,
}));
const attributes = computed(() =>
  props.control.type === Number ? { pattern: '\\d*' } : {}
);
const autocapitalize = computed(() =>
  props.control.autocapitalize ? 'on' : 'off'
);
const appendIcon = computed(() => generate(props.control.appendIcon));
const appendIconClasses = computed(() => props.control.appendIconClasses);

/**
 * Copy the value of the input control to the clipboard
 */
function copyInputValue(
  control: ActionControl<'input', keyof Data, Data>
): void {
  const value = props.data[control.key] as string;
  if (value) {
    navigator.clipboard.writeText(value);
    alert.message = t('action__copy_success', { text: value });
    alert.type = 'success';
  }
}

function focus(): void {
  componentRef.value?.focus?.();
}

function resetValidation(): void {
  componentRef.value?.resetValidation?.();
}

defineExpose({
  /**
   * Focus the input
   */
  focus,
  /**
   * Whether the password is shown
   */
  showPassword,
  /**
   * Reset input validation
   */
  resetValidation,
});
</script>
