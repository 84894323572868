export const dashboardIcon = 'mdi-monitor-dashboard';
export const mapIcon = 'mdi-map';
export const bikeIcon = 'mdi-bicycle-basket';
export const vehicleIcon = 'mdi-atv';
export const stationIcon = 'svg:station';
export const fastenerIcon = 'svg:fastener';
export const userIcon = 'mdi-account-supervisor';
export const tripIcon = 'mdi-chart-timeline-variant';
export const rentalIcon = 'mdi-script-text';
export const benefitIcon = 'mdi-gift';
export const bookingIcon = 'mdi-bookmark';
export const areaIcon = 'mdi-earth';
export const offerIcon = 'mdi-tag';
export const offerPolicyIcon = 'mdi-file-code';
export const pointOfInterestIcon = 'mdi-star-four-points';
export const issueIcon = 'mdi-clipboard-text';
export const roleIcon = 'mdi-shield-account';
export const scopeIcon = 'mdi-select-group';
