<template lang="pug">
ZSwipe(
  allowed-directions="x"
  is-fixed
  @throwout="close"
)
  VSnackbar.ZAlert(
    top
    :value="show"
    :color="computedType"
    :timeout="timeout"
    light
    multi-line
    @input="close"
  )
    VIcon.ZAlert__icon {{ computedIcon }}
    .ZAlert__wrapper
      .ZAlert__content
        .ZAlert__message
          ZErrorMessage(
            v-if="error"
            :error="error"
          )
          span(v-else) {{ computedMessage }}
        .ZAlert__detail(v-if="detail") {{ detail }}
      VBtn.ZAlert__action(
        v-for="(action, i) in computedActions"
        :key="'z-alert-action-' + i"
        small
        dark
        :ripple="!isTouchDevice"
        :color="action.color"
        @click="execute(action)"
      ) {{ action.text }}
    VBtn.ZAlert__closeBtn(
      icon
      light
      :ripple="!isTouchDevice"
      @click="close"
    )
      VIcon mdi-close
</template>

<style lang="stylus">
.ZAlert
  .v-snack__wrapper
    max-width none
    color: $colors.grey.darken-4

  .ZAlert__icon
    margin-right 12px

  .ZAlert__wrapper
    display flex
    align-items center
    max-width calc(100% - 80px)

    .ZAlert__message
      width 100%

    .ZAlert__detail
      margin-top 4px

  &.v-snack--multi-line .v-snack__content
    padding 16px 24px
    height auto

    .v-btn.ZAlert__closeBtn
      margin-left 0
      color black

    .ZAlert__message,
    .ZAlert__action
      margin-right 24px

    +media-down-xs()
      height auto
      min-height 56px
      padding 12px

      .ZAlert__message
        margin-right 12px

      .ZAlert__action
        margin-right 6px

      .ZAlert__closeBtn
        margin-right -6px
</style>

<script setup lang="ts">
import { RequestError } from '@/composables/useRequest';
import { check } from '@/lib/utils';
import { useI18n } from '@/composables/plugins';

import ZErrorMessage from '@/components/ui/atoms/ZErrorMessage.vue';
import ZSwipe from '@/components/ui/molecules/ZSwipe.vue';

import type AppError from '@/lib/classes/app-error';

export interface ZAlertProps {
  /**
   * An error to be displayed in the alert. Will set the alert in `type` error.
   */
  error?: AppError | RequestError | Error | null;
  /**
   * The message to be displayed in the alert.
   */
  message?: string;
  /**
   * The type of the alert. This could be used to determine the style or icon of the alert.
   */
  type?: 'success' | 'warning' | 'error' | 'info';
  /**
   * The actions that can be performed from the alert, with a button. Each action includes text, color, and an exec function.
   */
  actions?: AlertAction[];
  /**
   * The icon of the alert. If not provided, the icon will be determined by the `type` prop.
   */
  icon?: string | null;
}

export interface AlertAction {
  /**
   * The text of the action button.
   */
  text: string;
  /**
   * The color of the action button.
   */
  color?: string;
  /**
   * The function to be executed when the action button is clicked.
   * @param close - A callback to close the alert.
   */
  exec: (close: () => void) => void | Promise<void>;
}

const props = withDefaults(defineProps<ZAlertProps>(), {
  error: null,
  message: '',
  type: 'info',
  icon: null,
  actions: () => [],
});

const emit = defineEmits<{
  (name: 'closed'): void;
  (name: 'update:error', error: ZAlertProps['error']): void;
  (name: 'update:message', message: ZAlertProps['message']): void;
  (name: 'update:actions', actions: ZAlertProps['actions']): void;
}>();

const { t } = useI18n();

const initialType = 'info';
const initialIcon = 'mdi-information';
const initialMessage = computed(() => t('error__internal_error'));
const initialActions: AlertAction[] = [];
const timeout = 5000;

const isTouchDevice = check.hasTouch();
const show = computed<boolean>(() => !!props.error || !!props.message);

const defaultType = ref(initialType);
const defaultIcon = ref(initialIcon);
const defaultMessage = ref(initialMessage.value);
const defaultActions = ref<AlertAction[]>(initialActions);

const computedType = computed<string>(() =>
  props.error ? 'error' : (props.type ?? defaultType.value)
);

const computedIcon = computed<string>(() => {
  if (props.icon) return props.icon;
  switch (computedType.value) {
    case 'error':
      return 'mdi-alert-octagon';
    case 'warning':
      return 'mdi-alert';
    default:
      return defaultIcon.value;
  }
});

const computedMessage = computed<string>(() => {
  return props.message || defaultMessage.value;
});

const detail = computed(() => {
  if (
    !props.error ||
    !(props.error instanceof RequestError) ||
    !props.error.detail
  ) {
    return;
  }
  return t('error__details') + props.error.detail;
});

const computedActions = computed(() => props.actions);

function close(): void {
  defaultType.value = computedType.value;
  defaultIcon.value = computedIcon.value;
  defaultMessage.value = computedMessage.value;
  defaultActions.value = computedActions.value;
  reset();
}

function reset(): void {
  emit('update:error', null);
  emit('update:message', '');
  emit('update:actions', []);

  // wait for animation to finish before resetting values
  setTimeout(() => {
    defaultType.value = initialType;
    defaultIcon.value = initialIcon;
    defaultMessage.value = initialMessage.value;
    defaultActions.value = initialActions;
    emit('closed');
  }, 1000);
}

function execute(action: AlertAction): void {
  if (action.exec && typeof action.exec === 'function') {
    action.exec(close);
  }
}

watch(
  () => props.message,
  value => {
    if (value && props.error && props.type !== 'error') {
      emit('update:error', null);
    } else if (!value && !props.error) {
      close();
    }
  }
);

watch(
  () => props.error,
  value => {
    if (value && props.message) {
      emit('update:message', '');
    } else if (!value && !props.message) {
      close();
    }
  }
);
</script>
