import {
  OfferType,
  OfferObtentionMethodType,
  RuleType,
} from '#/core-api/enums';

import { OfferCategory } from '@/models/offer/enums';
import { tripIcon } from '@/config/icons';
import { defaultEnumDisplayProperties } from '@/lib/utils';

export function offerType(type?: OfferType): DisplayProperties {
  switch (type) {
    case OfferType.Undefined:
      return {
        message: 'offers__undefined_type',
        icon: 'mdi-help',
        class: 'secondary',
      };
    case OfferType.SharingPricing:
      return {
        message: 'offers__sharing_pricing',
        class: 'secondary_darker',
        icon: 'mdi-share-variant',
      };
    case OfferType.LeasingPricing:
      return {
        message: 'offers__leasing_pricing',
        icon: 'svg:leasing_contract',
        class: { light: 'other', dark: 'other_lighter' },
      };
    case OfferType.Discount:
      return {
        message: 'offers__discount',
        icon: 'mdi-percent-box-outline',
        class: 'warning',
      };
    case OfferType.Pack:
      return {
        message: 'offers__prepaid_pack',
        icon: 'mdi-package-variant-closed',
        class: 'primary',
      };
    default:
      return defaultEnumDisplayProperties(type);
  }
}

export function offerObtentionMethodType(
  type?: OfferObtentionMethodType
): DisplayProperties {
  switch (type) {
    case OfferObtentionMethodType.Undefined:
      return {
        message: 'offers__undefined_type',
      };
    case OfferObtentionMethodType.SingleImmediateCapture:
      return {
        message: 'offers__single_immediate_capture',
      };
    case OfferObtentionMethodType.Subscription:
      return {
        message: 'offers__subscription',
      };
    case OfferObtentionMethodType.SingleDeferredCapture:
      return {
        message: 'offers__single_deferred_capture',
      };
    case OfferObtentionMethodType.Manual:
      return {
        message: 'offers__manual',
      };
    case OfferObtentionMethodType.Pack:
      return {
        message: 'offers__pack',
      };
    default:
      return defaultEnumDisplayProperties(type);
  }
}

export function ruleType(type: RuleType): DisplayProperties {
  switch (type) {
    case RuleType.Undefined:
      return {
        message: 'offers__rule_type__undefined',
      };
    case RuleType.Repeated:
      return {
        message: 'offers__rule_type__repeated',
        icon: 'mdi-repeat',
      };
    case RuleType.Exempted:
      return {
        message: 'offers__rule_type__exempted',
        icon: 'mdi-debug-step-over',
      };
    case RuleType.TripConstraint:
      return {
        message: 'offers__rule_type__trip_constraint',
        icon: tripIcon,
      };
    case RuleType.Immediate:
      return {
        message: 'offers__rule_type__immediate',
        icon: 'mdi-ray-start',
      };
    case RuleType.ImmediateRelative:
      return {
        message: 'offers__rule_type__immediate_relative',
        icon: 'mdi-ray-vertex',
      };
    default:
      return defaultEnumDisplayProperties(type);
  }
}

export function offerCategory(category: OfferCategory): DisplayProperties {
  switch (category) {
    case OfferCategory.Uncategorized:
      return {
        message: 'offers__category__uncategorized',
        icon: 'mdi-help',
        class: 'secondary',
      };
    case OfferCategory.Pass:
      return {
        message: 'offers__category__pass',
        icon: 'mdi-ticket-confirmation',
        class: 'disabled_alt1',
      };
    case OfferCategory.Subscription:
      return {
        message: 'offers__category__subscription',
        icon: 'svg:subscription',
        class: 'success_alt1',
      };
    case OfferCategory.Coupon:
      return {
        message: 'offers__category__coupon',
        icon: 'mdi-ticket-percent',
        class: 'warning',
      };
    case OfferCategory.PayAsYouGo:
      return {
        message: 'offers__category__pay_as_you_go',
        icon: 'mdi-cash',
        class: 'disabled',
      };
    default:
      return defaultEnumDisplayProperties(category);
  }
}
