<template lang="pug">
z-tooltip(
  bottom
  :disabled="isDisabled"
)
  template(#activator)
    v-btn.ZLocateOnMapButton(
      icon
      :disabled="isDisabled"
      :to="resolvedTo"
      active-class
      @click="emit('click')"
    )
      v-icon mdi-map-marker
  span {{ t('map__locate_on_map') }}
</template>

<style lang="stylus">
.ZLocateOnMapButton
  margin 0
</style>

<script setup lang="ts">
import { getMapLatLngPath } from '@/lib/helpers/map';
import { useI18n } from '@/composables/plugins';

import type { RawLocation } from 'vue-router';

export interface ZLocateOnMapButtonProps {
  /**
   * Coordinates to locate
   */
  coordinates?: [number, number];
  /**
   * Router link
   */
  to?: RawLocation;
}

const props = defineProps<ZLocateOnMapButtonProps>();
const emit = defineEmits<{
  (event: 'click'): void;
}>();

const vm = getCurrentInstance();

const isDisabled = computed(
  () =>
    !vm?.proxy.$listeners.click &&
    (!props.coordinates ||
      props.coordinates.length !== 2 ||
      (!props.coordinates[0] && !props.coordinates[1]))
);
const resolvedTo = computed<RawLocation | undefined>(() => {
  if (props.to) return props.to;
  if (props.coordinates) return getMapLatLngPath(props.coordinates);
});

const { t } = useI18n();
</script>
