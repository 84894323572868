<!-- eslint-disable vue/no-v-text-v-html-on-component -->
<template lang="pug">
.ZSettings
  div(@click.stop="show = true")
    slot(name="activator")
  ZDialog(
    v-model="show"
    width="800px"
  )
    template(#toolbar)
      VToolbarTitle {{ t('app__settings') }}
    ZScrollable.ZSettings__menu(
      off
      auto
      prevent-touch-overflow-scrolling
    )
      VCard.ZSettings__wrapper
        VCard.ZSettings__content
          template(v-for="(section, sectionIndex) in allowedPreferences")
            template(v-if="isPreferenceDivider(section)")
              VDivider.ZSettings__content__divider(
                v-if="sectionIndex !== allowedPreferences.length - 1"
                :key="`sec-${sectionIndex}`"
              )
            template(v-else)
              VCardTitle.ZSettings__content__sectionTitle(
                :key="`sec-title-${sectionIndex}`"
                :class="section.color ? `${section.color}--text` : ''"
              )
                h3 {{ getTranslation(section.name) }}
              VCardTitle.ZSettings__content__sectionDescription(
                v-if="section.description"
                :key="`sec-${sectionIndex}`"
              )
                span {{ getTranslation(section.name, 'description') }}
              VCardText.ZSettings__content__section(
                :key="`sec-text-${sectionIndex}`"
              )
                VList(three-line)
                  span(
                    v-for="(preference, preferenceIndex) in section.items"
                    :id="`sec-${sectionIndex}-preference-${preferenceIndex}`"
                    :key="`sec-${sectionIndex}-preference-${preferenceIndex}`"
                  )
                    VListTile(
                      :disabled="preference.disabled"
                      @click="clickPreference($event, preference)"
                    )
                      VListTileContent
                        VListTileTitle(
                          v-html="getTranslation(section.name, preference.key, 'name')"
                        )
                        VListTileTitle.v-list__tile__sub-title(
                          v-html="getTranslation(section.name, preference.key, 'description')"
                        )
                      VListTileAction(:style="preference.actionStyle")
                        .ZSettings__content__input
                          VSwitch(
                            v-if="preference.type === 'switch'"
                            v-model="preferences[preference.key]"
                            hide-details
                            :color="preference.color ?? defaultColor"
                            :disabled="preference.disabled"
                            @click.prevent
                          )
                          VSlider(
                            v-if="preference.type === 'slider'"
                            hide-details
                            :color="preference.color ?? defaultColor"
                            :min="preference.min"
                            :max="preference.max"
                            :step="preference.step"
                            :ticks="!!preference.step"
                            :tick-labels="preference.tickLabels"
                            :thumb-label="preference.thumb"
                            :thumb-size="preference.thumbSize || 32"
                            tick-size="2"
                            :value="preference.map ? preference.map.indexOf(Number(preferences[preference.key])) : preferences[preference.key]"
                            :disabled="preference.disabled"
                            @change="setPreference(preference.key, preference.map ? preference.map[$event] : $event)"
                          )
                            template(#thumb-label="props")
                              span {{ preference.thumbLabels?.[props.value] }}
                          VRadioGroup(
                            v-else-if="preference.type === 'radio'"
                            v-model="preferences[preference.key]"
                            hide-details
                            :disabled="preference.disabled"
                          )
                            VRadio(
                              v-for="(item, itemIndex) in getItems(preference)"
                              :key="`sec-${sectionIndex}-preference-${preferenceIndex}-radio-${itemIndex}`"
                              :color="item.color || defaultColor"
                              :label="getItemTranslation(item, preference.preventItemsTranslation)"
                              :value="item.value"
                            )
                          VMenu(
                            v-else-if="preference.type === 'select'"
                            :close-on-content-click="!preference.multiple"
                            :disabled="preference.disabled"
                            offset-y
                            left
                            @input="$event && preference.multiple && preference.selectAll && !String(preferences[preference.key]).length && setPreference(preference.key, '*')"
                          )
                            template(#activator)
                              .ZSettings__menu__value(
                                :ref="element => setMenuActivatorRef(preference.key, element)"
                              )
                                span(
                                  v-if="preference.multiple"
                                  v-html="multiplePreferenceSelectedText(preference)"
                                )
                                span(v-else) {{ translateSelectedPreference(preference) }}
                                VIcon(
                                  small
                                  right
                                ) mdi-chevron-down
                            ZCheckGroupAll(
                              v-if="preference.multiple && preference.selectAll"
                              :items="getItems(preference)"
                              :select-all-text="getSelectAllTranslation(preference.selectAllText)"
                              :select-by-text="t('app__filter_by')"
                              item-key="value"
                              :value="preferences[preference.key] === '*' || !preferences[preference.key] ? [] : String(preferences[preference.key]).split(',')"
                              @input="setPreference(preference.key, $event.join(',') || '*'); preference.reload && reload()"
                            )
                            VList(
                              v-else
                              dense
                            )
                              VListTile(
                                v-for="(item, itemIndex) in getItems(preference)"
                                :key="`sec-${sectionIndex}-preference-${preferenceIndex}-select-${itemIndex}`"
                                @click="setPreferenceValue(preference, item.value)"
                              )
                                VListTileContent(
                                  :class="preferenceHasValue(preference, item.value) ? 'primary--text' : ''"
                                )
                                  VListTileTitle
                                    ZIcon.ZSettings__menu__icon(
                                      v-if="item.icon"
                                      small
                                      :color="preferenceHasValue(preference, item.value) ? item.color : 'grey'"
                                    ) {{ item.icon }}
                                    span {{ getItemTranslation(item, preference.preventItemsTranslation) }}
</template>

<style lang="stylus">
.ZSettings
  user-select none

.v-sheet.ZSettings__wrapper
  display flex
  justify-content center
  min-height 100%

.ZSettings__content
  width 100%
  padding 16px
  padding-top 0

  +media-down-xs()
    padding-top 6px

  .v-input--switch
    flex 0

    .v-input--selection-controls__input
      margin-right 0

  .v-list__tile
    border-radius 16px

  .v-list__tile__action
    min-width 120px

  .v-menu__activator
    .ZSettings__menu__value
      padding 8px
      text-align right

  .v-menu__activator--active
    .ZSettings__menu__value
      .v-icon
        transform rotate(180deg)

.ZSettings__content__input
  .v-slider__thumb
    transform translateY(-50%) scale(0.75)
    cursor grab

  .v-slider__ticks > span
    top 9px

  .v-slider__ticks:first-child,
  .v-slider__ticks:last-child
    > span
      transform translateX(-50%)

.ZSettings__menu.ZScrollable
  top 64px

  +media-down-sm()
    top 56px

.ZSettings__menu__icon
  margin-right 8px

.ZSettings__content__divider
  margin-top 16px
  margin-bottom 16px

.ZSettings__content__sectionTitle
  padding-bottom 0

.ZSettings__content__sectionDescription
  padding-top 0
  padding-bottom 0

.ZSettings__content__section
  padding-top 0
  padding-bottom 0
</style>

<script setup lang="ts">
import axios from 'axios';
import toSnakeCase from 'to-snake-case';

import { capitalize } from '@/lib/utils';
import store from '@/store';
import { useAlert } from '@/composables';
import { useI18n, usePreferences, usePrivileges } from '@/composables/plugins';
import { availablePreferences } from '@/config/preferences';

import ZDialog from '@/components/ui/molecules/ZDialog.vue';
import ZCheckGroupAll from '@/components/ui/molecules/ZCheckGroupAll.vue';

import type { Preferences } from '@/composables/plugins';
import type {
  PreferenceDivider,
  PreferenceItem,
  AnyPreferenceItem,
  PreferenceKey,
  PreferenceOption,
  PreferenceConfig,
  PreferenceValue,
} from '@/config/preferences';
import type { AvailableLocale } from '@/config/app';

const { t, loadLanguage } = useI18n();
const alert = useAlert();
const { preferences } = usePreferences();
const { isGranted } = usePrivileges();

const currentLocale = computed(() => preferences.lang);

const defaultColor = 'info_alt1';

const show = computed({
  get: () => store.getters.settingsOpen,
  set: value => store.commit('settingsOpen', value),
});

const allowedPreferences = computed(() =>
  availablePreferences.filter(
    groupOrDivider =>
      'divider' in groupOrDivider ||
      !groupOrDivider.permission ||
      isGranted(groupOrDivider.permission)
  )
);

function isPreferenceDivider(
  preference: PreferenceConfig
): preference is PreferenceDivider {
  return 'divider' in preference;
}

function getItems<T extends 'radio' | 'select'>(
  preference: PreferenceItem<T>
): PreferenceOption<PreferenceValue<T>>[] {
  return (
    typeof preference.items === 'function'
      ? preference.items()
      : (preference.items ?? [])
  ) as PreferenceOption<PreferenceValue<T>>[];
}

function getTranslation(...keys: (string | number)[]): string {
  const key = keys.map(v => toSnakeCase(v.toString())).join('__');
  return t(`preferences__${key}`);
}

function getItemTranslation(
  item?: PreferenceOption<string | number | boolean>,
  preventTextTranslation?: boolean
): string {
  if (item?.i18nKey) return t(item.i18nKey);
  if (item?.text) {
    if (preventTextTranslation) return String(item.text);
    return getTranslation('item', item.text);
  }
  return '';
}

function getSelectAllTranslation(selectAllText = 'all'): string {
  return getTranslation('item', selectAllText);
}

function translateSelectedPreference(
  preference: PreferenceItem<'select'>
): string {
  const selectedItem = getItems(preference).find(
    item => item.value === preferences[preference.key]
  );
  return getItemTranslation(selectedItem, preference.preventItemsTranslation);
}

const menuActivatorRefs: Partial<Record<PreferenceKey, HTMLElement>> = {};

function setMenuActivatorRef(
  key: PreferenceKey,
  element: ComponentPublicInstance | Element | null
): void {
  if (!element) return;
  menuActivatorRefs[key] = element as HTMLElement;
}

function clickPreference(
  event: MouseEvent,
  preference: AnyPreferenceItem
): void {
  if (preference.type === 'switch') {
    setPreference(preference.key, !preferences[preference.key]);
    if (preference.reload) reload();
  } else if (preference.type === 'select') {
    if ((event.target as HTMLElement).closest('.ZSettings__menu__value')) {
      return;
    }
    const activator = menuActivatorRefs[preference.key];
    activator?.click();
  } else {
    return;
  }
}

function reload(): void {
  alert.message = t('app__need_reload');
  alert.actions = [
    {
      text: 'Reload',
      exec: () => window.location.reload(),
    },
  ];
}

function preferenceItemsToTranslatedString(
  preference: PreferenceItem<'select'>
): string {
  const items = getItems(preference).filter(({ value }) =>
    preferenceHasValue(preference, String(value))
  );
  return items
    .map(item => getItemTranslation(item, preference.preventItemsTranslation))
    .join(', ');
}

function multiplePreferenceSelectedText(
  preference: PreferenceItem<'select'>
): string {
  if (!preference.selectAll) {
    return preferences[preference.key] === ''
      ? `<em>${capitalize(t('app__none'))}</em>`
      : preferenceItemsToTranslatedString(preference);
  } else {
    return preferences[preference.key] && preferences[preference.key] !== '*'
      ? preferenceItemsToTranslatedString(preference)
      : getSelectAllTranslation(preference.selectAllText);
  }
}

function preferenceHasValue(
  preference: PreferenceItem<'select'>,
  value: string | number
): boolean {
  if (preference.multiple) {
    // make sure preference is processed as a string
    return String(preferences[preference.key])
      .split(',')
      .includes(String(value));
  } else {
    return value === preferences[preference.key];
  }
}

function setPreferenceValue(
  preference: PreferenceItem<'select'>,
  value: string | number
): void {
  let key = preference.key;

  if (preference.multiple) {
    // toggle preference value in array
    if (preferenceHasValue(preference, value)) {
      // make sure preference is processed as a string
      setPreference(
        key,
        String(preferences[key])
          .split(',')
          .filter(val => val !== value)
          .join(',')
      );
    } else {
      if (preferences[key] === '' || preferences[key] === undefined) {
        setPreference(key, value);
      } else {
        // make sure preference is processed as a string
        setPreference(
          key,
          String(preferences[key]).split(',').concat(String(value)).join(',')
        );
      }
    }
  } else {
    setPreference(key, value);
  }
  if (preference.reload) reload();
}

function setPreference<K extends PreferenceKey, V extends Preferences[K]>(
  key: K,
  value: V
): void {
  preferences[key] = value;
}

async function switchLanguage(locale: AvailableLocale): Promise<void> {
  preferences.lang = locale;
  store.commit('locale', locale);

  const [error, response] = await to(
    axios.get(`/api/translations?lang=${locale}`)
  );
  if (error) alert.error = error;
  else loadLanguage(locale, response.data);
}

watch(currentLocale, locale => {
  switchLanguage(locale);
});
</script>
