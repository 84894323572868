<template lang="pug">
.ZIconColorValue(:class="classes")
  .ZIconColorValue__container(v-if="hasValue || showHint")
    template(v-if="hasValue")
      ZIcon.ZIconColorValue__icon(
        v-if="(icon || resolvedColor) && !iconSlot"
        :color="resolvedColor"
        :small="size === 'small'"
        :size="size === 'extra-small' ? 12 : size === 'small' ? null : 20"
        :class="iconClasses"
        :style="{ opacity }"
      ) {{ icon || 'mdi-circle' }}
      component.ZIconColorValue__icon(
        :is="iconSlot.component.name"
        v-if="iconSlot?.component"
        :class="iconSlot.component.class"
        v-bind="iconSlot.component.props"
        v-on="iconSlot?.component?.listeners"
      ) {{ iconSlot.component.content }}
      component(
        :is="badge ? ZBadge : 'div'"
        v-bind="badge"
        :class="{ ZIconColorValue__content: !badge }"
      )
        span.ZIconColorValue__text(
          :class="textClasses"
          :style="(resolvedTextColor || [])[0] === '#' ? { color: resolvedTextColor } : {}"
          v-html="formattedValue"
        )
        span.ZIconColorValue__unit(
          v-if="unit"
          v-html="unit"
        )
        ZLastUpdateDelay(
          v-if="lastUpdated"
          :value="lastUpdated"
          :warning="warning"
          :outline="outlineDelay"
        )
      em.ZIconColorValue__metaInfo(
        v-if="metaInfo"
        v-html="metaInfo"
      )
    ZToggleableHint.ZIconColorValue__hint(
      v-if="showHint"
      :class="{ 'ZIconColorValue__hint--left': empty }"
      :error="!!error"
    )
      span(
        v-if="hint"
        v-html="hint"
      )
      ZErrorMessage(
        v-if="error"
        :error="error"
      )
  VProgressLinear.ZIconColorValue__loader(
    v-if="loading"
    :height="1"
    color="fifteen_control"
    :class="loaderClasses"
    indeterminate
  )
</template>

<style lang="stylus">
.ZIconColorValue__container
  display flex
  align-items center

.ZIconColorValue__hint
  margin-right -12px
  margin-left 4px

  &.ZIconColorValue__hint--left
    margin-left -4px

.ZIconColorValue
  display flex
  align-items center
  padding-left 3px
  position relative
  height 100%

  .ZIconColorValue__content,
  .ZBadge .v-badge
    display flex
    align-items center
    width 100%

  &--dense
    font-size 13px

  &--wrap
    line-height 1.2
    white-space normal

    .ZIconColorValue__content
      padding 4px 0

  .v-progress-linear.ZIconColorValue__loader
    margin 6px 0 5px
    width 48px

    &.ZIconColorValue__loader--withValue
      width 100%
      margin 0
      position absolute
      bottom 0

.ZIconColorValue__icon
  margin-right 4px

  &--small
    margin-right 4px
    margin-left -1px

  &--extraSmall
    margin-right 3px
    margin-left -1px

.ZIconColorValue__text
  &--large
    font-weight 500
    font-size 18px

  &--ellipsis
    flex 1
    overflow hidden
    max-width 420px
    text-overflow ellipsis

  &--noMaxWidth
    max-width none

.ZIconColorValue__unit
  margin-left 2px

.ZIconColorValue__metaInfo
  max-height 24px
  font-size 12px
  display flex
  margin-left 8px
  color: $colors.grey.base

  svg
    width 16px
    height 16px

    path
      fill currentColor

  .ZIconColorValue--withBadge &
    margin-left 4px

.theme--dark,
.theme--light
  .ZIconColorValue
    .v-icon.ZIconColorValue__icon--noColor
      color currentColor
</style>

<script setup lang="ts">
import { type RequestError } from '@/composables/useRequest';
import { useI18n } from '@/composables/plugins';
import { useColors } from '@/composables';

import ZErrorMessage from '@/components/ui/atoms/ZErrorMessage.vue';
import ZBadge from '@/components/ui/molecules/ZBadge.vue';

import type AppError from '@/lib/classes/app-error';

export interface IconColorValueProps {
  /**
   * Whether the component should have dense display.
   */
  dense?: boolean;
  /**
   * The icon to be displayed.
   */
  icon?: string;
  /**
   * The slot for additional icon content
   */
  iconSlot?: Slot | null;
  /**
   * Opacity level of the component.
   */
  opacity?: number;
  /**
   * Color of the icon. If a `color` is passed but no `icon`, a `'mdi-circle'` icon is used to display the color.
   */
  color?: Color;
  /**
   * Text color for the component.
   */
  textColor?: Color;
  /**
   * Whether the text should be displayed in bold.
   */
  boldText?: boolean;
  /**
   * Whether the component is in a loading state.
   */
  loading?: boolean;
  /**
   * Whether an error has occurred. If it is a boolean, a generic error text is displayed.
   */
  error?: AppError | RequestError | Error | null;
  /**
   * Whether the value is flagged as being empty.
   */
  empty?: boolean;
  /**
   * A hint or additional information for the component.
   */
  hint?: string;
  /**
   * The value to be displayed.
   */
  value?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  /**
   * Unit to be displayed after the value.
   */
  unit?: string;
  /**
   * Display size.
   */
  size?: 'normal' | 'small' | 'extra-small';
  /**
   * Whether the text value should be rendered with ellipsis if greater than 420px.
   */
  ellipsis?: boolean;
  /**
   * Prevent the text to be displayed with a max width.
   */
  noMaxWidth?: boolean;
  /**
   * Whether the text should be displayed in a larger font size.
   */
  largeText?: boolean;
  /**
   * Badge props to display an appended badge.
   */
  badge?: Badge | null;
  /**
   * The timestamp or object representing the last update time.
   */
  lastUpdated?: string | object | boolean | number | null;
  /**
   * Whether a warning state is present.
   */
  warning?: boolean;
  /**
   * Meta information to be displayed.
   */
  metaInfo?: string | number | null;
  /**
   * Whether outline the last update delay text.
   */
  outlineDelay?: boolean;
  /**
   * Whether the text should wrap within the component.
   */
  wrap?: boolean;
}

const props = withDefaults(defineProps<IconColorValueProps>(), {
  icon: '',
  iconSlot: null,
  opacity: 1,
  color: undefined,
  textColor: '',
  error: null,
  hint: '',
  value: null,
  size: 'normal',
  badge: null,
  lastUpdated: null,
  metaInfo: '',
  unit: undefined,
});

const { themeColor } = useColors();

const classes = computed(() => ({
  'ZIconColorValue--withBadge': !!props.badge,
  'ZIconColorValue--wrap': props.wrap,
  'ZIconColorValue--dense': props.dense,
}));

const textClasses = computed(() => ({
  'ZIconColorValue__text--ellipsis': props.ellipsis,
  'ZIconColorValue__text--noMaxWidth': props.noMaxWidth,
  'ZIconColorValue__text--large': props.largeText,
  [`${resolvedTextColor.value}--text`]:
    resolvedTextColor.value?.[0] && resolvedTextColor.value[0] !== '#',
  bold: props.boldText,
}));

const iconClasses = computed(() => ({
  'ZIconColorValue__icon--extraSmall': props.size === 'extra-small',
  'ZIconColorValue__icon--small': props.size === 'small',
  'ZIconColorValue__icon--noColor': !resolvedColor.value,
}));

const hasValue = computed(
  () => props.value !== '' && props.value !== null && props.value !== undefined
);

const loaderClasses = computed(() => ({
  'ZIconColorValue__loader--withValue': hasValue.value,
}));

const showHint = computed(() => (hasValue.value && props.hint) || props.error);

const { t, tin } = useI18n();

const formattedValue = computed(() =>
  hasValue.value
    ? (props.value as I18nArgs).__i18nArgs__
      ? t(props.value)
      : typeof props.value === 'string'
        ? tin(props.value)
        : props.value
    : '&nbsp;'
);

const resolvedColor = computed(() => themeColor(props.color));

const resolvedTextColor = computed(() => themeColor(props.textColor));
</script>
