import { ProductVersion } from '@/enums/product';

/**
 * Returns the formatted product version id based on its enum value
 * @param productVersionId - product version id value
 * @returns Formatted product version id
 */
export function productType(
  productVersionId: ProductVersion
): DisplayProperties {
  switch (productVersionId) {
    case ProductVersion.Zoov01:
      return {
        message: 'zoov01',
      };
    case ProductVersion.Zoov02:
      return {
        message: 'zoov02',
      };
    case ProductVersion.Edock01:
      return {
        message: 'edock',
      };
    case ProductVersion.Zoov03:
      return {
        message: 'fusion',
      };
    case ProductVersion.Flex:
      return {
        message: 'flex',
      };
    case ProductVersion.Proto:
      return {
        message: 'prototype',
      };
    case ProductVersion.Fusion2:
      return {
        message: 'fusion2',
      };
    default:
      return {
        message: String(ProductVersion),
      };
  }
}
