import StoreModule from '@/store/core/store-module';
import { areaFields } from '@/models/fields';
import {
  userEmailPopulateConfig,
  payAsYouGoPopulateConfig,
} from '@/lib/helpers/populate';

import type { ApiSchema } from '#/core-api';
import type {
  PopulatedField,
  PopulatedFieldArray,
} from '@/store/core/populate';
import type { Get, Post, Patch } from '@/store/types/core-api';

declare global {
  /**
   * Populated Area
   */
  type Area = DeepMerge<
    ApiSchema['area.ServiceableArea'],
    {
      id: string;
      creator?: PopulatedField<
        ApiSchema['area.ServiceableArea']['created_by'],
        ApiSchema['user.User']['email']
      >;
      'last updater'?: PopulatedField<
        ApiSchema['area.ServiceableArea']['last_updated_by'],
        ApiSchema['user.User']['email']
      >;
      pay_as_you_go?: PopulatedField<
        ApiSchema['area.ServiceableArea']['id'],
        ApiSchema['payment.Benefit']['name']
      >;
      pay_as_you_go_id?: PopulatedField<
        ApiSchema['area.ServiceableArea']['id'],
        ApiSchema['payment.Benefit']['id']
      >;
      trip_cooldown?: {
        pricing_whitelist?:
          | string[]
          | PopulatedFieldArray<
              ArrayElement<
                ApiSchema['area.TripCooldownData']['pricing_whitelist']
              >,
              ApiSchema['payment.Offer']['name']
            >;
      };
    }
  >;
}

const areasScopes = ['App', 'Areas', 'Map', 'Other'] as const;

export interface AreaStoreTypes {
  AREAS: [Get<'/areas', Area[], typeof areasScopes>, Post<'/areas'>];
  AREAS_COUNT: [Get<'/areas/count', void, typeof areasScopes>];
  AREA: [Get<'/areas/{ID}', Area>, Patch<'/areas/{ID}'>];
  AREA_REQUIRED_ROLE: [Post<'/areas/{ID}/set_required_role'>];
  AREA_LABEL: [Post<'/areas/{ID}/set_label'>];
  AREA_COORDINATES: [Post<'/areas/{ID}/set_coordinates'>];
  AREA_WAREHOUSE: [Post<'/areas/{ID}/set_warehouse'>];
}

const areaModule = new StoreModule<AreaStoreTypes>();
areaModule
  .createResourceAndAction({
    type: 'AREAS',
    requestEndPoint: '/areas',
    permission: 'gateway.area.list',
    scopes: areasScopes,
    withGlobalUsageQuery: true,
    requestOptions: () => ({
      freeze: true,
      params: {
        sort: 'label',
        fields: areaFields,
      },
    }),
    populate: [payAsYouGoPopulateConfig()],
    transform: data => data.areas,
  })
  .createResourceAndAction({
    type: 'AREAS_COUNT',
    requestEndPoint: '/areas/count',
    scopes: areasScopes,
    withGlobalUsageQuery: true,
  })
  .createResourceAndAction({
    type: 'AREA',
    requestEndPoint: '/areas/:id',
    permission: 'gateway.area.query',
    transform: data => data.area,
    populate: [
      userEmailPopulateConfig({
        dataField: 'created_by',
        asKey: 'creator',
      }),
      userEmailPopulateConfig({
        dataField: 'last_updated_by',
        asKey: 'last updater',
      }),
      payAsYouGoPopulateConfig(),
      {
        dataField: 'trip_cooldown.pricing_whitelist',
        dataType: String,
        actionType: 'GET_OFFERS',
        queryField: 'id',
        responseFields: [{ key: 'name', as: '__data', inject: true }],
      },
    ],
  })
  .createAction({
    type: 'AREAS',
    requestEndPoint: '/areas',
    requestMethod: 'POST',
    permission: 'gateway.area.add',
  })
  .createAction({
    type: 'AREA',
    requestEndPoint: '/areas/:id',
    requestMethod: 'PATCH',
    permission: 'gateway.area.update',
  })
  .createAction({
    type: 'AREA_LABEL',
    requestEndPoint: '/areas/:id/set_label',
    requestMethod: 'POST',
    permission: 'gateway.area.set_label',
  })
  .createAction({
    type: 'AREA_REQUIRED_ROLE',
    requestEndPoint: '/areas/:id/set_required_role',
    requestMethod: 'POST',
    permission: 'gateway.area.set_required_role',
  })
  .createAction({
    type: 'AREA_WAREHOUSE',
    requestEndPoint: '/areas/:id/set_warehouse',
    requestMethod: 'POST',
    permission: 'gateway.area.set_warehouse',
  });

export default areaModule;
