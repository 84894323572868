import { useAuth } from '@/composables';
import { usePrivileges } from '@/composables/plugins';
import { fieldPermissions as userFieldPermissions } from '@/models/user/field-permissions';

interface UseFieldPermissionsReturn {
  checkPermission: (modelKey: string) => boolean;
}

const fieldPermissionsMap: Record<'user', ReadPermission<User>[]> = {
  user: userFieldPermissions,
};

export function useFieldPermissions(
  modelName = 'user'
): UseFieldPermissionsReturn {
  const { userId } = useAuth();
  const { isGranted } = usePrivileges();

  const route = useRoute();
  const currentUserIdPage = computed(() => {
    return /User(\.|$)/.test(route.name ?? '') ? route.params.id : null;
  });

  function checkPermission(modelKey: string): boolean {
    const read = (fieldPermissionsMap[modelName as 'user'] ?? []).find(
      entry => entry.key === modelKey
    );
    if (!read) return true;

    if (
      read.self &&
      currentUserIdPage.value &&
      userId.value !== currentUserIdPage.value
    ) {
      return false;
    }

    return isGranted(read?.permission) ?? true;
  }

  return { checkPermission };
}
