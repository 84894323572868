<!-- eslint-disable vue/no-deprecated-slot-attribute -->
<template lang="pug">
.ZNavigationContent(:class="{ 'ZNavigationContent--dev': isDeveloperMode }")
  VToolbar.ZNavigationContent__header(
    v-if="mdAndDown"
    flat
    :dark="isDeveloperMode"
    :light="!isDeveloperMode"
    :color="isDeveloperMode ? 'black' : 'fifteen_control'"
    absolute
    :dense="mdAndDown"
  )
    template(v-if="!mini")
      ZAppLogo.ZNavigationContent__logo(
        :width="mdAndDown ? 80 : 96"
        :variant="isDark ? 'light' : 'dark'"
        @click="toggle"
      )
      VSpacer
      VBtn(
        icon
        @click="toggle"
      )
        VIcon(:color="isDark ? 'white' : 'black'") mdi-close
    template(v-else)
      VBtn(
        icon
        @click="toggle"
      )
        VIcon mdi-menu
  ZScrollable(
    :top="mdAndDown ? '56px' : '0'"
    :bottom="mdAndDown ? '56px' : '32px'"
  )
    VList
      template(v-for="(item, i) in navigationItems")
        VDivider(
          v-if="item.meta && item.meta.sepBefore"
          :key="'nav-sep-' + i"
        )
        component(
          :is="mini ? 'ZTooltip' : 'div'"
          :key="'nav-item-' + i"
          right
          opaque
          :color="isDark ? 'black' : ''"
        )
          VListTile(
            :key="'nav-item-' + i"
            :slot="mini ? 'activator' : null"
            :value="false"
            :to="item.path"
            @click="close"
          )
            VListTileAction
              ZIcon(size="22") {{ item.meta?.icon }}
            VListTileContent
              // eslint-disable-next-line vue/no-v-text-v-html-on-component
              VListTileTitle(v-text="item.displayedName")
          span(v-if="mini") {{ item.displayedName }}
</template>

<style lang="stylus">
.ZNavigationContent > .ZScrollable
  user-select none
  -ms-overflow-style none
  scrollbar-width none !important

  &::-webkit-scrollbar
    display none

  .v-list__tile:hover
    .ZNavigationContent__itemIcon--toggleable
      opacity 0

  .v-list__tile__action--stack
    align-items center

  .v-list__tile--link.v-list__tile--active
    color $color-fifteen_control !important

    svg
      path
        fill $color-fifteen_control

.ZNavigationContent__logo
  cursor pointer
  transform translateY(4px)

.ZNavigationContent__itemIcon
  position absolute
  transition opacity 0.1s

.ZNavigationContent__header
  .v-toolbar__content
    background: $colors.grey.lighten-5

  &__title
    font-quick()
    margin-left 7px
    font-weight bold
    width 118px

    &--solo
      text-align center

.theme--dark
  .ZNavigationContent__header
    .v-toolbar__content
      background-color $color-grey_alt
</style>

<script setup lang="ts">
import { useDarkTheme, useVuetifyBreakpoints } from '@/composables';

import type { RouteConfig } from 'vue-router';

type NavigationItem = RouteConfig & {
  displayedName?: string;
};

export interface ZNavigationContentProps {
  /**
   * Navigation items
   */
  navigationItems?: NavigationItem[];
  /**
   * Whether navigation is in mini variant
   */
  mini?: boolean;
  /**
   * Whether developer mode is activated
   */
  isDeveloperMode?: boolean;
  /**
   * Whether to show the logo
   */
  showLogo?: boolean;
}

withDefaults(defineProps<ZNavigationContentProps>(), {
  navigationItems: () => [],
  mini: false,
  dev: false,
  showLogo: true,
});

const emit = defineEmits<{
  (name: 'toggle'): void;
  (name: 'close'): void;
}>();

const { mdAndDown } = useVuetifyBreakpoints();

const { isDark } = useDarkTheme();

function toggle(): void {
  emit('toggle');
}

function close(): void {
  emit('close');
}
</script>
