import type { GetterType } from '@/store';

/*
 * Global filters: areas
 */
export default {
  buildQuery: (type: GetterType, areaIds: string[]) => {
    if (!areaIds || !areaIds.length) return null;
    switch (type) {
      case 'AREAS':
      case 'AREAS_COUNT':
        return {
          $and: [{ id: { $in: areaIds } }],
        };
      case 'BIKES':
      case 'VEHICLES':
      case 'STATIONS':
      case 'TRIPS':
      case 'BOOKINGS':
      case 'FASTENERS':
      case 'BIKES_COUNT':
      case 'VEHICLES_COUNT':
      case 'STATIONS_COUNT':
      case 'TRIPS_COUNT':
      case 'BOOKINGS_COUNT':
      case 'FASTENERS_COUNT':
      case 'ISSUES_COUNT':
      case 'POINTS_OF_INTEREST':
      case 'POINTS_OF_INTEREST_COUNT':
        return {
          $and: [{ area_id: { $in: areaIds } }],
        };
      case 'ISSUES':
        return {
          $and: [{ selector_ids: { $in: areaIds } }],
        };
    }
  },
  isContained: (
    type: GetterType,
    areaIds: string[],
    item: { area_id: string; selector_ids: string[] }
  ) => {
    if (!areaIds || !areaIds.length) return true;
    switch (type) {
      case 'BIKE':
      case 'VEHICLE':
      case 'STATION':
      case 'TRIP':
      case 'BOOKING':
      case 'FASTENER':
      case 'POINTS_OF_INTEREST':
        return areaIds.includes(item.area_id);
      case 'ISSUE':
        return item.selector_ids.some(selectorId =>
          areaIds.includes(selectorId)
        );
      default:
        return true;
    }
  },
};
