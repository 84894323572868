import StoreModule from '@/store/core/store-module';
import { offerPolicyFields } from '@/models/fields';
import { offersPopulateConfig } from '@/lib/helpers/populate';

import type { ApiSchema } from '#/core-api';
import type { PopulatedField } from '@/store/core/populate';
import type { Get, Post, Patch, Delete } from '@/store/types/core-api';

declare global {
  /**
   * Populated Offer Policy
   */
  type OfferPolicy = DeepMerge<
    ApiSchema['payment.OfferPolicy'],
    {
      id: string;
      offers?: PopulatedField<
        ApiSchema['payment.OfferPolicy']['offer_ids'],
        ApiSchema['payment.Offer']['name'][]
      >;
    }
  >;
}

const offerPoliciesScopes = ['OfferPolicies', 'App'] as const;

export interface OfferPolicyStoreTypes {
  OFFER_POLICIES: [
    Get<'/payments/offer_policies', OfferPolicy[], typeof offerPoliciesScopes>,
    Post<'/payments/offer_policies'>,
  ];
  OFFER_POLICIES_COUNT: [
    Get<'/payments/offer_policies/count', void, typeof offerPoliciesScopes>,
  ];
  OFFER_POLICY: [
    Get<'/payments/offer_policies/{ID}', OfferPolicy>,
    Patch<'/payments/offer_policies/{ID}'>,
    Delete<'/payments/offer_policies/{ID}'>,
  ];
}

const offerPoliciesModule = new StoreModule<OfferPolicyStoreTypes>();
offerPoliciesModule
  .createResourceAndAction({
    type: 'OFFER_POLICIES',
    requestEndPoint: '/payments/offer_policies',
    scopes: offerPoliciesScopes,
    requestOptions: () => ({
      freeze: true,
      params: {
        fields: offerPolicyFields,
      },
    }),
    populate: [
      offersPopulateConfig({
        dataField: 'offer_ids',
      }),
    ],
    transform: data => data.offer_policies,
  })
  .createResourceAndAction({
    type: 'OFFER_POLICIES_COUNT',
    requestEndPoint: '/payments/offer_policies/count',
    scopes: offerPoliciesScopes,
  })
  .createAction({
    type: 'OFFER_POLICIES',
    requestEndPoint: '/payments/offer_policies',
    requestMethod: 'POST',
  })
  .createResourceAndAction({
    type: 'OFFER_POLICY',
    requestEndPoint: '/payments/offer_policies/:id',
    transform: data => data.offer_policy,
    populate: [
      offersPopulateConfig({
        dataField: 'offer_ids',
      }),
    ],
  })
  .createAction({
    type: 'OFFER_POLICY',
    requestEndPoint: '/payments/offer_policies/:id',
    requestMethod: 'PATCH',
  })
  .createAction({
    type: 'OFFER_POLICY',
    requestEndPoint: '/payments/offer_policies/:id',
    requestMethod: 'DELETE',
  });

export default offerPoliciesModule;
