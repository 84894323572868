import { ApiKeyStatus } from '#/core-api/enums';

import { defaultEnumDisplayProperties } from '@/lib/utils';

export function apiKeyStatus(status?: ApiKeyStatus): DisplayProperties {
  switch (status) {
    case ApiKeyStatus.NotSet:
      return {
        message: 'Not set',
        class: 'warning_alt1',
      };
    case ApiKeyStatus.Active:
      return {
        message: 'Active',
        class: 'success',
      };
    case ApiKeyStatus.Inactive:
      return {
        message: 'Inactive',
        class: 'disabled',
      };
    case ApiKeyStatus.Deleted:
      return {
        message: 'Deleted',
        class: 'error',
      };
    default:
      return defaultEnumDisplayProperties(status);
  }
}
