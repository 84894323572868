import { BatteryManufacturer, BatteryStatus } from '#/core-api/enums';

import { defaultEnumDisplayProperties } from '@/lib/utils';

export function batteryManufacturer(
  value: number | BatteryManufacturer
): DisplayProperties {
  switch (value) {
    case BatteryManufacturer.Greenway:
    case 186:
      return {
        message: 'Greenway',
      };
    case BatteryManufacturer.Portapower:
      return {
        message: 'PortaPower',
      };
    case BatteryManufacturer.Unknown:
      return {
        message: 'battery__manufacturer_unknown',
      };
    case 3:
    case 188:
      return {
        message: 'Nalon',
      };
    case 4:
      return {
        message: 'IBS',
      };
    default:
      return {
        message: String(value),
      };
  }
}

export function batteryStatus(status: BatteryStatus): DisplayProperties {
  switch (status) {
    case BatteryStatus.Started:
      return {
        message: 'battery__started',
      };
    case BatteryStatus.Stopped:
      return {
        message: 'battery__stopped',
      };
    case BatteryStatus.Absent:
      return {
        message: 'battery__absent',
      };
    default:
      return defaultEnumDisplayProperties(status);
  }
}

export function batteryType(type: number): DisplayProperties {
  switch (type) {
    case 0:
      return {
        message: 'Community',
      };
    case 1:
      return {
        message: 'User',
      };
    case 2:
      return {
        message: 'IBS',
      };
    default:
      return {
        message: String(type),
      };
  }
}
