<template lang="pug">
span.ZToggleableHint(
  :class="classes"
  @mouseenter="isOpen = true"
  @mouseleave="isOpen = false"
  @click.stop="handleClick"
)
  VBtn(
    ref="activatorRef"
    small
    icon
  )
    ZIcon(
      small
      :color="color"
    ) {{ icon }}

  .ZToggleableHint__slot
    slot
</template>

<style lang="stylus">
.ZToggleableHint
  position relative
  display inline-flex

  .v-menu__activator *
    cursor help

  .ZToggleableHint__slot
    display none

    kbd
      display inline
      font-size 100%

  .v-btn.v-btn--small
    width 24px
    height 24px
    display flex
    align-items center
    justify-content center
    cursor help
    margin 0

    +media-down-xs()
      &:hover:before
        background-color transparent
</style>

<script lang="ts">
export default { name: 'ZToggleableHint' };
</script>

<script setup lang="ts">
import store from '@/store';
import { check, toggleScrollListener } from '@/lib/utils';

export interface ZToggleableHintProps {
  /**
   * Whether the hint is in error state
   */
  error?: boolean;
  /**
   * Icon name
   */
  icon?: string;
}

const props = defineProps<ZToggleableHintProps>();

const isOpen = ref(false);

const activatorRef = ref<HTMLSpanElement>();

const slots = useSlots();

const icon = computed(
  () =>
    props.icon || (props.error ? 'mdi-alert-circle-outline' : 'mdi-information')
);
const classes = computed(() => ({
  'ZToggleableHint--error': props.error,
}));
const color = computed(() => (props.error ? 'error' : 'info'));

function handleClick(): void {
  if (!check.hasTouch()) return;

  isOpen.value = !isOpen.value;
}

function close(): void {
  isOpen.value = false;
}

const { width: windowWidth, height: windowHeight } = useWindowSize({
  listenOrientation: true,
  includeScrollbar: true,
});

const position = computed(() => {
  const { left, top, right, height } = useElementBounding(activatorRef.value);

  return {
    left: left.value,
    top: top.value + height.value,
    right: windowWidth.value - right.value,
    bottom: windowHeight.value - top.value,
  };
});

watch([isOpen, windowWidth], () => {
  const { left, top, right, bottom } = position.value;

  if (!slots.default) return;

  store.commit('toggleableHintContent', {
    left,
    top,
    right,
    bottom,
    defaultSlots: slots.default() ?? [],
    error: props.error,
    isVisible: isOpen.value,
  });

  toggleScrollListener(isOpen.value, close);
});
</script>
