<template lang="pug">
.ZVehicleScanner
  ZTooltip(bottom)
    template(#activator)
      VBtn.ml-0(
        icon
        @click="isOpen = !isOpen"
      )
        VIcon mdi-qrcode-scan
    span {{ t('vehicles__scan') }}
  VDialog(
    v-model="isOpen"
    lazy
    max-width="600"
    :transition="false"
    content-class="ZVehicleScanner__dialog"
  )
    VCard.ZVehicleScanner__card(dark)
      ZQrCodeReader(
        ref="qrCodeReaderRef"
        v-model="isOpen"
        closable
        :validate="validateQrCode"
        @decoded="decodedHandler"
      )
    ZInputFieldBlock(
      ref="manualInputRef"
      :value="manualInputId"
      :label="t('vehicles__id')"
      :enter-text="t('vehicles__scan')"
      :rules="rules"
      @input="manualInputHandler"
    )
</template>

<style lang="stylus">
.ZVehicleScanner__dialog
  overflow-x hidden
  border-radius 16px

.ZVehicleScanner__card
  .v-input--selection-controls
    padding-top 0

  &.v-card.theme--dark
    background-color darken($colors.grey.darken-3, 37.5%)

.ZVehicleScanner__separator
  width calc(100% + 2 * 16px)
  height 1px
  background-color rgba(255, 255, 255, 0.1)
  transform translateX(-16px)
</style>

<script setup lang="ts">
import { parseURL, getQuery } from 'ufo';

import { serialNumberRule } from '@/lib/helpers/rules';
import { useVModelProxy, useAlert } from '@/composables';
import { useI18n } from '@/composables/plugins';
import { isBike, isProductTypeBike } from '@/models/vehicle/helpers';
import { appConfig } from '@/config';
import store from '@/store';

import ZInputFieldBlock from '@/components/ui/molecules/ZInputFieldBlock.vue';
import ZQrCodeReader from '@/components/ui/organisms/ZQrCodeReader.vue';

import type { ParsedQuery } from 'ufo';

export interface ZVehicleScannerProps {
  /**
   * Whether the scanner is open
   * @model
   */
  value?: boolean;
}

const props = withDefaults(defineProps<ZVehicleScannerProps>(), {
  value: false,
});

defineEmits<{
  (event: 'input', value: boolean): void;
}>();

const rules = [serialNumberRule];
const isOpen = useVModelProxy({ props });
const manualInputId = ref<string>();
const { t } = useI18n();
const alert = useAlert();
const router = useRouter();

const qrCodeReaderRef = ref<InstanceType<typeof ZQrCodeReader> | null>(null);
const manualInputRef = ref<ComponentExposed<typeof ZInputFieldBlock> | null>(
  null
);

function validateQrCode(data: string): boolean {
  const { host } = parseURL(data);
  if (!host || !appConfig.qrCodeHosts.includes(host)) return false;
  const query = getQuery<DecodedQuery>(data);
  return !!query.bike_id || !!query.product_id;
}

type DecodedQuery = ParsedQuery & {
  bike_id?: string;
  product_id?: string;
  product_type_id?: string;
};

async function decodedHandler(data: string): Promise<void> {
  // Close the dialog immediately
  isOpen.value = false;
  const query = getQuery<DecodedQuery>(data);
  const id = query.bike_id || query.product_id;
  if (!id) return;

  const isBikeProduct =
    !!query.bike_id || isProductTypeBike(Number(query.product_type_id));

  if (isBikeProduct) {
    store.dispatch('GET_BIKE', {
      serial_number: Number(id),
      params: { scanned: 'true' },
      preventStore: true,
      preventPopulate: true,
    });
  }
  // TODO: use ticket of type 'action' to store 'scan info' for the vehicle,
  // when ticket service is ready, and remove the above nonsense. ☝️

  // Get the use geolocation and set the vehicle location
  const { coords, pause } = useGeolocation();
  watchOnce(coords, async newCoords => {
    if (!newCoords) return;
    await store.dispatch('PATCH_VEHICLE', {
      id,
      data: {
        location: {
          type: 'Point',
          coordinates: [newCoords.longitude, newCoords.latitude],
        },
      },
    });
    pause();
  });

  goToProductPage(id, isBikeProduct);
}

function goToProductPage(id: string, isBikeProduct: boolean): void {
  if (isBikeProduct) {
    router.push({
      name: 'Bike',
      params: { serial_number: id },
    });
  } else {
    router.push({
      name: 'Vehicle',
      params: { id },
    });
  }
}

/**
 * Open the scanner dialog
 */
function open(): void {
  isOpen.value = true;
}
/**
 * Close the scanner dialog
 */
function close(): void {
  isOpen.value = false;
}

async function manualInputHandler(id?: string): Promise<void> {
  if (!id) return;
  // Close the dialog immediately
  isOpen.value = false;
  // First, fetch the vehicle to retrieve the product version id
  const [error, vehicle] = await to(store.dispatch('GET_VEHICLE', { id }));
  if (error) {
    alert.error = error;
    return;
  }

  goToProductPage(id, isBike(vehicle.product_version_id));
}

watch(isOpen, newValue => {
  if (!newValue) {
    // Make sure torch turns off
    qrCodeReaderRef.value?.torchOff();
  } else {
    // Clear manual input when opening the scanner
    manualInputId.value = undefined;
    manualInputRef.value?.clear();
  }
});

defineExpose({
  open,
  close,
});
</script>
