import { SystemState } from '#/core-api/enums';

import { defaultEnumDisplayProperties } from '@/lib/utils';

/**
 * @see https://doc.airvantage.net/av/reference/cloud/API/API-System-v1/#API-Systemv1-Overview-Fields
 */
export function simSystemState(state?: SystemState): DisplayProperties {
  switch (state) {
    case SystemState.NotSet:
      return {
        message: 'sim__system_state__not_set',
        class: 'disabled',
      };
    case SystemState.Inventory:
      return {
        message: 'sim__system_state__inventory',
        class: 'info',
      };
    case SystemState.Active:
      return {
        message: 'sim__system_state__active',
        class: 'success',
      };
    case SystemState.TestReady:
      return {
        message: 'sim__system_state__test_ready',
        class: 'warning',
      };
    case SystemState.Suspended:
      return {
        message: 'sim__system_state__suspended',
        class: 'error',
      };
    case SystemState.Retired:
      return {
        message: 'sim__system_state__retired',
        class: 'disabled',
      };
    default:
      return defaultEnumDisplayProperties(state);
  }
}
