// eslint-disable-next-line @typescript-eslint/no-explicit-any
type FilterValue = any;

/**
 * Wraps a value under a `$eq` operator if shouldWrap is true
 * @param value - The value to wrap
 * @param shouldWrap - Whether to wrap the value
 */
export default function wrap$eq(
  value: FilterValue,
  shouldWrap = false
): MongoQuery {
  return shouldWrap ? { $eq: value } : value;
}
