/**
 * Get the delay between now and a given date, in milliseconds
 * @param date - The input date
 */
export default function delayFromNow(
  date?: Date | string | number | null
): number {
  if (!date) return 0;
  const now = Date.now();
  const time = new Date(date).getTime();
  return now - time;
}
