import { appConfig } from '@/config';

import type { ThemeColor } from '@/themes';

const warnLimit = appConfig.batteryPriorityLimit;
const baseMinSoc = appConfig.baseMinSoc;

/**
 * Get the color of the battery based on its level
 * @param level - The battery level in percent (_i.e_ SoC - State of Charge)
 */
export function getBatteryColor(level?: number): ThemeColor | undefined {
  if (level === undefined) return;
  switch (true) {
    case level > 99:
      return 'success';
    case level > warnLimit && level <= 99:
      return 'primary';
    case level >= baseMinSoc && level <= warnLimit:
      return 'warning';
    case level < baseMinSoc:
      return 'error';
  }
}

/**
 * Get the color of the battery based on its level for display in a chart
 * @param level - The battery level in percent (_i.e_ SoC - State of Charge)
 */
export function getBatteryChartColor(level?: number): ThemeColor | undefined {
  if (level === undefined) return;
  switch (true) {
    case level > 99:
      return 'success';
    case level > warnLimit && level <= 99:
      return 'primary';
    case level >= baseMinSoc && level <= warnLimit:
      return 'warning';
    case level < baseMinSoc:
      return 'error_alt1';
  }
}

/**
 * Get the icon of the battery based on its level
 * @param level - The battery level in percent (_i.e_ SoC - State of Charge)
 * @param alertEmpty - Whether to show the alert icon when the battery is empty
 */
export function getBatteryIcon(
  level?: number,
  alertEmpty = true
): string | undefined {
  if (level === undefined) return;
  switch (true) {
    case level >= 99:
      return 'mdi-battery';
    case level < 10:
      return alertEmpty ? 'mdi-battery-alert' : 'mdi-battery-outline';
    default:
      const batteryImageValue = Math.floor(level / 10) * 10;
      return `mdi-battery-${batteryImageValue}`;
  }
}
