<template lang="pug">
ZMultiInput.ZActionControlMultiInput(
  v-show="canShow"
  ref="componentRef"
  v-model="modelValue"
  :style="style"
  :label="label"
  :rules="rules"
  :color="color"
  :disabled="isDisabled"
  :hint="textareaHint"
  :placeholder="placeholder"
  :mask="mask"
  :messages="message"
  :hide-details="areDetailsHidden"
  :autocapitalize="autocapitalize"
  :textarea="control.textarea"
  :key-label="control.keyLabel"
  :key-rules="control.keyRules"
  :key-handler="control.keyHandler"
  :key-items="control.keyItems"
  :i18n="control.i18n"
  :selected-key.sync="selectedKey"
  auto-grow
  dense
  v-bind="additionalProps"
  @keypress.enter="emit('enter', $event)"
  v-on="control.handleAddKey ? { 'add-key': control.handleAddKey } : {}"
)
</template>

<style lang="stylus">
.ZActionControlMultiInput
  textarea
    margin-top 4px

  &.v-textarea.v-text-field--enclosed textarea
    margin-top 0
</style>

<script setup lang="ts">
import { useActionControl } from './useActionControl';

import { useI18n } from '@/composables/plugins';
import { check } from '@/lib/utils';

import ZMultiInput from '@/components/ui/molecules/ZMultiInput.vue';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Data = any;

interface ZActionControlMultiInputProps {
  /**
   * The control object
   */
  control: ActionControl<'multiInput', keyof Data, Data>;
  /**
   * The parent action 'data' object
   */
  data: Data;
  /**
   * The control’s value
   * @model
   */
  value?: Record<string, string>;
}

const props = withDefaults(defineProps<ZActionControlMultiInputProps>(), {
  value: () => ({}),
});
const emit = defineEmits<{
  /**
   * Emitted when the 'enter' key is pressed.
   */
  (name: 'enter', event: KeyboardEvent): void;
}>();

const { t } = useI18n();

const {
  modelValue,
  canShow,
  style,
  label,
  rules,
  color,
  isDisabled,
  hint,
  placeholder,
  mask,
  message,
  areDetailsHidden,
  additionalProps,
} = useActionControl(props);

// Synchronize selected key with control if property is a ref
const control = computed(() => props.control);
const selectedKey = computed({
  get: () => unref(props.control.selectedKey),
  set: value => {
    if (isRef(control.value.selectedKey)) {
      control.value.selectedKey.value = value;
    }
  },
});

const textareaHint = computed(
  () =>
    hint.value ||
    (!check.hasTouch() && props.control.textarea
      ? t('action__textarea_hint')
      : '')
);
const autocapitalize = computed(() =>
  props.control.autocapitalize ? 'on' : 'off'
);

const componentRef = ref<TemplateRef | null>(null);

function focus(): void {
  componentRef.value?.focus?.();
}

function resetValidation(): void {
  componentRef.value?.resetValidation?.();
}

defineExpose({
  /**
   * Focus the input
   */
  focus,
  /**
   * Reset input validation
   */
  resetValidation,
});
</script>
