import { useVModelProxy, useColors } from '@/composables';
import { useI18n } from '@/composables/plugins';
import { capitalize, getRawSvgIcon, isFunc } from '@/lib/utils';
import { mdiInformation } from '@/config/mdi';
import {
  requiredRule,
  arrayRequiredRule,
  priceRequiredRule,
} from '@/lib/helpers/rules';

import type { MaybeActionDataFactory } from '@/types/actions';
import type { Style } from '@fifteen/design-system-vue';

export type ActionControlProps<
  TypeT extends ActionControlType,
  KeyT extends keyof DataT,
  DataT extends object,
> = {
  control: ActionControl<TypeT, KeyT, DataT> | ActionControlSeparator<DataT>;
  data: DataT;
  value?: DataT[KeyT];
  [key: string]: unknown;
};

export interface UseActionControlReturn<
  KeyT extends keyof DataT,
  DataT extends object,
> {
  /**
   * The control two-way bound value (v-model)
   */
  modelValue: WritableComputedRef<DataT[KeyT] | undefined>;
  /**
   * Based on a factory or a value, get the value, passing action’s 'data' to the factory
   * @param maybeFactory - Factory or value
   */
  generate<T>(maybeFactory: MaybeActionDataFactory<T, DataT>): T;
  /**
   * Whether the control can be displayed based on its condition and privilege
   */
  canShow: ComputedRef<boolean>;
  /**
   * The control style
   */
  style: ComputedRef<Style>;
  /**
   * The control label
   */
  label: ComputedRef<string>;
  /**
   * The control rules
   */
  rules: ComputedRef<VuetifyRule[]>;
  /**
   * The control resolved theme color
   */
  color: ComputedRef<string | undefined>;
  /**
   * Icon to display in the control
   */
  icon: ComputedRef<string | undefined>;
  /**
   * Whether the control is disabled
   */
  isDisabled: ComputedRef<boolean | undefined>;
  /**
   * The control hint
   */
  hint: ComputedRef<string>;
  /**
   * The control placeholder, if available
   */
  placeholder: ComputedRef<string | undefined>;
  /**
   * The control mask, if available
   */
  mask: ComputedRef<string | string[] | undefined>;
  /**
   * The control message
   */
  message: ComputedRef<string>;
  /**
   * Whether the control details are hidden
   */
  areDetailsHidden: ComputedRef<boolean>;
  /**
   * Additional props for the control
   */
  additionalProps: ComputedRef<Record<string, unknown>>;
}

const defaultControlColor: Color = {
  dark: 'primary',
  light: 'info',
};

export function useActionControl<
  TypeT extends ActionControlType,
  KeyT extends keyof DataT,
  DataT extends object,
>(
  props: ActionControlProps<TypeT, KeyT, DataT>
): UseActionControlReturn<KeyT, DataT> {
  // Control needs to be reactively updated when props change
  const control = computed(
    () => props.control as ActionControl<TypeT, keyof DataT, DataT>
  );
  // While data is a "reactive" property (a proxy)
  const data = props.data;

  const { tin } = useI18n();
  const { themeColor } = useColors();

  const modelValue = useVModelProxy({
    props,
    transform: value => handleInput(value),
  });

  function handleInput(
    value: DataT[KeyT] | undefined
  ): DataT[KeyT] | undefined {
    if (!value) return value;

    if (control.value.controlType === 'combobox') {
      // @ts-expect-error we are sure that the control is a v-combobox, and the value has `internalSearch` property
      value.internalSearch = '';
    }

    const newValue =
      'handle' in control.value && control.value.handle
        ? control.value.handle(value)
        : 'autocapitalize' in control.value && control.value.autocapitalize
          ? capitalize(value as string)
          : value;

    return newValue as DataT[KeyT];
  }

  function generate<T>(maybeFactory: MaybeActionDataFactory<T, DataT>): T {
    return isFunc(maybeFactory) ? maybeFactory(data) : (maybeFactory as T);
  }

  const canShow = computed(() => {
    return (
      (!control.value.condition || control.value.condition(data)) &&
      (!('privilege' in control.value) || control.value.privilege !== false)
    );
  });

  const style = computed(() => {
    return 'width' in control && control.width
      ? { width: `calc(${generate(control.width)} - 16px)` }
      : {};
  });

  const label = computed(() => {
    if (!('label' in control.value) || !control.value.label) return '';
    return `${tin(control.value.label)}${
      control.value.unit ? ` (${tin(control.value.unit)})` : ''
    }`;
  });

  const rules = computed(() => {
    let rules: VuetifyRule[] = [];
    // Return empty rules on hidden control
    if (!canShow.value) return rules;
    // Create the rule corresponding to a `required` control, if given
    if (generate(control.value.required)) {
      rules = [
        'multiple' in control.value && control.value.multiple
          ? arrayRequiredRule
          : control.value.controlType === 'price'
            ? priceRequiredRule
            : requiredRule,
      ];
    }
    // Concat additional rules
    if (control.value.rules) {
      rules = rules.concat(generate(control.value.rules));
    }
    return rules;
  });

  const color = computed(() => {
    return themeColor(
      ('color' in control.value && control.value.color) || defaultControlColor
    );
  });

  const icon = computed(() => {
    return ('icon' in control.value && control.value.icon) || undefined;
  });

  const isDisabled = computed(() => {
    return 'disabled' in control.value && generate(control.value.disabled);
  });

  const hint = computed(() => {
    return 'hint' in control.value && control.value.hint
      ? tin(control.value.hint)
      : '';
  });

  const placeholder = computed(() =>
    'placeholder' in control.value && control.value.placeholder
      ? generate(control.value.placeholder)
      : undefined
  );

  const mask = computed(() =>
    'mask' in control.value && control.value.mask
      ? generate(control.value.mask)
      : undefined
  );

  const message = computed(() => {
    if (!('messages' in control.value)) return '';
    const { messages, noMessagesIcon } = control.value;
    const currentValue = data[control.value.key];
    const iconStyle = {
      'margin-bottom': '2px',
      'margin-right': '4px',
    };
    const generatedMessages = generate(messages);
    const arrayedMessages = Array.isArray(generatedMessages)
      ? generatedMessages
      : [generatedMessages];
    const iconMarkup = noMessagesIcon
      ? ''
      : getRawSvgIcon(mdiInformation, '13px', iconStyle);
    const messagesMarkup = arrayedMessages
      .filter(message => message !== undefined)
      .flatMap(message => tin(message).split('\n'))
      .join('<br/>')
      .replace(/%v/g, String(currentValue));
    return generatedMessages && arrayedMessages.length
      ? `<div class="inline-hint">${iconMarkup}${messagesMarkup}</div>`
      : '';
  });

  const areDetailsHidden = computed(() => {
    return !rules.value.length && !hint.value && !message.value;
  });

  const additionalProps = computed(() => {
    return ('props' in control.value && control.value.props) || {};
  });

  return {
    modelValue,
    generate,
    canShow,
    style,
    label,
    rules,
    color,
    icon,
    isDisabled,
    hint,
    placeholder,
    mask,
    message,
    areDetailsHidden,
    additionalProps,
  };
}
