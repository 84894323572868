<template lang="pug">
VNavigationDrawer.ZNavigationDrawer(
  :value="miniVariant ? true : isOpen"
  :mini-variant="!isOpen && miniVariant"
  :width="184"
  :height="navigationDrawerHeight"
  :mini-variant-width="48"
  persistent
  floating
  disable-resize-watcher
  touchless
  app
  @input="!miniVariant && (isOpen = $event)"
)
  ZNavigationContent(
    :navigation-items="items"
    :dark="isDark"
    :mini-variant="!isOpen && miniVariant"
    :is-developer-mode="isDeveloperMode"
    @toggle="isOpen = !isOpen"
  )
</template>

<style lang="stylus">
.ZNavigationDrawer.v-navigation-drawer
  // mobile overlay has z-index 5
  z-index 11
  min-width auto
  padding 6px
  top 52px

  &.v-navigation-drawer--mini-variant
    .v-list__tile
      border-radius 14px
      height 36px
      width 36px
      padding 0 6px
      display block

  a
    font-size 14px

  +media-down-md()
    top 0
    border-top-right-radius 12px
    border-bottom-right-radius 12px

  .v-list
    background transparent
    display flex
    flex-direction column
    justify-content center
    padding 6px
    gap 2px

  .v-list__tile
    border-radius 14px
    height 36px
    padding 0 7px

  .v-divider
    margin 6px 2px

  .v-list__tile--active
    background alpha($color-fifteen_control, 0.1)

    &.theme--dark
      background alpha($color-fifteen_control, 0.2)

  .v-list__tile__content
    margin-left 16px

  &.theme--light,
  &.theme--dark
    background transparent

  +media-down-md()
    &.theme--light
      background-color: $colors.grey.lighten-5

    &.theme--dark
      background-color $color-grey_alt

  &.v-navigation-drawer--mini-variant .v-divider
    display block !important
    margin 6px 2px

.v-navigation-drawer--mini-variant .v-list__tile__action,
.v-navigation-drawer .v-list__tile__action
  min-width auto
</style>

<script setup lang="ts">
import {
  useVuetifyBreakpoints,
  useVModelProxy,
  useDarkTheme,
} from '@/composables';

import ZNavigationContent from '@/components/ui/molecules/ZNavigationContent.vue';

import type { NavigationItem } from '@/composables';

type Version = {
  name: string;
  number: string;
  icon?: string;
};

interface ZNavigationDrawerProps {
  /**
   * Controls the drawer open state
   * @model
   */
  value?: boolean;
  /**
   * List of navigation items
   */
  items?: NavigationItem[];
  /**
   * App versions to be displayed on the navigation drawer footer
   */
  versions?: Version[];
  /**
   * Adjust the style of the drawer when the developer mode is active
   */
  isDeveloperMode?: boolean;
}

const props = withDefaults(defineProps<ZNavigationDrawerProps>(), {
  value: false,
  items: () => [],
  versions: () => [],
  isDeveloperMode: true,
});

const { lgAndUp, mdAndDown } = useVuetifyBreakpoints();
const { isDark } = useDarkTheme();

const isOpen = useVModelProxy({ props });

const miniVariant = computed(() => lgAndUp.value);
const navigationDrawerHeight = computed(() => {
  const top = mdAndDown.value ? 0 : 48;
  return `calc(100% - ${top}px)`;
});
</script>
