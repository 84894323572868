import axios from 'axios';

export interface UseRuntimeConfigReturn {
  fetchRuntimeConfig: () => Promise<void>;
  runtimeConfig: Partial<PublicRuntimeConfig>;
}

const runtimeConfig = reactive<Partial<PublicRuntimeConfig>>({});

export function useRuntimeConfig(): UseRuntimeConfigReturn {
  async function fetchRuntimeConfig(): Promise<void> {
    const { data } = await axios.get<PublicRuntimeConfig>('/api/env', {});
    Object.assign(runtimeConfig, data);
  }

  return {
    fetchRuntimeConfig,
    runtimeConfig,
  };
}
