import { EntityType } from '#/core-api/enums';

import { pointOfInterestIcon, stationIcon, vehicleIcon } from '@/config/icons';

export function entityType(entityType: EntityType): DisplayProperties {
  switch (entityType) {
    default:
    case EntityType.Unknown:
      return {
        message: 'entity__unknown_type',
      };
    case EntityType.Station:
      return {
        message: 'entity__station',
        icon: stationIcon,
      };
    case EntityType.Parking:
      return {
        message: 'entity__parking',
        icon: 'mdi-parking',
      };
    case EntityType.Vehicle:
      return {
        message: 'entity__vehicle',
        icon: vehicleIcon,
      };
    case EntityType.PointOfInterest:
      return {
        message: 'entity__point_of_interest',
        icon: pointOfInterestIcon,
      };
  }
}
