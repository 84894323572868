import {
  BikeStateMaintenanceState,
  BikeStateSource,
  LockState,
  TripState,
} from '#/core-api/enums';

export const bikeTripStateList = [
  TripState.Unchanged,
  TripState.Available,
  TripState.Booked,
  TripState.InTrip,
  TripState.InPause,
];

export function bikeStateTripState(tripState: TripState): DisplayProperties {
  switch (tripState) {
    case TripState.Unchanged:
      return {
        message: 'Unchanged',
      };
    case TripState.Available:
      return {
        message: 'Available',
      };
    case TripState.Booked:
      return {
        message: 'Booked',
      };
    case TripState.InTrip:
      return {
        message: 'In trip',
      };
    case TripState.InPause:
      return {
        message: 'In pause',
      };
    default:
      return {
        message: `Unhandled trip state: ${tripState}`,
      };
  }
}

export const bikeLockStateList = [
  LockState.Unchanged,
  LockState.Locked,
  LockState.Unlocked,
  LockState.SoftUnlocked,
];

export function bikeStateLockState(lockState: LockState): DisplayProperties {
  switch (lockState) {
    case LockState.Unchanged:
      return {
        message: 'Unchanged',
      };
    case LockState.Locked:
      return {
        message: 'Locked',
      };
    case LockState.Unlocked:
      return {
        message: 'Unlocked',
      };
    case LockState.SoftUnlocked:
      return {
        message: 'Soft unlocked',
      };
    default:
      return {
        message: `Unhandled lock state: ${lockState}`,
      };
  }
}

export const bikeMaintenanceStateList = [
  BikeStateMaintenanceState.Unchanged,
  BikeStateMaintenanceState.InOrder,
  BikeStateMaintenanceState.MaintenanceRequired,
  BikeStateMaintenanceState.InTruck,
  BikeStateMaintenanceState.InWarehouse,
];

export function bikeStateMaintenanceState(
  maintenanceState: BikeStateMaintenanceState
): DisplayProperties {
  switch (maintenanceState) {
    case BikeStateMaintenanceState.Unchanged:
      return {
        message: 'Unchanged',
      };
    case BikeStateMaintenanceState.InOrder:
      return {
        message: 'In order',
      };
    case BikeStateMaintenanceState.MaintenanceRequired:
      return {
        message: 'Maintenance required',
      };
    case BikeStateMaintenanceState.InTruck:
      return {
        message: 'In truck',
      };
    case BikeStateMaintenanceState.InWarehouse:
      return {
        message: 'In warehouse',
      };
    default:
      return {
        message: `Unhandled maintenance state: ${maintenanceState}`,
      };
  }
}

export function bikeStateSource(source: BikeStateSource): DisplayProperties {
  switch (source) {
    case BikeStateSource.SourceUnknown:
      return {
        message: 'Unknown',
      };
    case BikeStateSource.SourceBle:
      return {
        message: 'BLE',
      };
    case BikeStateSource.SourceLwm2M:
      return {
        message: 'LWM2M',
      };
    case BikeStateSource.SourceAutolockTimeout:
      return {
        message: 'Autolock timeout',
      };
    case BikeStateSource.SourceAutolockStack:
      return {
        message: 'Autolock stack',
      };
    case BikeStateSource.SourceRfid:
      return {
        message: 'RFID',
      };
    case BikeStateSource.SourceAutolockStackMaintenance:
      return {
        message: 'Autolock stack maintenance',
      };
    case BikeStateSource.SourcePauseInactivity:
      return {
        message: 'Pause inactivity',
      };
    case BikeStateSource.SourceAutolockStackFallback:
      return {
        message: 'Autolock stack fallback',
      };
    case BikeStateSource.SourceAutolockDocked:
      return {
        message: 'Autolock docked',
      };
    default:
      return {
        message: `Unhandled source: ${source}`,
      };
  }
}
