import {
  FastenerType,
  PowerSource,
  FastenerMaintenanceState,
  FastenerStatus,
  FastenerAllowedInOrderStatus,
  Availability,
} from '#/core-api/enums';

import { defaultEnumDisplayProperties } from '@/lib/utils';

export function fastenerType(type: FastenerType): DisplayProperties {
  switch (type) {
    case FastenerType.Stack:
      return {
        message: 'fasteners__stack',
        icon: 'svg:fastener_stack',
      };
    case FastenerType.Virtual:
      return {
        message: 'fasteners__virtual',
        icon: 'svg:fastener_virtual',
      };
    case FastenerType.Dock:
      return {
        message: 'fasteners__dock',
        icon: 'svg:fastener_dock',
      };
    default:
      return defaultEnumDisplayProperties(type);
  }
}

export function fastenerAvailability(value: Availability): DisplayProperties {
  switch (value) {
    case Availability.AvailableForSharing:
      return {
        message: 'fasteners__available_for_sharing',
        class: 'secondary_darker',
        icon: 'mdi-share-variant',
      };
    case Availability.AvailableForLeasing:
      return {
        message: 'fasteners__available_for_leasing',
        class: { light: 'other', dark: 'other_lighter' },
        icon: 'svg:leasing_contract',
      };
    default:
      return defaultEnumDisplayProperties(value);
  }
}

export function fastenerPowerSource(type: PowerSource): DisplayProperties {
  switch (type) {
    case PowerSource.None:
      return {
        message: 'fasteners__power_source__none',
      };
    case PowerSource.Battery:
      return {
        message: 'fasteners__power_source__battery',
      };
    case PowerSource.Grid:
      return {
        message: 'fasteners__power_source__grid',
      };
    case PowerSource.Sector:
      return {
        message: 'fasteners__power_source__sector',
      };
    default:
      return defaultEnumDisplayProperties(type);
  }
}

export function fastenerMaintenanceState(
  state: FastenerMaintenanceState
): DisplayProperties {
  switch (state) {
    case FastenerMaintenanceState.NotSet:
      return {
        message: 'fasteners__maintenance_state_not_set',
        class: 'secondary',
        icon: 'mdi-minus-circle',
      };
    case FastenerMaintenanceState.InField:
      return {
        message: 'fasteners__in_field',
        class: 'primary',
        icon: 'mdi-check-circle',
      };
    case FastenerMaintenanceState.NeedMaintenanceInOrder:
      return {
        message: 'fasteners__need_maintenance_in_order',
        class: 'warning',
        icon: 'mdi-alert-circle',
      };
    case FastenerMaintenanceState.NeedMaintenanceOutOfOrder:
      return {
        message: 'fasteners__need_maintenance_out_of_order',
        class: 'error',
        icon: 'mdi-alert-circle',
      };
    case FastenerMaintenanceState.UnderMaintenance:
      return {
        message: 'fasteners__under_maintenance',
        class: 'accent_alt1',
        icon: 'svg:wrench_circle',
      };
    default:
      return defaultEnumDisplayProperties(state);
  }
}

export function fastenerStatus(status?: FastenerStatus): DisplayProperties {
  switch (status) {
    case FastenerStatus.NotSet:
      return {
        message: 'fasteners__status_not_set',
        class: 'secondary',
      };
    case FastenerStatus.Active:
      return {
        message: 'fasteners__available',
        class: 'primary',
      };
    case FastenerStatus.Inactive:
      return {
        message: 'fasteners__inactive',
        class: 'disabled',
      };
    default:
      return defaultEnumDisplayProperties(status);
  }
}

export function fastenerAllowedInOrderStatus(
  status: FastenerAllowedInOrderStatus
): DisplayProperties {
  switch (status) {
    case FastenerAllowedInOrderStatus.AllowedNotSet:
      return {
        message: 'fasteners__allowed_in_order_status_not_set',
      };
    case FastenerAllowedInOrderStatus.AllowedInOrder:
      return {
        message: 'fasteners__allowed_in_order',
      };
    case FastenerAllowedInOrderStatus.AllowedOutOfOrder:
      return {
        message: 'fasteners__allowed_out_of_order',
        class: 'error',
        icon: 'mdi-cog',
      };
    default:
      return defaultEnumDisplayProperties(status);
  }
}
