export const fields = [
  'id',
  '_created',
  'encoded_coordinates',
  'geo_areas_unallowed',
  'metadata',
  'label',
  'status',
  'warehouse',
  'required_role',
  'country_code',
  'created_by',
  '_last_updated_at',
  'last_updated_by',
  'free_floating_not_allowed',
  'soft_unlock_timeout',
  'suspicious_trip_params',
  'trip_cooldown',
  'policies',
  'min_soc_start_delta',
  'force_lock',
] satisfies ModelField<'area.ServiceableArea'>[];
