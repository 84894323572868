import { isValid } from 'date-fns';

import { useI18n } from '@/composables/plugins';
import { processTime, getTimezoneOffset } from '@/lib/utils';

const { tc } = useI18n();

export function quickRangeText(name: string, excludeCurrent?: boolean): string {
  const { mode, period, count } = processTime(name, excludeCurrent);
  return tc(`app__${mode}_x_${period}s`, count, { count });
}

export function dateToDisplay(date?: Date | string | number): string {
  if (!date) return '';
  const newDate = new Date(
    new Date(date).getTime() - getTimezoneOffset(date) * 60 * 1000
  );
  if (!isValid(newDate)) return '';
  return newDate.toISOString().substring(0, 10);
}

export function padTime(number: number | string): string {
  if (Number(number) < 10) return '0' + number;
  else return String(number);
}

export function timeToDisplay(date?: Date | string | number): string {
  if (!date) return '';
  const newDate = new Date(date);
  if (!isValid(newDate)) return '';
  return padTime(newDate.getHours()) + ':' + padTime(newDate.getMinutes());
}

export function formatTimeRange(range?: TimeRange | null): string {
  if (!range) return '';
  const { type, name, from, to, excludeCurrent } = range;

  let displayedRange = '';
  if (type === 'quick' && name) {
    displayedRange = quickRangeText(name, excludeCurrent ?? false);
  } else if (type === 'range' && from && to) {
    const displayedFrom = dateToDisplay(from) + ', ' + timeToDisplay(from);
    const displayedTo = dateToDisplay(to) + ', ' + timeToDisplay(to);
    displayedRange = displayedFrom + ' → ' + displayedTo;
  }

  return displayedRange;
}
