import { TicketStatus, TicketType } from '@/store/types/core-api/enums';
import StoreModule from '@/store/core/store-module';
import { noteFields } from '@/models/fields';
import { internalEmailPopulateConfig } from '@/lib/helpers/populate';

import type { ApiSchema } from '#/core-api';
import type { PopulatedField } from '@/store/core/populate';
import type { Get, Post, Patch, Delete } from '@/store/types/core-api';

declare global {
  /**
   * Ticket of type note
   */
  type Note = DeepMerge<
    ApiSchema['ticket.Ticket'],
    {
      id: string;
      type: TicketType.Note;
      creator: PopulatedField<
        ApiSchema['ticket.Ticket']['created_by'],
        ApiSchema['user.User']['email']
      >;
    }
  >;
}

export interface NoteStoreTypes {
  NOTES: [Get<'/tickets/tickets', Note[]>, Post<'/tickets/tickets'>];
  NOTE: [Patch<'/tickets/tickets/{Id}'>, Delete<'/tickets/tickets/{Id}'>];
}

const notesModule = new StoreModule<NoteStoreTypes>();
notesModule
  .createResourceAndAction({
    type: 'NOTES',
    requestEndPoint: '/tickets/tickets',
    requestOptions: () => {
      return {
        freeze: true,
        params: {
          sort: '-created_at',
          fields: noteFields,
          query: { type: TicketType.Note },
        },
      };
    },
    populate: [
      internalEmailPopulateConfig({
        dataField: 'created_by',
      }),
    ],
    transform: data => data.tickets,
  })
  .createAction({
    type: 'NOTES',
    requestMethod: 'POST',
    requestEndPoint: '/tickets/tickets',
    requestOptions: () => ({
      data: {
        label: 'Note',
        type: TicketType.Note,
        status: TicketStatus.Open,
      },
    }),
  })
  .createAction({
    type: 'NOTE',
    requestMethod: 'PATCH',
    requestEndPoint: '/tickets/tickets/:id',
  })
  .createAction({
    type: 'NOTE',
    requestMethod: 'DELETE',
    requestEndPoint: '/tickets/tickets/:id',
  });

export default notesModule;
