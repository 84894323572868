/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Check if the given value is a function.
 * @param value - The value to check.
 */
export default function isFunc<T>(
  value: T | ((...args: any[]) => T)
): value is (...args: any[]) => T {
  return typeof value === 'function';
}
