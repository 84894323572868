import StoreModule from '@/store/core/store-module';
import { fastenerFields } from '@/models/fields';
import {
  areasPopulateConfig,
  stationsPopulateConfig,
  fleetProductPopulateConfig,
  openIssuesPopulateConfig,
  vehiclesPopulateConfig,
} from '@/lib/helpers/populate';

import type { ApiSchema } from '#/core-api';
import type {
  PopulatedField,
  PopulatedFieldArray,
} from '@/store/core/populate';
import type {
  FleetProductPopulatedType,
  FastenedBikesPopulatedType,
  FastenedVehiclesPopulatedType,
} from '@/lib/helpers/populate';
import type { Get, Post, Patch, Delete } from '@/store/types/core-api';
import type { OverridePayload } from '@/store/types/helpers/store-types';

declare global {
  /**
   * Populated Fastener
   */
  type Fastener = DeepMerge<
    ApiSchema['station.Fastener'],
    {
      id: string;
      area?: PopulatedField<
        ApiSchema['station.Fastener']['area_id'],
        ApiSchema['area.ServiceableArea']['label']
      >;
      station?: PopulatedField<
        ApiSchema['station.Fastener']['station_id'],
        ApiSchema['station.Station']['label']
      >;
      open_issues?: PopulatedFieldArray<
        ApiSchema['station.Fastener']['id'],
        Pick<ApiSchema['issue.Issue'], 'id' | 'label' | 'severity'>
      >;
      products?: FastenedBikesPopulatedType | FastenedVehiclesPopulatedType;
    } & FleetProductPopulatedType<
      ApiSchema['station.Fastener']['serial_number']
    >
  >;
}

const fastenersScopes = [
  'Fasteners',
  'StationFasteners',
  'FastenerShape',
  'Map',
  'Other',
  'Bikes',
] as const;

type ActionPathParamsOverride = {
  id: string;
  serial_number: never;
};
type ExecPathParamsOverride = {
  id: string;
  serial_number: never;
  action: never;
};

export interface FastenerStoreTypes {
  FASTENERS: [Get<'/stations/fasteners', Fastener[], typeof fastenersScopes>];
  FASTENERS_COUNT: [
    Get<'/stations/fasteners/count', void, typeof fastenersScopes>,
  ];
  FASTENER: [
    Get<'/stations/fasteners/{Id}', Fastener>,
    Post<'/stations/fasteners'>,
    Patch<'/stations/fasteners/{Id}'>,
    Delete<'/stations/fasteners/{Id}'>,
  ];
  FASTENERS_AUTODIAG_DEFINITION: [
    Get<'/stations/fasteners/autodiag_definition/errors'>,
  ];
  FASTENER_EXEC: [
    OverridePayload<
      Post<'/stations/fasteners/{SerialNumber}/exec/{Action}'>,
      ActionPathParamsOverride
    >,
  ];
  FASTENER_UPDATE_FIRMWARE: [
    OverridePayload<
      Post<'/stations/fasteners/{SerialNumber}/exec/{Action}'>,
      ExecPathParamsOverride
    >,
  ];
  FASTENER_PRODUCT_CONFIG_UPDATE: [
    OverridePayload<
      Post<'/stations/fasteners/{SerialNumber}/exec/{Action}'>,
      ExecPathParamsOverride
    >,
  ];
  FASTENER_PRODUCT_REBOOT: [
    OverridePayload<
      Post<'/stations/fasteners/{SerialNumber}/exec/{Action}'>,
      ExecPathParamsOverride
    >,
  ];
  FASTENER_REGISTER_UPDATE: [
    OverridePayload<
      Post<'/stations/fasteners/{SerialNumber}/exec/{Action}'>,
      ExecPathParamsOverride
    >,
  ];
  FASTENER_SYNC: [
    OverridePayload<
      Post<'/stations/fasteners/{SerialNumber}/sync'>,
      ExecPathParamsOverride
    >,
  ];
  FASTENER_UPLOAD_LOGS: [
    OverridePayload<
      Post<'/stations/fasteners/{SerialNumber}/exec/{Action}'>,
      ExecPathParamsOverride
    >,
  ];
  FASTENER_UPLOAD_RAW_DATA: [
    OverridePayload<
      Post<'/stations/fasteners/{SerialNumber}/exec/{Action}'>,
      ExecPathParamsOverride
    >,
  ];
}

const fastenersModule = new StoreModule<FastenerStoreTypes>();
fastenersModule
  .createResourceAndAction({
    type: 'FASTENERS',
    requestEndPoint: '/stations/fasteners',
    scopes: fastenersScopes,
    withGlobalUsageQuery: true,
    requestOptions: () => ({
      params: {
        fields: fastenerFields,
      },
    }),
    populate: [
      areasPopulateConfig(),
      stationsPopulateConfig(),
      openIssuesPopulateConfig({
        dataField: 'id',
      }),
    ],
    transform: data => data.fasteners,
  })
  .createResourceAndAction({
    type: 'FASTENER',
    requestEndPoint: '/stations/fasteners/:id',
    transform: data => data.fastener,
    populate: [
      vehiclesPopulateConfig(),
      fleetProductPopulateConfig({
        dataField: 'serial_number',
      }),
      areasPopulateConfig(),
      stationsPopulateConfig(),
      openIssuesPopulateConfig({
        dataField: 'id',
      }),
    ],
  })
  .createResourceAndAction({
    type: 'FASTENERS_COUNT',
    requestEndPoint: '/stations/fasteners/count',
    scopes: fastenersScopes,
    withGlobalUsageQuery: true,
  })
  .createResourceAndAction({
    type: 'FASTENERS_AUTODIAG_DEFINITION',
    requestEndPoint: 'stations/fasteners/autodiag_definition/errors',
  })
  .createAction({
    type: 'FASTENER',
    requestEndPoint: '/stations/fasteners',
    requestMethod: 'POST',
    permission: 'gateway.fastener.add',
    longRequest: true,
  })
  .createAction({
    type: 'FASTENER',
    requestEndPoint: '/stations/fasteners/:id',
    requestMethod: 'PATCH',
    permission: 'gateway.fastener.edit',
    longRequest: true,
  })
  .createAction({
    type: 'FASTENER',
    requestEndPoint: '/stations/fasteners/:id',
    requestMethod: 'DELETE',
    permission: 'gateway.fastener.delete',
  })
  /*
   * Exec actions
   */
  .createAction({
    type: 'FASTENER_EXEC',
    requestEndPoint: '/stations/fasteners/:id/exec/:action',
    requestMethod: 'POST',
    permission: 'gateway.fastener.exec',
    longRequest: true,
  })
  .createAction({
    type: 'FASTENER_UPDATE_FIRMWARE',
    requestEndPoint: '/stations/fasteners/:id/exec/firmware_update',
    requestMethod: 'POST',
    longRequest: true,
  })
  .createAction({
    type: 'FASTENER_PRODUCT_CONFIG_UPDATE',
    requestEndPoint: '/stations/fasteners/:id/exec/product_config/0/update',
    requestMethod: 'POST',
    longRequest: true,
  })
  .createAction({
    type: 'FASTENER_PRODUCT_REBOOT',
    requestEndPoint: '/stations/fasteners/:id/exec/firmware_main/0/reboot',
    requestMethod: 'POST',
    longRequest: true,
  })
  .createAction({
    type: 'FASTENER_REGISTER_UPDATE',
    requestEndPoint: '/stations/fasteners/:id/exec/register_update',
    requestMethod: 'POST',
    longRequest: true,
  })
  .createAction({
    type: 'FASTENER_SYNC',
    requestEndPoint: '/stations/fasteners/:id/sync',
    requestMethod: 'POST',
    permission: 'gateway.fastener.sync',
    longRequest: true,
  })
  .createAction({
    type: 'FASTENER_UPLOAD_LOGS',
    requestEndPoint: '/stations/fasteners/:id/exec/upload_logs',
    requestMethod: 'POST',
    longRequest: true,
  })
  .createAction({
    type: 'FASTENER_UPLOAD_RAW_DATA',
    requestEndPoint: '/stations/fasteners/:id/exec/upload_raw_data',
    requestMethod: 'POST',
    longRequest: true,
  });

export default fastenersModule;
