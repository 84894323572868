import type { ZAlertProps } from '@/components/ui/molecules/ZAlert.vue';

const alertState = reactive<ZAlertProps>({
  error: null,
  message: '',
  type: undefined,
  actions: [],
  icon: null,
});

export function useAlert(): ZAlertProps {
  return alertState;
}
