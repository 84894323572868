export const fields = [
  'id',
  'area_id',
  'station_id',
  'label',
  'type',
  'products',
  'capacity',
  'location',
  'constraints',
  'availability',
  'status',
  'power_source',
  'serial_number',
  'software_version',
  'lwm2m_info',
  'auto_diags',
  'virtual_area_encoded',
  'allowed_product_versions',
  'has_electrical_link',
  'is_maintenance',
  'allowed_in_order_status',
  'is_full',
  'deployed_in_env',
  'powermeter',
  // @ts-expect-error - The returned field in API is `created` but its name in DB is `_created`
  '_created',
  'out_of_order',
  'maintenance_state',
  'metadata',
  'rfid_uid',
] satisfies ModelField<'station.Fastener'>[];
