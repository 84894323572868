import {
  sub,
  startOfDay,
  startOfHour,
  startOfMinute,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from 'date-fns';

/**
 * Computes the start of a time binning after substracting for the specified amount of time
 * @param timestamp - The input timestamp
 * @param subtractVal - The amount of time to substract (unit based on the binning)
 * @param binning - The binning to round to
 */
export default function computeStartOfBinning(
  timestamp: number,
  subtractVal: number,
  binning: string
): number {
  let fromDate: Date;

  switch (binning) {
    case 'minutes':
      fromDate = startOfMinute(sub(timestamp, { minutes: subtractVal }));
      break;
    case 'hours':
      fromDate = startOfHour(sub(timestamp, { hours: subtractVal }));
      break;
    case 'days':
      fromDate = startOfDay(sub(timestamp, { days: subtractVal }));
      break;
    case 'weeks':
      fromDate = startOfWeek(sub(timestamp, { weeks: subtractVal }));
      break;
    case 'months':
      fromDate = startOfMonth(sub(timestamp, { months: subtractVal }));
      break;
    case 'years':
      fromDate = startOfYear(sub(timestamp, { years: subtractVal }));
      break;
    default:
      throw new Error('Invalid slice');
  }

  return fromDate.getTime();
}
