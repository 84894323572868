import { UserSex, UserStatus, OptInType } from '#/core-api/enums';

import { defaultEnumDisplayProperties } from '@/lib/utils';

export function userStatus(status?: UserStatus): DisplayProperties {
  switch (status) {
    case UserStatus.NotSet:
      return {
        message: 'users__status_not_set',
        class: 'secondary',
      };
    case UserStatus.Inactive:
      return {
        message: 'users__inactive',
        class: 'secondary',
      };
    case UserStatus.HasBooking:
      return {
        message: 'users__has_booking',
        class: 'warning',
      };
    case UserStatus.InTrip:
      return {
        message: 'users__in_trip',
        class: 'success',
        icon: 'mdi-play-circle',
      };
    case UserStatus.InPause:
      return {
        message: 'users__in_pause',
        class: 'disabled',
        icon: 'mdi-pause-circle',
      };
    case UserStatus.Suspended:
      return {
        message: 'users__suspended',
        class: 'error',
        icon: 'mdi-account-off',
      };
    case UserStatus.NeedsPayment:
      return {
        message: 'users__needs_payment',
        class: 'warning',
        icon: 'svg:coin_euro_alert',
      };
    case UserStatus.Anonymized:
      return {
        message: 'users__anonymized',
        class: 'black',
        icon: 'mdi-sunglasses',
      };
    default:
      return defaultEnumDisplayProperties(status);
  }
}

export function optIns(optIn?: OptInType): DisplayProperties {
  switch (optIn) {
    case OptInType.NotSet:
      return {
        message: 'users__opt_in_not_set',
        class: 'secondary',
      };
    case OptInType.TermsAndConditions:
      return {
        message: 'users__terms_and_conditions',
      };
    case OptInType.PrivatePolicy:
      return {
        message: 'users__private_policy',
      };
    case OptInType.Newsletter:
      return {
        message: 'users__newsletter',
      };
    case OptInType.InvoiceSubscriptionSharing:
      return {
        message: 'users__invoice_subscription_sharing',
      };
    case OptInType.InvoiceSubscriptionLeasing:
      return {
        message: 'users__invoice_subscription_leasing',
      };
    case OptInType.InvoiceIntentSharing:
      return {
        message: 'users__invoice_intent_sharing',
      };
    case OptInType.GeolocationSharing:
      return {
        message: 'users__geolocation_sharing',
      };
    case OptInType.GeolocationLeasing:
      return {
        message: 'users__geolocation_leasing',
      };
    case OptInType.Tracking:
      return {
        message: 'users__tracking',
      };
    default:
      return defaultEnumDisplayProperties(optIn);
  }
}

export function userSex(sex?: UserSex): DisplayProperties {
  switch (sex) {
    case UserSex.NotSet:
      return {
        message: 'user__sex_not_set',
      };
    case UserSex.Male:
      return {
        message: 'users__m',
      };
    case UserSex.Female:
      return {
        message: 'users__f',
      };
    default:
      return defaultEnumDisplayProperties(sex);
  }
}

export function deviceOs(name: 'android' | 'ios' | 'iOS'): DisplayProperties {
  switch (name) {
    case 'android':
      return {
        message: name,
        icon: 'mdi-android',
        class: 'android',
      };
    case 'ios':
    case 'iOS':
      return {
        message: name,
        icon: 'mdi-apple',
        class: 'grey',
      };
    default:
      return defaultEnumDisplayProperties(name);
  }
}
