import {
  LockedStatus,
  BikeStatus,
  BikeMaintenanceState,
  LostInfoStatus,
  BikeAccessibility,
  StackProductCategory,
  DockChargerState,
  DockDockCategory,
  DockDockStatus,
  PowerSavingMode,
  PowerTransferLastElectionResult,
} from '#/core-api/enums';

import { appConfig } from '@/config';

export function bikeStatus(status?: BikeStatus): DisplayProperties {
  switch (status) {
    default:
    case BikeStatus.NotSet:
      return {
        message: 'bikes__status_not_set',
        class: 'secondary',
      };
    case BikeStatus.Available:
      return {
        message: 'bikes__available',
        class: 'primary',
      };
    case BikeStatus.Booked:
      return {
        message: 'bikes__booked',
        class: 'warning',
      };
    case BikeStatus.InUse:
      return {
        message: 'bikes__in_trip',
        class: 'success',
        icon: 'mdi-play-circle',
      };
    case BikeStatus.Paused:
      return {
        message: 'bikes__in_pause',
        class: 'disabled',
        icon: 'mdi-pause-circle',
      };
  }
}

export function bikeLockedStatus(
  lockedStatus?: LockedStatus,
  warn?: boolean
): DisplayProperties {
  switch (lockedStatus) {
    default:
    case LockedStatus.LockedstatusNotSet:
      return {
        message: 'bikes__locked_status_not_set',
        class: 'secondary',
      };
    case LockedStatus.Locked:
      return {
        message: 'bikes__locked',
        class: 'success',
        icon: 'mdi-lock',
      };
    case LockedStatus.Unlocked:
      return {
        message: 'bikes__unlocked',
        class: warn ? 'error' : 'grey',
        icon: warn ? 'svg:lock_open_alert' : 'mdi-lock-open',
      };
    case LockedStatus.SoftUnlocked:
      return {
        message: 'bikes__soft_unlocked',
        class: warn ? 'error' : 'grey',
        icon: 'mdi-lock-open-minus',
      };
  }
}

export function bikeMaintenanceState(
  maintenanceState?: BikeMaintenanceState
): DisplayProperties & {
  chartColor: string;
} {
  switch (maintenanceState) {
    default:
    case BikeMaintenanceState.NotSet:
      return {
        message: 'bikes__not_set',
        class: 'secondary',
        chartColor: 'secondary',
      };
    case BikeMaintenanceState.InField:
      return {
        message: 'bikes__in_field',
        class: 'primary',
        chartColor: 'primary',
        icon: 'mdi-check-circle',
      };
    case BikeMaintenanceState.NeedFieldMaintenance:
      return {
        message: 'bikes__need_field_maintenance',
        class: 'warning',
        chartColor: 'warning',
        icon: 'mdi-alert-circle',
      };
    case BikeMaintenanceState.Recycled:
      return {
        message: 'bikes__recycled',
        class: 'success_alt1darker',
        chartColor: 'success_alt1_darker',
        icon: 'mdi-recycle',
      };
    case BikeMaintenanceState.InWarehouseLvl1:
      return {
        message: 'bikes__in_warehouse_level_1',
        class: 'accent',
        chartColor: 'accent_darker',
        icon: 'mdi-home-floor-1',
      };
    case BikeMaintenanceState.InWarehouseLvl2:
      return {
        message: 'bikes__in_warehouse_level_2',
        class: 'accent_alt1',
        chartColor: 'accent_alt1',
        icon: 'mdi-home-floor-2',
      };
    case BikeMaintenanceState.ToSetup:
      return {
        message: 'bikes__to_setup',
        class: 'secondary_darker',
        chartColor: 'secondary_darker',
        icon: 'mdi-cog',
      };
    case BikeMaintenanceState.InWarehouseExitZone:
      return {
        message: 'bikes__in_warehouse_exit_zone',
        class: { light: 'other', dark: 'other_lighter' },
        chartColor: 'other',
        icon: 'svg:home_exit',
      };
    case BikeMaintenanceState.InTruckForReallocation:
      return {
        message: 'bikes__in_truck_for_reallocation',
        class: 'disabled',
        chartColor: 'disabled',
        icon: 'mdi-truck-delivery',
      };
    case BikeMaintenanceState.InWarehouseLvl1Plus:
      return {
        message: 'bikes__in_warehouse_level_1_plus',
        class: 'accent',
        chartColor: 'accent',
        icon: 'svg:home_1_plus',
      };
    case BikeMaintenanceState.InWarehouseLvl3:
      return {
        message: 'bikes__in_warehouse_level_3',
        class: 'accent_alt1',
        chartColor: 'accent_alt1_lighter2',
        icon: 'mdi-home-floor-3',
      };
    case BikeMaintenanceState.ToRecycle:
      return {
        message: 'bikes__to_recycle',
        class: { light: 'disabled_alt1_darker', dark: 'disabled_alt1' },
        chartColor: 'disabled_alt1',
        icon: 'mdi-recycle',
      };
    case BikeMaintenanceState.NeedWarehouse:
      return {
        message: 'bikes__need_warehouse',
        class: 'error',
        chartColor: 'error',
        icon: 'mdi-home-alert',
      };
    case BikeMaintenanceState.InTruckToWarehouse:
      return {
        message: 'bikes__in_truck_to_warehouse',
        class: 'other_alt1',
        chartColor: 'other_alt1',
        icon: 'mdi-ambulance',
      };
    case BikeMaintenanceState.EntryZone:
      return {
        message: 'bikes__in_warehouse_entry_zone',
        class: 'accent',
        chartColor: 'accent_darker2',
        icon: 'svg:home_entry',
      };
    case BikeMaintenanceState.PreExitZone:
      return {
        message: 'bikes__in_warehouse_pre_exit_zone',
        class: { light: 'other', dark: 'other_lighter' },
        chartColor: 'other_darker',
        icon: 'svg:home_exit',
      };
  }
}

export const bikeMaintenanceStateList = [
  BikeMaintenanceState.InField,
  BikeMaintenanceState.NeedFieldMaintenance,
  BikeMaintenanceState.NeedWarehouse,
  BikeMaintenanceState.InTruckToWarehouse,
  BikeMaintenanceState.InTruckForReallocation,
  BikeMaintenanceState.EntryZone,
  BikeMaintenanceState.InWarehouseLvl1,
  BikeMaintenanceState.InWarehouseLvl1Plus,
  BikeMaintenanceState.InWarehouseLvl2,
  BikeMaintenanceState.InWarehouseLvl3,
  BikeMaintenanceState.PreExitZone,
  BikeMaintenanceState.InWarehouseExitZone,
  BikeMaintenanceState.ToSetup,
  BikeMaintenanceState.ToRecycle,
  BikeMaintenanceState.Recycled,
];

export function bikeOutOfOrder(
  outOfOrder: boolean | string
): DisplayProperties {
  switch (outOfOrder) {
    default:
    case false:
      return {
        message: 'bikes__in_order',
        class: 'primary',
      };
    case true:
      return {
        message: 'bikes__out_of_order',
        class: 'error',
      };
  }
}

export const bikePriorityList = [1, 2, 4, 5, 6];

export function bikePriority(priority?: number): DisplayProperties & {
  opacity?: number;
  small?: boolean;
} {
  switch (priority) {
    default:
    case 0:
      return {
        message: '',
        class: '',
        icon: '',
      };
    case 1:
      return {
        message: 'bikes__unlocked',
        class: 'error_alt3',
        opacity: 1,
        icon: 'mdi-rhombus',
      };
    case 2:
      return {
        message: 'bikes__in_search',
        class: 'warning_alt1',
        opacity: 1,
        icon: 'mdi-rhombus',
      };
    case 3:
      return {
        message: 'bikes__low_battery',
        class: 'warning_alt1',
        opacity: 1,
        icon: 'mdi-rhombus',
      };
    case 4:
    case 4.1:
    case 4.2:
    case 4.3:
      const index = Number(String(priority).split('.')[1]) - 1;
      return {
        message:
          priority === 4
            ? 'bikes__no_trip_for_a_while'
            : 'bikes__no_trip_for_' + appConfig.noTripSince[index] + 'h',
        class: 'error_alt1',
        opacity: 0.6 + index * 0.2,
        small: true,
        icon: 'mdi-rhombus',
      };
    case 5:
      return {
        message: 'bikes__need_warehouse',
        class: 'error',
        opacity: 1,
        small: true,
        icon: 'mdi-rhombus',
      };
    case 6:
      return {
        message: 'bikes__need_field_maintenance',
        class: 'warning',
        opacity: 1,
        small: true,
        icon: 'mdi-rhombus',
      };
  }
}

export function bikeLostStatus(lostStatus: LostInfoStatus): DisplayProperties {
  switch (lostStatus) {
    default:
    case LostInfoStatus.NotSet:
      return {
        message: 'bikes__lost_status_not_set',
        class: 'secondary',
      };
    case LostInfoStatus.Tracked:
      return {
        message: 'bikes__tracked',
        class: 'primary',
        icon: 'mdi-crosshairs-gps',
      };
    case LostInfoStatus.InSearch:
      return {
        message: 'bikes__in_search',
        class: 'warning_alt1',
        icon: 'mdi-eye-outline',
      };
    case LostInfoStatus.Lost:
      return {
        message: 'bikes__lost',
        class: 'error',
        icon: 'mdi-map-marker-off',
      };
  }
}

export function bikeAccessibility(
  accessibility: BikeAccessibility
): DisplayProperties {
  switch (accessibility) {
    default:
    case BikeAccessibility.Pickup:
      return {
        message: 'bikes__pickup',
      };
    case BikeAccessibility.Delivery:
      return {
        message: 'bikes__delivery',
      };
    case BikeAccessibility.Spare:
      return {
        message: 'bikes__spare',
      };
  }
}

export function bikeStacked(stacked: number): DisplayProperties {
  switch (stacked) {
    default:
    case 0:
      return {
        message: 'bikes__stacked_right',
      };
    case 1:
      return {
        message: 'bikes__unstacked_right',
      };
  }
}

export function bikeStackedNextBike(
  stackedNextBike: number
): DisplayProperties {
  switch (stackedNextBike) {
    default:
    case 0:
      return {
        message: 'bikes__stacked_left',
      };
    case 1:
      return {
        message: 'bikes__unstacked_left',
      };
  }
}

export function bikeStackProductCategory(
  productCategory: StackProductCategory
): DisplayProperties {
  switch (productCategory) {
    default:
    case StackProductCategory.NotSet:
      return {
        message: 'bikes__unknown_product_category',
      };
    case StackProductCategory.Bike:
      return {
        message: 'bikes__bike_product_category',
      };
    case StackProductCategory.ElectricStation:
      return {
        message: 'bikes__electric_station_product_category',
      };
    case StackProductCategory.PassiveStation:
      return {
        message: 'bikes__passive_station_product_category',
      };
    case StackProductCategory.PassiveMaintenanceStation:
      return {
        message: 'bikes__passive_maintenance_station_product_category',
      };
  }
}

export function bikeDockChargerState(
  chargerState: DockChargerState
): DisplayProperties {
  switch (chargerState) {
    default:
    case DockChargerState.NotPresent:
      return {
        message: 'bikes__charger_state__not_present',
      };
    case DockChargerState.Stopped:
      return {
        message: 'bikes__charger_state__stopped',
      };
    case DockChargerState.Started:
      return {
        message: 'bikes__charger_state__started',
      };
  }
}

export function bikeDockCategory(
  dockCategory: DockDockCategory
): DisplayProperties {
  switch (dockCategory) {
    default:
    case DockDockCategory.NotDocked:
      return {
        message: 'bikes__dock_category__not_docked',
      };
    case DockDockCategory.Standard:
      return {
        message: 'bikes__dock_category__standard',
      };
    case DockDockCategory.Electrical:
      return {
        message: 'bikes__dock_category__electrical',
      };
  }
}

export function bikeDocked(docked: DockDockStatus): DisplayProperties {
  switch (docked) {
    default:
    case DockDockStatus.Unknown:
      return {
        message: 'bikes__docked_state__unknown',
      };
    case DockDockStatus.Docked:
      return {
        message: 'bikes__docked_state__docked',
      };
    case DockDockStatus.Undocked:
      return {
        message: 'bikes__docked_state__undocked',
      };
  }
}

export function bikePowerSavingMode(mode: PowerSavingMode): DisplayProperties {
  switch (mode) {
    default:
    case PowerSavingMode.ModeNone:
      return {
        message: 'bikes__power_saving__none',
      };
    case PowerSavingMode.ModeLowPower:
      return {
        message: 'bikes__power_saving__low_power',
        icon: 'mdi-battery-clock',
        class: 'disabled_alt1',
      };
    case PowerSavingMode.ModeDeepSleep:
      return {
        message: 'bikes__power_saving__deep_sleep',
        icon: 'mdi-power-sleep',
        class: 'disabled_alt1',
      };
  }
}

export function bikePowerTransferLastElectionResult(
  lastElectionResult: PowerTransferLastElectionResult
): DisplayProperties {
  switch (lastElectionResult) {
    case PowerTransferLastElectionResult.Unknown:
    default:
      return {
        message: 'bikes__power_transfer__unknown',
      };
    case PowerTransferLastElectionResult.LastAndHighBatt:
      return {
        message: 'bikes__power_transfer__last_and_high_batt',
      };
    case PowerTransferLastElectionResult.LastAndLowBatt:
      return {
        message: 'bikes__power_transfer__last_and_low_batt',
      };
    case PowerTransferLastElectionResult.MiddleInStack:
      return {
        message: 'bikes__power_transfer__middle_in_stack',
      };
    case PowerTransferLastElectionResult.LastAndNotChargeAllowed:
      return {
        message: 'bikes__power_transfer__last_and_not_charge_allowed',
      };
    case PowerTransferLastElectionResult.Not_30VCompatible:
      return {
        message: 'bikes__power_transfer__not_30v_compatible',
      };
    case PowerTransferLastElectionResult.NotInStack:
      return {
        message: 'bikes__power_transfer__not_in_stack',
      };
    case PowerTransferLastElectionResult.RankTooHigh:
      return {
        message: 'bikes__power_transfer__rank_to_high',
      };
  }
}
