<template lang="pug">
ZTooltip.ZDirectionButton(
  bottom
  :disabled="isDisabled"
)
  template(#activator)
    VBtn.ZDirectionButton__button(
      icon
      :disabled="isDisabled"
      :href="directionLink"
      target="_blank"
    )
      VIcon mdi-directions
  span {{ text }}
    ZMiniChip.ZDirectionButton__hint(
      is-hint
      dense
    )
      VIcon(size="11") mdi-information
      span {{ mapDirectionsApp }}
</template>

<style lang="stylus">
.ZDirectionButton__button
  margin 0

.ZDirectionButton__hint
  margin-left 4px
</style>

<script setup lang="ts">
import { appConfig } from '@/config';
import { useI18n, usePreferences } from '@/composables/plugins';

export interface ZDirectionButtonProps {
  /**
   * The coordinates to use for the direction button
   */
  coordinates?: number[];
}
const props = withDefaults(defineProps<ZDirectionButtonProps>(), {
  coordinates: () => [],
});

const { t } = useI18n();

const { preferences } = usePreferences();

const text = computed(() => t('map__directions'));

const coordinatesString = computed(() =>
  [...props.coordinates].reverse().join(',')
);

const mapDirectionsApp = computed(() => preferences.mapDirectionsApp);

const directionLink = computed(
  () =>
    appConfig.mapDirectionsBaseLink[mapDirectionsApp.value] +
    coordinatesString.value
);

const isDisabled = computed(
  () =>
    !props.coordinates ||
    props.coordinates.length !== 2 ||
    (!props.coordinates[0] && !props.coordinates[1])
);
</script>
