export const fields = [
  'id',
  'user_id',
  'estimation_id',
  'status',
  'pickup',
  'booked_at',
  'expires_at',
  'ended_at',
  '_created',
  'area_id',
  'metadata',
  'rental_id',
] satisfies ModelField<'rental.Booking'>[];
