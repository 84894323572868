export const fields = [
  'id',
  'label',
  'location',
  'area_id',
  // @ts-expect-error TODO: needed because this is the DB field, replace with `critical_area_encoded` when Core API fixed this
  'critical_coverage_area',
  '_created',
  'metadata',
  'nearby_freefloating_vehicle_ids',
  'urban_furnitures',
  'status',
  'out_of_order',
  'maintenance_state',
] satisfies ModelField<'station.Station'>[];
