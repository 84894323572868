import { type Module } from 'vuex';

import { appConfig } from '@/config';

import type AppError from '@/lib/classes/app-error';
import type { RootState } from '@/store';
import type { AxiosError } from 'axios';

export interface Notification {
  service: string;
  type: string;
  route: string;
  error: Error | AppError | ApiErrorData | AxiosError<ApiErrorData> | null;
  message: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  arguments: any[] | null;
  timestamp: string | number;
  new: boolean;
  debug: boolean;
}

export interface NotificationsState {
  items: Notification[];
  open: boolean;
}

export const notificationsModule = {
  namespaced: true,
  state: {
    items: [],
    open: false,
  },
  getters: {
    items: state => state.items,
    open: state => state.open,
  },
  mutations: {
    add(state, notification: Notification) {
      if (
        // if notification exists...
        state.items.reduce((result, item) => {
          const exists =
            item.service === notification.service &&
            item.type === notification.type &&
            item.route === notification.route &&
            (item.error
              ? item.error.message === notification.error?.message
              : true) &&
            (item.error &&
            'stack' in item.error &&
            notification.error &&
            'stack' in notification.error
              ? item.error.stack === notification.error.stack
              : true) &&
            item.message === notification.message &&
            JSON.stringify(item.arguments) ===
              JSON.stringify(notification.arguments);
          // update on the fly its timestamp and "newness"
          if (exists) {
            item.timestamp = notification.timestamp;
            item.new = appConfig.renewNotifications;
          }
          return exists ? false : result;
        }, true)
      ) {
        // if not, add notification
        // limit items for performances
        if (state.items.length === appConfig.notificationsLimit) {
          state.items.shift();
        }
        state.items.push(notification);
      }
    },
    clear(state) {
      state.items = [];
    },
    open(state, value: boolean) {
      state.open = value;
    },
  },
  actions: {},
} satisfies Module<NotificationsState, RootState>;

export default notificationsModule;
