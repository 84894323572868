import { AppErrorCode } from '@/enums/errors';
import AppError from '@/lib/classes/app-error';

/**
 * Convert a string to a boolean
 * @param str - String to convert to boolean
 */
export default function toBoolean(str: 'true' | 'false'): boolean {
  switch (str) {
    case 'true':
      return true;
    case 'false':
      return false;
    default:
      throw new AppError(AppErrorCode.InvalidConversionStringToBoolean, {
        args: { str },
      });
  }
}
