export const fields = [
  'benefit_id',
  'bill',
  'billing_user_id',
  'captured_payments_ids',
  'ending_at',
  'id',
  'metadata',
  'payment_in_progress_id',
  'payment_in_progress_info',
  'pre_auth_expiration',
  'pre_auth_payment_id',
  'rented_vehicle_ids',
  'scope_id',
  'status',
  'time_zone',
  'type',
  'trips_limit',
  'user_id',
  'created_at',
  'updated_at',
  'extra_properties',
] satisfies ModelField<'rental.Rental'>[];
