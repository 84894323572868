import { set } from 'vue';

import { keysOf } from '@/lib/utils';
import { useI18n } from '@/composables/plugins';

import type { AvailableLocale } from '@/config/app';

const { availableLocales } = useI18n();

export interface UseActionControlsFieldsLocalesReturn {
  /**
   * Available locales
   */
  i18nKeysLocales: Ref<AvailableLocale[]>;
  /**
   * Sync locales across action controls fields
   * @param data - Data to sync
   */
  sync: <T>(data: T) => void;
}

/**
 * Sync available locales for i18n related control fields (e.g. i18n_title, i18n_description...)
 */
export function useActionControlsFieldsLocales(): UseActionControlsFieldsLocalesReturn {
  const i18nKeysLocales = ref<AvailableLocale[]>(availableLocales);

  /**
   * Synchronize i18n keys on an object
   * Keys should start with i18n_
   */
  function syncOnObject<T extends object, U extends keyof T>(
    object: T,
    key: U
  ): void {
    const valuedObjectKeys = (
      typeof object[key] === 'object' ? keysOf(object[key] ?? {}) : []
    ).filter(locale => !!object[key][locale]);

    if ((key as string).startsWith('i18n_')) {
      // Update locales
      i18nKeysLocales.value = Array.from(
        new Set([
          ...i18nKeysLocales.value,
          ...valuedObjectKeys.filter(
            locale => !i18nKeysLocales.value.includes(locale)
          ),
        ])
      );

      i18nKeysLocales.value.forEach(i18nKey => {
        if (
          typeof object[key] === 'object' &&
          object[key] !== null &&
          !object[key].hasOwnProperty(i18nKey)
        ) {
          set(object[key], i18nKey, '');
        }
      });
    } else if (typeof object[key] === 'object' && object[key] !== null) {
      sync(object[key]);
    }
  }

  /**
   * Synchronize i18n keys on all object keys
   */
  function sync<T>(data: T): void {
    if (data === null || typeof data !== 'object') return;
    keysOf(data).forEach(firstLevelKey => {
      if (!Array.isArray(data[firstLevelKey])) {
        syncOnObject(data, firstLevelKey);
      } else {
        (data[firstLevelKey] as unknown[]).forEach(item => {
          sync(item);
        });
      }
    });
  }

  return {
    i18nKeysLocales,
    sync,
  };
}
