/**
 * Create a query for a boolean field that is false
 * @param queryKey - The key of the query
 * @param negation - Whether to negate the query
 */
export default function booleanFalseQuery(
  queryKey: string,
  negation = false
): MongoQuery {
  const operator = negation ? '$nin' : '$in';
  return {
    [queryKey]: { [operator]: [null, false] },
  };
}
