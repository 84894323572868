/**
 * Map of bounds by iso-country code in a dense representation
 * from https://gist.github.com/graydon/11198540#gistcomment-2824611
 */
export const countryCodeToBoundsMap = {
  ABW: [12.1702998, -70.2809842, 12.8102998, -69.6409842],
  AFG: [29.3772, 60.5176034, 38.4910682, 74.889862],
  AGO: [-18.038945, 11.4609793, -4.3880634, 24.0878856],
  AIA: [18.0615454, -63.6391992, 18.7951194, -62.7125449],
  ALA: [59.4541578, 19.0832098, 60.87665, 21.3456556],
  ALB: [39.6448625, 19.1246095, 42.6610848, 21.0574335],
  AND: [42.4288238, 1.4135781, 42.6559357, 1.7863837],
  ANT: [12.1544542, -68.940593, 12.1547472, -68.9403518],
  ARE: [22.6444, 51.498, 26.2822, 56.3834],
  ARG: [-55.1850761, -73.5600329, -21.781168, -53.6374515],
  ARM: [38.8404775, 43.4471395, 41.300712, 46.6333087],
  ASM: [-14.7608358, -171.2951296, -10.8449746, -167.9322899],
  ATA: [-85.0511287, -180.0, -60.0, 180.0],
  ATF: [-50.2187169, 39.4138676, -11.3139928, 77.8494974],
  ATG: [16.7573901, -62.5536517, 17.929, -61.447857],
  AUS: [-55.3228175, 72.2460938, -9.0882278, 168.2249543],
  AUT: [46.3722761, 9.5307487, 49.0205305, 17.160776],
  AZE: [38.3929551, 44.7633701, 41.9502947, 51.0090302],
  BDI: [-4.4693155, 29.0007401, -2.3096796, 30.8498462],
  BEL: [49.4969821, 2.3889137, 51.5516667, 6.408097],
  BEN: [6.0398696, 0.776667, 12.4092447, 3.843343],
  BFA: [9.4104718, -5.5132416, 15.084, 2.4089717],
  BGD: [20.3756582, 88.0075306, 26.6382534, 92.6804979],
  BGR: [41.2353929, 22.3571459, 44.2167064, 28.8875409],
  BHR: [25.535, 50.2697989, 26.6872444, 50.9233693],
  BHS: [20.7059846, -80.7001941, 27.4734551, -72.4477521],
  BIH: [42.5553114, 15.7287433, 45.2764135, 19.6237311],
  BLM: [17.670931, -63.06639, 18.1375569, -62.5844019],
  BLR: [51.2575982, 23.1783344, 56.17218, 32.7627809],
  BLZ: [15.8857286, -89.2262083, 18.496001, -87.3098494],
  BMU: [32.0469651, -65.1232222, 32.5913693, -64.4109842],
  BOL: [-22.8982742, -69.6450073, -9.6689438, -57.453],
  BRA: [-33.8689056, -73.9830625, 5.2842873, -28.6341164],
  BRB: [12.845, -59.8562115, 13.535, -59.2147175],
  BRN: [4.002508, 114.0758734, 5.1011857, 115.3635623],
  BTN: [26.702016, 88.7464724, 28.246987, 92.1252321],
  BVT: [-54.654, 2.9345531, -54.187, 3.7791099],
  BWA: [-26.9059669, 19.9986474, -17.778137, 29.375304],
  CAF: [2.2156553, 14.4155426, 11.001389, 27.4540764],
  CAN: [41.6765556, -141.00275, 83.3362128, -52.3231981],
  CCK: [-12.4055983, 96.612524, -11.6213132, 97.1357343],
  CHE: [45.817995, 5.9559113, 47.8084648, 10.4922941],
  CHL: [-56.725, -109.6795789, -17.4983998, -66.0753474],
  CHN: [8.8383436, 73.4997347, 53.5608154, 134.7754563],
  CIV: [4.1621205, -8.601725, 10.740197, -2.493031],
  CMR: [1.6546659, 8.3822176, 13.083333, 16.1921476],
  COD: [-13.459035, 12.039074, 5.3920026, 31.3056758],
  COG: [-5.149089, 11.0048205, 3.713056, 18.643611],
  COK: [-22.15807, -166.0856468, -8.7168792, -157.1089329],
  COL: [-4.2316872, -82.1243666, 16.0571269, -66.8511907],
  COM: [-12.621, 43.025305, -11.165, 44.7451922],
  CPV: [14.8031546, -25.3609478, 17.2053108, -22.6673416],
  CRI: [5.3329698, -87.2722647, 11.2195684, -82.5060208],
  CUB: [19.6275294, -85.1679702, 23.4816972, -73.9190004],
  CXR: [-10.5698515, 105.5336422, -10.4123553, 105.7130159],
  CYM: [19.0620619, -81.6313748, 19.9573759, -79.5110954],
  CYP: [34.4383706, 32.0227581, 35.913252, 34.8553182],
  CZE: [48.5518083, 12.0905901, 51.0557036, 18.859216],
  DEU: [47.2701114, 5.8663153, 55.099161, 15.0419319],
  DJI: [10.9149547, 41.7713139, 12.7923081, 43.6579046],
  DMA: [15.0074207, -61.6869184, 15.7872222, -61.0329895],
  DNK: [54.4516667, 7.7153255, 57.9524297, 15.5530641],
  DOM: [17.2701708, -72.0574706, 21.303433, -68.1101463],
  DZA: [18.968147, -8.668908, 37.2962055, 11.997337],
  ECU: [-5.0159314, -92.2072392, 1.8835964, -75.192504],
  EGY: [22.0, 24.6499112, 31.8330854, 37.1153517],
  ERI: [12.3548219, 36.4333653, 18.0709917, 43.3001714],
  ESH: [20.556883, -17.3494721, 27.6666834, -8.666389],
  ESP: [27.4335426, -18.3936845, 43.9933088, 4.5918885],
  EST: [57.5092997, 21.3826069, 59.9383754, 28.2100175],
  ETH: [3.397448, 32.9975838, 14.8940537, 47.9823797],
  FIN: [59.4541578, 19.0832098, 70.0922939, 31.5867071],
  FJI: [-21.9434274, 172.0, -12.2613866, -178.5],
  FLK: [-53.1186766, -61.7726772, -50.7973007, -57.3662367],
  FRA: [41.2632185, -5.4534286, 51.268318, 9.8678344],
  FRO: [61.3915553, -7.6882939, 62.3942991, -6.2565525],
  FSM: [0.827, 137.2234512, 10.291, 163.2364054],
  GAB: [-4.1012261, 8.5002246, 2.3182171, 14.539444],
  GBR: [49.674, -14.015517, 61.061, 2.0919117],
  GEO: [41.0552922, 39.8844803, 43.5864294, 46.7365373],
  GGY: [49.4155331, -2.6751703, 49.5090776, -2.501814],
  GHA: [4.5392525, -3.260786, 11.1748562, 1.2732942],
  GIB: [36.100807, -5.3941295, 36.180807, -5.3141295],
  GIN: [7.1906045, -15.5680508, 12.67563, -7.6381993],
  GLP: [15.8320085, -61.809764, 16.5144664, -61.0003663],
  GMB: [13.061, -17.0288254, 13.8253137, -13.797778],
  GNB: [10.6514215, -16.894523, 12.6862384, -13.6348777],
  GNQ: [-1.6732196, 5.4172943, 3.989, 11.3598628],
  GRC: [34.7006096, 19.2477876, 41.7488862, 29.7296986],
  GRD: [11.786, -62.0065868, 12.5966532, -61.1732143],
  GRL: [59.515387, -74.1250416, 83.875172, -10.0288759],
  GTM: [13.6345804, -92.3105242, 17.8165947, -88.1755849],
  GUF: [2.112222, -54.60278, 5.7507111, -51.6346139],
  GUM: [13.182335, 144.563426, 13.706179, 145.009167],
  GUY: [1.1710017, -61.414905, 8.6038842, -56.4689543],
  HKG: [22.1193278, 114.0028131, 22.4393278, 114.3228131],
  HMD: [-53.394741, 72.2460938, -52.7030677, 74.1988754],
  HND: [12.9808485, -89.3568207, 17.619526, -82.1729621],
  HRV: [42.1765993, 13.2104814, 46.555029, 19.4470842],
  HTI: [17.9099291, -75.2384618, 20.2181368, -71.6217461],
  HUN: [45.737128, 16.1138867, 48.585257, 22.8977094],
  IDN: [-11.2085669, 94.7717124, 6.2744496, 141.0194444],
  IMN: [54.0539576, -4.7946845, 54.4178705, -4.3076853],
  IND: [6.5546079, 68.1113787, 35.6745457, 97.395561],
  IOT: [-7.6454079, 71.036504, -5.037066, 72.7020157],
  IRL: [51.222, -11.0133788, 55.636, -5.6582363],
  IRN: [24.8465103, 44.0318908, 39.7816502, 63.3332704],
  IRQ: [29.0585661, 38.7936719, 37.380932, 48.8412702],
  ISL: [63.0859177, -25.0135069, 67.353, -12.8046162],
  ISR: [29.4533796, 34.2674994, 33.3356317, 35.8950234],
  ITA: [35.2889616, 6.6272658, 47.0921462, 18.7844746],
  JAM: [16.5899443, -78.5782366, 18.7256394, -75.7541143],
  JEY: [49.1625179, -2.254512, 49.2621288, -2.0104193],
  JOR: [29.183401, 34.8844372, 33.3750617, 39.3012981],
  JPN: [20.2145811, 122.7141754, 45.7112046, 154.205541],
  KAZ: [40.5686476, 46.4932179, 55.4421701, 87.3156316],
  KEN: [-4.8995204, 33.9098987, 4.62, 41.899578],
  KGZ: [39.1728437, 69.2649523, 43.2667971, 80.2295793],
  KHM: [9.4752639, 102.3338282, 14.6904224, 107.6276788],
  KIR: [-7.0516717, -179.1645388, 7.9483283, -164.1645388],
  KNA: [16.895, -63.051129, 17.6158146, -62.3303519],
  KOR: [32.9104556, 124.354847, 38.623477, 132.1467806],
  KWT: [28.5243622, 46.5526837, 30.1038082, 49.0046809],
  LAO: [13.9096752, 100.0843247, 22.5086717, 107.6349989],
  LBN: [33.0479858, 34.8825667, 34.6923543, 36.625],
  LBR: [4.1555907, -11.6080764, 8.5519861, -7.367323],
  LBY: [19.5008138, 9.391081, 33.3545898, 25.3770629],
  LCA: [13.508, -61.2853867, 14.2725, -60.6669363],
  LIE: [47.0484291, 9.4716736, 47.270581, 9.6357143],
  LKA: [5.719, 79.3959205, 10.035, 82.0810141],
  LSO: [-30.6772773, 27.0114632, -28.570615, 29.4557099],
  LTU: [53.8967893, 20.653783, 56.4504213, 26.8355198],
  LUX: [49.4969821, 4.9684415, 50.430377, 6.0344254],
  LVA: [55.6746505, 20.6715407, 58.0855688, 28.2414904],
  MAC: [22.0766667, 113.5281666, 22.2170361, 113.6301389],
  MAF: [17.8963535, -63.3605643, 18.1902778, -62.7644063],
  MAR: [21.3365321, -17.2551456, 36.0505269, -0.998429],
  MCO: [43.7247599, 7.4090279, 43.7519311, 7.4398704],
  MDA: [45.4674139, 26.6162189, 48.4918695, 30.1636756],
  MDG: [-25.6071002, 43.2202072, -11.9519693, 50.4862553],
  MDV: [-0.9074935, 72.3554187, 7.3106246, 73.9700962],
  MEX: [14.3886243, -118.59919, 32.7186553, -86.493266],
  MHL: [-0.5481258, 163.4985095, 14.4518742, 178.4985095],
  MKD: [40.8536596, 20.4529023, 42.3735359, 23.034051],
  MLI: [10.147811, -12.2402835, 25.001084, 4.2673828],
  MLT: [35.6029696, 13.9324226, 36.2852706, 14.8267966],
  MMR: [9.4399432, 92.1719423, 28.547835, 101.1700796],
  MNE: [41.7495999, 18.4195781, 43.5585061, 20.3561641],
  MNG: [41.5800276, 87.73762, 52.1496, 119.931949],
  MNP: [14.036565, 144.813338, 20.616556, 146.154418],
  MOZ: [-26.9209427, 30.2138197, -10.3252149, 41.0545908],
  MRT: [14.7209909, -17.068081, 27.314942, -4.8333344],
  MSR: [16.475, -62.450667, 17.0152978, -61.9353818],
  MTQ: [14.3948596, -61.2290815, 14.8787029, -60.8095833],
  MUS: [-20.725, 56.3825151, -10.138, 63.7151319],
  MWI: [-17.1296031, 32.6703616, -9.3683261, 35.9185731],
  MYS: [-5.1076241, 105.3471939, 9.8923759, 120.3471939],
  MYT: [-13.0210119, 45.0183298, -12.6365902, 45.2999917],
  NAM: [-28.96945, 11.5280384, -16.9634855, 25.2617671],
  NCL: [-23.2217509, 162.6034343, -17.6868616, 167.8109827],
  NER: [11.693756, 0.1689653, 23.517178, 15.996667],
  NFK: [-29.333, 167.6873878, -28.796, 168.2249543],
  NGA: [4.0690959, 2.676932, 13.885645, 14.678014],
  NIC: [10.7076565, -87.901532, 15.0331183, -82.6227023],
  NIU: [-19.3548665, -170.1595029, -18.7534559, -169.5647229],
  NLD: [50.7295671, 1.9193492, 53.7253321, 7.2274985],
  NOR: [57.7590052, 4.0875274, 71.3848787, 31.7614911],
  NPL: [26.3477581, 80.0586226, 30.446945, 88.2015257],
  NRU: [-0.5541334, 166.9091794, -0.5025906, 166.9589235],
  NZL: [-52.8213687, -179.059153, -29.0303303, 179.3643594],
  OMN: [16.4649608, 52, 26.7026737, 60.054577],
  PAK: [23.5393916, 60.872855, 37.084107, 77.1203914],
  PAN: [7.0338679, -83.0517245, 9.8701757, -77.1393779],
  PCN: [-25.1306736, -130.8049862, -23.8655769, -124.717534],
  PER: [-20.1984472, -84.6356535, -0.0392818, -68.6519906],
  PHL: [4.2158064, 114.0952145, 21.3217806, 126.8072562],
  PLW: [2.748, 131.0685462, 8.222, 134.7714735],
  PNG: [-13.1816069, 136.7489081, 1.8183931, 151.7489081],
  POL: [49.0020468, 14.1229707, 55.0336963, 24.145783],
  PRI: [17.9268695, -67.271492, 18.5159789, -65.5897525],
  PRK: [37.5867855, 124.0913902, 43.0089642, 130.924647],
  PRT: [29.8288021, -31.5575303, 42.1543112, -6.1891593],
  PRY: [-27.6063935, -62.6442036, -19.2876472, -54.258],
  PSE: [31.2201289, 34.0689732, 32.5521479, 35.5739235],
  PYF: [-28.0990232, -154.9360599, -7.6592173, -134.244799],
  QAT: [24.4707534, 50.5675, 26.3830212, 52.638011],
  REU: [-21.3897308, 55.2164268, -20.8717136, 55.8366924],
  ROU: [43.618682, 20.2619773, 48.2653964, 30.0454257],
  RUS: [41.1850968, 19.6389, 82.0586232, 180],
  RWA: [-2.8389804, 28.8617546, -1.0474083, 30.8990738],
  SAU: [16.29, 34.4571718, 32.1543377, 55.6666851],
  SDN: [8.685278, 21.8145046, 22.224918, 39.0576252],
  SEN: [12.2372838, -17.7862419, 16.6919712, -11.3458996],
  SGP: [1.1304753, 103.6920359, 1.4504753, 104.0120359],
  SGS: [-59.684, -42.354739, -53.3500755, -25.8468303],
  SHN: [-16.23, -5.9973424, -15.704, -5.4234153],
  SJM: [70.6260825, -9.6848146, 81.028076, 34.6891253],
  SLB: [-13.2424298, 155.3190556, -4.81085, 170.3964667],
  SLE: [6.755, -13.5003389, 9.999973, -10.271683],
  SLV: [12.976046, -90.1790975, 14.4510488, -87.6351394],
  SMR: [43.8937002, 12.4033246, 43.992093, 12.5160665],
  SOM: [-1.8031969, 40.98918, 12.1889121, 51.6177696],
  SPM: [46.5507173, -56.6972961, 47.365, -55.9033333],
  SRB: [42.2322435, 18.8142875, 46.1900524, 23.006309],
  SSD: [23.8869795809, 3.50917, 35.2980071182, 12.2480077571],
  STP: [-0.2135137, 6.260642, 1.9257601, 7.6704783],
  SUR: [1.8312802, -58.070833, 6.225, -53.8433358],
  SVK: [47.7314286, 16.8331891, 49.6138162, 22.56571],
  SVN: [45.4214242, 13.3754696, 46.8766816, 16.5967702],
  SWE: [55.1331192, 10.5930952, 69.0599699, 24.1776819],
  SWZ: [-27.3175201, 30.7908, -25.71876, 32.1349923],
  SYC: [-10.4649258, 45.9988759, -3.512, 56.4979396],
  SYR: [32.311354, 35.4714427, 37.3184589, 42.3745687],
  TCA: [20.9553418, -72.6799046, 22.1630989, -70.8643591],
  TCD: [7.44107, 13.47348, 23.4975, 24.0],
  TGO: [5.926547, -0.1439746, 11.1395102, 1.8087605],
  THA: [5.612851, 97.3438072, 20.4648337, 105.636812],
  TJK: [36.6711153, 67.3332775, 41.0450935, 75.1539563],
  TKL: [-9.6442499, -172.7213673, -8.3328631, -170.9797586],
  TKM: [35.129093, 52.335076, 42.7975571, 66.6895177],
  TLS: [-9.5642775, 124.0415703, -8.0895459, 127.5335392],
  TON: [-24.1034499, -179.3866055, -15.3655722, -173.5295458],
  TTO: [9.8732106, -62.083056, 11.5628372, -60.2895848],
  TUN: [30.230236, 7.5219807, 37.7612052, 11.8801133],
  TUR: [35.8076804, 25.6212891, 42.297, 44.8176638],
  TUV: [-9.9939389, 175.1590468, -5.4369611, 178.7344938],
  TWN: [10.374269, 114.3599058, 26.4372222, 122.297],
  TZA: [-11.761254, 29.3269773, -0.9854812, 40.6584071],
  UGA: [-1.4823179, 29.573433, 4.2340766, 35.000308],
  UKR: [44.184598, 22.137059, 52.3791473, 40.2275801],
  UMI: [6.1779744, -162.6816297, 6.6514388, -162.1339885],
  URY: [-35.7824481, -58.4948438, -30.0853962, -53.0755833],
  USA: [24.9493, -125.0011, 49.5904, -66.9326],
  UZB: [37.1821164, 55.9977865, 45.590118, 73.1397362],
  VAT: [41.9002044, 12.4457442, 41.9073912, 12.4583653],
  VCT: [12.5166548, -61.6657471, 13.583, -60.9094146],
  VEN: [0.647529, -73.3529632, 15.9158431, -59.5427079],
  VGB: [17.623468, -65.159094, 18.464984, -64.512674],
  VIR: [17.623468, -65.159094, 18.464984, -64.512674],
  VNM: [8.1790665, 102.14441, 23.393395, 114.3337595],
  VUT: [-20.4627425, 166.3355255, -12.8713777, 170.449982],
  WLF: [-14.5630748, -178.3873749, -12.9827961, -175.9190391],
  WSM: [-14.2770916, -173.0091864, -13.2381892, -171.1929229],
  XKX: [43.2721, 21.7751, 41.8471, 20.0707],
  YEM: [11.9084802, 41.60825, 19.0, 54.7389375],
  ZAF: [-47.1788335, 16.3335213, -22.1250301, 38.2898954],
  ZMB: [-18.0765945, 21.9993509, -8.2712822, 33.701111],
  ZWE: [-22.4241096, 25.2373, -15.6097033, 33.0683413],
};

// @ts-expect-error - Commented out currencies are not supported by Core API because Stripe does not support them
export const countryCodeToCurrencyMap: Record<CountryCode, CurrencyCode> = {
  AND: 'EUR',
  ARE: 'AED',
  AFG: 'AFN',
  ATG: 'XCD',
  AIA: 'XCD',
  ALB: 'ALL',
  ARM: 'AMD',
  AGO: 'AOA',
  ARG: 'ARS',
  ASM: 'USD',
  AUT: 'EUR',
  AUS: 'AUD',
  ABW: 'AWG',
  ALA: 'EUR',
  AZE: 'AZN',
  BIH: 'BAM',
  BRB: 'BBD',
  BGD: 'BDT',
  BEL: 'EUR',
  BFA: 'XOF',
  BGR: 'BGN',
  BHR: 'BHD',
  BDI: 'BIF',
  BEN: 'XOF',
  BLM: 'EUR',
  BMU: 'BMD',
  BRN: 'BND',
  BOL: 'BOB',
  BRA: 'BRL',
  BHS: 'BSD',
  BTN: 'BTN',
  BVT: 'NOK',
  BWA: 'BWP',
  BLR: 'BYN',
  BLZ: 'BZD',
  CAN: 'CAD',
  CCK: 'AUD',
  COD: 'CDF',
  CAF: 'XAF',
  COG: 'CDF',
  CHE: 'CHE',
  CIV: 'XOF',
  COK: 'NZD',
  CHL: 'CLF',
  CMR: 'XAF',
  CHN: 'CNY',
  COL: 'COP',
  CRI: 'CRC',
  CUB: 'CUP',
  CPV: 'CVE',
  CXR: 'AUD',
  CYP: 'EUR',
  CZE: 'CZK',
  DEU: 'EUR',
  DJI: 'DJF',
  DNK: 'DKK',
  DMA: 'DOP',
  DOM: 'DOP',
  DZA: 'DZD',
  ECU: 'USD',
  EST: 'EUR',
  EGY: 'EGP',
  ESH: 'MAD',
  ERI: 'ERN',
  ESP: 'EUR',
  ETH: 'ETB',
  FIN: 'EUR',
  FJI: 'FJD',
  FSM: 'USD',
  FRO: 'DKK',
  FRA: 'EUR',
  GAB: 'XAF',
  GBR: 'GBP',
  GRD: 'XCD',
  GEO: 'GEL',
  GUF: 'EUR',
  GGY: 'GBP',
  GHA: 'GHS',
  GIB: 'GIP',
  GRL: 'DKK',
  GMB: 'GMD',
  GIN: 'GNF',
  GLP: 'EUR',
  GNQ: 'XAF',
  GRC: 'EUR',
  GTM: 'GTQ',
  GUM: 'USD',
  GNB: 'XOF',
  GUY: 'GYD',
  HKG: 'HKD',
  HND: 'HNL',
  HRV: 'EUR',
  HTI: 'HTG',
  HUN: 'HUF',
  IDN: 'IDR',
  IRL: 'EUR',
  ISR: 'ILS',
  IMN: 'GBP',
  IND: 'INR',
  IOT: 'USD',
  IRQ: 'IQD',
  IRN: 'IRR',
  ISL: 'ISK',
  ITA: 'EUR',
  JEY: 'GBP',
  JAM: 'JMD',
  JOR: 'JOD',
  JPN: 'JPY',
  KEN: 'KES',
  KGZ: 'KGS',
  KHM: 'KHR',
  KIR: 'AUD',
  COM: 'KMF',
  KNA: 'XCD',
  PRK: 'KPW',
  KOR: 'KRW',
  KWT: 'KWD',
  CYM: 'KYD',
  KAZ: 'KZT',
  LBN: 'LBP',
  LCA: 'XCD',
  LIE: 'CHF',
  LKA: 'LKR',
  LBR: 'LRD',
  LSO: 'LSL',
  LTU: 'EUR',
  LUX: 'EUR',
  LVA: 'EUR',
  LBY: 'LYD',
  MAR: 'MAD',
  MCO: 'EUR',
  MDA: 'MDL',
  MNE: 'EUR',
  MAF: 'EUR',
  MDG: 'MGA',
  MHL: 'USD',
  MLI: 'XOF',
  MMR: 'MMK',
  MNG: 'MNT',
  MAC: 'MOP',
  MNP: 'USD',
  MTQ: 'EUR',
  // MRT: 'MRU',
  MSR: 'XCD',
  MLT: 'EUR',
  MUS: 'MUR',
  MDV: 'MVR',
  MWI: 'MWK',
  MEX: 'MXN',
  MYS: 'MYR',
  MOZ: 'MZN',
  NAM: 'NAD',
  NCL: 'XPF',
  NER: 'NGN',
  NFK: 'AUD',
  NGA: 'NGN',
  NIC: 'NIO',
  NLD: 'EUR',
  NOR: 'NOK',
  NPL: 'NPR',
  NRU: 'AUD',
  NIU: 'NZD',
  NZL: 'NZD',
  OMN: 'OMR',
  PAN: 'PAB',
  PER: 'PEN',
  PYF: 'XPF',
  PNG: 'PGK',
  PHL: 'PHP',
  PAK: 'PKR',
  POL: 'PLN',
  SPM: 'EUR',
  PCN: 'NZD',
  PRI: 'USD',
  PRT: 'EUR',
  PLW: 'USD',
  PRY: 'PYG',
  QAT: 'QAR',
  REU: 'EUR',
  ROU: 'RON',
  SRB: 'RSD',
  RUS: 'RUB',
  RWA: 'RWF',
  SAU: 'SAR',
  SLB: 'SBD',
  SYC: 'SCR',
  SDN: 'SDG',
  SWE: 'SEK',
  SGP: 'SGD',
  SHN: 'SHP',
  SVN: 'EUR',
  SJM: 'NOK',
  SVK: 'EUR',
  SLE: 'SLL',
  SMR: 'EUR',
  SEN: 'XOF',
  SOM: 'SOS',
  SUR: 'SRD',
  SSD: 'SSP',
  STP: 'STN',
  SLV: 'USD',
  SYR: 'SYP',
  SWZ: 'SZL',
  TCA: 'USD',
  TCD: 'XAF',
  ATF: 'EUR',
  TGO: 'XOF',
  THA: 'THB',
  TJK: 'TJS',
  TKL: 'NZD',
  TLS: 'USD',
  TKM: 'TMT',
  TUN: 'TND',
  TON: 'TOP',
  TUR: 'TRY',
  TTO: 'TTD',
  TUV: 'AUD',
  TWN: 'TWD',
  TZA: 'TZS',
  UKR: 'UAH',
  UGA: 'UGX',
  UMI: 'USD',
  USA: 'USD',
  URY: 'UYU',
  UZB: 'UZS',
  VAT: 'EUR',
  VCT: 'XCD',
  // VEN: 'VES',
  VGB: 'USD',
  VIR: 'USD',
  VNM: 'VND',
  VUT: 'VUV',
  WLF: 'XPF',
  WSM: 'USD',
  YEM: 'YER',
  MYT: 'EUR',
  ZAF: 'ZAR',
  ZMB: 'ZMW',
  ZWE: 'ZWL',
  HMD: 'AUD',
  FLK: 'FKP',
  SGS: 'GEL',
  LAO: 'LAK',
  MKD: 'MKD',
  ATA: 'USD',
  PSE: 'ILS',
  XKX: 'EUR',
} as const;

// Commented out currencies are not supported by Core API yet
export const currencySymbols: Record<CurrencyCode, string> = {
  AED: 'د.إ',
  AFN: '؋',
  ALL: 'L',
  AMD: '֏',
  ANG: 'ƒ',
  AOA: 'Kz',
  ARA: '₳',
  ARL: '$L',
  ARS: '$',
  AUD: '$',
  AWG: 'ƒ',
  AZN: '₼',
  BAM: 'KM',
  BEL: 'BEL',
  BBD: '$',
  BDT: '৳',
  BGN: 'лв',
  BHD: '.د.ب',
  BIF: 'FBu',
  BMD: '$',
  BND: '$',
  BOB: '$b',
  BOV: 'BOV',
  BRL: 'R$',
  BSD: '$',
  // BTC: '₿',
  BTN: 'Nu.',
  BWP: 'P',
  BYN: 'Br',
  // BYR: 'Br',
  BZD: 'BZ$',
  CAD: '$',
  CDF: 'FC',
  CHE: 'CHE',
  CHF: 'CHF',
  CHW: 'CHW',
  CLF: 'CLF',
  CLP: '$',
  CNY: '¥',
  COP: '$',
  COU: 'COU',
  CRC: '₡',
  // CUC: '$',
  CUP: '₱',
  CVE: '$',
  CZK: 'Kč',
  DJF: 'Fdj',
  DKK: 'kr',
  DOP: 'RD$',
  DZD: 'دج',
  ECV: 'ECV',
  // EEK: 'kr',
  EGP: '£',
  ERN: 'Nfk',
  ETB: 'Br',
  // ETH: 'Ξ',
  EUR: '€',
  FJD: '$',
  FKP: '£',
  GBP: '£',
  GEL: '₾',
  // GGP: '£',
  // GHC: '₵',
  GHS: 'GH₵',
  GIP: '£',
  GMD: 'D',
  GNF: 'FG',
  GTQ: 'Q',
  GYD: '$',
  HKD: '$',
  HNL: 'L',
  // HRK: 'kn',
  HTG: 'G',
  HUF: 'Ft',
  IDR: 'Rp',
  ILS: '₪',
  // IMP: '£',
  INR: '₹',
  IQD: 'ع.د',
  IRR: '﷼',
  ISK: 'kr',
  // JEP: '£',
  JMD: 'J$',
  JOD: 'JD',
  JPY: '¥',
  KES: 'KSh',
  KGS: 'лв',
  KHR: '៛',
  KMF: 'CF',
  KPW: '₩',
  KRH: '៛',
  KRW: '₩',
  KWD: 'KD',
  KYD: '$',
  KZT: '₸',
  LAK: '₭',
  LBP: '£',
  LKR: '₨',
  LRD: '$',
  LSL: 'M',
  // LTC: 'Ł',
  // LTL: 'Lt',
  LUC: 'fr.',
  // LVL: 'Ls',
  LYD: 'LD',
  MAD: 'MAD',
  MDL: 'lei',
  MGA: 'Ar',
  MKD: 'ден',
  MMK: 'K',
  MNT: '₮',
  MOP: 'MOP$',
  // MRO: 'UM',
  // MRU: 'UM',
  MUR: '₨',
  MVR: 'Rf',
  MWK: 'MK',
  MXN: '$',
  MXV: 'MXV',
  MYR: 'RM',
  MZN: 'MT',
  NAD: '$',
  NGN: '₦',
  NIO: 'C$',
  NOK: 'kr',
  NPR: '₨',
  NZD: '$',
  OMR: '﷼',
  PAB: 'B/.',
  PEN: 'S/.',
  PGK: 'K',
  PHP: '₱',
  PKR: '₨',
  PLN: 'zł',
  PYG: 'Gs',
  QAR: '﷼',
  RON: 'lei',
  RSD: 'Дин.',
  RUB: '₽',
  RWF: 'R₣',
  SAR: '﷼',
  SBD: '$',
  SCR: '₨',
  SDG: 'ج.س.',
  SEK: 'kr',
  SGD: 'S$',
  SHP: '£',
  SLL: 'Le',
  SOS: 'S',
  SRD: '$',
  SSP: '£',
  // STD: 'Db',
  STN: 'Db',
  // SVC: '$',
  SYP: '£',
  SZL: 'E',
  THB: '฿',
  TJS: 'SM',
  TMT: 'T',
  TND: 'د.ت',
  TOP: 'T$',
  // TRL: '₤',
  TRY: '₺',
  TTD: 'TT$',
  // TVD: '$',
  TWD: 'NT$',
  TZS: 'TSh',
  UAH: '₴',
  UGX: 'USh',
  USD: '$',
  // UYI: 'UYI',
  UYU: '$U',
  // UYW: 'UYW',
  UZS: 'лв',
  // VEF: 'Bs',
  // VES: 'Bs.S',
  VND: '₫',
  VUV: 'VT',
  WST: 'WS$',
  XAF: 'FCFA',
  XAG: 'XAG',
  XAU: 'XAU',
  // XBT: 'Ƀ',
  XCD: '$',
  // XDR: 'XDR',
  XOF: 'CFA',
  XPF: '₣',
  // XSU: 'Sucre',
  // XUA: 'XUA',
  YER: '﷼',
  ZAR: 'R',
  ZMW: 'ZK',
  // ZWD: 'Z$',
  ZWL: '$',
};

// list of all locales iso-3166-1 alpha-2v and iso-639-2 codes
export const locales = [
  // iso-3166-1 alpha-2v codes
  'af',
  'ak',
  'sq',
  'am',
  'ar',
  'hy',
  'as',
  'az',
  'bm',
  'eu',
  'be',
  'bn',
  'bs',
  'bg',
  'my',
  'ca',
  'zh',
  'kw',
  'hr',
  'cs',
  'da',
  'nl',
  'en',
  'eo',
  'et',
  'ee',
  'fo',
  'fi',
  'fr',
  'ff',
  'gl',
  'lg',
  'ka',
  'de',
  'el',
  'gu',
  'ha',
  'he',
  'hi',
  'hu',
  'is',
  'ig',
  'id',
  'ga',
  'it',
  'ja',
  'kl',
  'kn',
  'kk',
  'km',
  'ki',
  'rw',
  'ko',
  'lv',
  'lt',
  'mk',
  'mg',
  'ms',
  'ml',
  'mt',
  'gv',
  'mr',
  'ne',
  'nd',
  'nb',
  'nn',
  'or',
  'om',
  'ps',
  'fa',
  'pl',
  'pt',
  'pa',
  'ro',
  'rm',
  'ru',
  'sg',
  'sr',
  'sn',
  'ii',
  'si',
  'sk',
  'sl',
  'so',
  'es',
  'sw',
  'sv',
  'ta',
  'te',
  'th',
  'bo',
  'ti',
  'to',
  'tr',
  'uk',
  'ur',
  'uz',
  'vi',
  'cy',
  'yo',
  'zu',
  // ISO 639-2 codes
  'aar',
  'abk',
  'ace',
  'ach',
  'ada',
  'ady',
  'afa',
  'afh',
  'afr',
  'ain',
  'aka',
  'akk',
  'alb',
  'ale',
  'alg',
  'alt',
  'amh',
  'ang',
  'anp',
  'apa',
  'ara',
  'arc',
  'arg',
  'arm',
  'arn',
  'arp',
  'art',
  'arw',
  'asm',
  'ast',
  'ath',
  'aus',
  'ava',
  'ave',
  'awa',
  'aym',
  'aze',
  'bad',
  'bai',
  'bak',
  'bal',
  'bam',
  'ban',
  'baq',
  'bas',
  'bat',
  'bej',
  'bel',
  'bem',
  'ben',
  'ber',
  'bho',
  'bih',
  'bik',
  'bin',
  'bis',
  'bla',
  'bnt',
  'bos',
  'bra',
  'bre',
  'btk',
  'buo',
  'bur',
  'byn',
  'cad',
  'cai',
  'car',
  'cat',
  'cau',
  'ceb',
  'cel',
  'cha',
  'chb',
  'che',
  'chg',
  'chi',
  'chk',
  'chm',
  'chn',
  'cho',
  'chp',
  'chr',
  'chu',
  'chv',
  'chy',
  'cmc',
  'cop',
  'cor',
  'cos',
  'cpe',
  'cpf',
  'cpp',
  'cre',
  'crh',
  'crp',
  'csb',
  'cus',
  'cze',
  'dak',
  'dan',
  'dar',
  'day',
  'del',
  'den',
  'dgr',
  'din',
  'div',
  'doi',
  'dra',
  'dsb',
  'dua',
  'dum',
  'dut',
  'dyu',
  'dzo',
  'efi',
  'egy',
  'eka',
  'elx',
  'eng',
  'enm',
  'epo',
  'est',
  'ewe',
  'ewo',
  'fan',
  'fao',
  'fat',
  'fij',
  'fil',
  'fin',
  'fiu',
  'fon',
  'fre',
  'frm',
  'fro',
  'frr',
  'frs',
  'fry',
  'ful',
  'fur',
  'gaa',
  'gay',
  'gba',
  'gem',
  'geo',
  'ger',
  'gez',
  'gil',
  'gla',
  'gle',
  'glg',
  'glv',
  'gmh',
  'goh',
  'gon',
  'gor',
  'got',
  'grb',
  'grc',
  'gre',
  'grn',
  'gsw',
  'guj',
  'gwi',
  'hai',
  'hat',
  'hau',
  'haw',
  'heb',
  'her',
  'hil',
  'him',
  'hin',
  'hit',
  'hmn',
  'hmo',
  'hrv',
  'hsb',
  'hun',
  'hup',
  'iba',
  'ibo',
  'ice',
  'ido',
  'iii',
  'ijo',
  'iku',
  'ile',
  'ilo',
  'ina',
  'inc',
  'ind',
  'ine',
  'inh',
  'ipk',
  'ira',
  'iro',
  'ita',
  'jav',
  'jbo',
  'jpn',
  'jpr',
  'jrb',
  'kaa',
  'kab',
  'kac',
  'kal',
  'kam',
  'kan',
  'kar',
  'kas',
  'kau',
  'kaw',
  'kaz',
  'kbd',
  'kha',
  'khi',
  'khm',
  'kho',
  'khq',
  'kik',
  'kin',
  'kir',
  'kmb',
  'kok',
  'kom',
  'kon',
  'kor',
  'kos',
  'kpe',
  'krc',
  'krl',
  'kro',
  'kru',
  'kua',
  'kum',
  'kur',
  'kut',
  'lad',
  'lah',
  'lam',
  'lao',
  'lat',
  'lav',
  'lez',
  'lim',
  'lin',
  'lit',
  'lol',
  'loz',
  'ltz',
  'lua',
  'lub',
  'lug',
  'lui',
  'lun',
  'luo',
  'lus',
  'mac',
  'mad',
  'mag',
  'mah',
  'mai',
  'mak',
  'mal',
  'man',
  'mao',
  'map',
  'mar',
  'mas',
  'may',
  'mdf',
  'mdr',
  'men',
  'mga',
  'mic',
  'min',
  'mis',
  'mkh',
  'mlg',
  'mlt',
  'mnc',
  'mni',
  'mno',
  'moh',
  'mon',
  'mos',
  'mul',
  'mun',
  'mus',
  'mwl',
  'mwr',
  'myn',
  'myv',
  'nah',
  'nai',
  'nap',
  'nau',
  'nav',
  'nbl',
  'nde',
  'ndo',
  'nds',
  'nep',
  'new',
  'nia',
  'nic',
  'niu',
  'nno',
  'nob',
  'nog',
  'non',
  'nor',
  'nqo',
  'nso',
  'nub',
  'nwc',
  'nya',
  'nym',
  'nyn',
  'nyo',
  'nzi',
  'oci',
  'oji',
  'ori',
  'orm',
  'osa',
  'oss',
  'ota',
  'oto',
  'paa',
  'pag',
  'pal',
  'pam',
  'pan',
  'pap',
  'pau',
  'peo',
  'phi',
  'phn',
  'pli',
  'pol',
  'pon',
  'por',
  'pra',
  'pro',
  'pus',
  'que',
  'raj',
  'rap',
  'rar',
  'roa',
  'roh',
  'rom',
  'rum',
  'run',
  'rup',
  'rus',
  'sad',
  'sag',
  'sah',
  'sai',
  'sal',
  'sam',
  'san',
  'sas',
  'sat',
  'scn',
  'sco',
  'sel',
  'sem',
  'sga',
  'sgn',
  'shn',
  'sid',
  'sin',
  'sio',
  'sit',
  'sla',
  'slo',
  'slv',
  'sma',
  'sme',
  'smi',
  'smj',
  'smn',
  'smo',
  'sms',
  'sna',
  'snd',
  'snk',
  'sog',
  'som',
  'son',
  'sot',
  'spa',
  'srd',
  'srn',
  'srp',
  'srr',
  'ssa',
  'ssw',
  'suk',
  'sun',
  'sus',
  'sux',
  'swa',
  'swe',
  'syc',
  'syr',
  'tah',
  'tai',
  'tam',
  'tat',
  'tel',
  'tem',
  'ter',
  'tet',
  'tgk',
  'tgl',
  'tha',
  'tib',
  'tig',
  'tir',
  'tiv',
  'tkl',
  'tlh',
  'tli',
  'tmh',
  'tog',
  'ton',
  'tpi',
  'tsi',
  'tsn',
  'tso',
  'tuk',
  'tum',
  'tup',
  'tur',
  'tut',
  'tvl',
  'twi',
  'tyv',
  'udm',
  'uga',
  'uig',
  'ukr',
  'umb',
  'und',
  'urd',
  'uzb',
  'vai',
  'ven',
  'vie',
  'vol',
  'vot',
  'wak',
  'wal',
  'war',
  'was',
  'wel',
  'wen',
  'wln',
  'wol',
  'xal',
  'xho',
  'yao',
  'yap',
  'yid',
  'yor',
  'ypk',
  'zap',
  'zbl',
  'zen',
  'zgh',
  'zha',
  'zho',
  'znd',
  'zul',
  'zun',
  'zza',
];
