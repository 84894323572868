<template lang="pug">
.ZAction(v-if="!isHidden")
  VDialog(
    ref="confirmationDialogRef"
    v-model="execConfirmationDialog"
    :max-width="settings.dialogMaxWidth"
    v-bind="settings.dialogProps"
    persistent
  )
    VCard
      VToolbar.ZAction__dialog__title.ZAction__dialog__title--alert(
        dark
        flat
      )
        VIcon(left) mdi-alert
        h3 {{ t('app__warning') }}
      VCardText.ZAction__dialog__text
        p(v-html="tin(execConfirmationText)")
        code.ZAction__dialog__recap(v-if="execConfirmationRecap") {{ execConfirmationRecap }}
        p(v-if="!execConfirmationInput") {{ t('app__wish_proceed') }}
        template(v-else)
          p {{ t('app__wish_proceed_confirmation') }}
          VForm(
            ref="confirmationFormRef"
            v-model="valid"
            lazy-validation
            @submit.prevent
          )
            VTextField(
              v-model="execConfirmationInputValue"
              :label="tin(execConfirmationInput.label)"
              :type="execConfirmationInput.type"
              v-bind="execConfirmationInput.type === 'number' ? { pattern: '\\d*' } : {}"
              :rules="[execConfirmationRule]"
            )
      VCardActions.ZAction__dialog__actions
        VSpacer
        VBtn(
          flat
          color="error"
          @click="execConfirmationDialog = false"
        ) {{ t('action__abort') }}
        VBtn(
          color="primary"
          @click="confirmationExecute"
        ) {{ t('action__proceed') }}

  template(v-if="(settings.isIcon || settings.isFab) && !hiddenBtn")
    ZTooltip(
      v-model="tooltip"
      bottom
      :disabled="isDisabled"
    )
      template(#activator)
        VBtn(
          ref="tooltipActivatorRef"
          :icon="settings.isIcon"
          :fab="settings.isFab"
          :disabled="isDisabled"
          :color="settings.color"
          v-bind="settings.btnProps"
          :small="settings.btnProps?.small || xs"
          :loading="settings.withDialog ? preloading : preloading || loading"
          flat
          @mouseenter="tooltip = true"
          @mouseleave="tooltip = false"
          @click="handleClick"
        )
          ZIcon(
            v-bind="settings.iconProps"
            :small="settings.btnProps?.small"
          ) {{ settings.icon }}
      span {{ tin(settings.dialogTitle || settings.text || '') }}

  template(v-else-if="settings.isListItem")
    VListTile(
      :disabled="isDisabled"
      :color="themeColor(settings.color)"
      @click="handleClick"
    )
      VListTileAction(v-if="settings.icon")
        ZIcon(
          :color="isDisabled ? '' : themeColor(settings.color)"
          v-bind="settings.iconProps"
        ) {{ settings.icon }}
      VListTileContent
        VListTileTitle.ZAction__listItem__title
          span {{ tin(settings.listItemText || settings.dialogTitle || settings.text || '') }}
          template(v-if="settings.subText")
            VDivider.ZAction__listItem__divider
            VListTileSubTitle {{ tin(settings.subText) }}

  template(v-else-if="!hiddenBtn")
    .ZAction__btn
      VBtn(
        :disabled="isDisabled"
        v-bind="settings.btnProps"
        :loading="settings.withDialog ? preloading : preloading || loading"
        flat
        @click="handleClick"
      )
        ZIcon.ZAction__btn__icon(
          v-if="settings.icon"
          :color="themeColor(settings.color)"
          :small="settings.btnProps?.small"
          :size="!settings.btnProps?.small ? 20 : undefined"
          v-bind="settings.iconProps"
        ) {{ settings.icon }}
        span {{ tin(settings.text ?? '') }}

      .ZAction__btn__hint(v-if="buttonHint")
        ZToggleableHint
          span(v-html="buttonHint")

  template(v-if="settings.successDialog?.active")
    VDialog(
      v-model="showSuccessDialog"
      :max-width="settings.successDialog.maxWidth ?? settings.dialogMaxWidth"
      persistent
    )
      VCard
        VToolbar(dark)
          h4
            VIcon.ZAction__endDialog__icon(v-if="settings.successDialog.icon") {{ settings.successDialog.icon }}
            span {{ settings.successDialog.title }}
        VCardText
          slot(name="success-dialog-content")
        VCardActions.ZAction__dialog__actions
          VBtn(
            color="primary"
            @click="onCloseSuccessDialog"
          ) {{ t('app__ok') }}

  template(v-if="settings.withDialog")
    VDialog(
      ref="dialogRef"
      v-model="showDialog"
      :max-width="settings.dialogMaxWidth"
      v-bind="settings.dialogProps"
      persistent
      :transition="false"
    )
      VForm(
        ref="formRef"
        v-model="valid"
        lazy-validation
        @submit.prevent
      )
        VCard
          VToolbar.ZAction__dialog__title(flat)
            VIcon.ZAction__dialog__icon(
              :color="themeColor(settings.dialogColor ?? settings.color)"
            ) {{ settings.icon }}
            h3 {{ tin(settings.dialogTitle || settings.text || '') }}
            VSpacer
            VBtn.ZAction__dialog__close(
              icon
              @click="handleCancel"
            )
              VIcon mdi-close
          .ZAction__caution(v-if="settings.caution && cautionOpen")
            VIcon.ZAction__caution__icon(small) mdi-alert
            small {{ settings.caution }}
            VBtn.ZAction__caution__button(
              small
              icon
              left
              light
              @click="cautionOpen = false"
            )
              VIcon(size="12") mdi-close
          VProgressLinear.ZAction__dialog__progress(
            v-if="settings.withProgress"
            :height="2"
            :class="{ 'ZAction__dialog__progress--show': loading }"
            :value="progress"
            :indeterminate="progress === 0"
          )
          VCardText.ZAction__dialog__text(
            v-if="hasDialogContent"
            :class="{ 'ZAction__dialog__text--stepped': settings.steps }"
          )
            ZActionDropZone(
              v-if="settings.onDrop"
              :data="data"
              @drop="settings.onDrop"
            )
            slot(
              name="dialog-content-before"
              :self="self"
            )
            p(
              v-if="actionDialogContent"
              v-html="tin(actionDialogContent)"
            )
            //- iterate over steps and/or controls (add a dynamic v-stepper/div wrapper anyhow)
            component(
              :is="settings.steps ? 'VStepper' : 'div'"
              v-model="currentStep"
              v-bind="settings.steps ? { vertical: true, class: 'ZAction__dialog__stepper' } : {}"
            )
              template(v-for="(step, stepIndex) in activeSteps")
                transition(
                  :key="`transition-${stepIndex}`"
                  name="z-fade"
                )
                  VStepperStep.ZAction__dialog__stepperStep(
                    v-if="settings.steps"
                    :key="`stepper-step-${stepIndex}`"
                    :class="{ 'ZAction__dialog__stepperStep--solo': activeSteps.length === 1 }"
                    :complete="isStepComplete(stepIndex)"
                    :step="stepIndex + 1"
                    :color="themeColor(generate(step.color))"
                  )
                    span(v-html="tin(step.title ?? '')")
                    small
                      .ZAction__dialog__stepperHint(
                        v-if="generate(step.hint) && currentStep === stepIndex + 1"
                        v-html="generate(step.hint)"
                      )
                    small(
                      v-if="step.summary && currentStep !== stepIndex + 1"
                      :key="`summary-${stepIndex}`"
                    ) {{ generate(step.summary) }}
                component(
                  :is="settings.steps ? 'VStepperContent' : 'div'"
                  :key="`step-content-${stepIndex}`"
                  :class=`{
                    'ZAction__dialog__stepperContent': settings.steps,
                    'ZAction__dialog__stepperContent--solo': step.solo || activeSteps.length === 1,
                    'ZAction__dialog__stepperContent--flex': step.flex || settings.flex,
                  }`
                  v-bind="settings.steps ? { step: stepIndex + 1 } : {}"
                )
                  template(v-for="(control, controlIndex) in step.controls")
                    ZActionControlSlot(
                      v-if="control.controlType === 'slot'"
                      :key="`${stepIndex}-${controlIndex}`"
                      :control="infer(control, 'slot')"
                      :data="data"
                    )
                      template(#default="{ slotName }")
                        slot(
                          :name="slotName"
                          :data="data"
                        )
                    ZActionControlSeparator(
                      v-else-if="control.controlType === 'sep'"
                      :key="`${stepIndex}-${controlIndex}`"
                      :control="control"
                      :data="data"
                    )
                    ZActionControlSwitch(
                      v-else-if="control.controlType === 'switch'"
                      :key="`${stepIndex}-${controlIndex}`"
                      :ref="refSetter(stepIndex, controlIndex)"
                      v-model="controlValues[stepIndex][controlIndex]"
                      :control="infer(control, 'switch')"
                      :data="data"
                    )
                    ZActionControlCheckbox(
                      v-else-if="control.controlType === 'checkbox'"
                      :key="`${stepIndex}-${controlIndex}`"
                      :ref="refSetter(stepIndex, controlIndex)"
                      v-model="controlValues[stepIndex][controlIndex]"
                      :control="infer(control, 'checkbox')"
                      :data="data"
                    )
                    ZActionControlSlider(
                      v-else-if="control.controlType === 'slider'"
                      :key="`${stepIndex}-${controlIndex}`"
                      :ref="refSetter(stepIndex, controlIndex)"
                      v-model="controlValues[stepIndex][controlIndex]"
                      :control="infer(control, 'slider')"
                      :data="data"
                    )
                    ZActionControlRadioGroup(
                      v-else-if="control.controlType === 'radioGroup'"
                      :key="`${stepIndex}-${controlIndex}`"
                      :ref="refSetter(stepIndex, controlIndex)"
                      v-model="controlValues[stepIndex][controlIndex]"
                      :control="infer(control, 'radioGroup')"
                      :data="data"
                    )
                    ZActionControlInput(
                      v-else-if="control.controlType === 'input'"
                      :key="`${stepIndex}-${controlIndex}`"
                      :ref="refSetter(stepIndex, controlIndex)"
                      v-model="controlValues[stepIndex][controlIndex]"
                      :control="infer(control, 'input')"
                      :data="data"
                    )
                    ZActionControlInputUint(
                      v-else-if="control.controlType === 'uint'"
                      :key="`${stepIndex}-${controlIndex}`"
                      :ref="refSetter(stepIndex, controlIndex)"
                      v-model="controlValues[stepIndex][controlIndex]"
                      :control="infer(control, 'uint')"
                      :data="data"
                    )
                    ZActionControlTextarea(
                      v-else-if="control.controlType === 'textarea'"
                      :key="`${stepIndex}-${controlIndex}`"
                      :ref="refSetter(stepIndex, controlIndex)"
                      v-model="controlValues[stepIndex][controlIndex]"
                      :control="infer(control, 'textarea')"
                      :data="data"
                      @enter="handleKeypressEnter"
                    )
                    ZActionControlMultiInput(
                      v-else-if="control.controlType === 'multiInput'"
                      :key="`${stepIndex}-${controlIndex}`"
                      :ref="refSetter(stepIndex, controlIndex)"
                      v-model="controlValues[stepIndex][controlIndex]"
                      :control="infer(control, 'multiInput')"
                      :data="data"
                      @enter="handleKeypressEnter"
                    )
                    ZActionControlInputDelay(
                      v-else-if="control.controlType === 'delay'"
                      :key="`${stepIndex}-${controlIndex}`"
                      :ref="refSetter(stepIndex, controlIndex)"
                      v-model="controlValues[stepIndex][controlIndex]"
                      :control="infer(control, 'delay')"
                      :data="data"
                    )
                    ZActionControlInputPrice(
                      v-else-if="control.controlType === 'price'"
                      :key="`${stepIndex}-${controlIndex}`"
                      :ref="refSetter(stepIndex, controlIndex)"
                      v-model="controlValues[stepIndex][controlIndex]"
                      :control="infer(control, 'price')"
                      :data="data"
                    )
                    ZActionControlCombobox(
                      v-else-if="control.controlType === 'combobox'"
                      :key="`${stepIndex}-${controlIndex}`"
                      :ref="refSetter(stepIndex, controlIndex)"
                      v-model="controlValues[stepIndex][controlIndex]"
                      :control="infer(control, 'combobox')"
                      :data="data"
                      :dialog-max-width="settings.dialogMaxWidth"
                    )
                    ZActionControlAutocomplete(
                      v-else-if="control.controlType === 'autocomplete'"
                      :key="`${stepIndex}-${controlIndex}`"
                      :ref="refSetter(stepIndex, controlIndex)"
                      v-model="controlValues[stepIndex][controlIndex]"
                      :control="infer(control, 'autocomplete')"
                      :data="data"
                      @focus="isAutocompleteFocused = true"
                      @blur="isAutocompleteFocused = false"
                    )
                    ZActionControlInputPhoneNumber(
                      v-else-if="control.controlType === 'phone'"
                      :key="`${stepIndex}-${controlIndex}`"
                      :ref="refSetter(stepIndex, controlIndex)"
                      v-model="controlValues[stepIndex][controlIndex]"
                      :control="infer(control, 'phone')"
                      :data="data"
                    )
                    ZActionControlInputDatePicker(
                      v-else-if="control.controlType === 'date'"
                      :key="`${stepIndex}-${controlIndex}`"
                      :ref="refSetter(stepIndex, controlIndex)"
                      v-model="controlValues[stepIndex][controlIndex]"
                      :control="infer(control, 'date')"
                      :data="data"
                    )
            slot(
              name="dialog-content-after"
              :self="self"
              :refs="refs"
            )
          VDivider(v-if="hasDialogContent")
          VCardActions.ZAction__dialog__actions
            VBtn.ZAction__dialog__prev(
              v-if="currentStep > 1"
              key="prev-btn"
              flat
              :small="xs"
              @click="prevStep()"
            )
              VIcon mdi-chevron-left
              span {{ t('action__prev') }}
            VSpacer
            VBtn(
              key="cancel-btn"
              flat
              color="error"
              :disabled="settings.disableCancel && loading"
              @click="handleCancel"
            ) {{ endDialog ? t('action__close') : tin(settings.cancelText ?? '') }}
            ZOptionsButton.ZAction__dialog__confirm(
              v-if="currentStep === activeSteps.length"
              v-model="selectedConfirmItem"
              :disabled="disabledConfirm"
              :loading="loading"
              :items="confirmItems"
              color="primary"
              @click="handleExecute"
            )
            VBtn.ZAction__dialog__next(
              v-if="currentStep < activeSteps.length"
              key="next-btn"
              :disabled="!canGoNext"
              color="primary"
              @click="nextStep()"
            ) {{ t('action__next') }}
              VIcon.ml-1 mdi-chevron-right
</template>

<style lang="stylus">
.highlight
  padding 2px 10px
  border-radius 100px
  background-color alpha(#fff, 0.15)

.ZAction
  display flex

  .ZAction__btn
    position relative
    display flex
    align-items center

    &__icon
      margin-left -2px
      margin-right 8px

    &__hint
      position absolute
      top -4px
      right -4px

  .v-btn
    background-color none

    &:after
      border 1px solid currentColor
      content ''
      position absolute
      opacity 0.14
      left 0
      top 0
      height 100%
      -webkit-transition 0.3s cubic-bezier(0.25, 0.8, 0.5, 1)
      transition 0.3s cubic-bezier(0.25, 0.8, 0.5, 1)
      width 100%
      border-radius inherit

    &:before
      background-color currentColor
      opacity 0.04

    &:hover
      &:before
        opacity 0.12

  .ZToggleableHint > .v-btn
    &:before
      background-color transparent

    &:after
      content none

  [role='listitem']
    width 100%

  .ZAction__listItem__title
    display flex
    align-items center
    width 100%

    .v-list__tile__sub-title
      width auto

  .ZAction__listItem__divider
    margin-left 4px
    margin-right 4px
    padding-left 8px
    padding-right 8px

  .v-dialog__container
    display none !important

  &__caution
    color alpha(black, 0.75)
    background-color $color-error
    display flex
    align-items center
    margin 0
    line-height 1
    padding 12px 16px

    &__icon
      opacity 0.75
      margin-right 8px

    &__button
      margin-top 0
      margin-bottom 0

  &__dialog
    &.v-dialog
      overflow hidden
      transition left 0.5s ease, top 0.5s ease, transform 0.5s ease

      +media-up-xs()
        position absolute
        top 50%
        left 50%
        margin 0
        transform translate(-50%, -50%)

        &.v-dialog--animated
          animation-name animate-action-dialog

      &__title
        padding 0 8px

    &--justifyLeft
      position absolute
      top 50%
      left 0
      transform translate(0, -50%)

      &.v-dialog--animated
        animation-name animate-action-dialog-left

      +media-down-xs()
        position static

    &--justifyTop
      position absolute
      top 0
      left 50%
      transform translate(-50%, 0)

      &.v-dialog--animated
        animation-name animate-action-dialog-top

      +media-down-xs()
        position static
        transform translateY(calc((100% - 100vh) / 2 + 16px))

    &__close
      margin-right -8px !important

    &__text
      .v-input
        margin-top 0

      .v-input--hide-details > .v-input__control > .v-input__slot
        margin-bottom 12px

    &__actions
      display flex
      justify-content flex-end
      flex-wrap wrap

  .v-list__tile__action
    min-width 36px

.ZAction__dialog
  border-radius 16px
  box-shadow none

.ZAction__dialog__title--alert
  .v-toolbar__content
    background $color-error

    h3
      margin-left 4px

.ZAction__dialog__icon
  margin-right 8px
  margin-left -8px

.v-progress-linear.ZAction__dialog__progress
  margin 0
  opacity 0
  transition opacity 0.3s

  &.ZAction__dialog__progress--show
    opacity 1

.ZAction__dialog__text
  position relative
  padding 16px 20px

  &.ZAction__dialog__text--stepped
    padding 0

  +media-down-xxs()
    padding-right 14px

  &.v-card__text
    overflow auto
    max-height calc(100vh - 232px)

    +media-down-sm()
      max-height 60vh

  .ZAction__dialog__stepperStep
    transition all 0.3s ease

    &.ZAction__dialog__stepperStep--solo
      padding 0
      opacity 0

    +media-down-xs()
      padding 15px

  .v-stepper__content.ZAction__dialog__stepperContent
    margin -16px -36px -16px 36px
    padding 4px 52px 4px 0
    transition margin-left 0.3s ease

    &.ZAction__dialog__stepperContent--solo
      margin-left 0

    &:not(:last-child)
      border-left-style dashed

      +media-down-xs()
        border-left none

    +media-down-xs()
      margin -16px 0
      padding 4px 15px

    .v-stepper__wrapper
      > *
        margin-left 21px

      > :last-child
        margin-bottom 8px

      +media-down-xs()
        margin-left 0

  .theme--dark.v-stepper,
  .theme--light.v-stepper
    background-color transparent

    .v-stepper__step__step .v-icon
      color inherit

  pre
    margin-bottom 16px
    padding 8px 16px
    border-radius 2px

  .theme--light
    pre
      background-color: $colors.grey.lighten-3

  .theme--dark
    pre
      background-color #333

  .v-input--is-disabled .v-messages
    opacity 0.6

  .ZActionControlTextarea:not(:last-child)
    margin-bottom 12px

scrollbar-theme('.ZAction__dialog__text')

.ZAction__dialog__stepper
  box-shadow none

.ZAction__dialog__stepperHint
  inline-hint()

.ZAction__dialog__recap
  margin-bottom 16px

.ZAction__dialog__actions
  border-top 0

  .theme--dark &
    background lighten($colors.grey.darken-3, 7%)

  .theme--light &
    background: $colors.grey.lighten-3

.v-btn.ZAction__dialog__prev
  padding-left 4px

.ZAction__dialog
  .v-card__actions
    .ZAction__dialog__confirm
      margin-left 4px

.v-btn.ZAction__dialog__next
  padding-right 4px

.ZAction__dialog__stepperContent--solo .ZActionControlSlider
  margin-top 20px
  padding-right 8px

.ZAction__dialog__stepperContent--solo
  .v-input__append-outer
    margin-top 8px

.ZAction__dialog__stepperContent--flex
  display flex
  flex-wrap wrap
  column-gap 16px

.ZAction__endDialog__icon
  margin-right 8px

@keyframes animate-action-dialog
  0%
    transform translate(-50%, -50%) scale(1)

  50%
    transform translate(-50%, -50%) scale(1.03)

  100%
    transform translate(-50%, -50%) scale(1)

@keyframes animate-action-dialog-left
  0%
    transform translate(0, -50%) scale(1)

  50%
    transform translate(0, -50%) scale(1.03)

  100%
    transform translate(0, -50%) scale(1)

@keyframes animate-action-dialog-top
  0%
    transform translate(-50%, 0) scale(1)

  50%
    transform translate(-50%, 0) scale(1.03)

  100%
    transform translate(-50%, 0) scale(1)
</style>

<script setup lang="ts" generic="DataT extends object = object">
/* eslint-disable @typescript-eslint/no-explicit-any */
import { set } from 'vue';

import ZActionControlSlot from './action/ZActionControlSlot.vue';
import ZActionControlSeparator from './action/ZActionControlSeparator.vue';
import ZActionControlSwitch from './action/ZActionControlSwitch.vue';
import ZActionControlCheckbox from './action/ZActionControlCheckbox.vue';
import ZActionControlSlider from './action/ZActionControlSlider.vue';
import ZActionControlRadioGroup from './action/ZActionControlRadioGroup.vue';
import ZActionControlInput from './action/ZActionControlInput.vue';
import ZActionControlInputUint from './action/ZActionControlInputUint.vue';
import ZActionControlTextarea from './action/ZActionControlTextarea.vue';
import ZActionControlMultiInput from './action/ZActionControlMultiInput.vue';
import ZActionControlInputDelay from './action/ZActionControlInputDelay.vue';
import ZActionControlInputPrice from './action/ZActionControlInputPrice.vue';
import ZActionControlCombobox from './action/ZActionControlCombobox.vue';
import ZActionControlAutocomplete from './action/ZActionControlAutocomplete.vue';
import ZActionControlInputPhoneNumber from './action/ZActionControlInputPhoneNumber.vue';
import ZActionControlInputDatePicker from './action/ZActionControlInputDatePicker.vue';
import ZActionDropZone from './action/ZActionDropZone.vue';
import { useActionControl } from './action/useActionControl';

import { toBoolean, isFunc, noop, keysOf } from '@/lib/utils';
import mergeActionSettings from '@/lib/helpers/merge-action-settings';
import { useKeyboard, useColors, useAlert } from '@/composables';
import { useI18n } from '@/composables/plugins';
import { useVuetifyBreakpoints } from '@/composables/useVuetifyBreakpoints';
import store from '@/store';

import ZOptionsButton from '@/components/ui/molecules/ZOptionsButton.vue';
import { injectionKey } from '@/components/ui/atoms/ZActionProvider.vue';

import type { AlertAction } from '@/components/ui/molecules/ZAlert.vue';
import type { MaybeActionDataFactory } from '@/types/actions';

type AnyControl =
  | ActionControl<ActionControlType, keyof DataT, DataT>
  | ActionControlSeparator<DataT>;

type AnyActionExecConfirmation =
  ActionExecConfirmation<ExecConfirmationInputType>;

interface ZActionProps {
  /**
   * Hide the action trigger button
   */
  hiddenBtn?: boolean;
  /**
   * The action settings
   */
  actionSettings?: Partial<ActionSettings<DataT>>;
}

const props = withDefaults(defineProps<ZActionProps>(), {
  hiddenBtn: false,
  actionSettings: () => ({}),
});

const emit = defineEmits<{
  /**
   * Click the action activator (button, list item, etc.)
   */
  (event: 'click'): void;
  /**
   * Emitted when loading state changes
   */
  (event: 'loading', value: boolean): void;
}>();

const actionProvider = inject(injectionKey, { actionSettings: ref({}) });

const tooltip = ref(false);
const showDialog = ref(false);
const loading = ref(false);
const preloading = ref(false);
const executing = ref(false);
const valid = ref(true);
const execConfirmationDialog = ref(false);
const execConfirmationText = ref('');
const execConfirmationRecap = ref<string>();
const execConfirmationInput = ref<AnyActionExecConfirmation['input'] | null>(
  null
);
const execConfirmationInputValue = ref('');
const execConfirmationRule: VuetifyRule = value => {
  return value === String(execConfirmationInput.value?.target) || 'Invalid';
};

const nextAnimation = ref(false);
const progress = ref(0);
const selectedConfirmItem = ref(0);
const cautionOpen = ref(true);
const isAutocompleteFocused = ref(false);

const endDialog = ref(false);
const showSuccessDialog = ref(false);

const { t, tin } = useI18n();
const { themeColor } = useColors();
const alert = useAlert();
const { xs } = useVuetifyBreakpoints();

const defaultSettings: ActionSettings = {
  dialogMaxWidth: 400,
  btnProps: {},
  iconProps: {},
  privilege: null,
  execFunc: noop,
  withDialog: true,
  confirmKeyEnter: true,
  initialStep: 1,
  cancelText: 'action__cancel',
  confirmText: 'action__confirm',
};

type Value = any;
type Key = string | symbol;

const confirmationDialogRef = ref<TemplateRef | null>(null);
const confirmationFormRef = ref<TemplateRef | null>(null);
const tooltipActivatorRef = ref<TemplateRef | null>(null);
const dialogRef = ref<TemplateRef | null>(null);
const formRef = ref<TemplateRef | null>(null);

const dataStore: DataT = {} as DataT;
const refsStore: ActionRefs<DataT> = {} as ActionRefs<DataT>;

// Proxy that get and set the value of a control based on its key
const data = new Proxy(dataStore, {
  get: (_, key) => getData(key as keyof DataT),
  set: (_, key, value) => setData(key as keyof DataT, value),
  ownKeys: () => getDataKeys(), // Allow enumeration of data keys
  getOwnPropertyDescriptor: (_, key) => {
    // The property descriptor so that all the above works
    return {
      value: getData(key as keyof DataT),
      writable: true,
      enumerable: true,
      configurable: true,
    };
  },
});
// Proxy that get the template ref of a control based on its key (read-only)
const refs = new Proxy(refsStore, {
  get: (_, key) => getRef(key as keyof DataT),
  ownKeys: () => getDataKeys(), // Allow enumeration of data keys
  getOwnPropertyDescriptor: (_, key) => {
    // The property descriptor so that all the above works
    return {
      value: getRef(key as keyof DataT),
      writable: false,
      enumerable: true,
      configurable: true,
    };
  },
}) as ActionRefs<DataT>;

const settings = computed<Partial<ActionSettings<DataT>>>(() => {
  const settings = mergeActionSettings(
    defaultSettings,
    mergeActionSettings(actionProvider?.actionSettings?.value ?? {}, {
      ...props.actionSettings,
      ...(actionProvider?.actionSettingsOverride?.value ?? {}),
    })
  );
  // default dialog props contentClass
  settings.dialogProps = {
    contentClass: 'ZAction__dialog',
    ...settings.dialogProps,
  };
  return settings;
});

watch(settings, () => {
  // Whenever settings fully change, the action is different so we reset internal states
  loading.value = false;
  preloading.value = false;
  executing.value = false;
});

const currentStep = ref(1);
const initialStep = computed(() => settings.value.initialStep ?? 1);
watchImmediate(initialStep, () => {
  currentStep.value = initialStep.value;
});
watch(currentStep, () => {
  settings.value.onChangeStep?.(currentStep.value, data);
});

type AnyActionSteps = Partial<ActionStep<DataT>>;

const steps = computed<AnyActionSteps[]>(() => {
  return settings.value.steps || [{ controls: settings.value.controls }];
});

function prevStep(): void {
  currentStep.value--;
  focusControl();
}
function nextStep(): void {
  currentStep.value++;
  nextAnimation.value = true;
  setTimeout(() => (nextAnimation.value = false), 500);
  focusControl();
}

type ControlStore<T = unknown> = Record<number, Record<number, T>>;

const controlValues = reactive<ControlStore<Value>>({ 0: {} });
const controlRefs = reactive<ControlStore<TemplateRef>>({ 0: {} });

function refSetter(
  stepIndex: number,
  controlIndex: number
): (ref: TemplateRef) => void {
  return ref => (controlRefs[stepIndex][controlIndex] = ref);
}

/**
 * Type helper to infer control type, as Typescript cannot narrow control based on `controlType`
 * in the template and Vue 2 does not accept `as` statements in the template neither.
 * This helper throws an error if the control is not of the expected type.
 * @param control - The control to infer
 * @param type - The expected control type
 */
function infer<T extends ActionControlType>(
  control: AnyControl,
  type: T
): ActionControl<T, keyof DataT, DataT> {
  if (control.controlType !== type) {
    throw new Error(
      `Trying to infer control '${type}' on '${control.controlType}'`
    );
  }
  return control as ActionControl<T, keyof DataT, DataT>;
}

// Make sure reactive collections are properly initialized and reactive for all steps
steps.value.forEach((_, stepIndex) => {
  set(controlValues, stepIndex, {});
  set(controlRefs, stepIndex, {});
});

const hasPrivilege = computed(
  () => settings.value.privilege === null || settings.value.privilege
);
// A false privilege leads to a hidden action
const isHidden = computed(
  () => hasPrivilege.value === false || !!settings.value.hidden
);
// While an undefined one, which can be found when it exists on only one selector when multiple are selected, leads to a disabled action
const isDisabled = computed(
  () => hasPrivilege.value === undefined || !!settings.value.disabled
);
const buttonHint = computed(() => {
  const inconsistentPrivilegeHint = t('app__inconsistent_privilege_hint');
  return hasPrivilege.value === undefined
    ? inconsistentPrivilegeHint
    : settings.value.btnHint;
});
const hasControls = computed(
  () => !!settings.value.steps || !!settings.value.controls?.length
);
const slots = useSlots();
const actionDialogContent = computed(() => settings.value.dialogContent);
const hasDialogContent = computed(() => {
  return !!(
    actionDialogContent.value ||
    hasControls.value ||
    slots['dialog-content-before'] ||
    slots['dialog-content-after']
  );
});
const activeSteps = computed(() =>
  steps.value.filter(step => isActiveStep(step))
);

const dialogClippedOverlay = computed(
  () => settings.value.dialogClippedOverlay
);
const showGlobalOverlay = computed(() => {
  return (
    !!dialogClippedOverlay.value &&
    (showDialog.value || execConfirmationDialog.value)
  );
});
watch([dialogClippedOverlay, showGlobalOverlay], ([clipPath, show]) => {
  store.commit('overlay', {
    props: { show, clipPath },
    onClick: () => show && clickOverlay(),
  });
});
function clickOverlay(): void {
  if (!showGlobalOverlay.value) return;
  dialogRef.value?.animateClick();
  confirmationDialogRef.value?.animateClick();
}

const canGoNext = computed(() => isStepComplete(currentStep.value - 1));
const disabledConfirm = computed(() => {
  const disabledBySettings = generate(settings.value.disabledConfirm);
  return disabledBySettings || !canGoNext.value || nextAnimation.value;
});
const confirmItems = computed(() => [
  {
    shortText: [settings.value.confirmText, 'action__close']
      .filter(v => v !== undefined)
      .map(v => tin(v))
      .join(' & '),
    text: t('action__execute_and_close_dialog'),
  },
  {
    shortText: tin(settings.value.confirmText ?? ''),
    text: t('action__execute_and_keep_dialog'),
  },
]);
const keepDialog = computed(() => !!selectedConfirmItem.value);

const serializedControlValues = computed(() => JSON.stringify(controlValues));
watch(serializedControlValues, () => {
  if (settings.value.steps) {
    settings.value.onInput?.(data, refs, currentStep.value - 1);
  } else {
    settings.value.onInput?.(data, refs);
  }
});

function generate<T>(maybeFactory: MaybeActionDataFactory<T, DataT>): T {
  return isFunc(maybeFactory) ? maybeFactory(data) : (maybeFactory as T);
}
/**
 * Set the all the initial controls values in the data store
 */
function setControlValues(): void {
  steps.value.forEach(step => {
    (step.controls ?? []).forEach(control => {
      if (control.controlType === 'sep') return;
      let controlValue = generate(control.value);
      // specific cast boolean to string because vuetify does not handle `false` properly
      if (
        control.controlType === 'radioGroup' &&
        (control as ActionControl<'radioGroup'>).type === Boolean
      ) {
        controlValue = (controlValue || false).toString();
      }
      // @ts-expect-error cannot know at this point which type is the control based on the key
      data[control.key] = controlValue;
    });
  });
}
// Initialize control values at setup time
setControlValues();

/**
 * Helper to serialize the control values given its type:
 * boolean is casted into a string due to Vuetify issues
 */
function castSafe(
  value: Value,
  type: ActionControl<'radioGroup'>['type']
): Value {
  return type === Boolean ? String(value) : value;
}
/**
 * Helper to ensure the control values given its type
 */
function uncastSafe(
  value: Value,
  type: ActionControl<'radioGroup'>['type']
): Value {
  if (value === undefined) return value;
  return type === String
    ? String(value)
    : type === Number
      ? value === ''
        ? undefined
        : Number(value)
      : type === Boolean
        ? toBoolean(value)
        : value;
}
/**
 * Enumerate all data keys
 */
function getDataKeys(): Key[] {
  return steps.value.flatMap(step => {
    return (step.controls ?? [])
      .filter(control => control.controlType !== 'sep')
      .map(control => control.key as Key);
  });
}
/**
 * Helper to find a control index, its type and step index given a control key
 * @param key - The control key
 */
function findControlByKey<Key extends keyof DataT>(
  key: Key
): {
  stepIndex: number;
  controlIndex: number;
  type?: ActionControl<'radioGroup'>['type'];
} {
  let type: ActionControl<'radioGroup'>['type'];
  let controlIndex: number | undefined;

  const stepIndex = steps.value.findIndex(step => {
    const controls = step.controls ?? [];
    controlIndex = controls.findIndex(
      control => control.controlType !== 'sep' && control.key === key
    );
    const control = controls[controlIndex];
    if (controlIndex !== -1) {
      if ('type' in control && control.type) {
        type = control.type;
      }
      return true;
    } else {
      return false;
    }
  });

  if (stepIndex === -1 || controlIndex === undefined || controlIndex === -1) {
    throw new Error(`Control '${String(key)}' not found`);
  }
  return {
    stepIndex,
    controlIndex,
    type,
  };
}
/**
 * Get a given control value by key
 * @param key - The control key
 * @returns The control value
 */
function getData<Key extends keyof DataT>(key: Key): DataT[Key] {
  try {
    const { stepIndex, controlIndex, type } = findControlByKey(key);
    return uncastSafe(controlValues[stepIndex][controlIndex], type);
  } catch {
    // Any other unknown key will be accessed in dataStore, which can hold other entries
    return dataStore[key];
  }
}
/**
 * Update a given control data by key
 * @param key - The control key
 * @param value - The given value to update
 */
function setData<Key extends keyof DataT>(
  key: Key,
  value: DataT[Key]
): boolean {
  // Make sure ref is not readonly, otherwise it would break the reactivity of the control and throw later on.
  if (isRef(value) && isReadonly(value)) {
    throw new Error(
      `Control '${
        key as string
      }': Cannot set a readonly Ref for control value. Either use a Ref, a WritableComputedRef, or a plain value`
    );
  }
  try {
    const { stepIndex, controlIndex, type } = findControlByKey(key);
    set(
      controlValues[stepIndex],
      controlIndex,
      isRef(value)
        ? // If the control value is a ref, creates a computed proxy around it to keep reactivity,
          // while ensuring the two-way conversion to "safe" values for boolean and number types
          computed({
            get: () => uncastSafe(value.value, type),
            set: _value => {
              value.value = castSafe(_value, type);
            },
          })
        : castSafe(structuredClone(value), type)
    );
  } catch {
    // Any other unknown key will be stored in dataStore
    set(dataStore, key as string, value);
  }
  // Always return true so that the Proxy never breaks
  return true;
}
/**
 * Get a given control’s template ref by key
 * @param key - The control key
 * @returns The template ref
 */
function getRef<Key extends keyof DataT>(key: Key): TemplateRef | undefined {
  try {
    const { stepIndex, controlIndex } = findControlByKey(key);
    return controlRefs[stepIndex][controlIndex];
  } catch {
    // Any other unknown key will return an undefined ref
    return undefined;
  }
}
/**
 * Reset form validation
 */
function resetValidation(): void {
  formRef.value?.resetValidation();
}

const { pressedKeys } = useKeyboard({
  onKeyup: handleKeyup,
});
/**
 * Keyup event handler. It executes the action or validates "new items" dialog when Enter is pressed,
 * or cancels the action or the "new items" creation if open, when Escape is pressed
 * @param event - The keyboard event
 */
function handleKeyup(event: KeyboardEvent): void {
  if (
    showDialog.value &&
    settings.value.confirmKeyEnter &&
    !pressedKeys.get('Shift')
  ) {
    switch (event.key) {
      case 'Enter': {
        // To execute action, currentStep must be the final step
        if (currentStep.value !== activeSteps.value.length) return;
        // If an autocomplete is focused, do nothing, as 'Enter' selects the item, we don't want to execute the action
        if (isAutocompleteFocused.value) return;
        if (!executing.value) handleExecute();
        break;
      }
      case 'Escape': {
        // If an autocomplete is focused, do nothing, as 'Escape' closes the menu, we don't want to cancel the action
        if (isAutocompleteFocused.value) return;
        handleCancel();
        return;
      }
    }
  }
}
/**
 * Prevent default behavior on Enter keypress, unless Shift is pressed.
 * @param event - The keyboard event
 */
function handleKeypressEnter(event: KeyboardEvent): void {
  if (!pressedKeys.get('Shift')) event.preventDefault();
}

watch(showDialog, newVal => {
  // Whenever dialog opens, show caution banner (will be displayed if given in settings)
  if (newVal) cautionOpen.value = true;
  // Whenever dialog closes, reset step to the initial one
  else currentStep.value = initialStep.value;
});

/**
 * Check if a step is complete, based on its controls rules & required prop,
 * and based on the step’s additional `complete` function
 */
function isStepComplete(stepIndex: number): boolean {
  const step = steps.value[stepIndex];
  const controls = step.controls ?? [];
  const isStepValid = controls.reduce((validStep, control, controlIndex) => {
    const { rules } = useActionControl({ control, data });
    // generate the control final rules
    const controlRules = rules.value;
    // get the control value
    const controlValue = controlValues[stepIndex][controlIndex];
    // check if every rules are fulfilled by control value
    const isControlValid = controlRules.reduce(
      (validControl, rule) => validControl && rule(controlValue) === true,
      true
    );
    // return the accumulated control validity
    return validStep && isControlValid;
  }, true);
  // Get the step `complete` state. If undefined, default to true.
  const isStepComplete =
    !('complete' in step) ||
    step.complete === undefined ||
    !!generate(step.complete);
  // Return the final completion
  return isStepComplete && isStepValid;
}

/**
 * Handle click on the trigger button and trigger the action
 * @param event - The mouse event
 */
function handleClick(event: MouseEvent): void {
  event.stopPropagation();
  resetValidation();
  trigger();
  emit('click');
}

/**
 * Handle click on the cancel button and cancel the action
 */
function handleCancel(): void {
  settings.value.onCancel?.();
  resetValidation();
  showDialog.value = false;
  loading.value = false;
  endCallback();
}

/**
 * Handler that executes the action
 */
async function handleExecute(): Promise<void> {
  executing.value = true;
  if (formRef.value?.validate()) {
    // blur tags
    steps.value.forEach((step, stepIndex) => {
      (step.controls ?? []).forEach((control, controlIndex) => {
        if (control.controlType === 'combobox') {
          controlRefs[stepIndex][controlIndex]?.blur?.();
        }
      });
    });
    await nextTick();
    initExecute();
  }
}

/**
 * Focus the controls that have the property `focus`
 */
function focusControl(): void {
  if (settings.value.withDialog) {
    steps.value.forEach((step, stepIndex) => {
      if (currentStep.value === stepIndex + 1) {
        (step.controls ?? []).forEach((control, controlIndex) => {
          if ('focus' in control && control.focus) {
            nextTick(() => {
              controlRefs[stepIndex][controlIndex]?.focus?.();
            });
          }
        });
      }
    });
  }
}

type Self = any;
const self = ref<Self>(getCurrentInstance()?.proxy as Self);

/**
 * Callback when the action is finished
 */
function endCallback(): void {
  executing.value = false;
  setEndDialogState();
  if (settings.value.resetControls) {
    setControlValues();
  }
  execConfirmationInputValue.value = '';
  confirmationFormRef.value?.resetValidation();

  settings.value.onEnd?.(self.value);
}

/**
 * Call the `onTrigger` event handler of action settings
 * @param self - The action component instance
 */
async function onTrigger(self: Self): Promise<unknown> {
  return await settings.value.onTrigger?.(self);
}

/**
 * Call the `onOpen` event handler of action settings
 * @param self - The action component instance
 */
function onOpen(self: Self): void {
  settings.value.onOpen?.(self);
}

/**
 * Call the `onError` event handler of action settings and end callback
 * @param error - The error to handle
 */
function onError(error: Error): void {
  const hideError = settings.value.onError?.(error);
  if (!error) return endCallback();
  error.message = (error.message ?? '').replace(/\[[A-Z_.]*\]/, '');
  if (!hideError) alert.error = error;
}

/**
 * Call the `onSuccess` event handler of action settings, display success alert, and call end callback
 * @param message - The success message
 * @param actions - The actions that may be shown on the success alert
 * @param response - The response of the action
 */
function onSuccess(
  message: string | I18nArgs | null | undefined,
  actions: AlertAction[] | null | undefined,
  response: any
): void {
  settings.value.onSuccess?.(self.value, response);
  if (settings.value.successDialog?.active) {
    showSuccessDialog.value = true;
  }
  if (settings.value.successIcon) {
    alert.icon = settings.value.successIcon;
  }
  alert.type = 'success';
  if (message) alert.message = tin(message);
  if (actions) alert.actions = actions;
  resetValidation();
  endCallback();
}

/**
 * Called when the success dialog is closed
 */
function onCloseSuccessDialog(): void {
  showSuccessDialog.value = false;
  settings.value.successDialog?.onClose?.();
}

/**
 * Initialize the action execution
 */
async function initExecute(): Promise<void> {
  progress.value = 0;
  if (settings.value.execConfirmation) {
    const execConfirmationSettings = isFunc(settings.value.execConfirmation)
      ? settings.value.execConfirmation(self.value)
      : (settings.value.execConfirmation as AnyActionExecConfirmation);
    execConfirmationText.value = execConfirmationSettings.text;
    execConfirmationRecap.value = execConfirmationSettings.recap;
    execConfirmationInput.value = execConfirmationSettings.input;
    if (execConfirmationSettings.active) {
      execConfirmationDialog.value = true;
    } else {
      execute();
    }
  } else {
    execute();
  }
}

/**
 * Confirm the action execution
 */
function confirmationExecute(): void {
  if (execConfirmationInput.value && !confirmationFormRef.value?.validate()) {
    return;
  }
  execConfirmationDialog.value = false;
  execute();
}

/**
 * Execute the action and handle the response or the error
 */
async function execute(): Promise<void> {
  loading.value = true;
  progress.value = 0;
  const res = await settings.value.execFunc?.(self.value);
  if (settings.value.withProgress) {
    await new Promise(resolve => setTimeout(resolve, 200));
  }
  loading.value = false;
  if (res instanceof Error) {
    onError(res);
  } else if (Array.isArray(res) && res[0] instanceof Error) {
    onError(res[0]);
  } else {
    const response = Array.isArray(res) ? res[1] : res;
    setEndDialogState();
    const successMessage = isFunc(settings.value.successMessage)
      ? settings.value.successMessage(self.value, response)
      : (settings.value.successMessage as string);
    const successActions = isFunc(settings.value.successActions)
      ? settings.value.successActions(self.value, response)
      : (settings.value.successActions as AlertAction[]);
    onSuccess(successMessage, successActions, response);
  }
}

/**
 * Trigger the action
 * @param data - Additional data to pass to the action that will set the action’s data
 * @param bypassDialog - Whether to bypass the dialog and execute the action directly
 */
async function trigger({
  data,
  bypassDialog = false,
}: { data?: Partial<DataT>; bypassDialog?: boolean } = {}): Promise<void> {
  preloading.value = true;
  showSuccessDialog.value = false;
  emit('loading', true);
  const res = await onTrigger(self.value);
  setControlValues();
  if (data) {
    keysOf(data).forEach(key => setData(key, data[key]!));
  }
  resetValidation();
  await nextTick();
  preloading.value = false;
  emit('loading', false);
  if (res instanceof Error) {
    onError(res);
  } else if (Array.isArray(res) && res[0] instanceof Error) {
    onError(res[0]);
  } else {
    if (bypassDialog || !settings.value.withDialog) {
      await initExecute();
    } else {
      showDialog.value = true;
      await onOpen(self.value);
      selectedConfirmItem.value = settings.value.keepDialogOpen ? 1 : 0;
      focusControl();
    }
  }
}

/**
 * Cancel the action
 */
function cancel(): void {
  handleCancel();
}

/**
 * Check if step is active
 */
function isActiveStep(step: AnyActionSteps): boolean {
  return !generate(step.inactive);
}

/**
 * Whether to show the "end dialog" based on the `keepDialog` setting
 */
function setEndDialogState(): void {
  if (!keepDialog.value) {
    showDialog.value = false;
  } else {
    endDialog.value = true;
  }
}

defineExpose({
  /**
   * Get a given control value by key
   */
  getData,
  /**
   * Set a given control’s value by key
   */
  setData,
  /**
   * Get a given control’s template ref by key
   */
  getRef,
  /**
   * Reset form validation
   */
  resetValidation,
  /**
   * Trigger the action
   */
  trigger,
  /**
   * Cancel the action
   */
  cancel,
  /**
   * The action’s progress value, between `0` and `100`.
   */
  progress,
  /**
   * The action’s state whether the dialog is kept open or not.
   */
  keepDialog,
  /**
   * The data carried by the action
   */
  data,
  /**
   * The action control refs
   */
  refs,
});
</script>
