/**
 * App main entry point
 */
import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import { createHead } from '@unhead/vue';
import { UnheadPlugin } from '@unhead/vue/vue2';
import axios from 'axios';

import router from '@/router';
import { version } from '@/../package.json';
import store from '@/store';
import '@/styles/main.styl';
import '@/plugins/vuetify'; // auto installs
import { i18n } from '@/plugins/i18n';
import { useI18n } from '@/composables/plugins';
import { getLocalPreferences } from '@/lib/helpers/get-local-preferences';
import { appConfig } from '@/config';

import App from '@/components/App.vue';
// register our UI components globally
import ZTooltip from '@/components/ui/molecules/ZTooltip.vue';
import ZIcon from '@/components/ui/atoms/ZIcon.vue';
import ZScrollable from '@/components/ui/organisms/ZScrollable.vue';
import ZMiniChip from '@/components/ui/atoms/ZMiniChip.vue';
import ZAppLogo from '@/components/ui/atoms/ZAppLogo.vue';
import ZDisplayField from '@/components/domain/model/ZDisplayField';
import ZLastUpdateDelay from '@/components/ui/molecules/ZLastUpdateDelay.vue';
import ZToggleableIconBtn from '@/components/ui/molecules/ZToggleableIconBtn.vue';
import ZOptionsButton from '@/components/ui/molecules/ZOptionsButton.vue';
import ZPopulatedKeyIcon from '@/components/ui/molecules/ZPopulatedKeyIcon.vue';
import ZToggleableHint from '@/components/ui/molecules/ZToggleableHint.vue';
import Privilege from '@/components/ui/atoms/Privilege.vue';

const head = createHead();
Vue.use(UnheadPlugin);

Vue.component('ZTooltip', ZTooltip);
Vue.component('ZIcon', ZIcon);
Vue.component('ZScrollable', ZScrollable);
Vue.component('ZMiniChip', ZMiniChip);
Vue.component('ZAppLogo', ZAppLogo);
Vue.component('ZDisplayField', ZDisplayField);
Vue.component('ZLastUpdateDelay', ZLastUpdateDelay);
Vue.component('ZToggleableIconBtn', ZToggleableIconBtn);
Vue.component('ZOptionsButton', ZOptionsButton);
Vue.component('ZPopulatedKeyIcon', ZPopulatedKeyIcon);
Vue.component('ZToggleableHint', ZToggleableHint);
// eslint-disable-next-line vue/multi-word-component-names
Vue.component('Privilege', Privilege);

// use synchronous preferences definition & async keys right now
// so that reactivity on preferences properties will be well initialized
Sentry.init({
  Vue,
  dsn: 'https://5478940fe83f47c39cd0ced1fb03d6d8@o702202.ingest.sentry.io/5777568',
  integrations: [new Integrations.BrowserTracing()],
  logErrors: true,
  release: version,
  environment: window.VUE_APP_DEPLOYMENT_LABEL,
  enabled: import.meta.env.MODE === 'production',
  sampleRate: 1.0,
  tracesSampleRate: 0.1,
  maxBreadcrumbs: 100,
});

// we don't need to be warn in console about Vue being used in development mode,
// because our boilerplate handles everything from dev to prod modes.
Vue.config.productionTip = false;

// App initialization
async function initApp(): Promise<void> {
  // First fetch of static content in the right language
  const { loadLanguage } = useI18n();
  const locale = getLocalPreferences().locale ?? appConfig.defaultLocale;
  const [error, response] = await to(
    axios.get(`/api/translations?lang=${locale}`)
  );
  if (!error) loadLanguage(locale, response.data);
  store.commit('locale', locale);

  // Instantiate Vue app and mount it
  new Vue({
    i18n,
    el: '#app',
    router,
    store,
    render: h => h(App),
    // @ts-expect-error head is not typed
    unhead: head,
  });
}

// Run app initialization
initApp();
