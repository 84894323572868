import Vue from 'vue';
import Vuex, { Store as VuexStore } from 'vuex';
import createCache from 'vuex-cache';

// store modules
import login from '@/store/modules/login';
import env from '@/store/modules/env';
import bikes from '@/models/bike/store';
import vehicles from '@/models/vehicle/store';
import issues from '@/models/ticket/issue/store';
import stations from '@/models/station/store';
import users from '@/models/user/store';
import roles from '@/models/role/store';
import estimations from '@/models/estimation/store';
import bookings from '@/models/booking/store';
import trips from '@/models/trip/store';
import offers from '@/models/offer/store';
import offerPolicies from '@/models/offer-policy/store';
import omnis from '@/models/omni/store';
import benefits from '@/models/benefit/store';
import fasteners from '@/models/fastener/store';
import pointsOfInterest from '@/models/point-of-interest/store';
import rentals from '@/models/rental/store';
import areas from '@/models/area/store';
import scopes from '@/models/scope/store';
import payments from '@/store/modules/payments';
import fleet from '@/store/modules/fleet';
import notes from '@/models/ticket/note/store';
import parkings from '@/models/parking/store';
import datacenter from '@/store/modules/datacenter';
import operations from '@/store/modules/operations';
import metadataRegistry from '@/store/modules/metadata-registry';
import notifications from '@/store/modules/notifications';
import localAppState from '@/store/modules/local-app-state';
import { noop } from '@/lib/utils';

import type { ZOverlayProps } from '@/components/ui/molecules/ZOverlay.vue';
import type { VNode } from 'vue';
import type { StoreOptions } from 'vuex';
import type { LocalAppState } from '@/store/modules/local-app-state';
import type { Namespaced, TypedStore } from '@/store/types/helpers/store';
import type {
  StoreModuleState,
  StoreModuleGetters,
  StoreModuleMutations,
  StoreModuleActions,
  StoreModuleActionScopes,
} from '@/store/types/helpers/store-types';
import type {
  NotificationsState,
  notificationsModule,
} from '@/store/modules/notifications';
import type { LoginStoreTypes } from '@/store/modules/login';
import type { EnvStoreTypes } from '@/store/modules/env';
import type { BikeStoreTypes } from '@/models/bike/store';
import type { VehicleStoreTypes } from '@/models/vehicle/store';
import type { IssueStoreTypes } from '@/models/ticket/issue/store';
import type { StationStoreTypes } from '@/models/station/store';
import type { UserStoreTypes } from '@/models/user/store';
import type { RoleStoreTypes } from '@/models/role/store';
import type { EstimationStoreTypes } from '@/models/estimation/store';
import type { BookingStoreTypes } from '@/models/booking/store';
import type { TripStoreTypes } from '@/models/trip/store';
import type { OfferStoreTypes } from '@/models/offer/store';
import type { OfferPolicyStoreTypes } from '@/models/offer-policy/store';
import type { OmniStoreTypes } from '@/models/omni/store';
import type { BenefitStoreTypes } from '@/models/benefit/store';
import type { FastenerStoreTypes } from '@/models/fastener/store';
import type { PointOfInterestStoreTypes } from '@/models/point-of-interest/store';
import type { RentalStoreTypes } from '@/models/rental/store';
import type { AreaStoreTypes } from '@/models/area/store';
import type { ScopeStoreTypes } from '@/models/scope/store';
import type { NoteStoreTypes } from '@/models/ticket/note/store';
import type { PaymentsStoreTypes } from '@/store/modules/payments';
import type { FleetStoreTypes } from '@/store/modules/fleet';
import type { DatacenterStoreTypes } from '@/store/modules/datacenter';
import type { OperationsStoreTypes } from '@/store/modules/operations';
import type { MetadataRegistryStoreTypes } from '@/store/modules/metadata-registry';
import type { ParkingStoreTypes } from '@/models/parking/store';

type UsageType = 'area';

// register Vuex module
Vue.use(Vuex);

export interface RootState {
  refreshingToken: boolean;
  slowServer: boolean;
  offline: boolean;
  offlineRetry: () => void;
  redirectRoute: string | null;
  previousRoute: string | null;
  usage: { [U in UsageType]?: string[] };
  onDecoded: ((decodedId: string) => void) | null;
  lastScannedBike: string | null;
  connected: boolean;
  locked: boolean;
  connectLoading: boolean;
  lockLoading: boolean;
  unlockBatteryLoading: boolean;
  synchronized: boolean;
  synchronizing: boolean;
  syncFailed: boolean;
  bikeScannerOpen: boolean;
  connectTimeout: NodeJS.Timeout | null;
  connectedSerialNumber: null;
  previousBikeSafe: boolean;
  overlay: {
    props: ZOverlayProps;
    onClick: (event: MouseEvent) => void;
  };
  showRightPanel: boolean;
  settingsOpen: boolean;
  appInfoOpen: boolean;
  toggleableHintContent: {
    left: number;
    top: number;
    right: number;
    bottom: number;
    defaultSlots: VNode[];
    error?: boolean;
    isVisible: boolean;
  };
  isAppInitialized: boolean;
  locale: string;
}

const storeModules = {
  login: login.module,
  bikes: bikes.module,
  vehicles: vehicles.module,
  env: env.module,
  stations: stations.module,
  users: users.module,
  roles: roles.module,
  estimations: estimations.module,
  bookings: bookings.module,
  trips: trips.module,
  payments: payments.module,
  offers: offers.module,
  offerPolicies: offerPolicies.module,
  benefits: benefits.module,
  fasteners: fasteners.module,
  pointsOfInterest: pointsOfInterest.module,
  rentals: rentals.module,
  fleet: fleet.module,
  areas: areas.module,
  scopes: scopes.module,
  datacenter: datacenter.module,
  operations: operations.module,
  metadataRegistry: metadataRegistry.module,
  omnis: omnis.module,
  notes: notes.module,
  issues: issues.module,
  parkings: parkings.module,
} satisfies StoreOptions<RootState>['modules'];

export const storeDefinition = {
  // use vuex-cache plugin to cache some actions
  plugins: [createCache({ timeout: 500 })],

  modules: {
    ...storeModules,
    localAppState,
    notifications,
  },
  /*
   * Below, simple state items and mutations are not registered via a module
   */
  state: {
    refreshingToken: false,
    slowServer: false,
    offline: false,
    offlineRetry: noop,
    // used for redirection after successful login
    redirectRoute: null,
    previousRoute: '/',
    // global usage
    usage: { area: undefined },
    // global bike scanner
    onDecoded: null,
    lastScannedBike: null,
    connected: false,
    locked: false,
    connectLoading: false,
    lockLoading: false,
    unlockBatteryLoading: false,
    synchronized: false,
    synchronizing: false,
    syncFailed: false,
    bikeScannerOpen: false,
    connectTimeout: null,
    connectedSerialNumber: null,
    previousBikeSafe: false,
    // overlay
    overlay: {
      props: {
        show: false,
        clipPath: '',
      },
      onClick: (_: MouseEvent) => {},
    },
    showRightPanel: true,
    settingsOpen: false,
    appInfoOpen: false,
    toggleableHintContent: {
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      defaultSlots: [] as VNode[],
      error: false,
      isVisible: false,
    },
    isAppInitialized: false,
    locale: 'en',
  },
  /* prettier-ignore */
  getters: {
    refreshingToken: state => state.refreshingToken,
    slowServer: state => state.slowServer,
    offline: state => state.offline,
    offlineRetry: state => state.offlineRetry,
    redirectRoute: state => state.redirectRoute,
    previousRoute: state => state.previousRoute,
    usage: state => ({ name }: { name: UsageType }) => state.usage[name],
    onDecoded: state => state.onDecoded,
    lastScannedBike: state => state.lastScannedBike,
    connected: state => state.connected,
    locked: state => state.locked,
    connectLoading: state => state.connectLoading,
    lockLoading: state => state.lockLoading,
    unlockBatteryLoading: state => state.unlockBatteryLoading,
    synchronized: state => state.synchronized,
    synchronizing: state => state.synchronizing,
    syncFailed: state => state.syncFailed,
    bikeScannerOpen: state => state.bikeScannerOpen,
    connectTimeout: state => state.connectTimeout,
    connectedSerialNumber: state => state.connectedSerialNumber,
    previousBikeSafe: state => state.previousBikeSafe,
    overlay: state => state.overlay,
    showRightPanel: state => state.showRightPanel,
    settingsOpen: state => state.settingsOpen,
    appInfoOpen: state => state.appInfoOpen,
    toggleableHintContent: state => state.toggleableHintContent,
    isAppInitialized: state => state.isAppInitialized,
    locale: state => state.locale
  },
  /* prettier-ignore */
  mutations: {
    refreshingToken: (state, value: RootState['refreshingToken']) => (state.refreshingToken = value),
    slowServer: (state, value: RootState['slowServer']) => (state.slowServer = value),
    offline: (state, value: RootState['offline']) => (state.offline = value),
    offlineRetry: (state, value: RootState['offlineRetry']) => (state.offlineRetry = value),
    redirectRoute: (state, value: RootState['redirectRoute']) => (state.redirectRoute = value),
    previousRoute: (state, value: RootState['previousRoute']) => (state.previousRoute = value),
    usage: (state, { name, value }: { name: UsageType; value: string[] }) => (state.usage[name] = value),
    onDecoded: (state, value: RootState['onDecoded']) => (state.onDecoded = value),
    lastScannedBike: (state, value: RootState['lastScannedBike']) => (state.lastScannedBike = value),
    connected: (state, value: RootState['connected']) => (state.connected = value),
    locked: (state, value: RootState['locked']) => (state.locked = value),
    connectLoading: (state, value: RootState['connectLoading']) => (state.connectLoading = value),
    lockLoading: (state, value: RootState['lockLoading']) => (state.lockLoading = value),
    unlockBatteryLoading: (state, value: RootState['unlockBatteryLoading']) => (state.unlockBatteryLoading = value),
    synchronized: (state, value: RootState['synchronized']) => (state.synchronized = value),
    synchronizing: (state, value: RootState['synchronizing']) => (state.synchronizing = value),
    syncFailed: (state, value: RootState['syncFailed']) => (state.syncFailed = value),
    bikeScannerOpen: (state, value: RootState['bikeScannerOpen']) => (state.bikeScannerOpen = value),
    connectTimeout: (state, value: RootState['connectTimeout']) => (state.connectTimeout = value),
    connectedSerialNumber: (state, value: RootState['connectedSerialNumber']) => (state.connectedSerialNumber = value),
    previousBikeSafe: (state, value: RootState['previousBikeSafe']) => (state.previousBikeSafe = value),
    overlay: (state, value: RootState['overlay']) => (state.overlay = value),
    showRightPanel: (state, value: RootState['showRightPanel']) => (state.showRightPanel = value),
    settingsOpen: (state, value: RootState['settingsOpen']) => (state.settingsOpen = value),
    appInfoOpen: (state, value: RootState['appInfoOpen']) => (state.appInfoOpen = value),
    toggleableHintContent: (state, value: RootState['toggleableHintContent']) => (state.toggleableHintContent = value),
    isAppInitialized: (state, value: RootState['isAppInitialized']) => (state.isAppInitialized = value),
    locale: (state, value: RootState['locale']) => (state.locale = value)
  },
  actions: {},
} satisfies StoreOptions<RootState>;

export interface ModelStoreTypes
  extends BikeStoreTypes,
    VehicleStoreTypes,
    IssueStoreTypes,
    StationStoreTypes,
    UserStoreTypes,
    RoleStoreTypes,
    EstimationStoreTypes,
    BookingStoreTypes,
    TripStoreTypes,
    OfferStoreTypes,
    OfferPolicyStoreTypes,
    OmniStoreTypes,
    BenefitStoreTypes,
    FastenerStoreTypes,
    PointOfInterestStoreTypes,
    RentalStoreTypes,
    AreaStoreTypes,
    ScopeStoreTypes,
    PaymentsStoreTypes,
    FleetStoreTypes,
    NoteStoreTypes,
    ParkingStoreTypes {}

export interface StoreTypes
  extends ModelStoreTypes,
    LoginStoreTypes,
    EnvStoreTypes,
    DatacenterStoreTypes,
    OperationsStoreTypes,
    MetadataRegistryStoreTypes {}

export type StateType = keyof StoreModuleState<StoreTypes>;
export type GetterType = keyof StoreModuleGetters<StoreTypes>;
export type MutationType = keyof StoreModuleMutations<StoreTypes>;
export type ActionType<Method extends HttpMethod = HttpMethod> =
  keyof StoreModuleActions<StoreTypes> extends infer T
    ? T extends `${Method}_${string}`
      ? T
      : never
    : never;

export type ScopeType =
  StoreModuleActionScopes<StoreTypes>[keyof StoreModuleActionScopes<StoreTypes>];

type State = StoreModuleState<StoreTypes> &
  RootState &
  LocalAppState &
  Namespaced<'notifications', NotificationsState>;

type Getters = StoreModuleGetters<StoreTypes> &
  typeof storeDefinition.getters &
  typeof localAppState.getters &
  Namespaced<'notifications', typeof notificationsModule.getters>;

type Mutations = StoreModuleMutations<StoreTypes> &
  typeof storeDefinition.mutations &
  typeof localAppState.mutations &
  Namespaced<'notifications', typeof notificationsModule.mutations>;

type Actions = StoreModuleActions<StoreTypes> &
  typeof storeDefinition.actions &
  typeof localAppState.actions &
  Namespaced<'notifications', typeof notificationsModule.actions>;

/**
 * Fully typed store interface
 */
export type Store = TypedStore<State, Getters, Mutations, Actions>;
/**
 * Store instance, casted into a fully typed store
 */
const store = new VuexStore(storeDefinition) as Store;

export default store;
