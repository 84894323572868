import store from '@/store';
import { ProductVersion } from '@/enums/product';

import type { ApiSchema } from '#/core-api';

/**
 * Returns the firmware update product endpoint based on the product value
 * @param product - The product value
 * @returns The firmware endpoint
 */
export function productEndpoint(product?: ProductVersion): string {
  switch (product) {
    case ProductVersion.Zoov01:
      return 'zoov01';
    case ProductVersion.Zoov02:
      return 'zoov02';
    case ProductVersion.Edock01:
      return 'edock01';
    case ProductVersion.Zoov03:
      return 'zoov03';
    case ProductVersion.Flex:
      return 'flex';
    case ProductVersion.Proto:
      return 'proto';
    case ProductVersion.Fusion2:
      return 'fusion2';
    default:
      return String(product);
  }
}

/**
 * Returns the formatted product version id based on its enum value
 * @param productVersionId - product version id value
 * @returns Formatted product version id
 */
export function getProductVersionName(productVersionId: number): string {
  const productVersions: ApiSchema['fleet.ProductVersion'][] =
    store.getters['FLEET_PRODUCT_VERSIONS'] ?? [];

  const productVersion = productVersions.find(
    productVersion => productVersion.id === productVersionId
  );

  return productVersion?.name ?? String(productVersionId);
}
