<template lang="pug">
VAutocomplete.ZActionControlAutocomplete(
  v-show="canShow"
  ref="componentRef"
  v-model="modelValue"
  :style="style"
  :label="label"
  :rules="rules"
  :color="color"
  :disabled="isDisabled"
  :hint="comboboxHint"
  :placeholder="placeholder"
  :mask="mask"
  :messages="message"
  :hide-details="areDetailsHidden"
  :menu-props="{ maxHeight: 310, contentClass: 'ZActionControlAutocomplete__menu' }"
  :items="items"
  :item-text="control.itemText"
  :item-value="control.itemValue"
  :loading="isLoading"
  :search-input="control.searchInput"
  :append-icon="items?.length ? vuetify.icons.dropdown : ''"
  :multiple="isMultiple"
  :return-object="control.returnObject"
  :small-chips="isMultiple || control.chips"
  :no-filter="control.noFilter"
  :clearable="!control.unclearable"
  dense
  v-bind="additionalProps"
  @update:searchInput="search"
  @focus="handleFocus"
  @blur="handleBlur"
  @keyup.esc="handleEsc"
)
  template(
    v-if="hasSearch"
    #no-data
  )
    span.ZActionControlAutocomplete__noData(
      :class="{ 'ZActionControlAutocomplete__noData--error': searchState === SearchState.Error }"
    ) {{ helpText }}

  template(#item="{ item }")
    VListTileContent.ZActionControlAutocomplete__item(
      :class="{ 'ZActionControlAutocomplete__item--withSubtext': !!item.subtext }"
    )
      VListTileTitle.ZActionControlAutocomplete__item__title(
        :style="item.subtext ? { height: '18px' } : {}"
      )
        ZIcon.ZActionControlAutocomplete__item__titleIcon(
          v-if="item.icon"
          small
        ) {{ item.icon }}
        span {{ typeof item === 'object' ? item[control.itemText ?? 'text'] : item }}
        template(v-if="item.info")
          VDivider.ZActionControlAutocomplete__item__divider
          span(style="opacity: 0.65") {{ item.info }}
      VListTileSubTitle(v-if="item.subtext") {{ item.subtext }}
</template>

<style lang="stylus">
themed-autocomplete-menu('.ZActionControlAutocomplete__menu')

.ZActionControlAutocomplete__noData
  padding-left 8px
  font-size 13px
  inline-hint()

  &.ZActionControlAutocomplete__noData--error
    color $color-error !important

.ZActionControlAutocomplete__item--withSubtext
  margin-top 4px
  margin-bottom 4px

.ZActionControlAutocomplete__item__title
  display flex
  flex 1 1 auto
  max-width 100%
  align-items center

.ZActionControlAutocomplete__item__titleIcon
  margin-right 8px

.ZActionControlAutocomplete__item__divider
  margin-top 0
  margin-left 4px
  margin-right 4px
  padding-left 8px
  padding-right 8px
</style>

<script setup lang="ts">
import { useActionControl } from './useActionControl';

import { useI18n, useVuetify } from '@/composables/plugins';
import { check } from '@/lib/utils';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Data = any;

interface ZActionControlAutocompleteProps {
  /**
   * The control object
   */
  control: ActionControl<'autocomplete', keyof Data, Data>;
  /**
   * The parent action 'data' object
   */
  data: Data;
  /**
   * The control’s value.
   * Its model is currently `any` due to Vue 2 errors when receiving "null" values. TODO: fix in Vue 3
   * @model
   */
  value?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
}

const props = withDefaults(defineProps<ZActionControlAutocompleteProps>(), {
  value: '',
});

const emit = defineEmits<{
  (name: 'focus', event: FocusEvent): void;
  (name: 'blur', event: FocusEvent): void;
}>();

const { t } = useI18n();
const vuetify = useVuetify();

const {
  modelValue,
  generate,
  canShow,
  style,
  label,
  rules,
  color,
  isDisabled,
  hint,
  placeholder,
  mask,
  message,
  areDetailsHidden,
  additionalProps,
} = useActionControl(props);

const componentRef = ref<TemplateRef | null>(null);

const comboboxHint = computed(
  () => hint.value || (!check.hasTouch() ? t('action__tags_hint') : '')
);
const hasSearch = computed(() => !!props.control.search);
const searchItems = ref<(string | AutocompleteItem)[]>([]);
const isSearchLoading = ref(false);
const isFocused = ref(false);
const errorMessage = ref('');

enum SearchState {
  Start,
  Loading,
  NoData,
  Error,
}
const searchState = ref<SearchState>(SearchState.Start);

const helpText = computed(() => {
  switch (searchState.value) {
    case SearchState.Start:
      return props.control.searchText || t('action__start_typing_to_search');
    case SearchState.Loading:
      return t('action__autocomplete_loading');
    case SearchState.NoData:
      return (
        props.control.noDataText || t('action__autocomplete_no_matching_data')
      );
    case SearchState.Error:
      return `${t('action__autocomplete_error')}: ${errorMessage.value}`;
  }
});

const items = computed(() => {
  return hasSearch.value
    ? searchItems.value
    : generate(props.control.items ?? []);
});
const isLoading = computed(() => {
  return props.control.loading || isSearchLoading.value;
});

const isMultiple = computed(() => props.control.multiple);
// Make sure to reset input value when multiple changes, to avoid internal errors thrown by Vuetify
watch(isMultiple, newIsMultiple => {
  if (newIsMultiple) modelValue.value = [];
  else modelValue.value = '';
});

/**
 * Execute search to get the autocomplete items
 * @param value - The search input value
 */
async function search(value: string): Promise<void> {
  if (!props.control.search) return;
  if (!value) {
    if (searchItems.value.length > 0) {
      searchItems.value = [];
    }
    searchState.value = SearchState.Start;
    return;
  }
  if (!isFocused.value) return;
  if (isSearchLoading.value) return;

  isSearchLoading.value = true;
  searchState.value = SearchState.Loading;
  const [error, response] = await to(props.control.search(value));
  isSearchLoading.value = false;
  if (error) {
    errorMessage.value = error.message;
    searchState.value = SearchState.Error;
    return;
  }
  if (!response || !response.length) {
    searchState.value = SearchState.NoData;
    return;
  }
  searchItems.value = response;
}

function handleFocus(event: FocusEvent): void {
  isFocused.value = true;
  emit('focus', event);
}

function handleBlur(event: FocusEvent): void {
  isFocused.value = false;
  emit('blur', event);
}

function handleEsc(): void {
  componentRef.value?.blur?.();
}

function focus(): void {
  componentRef.value?.focus?.();
}

function resetValidation(): void {
  componentRef.value?.resetValidation?.();
}

defineExpose({
  /**
   * Focus the input
   */
  focus,
  /**
   * Reset input validation
   */
  resetValidation,
});
</script>
