import polyline from '@mapbox/polyline';

/**
 * Create a {@link GeoBundleFeature} from an encoded polyline.
 * @param encodedPolyline - The Google-type encoded polyline
 * @param properties - The properties of the feature with required `id`
 */
export default function createGeoBundleFeatureFromEncodedPolyline(
  encodedPolyline: string,
  properties: GeoBundleProperties
): GeoBundleFeature {
  const decodedCoordinates = polyline
    .decode(encodedPolyline)
    .map(coords => [coords[1], coords[0]]);
  return {
    type: 'Feature',
    geometry: {
      type: 'Polygon',
      coordinates: [decodedCoordinates],
    },
    properties,
  };
}
