import destr from 'destr';

import store from '@/store';
import { availablePreferences } from '@/config/preferences';
import { useAuth } from '@/composables';
import { keysOf } from '@/lib/utils';
import { getLocalPreferences } from '@/lib/helpers/get-local-preferences';

import type {
  PreferenceConfig,
  PreferenceGroup,
  PreferenceKey,
  PreferenceValueType,
} from '@/config/preferences';
import type { WritableComputedRef } from 'vue';

type UserSettings = Record<`preference:${string}`, string> | undefined;

export type Preferences = {
  [Key in PreferenceKey]: PreferenceValueType<Key>;
};

export interface UsePreferencesReturn {
  preferences: Preferences;
}

function setLocalPreferences(preferences: Preferences): void {
  localStorage.setItem('preferences', JSON.stringify(preferences));
}

const userId = ref<string>();
const userSettings = ref<UserSettings>();

type PreferencesStore = {
  [Key in PreferenceKey]: PreferenceValueType<Key> | undefined;
};

type PreferencesProxy = {
  [Key in PreferenceKey]: WritableComputedRef<Preferences[Key]>;
};

const preferencesStore: PreferencesStore = reactive({
  lang: undefined,
  locale: undefined,
  themeDark: undefined,
  qrSounds: undefined,
  mapDirectionsApp: undefined,
  mapBatteryLevel: undefined,
  mapBatterySwappingThreshold: undefined,
  latLngOrder: undefined,
  persistentFilters: undefined,
  rowsPerPage: undefined,
  pageSize: undefined,
  bikesDefaultOpenTab: undefined,
  bikesToCheckReallocationThreshold: undefined,
  globalAreaUsage: undefined,
  defaultLandingPage: undefined,
  displayedBikesPriority: undefined,
  displayedVehiclesPriority: undefined,
  mapLwm2mUpdateAlert: undefined,
  developerMode: undefined,
});

const preferencesProxy: PreferencesProxy = keysOf(
  preferencesStore
).reduce<PreferencesProxy>((proxy, key) => {
  // @ts-expect-error ts(2322) we iterate over keys which values does not have the same type and cannot be intersected
  proxy[key] = computed({
    get: () => getPreferenceValue(key),
    set: value => setPreferenceValue(key, value),
  });
  return proxy;
}, {} as PreferencesProxy);

const preferences = toReactive(preferencesProxy);

function isPreferenceGroup(
  groupOrDivider: PreferenceConfig
): groupOrDivider is PreferenceGroup {
  return !('divider' in groupOrDivider);
}

function getPreferenceDefault<
  K extends PreferenceKey,
  V extends Preferences[K],
>(key: K): V {
  const defaultConfig = availablePreferences
    .filter(isPreferenceGroup)
    .flatMap(({ items }) => items)
    .find(config => config.key === key)?.default;
  if (typeof defaultConfig === 'function') return defaultConfig() as V;
  return defaultConfig as V;
}

function getPreferenceValue<K extends PreferenceKey, V extends Preferences[K]>(
  key: K
): V {
  return (
    (preferencesStore[key] as V) ??
    destr<V>(userSettings.value?.[`preference:${key}`]) ??
    destr<V>(getLocalPreferences()[key]) ??
    getPreferenceDefault(key)
  );
}

function setPreferenceValue<K extends PreferenceKey, V extends Preferences[K]>(
  key: K,
  value?: V
): boolean {
  const { migrateKey } =
    availablePreferences
      .filter(isPreferenceGroup)
      .flatMap(({ items }) => items)
      .find(config => config.key === key) ?? {};

  store.dispatch('POST_USER_SETTINGS', {
    id: userId.value!,
    data: {
      settings: {
        ...(migrateKey ? { [`preference:${migrateKey}`]: String(value) } : {}),
        [`preference:${key}`]: String(value),
      },
    },
  });
  const localPreferences = getLocalPreferences();
  const updatedLocalPreferences = {
    ...localPreferences,
    ...(migrateKey ? { [migrateKey]: value } : {}),
    [key]: value,
  };
  setLocalPreferences(updatedLocalPreferences);
  if (migrateKey) preferencesStore[migrateKey as K] = value;
  preferencesStore[key] = value;
  return true;
}

// Synchronize user settings with local preferences when user settings are loaded
watch(userSettings, () => {
  keysOf(preferences).forEach(key => {
    if (!userSettings.value) return;
    if (`preference:${key}` in userSettings.value) {
      const value = destr<Preferences[typeof key]>(
        userSettings.value[`preference:${key}`]
      );
      if (value !== undefined) {
        const localPreferences = getLocalPreferences();
        const updatedLocalPreferences = {
          ...localPreferences,
          [key]: value,
        };
        setLocalPreferences(updatedLocalPreferences);
      }
    }
  });
});

const isInitialized = ref(false);

export function usePreferences(): UsePreferencesReturn {
  const { userId: _userId, user } = useAuth();

  if (!isInitialized.value) {
    userId.value = _userId.value;
    userSettings.value = user.value?.settings;

    watch(_userId, newUserId => (userId.value = newUserId));
    watch(user, newUser => (userSettings.value = newUser?.settings));

    isInitialized.value = true;
  }

  return {
    preferences,
  };
}
