<template lang="pug">
VBtn.ZOpenPageButton(
  flat
  small
  :to="to"
  @click="handleClick"
)
  em {{ text }}
  ZIcon.ZOpenPageButton__icon(size="16") {{ icon }}
</template>

<style lang="stylus">
.ZOpenPageButton
  border-radius 16px
  height 24px
  display flex
  margin 2px 0 2px 8px
  padding 0 12px
  min-width auto
  text-transform none
  font-weight normal
  font-size 13px
  white-space nowrap
  align-items center
  text-decoration none

  &__icon
    margin-left 4px

.theme--light
  .ZOpenPageButton
    transform translateY(1px)
    margin-left 4px
    box-shadow inset 0 0 0 1px rgba(0, 0, 0, 0.15)
    color rgba(0, 0, 0, 0.87)
    background-color rgba(0, 0, 0, 0.06)

    &:hover
      background-color rgba(0, 0, 0, 0.12)

.theme--dark
  .ZOpenPageButton
    box-shadow inset 0 0 0 1px rgba(255, 255, 255, 0.2)
    color rgba(255, 255, 255, 0.87)
    background-color rgba(255, 255, 255, 0.1)

    &:hover
      background-color rgba(255, 255, 255, 0.2)
</style>

<script setup lang="ts">
import type { RawLocation } from 'vue-router';

const emit = defineEmits<{
  (event: 'click'): void;
}>();

export interface ZOpenPageButtonProps {
  /**
   * The button link.
   */
  to: RawLocation;
  /**
   * The button text
   */
  text: string;
  /**
   * The button icon
   */
  icon: string;
}

const props = defineProps<ZOpenPageButtonProps>();

const router = useRouter();

async function handleClick(): Promise<void> {
  emit('click');

  // Wait for the click event to be handled
  // before navigating to the new page
  await nextTick();

  router.push(props.to);
}
</script>
