<template lang="pug">
VSwitch.ZActionControlSwitch(
  v-show="canShow"
  ref="componentRef"
  v-model="modelValue"
  :style="style"
  :label="label"
  :rules="rules"
  :color="color"
  :disabled="isDisabled"
  :hint="hint"
  :messages="message"
  :hide-details="areDetailsHidden"
  v-bind="additionalProps"
)
</template>

<style lang="stylus">
.ZActionControlSwitch.v-input--switch
  .v-input__control
    width 100%

  .v-input__slot
    flex-direction row-reverse

  .v-label
    flex 1 1 auto

    &::after
      content ''
      display block
      height 1px
      right 0
      flex 1 1 auto
      margin 8px

    &.theme--dark::after
      background-color rgba(255, 255, 255, 0.12)

    &.theme--light::after
      background-color rgba(0, 0, 0, 0.12)
</style>

<script setup lang="ts">
import { useActionControl } from './useActionControl';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Data = any;

interface ZActionControlSwitchProps {
  /**
   * The control object
   */
  control: ActionControl<'switch', keyof Data, Data>;
  /**
   * The parent action 'data' object
   */
  data: Data;
  /**
   * The control’s value
   * @model
   */
  value?: boolean;
}

const props = withDefaults(defineProps<ZActionControlSwitchProps>(), {
  value: false,
});

const {
  modelValue,
  canShow,
  style,
  label,
  rules,
  color,
  isDisabled,
  hint,
  message,
  areDetailsHidden,
  additionalProps,
} = useActionControl(props);

const componentRef = ref<TemplateRef | null>(null);

function focus(): void {
  componentRef.value?.focus?.();
}

function resetValidation(): void {
  componentRef.value?.resetValidation?.();
}

defineExpose({
  /**
   * Focus the input
   */
  focus,
  /**
   * Reset input validation
   */
  resetValidation,
});
</script>
