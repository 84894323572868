/*
 *  Navigation guards
 */

import store from '@/store';
import { appConfig } from '@/config';
import { parseJwt } from '@/lib/utils';
import { useAuth } from '@/composables';
import { usePrivileges, usePreferences } from '@/composables/plugins';

import type { NavigationGuard } from 'vue-router';

export function getRedirectRoute(): string {
  const { preferences } = usePreferences();

  return (
    store.state.redirectRoute ||
    preferences?.defaultLandingPage ||
    appConfig.loginDefaultRedirectRoute
  );
}

// validates login in /login route and redirects
export const validateLogin: NavigationGuard = (_, __, next) => {
  if (store.getters['APP_LOGGED_IN']) {
    return getRedirectRoute();
  }

  return next();
};

const checkAuthStatus: NavigationGuard = async (toRoute, _, next) => {
  // If user is logged in, no need to check auth
  if (store.getters['APP_LOGGED_IN']) {
    return;
  }

  const isAuthenticated = await store.dispatch('GET_AUTH_STATUS');

  if (!isAuthenticated) {
    if (toRoute.path !== '/login' && !/\/(4|5)[0-9]{2}/.test(toRoute.path)) {
      store.commit('redirectRoute', toRoute.path);
    }

    return next(toRoute.path !== '/login' ? '/login' : undefined);
  }

  const token = await store.dispatch('GET_USER_TOKEN');
  store.commit('LOGIN_DATA', {
    data: { ...parseJwt(token), token },
  });

  store.commit('APP_LOGGED_IN', { data: true });

  if (toRoute.path === '/login') {
    const redirectRoute = getRedirectRoute();
    if (redirectRoute) {
      return next(redirectRoute);
    }
  }
};

// Global guard called before each route
export const beforeEachGuard: NavigationGuard = async (
  toRoute,
  fromRoute,
  next
) => {
  await checkAuthStatus(toRoute, fromRoute, next);

  const permissionRedirection = await permissionsGuard(
    toRoute,
    fromRoute,
    next
  );
  if (permissionRedirection) return next(permissionRedirection);

  return next();
};

const permissionsGuard: NavigationGuard = async toRoute => {
  // always allowed routes
  if (['4xx Error', 'Login'].some(name => toRoute.name === name)) return;

  const { userId } = useAuth();
  const { onReady, isGrantedRoute } = usePrivileges();

  await onReady();

  // get permission
  const ownUserRoute = userId.value === toRoute.params.id;
  const allowedRoute = ownUserRoute || isGrantedRoute(toRoute.name);

  if (allowedRoute) return;

  if (toRoute.path !== '/403') {
    return '/403';
  }
};
