<template lang="pug">
.ZActionControlSlot
  slot(
    v-if="canShow"
    :slot-name="control.name"
    :data="data"
  )
</template>

<script setup lang="ts">
import { useActionControl } from './useActionControl';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Data = any;

interface ZActionControlTextareaProps {
  /**
   * The control object
   */
  control: ActionControl<'slot', keyof Data, Data>;
  /**
   * The parent action 'data' object
   */
  data: Data;
  /**
   * The control’s value
   * @model
   */
  value?: string;
}

const props = withDefaults(defineProps<ZActionControlTextareaProps>(), {
  value: '',
});

const { canShow } = useActionControl(props);
</script>
