import { AlarmType } from '#/core-api/enums';

import { defaultEnumDisplayProperties } from '@/lib/utils';

export function omniAlarmType(alarmType?: AlarmType): DisplayProperties {
  switch (alarmType) {
    case AlarmType.Fall:
      return {
        message: 'omnis__fall',
        class: 'warn',
      };
    case AlarmType.FallCleared:
      return {
        message: 'omnis__fall_cleared',
        class: 'info',
        icon: 'mdi-lock',
      };
    case AlarmType.IllegalMovement:
      return {
        message: 'omnis__illegal_movement',
        class: 'warn',
      };
    case AlarmType.UnknownAlarmType:
      return {
        message: 'omnis__unknown_alarm_type',
        class: 'warn',
      };
    default:
      return defaultEnumDisplayProperties(alarmType);
  }
}
