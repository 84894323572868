/**
 * Check whether the given date is the default "empty" mongoDB date
 * @param date – The input date, in the form of a string or a JS date
 */
export default function isDefaultMongoDate(
  date?: Date | string | number | null
): boolean {
  if (!date || Object.prototype.toString.call(date) !== '[object Date]') {
    return false;
  }

  return new Date(date).toISOString() === '0001-01-01T00:00:00.000Z';
}
