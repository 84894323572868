import { uniqueKeyFromLabel } from '@/lib/utils';
import { useI18n } from '@/composables/plugins';
import { mapboxConfig } from '@/config';
import {
  isVehicleInWarehouse,
  isVehicleLocalizedInWarehouse,
} from '@/models/vehicle/helpers';

import type { Ref } from 'vue';
import type { MapVehicle } from '@/models/vehicle/map/types';
import type { MapArea } from '@/models/area/map/types';

const warehouseLayer: GeoBundleLayer = {
  type: 'symbol',
  layout: {
    'icon-image': [
      'case',
      ['get', '_selected'],
      'warehouse-selected',
      'warehouse',
    ],
    'icon-size': {
      stops: [
        [9, 0.4],
        [11, 0.8],
      ],
    },
    'icon-ignore-placement': true,
    'icon-allow-overlap': true,
    'text-allow-overlap': true,
    'text-ignore-placement': true,
    'text-field': ['get', 'vehiclesCountText'],
    'text-size': 10,
    'text-offset': [0, 1.5],
  },
  paint: {
    ...mapboxConfig.textPaint,
    'text-opacity': {
      stops: [
        [10, 0],
        [11, 1],
      ],
    },
  },
};

function getVehiclesInWarehouse(
  area: MapArea,
  vehicles?: MapVehicle[]
): MapVehicle[] {
  return (vehicles ?? []).filter(vehicle => {
    if (
      vehicle.area_id !== area.id ||
      !vehicle.maintenance_state ||
      !area.warehouse?.location?.coordinates ||
      !vehicle.location?.coordinates
    ) {
      return false;
    }
    return (
      isVehicleInWarehouse(vehicle.maintenance_state) &&
      isVehicleLocalizedInWarehouse(
        vehicle.location.coordinates,
        area.warehouse.location.coordinates
      )
    );
  });
}

export interface MapWarehouse {
  id: string;
  area?: string;
  location?: { coordinates?: Coordinates };
  total_vehicles: number;
  vehicles: MapVehicle[];
}

export interface UseVehicleWarehousesReturn {
  /**
   * The warehouses list
   */
  warehouses: ComputedRef<MapWarehouse[]>;
  /**
   * The warehouses geo-bundles
   */
  geoBundles: ComputedRef<GeoBundle[]>;
}

/**
 * Based on areas, and optionnaly vehicles to compute the vehicles in warehouse,
 * get warehouses list and associated geo-bundles.
 * @param areas - The areas list
 * @param vehicles - The vehicles list
 * @param selectable - Whether the warehouse geo-bundles are selectable
 */
export function useWarehouses({
  areas,
  vehicles,
  selectable,
}: {
  areas: Ref<MapArea[]>;
  vehicles?: Ref<MapVehicle[]>;
  selectable?: boolean;
}): UseVehicleWarehousesReturn {
  const { tc } = useI18n();

  const warehouses = computed(() => {
    return areas.value.reduce<MapWarehouse[]>((warehouses, area, index) => {
      if (!area.warehouse?.location?.coordinates) return warehouses;

      const vehiclesInWarehouse = getVehiclesInWarehouse(area, vehicles?.value);
      const id = uniqueKeyFromLabel({
        label: area.label,
        mapKey: 'warehouses',
        reset: index === 0,
      });

      warehouses.push({
        id,
        area: area.label,
        location: area.warehouse.location,
        total_vehicles: vehiclesInWarehouse.length,
        vehicles: vehiclesInWarehouse,
      });

      return warehouses;
    }, []);
  });

  const features = computed(() => {
    return warehouses.value.map(warehouse => {
      const vehiclesCount = warehouse.vehicles.length;
      return {
        type: 'Feature',
        properties: {
          id: warehouse.id,
          type: 'warehouse',
          vehiclesCountText: `${vehiclesCount} ${tc(
            'data__vehicle',
            vehiclesCount
          )}`,
        },
        geometry: {
          type: 'Point',
          coordinates: warehouse.location?.coordinates ?? [],
        },
      } satisfies GeoBundleFeature;
    });
  });

  const geoBundles = computed(() => [
    {
      id: 'warehouses',
      options: { pointer: selectable, selectable },
      source: {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: features.value,
        },
      },
      layers: [warehouseLayer],
    } satisfies GeoBundle,
  ]);

  return {
    warehouses,
    geoBundles,
  };
}
