import {
  TicketSeverity,
  TicketStatus,
  TicketTarget,
  TicketType,
} from '#/core-api/enums';

import {
  areaIcon,
  benefitIcon,
  fastenerIcon,
  offerIcon,
  rentalIcon,
  stationIcon,
  tripIcon,
  userIcon,
  vehicleIcon,
} from '@/config/icons';
import { IssueSource } from '@/models/ticket/issue/enums';

export function issueType(type?: TicketType): DisplayProperties {
  switch (type) {
    default:
    case TicketType.NotSet:
      return {
        message: 'tickets__type_unknown',
        icon: 'mdi-help-circle-outline',
      };
    case TicketType.Note:
      return {
        message: 'tickets__type_note',
        icon: 'mdi-note-text',
      };
    case TicketType.Issue:
      return {
        message: 'tickets__type_issue',
        icon: 'mdi-clipboard-text',
      };
    case TicketType.Action:
      return {
        message: 'tickets__type_action',
        icon: 'mdi-gesture-tap-button',
      };
  }
}

export function issueStatus(status?: TicketStatus): DisplayProperties {
  switch (status) {
    default:
    case TicketStatus.NotSet:
      return {
        message: 'issues__no_issue_status',
        class: '',
        icon: 'mdi-help-circle-outline',
      };
    case TicketStatus.Open:
      return {
        message: 'issues__open',
        class: 'error',
        icon: 'mdi-clipboard-text-outline',
      };
    case TicketStatus.Closed:
      return {
        message: 'issues__closed',
        class: 'success',
        icon: 'mdi-clipboard-check-outline',
      };
  }
}

export const issueTargetModelList = [
  TicketTarget.Vehicle,
  TicketTarget.Parking,
  TicketTarget.Station,
  TicketTarget.User,
  TicketTarget.Trip,
  TicketTarget.Rental,
  TicketTarget.Benefit,
  TicketTarget.Offer,
  TicketTarget.Area,
];

export function issueSeverity(severity?: TicketSeverity): DisplayProperties {
  switch (severity) {
    default:
    case TicketSeverity.NotSet:
      return {
        message: 'issues__no_issue_severity',
        class: '',
      };
    case TicketSeverity.Low:
      return {
        message: 'issues__severity_low',
        class: 'disabled',
        icon: 'mdi-chevron-down-circle',
      };
    case TicketSeverity.Medium:
      return {
        message: 'issues__severity_medium',
        class: 'warning',
        icon: 'svg:equal_circle',
      };
    case TicketSeverity.High:
      return {
        message: 'issues__severity_high',
        class: 'error',
        icon: 'mdi-chevron-up-circle',
      };
  }
}

export function issueTarget(target?: TicketTarget): DisplayProperties {
  switch (target) {
    default:
    case TicketTarget.NotSet:
      return {
        message: 'issues__target__not_set',
        class: '',
      };
    case TicketTarget.Vehicle:
      return {
        message: 'issues__target__vehicle',
        icon: vehicleIcon,
      };
    case TicketTarget.Parking:
      return {
        message: 'issues__target__fastener',
        icon: fastenerIcon,
      };
    case TicketTarget.Station:
      return {
        message: 'issues__target__station',
        icon: stationIcon,
      };
    case TicketTarget.User:
      return {
        message: 'issues__target__user',
        icon: userIcon,
      };
    case TicketTarget.Trip:
      return {
        message: 'issues__target__trip',
        icon: tripIcon,
      };
    case TicketTarget.Rental:
      return {
        message: 'issues__target__rental',
        icon: rentalIcon,
      };
    case TicketTarget.Benefit:
      return {
        message: 'issues__target__benefit',
        icon: benefitIcon,
      };
    case TicketTarget.Offer:
      return {
        message: 'issues__target__offer',
        icon: offerIcon,
      };
    case TicketTarget.Area:
      return {
        message: 'issues__target__area',
        icon: areaIcon,
      };
  }
}

export function issueSource(source?: IssueSource): DisplayProperties {
  switch (source) {
    default:
      return {
        message: '',
        icon: '',
      };
    case IssueSource.Angels:
      return {
        message: 'issues__source__angels',
        icon: 'svg:angels_logo',
      };
    case IssueSource.Automations:
      return {
        message: 'issues__source__automations',
        icon: 'mdi-cog-sync',
      };
    case IssueSource.Control:
      return {
        message: 'issues__source__control',
        icon: 'svg:control_logo',
      };
    case IssueSource.EndUserApp:
      return {
        message: 'issues__source__end_user_app',
        icon: 'mdi-cellphone',
      };
  }
}
