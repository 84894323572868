<!-- eslint-disable vue/no-v-text-v-html-on-component -->
<template lang="pug">
VMenu(
  v-model="isOpen"
  offset-y
  bottom
  left
  :close-on-content-click="false"
  max-width="300px"
  content-class="ZUserMenu"
)
  template(#activator)
    ZBadge.ZUserMenu__updateBadge(
      icon="mdi-record"
      :icon-color="showUpdateAvailable ? 'success' : 'transparent'"
      dense
    )
      VBtn(icon)
        VIcon(
          large
          color="fifteen_control"
        ) mdi-account-circle
  VCard.ZUserMenu__header
    VCardTitle
      .ZUserMenu__email
        span.ZUserMenu__email__text(:title="user?.email") {{ user?.email }}
        VSpacer
        ZTooltip(left)
          template(#activator)
            VBtn(
              icon
              small
              left
              v-bind="userId ? { to: { name: 'User', params: { id: userId } } } : {}"
              @click="isOpen = false"
            )
              VIcon(small) mdi-card-account-details
          span {{ t('menu__open_user') }}
      .ZUserMenu__id
        span {{ userId }}
      .ZUserMenu__id
        span {{ user?.phone }}

      .ZUserMenu__userRoles
        ZMiniChip(
          v-for="(role, i) in displayRoles"
          :key="'role-' + i"
          v-bind="isGranted('admin.route.role') ? { to: wrapRoute('/roles/' + role.name) } : {}"
        )
          ZIcon(
            :size="13"
            :color="role.class"
          ) {{ role.icon }}
          span {{ role.message }}

  VList.ZUserMenu__list(dense)
    VListTile(
      v-if="showUpdateAvailable"
      @click="updateServiceWorker"
    )
      VListTileAction
        ZBadge(
          icon="mdi-record"
          :icon-color="showUpdateAvailable ? 'success' : 'transparent'"
          dense
          relative
        )
          VIcon mdi-update
      VListTileContent
        VListTileTitle {{ t('app__update_webapp') }}
        VListTileSubTitle {{ t('app__update_new_version', { version: newVersionAvailable }) }}
    Privilege(permission="admin.app.notifications")
      ZAppNotifications(
        list-item
        @click="isOpen = false"
      )
    ZSettings
      template(#activator)
        VListTile(@click="isOpen = false")
          VListTileAction
            VIcon mdi-cog
          VListTileContent
            VListTileTitle {{ t('app__settings') }}
    ZChangeDomain(list-item)
    ZAppInfo
      template(#activator)
        VListTile(@click="isOpen = false")
          VListTileAction
            VIcon mdi-information-outline
          VListTileContent
            VListTileTitle {{ t('app__open_app_info') }}
    VListTile(@click="logout")
      VListTileAction
        VIcon mdi-exit-to-app
      VListTileContent
        VListTileTitle {{ t('menu__log_out') }}
</template>

<style lang="stylus">
.ZUserMenu
  border 1px solid $colors.grey.lighten-2
  border-radius 16px

  &__header
    user-select none

    .v-btn--icon.v-btn--small
      width 26px
      height 26px

    .v-card__title
      display block

  &__email
    font-size 16px
    display flex
    align-items center

    &__text
      height max-content

  &__id
    margin-bottom 6px
    opacity 0.75

.ZUserMenu__updateBadge.ZBadge .v-badge__badge
  top 0
  left calc(100% - 8px)

.ZUserMenu__userRoles
  max-height 180px
  overflow-y auto
  margin-right -4px
  display flex
  flex-wrap wrap
  gap 4px

  .mini-chip,
  .mini-chip > .ZSvgIcon
    color white

  &--admin
    margin-left 2px

  .theme--dark.VIcon,
  .theme--light.VIcon
    color inherit

  .theme--dark &
    scrollbar-style($colors.grey.darken-2, $color-primary)

  .theme--light &
    scrollbar-style($colors.grey.lighten-1, $color-primary)

  scrollbar-on-hover()

.ZUserMenu__email
  font-size 18px
  height 24px

.ZUserMenu__id
  font-size 12px
  margin-bottom 16px

.ZUserMenu__email__text
  text-overflow ellipsis
  overflow hidden

.ZUserMenu__list
  .ZBadge
    width 12px

  .v-list__tile__subtitle
    font-size 12px
    opacity 0.75

.ZUserMenu__header
  &.theme--light.v-sheet
    border-bottom 1px solid
    border-color: $colors.grey.lighten-2

  &.theme--dark.v-sheet
    border-bottom 1px solid
    border-color: $colors.grey.darken-2

.theme--dark
  .ZUserMenu__header
    box-shadow none

  .ZUserMenu
    border 1px solid $colors.grey.darken-2

.ZUserMenu__locale--active
  color $color-primary
</style>

<script setup lang="ts">
import { VDivider } from 'vuetify/lib';

import { useI18n, usePrivileges } from '@/composables/plugins';
import { roleName } from '@/models/role/mappers/display';
import { useServiceWorkerPrompt, useWrapRoute, useAuth } from '@/composables';

import ZSettings from '@/components/ui/organisms/ZSettings.vue';
import ZAppNotifications from '@/components/ui/organisms/ZAppNotifications.vue';
import ZChangeDomain from '@/components/ui/molecules/ZChangeDomain.vue';
import ZBadge from '@/components/ui/molecules/ZBadge.vue';
import ZAppInfo from '@/components/ui/molecules/ZAppInfo.vue';

const emit = defineEmits<{
  (name: 'logout'): void;
}>();

const isOpen = ref(false);

const { updateServiceWorker, newVersionAvailable, showUpdateAvailable } =
  useServiceWorkerPrompt();

const { t } = useI18n();
const { userRoles, user, userId } = useAuth();
const { isGranted } = usePrivileges();
const { wrapRoute } = useWrapRoute();

const displayRoles = computed(() =>
  userRoles.value.map(role => ({
    name: role,
    ...roleName(role),
  }))
);

function logout(): void {
  emit('logout');
  isOpen.value = false;
}
</script>
