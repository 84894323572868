<template lang="pug">
.ZSvgIcon(
  :style="style"
  :class="classes"
  v-html="svgMarkupWithUniqueIds"
)
</template>

<style lang="stylus">
.v-btn .v-btn__content .ZSvgIcon
  color inherit

.ZSvgIcon,
.v-btn--floating .v-icon.ZSvgIcon,
.v-alert .v-alert__icon.v-icon.ZSvgIcon,
.v-alert__dismissible .v-icon.ZSvgIcon // and dismissible alert
  display inline-flex
  vertical-align middle
  width 24px
  height 24px
  min-width 24px
  min-height 24px

  svg
    width 100%

  &:not(.self-color):not(.self-color--text):not(.ZSvgIcon--selfColor)
    svg
      path,
      polygon,
      rect
        fill currentColor

      line,
      polyline,
      circle,
      ellipse
        stroke currentColor

.v-alert .v-alert__icon.v-icon.ZSvgIcon,
.v-alert__dismissible .v-icon.ZSvgIcon // and dismissible alert
  // we need to se max-width & max-height too
  max-width 24px
  max-height 24px

.ZSvgIcon.ZSvgIcon--small,
.v-stepper__step__step .v-icon.ZSvgIcon // override when icon is in stepper step dot
  width 16px
  height 16px
  min-width 16px
  min-height 16px

.ZSvgIcon.ZSvgIcon--large
  width 32px
  height 32px
  min-width 32px
  min-height 32px

.ZSvgIcon--spin
  animation z-svg-icon-spin 1.5s linear infinite

@keyframes z-svg-icon-spin
  0%
    transform rotate(0)

  100%
    transform rotate(360deg)
</style>

<script setup lang="ts">
import { parseDocument, ElementType } from 'htmlparser2';

import { useColors } from '@/composables';

import type { ChildNode } from 'domhandler';

export interface ZSvgIconProps {
  /**
   * SVG markup to be used as the icon
   */
  svgMarkup?: string;
  /**
   * Whether the icon should be displayed small
   */
  small?: boolean;
  /**
   * Whether the icon should be displayed large
   */
  large?: boolean;
  /**
   * Size of the icon, can be a number or a string
   */
  size?: number | string | null;
  /**
   * Color of the icon
   */
  color?: Color;
  /**
   * Whether the icon should be aligned to the right
   */
  right?: boolean;
  /**
   * Whether the icon should spin
   */
  spin?: boolean;
  /**
   * Whether the icon should be self-colored
   */
  selfColor?: boolean;
}

const props = withDefaults(defineProps<ZSvgIconProps>(), {
  svgMarkup: '',
  small: false,
  large: false,
  size: null,
  color: '',
  right: false,
  spin: false,
  selfColor: false,
});

const { themeColor } = useColors();

const resolvedColor = computed(() => themeColor(props.color));

const style = computed(() => {
  const color =
    (resolvedColor.value ?? '')[0] === '#' ? resolvedColor.value : '';
  if (props.size && !Number.isNaN(parseInt(String(props.size)))) {
    const size = `${parseInt(String(props.size))}px`;
    return {
      width: size,
      height: size,
      'min-width': size,
      'min-height': size,
      color,
    };
  } else {
    return { color };
  }
});

const classes = computed(() => ({
  'ZSvgIcon--spin': props.spin,
  'ZSvgIcon--small': props.small,
  'ZSvgIcon--large': props.large,
  'ZSvgIcon--selfColor': props.selfColor,
  [`${props.color}--text`]:
    !!resolvedColor.value && resolvedColor.value[0] !== '#',
  'v-icon--right': props.right,
}));

const svgMarkupWithUniqueIds = computed(() => {
  let markup = props.svgMarkup;
  if (!markup) return '';

  // generate unique ids for id attributes, masks and linear-gradients fills
  // and replace svg markup with these updated ids
  // TODO: when moving to Vue 3, use built-in `useId` instead
  // @ts-expect-error _uid is not documented as part of current instance, but always exist
  const uid = getCurrentInstance()?.proxy?._uid;
  const urlIdRegex = /^url\(#([a-zA-Z0-9_-]+)\)/;
  const parsedMarkup = parseDocument(markup);

  function findAttrs(nodes: ChildNode[] = []): void {
    nodes.forEach(node => {
      node.type === ElementType.Tag &&
        node.attributes?.forEach(attr => {
          if (attr.name === 'id') {
            const id = attr.value;
            const attrIdRegex = new RegExp(`id="${id}"`);
            markup = markup?.replace(attrIdRegex, `id="${id}-${uid}"`);
          }
          const exec = urlIdRegex.exec(attr.value);
          if (exec) {
            const id = exec[1];
            const refIdRegex = new RegExp(`url\\(#${id}\\)`);
            markup = markup?.replace(refIdRegex, `url(#${id}-${uid})`);
          }
        });
      if ('children' in node) {
        findAttrs(node.children);
      }
    });
  }

  findAttrs(parsedMarkup.children);

  return markup;
});
</script>
