import { default as MapboxGeocoderClass } from '@mapbox/mapbox-gl-geocoder';

import type { default as MapboxglType } from 'mapbox-gl';
import type { default as MapboxDrawType } from '@mapbox/mapbox-gl-draw';
import type { MapiResponse } from '@mapbox/mapbox-sdk/lib/classes/mapi-response';
import type { Ref, ShallowRef } from 'vue';

class AugmentedMapboxGeocoder extends MapboxGeocoderClass {
  // @ts-expect-error - MapboxGeocoder does not expose the container property
  public container: HTMLDivElement;
  // @ts-expect-error - MapboxGeocoder does not expose the _inputEl property
  public _inputEl: HTMLInputElement;
  // @ts-expect-error - MapboxGeocoder does not expose the _collapse property
  public _collapse: () => void;
  // @ts-expect-error - MapboxGeocoder does not expose the _unCollapse property
  public _unCollapse: () => void;
  // @ts-expect-error - MapboxGeocoder does not expose the _geocode property
  public _geocode: (query: string) => MapiResponse;
  // @ts-expect-error - MapboxGeocoder does not expose the _clear property
  public _onQueryResult: (response: { body: MapiResponse['body'] }) => void;

  constructor(options: MapboxGeocoder.GeocoderOptions) {
    super(options);
  }
}

const map = ref<mapboxgl.Map | null>(null);
const mapLoaded = ref<boolean>(false);
const mapInitialized = ref<boolean>(false);
const styleLoaded = ref<boolean>(false);
const style = ref<string | null>(null);
const geocoderControl = shallowRef<AugmentedMapboxGeocoder | null>(null);
const mapboxgl = shallowRef<typeof MapboxglType | null>(null);
const MapboxGeocoder = shallowRef<typeof AugmentedMapboxGeocoder | null>(null);
const MapboxDraw = shallowRef<typeof MapboxDrawType | null>(null);

export interface UseMapboxReturn {
  /**
   * Map instance
   */
  map: Ref<mapboxgl.Map | null>;
  /**
   * Whether the map is loaded
   */
  mapLoaded: Ref<boolean>;
  /**
   * Flag that indicates whether map has been initialized
   */
  mapInitialized: Ref<boolean>;
  /**
   * Whether the map style is loaded
   */
  styleLoaded: Ref<boolean>;
  /**
   * Current map style
   */
  style: Ref<string | null>;
  /**
   * Geocoder control instance
   */
  geocoderControl: ShallowRef<AugmentedMapboxGeocoder | null>;
  /**
   * mapboxgl script
   */
  mapboxgl: ShallowRef<typeof MapboxglType | null>;
  /**
   * MapboxGeocoder class
   */
  MapboxGeocoder: ShallowRef<typeof AugmentedMapboxGeocoder | null>;
  /**
   * MapboxDraw class
   */
  MapboxDraw: ShallowRef<typeof MapboxDrawType | null>;
  /**
   * Load mapbox scripts once
   */
  loadScripts: () => Promise<void>;
}

async function loadScripts(): Promise<void> {
  if (!mapboxgl.value) {
    const mapboxglImport = await import('mapbox-gl');
    mapboxgl.value = mapboxglImport.default;
  }
  if (!MapboxGeocoder.value) {
    const mapboxGeocoderImport = await import('@mapbox/mapbox-gl-geocoder');
    MapboxGeocoder.value =
      mapboxGeocoderImport.default as typeof AugmentedMapboxGeocoder;
  }
  if (!MapboxDraw.value) {
    const mapboxDrawImport = await import('@mapbox/mapbox-gl-draw');
    MapboxDraw.value = mapboxDrawImport.default;
  }
}

/**
 * Composable that asynchronously loads mapbox scripts and give access
 * to a single map and related structure instances, shared across app
 */
export function useMapbox(): UseMapboxReturn {
  return {
    map,
    mapLoaded,
    mapInitialized,
    styleLoaded,
    style,
    geocoderControl,
    mapboxgl,
    MapboxGeocoder,
    MapboxDraw,
    loadScripts,
  };
}
