import theme from '@/themes';
import { createLayers } from '@/models/area/map/layers';
import {
  createFeatureCollections,
  createInterventionZoneFeatureCollection,
} from '@/models/area/map/features';

import type { AreasGeoBundleOptions } from '@/models/area/map/types';
import type { InterventionZone } from '@/models/area/helpers';

const interventionZoneColor: mapboxgl.Expression = [
  'case',
  ['get', '_dark'],
  theme.success,
  theme.success_darker2,
];

export function createAreasGeoBundles({
  areas,
  outlined,
  selectableTypes,
  contextMenuDataTypes,
  t,
}: AreasGeoBundleOptions): GeoBundle[] {
  const {
    allowedFeatureCollection,
    unallowedFeatureCollection,
    policiesFeatureCollection,
  } = createFeatureCollections(areas, t);
  const allowedGeoBundle: GeoBundle = {
    id: 'areas',
    source: {
      type: 'geojson',
      data: allowedFeatureCollection,
    },
    options: {
      ...(selectableTypes?.includes('area')
        ? {
            pointer: true,
            selectable: true,
          }
        : {}),
      ...(contextMenuDataTypes?.includes('area')
        ? {
            pointer: true,
            contextMenuDataType: 'area',
            contextMenuEvents: ['click'],
          }
        : {}),
    },
    layers: createLayers({
      color: theme.primary,
      opacity: [
        'interpolate',
        ['linear'],
        ['zoom'],
        12,
        ['case', ['get', '_dark'], 0.15, 0.2],
        14,
        ['case', ['get', '_dark'], 0.08, 0.1],
      ],
      outlined,
    }),
  };
  const unallowedGeoBundle: GeoBundle = {
    id: 'unallowed-areas',
    source: {
      type: 'geojson',
      data: unallowedFeatureCollection,
    },
    options: {
      ...(selectableTypes?.includes('unallowed-area')
        ? {
            pointer: true,
            selectable: true,
          }
        : {}),
      ...(contextMenuDataTypes?.includes('unallowed-area')
        ? {
            pointer: true,
            contextMenuDataType: 'unallowed-area',
            contextMenuEvents: ['click'],
          }
        : {}),
    },
    layers: createLayers({
      color: theme.error,
      opacity: [
        'interpolate',
        ['linear'],
        ['zoom'],
        12,
        ['case', ['get', '_dark'], 0.2, 0.25],
        14,
        ['case', ['get', '_dark'], 0.15, 0.2],
      ],
      outlined,
    }),
  };
  const policiesGeoBundle: GeoBundle = {
    id: 'area-policies',
    source: {
      type: 'geojson',
      data: policiesFeatureCollection,
    },
    options: {
      ...(selectableTypes?.includes('area-policy')
        ? {
            pointer: true,
            selectable: true,
          }
        : {}),
      ...(contextMenuDataTypes?.includes('area-policy')
        ? {
            pointer: true,
            contextMenuDataType: 'area-policy',
            contextMenuEvents: ['click'],
          }
        : {}),
    },
    layers: createLayers({
      color: ['get', 'color'],
      opacity: [
        'interpolate',
        ['linear'],
        ['zoom'],
        12,
        ['case', ['get', '_dark'], 0.2, 0.25],
        14,
        ['case', ['get', '_dark'], 0.15, 0.2],
      ],
      outlined,
    }),
  };

  return [allowedGeoBundle, unallowedGeoBundle, policiesGeoBundle];
}

export function createAreasInterventionZonesGeoBundle({
  interventionZones,
  showInterventionZones,
  selectable = false,
  withContextMenu = false,
  beforeGeoBundleId,
}: {
  interventionZones: InterventionZone &
    {
      areaId: string;
    }[];
  showInterventionZones?: boolean;
  selectable?: boolean;
  withContextMenu?: boolean;
  beforeGeoBundleId?: string;
}): GeoBundle {
  const interventionZoneVisibility = showInterventionZones ? 'visible' : 'none';
  const interventionZonesFeatureCollection =
    createInterventionZoneFeatureCollection(interventionZones);

  return {
    id: 'intervention-zones',
    source: {
      type: 'geojson',
      data: interventionZonesFeatureCollection,
    },
    options: {
      ...(selectable
        ? {
            pointer: true,
            selectable: true,
          }
        : {}),
      ...(withContextMenu
        ? {
            pointer: true,
            contextMenuDataType: 'intervention-zone',
            contextMenuEvents: ['click'],
          }
        : {}),
      beforeGeoBundleId,
    },
    layers: [
      {
        type: 'line',
        layout: {
          visibility: interventionZoneVisibility,
        },
        paint: {
          'line-width': 2,
          'line-color': interventionZoneColor,
        },
      },
      {
        type: 'fill',
        maxzoom: 14,
        layout: {
          visibility: interventionZoneVisibility,
        },
        paint: {
          'fill-opacity': 0.05,
          'fill-color': theme.success,
        },
      },
      {
        type: 'symbol',
        layout: {
          visibility: interventionZoneVisibility,
          'symbol-placement': 'line',
          'text-ignore-placement': true,
          'text-field': ['get', 'text'],
          'text-size': 10,
          'text-offset': [0, 2],
        },
        paint: {
          'text-color': interventionZoneColor,
          'text-halo-color': interventionZoneColor,
          'text-halo-width': 0.25,
          'text-halo-blur': 0,
        },
      },
      {
        type: 'fill',
        maxzoom: 14,
        layout: {
          visibility: interventionZoneVisibility,
        },
        paint: {
          'fill-opacity': 0.05,
          'fill-color': theme.success,
        },
      },
      {
        type: 'fill',
        maxzoom: 14,
        filter: ['==', ['get', '_selected'], true],
        layout: {
          visibility: interventionZoneVisibility,
        },
        paint: {
          'fill-opacity': 0.25,
          'fill-color': theme.success,
        },
      },
    ],
  };
}
