import store from '@/store';

import type { storeDefinition } from '@/store';
import type localAppState from '@/store/modules/local-app-state';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type UseStoreState = WritableComputedRef<any>;

/**
 * Binds a writable computed to a store state which is associated to getter and a mutation
 * @param stateName - Name of the state
 * @returns
 */
export function useStoreState(
  stateName:
    | keyof typeof storeDefinition.getters
    | keyof typeof localAppState.getters
): UseStoreState {
  return computed({
    get() {
      return store.getters[stateName];
    },
    set(value) {
      store.commit(stateName, value);
    },
  });
}
