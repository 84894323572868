<template lang="pug">
.ZInputDelay
  ZInputUint.ZInputDelay__count(
    ref="inputRef"
    v-model="count"
    :label="label"
    :hint="hint"
    :messages="messages"
    :disabled="disabled"
    :rules="computedRules"
    :color="color"
  )
  VSelect(
    ref="selectRef"
    v-model="period"
    :items="periods"
    :disabled="disabled || periods.length === 1"
    dense
  )
  ZToggleableHint(v-if="periods.length === 1")
    span(
      v-html="t('app__only_period_available', { period: getPeriodTranslation(period) })"
    )
</template>

<style lang="stylus">
.ZInputDelay
  display flex
  align-items baseline
  gap 8px

  .ZToggleableHint
    left -8px
    margin-right -8px

  .v-select.v-text-field input
    width 0

.ZInputDelay__count
  width 100%
</style>

<script setup lang="ts">
import { useI18n } from '@/composables/plugins';
import { capitalize } from '@/lib/utils';
import { requiredRule } from '@/lib/helpers/rules';

import ZInputUint from '@/components/ui/molecules/ZInputUint.vue';

export type InputDelayPeriod = 'd' | 'w' | 'm' | 'y';
export type InputDelayValue = `${number}${InputDelayPeriod}`;

export interface ZInputDelayProps {
  /**
   * The final delay value
   * @model
   */
  value?: InputDelayValue;
  /**
   * The label of the input
   */
  label?: string;
  /**
   * The default period value
   */
  defaultPeriod?: InputDelayPeriod;
  /**
   * Disable the input
   */
  disabled?: boolean;
  /**
   * Make the input required
   */
  required?: boolean;
  /**
   * Pass additional rules to the input
   */
  rules?: VuetifyRule[];
  /**
   * Color of the input
   */
  color?: string;
  /**
   * The hint text, passed to the v-input-uint
   */
  hint?: string;
  /**
   * The messages, passed to the v-input-uint
   */
  messages?: string | string[];
  /**
   * Available periods
   */
  periodItems?: InputDelayPeriod[];
}

const props = withDefaults(defineProps<ZInputDelayProps>(), {
  value: undefined,
  label: undefined,
  defaultPeriod: 'd',
  disabled: false,
  required: false,
  rules: () => [],
  color: 'primary',
  hint: undefined,
  messages: () => [],
  periodItems: () => ['d', 'w', 'm', 'y'],
});

const emit = defineEmits<{
  (name: 'input', value: InputDelayValue | null): void;
}>();

const { t } = useI18n();

const periodsMap = {
  d: 'days',
  w: 'weeks',
  m: 'months',
  y: 'years',
};

const inputRef = ref<TemplateRef | null>(null);
const selectRef = ref<TemplateRef | null>(null);

const count = ref<number | null>(null);
const period = ref<InputDelayPeriod>('d');

const periods = computed(() => {
  return props.periodItems.map(period => ({
    value: period,
    text: getPeriodTranslation(period),
  }));
});

const computedRules = computed(() => {
  return [...(props.required ? [requiredRule] : []), ...props.rules];
});

watch([count, period], () => {
  emit('input', count.value ? `${count.value}${period.value}` : null);
});

watchImmediate([() => props.value, () => props.defaultPeriod], () => {
  count.value = props.value ? Number(props.value.replace(/[^0-9]/g, '')) : null;
  period.value = props.value
    ? (props.value.replace(/[0-9]/g, '') as InputDelayPeriod)
    : props.defaultPeriod;
});

function getPeriodTranslation(period: InputDelayPeriod): string {
  return capitalize(t(`app__${periodsMap[period]}`));
}

function focus(): void {
  inputRef.value?.focus?.();
}

function resetValidation(): void {
  inputRef.value?.resetValidation?.();
}

defineExpose({
  /**
   * Focus the input
   */
  focus,
  /**
   * Reset input validation
   */
  resetValidation,
});
</script>
