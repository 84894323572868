import StoreModule from '@/store/core/store-module';
import { fleetProductPopulateConfig } from '@/lib/helpers/populate';

import type { ApiSchema } from '#/core-api';
import type { FleetProductPopulatedType } from '@/lib/helpers/populate';
import type { Get } from '@/store/types/core-api';

declare global {
  /**
   * Populated Omni
   */
  type Omni = DeepMerge<
    ApiSchema['gateway_omni.Omni'],
    { id: string } & FleetProductPopulatedType<
      ApiSchema['bike.Bike']['serial_number']
    >
  >;
}

export interface OmniStoreTypes {
  OMNI: [Get<'/gateway_omni/omnis/{Id}', Omni>];
}

const omnisModule = new StoreModule<OmniStoreTypes>();
omnisModule.createResourceAndAction({
  type: 'OMNI',
  requestEndPoint: '/gateway_omni/omnis/:id',
  transform: data => data.omni,
  populate: [
    fleetProductPopulateConfig({
      dataField: 'id',
    }),
  ],
});

export default omnisModule;
