<template lang="pug">
.ZBadge(:class="classes")
  VBadge(
    :color="loading || error ? '' : bgColor || 'transparent'"
    :value="hasBadge"
  )
    template(#badge)
      VProgressCircular(
        v-if="loading"
        indeterminate
        :color="isDark ? 'grey lighten-3' : 'grey darken-3'"
        :size="16"
        :width="2"
      )
      .ZBadge__content(:class="contentClasses")
        ZIcon(
          v-if="icon || error"
          :color="error ? 'error' : iconColor"
          small
        ) {{ error ? 'mdi-close' : icon }}
        span(
          v-if="!['', null, undefined, 0].includes(text)"
          :class="textClasses"
        ) {{ text }}
    slot
</template>

<style lang="stylus">
.ZBadge
  display inline

  .v-badge__badge
    top -8px
    right auto
    left calc(100% - 3px)
    display block
    padding 0 6px
    width auto
    height 18px
    border-radius 9px
    font-weight 500
    font-size 12px
    line-height 1.5
    display flex

    .v-progress-circular
      position absolute
      top 0
      left 0

    .ZBadge__content
      display flex
      justify-content center
      white-space pre
      align-items center

      &--darkText
        color: $colors.grey.darken-4

      &--iconOnly
        .v-icon,
        .ZSvgIcon
          margin-left -2px

  &--dense
    .v-badge__badge
      padding 0 2px

  &--relative
    width 100%

    .v-badge
      display flex
      justify-content space-between
      align-items center

    .v-badge__badge
      position relative
      top auto
      left auto
      right auto
      margin-left 4px

      .v-progress-circular
        position relative
</style>

<script setup lang="ts">
import { useDarkTheme } from '@/composables';

export interface ZBadgeProps {
  /**
   * Text to display in the badge
   */
  text?: string | number;
  /**
   * Icon to display in the badge
   */
  icon?: string;
  /**
   * Color to use for the badge
   */
  color?: Color;
  /**
   * Alpha value to use for the badge
   */
  alpha?: number;
  /**
   * Color to use for the icon
   */
  iconColor?: string;
  /**
   * Whether the badge is loading
   */
  loading?: boolean;
  /**
   * Whether the badge is in error state
   */
  error?: boolean;
  /**
   * Whether the badge is relative
   */
  relative?: boolean;
  /**
   * Whether the badge has dark text
   */
  darkText?: boolean;
  /**
   * Whether the badge is dense
   */
  dense?: boolean;
}

const props = defineProps<ZBadgeProps>();

const { isDark } = useDarkTheme();

const hasBadge = computed(
  () => !!props.text || !!props.icon || props.loading || props.error
);

const bgColor = computed(() =>
  props.color === 'auto'
    ? isDark.value
      ? 'grey darken-1'
      : '#d5d5d5'
    : props.color
);

const classes = computed(() => {
  return { 'ZBadge--relative': props.relative, 'ZBadge--dense': props.dense };
});

const contentClasses = computed(() => {
  return {
    'ZBadge__content--iconOnly':
      props.icon &&
      !props.text &&
      (!props.color || props.color === 'transparent'),
    'ZBadge__content--darkText': props.darkText,
  };
});

const textClasses = computed<string[]>(() => {
  const classes = [];
  if (!isDark.value && (props.loading || props.color === 'auto')) {
    classes.push('grey--text text--darken-4');
  }
  if (isDark.value && props.color === 'auto') {
    classes.push('grey--text text--lighten-3');
  }
  return classes;
});
</script>
