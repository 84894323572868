<template lang="pug">
.ZToggleableHintContent(
  v-if="defaultSlots.length > 0"
  ref="content"
  :class="classes"
  :style="style"
)
  ZVnodeRenderer(
    v-for="(slot, index) in defaultSlots"
    :key="index"
    :vnode="slot"
  )
</template>

<style lang="stylus">
.ZToggleableHintContent
  position fixed
  z-index 999
  background-color white
  box-shadow rgba(0, 0, 0, 0.2) -4px 4px 12px -6px
  border 1px solid $colors.grey.lighten-3
  border-radius 12px
  padding 6px 10px
  margin-top 5px
  opacity 0
  width fit-content
  pointer-events none
  font-size 12px

  &--visible
    opacity 1
    pointer-events all

  &--error
    background-color alpha($color-error, 0.95)
    color white

.theme--dark
  .ZToggleableHintContent
    background-color: $colors.grey.darken-3
    box-shadow rgba(0, 0, 0, 0.2) -4px 4px 12px -6px
    border 1px solid $colors.grey.darken-1
</style>

<script setup lang="ts">
import store from '@/store';

import ZVnodeRenderer from '@/components/ui/atoms/ZVnodeRenderer.vue';

const left = computed(() => store.getters.toggleableHintContent.left);
const right = computed(() => store.getters.toggleableHintContent.right);
const top = computed(() => store.getters.toggleableHintContent.top);
const bottom = computed(() => store.getters.toggleableHintContent.bottom);

const defaultSlots = computed(
  () => store.getters.toggleableHintContent.defaultSlots ?? []
);

const error = computed(() => store.getters.toggleableHintContent.error);
const isVisible = computed(() => store.getters.toggleableHintContent.isVisible);

const classes = computed(() => ({
  'ZToggleableHintContent--visible': isVisible.value,
  'ZToggleableHintContent--error': error.value,
}));

const style = computed(() => {
  const { width, height } = useWindowSize();
  const rightMargin = width.value > 400 ? 300 : width.value / 2;
  const bottomMargin = height.value > 400 ? 300 : height.value / 2;

  const isRightAnchored = right.value < rightMargin;
  const isBottomAnchored = bottom.value < bottomMargin;

  return {
    ...(isRightAnchored
      ? { right: right.value + 'px' }
      : { left: left.value + 'px' }),
    ...(isBottomAnchored
      ? { bottom: bottom.value + 'px' }
      : { top: top.value + 'px' }),
  };
});
</script>
