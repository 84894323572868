import type { Ref, ShallowRef, WritableComputedRef } from 'vue';

/**
 * Shorthand for v-model binding. Use an internal value to ensure that a component can works regardless v-model is assigned or not.
 */
export function useVModelProxy<
  PropsT extends Record<string, unknown>,
  PropNameT extends keyof PropsT = 'value',
  T extends PropsT[PropNameT] = PropsT[PropNameT],
>(options: {
  /**
   * Component props
   */
  props: PropsT;
  /**
   * Component prop name to bind
   */
  propName?: PropNameT;
  /**
   * Use a default value to the proxied prop. Will set the internal ref to that value instead of the prop's initial value.s
   */
  defaultValue?: PropsT[PropNameT];
  /**
   * Event name to override the trigger update event, default to 'update:<propName>'
   */
  eventName?: string;
  /**
   * Use an other source instead of the default shallow ref to store the internal value
   */
  internalRef?:
    | Ref<PropsT[PropNameT]>
    | ShallowRef<PropsT[PropNameT]>
    | WritableComputedRef<PropsT[PropNameT]>;
  /**
   * Handler to process the value before it is set by the proxy
   */
  transform?: (value: T) => T;
  /**
   * Emit function from component declaration, or custom event emitter. If not given, it will be deduced from the setup context
   */
  emit?: (name: string, value: T) => void;
}): WritableComputedRef<T> {
  const {
    props,
    propName = 'value',
    defaultValue,
    eventName,
    internalRef,
    transform,
    emit,
  } = options;

  // Internal ref with initial value based on default or prop value
  const internal = internalRef ?? shallowRef(defaultValue ?? props[propName]);

  const vm = getCurrentInstance();

  // Due to Vue2 losing context of `this` into $emit
  // We need to bind it to the emit function
  const _emit = emit || vm?.proxy.$emit.bind(vm?.proxy);

  // Select the event type to emit
  const _eventName =
    eventName ||
    (propName === 'value' ? 'input' : `update:${String(propName)}`);
  // Properly trigger the update event (if a default was overriding prop)
  if (defaultValue !== undefined && defaultValue !== null) {
    _emit?.(_eventName, internal.value as T);
  }

  // Watch any change in prop, leading to an update of the internal value
  watch(
    () => props[propName] as T,
    (newVal: T) => (internal.value = newVal)
  );

  const value = computed<T>({
    get(): T {
      return internal.value as T;
    },
    set: (value: T) => {
      const transformedValue =
        typeof transform === 'function' ? transform(value) : value;
      internal.value = transformedValue;
      _emit?.(_eventName, transformedValue);
    },
  });

  return value;
}
