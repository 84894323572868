import isNullish from './is-nullish';

/**
 * This util is aimed to be used in a switch. It returns the default display properties for the `default` case.
 * By making sure that the method argument is of type `undefined`, we ensure that the switch handles all the enum values in cases.
 * Indeed, if a enum value is not handled in the switch, the TypeScript compiler will throw error TS2345:
 * > Argument of type '_Unhandled enum value(s)_ | undefined' is not assignable to parameter of type 'undefined'.
 * @param value - The enum default value to handle which should be `undefined`.
 * @param i18nKey - Optionally, an i18n key to use for the message, overriding the default one.
 */
export default function defaultEnumDisplayProperties(
  value: undefined,
  i18nKey = 'data__unknown_enum_value'
): DisplayProperties {
  const i18nArgs: I18nArgs = [i18nKey, { value }];
  // We must flag the array so that it is properly handled elsewhere.
  i18nArgs.__i18nArgs__ = true;
  return {
    message: isNullish(value) ? '' : i18nArgs,
  };
}
