<template lang="pug">
VDialog.ZDialog(
  v-model="isOpen"
  :width="width"
  content-class="ZDialog__dialog"
  :persistent="persistent"
)
  VToolbar.ZDialog__toolbar(
    v-if="slots['toolbar']"
    :height="56"
  )
    slot(name="toolbar")
    VSpacer(v-if="!hideCloseButton")
    VBtn(
      v-if="!hideCloseButton"
      icon
      @click="isOpen = false"
    )
      VIcon mdi-close
  .ZDialog__content
    slot
</template>
<style lang="stylus">
.ZDialog__dialog
  overflow hidden

.ZDialog__content
  // 56px for the toolbar and 2 * 24px for the margins = 104px
  max-height calc(100vh - 104px)
  overflow auto

scrollbar-theme('.ZDialog__content')
</style>

<script setup lang="ts">
import { useVModelProxy } from '@/composables';

export interface ZDialogProps {
  /**
   * Whether the dialog is visible.
   */
  value: boolean;
  /**
   * The width of the dialog.
   */
  width: string | number;
  /**
   * Whether the dialog should be persistent
   */
  persistent?: boolean;
  /**
   * Whether to hide the close button
   */
  hideCloseButton?: boolean;
}

const props = withDefaults(defineProps<ZDialogProps>(), {
  persistent: false,
  hideCloseButton: false,
});

defineEmits<{
  (name: 'input', value: boolean): void;
}>();

const slots = useSlots();

const isOpen = useVModelProxy({ props });
useEventListener('popstate', () => (isOpen.value = false), false);
</script>
