/**
 * Return a copy of an object with given keys removed
 * @param object - The input object
 * @param keys - The list of keys to remove
 * @return The reduced object
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function objectRemoveKeys<T extends Record<string, any>>(
  object: T = {} as T,
  keys: string[] = []
): Partial<T> {
  return Object.entries(object).reduce(
    (reducedObject: Partial<T>, [key, value]) => {
      if (!keys.includes(key)) {
        reducedObject[key as keyof T] = value;
      }
      return reducedObject;
    },
    {}
  );
}
