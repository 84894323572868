<template lang="pug">
.ZDataTable(ref="elementRef")
  VProgressLinear.ZDataTable__progressBar(
    :class="{ 'ZDataTable__progressBar--visible': loading }"
    :indeterminate="loading"
    height="2"
  )
  VCard.ZDataTable__container(:style="{ opacity: disabled ? 0.75 : 1 }")
    transition(name="z-fade")
      .ZDataTable__overlay(v-if="disabled")

    VMenu(
      v-if="slots['item-actions']"
      :value="listMenuIsOpen"
      :position-x="listMenuPositionX"
      :position-y="listMenuPositionY"
      :close-on-click="false"
      :close-on-content-click="false"
      transition="z-fade-in"
      menu-left
      dense
      offset-y
    )
      VCard.ZDataTable__listMenuEmptyText(v-if="listMenuIsEmpty")
        em {{ t('app__no_actions') }}
      VList.ZDataTable__listMenuList(
        ref="listMenuContentRef"
        dense
      )
        ZActionProvider(:action-settings="{ onEnd: handleActionEnd }")
          slot(
            v-if="clickedListMenuIndex !== null"
            name="item-actions"
            :index="clickedListMenuIndex"
          )

    VDataTable.ZDataTable__table(
      v-model="selection"
      :headers="computedHeaders"
      :items="keyedItems"
      :pagination.sync="pagination"
      :class=`{
        'ZDataTable__table--dense': dense,
        'ZDataTable__table--overflowX': tableOverflowAuto
      }`
      :select-all="selectAll"
      hide-actions
      item-key="__id"
    )
      template(#headerCell="{ header }")
        span.ZDataTable__header__cell(
          :class="{ 'data-table__header__cell--left': header.left }"
        )
          span {{ header.text }}
          ZPopulatedKeyIcon(v-if="header.populated")

      template(#items="scope")
        component.ZDataTable__tableRow(
          :is="itemLink(scope.item) ? 'router-link' : 'tr'"
          :to="wrapRoute(itemLink(scope.item))"
          :class="itemClasses(scope.item, clickedListMenuIndex === scope.index)"
        )
          td(
            v-if="selectAll"
            width="48px"
          )
            VCheckbox(
              v-model="scope.selected"
              color="primary"
              hide-details
              @click.native="onClickCheckbox(scope)"
            )
          td.ZDataTable__listMenuCell(v-if="slots['item-actions']")
            VBtn.ZDataTable__listMenuActivator(
              :class="{ 'v-btn--active': clickedListMenuIndex === scope.index }"
              flat
              small
              icon
              @click.prevent="onClickListMenu($event, scope.index)"
            )
              VIcon(small) mdi-dots-vertical
          template(v-for="(key, j) in getKeys(scope.item)")
            td(
              v-if="!scope.item[key].hidden"
              :id="scope.item[key].expand ? `expandable-cell-${key}` : undefined"
              :key="`cell-${j}`"
              nowrap
              v-bind="scope.item[key].tdProps"
            )
              .ZDataTable__table__cellContent(
                :class="{ 'ZDataTable__table__cellContent--expandable': scope.item[key].expand }"
                @click="scope.item[key].expand && expandCell($event, scope)"
              )
                ZDisplayField(
                  :item="scope.item[key]"
                  :no-wrap="!forceMultiline"
                  :force-multiline="forceMultiline"
                  :no-max-width="noMaxWidth"
                )
                template(v-if="scope.item[key].expand")
                  VSpacer
                  VIcon.ZDataTable__expandIcon(
                    small
                    :class="{ rotated: scope.expanded }"
                  ) mdi-chevron-down

      template(#expand="scope")
        VCard.ZDataTable__expandCard(
          v-if="getExpandEntry(scope.item)"
          :style="{ paddingLeft: `${getExpandOffsetX(scope.item)}px` }"
          @click="scope.expanded = false"
        )
          VCardText
            .ZDataTable__expandContent(
              v-for="(content, k) in getExpandEntry(scope.item)?.expand"
              :key="`expand-${k}`"
              :class="content.class"
            )
              .ZDataTable__expandContentPrefix(v-if="content.prefix") {{ content.prefix }}
              .ZDataTable__expandContentValue(
                :class="{ 'ZDataTable__expandContentValue--alone': !content.prefix }"
              ) {{ content.val }}

      template(#no-data)
        .ZDataTable__noData(v-if="loading")
          span {{ t('app__status_requesting_data') }}
        .ZDataTable__noData.ZDataTable__noData--error(v-else-if="error")
          span {{ t('app__status_failed_fetch') }}
          template(v-if="withErrorActions")
            privilege(permission="admin.app.notifications")
              VBtn.ZDataTable__noData__viewError(
                small
                dense
                flat
                @click="viewError"
              ) {{ t('app__view_error') }}
                ZIcon(
                  v-if="!xs"
                  small
                ) svg:bell_open
            VBtn(
              small
              dense
              flat
              @click="emit('retry')"
            ) {{ t('app__retry') }}
              ZIcon(
                v-if="!xs"
                small
              ) mdi-refresh
        .ZDataTable__noData(v-else)
          span {{ t('app__no_data') }}
          ZToggleableHint(v-if="!noHint")
            span(
              v-if="areaDependent"
              v-html="t('app__check_selected_areas', { current: selectedAreasText })"
            )
            br(v-if="areaDependent && withFilterHint")
            span(
              v-if="withFilterHint"
              v-html="t('app__check_filters_and_search')"
            )
            br(v-if="(areaDependent || withFilterHint) && withPageHint")
            span(
              v-if="withPageHint"
              v-html="t('app__check_page_number')"
            )
</template>

<style lang="stylus">
.ZDataTable__container
  box-shadow none

  .v-table__overflow
    overflow visible

  thead
    position sticky
    top 0
    z-index 1

    .theme--dark &
      background: $colors.grey.darken-3
      box-shadow 0 1px 0 0 $colors.grey.darken-2

    .theme--light &
      background white
      box-shadow 0 1px 0 0 $colors.grey.lighten-3

  .v-table thead tr:first-child
    border none

  tbody
    .ZDataTable__tableRow
      display table-row
      vertical-align middle

      td
        position relative

    a.ZDataTable__tableRow
      text-decoration none
      color currentColor
      // same as vuetify td
      transition all 0.3s cubic-bezier(0.25, 0.8, 0.5, 1)

      &:not(:first-child)
        border-top $data-table-border--light

      &:hover
        background-color rgba(0, 0, 0, 0.015)

      &--active,
      &--active:hover
        background-color rgba(0, 0, 0, 0.04)

  &.theme--dark
    tbody
      a.ZDataTable__tableRow
        &:not(:first-child)
          border-top $data-table-border--dark

        &:hover
          background-color rgba(255, 255, 255, 0.08)

        &--active,
        &--active:hover
          background-color rgba(255, 255, 255, 0.12)

.ZDataTable__table
  use-macro-table()

.ZDataTable__overlay
  absolute-full()
  z-index 1
  pointer-events none
  background-color rgba(0, 0, 0, 0.05)

  .theme--dark &
    background-color rgba(0, 0, 0, 0.08)

.v-card.ZDataTable__expandCard
  cursor n-resize

  .v-card__text
    width fit-content
    position relative
    padding-top 4px
    padding-bottom 8px
    display flex
    flex-direction column
    gap 6px

.ZDataTable__expandContent
  display flex
  overflow hidden
  border-radius 32px
  width fit-content

.ZDataTable__expandContentPrefix
  padding 3px 8px 3px 12px

  .theme--light &
    color rgba(0, 0, 0, 0.87)
    background-color rgba(0, 0, 0, 0.12)

  .theme--dark &
    color rgba(255, 255, 255, 0.87)
    background-color rgba(125, 125, 125, 0.16)

.ZDataTable__expandContentValue
  padding 3px 12px 3px 8px

  &--alone
    padding-left 12px

  .theme--light &
    background-color rgba(0, 0, 0, 0.06) !important

  .theme--dark &
    background-color rgba(255, 255, 255, 0.1) !important

.ZDataTable__expandIcon
  margin-left 4px
  margin-right 8px

.ZDataTable__noData
  line-height 1.125
  display flex
  align-items center
  justify-content center
  position absolute
  left 0
  width 100%
  transform translate(0, -50%)

  .v-btn:not(.v-btn--icon)
    min-width 72px

  &--error
    color $color-error

  .ZDataTable__noData__viewError
    margin-right 0

.ZDataTable__header__cell--left
  margin-left -12px

.ZDataTable__container
  .theme--dark.v-table tbody tr:hover
    background rgba(255, 255, 255, 0.08)

  .theme--light.v-table tbody tr:hover
    background rgba(0, 0, 0, 0.04)

.ZDataTable__progressBar
  position absolute
  margin 0
  overflow hidden
  opacity 0
  z-index 2

  &--visible
    opacity 1

.ZDataTable__listMenuEmptyText
  padding 8px 16px

  em
    font-size 13px
    opacity 0.87

.ZDataTable__listMenuCell
  padding 0 !important

.ZDataTable__listMenuActivator
  margin 0 0 0 4px
</style>

<script setup lang="ts">
import { useI18n } from '@/composables/plugins';
import {
  useVModelProxy,
  useVuetifyBreakpoints,
  useWrapRoute,
} from '@/composables';
import { objectPickKeys, toggleScrollListener } from '@/lib/utils';
import store from '@/store';

import ZActionProvider from '@/components/ui/atoms/ZActionProvider.vue';

import type { ComponentPublicInstance } from 'vue';

export type DataTableItem = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

interface ExpandScope {
  item: DataTableItem;
  expanded: boolean;
}

interface ItemsScope {
  index: number;
  selected: boolean;
}

export interface ZDataTableProps {
  /**
   * Items to display in the table.
   */
  items?: VDataTableRow[];
  /**
   * The headers of the table, in Vuetify format
   */
  headers?: VuetifyTableHeader[];
  /**
   * The page size.
   */
  pageSize?: number;
  /**
   * Whether data is loading.
   */
  loading?: boolean;
  /**
   * The property by which to sort the items.
   * @model
   */
  sortBy?: string;
  /**
   * Whether to sort in descending order.
   * @model
   */
  sortDescending?: boolean;
  /**
   * The base route for items, used for links.
   */
  itemBaseRoute?: string;
  /**
   * The property which defines uniquely the item and allows to generate links.
   */
  itemIdField?: string;
  /**
   * Whether no maximum width is applied to the table.
   */
  noMaxWidth?: boolean;
  /**
   * Puts the table in error state.
   */
  error?: boolean;
  /**
   * Whether hint is hidden.
   */
  noHint?: boolean;
  /**
   * Whether to show error actions.
   */
  withErrorActions?: boolean;
  /**
   * Whether to show a filter hint.
   */
  withFilterHint?: boolean;
  /**
   * Whether to show a page hint.
   */
  withPageHint?: boolean;
  /**
   * Disables the table, preventing interactions.
   */
  disabled?: boolean;
  /**
   * Whether to use dense representation of the table.
   */
  dense?: boolean;
  /**
   * Force multiline mode for cells.
   */
  forceMultiline?: boolean;
  /**
   * Shows a checkbox to select all items.
   */
  selectAll?: boolean;
  /**
   * The selected items.
   * @model
   */
  value?: DataTableItem[];
  /**
   * Set the table overflow to auto.
   */
  tableOverflowAuto?: boolean;
}

const props = withDefaults(defineProps<ZDataTableProps>(), {
  items: () => [],
  headers: () => [],
  pageSize: 10,
  sortBy: '',
  sortDescending: true,
  itemBaseRoute: undefined,
  itemIdField: undefined,
  value: () => [],
});

const emit = defineEmits<{
  (name: 'input', value: DataTableItem[]): void;
  (name: 'update:sortBy', value: string): void;
  (name: 'update:sortDescending', value: boolean): void;
  (name: 'retry'): void;
}>();

const router = useRouter();
const slots = useSlots();
const { t } = useI18n();
const { wrapRoute } = useWrapRoute();
const { xs } = useVuetifyBreakpoints();

const deactivated = ref(false);
const shiftKeydown = ref(false);
const lastCheckboxClicked = ref<number | null>(null);

const selection = useVModelProxy({ props });
const sortBy = useVModelProxy({ props, propName: 'sortBy' });
const sortDescending = useVModelProxy({ props, propName: 'sortDescending' });

const elementRef = ref<HTMLElement | null>(null);

// here is how we handle the pagination.sync mess of v-data-table:
const pagination = computed({
  get() {
    return {
      page: 1, // we always return 1 as pagination is handled outside
      rowsPerPage: props.pageSize,
      sortBy: sortBy.value,
      descending: sortDescending.value,
      totalItems: 0,
    };
  },
  set(newPagination) {
    // We captures changes in sorting, via .sync modifier of pagination prop.
    // We set directly these proxies data so that we can watch on them and
    // avoid deep watcher (for performance reasons) and trigger specific
    // update events (captures by parent via .sync).
    sortBy.value = newPagination.sortBy;
    sortDescending.value = newPagination.descending;
  },
});

const route = useRoute();
const selectedAreasText = computed(() => {
  return route.query.area;
});

const computedHeaders = computed(() => [
  ...(slots['item-actions'] ? [{ text: '', value: '', sortable: false }] : []),
  ...props.headers,
]);

const keyedItems = computed(() => {
  return props.items.map((item, index) => {
    const currentItem: DisplayField | null = props.itemIdField
      ? item[props.itemIdField]
      : null;

    item.__id = currentItem ? currentItem.val : index;

    // Because Vuetify’s VDataTable doesn't handle it natively,
    // pick only the keys that are in the headers, avoiding potential shifts in the table columns
    return objectPickKeys(item, [
      ...props.headers.map(header => header.value ?? header.text),
      '__id',
      '__classes',
    ]);
  });
});

const areaDependent = computed(() => {
  return !router.currentRoute.meta || !router.currentRoute.meta.areaIndependent;
});

onMounted(() => {
  addEventListeners();
});

onActivated(() => {
  // if component has been deactivated, reactivate event listeners
  if (deactivated.value) {
    deactivated.value = false;
    addEventListeners();
  }
});

onDeactivated(() => {
  removeEventListeners();
  deactivated.value = true;
});

onUnmounted(() => {
  removeEventListeners();
});

function addEventListeners(): void {
  if (props.selectAll) {
    window.addEventListener('keydown', onKeydown, false);
    window.addEventListener('keyup', onKeyup, false);
  }
}
function removeEventListeners(): void {
  if (props.selectAll) {
    window.removeEventListener('keydown', onKeydown, false);
    window.removeEventListener('keyup', onKeyup, false);
  }
}

// Handlers for Shift-click on checkbox for group select / unselect
function onKeydown(event: KeyboardEvent): void {
  if (event.key === 'Shift') shiftKeydown.value = true;
}

function onKeyup(event: KeyboardEvent): void {
  if (event.key === 'Shift') shiftKeydown.value = false;
}

function onClickCheckbox(scope: ItemsScope): void {
  if (shiftKeydown.value && lastCheckboxClicked.value !== null) {
    const startIndex = Math.min(scope.index, lastCheckboxClicked.value);
    const endIndex = Math.max(scope.index, lastCheckboxClicked.value);
    // select all between last checkbox clicked and new one

    if (scope.selected) {
      const newSelection: DataTableItem[] = [];
      for (let i = startIndex; i <= endIndex; i++) {
        if (!selection.value.some(({ __id }) => __id === i)) {
          newSelection.push(props.items[i]);
        }
      }
      selection.value = selection.value.concat(newSelection);
    }
    // unselect all between last checkbox clicked and new one
    else {
      selection.value = selection.value.filter(
        ({ __id }) => __id < startIndex || __id > endIndex
      );
    }
  }
  lastCheckboxClicked.value = scope.index;
}

function itemLink(item: DataTableItem): string | undefined {
  return props.itemBaseRoute && item.__id
    ? props.itemBaseRoute + item.__id
    : undefined;
}
function itemClasses(item: DataTableItem, isActive: boolean): string[] {
  return [
    ...(item.__classes ?? []),
    { 'ZDataTable__tableRow--active': isActive },
  ];
}
function viewError(): void {
  store.commit('notifications/open', true);
}
function getKeys(item: DataTableItem): string[] {
  return Object.keys(item).filter(key => key !== '__classes' && key !== '__id');
}
function getExpandOffsetX(item: DataTableItem): number {
  const expandEntry = getExpandEntry(item);
  const element = document.getElementById(
    `expandable-cell-${expandEntry?.key}`
  );
  const tableOffsetX = elementRef.value?.getBoundingClientRect().left ?? 0;
  const cellOffsetX = element?.getBoundingClientRect().left ?? 0;
  return cellOffsetX - tableOffsetX + 4;
}
function getExpandEntry(item: DataTableItem): DataTableItem | undefined {
  return Object.values(item).find(({ expand }) => !!expand);
}
function expandCell(event: MouseEvent, scope: ExpandScope): void {
  event.preventDefault();
  event.stopPropagation();
  scope.expanded = !scope.expanded;
}

const clickedListMenuIndex = ref<number | null>(null);
const clickedActivatorElement = ref<HTMLElement | null>(null);
const listMenuPositionX = ref(0);
const listMenuPositionY = ref(0);
const listMenuIsOpen = computed(() => clickedListMenuIndex.value !== null);
const listMenuIsEmpty = ref(true);
const listMenuContentRef = ref<ComponentPublicInstance>();
const listMenuObserveMutations = ref(false);

function getActivatorElement(event: MouseEvent): HTMLButtonElement | null {
  return (event.target as HTMLElement)?.closest(
    '.ZDataTable__listMenuActivator'
  );
}

function onClickListMenu(event: MouseEvent, index: number): void {
  const activatorElement = getActivatorElement(event);
  if (!activatorElement) return;
  clickedActivatorElement.value = activatorElement;
  const activatorRect = activatorElement.getBoundingClientRect();
  clickedActivatorElement.value.focus();
  clickedListMenuIndex.value = index;
  listMenuPositionX.value = activatorRect.x + activatorRect.width / 2;
  listMenuPositionY.value = activatorRect.y + activatorRect.height / 2;
  document.addEventListener('click', closeListMenu, false);
  toggleScrollListener(true, onScroll);
  listMenuObserveMutations.value = true;
}

watch(listMenuContentRef, refValue => {
  if (!refValue) return;
  useMutationObserver(refValue, onListMenuMutation, {
    childList: true,
    subtree: true,
  });
});
function onListMenuMutation(): void {
  if (!listMenuObserveMutations.value) return;
  checkListMenuContent();
}
function checkListMenuContent(): void {
  if (!listMenuContentRef.value) return;
  if (listMenuContentRef.value.$el.querySelector('*') === null) {
    listMenuIsEmpty.value = true;
  } else {
    listMenuIsEmpty.value = false;
  }
}

function closeListMenu(event?: MouseEvent): void {
  if (event && getActivatorElement(event)) return;
  clickedActivatorElement.value?.blur();
  clickedListMenuIndex.value = null;
  document.removeEventListener('click', closeListMenu, false);
}
function onScroll(): void {
  closeListMenu();
}
function handleActionEnd(): void {
  closeListMenu();
}
</script>
