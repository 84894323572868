import { TargetType } from '#/core-api/enums';

import type { DeliveryPlaceType } from '#/core-api/enums';

/**
 * Get the vue-router route name based on the target
 * @param target - the issues’ target
 */
export function benefitTargetRouteName(
  target: TargetType
): 'User' | 'Role' | 'Vehicle' | 'Area' | 'Station' {
  switch (target) {
    default:
    case TargetType.User:
      return 'User';
    case TargetType.Role:
      return 'Role';
    case TargetType.Bike:
      return 'Vehicle';
    case TargetType.Area:
      return 'Area';
    case TargetType.Station:
      return 'Station';
  }
}

export type BenefitsPlacesActionData = {
  additional_address_information: string;
  delivery_information: string;
  firstname: string;
  lastname: string;
  place_type: DeliveryPlaceType;
};
