import { PaymentMethodType } from '#/core-api/enums';

import {
  creditCardBrand,
  creditCardType,
} from '@/models/shared/helpers/credit-card';
import { useI18n } from '@/composables/plugins';
import { isNullish, obfuscate } from '@/lib/utils';

import type { ApiSchema } from '#/core-api';

const { t } = useI18n();
/**
 * Format a payment method for display.
 * @param paymentMethod - The payment method object
 * @param index - The payment method index
 * @param defaultPaymentMethodId - The default payment method id
 * @returns A formatted payment method that can be used in ZDisplayField component
 */
export function formatPaymentMethod(
  paymentMethod: ApiSchema['payment.PaymentMethod'],
  index?: number | null,
  defaultPaymentMethodId?: ApiSchema['payment.PaymentMethod']['id'],
  canReadGdprFields?: boolean | null
): DisplayField {
  const creditCardBrandProperties = creditCardBrand(paymentMethod.brand);
  const creditCardTypeProperties = creditCardType(paymentMethod.type);
  const cardBrandIcon = creditCardBrandProperties.icon; // The icon of the card brand (e.g. visa, mastercard...)
  const cardTypeIcon = creditCardTypeProperties.icon; // The icon of the card "type" (e.g. paypal, google pay...)
  const icon = cardTypeIcon ?? cardBrandIcon;

  const currentMonth = new Date().getUTCMonth() + 1;
  const currentYear = new Date().getUTCFullYear();
  const expirationYear = paymentMethod.expiration_year ?? 0;
  const expirationMonth = paymentMethod.expiration_month ?? 0;
  const isExpired =
    currentYear > expirationYear ||
    (currentYear === expirationYear && currentMonth >= expirationMonth);
  const expText = expirationMonth + ' / ' + expirationYear;
  const expTextColor = isExpired ? 'error--text' : 'success--text';
  const isDefault = defaultPaymentMethodId === paymentMethod.id;
  const defaultText = t('users__default_payment_method');
  const key = isNullish(index) ? '' : String(index + 1);

  const isPaypal = paymentMethod.type === PaymentMethodType.Paypal;
  const paypalEmail = canReadGdprFields
    ? paymentMethod.paypal_info?.email
    : obfuscate(paymentMethod.paypal_info?.email);

  const creditCardBrandName = creditCardBrandProperties.message;

  const creditCardTypeName =
    creditCardTypeProperties.message &&
    creditCardTypeProperties.message !== 'Card'
      ? `(${creditCardTypeProperties.message})`
      : '';

  return {
    key,
    val: isPaypal
      ? `${paypalEmail} (PayPal)`
      : `${creditCardBrandName} ${creditCardTypeName} •••• •••• •••• ${paymentMethod.last_4} `,
    metaInfo: `${
      !isPaypal ? `<b class="${expTextColor}">${expText}</b>` : ''
    } ${isDefault ? `&nbsp;– ${defaultText} ✓` : ''}`,

    icon,
    borderColor: isDefault ? 'primary' : '',
    iconSize: 'large',
    color: icon ? 'self-color' : '',
  };
}
