export default {
  resolve: (duration: number) => {
    return function delayFn<T>(response: T): Promise<T> {
      return new Promise(resolve => {
        setTimeout(() => resolve(response), duration);
      });
    };
  },
  reject: (duration: number) => {
    return function delayFn<T>(error: T): Promise<never> {
      return new Promise((_, reject) => {
        setTimeout(() => reject(error), duration);
      });
    };
  },
};
