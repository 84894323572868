/**
 * Obfuscate any value into a string with dashes
 * @param value - The value to obfuscate
 */
export default function obfuscate(value: unknown): string {
  if (typeof value === 'string') {
    return value.replace(/./g, '–');
  } else {
    return '–––––––';
  }
}
