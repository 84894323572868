import StoreModule from '@/store/core/store-module';
import { stationFields } from '@/models/fields';
import {
  areasPopulateConfig,
  vehiclesPopulateConfig,
  openIssuesPopulateConfig,
  stationNbReservedVehiclesPopulateConfig,
} from '@/lib/helpers/populate';

import type { ApiSchema } from '#/core-api';
import type {
  PopulatedField,
  PopulatedFieldArray,
} from '@/store/core/populate';
import type { Get, Post, Patch, Delete } from '@/store/types/core-api';

const nearbyFreeFloatingVehicleFields = [
  'id',
  'maintenance_state',
  'is_rented',
  'is_reserved',
  'vehicle_state.battery_info',
] satisfies FlattenedKey<ApiSchema['entity.Vehicle']>[];

declare global {
  /**
   * Populated Station
   */
  type Station = DeepMerge<
    ApiSchema['station.Station'],
    {
      id: string;
      open_issues?: PopulatedFieldArray<
        ApiSchema['station.Station']['id'],
        Pick<ApiSchema['issue.Issue'], 'id' | 'label' | 'severity'>
      >;
      area?: PopulatedField<
        ApiSchema['station.Station']['area_id'],
        ApiSchema['area.ServiceableArea']['label']
      >;
      /** @deprecated use nearby_freefloating_vehicle_ids instead */
      nearby_freefloating_products_sn?: (
        | number
        | PopulatedField<
            number,
            Pick<
              ApiSchema['bike.Bike'],
              | 'serial_number'
              | 'maintenance_state'
              | 'out_of_order'
              | 'status'
              | 'battery_community'
            > & {
              downplay?: boolean;
            }
          >
      )[];
      nearby_freefloating_vehicle_ids?: (
        | number
        | PopulatedField<
            number,
            Pick<
              ApiSchema['entity.Vehicle'],
              | 'id'
              | 'maintenance_state'
              | 'is_rented'
              | 'is_reserved'
              | 'vehicle_state'
            > & {
              downplay?: boolean;
            }
          >
      )[];
      /** @deprecated use `nb_reserved_vehicles` instead */
      nb_booked_bikes?: number;
      nb_reserved_vehicles?: number;
    }
  >;
}

const stationsScopes = ['Stations', 'Map', 'Other'] as const;

export interface StationStoreTypes {
  STATIONS: [
    Get<'/stations', Station[], typeof stationsScopes>,
    Post<'/stations'>,
  ];
  STATIONS_COUNT: [Get<'/stations/count'>];
  STATION: [
    Get<'/stations/{Id}', Station>,
    Patch<'/stations/{Id}'>,
    Delete<'/stations/{Id}'>,
  ];
  STATION_REQUIRED_ROLE: [Post<'/stations/{Id}/set_required_role'>];
  STATION_LABEL: [Post<'/stations/{Id}/set_label'>];
  STATION_COORDINATES: [Post<'/stations/{Id}/set_coordinates'>];
  STATION_AREA: [Post<'/stations/{Id}/set_area_id'>];
  STATION_URBAN_FURNITURE: [Patch<'/stations/urban_furnitures/{Id}'>];
  STATION_PULL_VEHICLE: [Post<'/stations/pull/{VehicleId}'>];
}

const stationsModule = new StoreModule<StationStoreTypes>();
stationsModule
  .createResourceAndAction({
    type: 'STATIONS',
    requestEndPoint: '/stations',
    scopes: stationsScopes,
    withGlobalUsageQuery: true,
    requestOptions: () => ({
      freeze: true,
      params: {
        fields: stationFields,
      },
    }),
    populate: [
      areasPopulateConfig(),
      openIssuesPopulateConfig({
        dataField: 'id',
      }),
    ],
    transform: data => data.stations,
  })
  .createResourceAndAction({
    type: 'STATIONS_COUNT',
    requestEndPoint: '/stations/count',
    scopes: stationsScopes,
    withGlobalUsageQuery: true,
  })
  .createResourceAndAction({
    type: 'STATION',
    requestEndPoint: '/stations/:id',
    transform: data => data.station,
    populate: [
      vehiclesPopulateConfig({
        dataField: 'nearby_freefloating_vehicle_ids',
        responseFields: [
          {
            key: nearbyFreeFloatingVehicleFields,
            as: 'nearby_freefloating_vehicle_ids',
          },
        ],
      }),
      areasPopulateConfig(),
      openIssuesPopulateConfig({
        dataField: 'id',
      }),
      stationNbReservedVehiclesPopulateConfig,
    ],
  })
  .createAction({
    type: 'STATION',
    requestEndPoint: '/stations/:id',
    requestMethod: 'PATCH',
    permission: 'gateway.station.edit',
    longRequest: true,
  })
  .createAction({
    type: 'STATIONS',
    requestEndPoint: '/stations',
    requestMethod: 'POST',
    permission: 'gateway.station.add',
  })
  .createAction({
    type: 'STATION',
    requestEndPoint: '/stations/:id',
    requestMethod: 'DELETE',
    permission: 'gateway.station.delete',
  })
  .createAction({
    type: 'STATION_REQUIRED_ROLE',
    requestEndPoint: '/stations/:id/set_required_role',
    requestMethod: 'POST',
    permission: 'gateway.station.set_required_role',
  })
  .createAction({
    type: 'STATION_LABEL',
    requestEndPoint: '/stations/:id/set_label',
    requestMethod: 'POST',
    permission: 'gateway.station.set_label',
  })
  .createAction({
    type: 'STATION_COORDINATES',
    requestEndPoint: '/stations/:id/set_coordinates',
    requestMethod: 'POST',
    permission: 'gateway.station.set_coordinates',
  })
  .createAction({
    type: 'STATION_AREA',
    requestEndPoint: '/stations/:id/set_area_id',
    requestMethod: 'POST',
    permission: 'gateway.station.set_area_id',
  })
  .createAction({
    type: 'STATION_URBAN_FURNITURE',
    requestMethod: 'PATCH',
    requestEndPoint: '/stations/urban_furnitures/:id',
  })
  .createAction({
    type: 'STATION_PULL_VEHICLE',
    requestMethod: 'POST',
    requestEndPoint: '/stations/pull/:vehicle_id',
  });

export default stationsModule;
