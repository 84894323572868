interface Version {
  version_major?: number;
  version_minor?: number;
  version_bugfix?: number;
  major?: number;
  minor?: number;
  bugfix?: number;
}

export default function versionString(version?: string | Version): string {
  if (typeof version === 'string') return version;
  if (typeof version === 'object') {
    const major = version.version_major || version.major || 0;
    const minor = version.version_minor || version.minor || 0;
    const bugfix = version.version_bugfix || version.bugfix || 0;
    return [major, minor, bugfix].join('.');
  }
  return '';
}
