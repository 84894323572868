import type mapboxgl from 'mapbox-gl';

type MapboxGl = typeof mapboxgl;

/**
 * Compute bounds, to be used by mapbox fitBounds, from a coordinates array
 * We pass mapboxgl object as a parameter as we want it to be lazyloaded
 * @param mapboxgl - mapboxgl
 * @param coordinates  - array of coordinates
 */
export default function computeBounds(
  mapboxgl: MapboxGl,
  coordinates: Coordinates[]
): mapboxgl.LngLatBounds {
  return coordinates.reduce(
    (bounds, coord) => bounds.extend(coord as [number, number]),
    new mapboxgl.LngLatBounds(
      coordinates[0] as [number, number],
      coordinates[0] as [number, number]
    )
  );
}
