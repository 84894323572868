// list of GDPR prone fields
export const gdprProneFields = [
  'email',
  'phone',
  'firstname',
  'lastname',
  'sex',
  'birthdate',
  'last_name',
  'avatar',
  'favorite_places',
  'address',
];
