import { removeDiacritics } from '@/lib/utils';

function sanitizeLabel(label?: string): string {
  if (!label) return '';
  return (
    // remove diacritics
    removeDiacritics(label)
      // set it lower case
      .toLowerCase()
      // replace all special characters by underscore
      .replace(/[^\w]/g, '_')
      // avoid group of more than one underscore
      .replace(/_{2,}/g, '_')
      // remove trailing underscore if any
      .replace(/_$/, '')
  );
}

const keyStore = new Map<string, Map<string, number>>();
/**
 * Generate a unique key based on a label. The label is first sanitized,
 * then if the key already exists, a counter is added to the key.
 * @param label - The label to generate the key from
 * @param mapKey - A key to identify the map where the generated keys are stored
 * @param reset - If true, the stored keys are reset
 */
export default function uniqueKeyFromLabel({
  label,
  mapKey,
  reset = false,
}: {
  label?: string;
  mapKey: string;
  reset?: boolean;
}): string {
  if (reset) keyStore.delete(mapKey);
  if (!keyStore.has(mapKey)) keyStore.set(mapKey, new Map());
  const storedKeys = keyStore.get(mapKey)!;

  const key = sanitizeLabel(label);
  const existingKeyCount = storedKeys.get(key);
  if (existingKeyCount) {
    storedKeys.set(key, existingKeyCount + 1);
    return `${key}_${existingKeyCount}`;
  }
  storedKeys.set(key, 1);
  return key;
}
