export const fieldPermissions: ReadPermission<User>[] = [
  { key: 'phone', permission: 'gateway.user.read.customer_fields' },
  { key: 'status', permission: 'gateway.user.read.customer_fields' },
  { key: 'firstname', permission: 'gateway.user.read.customer_fields' },
  { key: 'lastname', permission: 'gateway.user.read.customer_fields' },
  { key: 'birthdate', permission: 'gateway.user.read.customer_fields' },
  { key: 'sex', permission: 'gateway.user.read.customer_fields' },
  { key: 'favorite_places', permission: 'gateway.user.read.customer_fields' },
  { key: 'mileage', permission: 'gateway.user.read.customer_fields' },
  { key: 'carbon_footprint', permission: 'gateway.user.read.customer_fields' },
  { key: 'metadata', permission: 'gateway.user.read.customer_fields' },
  { key: 'settings', permission: 'gateway.user.read.customer_fields.all' },
  { key: 'customer_info', permission: 'gateway.user.read.customer_fields.all' },
  { key: 'discounts', permission: 'gateway.user.read.customer_fields' },
  { key: 'areas', permission: 'gateway.user.read.customer_fields' },
  { key: 'notes', permission: 'gateway.user.read.customer_fields' },
  { key: 'referral_code', permission: 'gateway.user.read.customer_fields' },
  { key: 'referred_by', permission: 'gateway.user.read.customer_fields' },
  { key: 'roles', permission: 'gateway.user.read.roles' },
  { key: 'firebase_instance_id', self: true },
  { key: 'firebase_registration_token', self: true },
  // added by data populating
  { key: 'ongoing_trip', permission: 'gateway.user.read.customer_fields' },
  { key: 'used_bike', permission: 'gateway.user.read.customer_fields' },
  { key: 'used_vehicle', permission: 'gateway.user.read.customer_fields' },
];
