import { createGeoBundleFeatureFromEncodedPolyline } from '@/lib/utils';
import { areaPolicyType } from '@/models/area/mappers/display';
import {
  areaPolicyText,
  getAreasInterventionZones,
  generateInterventionZoneId,
} from '@/models/area/helpers';
import theme from '@/themes';

import type { UseI18nReturn } from '@/composables/plugins';
import type { AreaFeatureCollections, MapArea } from '@/models/area/map/types';
import type { ThemeColor } from '@/themes';
import type { InterventionZone } from '@/models/area/helpers';

export function createFeatureCollections(
  areas: MapArea[] = [],
  t: UseI18nReturn['t']
): AreaFeatureCollections {
  const interventionZones = getAreasInterventionZones(areas);

  const interventionZonesFeatureCollection =
    createInterventionZoneFeatureCollection(interventionZones);

  return areas.reduce<AreaFeatureCollections>(
    (
      {
        allowedFeatureCollection,
        unallowedFeatureCollection,
        policiesFeatureCollection,
      },
      area
    ) => {
      if (area.encoded_coordinates) {
        allowedFeatureCollection.features.push(
          createGeoBundleFeatureFromEncodedPolyline(area.encoded_coordinates, {
            id: area.id,
            type: 'area',
          })
        );
      }
      area.geo_areas_unallowed?.forEach((unallowedEncodedPolyline, index) => {
        if (!unallowedEncodedPolyline) return;
        unallowedFeatureCollection.features.push(
          createGeoBundleFeatureFromEncodedPolyline(unallowedEncodedPolyline, {
            id: `area-${area.id}-unallowed-${index}`,
            type: 'unallowed-area',
          })
        );
      });
      area.policies?.forEach((policy, index) => {
        if (!policy?.encoded_coordinates) return;
        const policyTypeColor = areaPolicyType(policy.type).class;
        const policyTypeText = t(areaPolicyType(policy.type).message);
        const policyDataText = areaPolicyText(policy);
        policiesFeatureCollection.features.push(
          createGeoBundleFeatureFromEncodedPolyline(
            policy.encoded_coordinates,
            {
              id: `area-${area.id}-policy-${index}`,
              type: 'area-policy',
              text: `${policyTypeText}: ${policyDataText}`,
              color: theme[policyTypeColor as ThemeColor],
            }
          )
        );
      });

      return {
        allowedFeatureCollection,
        unallowedFeatureCollection,
        policiesFeatureCollection,
        interventionZonesFeatureCollection,
      };
    },
    {
      allowedFeatureCollection: {
        type: 'FeatureCollection',
        features: [],
      },
      unallowedFeatureCollection: {
        type: 'FeatureCollection',
        features: [],
      },
      policiesFeatureCollection: {
        type: 'FeatureCollection',
        features: [],
      },
      interventionZonesFeatureCollection,
    }
  );
}

export function createInterventionZoneFeatureCollection(
  interventionZones: (InterventionZone & {
    areaId: string;
  })[]
): GeoBundleFeatureCollection {
  return interventionZones.reduce<GeoBundleFeatureCollection>(
    (featureCollection, interventionZone, index) => {
      if (!interventionZone.encoded_polyline) return featureCollection;
      featureCollection.features.push(
        createGeoBundleFeatureFromEncodedPolyline(
          interventionZone.encoded_polyline,
          {
            id: generateInterventionZoneId(interventionZone.areaId, index),
            type: 'intervention-zone',
            text: interventionZone.label ?? `Intervention zone ${index + 1}`,
          }
        )
      );
      return featureCollection;
    },
    {
      type: 'FeatureCollection',
      features: [],
    }
  );
}
