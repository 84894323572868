import { BookingStatus } from '#/core-api/enums';

import { defaultEnumDisplayProperties } from '@/lib/utils';

export function bookingStatus(status?: BookingStatus): DisplayProperties {
  switch (status) {
    case BookingStatus.NotSet:
      return {
        message: 'bookings__undefined_status',
        class: 'secondary',
      };
    case BookingStatus.Pending:
      return {
        message: 'bookings__pending',
        class: 'disabled_alt1',
        icon: 'mdi-clock',
      };
    case BookingStatus.Honored:
      return {
        message: 'bookings__honored',
        class: 'success',
        icon: 'mdi-check-circle',
      };
    case BookingStatus.Canceled:
      return {
        message: 'bookings__canceled',
        class: 'error',
        icon: 'mdi-close-circle',
      };
    case BookingStatus.Expired:
      return {
        message: 'bookings__expired',
        class: 'error',
        icon: 'mdi-clock-alert',
      };
    case BookingStatus.Error:
      return {
        message: 'bookings__error',
        class: 'error',
        icon: 'mdi-alert-circle',
      };
    default:
      return defaultEnumDisplayProperties(status);
  }
}
