export const fields = [
  'serial_number',
  'last_phone_contact',
  'hardware',
  'bike_info',
  'last_thief',
  'location',
  'estimated_location',
  'last_phone_location',
  '_created',
  'battery_community',
  'battery_backup',
  'area_id',
  'lock_info',
  'maintenance_state',
  'mileage',
  'metadata',
  'data',
  'out_of_order',
  'product',
  'required_role',
  'software_version',
  'station_id',
  'fastener_id',
  'status',
  'scan_info',
  'lwm2m_info',
  'lost_info',
  'auto_diags',
  'accessibility',
  'deployed_in_env',
  'stack',
  'dock',
  'power_transfer',
  'power_saving',
  'product_config_crc',
  'bike_state_extended',
  'leased',
] satisfies ModelField<'bike.Bike'>[];
