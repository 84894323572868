import Vue from 'vue';
import VueI18n from 'vue-i18n';

import { appConfig } from '@/config';
import { getLocalPreferences } from '@/lib/helpers/get-local-preferences';

import type { AvailableLocale } from '@/config/app';
import type { I18nOptions, LocaleMessageObject, Values } from 'vue-i18n';

const lang = getLocalPreferences().lang;

Vue.use(VueI18n);

// We have to define our own class to add the availableLocales property
export class ControlVueI18n extends VueI18n {
  public availableLocales: AvailableLocale[];

  constructor(options: I18nOptions, availableLocales: AvailableLocale[]) {
    super(options);

    this.availableLocales = availableLocales;
  }
}

/**
 * I18n plugin
 */
export const i18n = new ControlVueI18n(
  {
    locale: lang,
    fallbackLocale: 'en',
  },
  [...appConfig.availableLocales]
);

// This is a new variable which holds the available languages,
// as i18n.availableLocales is read only and computed from i18n.messages
i18n.availableLocales = [...appConfig.availableLocales];

const loadedLanguages: string[] = [];

/**
 * Set the language of the application and update html tag attribute
 * @param lang - Locale string
 * @returns Locale string
 */
function setI18nLanguage(lang: string): string {
  i18n.locale = lang;
  document.querySelector('html')?.setAttribute('lang', lang);
  return lang;
}

/**
 * Load the language file for the given language
 * @param lang - Locale string
 * @param data - Locale messages data
 */
export function loadLanguage(lang: string, data: LocaleMessageObject): void {
  if (loadedLanguages.includes(lang)) {
    if (i18n.locale !== lang) setI18nLanguage(lang);
    return;
  }
  loadedLanguages.push(lang);
  i18n.setLocaleMessage(lang, data);
  setI18nLanguage(lang);
}

/**
 * Detect if a string is a translation key,
 * by checking if it has two successive underscores and no spaces
 * @param str - The given string
 * @returns True if the string is a translation key
 */
function isTranslationKey(str: string): boolean {
  return typeof str === 'string' && str.includes('__') && !str.includes(' ');
}

/**
 * Get final I18nArgs to pass to the improved version of t
 * @param keyOrI18nArgs - The translation key or I18nArgs
 * @param values - The optional translation values, if a translation key is used
 */
export function getI18nArgs(
  keyOrI18nArgs: string | I18nArgs,
  values?: Values
): [string, Values | undefined] {
  return Array.isArray(keyOrI18nArgs)
    ? [
        keyOrI18nArgs[0],
        typeof keyOrI18nArgs[1] === 'function'
          ? keyOrI18nArgs[1]()
          : keyOrI18nArgs[1],
      ]
    : [keyOrI18nArgs, values];
}

/**
 * Wrapper to create a more versatile 'Translate' (`t`) function that can accept {@link I18nArgs} directly
 * @param t - The i18n.t function
 */
export function createImprovedTranslate(t: ControlVueI18n['t']) {
  /**
   * Improved `t` function that can accept {@link I18nArgs} directly
   * @param keyOrI18nArgs - Translation key(string) or tuple {@link I18nArgs}
   * @param values - The translation values, used if first argument is not I18nArgs
   */
  return function improvedTranslate(
    this: ControlVueI18n,
    keyOrI18nArgs: string | I18nArgs,
    values?: Values
  ): string {
    const _t = t.bind(this);

    return _t(...getI18nArgs(keyOrI18nArgs, values)) as string;
  };
}

/**
 * Wrapper to create `translateIfNeeded` function with correct `this` binding
 * @param t - The i18n.t function
 */
export function createTranslateIfNeeded(t: ControlVueI18n['t']) {
  /**
   * Adds a smart check on translation keys :
   * - If the value is an array, we pass it as arguments to $t, resolving them if they are functions to enhance reactivity
   * - If the value is a translation key, we pass it normally to $t
   * - If the value is anything else, we return it as is
   * @param keyOrI18nArgs - Translation key(string) or tuple {@link I18nArgs}, or raw string not to be translated
   * @param values - The translation values, used if first argument is not I18nArgs
   * @returns The final translation text
   */
  return function translateIfNeeded(
    this: ControlVueI18n,
    keyOrI18nArgs: string | I18nArgs,
    values?: Values
  ): string {
    const _t = t.bind(this);

    const [_key, _values] = getI18nArgs(keyOrI18nArgs, values);

    if (isTranslationKey(_key)) {
      return _t(_key, _values) as string;
    } else {
      return _key;
    }
  };
}
