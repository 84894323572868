import { apiConfig } from '@/config';
import StoreModule from '@/store/core/store-module';
import { roleFields, roleBindingFields } from '@/models/fields';

import type { ApiSchema } from '#/core-api';
import type { PopulatedField } from '@/store/core/populate';
import type { Get, Post, Delete } from '@/store/types/core-api';

declare global {
  /**
   * Populated Role
   */
  type Role = DeepMerge<ApiSchema['user.Role'], { name: string }>;
  /**
   * Populated Privilege
   */
  type Privilege = DeepMerge<
    ApiSchema['auth.Privilege'],
    {
      area?: PopulatedField<
        ApiSchema['auth.Privilege']['selector_id'],
        ApiSchema['area.ServiceableArea']['label']
      >;
      scope?: PopulatedField<
        ApiSchema['auth.Privilege']['selector_id'],
        ApiSchema['auth.Scope']['name']
      >;
    }
  >;
  /**
   * Populated Role Binding
   */
  type RoleBinding = DeepMerge<
    ApiSchema['auth.RoleBinding'],
    {
      privileges?: Privilege[];
    }
  >;
}

const rolesScopes = ['App', 'Roles'] as const;

export interface RoleStoreTypes {
  ROLES: [Get<'/users/roles', Role[], typeof rolesScopes>, Post<'/users/role'>];
  ROLES_COUNT: [Get<'/users/roles/count', void, typeof rolesScopes>];
  ROLES_ALL: [Get<'/users/roles', Role[], typeof rolesScopes>];
  ROLE: [
    Get<'/users/role/{Name}', Role>,
    Post<'/users/role/{Name}'>,
    Delete<'/users/role'>,
  ];
  ROLE_PRIVILEGES: [Get<'/auth/role_bindings', Privilege[]>];
  ROLE_ADD_PRIVILEGES: [Post<'/auth/role_bindings/{Role}/add_privileges'>];
  ROLE_REMOVE_PRIVILEGES: [
    Post<'/auth/role_bindings/{Role}/remove_privileges'>,
  ];
}

const rolesModule = new StoreModule<RoleStoreTypes>();
rolesModule
  .createResourceAndAction({
    type: 'ROLES',
    requestEndPoint: '/users/roles',
    permission: 'gateway.user.roles.list',
    scopes: rolesScopes,
    requestOptions: () => ({
      freeze: true,
      params: {
        fields: roleFields,
      },
    }),
    transform: data => data.roles,
  })
  .createResourceAndAction({
    type: 'ROLES_COUNT',
    requestEndPoint: '/users/roles/count',
    scopes: rolesScopes,
  })
  .createResourceAndAction({
    type: 'ROLE',
    requestEndPoint: '/users/role/:name',
    permission: 'gateway.user.roles.query',
  })
  .aliasAction({
    fromType: 'ROLES',
    toType: 'ROLES_ALL',
    requestMethod: 'GET',
    requestOptions: () => ({
      preventPopulate: true,
      params: { limit: apiConfig.roleResponseLimit },
    }),
  })
  .createAction({
    type: 'ROLES',
    requestEndPoint: '/users/role',
    requestMethod: 'POST',
    permission: 'gateway.user.roles.add',
  })
  .createAction({
    type: 'ROLE',
    requestEndPoint: '/users/role',
    requestMethod: 'DELETE',
    permission: 'gateway.user.roles.delete',
  })
  .createAction({
    type: 'ROLE',
    requestEndPoint: '/users/role/:name',
    requestMethod: 'POST',
    permission: 'gateway.user.roles.update',
  })
  /*
   * role bindings
   */
  .createResourceAndAction({
    type: 'ROLE_PRIVILEGES',
    requestEndPoint: '/auth/role_bindings',
    permission: 'gateway.auth.role_binding.list',
    requestOptions: () => ({
      params: {
        fields: roleBindingFields,
      },
    }),
    transform: data => {
      return data?.role_bindings?.[0]?.privileges ?? [];
    },
    populate: [
      {
        dataField: 'selector_id',
        dataType: String,
        actionType: 'GET_AREAS',
        queryField: 'id',
        responseFields: [{ key: 'label', as: 'area' }],
        cacheTimeout: 24 * 60 * 60 * 1000, // areas are unlikely to change
        condition: privilege => privilege.selector_id !== '*',
      },
      {
        dataField: 'selector_id',
        dataType: String,
        actionType: 'GET_AUTH_SCOPES',
        queryField: 'id',
        responseFields: [{ key: 'name', as: 'scope' }],
        cacheTimeout: 24 * 60 * 60 * 1000, // scopes are unlikely to change
        condition: privilege => privilege.selector_id !== '*',
      },
    ],
  })
  .createAction({
    type: 'ROLE_ADD_PRIVILEGES',
    requestEndPoint: '/auth/role_bindings/:role/add_privileges',
    requestMethod: 'POST',
    permission: 'gateway.auth.role_binding.add_privileges',
  })
  .createAction({
    type: 'ROLE_REMOVE_PRIVILEGES',
    requestEndPoint: '/auth/role_bindings/:role/remove_privileges',
    requestMethod: 'POST',
    permission: 'gateway.auth.role_binding.remove_privileges',
  });

export default rolesModule;
