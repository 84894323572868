import { useI18n } from '@/composables/plugins';

interface FormatDurationOptions {
  abv?: boolean;
  unbreakable?: boolean;
  rounded?: boolean;
  roundedUp?: boolean;
  dense?: boolean;
}

export interface FormattedDuration {
  years: number;
  months: number;
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  text: string;
  future: boolean;
}

/**
 * Format a duration in seconds to a human readable string
 * @param valueInSeconds - Duration in seconds
 * @param options - Formatting options
 */
export default function formatDuration(
  valueInSeconds: number,
  options?: FormatDurationOptions
): FormattedDuration {
  const { t, te } = useI18n();

  function _t(unit: string): string {
    const key = `app__${unit}`;
    return te(key) ? t(key) : unit;
  }

  // setup settings
  const settings = options || {};
  const abv = settings.abv;
  const unbreakable = settings.unbreakable;
  const rounded = settings.rounded;
  const roundedUp = settings.roundedUp;
  const dense = settings.dense;

  // display
  const unitText = {
    years: abv ? 'y' : _t('years'),
    months: abv ? 'mo' : _t('months'),
    days: abv ? 'd' : _t('days'),
    hours: abv ? 'h' : _t('hours'),
    minutes: abv ? 'min' : _t('minutes'),
    seconds: abv ? 's' : _t('seconds'),
  };

  // computations
  const future = valueInSeconds < 0;
  let rest = Math.abs(valueInSeconds);
  const years = Math.floor(rest / (3600 * 24 * 365));
  rest = rest - years * 3600 * 24 * 365;
  const months = Math.floor(rest / (3600 * 24 * 30));
  rest = rest - months * 3600 * 24 * 30;
  const days = Math.floor(rest / (3600 * 24));
  rest = rest - days * 3600 * 24;
  const hours = Math.floor(rest / 3600);
  rest = rest - hours * 3600;
  const minutes = Math.floor(rest / 60);
  rest = rest - minutes * 60;
  const seconds = Math.ceil(rest);

  // format text
  let text;
  if (valueInSeconds === 0) {
    text = '0';
  } else {
    if (rounded) {
      // rounded text
      text = seconds + ' ' + unitText.seconds;
      if (minutes > 0) {
        text =
          minutes + (roundedUp && seconds ? 1 : 0) + ' ' + unitText.minutes;
      }
      if (hours > 0) {
        text =
          hours +
          (roundedUp && (minutes || seconds) ? 1 : 0) +
          ' ' +
          unitText.hours;
      }
      if (days > 0) {
        text =
          days +
          (roundedUp && (hours || minutes || seconds) ? 1 : 0) +
          ' ' +
          unitText.days;
      }
      if (months > 0) {
        text =
          months +
          (roundedUp && (days || hours || minutes || seconds) ? 1 : 0) +
          ' ' +
          unitText.months;
      }
      if (years > 0) {
        text =
          years +
          (roundedUp && (months || days || hours || minutes || seconds)
            ? 1
            : 0) +
          ' ' +
          unitText.years;
      }
    } else {
      // full text
      text =
        (years ? years + ' ' + unitText.years + ' ' : '') +
        (months ? months + ' ' + unitText.months + ' ' : '') +
        (days ? days + ' ' + unitText.days + ' ' : '') +
        (hours || (days && (minutes || seconds))
          ? hours + ' ' + unitText.hours + ' '
          : '') +
        (minutes || ((days || hours) && seconds)
          ? minutes + ' ' + unitText.minutes + ' '
          : '') +
        (seconds ? seconds + ' ' + unitText.seconds : '');
    }
  }

  // final formatting
  if (unbreakable && !dense) text = text.replace(' ', '&nbsp;');
  if (dense) text = text.replace(' ', '');

  return { years, months, days, hours, minutes, seconds, text, future };
}
