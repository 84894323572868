/* eslint-disable @typescript-eslint/no-explicit-any */
import { monextConfig } from '@/config';
import StoreModule from '@/store/core/store-module';

import type { Get, Post } from '@/store/types/core-api';

export interface PaymentsStoreTypes {
  SCOPE_PROVIDER_ACCOUNT: [Get<'/payments/provider_accounts/{ScopeID}'>];
  // TODO: better typing, but cannot find anything relevant in Monext doc :(
  MONEXT_GET_TOKEN: [
    {
      method: 'POST';
      payload: any;
      response: any;
      data: any;
    },
  ];
  PAYMENT_ACTION: [Post<'/payments/{ID}/action'>];
}

const paymentsModule = new StoreModule<PaymentsStoreTypes>();
paymentsModule
  // providers
  .createResourceAndAction({
    type: 'SCOPE_PROVIDER_ACCOUNT',
    requestEndPoint: '/payments/provider_accounts/:scope_id',
  })
  // monext
  .createAction({
    type: 'MONEXT_GET_TOKEN',
    requestApiConfig: monextConfig,
    requestEndPoint: '/webpayment/getToken',
    needsAuth: false,
    requestOptions: () => ({
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }),
    requestMethod: 'POST',
  })
  // action (for 3DS challenge, used for some payment providers, like Monext)
  .createAction({
    type: 'PAYMENT_ACTION',
    requestEndPoint: '/payments/:payment_id/action',
    requestMethod: 'POST',
  });

export default paymentsModule;
