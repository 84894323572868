<template lang="pug">
.ZActionDropZone(:class="{ 'ZActionDropZone--show': showDropZone }")
  h3 {{ t('app__drop_file') }}
</template>

<style lang="stylus">
.ZActionDropZone
  absolute-full()
  border 4px dashed $color-primary
  color $color-primary
  display flex
  align-items center
  justify-content center
  z-index -1
  opacity 0
  pointer-events none

  .theme--dark &
    background-color rgba(35, 35, 35, 0.9)

  .theme--light &
    background-color rgba(255, 255, 255, 0.9)

  &.ZActionDropZone--show
    opacity 1
    z-index 2
</style>

<script setup lang="ts">
import { useI18n } from '@/composables/plugins';
import { useAlert } from '@/composables';
import AppError from '@/lib/classes/app-error';
import { AppErrorCode } from '@/enums/errors';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Data = any;

export interface ZActionDropZoneProps {
  /**
   * The parent action 'data' object
   */
  data: Data;
}

const props = defineProps<ZActionDropZoneProps>();
const emit = defineEmits<{
  (name: 'drop', result: FileReader['result'], data: ActionData<Data>): void;
}>();

const { t } = useI18n();
const alert = useAlert();
const showDropZone = ref(false);

/**
 * Handle drag event on the control if a drop-zone is available, to show it and prevent default behavior
 * @param event - The drag event
 */
function handleDragover(event: DragEvent): boolean {
  event.preventDefault();
  showDropZone.value = true;
  return false;
}

/**
 * Handle dragenter event on the control, if a drop-zone is available, to show it
 */
function handleDragenter(): void {
  showDropZone.value = true;
}

/**
 * Handle dragleave event on the control if a drop-zone is available, to hide it
 */
function handleDragleave(): void {
  showDropZone.value = false;
}

/**
 * Handle drop event on the control if a drop-zone is available to retrieve the dropped yaml file
 * @param event - The drag event
 */
function handleDrop(event: DragEvent): boolean | void {
  const droppedFiles = event.dataTransfer?.files;
  if (!droppedFiles) return;
  if (
    droppedFiles[0].name.slice(-5) !== '.yaml' &&
    droppedFiles[0].name.slice(-4) !== '.yml'
  ) {
    alert.error = new AppError(AppErrorCode.WrongYamlFileExtension);
  } else if (droppedFiles[0].size > 10000) {
    alert.error = new AppError(AppErrorCode.FileTooLarge);
  } else {
    event.preventDefault();
    showDropZone.value = false;
    const files = Array.from(droppedFiles);
    if (!files.length) return;
    var reader = new FileReader();
    reader.onload = event => {
      emit('drop', (event.target as FileReader).result, props.data);
    };
    reader.readAsText(files[0]);
    return false;
  }
  showDropZone.value = false;
}

useEventListener('drop', handleDrop);
useEventListener('dragover', handleDragover);
useEventListener('dragenter', handleDragenter);
useEventListener('dragleave', handleDragleave);
</script>
