<template lang="pug">
.ZInitializationSteps(:class="classes")
  .ZInitializationSteps__logo
    ZAppLogo(:width="160")
  .ZInitializationSteps__content(v-if="steps.length")
    h3(v-html="headerText")
    VCard.ZInitializationSteps__card
      VCardText
        TransitionGroup(name="z-fade")
          .ZInitializationSteps__item(
            v-for="(step, i) in steps"
            :key="`step-${i}`"
          )
            span {{ step.label }}
            VDivider
            ZIcon(
              v-if="step.status"
              small
              :color="step.status === 'error' ? 'error' : 'success'"
            ) {{ step.status === 'error' ? 'mdi-close' : 'mdi-check' }}
            v-progress-circular(
              v-else
              indeterminate
              :size="14"
              :width="2"
            )
      template(v-if="error")
        VDivider
        VCardActions
          VBtn(
            flat
            :dark="isDark"
            @click="logout"
          )
            span {{ t('app__logout') }}
          VSpacer
          VBtn(
            :dark="isDark"
            @click="retry"
          )
            span {{ t('app__retry') }}
            VIcon(right) mdi-refresh
  .ZInitializationSteps__loader(v-else)
    p {{ t('app__loading') }}
  .splash-version {{ version }}
</template>

<style lang="stylus">
.ZInitializationSteps
  absolute-full()
  z-index 99
  opacity 1
  transition opacity 0.2s ease
  background $color-fifteen_control

  &--hidden
    opacity 0
    display none

.ZInitializationSteps__logo
  max-width 160px
  margin 75px auto 58px
  transform translate(0, -6px)

.ZInitializationSteps__content
  display flex
  flex-direction column
  justify-content space-between
  align-items center
  gap 16px
  color white

.ZInitializationSteps__loader
  display flex
  flex-direction column
  justify-content space-between
  align-items center
  gap 16px
  color white

.ZInitializationSteps__card
  box-shadow none
  border-radius 16px
  max-width calc(100% - 16px)
  width 400px

.ZInitializationSteps__item
  display flex
  justify-content space-between
  align-items center
</style>

<script setup lang="ts">
import { version } from '@/../package.json';
import { useDarkTheme } from '@/composables';
import { useI18n } from '@/composables/plugins';
import store from '@/store';

export type InitializationStep = {
  name: 'environment' | 'user' | 'areas' | 'privileges';
  label: string;
  status: 'error' | 'ok' | null;
};

interface ZInitializationStepsProps {
  /**
   * Whether the component is visible.
   */
  value?: boolean;
  /**
   * Array of steps.
   */
  steps?: InitializationStep[];
  /**
   * Whether an error occurred.
   */
  error?: boolean;
}

const props = withDefaults(defineProps<ZInitializationStepsProps>(), {
  value: false,
  steps: () => [],
  error: false,
});

const emit = defineEmits<{
  (name: 'retry'): void;
  (name: 'logout'): void;
}>();

const { t } = useI18n();
const { isDark } = useDarkTheme();

const classes = ref<string[]>([]);

const label = computed(() => store.getters['ENV']?.label);

const headerText = computed(() => {
  const labelText = label.value ? `- <b>${label.value}</b>` : '';
  return props.error
    ? `Initialization failed ${labelText}`
    : `Initialization ${labelText}`;
});

function retry(): void {
  emit('retry');
}

function logout(): void {
  emit('logout');
}

watchImmediate(
  () => props.value,
  newValue => {
    if (!newValue) {
      classes.value.push('ZInitializationSteps--hidden');
    } else {
      classes.value = classes.value.filter(
        className => className !== 'ZInitializationSteps--hidden'
      );
    }
  }
);
</script>
