<template lang="pug">
ZTooltip(
  bottom
  :disabled="isDisabled"
)
  VBtn.ZOpenButton(
    icon
    :small="small"
    :disabled="isDisabled"
    :to="wrapRoute(link)"
  )
    z-icon(:small="small || xs") {{ icon }}
  span {{ tooltip ?? t('app__open_link') }}
</template>

<style lang="stylus">
.ZOpenButton.v-btn
  padding 0
  margin 0

  +media-down-xs()
    height 24px
    width 24px
</style>

<script setup lang="ts">
import { useVuetifyBreakpoints, useWrapRoute } from '@/composables';
import { useI18n } from '@/composables/plugins';

import type { RawLocation } from 'vue-router';

export interface ZOpenButtonProps {
  /**
   * Icon to use for the open button
   */
  icon?: string;
  /**
   * Color to use for the open button
   */
  color?: string;
  /**
   * Whether the open button is small
   */
  small?: boolean;
  /**
   * Link to use for the open button
   */
  link?: RawLocation;
  /**
   * Tooltip text to use for the open button
   */
  tooltip?: string;
}

const props = withDefaults(defineProps<ZOpenButtonProps>(), {
  icon: 'mdi-open-in-app',
  color: '',
  small: false,
  link: '',
  tooltip: undefined,
});

const { wrapRoute } = useWrapRoute();
const { xs } = useVuetifyBreakpoints();
const { t } = useI18n();

const isDisabled = computed(() => !props.link);
</script>
