export function mapDisplayFieldsToVDataTableRow(
  line: DisplayField | DisplayField[]
): VDataTableRow {
  const _lines = Array.isArray(line) ? line : [line];

  const classesItem = _lines.find(entry => entry.key === '__classes');

  return (_lines ?? []).reduce<VDataTableRow>((acc, item) => {
    acc[item.key] = item;

    if (item.key === '__classes') {
      acc.__classes = item.val ?? [];
    }
    if (classesItem?.val) {
      acc.__classes = (acc.__classes ?? []).concat(classesItem.val ?? []);
    }

    return acc;
  }, {});
}
