<template lang="pug">
.ZAppInfo
  div(@click.stop="show = true")
    slot(name="activator")
  ZDialog.ZAppInfo__dialog(
    v-model="show"
    :width="440"
    hide-close-button
  )
    ZScrollable.ZAppInfo__menu(
      off
      auto
      prevent-touch-overflow-scrolling
    )
      VCard.ZAppInfo__card
        .ZAppInfo__header
          ZAppLogo(
            :variant="appLogoVariant"
            :width="104"
          )
        .ZAppInfo__content
          .ZAppInfo__version
            span.ZAppInfo__versionText {{ t('app__version') }}
            span.ZAppInfo__versionNumber {{ version }}

          .ZAppInfo__footer__legalLinks
            RouterLink.ZAppInfo__footer__legalLink(
              :to="appConfig.termsAndConditionsRoute"
            ) {{ t('app__terms_and_conditions') }}
            VDivider(vertical)
            RouterLink.ZAppInfo__footer__legalLink(
              :to="appConfig.privacyPolicyRoute"
            ) {{ t('app__privacy_policy') }}
            VDivider(vertical)
            button.ZAppInfo__footer__legalLink(
              link
              @click="openCookiesPreferences"
            ) {{ t('app__cookie_preferences') }}

        .ZAppInfo__card__footer
          .ZAppInfo__card__footerCopyright &copy; Fifteen {{ year }}
</template>

<style lang="stylus">
.ZAppInfo__menu
  top 64px

  +media-down-sm()
    top 56px

.ZAppInfo__header
  width 100%
  display flex
  justify-content center
  margin-top 16px

.ZAppInfo__card
  display flex
  justify-content center
  flex-direction column
  text-align center
  padding 12px
  gap 12px
  width 100%

.ZAppInfo__content
  display flex
  flex-direction column
  justify-content center
  gap 8px

.ZAppInfo__version
  margin-bottom 20px

.ZAppInfo__versionNumber
  font-weight bold

  &::before
    content ' '

.ZAppInfo__card__footer
  text-align right

.ZAppInfo__card__footerCopyright
  font-size 12px
  white-space nowrap

.ZAppInfo__footer__legalLinks
  display flex
  justify-content center
  gap 8px

  +media-down-xs()
    flex-direction column
    gap 4px

.ZAppInfo__footer__legalLink
  font-size 12px
  font-weight 400
  opacity 0.67
  transition opacity 0.1s
  color currentColor
  text-decoration underline

  &:hover
    opacity 1

.theme--dark
  .ZAppInfo__card__footerCopyright
    opacity 0.25

.theme--light
  .ZAppInfo__card__footerCopyright
    opacity 0.45
</style>

<script lang="ts" setup>
import { useI18n } from '@/composables/plugins';
import store from '@/store';
import { useDarkTheme } from '@/composables';
import { version as packageVersion } from '@/../package.json';
import { appConfig } from '@/config';

import ZDialog from '@/components/ui/molecules/ZDialog.vue';

const { t } = useI18n();
const { isDark } = useDarkTheme();

const show = computed({
  get: () => store.getters.appInfoOpen,
  set: value => store.commit('appInfoOpen', value),
});

const year = ref(new Date().getFullYear());
const version = ref(packageVersion);

const appLogoVariant = computed(() => (isDark.value ? 'light' : 'dark'));

function openCookiesPreferences(): void {
  window.openAxeptioCookies?.();
}
</script>
