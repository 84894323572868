import StoreModule from '@/store/core/store-module';
import { parkingFields } from '@/models/fields';

import type { ApiSchema } from '#/core-api';
import type { Get } from '@/store/types/core-api';

declare global {
  /**
   * Parking
   */
  type Parking = ApiSchema['entity.Parking'];
}

export interface ParkingStoreTypes {
  PARKINGS: [Get<'/entities/parkings', Parking[]>];
  PARKING: [Get<'/entities/parkings/{Id}', Parking>];
}

const parkingsModule = new StoreModule<ParkingStoreTypes>();
parkingsModule
  .createResourceAndAction({
    type: 'PARKINGS',
    requestEndPoint: '/entities/parkings',
    transform: data => data.parkings,
    requestOptions: () => ({
      params: {
        fields: parkingFields,
      },
    }),
  })
  .createResourceAndAction({
    type: 'PARKING',
    requestEndPoint: '/entities/parkings/:id',
    transform: data => data.parking,
    requestOptions: () => ({
      params: {
        fields: parkingFields,
      },
    }),
  });

export default parkingsModule;
