/* module for data that is also stored locally */
import { set } from 'vue';

import { keysOf } from '@/lib/utils';

import type { Getter, Mutation, Module } from 'vuex';
import type { RootState } from '@/store';

// helpers to use locally stored states
const prefix = 'appstate';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Section = Record<string, any>;
type MapFeatures = {
  satelliteView?: boolean;
  showInterventionZones?: boolean;
  selectedInterventionZones?: string[];
  showTrackers?: boolean;
};

export interface LocalAppState {
  legend?: string[];
  bikeListing: string[];
  sections: Section[];
  mapFeatures: MapFeatures;
}

/**
 * Set state in local storage
 * @param key - The state key
 * @param state - The state value
 */
function setStateLocally<K extends keyof S, S extends object = LocalAppState>(
  key: K,
  state: S[K]
): void {
  localStorage.setItem(`${prefix}:${key as string}`, JSON.stringify(state));
}

/**
 * Get state from local storage
 * @param key - The state key
 * @param defaultValue - The default value if state is not found
 */
function getStateLocally<K extends keyof S, S extends object = LocalAppState>(
  key: K,
  defaultValue: S[K]
): S[K] {
  const item = localStorage.getItem(`${prefix}:${key as string}`);
  return item ? JSON.parse(item) : defaultValue;
}

type Registered<State> = {
  state: State;
  getters: Record<keyof State, Getter<State, RootState>>;
  mutations: Record<keyof State, Mutation<State>>;
};

/**
 * Helper to register regular states with local storage reflexion
 */
function registerState<S extends object>(state: S): Registered<S> {
  return keysOf(state).reduce(
    (store, key) => {
      store.state[key] = getStateLocally(key, state[key]);
      store.getters[key] = storeState => storeState[key];
      store.mutations[key] = (storeState, value: S[keyof S]): void => {
        storeState[key] = value;
        setStateLocally(key, value);
      };
      return store;
    },
    {
      state: {},
      getters: {},
      mutations: {},
    } as Registered<S>
  );
}

const registeredStates = registerState<
  Omit<LocalAppState, 'sections' | 'mapFeatures'>
>({
  legend: undefined,
  bikeListing: [],
});

export default {
  state: {
    // sections state
    sections: getStateLocally('sections', []),
    mapFeatures: getStateLocally('mapFeatures', {
      satelliteView: false,
      showInterventionZones: false,
      selectedInterventionZones: [],
      showTrackers: true,
    }),
    // others
    ...registeredStates.state,
  },
  getters: {
    // get the section state by its name and state key
    sectionState:
      (state: LocalAppState) =>
      ({ name, key }: Record<string, string>) => {
        const section = state.sections.find((s: Section) => s.name === name);
        // section already registered
        if (section) return section.state[key];
        // section not yet registered
        else return undefined;
      },
    mapFeatures: (state: LocalAppState) => state.mapFeatures,
    // others
    ...registeredStates.getters,
  },
  mutations: {
    // set section state and store it locally
    sectionState(state: LocalAppState, { name, key, value }: Section): void {
      if (!name) return;
      const section = state.sections.find((s: Section) => s.name === name);
      // section already registered, update its state by setting a reactive prop
      if (section) {
        set(section.state, key, value);
      }
      // section not yet registered, add it with a new state
      else {
        const newSection: Section = { name };
        set(newSection, 'state', {});
        set(newSection.state, key, value);
        state.sections.push(newSection);
      }
      // store locally
      setStateLocally('sections', state.sections);
    },
    mapFeatures<K extends keyof MapFeatures>(
      state: LocalAppState,
      { key, value }: { key: K; value: MapFeatures[K] }
    ): void {
      state.mapFeatures[key] = value;
      // store locally
      setStateLocally('mapFeatures', state.mapFeatures);
    },
    // others
    ...registeredStates.mutations,
  },
  actions: {},
} satisfies Module<LocalAppState, RootState>;
