import { encodeQueryItem } from 'ufo';

/**
 * Serializes params to a query string like axios pre-v1
 * See: https://github.com/axios/axios/issues/5630
 */
export default function paramsSerializer(params: Record<string, any>): string {
  return Object.entries(params)
    .filter(
      ([, value]) =>
        value !== undefined &&
        value !== null &&
        (Array.isArray(value) ? value.length > 0 : true)
    )
    .flatMap(([key, value]) => {
      if (Array.isArray(value)) {
        return value.map(v => encodeQueryItem(`${key}[]`, v));
      }
      return encodeQueryItem(key, value);
    })
    .join('&');
}
