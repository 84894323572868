import { getCurrentInstance } from 'vue';

import store from '@/store';
import { usePrivileges } from '@/composables/plugins';

import type { Notification } from '@/store/modules/notifications';

interface UseNotificationReturn {
  /**
   * Creates a notification that is sent to the user
   * @param options - Partial notification settings
   */
  notify: (options: Partial<Notification>) => void;
}

export function useNotification(): UseNotificationReturn {
  const instance = getCurrentInstance();
  const { isGranted } = usePrivileges();
  const componentInstance = getCurrentInstance();
  const route = computed(() => componentInstance?.proxy.$route);

  function _notify(options: Partial<Notification> = {}): void {
    const service =
      options.service ??
      instance?.proxy.$vnode?.tag?.replace(/^vue-component-[0-9]+-/, '') ??
      'unknown';

    if (options.debug && !isGranted('admin.debug')) {
      return;
    }

    const notification: Notification = {
      type: 'info',
      route: route.value?.fullPath ?? '',
      message: '',
      error: null,
      arguments: null,
      new: true,
      timestamp: new Date().toISOString(),
      debug: false,
      ...options,
      service,
    };

    if (options.error && !options.type) notification.type = 'error';

    store.commit('notifications/add', notification);
  }

  function notify(options: Partial<Notification>): void {
    _notify(options);
  }

  return {
    notify,
  };
}
