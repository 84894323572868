<template lang="pug">
.ZQrCodeReader(:class="classes")
  VCard(
    v-if="isIosStandaloneNoWebRTC"
    :elevation="0"
  )
    VCardTitle
      h2
        VIcon(left) mdi-alert
        span {{ t('app__warning') }}
    VCardText {{ t('app__camera_access_refused') }}

  video.ZQrCodeReader__video(ref="videoRef")

  VBtn.ZQrCodeReader__button.ZQrCodeReader__button--left(
    v-if="hasTorch"
    icon
    color="info"
    @click="toggleTorch"
  )
    v-icon {{ isTorchOn ? 'mdi-flashlight-off' : 'mdi-flashlight' }}

  VBtn.ZQrCodeReader__button.ZQrCodeReader__button--right(
    v-if="closable && initialized"
    icon
    color="info"
    @click="closeHandler"
  )
    VIcon mdi-close
</template>

<style lang="stylus">
.ZQrCodeReader
  display flex

  &__video
    width 100%
    height 40vh
    object-fit cover

  &__button
    top 0
    position absolute
    margin 16px

    &:hover
      position absolute

    &--left
      left 0

    &--right
      right 0

    &--error
      border-color $color-error

      .ZQrCodeReader__scanOverlay_blurredSquare
        border-color $color-error

  // qr-scanner styles
  .scan-region-highlight
    border-radius 16px
    outline 50vmax solid alpha($color-accent, 0.25)

  .scan-region-highlight-svg
    display none

  .code-outline-highlight
    display none

  &--error
    .scan-region-highlight
      outline-color alpha($color-error, 0.24)

    .code-outline-highlight
      display block
      stroke $color-error !important

@keyframes scanSquareBreathe
  0%,
  100%
    opacity 0.1

  50%
    opacity 1
</style>

<script setup lang="ts">
import QrScanner from 'qr-scanner';

import AppError from '@/lib/classes/app-error';
import { check, noop } from '@/lib/utils';
import { AppErrorCode } from '@/enums/errors';
import { useVModelProxy, useAlert } from '@/composables';
import { useI18n, usePreferences } from '@/composables/plugins';

export interface ZQrCodeReaderProps {
  /**
   * Whether the scanner is active
   */
  value?: boolean;
  /**
   * Whether the component is closable, showing a close button emitting a 'close' event
   */
  closable?: boolean;
  /**
   * Method to validate the QR code. If given, the scanner will only emit 'decoded' event if the method returns true,
   * and switch the component in error state if it returns false.
   */
  validate?: (data: string) => boolean;
}

const props = withDefaults(defineProps<ZQrCodeReaderProps>(), {
  value: false,
  closable: false,
  validate: () => true,
});

const emit = defineEmits<{
  (event: 'close'): void;
  (event: 'decoded', data: string): void;
  (event: 'input', value: boolean): void;
}>();

const isInvalidQrCode = ref(false);
const hasTorch = ref(false);
const isTorchOn = ref(false);
const initialized = ref(false);
const validationSound = new Audio('/audio/valid-zoov-qr.mp3');
const isIosStandaloneNoWebRTC =
  check.isIOS() && check.isStandalone() && !check.hasWebRTC();

const isActive = useVModelProxy({ props });

const classes = computed(() => ({
  'ZQrCodeReader--error': isInvalidQrCode.value,
}));

const { preferences } = usePreferences();
const withSounds = computed(() => preferences.qrSounds);

const videoRef = ref<HTMLVideoElement | null>(null);
const scanner = ref<QrScanner | null>(null);

const alert = useAlert();
const { t } = useI18n();

onMounted(init);
onUnmounted(destroy);

async function init(): Promise<void> {
  if (!videoRef.value) return;
  scanner.value = new QrScanner(videoRef.value, onDecode, {
    onDecodeError: noop,
    highlightScanRegion: true,
    highlightCodeOutline: true,
    maxScansPerSecond: 5,
    preferredCamera: 'environment',
  });
  await start();
  hasTorch.value = await scanner.value.hasFlash();
}

function destroy(): void {
  scanner.value?.destroy();
}

async function start(): Promise<void> {
  await scanner.value?.start();
}

function pause(): void {
  scanner.value?.pause();
}

function onDecode(result: QrScanner.ScanResult): void {
  // Validate content
  if (!props.validate(result.data)) {
    isInvalidQrCode.value = true;
    alert.error = new AppError(AppErrorCode.InvalidQrCode);
    return;
  }
  isInvalidQrCode.value = false;
  sendUserFeedback();
  pause();
  emit('decoded', result.data);
  alert.error = null;
}

async function toggleTorch(): Promise<void> {
  if (!hasTorch.value) return;
  await scanner.value?.toggleFlash();
  isTorchOn.value = !!scanner.value?.isFlashOn();
}

function sendUserFeedback(): void {
  if (withSounds.value) validationSound.play();
  navigator.vibrate?.(15);
}

function closeHandler(): void {
  isActive.value = false;
  emit('close');
}

watch(isActive, newValue => {
  if (newValue) start();
  else pause();
});

/**
 * Turn off the torch if it is on
 */
function torchOff(): void {
  if (isTorchOn.value) toggleTorch();
}

defineExpose({
  torchOff,
});
</script>
