import { AppErrorCode } from '@/enums/errors';

/**
 * Mapper of error codes to i18n keys
 */
const errorMapper: Record<AppErrorCode, string> = {
  // General
  [AppErrorCode.InternalError]: 'error__internal_error',
  [AppErrorCode.NoDataAvailable]: 'error__no_data_available',
  [AppErrorCode.WrongYamlFileExtension]: 'error__wrong_yaml_file_extension',
  [AppErrorCode.FileTooLarge]: 'error__file_too_large',
  [AppErrorCode.InvalidQrCode]: 'error__invalid_qr_code',
  [AppErrorCode.BatchActionCancelled]: 'error__batch_action_cancelled',
  [AppErrorCode.BatchActionAllFailed]: 'error__batch_action_all_failed',
  [AppErrorCode.InvalidOrEmptyYamlFile]: 'error__invalid_or_empty_yaml_file',
  [AppErrorCode.MissingPermissionsInYamlFile]:
    'error__missing_permissions_in_yaml_file',
  [AppErrorCode.InvalidGeoJson]: 'error__invalid_geojson',
  [AppErrorCode.NoTargetFirmwareFound]: 'error__no_target_firmware_found',
  [AppErrorCode.NoProductVersionFound]: 'error__no_product_version_found',
  [AppErrorCode.MonextChallengeFailed]: 'error__monext_challenge_failed',
  [AppErrorCode.MonextChallengeErrored]: 'error__monext_challenge_errored',
  [AppErrorCode.MonextNoSecretInResponse]:
    'error__monext_no_secret_in_response',
  [AppErrorCode.MonextChallengeTimeout]: 'error__monext_challenge_timeout',
  [AppErrorCode.MonextGetTokenError]: 'error__monext_get_token_error',
  [AppErrorCode.MonextNoPaymentIdInResponse]:
    'error__monext_no_payment_id_in_response',
  [AppErrorCode.UnknownProvider]: 'error__unknown_provider',
  [AppErrorCode.StripeError]: 'error__stripe_error',
  [AppErrorCode.SetGeolocationError]: 'error__set_geolocation_error',
  [AppErrorCode.InvalidHexColorFormat]: 'error__invalid_hex_color_format',
  [AppErrorCode.InvalidConversionStringToBoolean]:
    'error__invalid_conversion_string_to_boolean',
  [AppErrorCode.FormattingFailed]: 'error__formatting_failed',
  [AppErrorCode.MetricsFailedToFetchData]:
    'error__metrics_failed_to_fetch_data',
  [AppErrorCode.UpdateFilterPresetFailed]: 'error__update_filter_preset_failed',
  [AppErrorCode.NoScopeName]: 'error__no_scope_name',
  [AppErrorCode.UnknownScope]: 'error__unknown_scope',
  [AppErrorCode.PaymentActionFailed]: 'error__payment_action_failed',
  [AppErrorCode.PaymentActionUnexpectedStatus]:
    'error__payment_action_unexpected_status',

  // Request
  [AppErrorCode.RequestManuallyCancelled]: 'error__request_manually_cancelled',
  [AppErrorCode.RequestNoResponseReceived]:
    'error__request_no_response_received',
  [AppErrorCode.RequestSetup]: 'error__request_setup',
  [AppErrorCode.RequestDataUndefinedInResponse]:
    'error__request_data_undefined_in_response',
  [AppErrorCode.RequestUnhandledRequestMethod]:
    'error__request_unhandled_request_method',
  [AppErrorCode.RequestUndefinedToken]: 'error__request_undefined_token',
  [AppErrorCode.RequestTimeout]: 'error__request_timeout',
  [AppErrorCode.RequestErrorStringType]: 'error__request_error_string_type',
};

/**
 * Get the translation key of an error code
 * If the error code is not found, the internal_error key is returned
 * @param code - App error code
 * @returns String of a translation key
 */
export function getErrorTranslationKey(code: AppErrorCode): string {
  return errorMapper[code] ?? errorMapper[AppErrorCode.InternalError];
}
