/**
 * Return icon in HTML markup from raw SVG path and some style properties
 * @param svgPath - The SVG path `d` attibute value
 * @param size - Size in pixels
 * @param style - CSS styles
 * @param classes - CSS classes
 */
export default function getRawSvgIcon(
  svgPath: string,
  size: string | number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  style: Record<string, string> = {},
  classes: string | string[] = []
): string {
  const sizeNumber =
    typeof size === 'number'
      ? size
      : size && parseInt(size)
      ? parseInt(size)
      : null;
  const iconSize = sizeNumber ? `${sizeNumber}px` : null;
  if (iconSize) {
    style.width = iconSize;
    style.height = iconSize;
    style['min-width'] = iconSize;
    style['min-height'] = iconSize;
  }
  const iconStyle = Object.entries(style)
    .map(([key, val]) => key + ': ' + val)
    .join(';');
  const classStr = ['ZSvgIcon'].concat(classes).join(' ');
  return `<div class="${classStr}" style="${iconStyle}">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="${svgPath}"/>
      </svg>
    </div>`;
}
