// app level config

export const devEnvs = ['tatooine 🪐'];
export const name = 'Control';

export const availableLocales = ['en', 'fr', 'de'] as const;
export type AvailableLocale = (typeof availableLocales)[number];
export const defaultLocale: AvailableLocale = 'en';
// notifications setup
export const renewNotifications = false;
// login redirection
export const loginDefaultRedirectRoute = 'Dashboard';
// display data table
export const pageSizeOptions = [10, 20, 50, 100];
export const historyPageSizeOptions = [20, 50, 100, 500];
// google maps link
export const mapDirectionsBaseLink = {
  gmaps:
    'https://www.google.com/maps/dir/?api=1&travelmode=driving&destination=',
  waze: 'https://waze.com/ul?navigate=yes&&ll=',
};
export const qrCodeHosts = [
  'www.zoov.eu',
  'www.thisebike.com',
  'this-product.com',
];
// bikes
export const lastContactWarningLimit = 90 * 60 * 1000; // 1h30 in ms
export const lastLockInfoWarningLimit = 5 * 60 * 1000; // 5 min in ms
export const lastThiefWarningLimit = 10 * 60 * 1000; // 10 min in ms
export const lastContactPriorityLimit = 6 * 60 * 60 * 1000; // 6 hours in ms
// soc limits
export const batteryPriorityLimit = 50; // %
export const batterySwappingLimits = [40, 25]; // %
export const baseMinSoc = 15; // %
export const noTripSince = [12, 24, 48, 72]; // hours
export const lastUpdateWarningLimit = 4 * 60 * 60 * 1000; // 4 hours in ms
// decreasing battery rate when not used
export const predictedSoCDecayRate = 0.8; // in % per hour
export const estimatedDecayRateInTripPerKmMin = 1; // in % per km
export const estimatedDecayRateInTripPerKmMax = 3; // in % per km
// simulated long touch for iOS
export const longTouchDelay = 500;
export const notificationsLimit = 100;
// histories
export const bikeHistoryOnMapPeriod = 6 * 60 * 60 * 1000; // 6h in ms
export const vehicleHistoryOnMapPeriod = 6 * 60 * 60 * 1000; // 6h in ms

export const maxArchivedIssues = 5;
export const defaultMetadataField = window.VUE_APP_METADATA_FIELD ?? 'metadata';
export const termsAndConditionsRoute = { query: { terms_and_conditions: '1' } };
export const privacyPolicyRoute = { query: { privacy_policy: '1' } };
