<template lang="pug">
VAutocomplete.ZInputCurrency(
  v-model="currency"
  :label="label"
  :items="currencies"
  :disabled="disabled"
  :rules="computedRules"
  clearable
  dense
)
  template(#selection="{ item }")
    span {{ item.text }}
  template(#item="{ item }")
    .ZInputCurrency__item
      .ZInputCurrency__item__text {{ item.text }}
</template>

<style lang="stylus">
.ZInputCurrency
  &.v-input.v-text-field.v-autocomplete
    .v-input__control > .v-input__slot
      min-width 88px

    .v-select__selections
      > input
        width 0

.ZInputCurrency__item
  display flex
  align-items center

.ZInputPhoneNumberItem__text
  width max-content
</style>

<script setup lang="ts">
import { i18nConfig } from '@/config';
import { useVModelProxy } from '@/composables';
import { requiredRule } from '@/lib/helpers/rules';

export interface ZInputCurrencyProps {
  /**
   * The value of the input
   * @model
   */
  value?: CurrencyCode;
  /**
   * Whether the input is disabled
   */
  disabled?: boolean;
  /**
   * Whether the input is required
   */
  required?: boolean;
  /**
   * Validation rules of the input
   */
  rules?: VuetifyRule<CurrencyCode>[];
  /**
   * The input label
   */
  label?: string;
  /**
   * The input hint
   */
  hint?: string;
}

const { currencySymbols } = i18nConfig;

const props = withDefaults(defineProps<ZInputCurrencyProps>(), {
  value: undefined,
  disabled: false,
  required: false,
  rules: () => [],
  label: '',
  hint: '',
});

const currencies = Object.entries(currencySymbols).map(([currency, symbol]) => {
  return {
    text: `${currency} (${symbol})`,
    value: currency,
  };
});

const currency = useVModelProxy({ props });

const computedRules = computed(() => {
  return [...(props.required ? [requiredRule] : []), ...props.rules];
});
</script>
