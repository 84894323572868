/* eslint-disable @typescript-eslint/no-explicit-any */
import { set } from 'vue';
import merge from 'deepmerge';
import { deferredPromise } from '@fifteen/shared-lib';
import { isAxiosError } from 'axios';
import piwa from 'piwa';
import destr from 'destr';
import { ApiErrorCode } from '@fifteen/sdk';

import { delayPromise, isFunc, queryToString } from '@/lib/utils';
import { apiConfig, debugConfig } from '@/config';
import { populateResource } from '@/store/core/populate';
import AppError from '@/lib/classes/app-error';
import globalAreasUsage from '@/lib/filters/areas';
import router from '@/router';
import { DatacenterErrorCode, AppErrorCode } from '@/enums/errors';
import { useI18n, actionTypesPermissionsRegistry } from '@/composables/plugins';
import { useNotification, useRequest, useRuntimeConfig } from '@/composables';
import { RequestError } from '@/composables/useRequest';

import type { UseRequestReturn, RequestConfig } from '@/composables/useRequest';
import type { ActionContext } from 'vuex';
import type { PopulateConfig } from '@/store/core/populate';
import type { DeferredPromise } from '@fifteen/shared-lib';
import type { AxiosError } from 'axios';
import type {
  ExtractActionMethods,
  StoreModuleActions,
  StoreModuleData,
  StoreModuleGetters,
  StoreModuleMutations,
  StoreModulePayloads,
  StoreModuleRemoteState,
  StoreModuleResponses,
  StoreModuleState,
} from '@/store/types/helpers/store-types';
import type { GetterType } from '@/store';

export type Context = ActionContext<AnyState, AnyState>;

interface CommitPayload {
  scope?: string;
  data: unknown;
}

type RequestApiConfig = RequestConfig & {
  /**
   * Number of retries after a first timeout
   */
  timeoutAttempts?: number;
  /**
   * Cancel the request if one is currently processing. This will
   * call the cancel method of our Request axios wrapper, which
   * uses axios’ cancel-token mechanics under the hood. Can be a
   * string which is the message sent to axios’ cancel-token.
   */
  cancel?: true | 'silent';
  /**
   * Set the specific timeout in the case of requests that are
   * flagged as slow, {@link CreateActionOptions['longRequest']}
   */
  slowServerTimeout?: number;
  /**
   * Number of retries of refresh token
   */
  refreshTokenRetryNum?: number;
  /**
   * Delay between two refresh token retries
   */
  refreshTokenRetryDelay?: number;
  /**
   * Prevent the request to store data in its corresponding state.
   */
  preventStore?: boolean;
};

interface BaseOptions<
  TypeT extends keyof State,
  State,
  DefaultT = State[TypeT],
> {
  /**
   * Define scopes fot the state, which will have a separated entry per scope.
   * As a consequence, you will need to use the corresponding getter as method,
   * passing the scope as the first parameter
   */
  scopes?: readonly string[] | null;
  /**
   * Define a default value for the state
   */
  defaultValue?: null | DefaultT | (() => DefaultT);
}

export interface CreateResourceOptions<TypeT extends keyof State, State>
  extends BaseOptions<TypeT, State> {
  /**
   * The type name defined in the types registry
   * @example 'BIKES', 'STATIONS_COUNT', 'BENEFIT_HISTORY'
   */
  type: TypeT;
  /**
   * Also store the state in localStorage
   */
  storeLocally?: boolean;
}

export interface CreateActionOptions<
  UnprefixedTypeT,
  MethodT extends HttpMethod,
  TypeT extends keyof State,
  State,
  Response,
  DefaultT,
  Options = RequestApiConfig,
> extends BaseOptions<TypeT, State, DefaultT> {
  /**
   * The action type name defined in the types registry
   * @example 'BIKES', 'STATIONS'
   */
  type: UnprefixedTypeT;
  /**
   * Request URL
   */
  requestEndPoint: string;
  /**
   * Request method should be deduced from the type, but you can override it if needed
   */
  requestMethod?: MethodT;
  /**
   * Request configuration, {@link RequestApiConfig}
   */
  requestApiConfig?: RequestApiConfig;
  /**
   * Permission associated to the action. This will add a mapping between the action type and the permission
   * so that we can access it's granted state with privileges plugin using `isGrantedAction` method:
   * @example
   * ```ts
   * const { isGrantedAction } = usePrivileges();
   * isGrantedAction('POST_AREAS');
   * ```
   */
  permission?: string;
  /**
   * Whether the action is bound to an API route that requires authentication
   */
  needsAuth?: boolean;
  /**
   * Request configuration factory
   * @param context - The action context
   */
  requestOptions?: (context: Context) => Partial<Options>;
  /**
   * Transform fetched data in anything convenient
   * @param data - The data directly after the fetch
   * @param context - The action context
   */
  transform?: (data: Response, context: Context, options: Options) => unknown;
  /**
   * Callback method called when action is successful
   * @param resource - The final data, after transformation and population
   * @param context - The action context
   */
  onSuccess?: (resource: DefaultT, context: Context) => void;
  /**
   * Callback method called when action failed
   * @param error - The error that caused the fail
   * @param context - The action context
   */
  onError?: (error: Error, context: Context) => void;
  /**
   * List of configurations to populate the data {@link PopulateConfig}
   */
  populate?: PopulateConfig[];
  /**
   * Whether the data is filtered by "global usage" query, i.e. globally selected areas
   */
  withGlobalUsageQuery?: boolean;
  /**
   * Flag that the request will be long, so that a longer timeout is applied
   * and the status snackbar informing server latency is not shown
   */
  longRequest?: boolean;
  /**
   * Prevent the request to store data in its corresponding state.
   * The request is performed but not mutation is called.
   */
  preventStore?: boolean;
}

export type CreateResourceAndActionOptions<
  TypeT extends keyof State,
  State,
  Response,
  DefaultT = State[TypeT],
  Options = RequestApiConfig,
> = CreateResourceOptions<TypeT, State> &
  Omit<
    CreateActionOptions<
      TypeT,
      'GET',
      TypeT,
      State,
      Response,
      DefaultT,
      Options
    >,
    'preventStore'
  >;

export interface AliasActionOptions<
  Types,
  FromType extends Unprefixed<keyof StoreModuleActions<Types>>,
  ToType extends Unprefixed<keyof StoreModuleActions<Types>>,
  MethodT extends ExtractActionMethods<Types, FromType>,
> {
  fromType: FromType;
  toType: ToType;
  requestMethod: MethodT;
  requestOptions?: (context: Context) => Partial<RequestApiConfig>;
}

export type ActionPayload = Partial<RequestApiConfig> & {
  /**
   * Prevent the action to use global usage query (i.e. globally filtered on areas)
   */
  preventGlobalUsageQuery?: boolean;
  /**
   * Freeze the response so that it is not made reactive, saving memory and processing
   */
  freeze?: boolean;
  /**
   * Prevent fetched data to be populated with additional api calls
   */
  preventPopulate?: boolean;
  /**
   * Specify populate settings to be used for this request only
   */
  populate?: PopulateConfig[];
  /**
   * Specify that only the given populate settings should be used, based on their names
   */
  populateOnly?: string[];
};

type Unprefixed<T> = T extends `${HttpMethod}_${infer R}` ? R : T;

// define refresh token promises that will wait for the first
// refresh token and be resolved/rejected externally
const waitingRefreshTokenPromises: DeferredPromise<void>[] = [];

/**
 * Get the value of a given input: if the input is a function, return the call to that function, otherwise return the input
 * @param value - Any value or function
 */
function getValue<T>(value: T | (() => T)): T {
  return isFunc(value) ? value() : value;
}

export default class StoreModule<Types> {
  public module: {
    state: Partial<StoreModuleState<Types>>;
    getters: Partial<StoreModuleGetters<Types>>;
    mutations: Partial<StoreModuleMutations<Types>>;
    actions: Partial<StoreModuleActions<Types>>;
  } = {
    state: {},
    getters: {},
    mutations: {},
    actions: {},
  };

  /**
   * Create an action which is automatically bound to a resource, that is to say
   * it defines a store action coupled to an API, and the associated state, getter
   * and mutation that handles storing the data properly. In other words, it creates
   * a local vuex state that reflects a remote resource.
   * @param config - The configuration, {@link CreateResourceAndActionOptions}
   */
  createResourceAndAction<
    TypeT extends keyof StoreModuleRemoteState<Types>,
    PrefixedTypeT extends
      keyof StoreModuleActions<Types> = `GET_${TypeT}` extends keyof StoreModuleActions<Types>
      ? `GET_${TypeT}`
      : never,
  >({
    type,
    scopes,
    defaultValue,
    requestEndPoint,
    requestApiConfig,
    permission,
    needsAuth,
    requestOptions,
    transform,
    onSuccess,
    onError,
    populate,
    withGlobalUsageQuery,
    longRequest,
    storeLocally,
  }: CreateResourceAndActionOptions<
    TypeT,
    StoreModuleState<Types>,
    StoreModuleResponses<Types>[PrefixedTypeT],
    StoreModuleData<Types>[TypeT],
    ActionPayload &
      (StoreModulePayloads<Types>[PrefixedTypeT] extends undefined
        ? Record<string, never>
        : StoreModulePayloads<Types>[PrefixedTypeT])
  >): this {
    this.createResource({
      type,
      scopes,
      defaultValue,
      storeLocally,
    });
    this.createAction({
      type,
      scopes,
      defaultValue,
      requestEndPoint,
      requestMethod: 'GET' as ExtractActionMethods<Types, TypeT>,
      requestApiConfig,
      permission,
      needsAuth,
      requestOptions,
      transform,
      onSuccess,
      onError,
      populate,
      withGlobalUsageQuery,
      longRequest,
      _mutationType: type,
    });
    // for chaining
    return this;
  }

  /**
   * Create a store resource, which, from a store type, will define
   * a state, a getter an the associated mutation
   * @param config - Create resource configuration, {@link CreateResourceOptions}
   */
  createResource<TypeT extends keyof StoreModuleState<Types> & string>({
    type,
    scopes = null,
    defaultValue = null,
    storeLocally = false,
  }: CreateResourceOptions<TypeT, StoreModuleState<Types>>): this {
    /* STATE */
    const initialValue =
      (storeLocally &&
        destr<StoreModuleState<Types>[TypeT]>(
          localStorage.getItem(`store__${type.toLowerCase()}`)
        )) ||
      defaultValue;
    // register resource in state and initiate it with default value
    this.module.state[type] = this._prepareResourceDefault(
      scopes,
      initialValue
    );

    /* GETTER */
    this.module.getters[type] = (state => {
      const value = state[type] as any;

      // if scopes are defined, return a getter function with scope param
      if (scopes) {
        return (scope: string) => value?.[scope] ?? getValue(initialValue);
      }
      // otherwise return value
      else {
        return value;
      }
    }) as StoreModuleGetters<Types>[TypeT];

    /* MUTATIONS */
    this.module.mutations[type] = (state, payload) => {
      // populate state

      if (scopes && payload?.scope) {
        // prevent error if state has not been properly initialized
        if (state[type] === undefined) set(state, type, {});
        // set data for the given scope
        // @ts-expect-error typescript does not properly understand that in this case, state is scoped
        set(state[type], payload.scope, payload.data);
      } else {
        set(state, type, payload.data);
      }

      // store locally if required
      if (storeLocally) this._storeLocally(state, type);
    };

    // for chaining
    return this;
  }

  /**
   * Create an action that is coupled with an API
   * @param config - Create action configuration {@link CreateActionOptions}
   */
  createAction<
    TypeT extends Unprefixed<keyof StoreModuleActions<Types>>,
    MethodT extends ExtractActionMethods<Types, TypeT>,
    PrefixedTypeT extends
      keyof StoreModuleActions<Types> = `${MethodT}_${TypeT}` extends keyof StoreModuleActions<Types>
      ? `${MethodT}_${TypeT}`
      : never,
    Options = Parameters<StoreModuleActions<Types>[PrefixedTypeT]>[1],
  >({
    type,
    scopes = null,
    defaultValue,
    requestEndPoint = '/',
    requestMethod = 'GET' as MethodT,
    requestApiConfig = apiConfig,
    permission,
    needsAuth = true,
    requestOptions = () => ({}),
    transform,
    onSuccess,
    onError,
    populate,
    preventStore = false,
    withGlobalUsageQuery = false,
    longRequest = false,
    _mutationType,
  }: CreateActionOptions<
    TypeT,
    MethodT,
    PrefixedTypeT,
    StoreModuleActions<Types>,
    StoreModuleResponses<Types>[PrefixedTypeT],
    StoreModuleData<Types>[PrefixedTypeT],
    Options
  > & {
    /**
     * @private This is an internal option, do not use it
     */
    _mutationType?: keyof StoreModuleMutations<Types>;
  }): this {
    // type string for logging purposes
    const storeTypeString = `[${requestMethod}_${type}]`;

    let request: UseRequestReturn;

    // store associated permission to the types-permissions registry
    const actionType = `${requestMethod}_${type}` as PrefixedTypeT;
    if (permission) actionTypesPermissionsRegistry.set(actionType, permission);

    // this action is async as it involves request to API
    this.module.actions[actionType] = async (context, _options) => {
      const { t } = useI18n();
      const options = (_options ?? {}) as ActionPayload;
      const { notify } = useNotification();
      const { runtimeConfig } = useRuntimeConfig();

      if (!request) {
        request = useRequest({
          ...requestApiConfig,
          headers: {
            ...(runtimeConfig.VUE_APP_CLIENT_ID && {
              'X-ClientId': runtimeConfig.VUE_APP_CLIENT_ID,
            }),
          },
        });
      }

      try {
        // global usage query
        const globalUsageOptions: Pick<RequestApiConfig, 'params'> = {};

        // if we do not prevent the use of global usage query
        if (
          !router.currentRoute.meta?.areaIndependent &&
          withGlobalUsageQuery &&
          !options.preventGlobalUsageQuery &&
          requestMethod === 'GET' &&
          context.getters['APP_LOGGED_IN']
        ) {
          // use global query for global usage filtering
          // area usage
          const areaUsageIds = context.getters.usage({ name: 'area' });
          const areaUsageQuery = globalAreasUsage.buildQuery(
            type as GetterType,
            areaUsageIds
          );

          // then create corresponding options
          if (areaUsageQuery) {
            globalUsageOptions.params = { query: areaUsageQuery };
          }
        }

        // merge request options defined at module creation time with additional options passed at dispatch time.
        // if they exists, additional options will overrides the previous ones
        // options query entries will also overrides globalUsageOptions query entries if provided
        const resolvedRequestOptions = requestOptions(context);
        const mergedOptions = merge<ActionPayload & { [K: string]: any }>(
          merge(resolvedRequestOptions, globalUsageOptions),
          options
        );
        // however we do not want all options to be deep merged (it is relevant for params.query by not for params.fields as
        // we may want the fields to be overridden by 'options', so use options.params.fields if defined
        if (mergedOptions.params?.fields && options.params?.fields) {
          mergedOptions.params.fields = options.params.fields;
        }

        // format any array param and params.query
        // if they were passed as array (fields) or object (query), they have been merged :-)
        if (mergedOptions.params) {
          Object.entries(mergedOptions.params).forEach(
            ([paramName, paramValue]) => {
              if (Array.isArray(paramValue)) {
                mergedOptions.params[paramName] = paramValue.join(',');
              }
            }
          );
          const query = mergedOptions.params.query;
          if (query instanceof Object) {
            mergedOptions.params.query = queryToString(query);
          }
        }

        // check if a cancel option is passed to the dispatch options.
        // if this is the case, we call request's cancel function and don't go any further.
        // we can pass a message in cancel option, which will be forwarded to request's cancel function.
        if (mergedOptions.cancel) {
          // if this action was already dispatched, request.cancel is a valid cancel function from
          // axios cancelToken, otherwise it is just a noop.
          if (typeof mergedOptions.cancel === 'string') {
            request.cancel.value?.(mergedOptions.cancel);
          } else {
            request.cancel.value?.();
          }
          return;
        }

        // the desired route, before transformation
        let transformedRequestEndPoint = requestEndPoint;

        // check if route has :pathParameters
        const pathParameters = requestEndPoint.match(/:[a-z_]+/g) || [];
        pathParameters.forEach(pathParameter => {
          const value = mergedOptions[pathParameter.replace(/^:/, '')];
          if (value) {
            // replace :pathParameter with the value provided in options
            transformedRequestEndPoint = transformedRequestEndPoint.replace(
              pathParameter,
              value
            );
          }
        });

        // while loop for multiple try (to handle refresh token or request timeout)
        const timeoutAttempts =
          mergedOptions.timeoutAttempts || apiConfig.timeoutAttempts;
        let retry = 1;
        while (retry) {
          // set authentication headers with stored token
          const token = context.getters['LOGIN_DATA']?.token;
          if (needsAuth && token && !debugConfig.bypassLogin) {
            mergedOptions.headers = {
              ...(mergedOptions.headers || {}),
              Authorization: `Bearer ${token}`,
            };
          }

          // fetch resource if token is defined or anyway if needsAuth = false
          // or make only a call if no resource is attached to this action
          // (`resource` is treated as a dump variable)
          let error: RequestError | AppError | null | undefined;
          let resource: any;
          let slowServerTimeout: NodeJS.Timeout | null = null;

          if (token || !needsAuth || debugConfig.bypassLogin) {
            // initiate counter to display that server is slow after a while
            if (!longRequest) {
              slowServerTimeout = setTimeout(
                () => context.commit('slowServer', true),
                apiConfig.slowServerTimeout
              );
            }
            switch (requestMethod) {
              case 'GET':
                [error, resource] = await to<unknown, RequestError | AppError>(
                  request.get(transformedRequestEndPoint, mergedOptions)
                );
                break;
              case 'POST':
                [error, resource] = await to<unknown, RequestError | AppError>(
                  request.post(
                    transformedRequestEndPoint,
                    mergedOptions.data ?? {},
                    mergedOptions
                  )
                );
                break;
              case 'PATCH':
                [error, resource] = await to<unknown, RequestError | AppError>(
                  request.patch(
                    transformedRequestEndPoint,
                    mergedOptions.data ?? {},
                    mergedOptions
                  )
                );
                break;
              case 'PUT':
                [error, resource] = await to<unknown, RequestError | AppError>(
                  request.put(
                    transformedRequestEndPoint,
                    mergedOptions.data ?? {},
                    mergedOptions
                  )
                );
                break;
              case 'DELETE':
                [error, resource] = await to<
                  unknown,
                  AxiosError<ApiErrorData> | AppError
                >(
                  request.delete(
                    transformedRequestEndPoint,
                    mergedOptions.data ?? {},
                    mergedOptions
                  )
                );
                break;
              default:
                throw new AppError(AppErrorCode.RequestUnhandledRequestMethod, {
                  args: { method: requestMethod },
                });
            }
          } else {
            // token is undefined
            // set error to true to fulfill below condition
            error = new AppError(AppErrorCode.RequestUndefinedToken);
          }

          // request ended, do not display that server is slow
          if (slowServerTimeout) {
            clearTimeout(slowServerTimeout);
            context.commit('slowServer', false);
          }

          if (error) {
            const errorCode = isAxiosError(error)
              ? error.response?.data.code
              : error.code;

            // check if token is undefined or expired
            const isInvalidTokenError = errorCode
              ? [
                  ApiErrorCode.AuthInvalidToken,
                  DatacenterErrorCode.InvalidToken,
                  AppErrorCode.RequestUndefinedToken,
                ].includes(errorCode)
              : false;

            if (isInvalidTokenError && needsAuth) {
              // if so, we need to refresh token
              // do not retry more than once to avoid infinite retry loop
              if (retry > 1) return;

              // however, multiple API calls can be performed by app almost simultaneously
              // so we must prevent multiple refresh token and wait
              // in the case we are currently refreshing token
              if (context.getters.refreshingToken) {
                // these calls just wait for the first refresh token to resolve
                const waitingPromise = deferredPromise<void>();
                waitingRefreshTokenPromises.push(waitingPromise);
                const { error: waitingError } = await piwa(waitingPromise);
                // if error while waiting (the first refresh token failed), abort silently
                if (waitingError) return;
                // otherwise we can perform the initial request (retry)
                retry++;
              } else {
                const { data: isAuthenticated } = await piwa(
                  context.dispatch('GET_AUTH_STATUS')
                );

                if (isAuthenticated) {
                  context.commit('refreshingToken', true);
                  context.commit('offline', false);

                  // wrapper function for refresh token
                  function refreshToken(): Promise<any> {
                    return context.dispatch('POST_REFRESH_TOKEN');
                  }

                  // make a retry chain with a delay between each retry
                  let retryRefreshTokenChain = refreshToken();

                  for (let i = 1; i < apiConfig.refreshTokenRetryNum; i++) {
                    retryRefreshTokenChain = retryRefreshTokenChain
                      .catch(
                        delayPromise.reject(apiConfig.refreshTokenRetryDelay)
                      )
                      .catch(() => refreshToken());
                  }

                  // call API for a new token
                  const { error: errorRefreshToken } = await piwa(
                    retryRefreshTokenChain
                  );
                  if (errorRefreshToken) {
                    // the auth cannot be made from this refresh token after all the refresh token retries
                    // so we stop and notify the user that his refresh token failed
                    retry = 0;
                    // reject the other waiting promises
                    waitingRefreshTokenPromises.forEach(promise =>
                      promise.reject(errorRefreshToken)
                    );
                    // but we are in offline mode
                    context.commit('offline', true);
                    context.commit('offlineRetry', () => {
                      context.commit('offline', false);
                      this.module.actions[actionType]?.(context, _options);
                    });
                    throw errorRefreshToken;
                  } else {
                    notify({
                      type: 'renew',
                      message: t('app__token_refreshed'),
                      new: false,
                    });
                    // auth is successful and we have a new token.
                    // resolve the other waiting promises
                    waitingRefreshTokenPromises.forEach(p => p.resolve());
                    // retry the initial request once again
                    retry++;
                  }

                  // flag that we are no longer refreshing token
                  context.commit('refreshingToken', false);
                } else {
                  // no refresh token entry is in store, quit the retry
                  retry = 0;
                }
              }
            } else {
              // error while fetching the resource
              // check if error is a timeout
              if (/timeout/.test(error.message)) {
                // if so, retry
                retry++;
                // after X timeouts, log and notify an error
                if (retry > timeoutAttempts) {
                  retry = 0;
                  // throw a 'failed successive timeouts' error for chaining (.catch or await)
                  // this forces us to catch the possible error, to avoid 'unhandled promise rejection'

                  throw new AppError(AppErrorCode.RequestTimeout, {
                    args: {
                      timeout: Math.round(
                        (mergedOptions.timeout || apiConfig.timeout) / 1000
                      ),
                      attempts: timeoutAttempts,
                    },
                  });
                }
              } else {
                // otherwise, stop retrying
                retry = 0;
                // anyhow, toggle offline off
                context.commit('offline', false);

                // store default value of resource
                if (
                  requestMethod === 'GET' &&
                  !preventStore &&
                  !mergedOptions.preventStore &&
                  _mutationType
                ) {
                  // Warning: we may need to use 'localStorage' here if storeLocally is true
                  const commitPayload: CommitPayload = {
                    data: getValue(defaultValue),
                  };
                  this._storeResource(context, _mutationType, commitPayload);
                }

                // trigger a callback if it is passed to instance
                onError?.(error, context);
                // as it is an async function, throw error for chaining (.catch or await).
                // this forces us to catch the possible error, to avoid 'unhandled promise rejection'
                throw error;
              }
            }
          } else {
            // resource has been successfully fetched

            // stop retrying
            retry = 0;

            // if response is flagged as being cancelled silently, do absolutely nothing
            // and return that flag
            if (resource?.silentCancel) return resource;

            // if response is empty or undefined, set resource to its default value
            if (!resource) {
              resource = this._prepareResourceDefault(scopes, defaultValue);
            }

            // transform resource in an other data structure if a transform function is passed
            resource = transform
              ? transform(resource, context, mergedOptions as Options)
              : resource;

            // merge populate options
            const mergedPopulate = [
              ...(populate ?? []),
              ...(mergedOptions.populate ?? []),
            ];

            // set populateSettings, using `populateOnly` option – stating wether the request should be made
            // only with picked populate settings based on its given names – or full populate settings array
            const populateSettings = mergedPopulate.filter(
              populateConfig =>
                !mergedOptions.populateOnly ||
                (populateConfig.name &&
                  mergedOptions.populateOnly.includes(populateConfig.name))
            );

            // We prepare the resource with populated entries to display populate loading state
            const pendingPopulatedResource =
              populateSettings.length && !mergedOptions.preventPopulate
                ? await populateResource(resource, populateSettings, true)
                : resource;

            // store resource if not prevented by option `preventStore`
            const prepareAndStore = (resource: any): void => {
              if (
                preventStore ||
                mergedOptions.preventStore ||
                !_mutationType
              ) {
                return;
              }
              const commitPayload: CommitPayload = {
                // we can freeze the data, indicating that Vue does not need
                // to make it reactive (no reactive getters and setters) so
                // that accessing or mutating it has improved performances
                data: mergedOptions.freeze ? Object.freeze(resource) : resource,
              };
              if (scopes?.length) {
                // verify that we have a scope in options
                if (!mergedOptions.scope) {
                  throw new AppError(AppErrorCode.NoScopeName);
                }
                // or that the scope provided is known
                else if (!scopes.includes(mergedOptions.scope)) {
                  throw new AppError(AppErrorCode.UnknownScope, {
                    args: {
                      providedScope: mergedOptions.scope,
                      knownScopes: scopes.join(', '),
                    },
                  });
                }
                // if everything is ok, set scope to commit payload
                commitPayload.scope = mergedOptions.scope;
              }

              // store resource with correct commit method
              this._storeResource(context, _mutationType, commitPayload);
            };

            // Execute wrapped storing logic
            prepareAndStore(pendingPopulatedResource);

            // Trigger a callback if it is passed to instance
            onSuccess?.(resource, context);

            // if a populate array is provided, we trigger other actions to fetched data
            // in order to populate the current data. This is purely async and non-blocking
            if (populateSettings.length && !mergedOptions.preventPopulate) {
              populateResource(resource, populateSettings).then(
                // this replace the currently store data with the new updated one
                prepareAndStore
              );
            }

            // as it is an async function, return `resource` for chaining (.then or await)
            return mergedOptions.freeze ? Object.freeze(resource) : resource;
          }
        }
      } catch (error) {
        if (!(error instanceof Error)) {
          // Something was thrown that is not an error
          return new AppError(AppErrorCode.InternalError);
        }

        const isKnownError =
          error instanceof AppError || error instanceof RequestError;

        const errorCode = isKnownError ? error.code : null;
        const errorDetail =
          isKnownError && 'detail' in error ? error.detail : null;

        // show error in development for debug purposes
        if (import.meta.env.MODE === 'development') {
          const translatedMessage =
            error instanceof AppError
              ? t(error.message, error.translationArgs)
              : error.message;
          const statusMessage =
            error instanceof RequestError ? `(${error.status}) ` : '';
          console.error(
            `${storeTypeString} ${statusMessage}${translatedMessage?.trim()}\n`,
            errorCode || errorDetail
              ? {
                  ...(errorCode && { code: errorCode }),
                  ...(errorDetail && { detail: errorDetail }),
                }
              : '',
            error
          );
        }

        // forward error
        throw error;
      }
    };

    // for chaining module creation functions
    return this;
  }

  /**
   * Create an alias of an existing action with optionally different requestOptions
   * @param options - The alias action options
   */
  aliasAction<
    FromType extends Unprefixed<keyof StoreModuleActions<Types>>,
    ToType extends Unprefixed<keyof StoreModuleActions<Types>>,
    MethodT extends ExtractActionMethods<Types, FromType>,
    PrefixedFromType extends
      keyof StoreModuleActions<Types> = `${MethodT}_${FromType}` extends keyof StoreModuleActions<Types>
      ? `${MethodT}_${FromType}`
      : never,
    PrefixedToType extends
      keyof StoreModuleActions<Types> = `${MethodT}_${ToType}` extends keyof StoreModuleActions<Types>
      ? `${MethodT}_${ToType}`
      : never,
  >({
    fromType,
    toType,
    requestMethod,
    requestOptions = () => ({}),
  }: AliasActionOptions<Types, FromType, ToType, MethodT>): this {
    const _fromType = `${requestMethod}_${fromType}` as PrefixedFromType;
    const _toType = `${requestMethod}_${toType}` as PrefixedToType;

    this.module.actions[_toType] = (context, options) => {
      const _options = requestOptions(context) as typeof options;
      const mergedOptions = merge<typeof options>(_options, options);
      return this.module.actions[_fromType]?.(context, mergedOptions) as any;
    };
    // for chaining module creation functions
    return this;
  }

  // internal helpers
  private _prepareResourceDefault(
    scopes: readonly string[] | null,
    defaultValue: any
  ): any {
    const _defaultValue = getValue(defaultValue);
    const value = scopes
      ? // initialize object with { scope1: defaultValue, scope2: defaultValue, ... }
        Object.fromEntries(scopes.map(scope => [scope, _defaultValue]))
      : _defaultValue;
    return value;
  }

  private _storeResource(
    context: Context,
    type: keyof StoreModuleMutations<Types> & string,
    commitPayload: CommitPayload
  ): void {
    if (this._checkMutation(type)) {
      context.commit(type, commitPayload);
    }
  }

  private _storeLocally(state: AnyState, key: string): void {
    localStorage.setItem(
      key,
      typeof state[key] === 'object' ? JSON.stringify(state[key]) : state[key]
    );
  }

  private _checkMutation(
    type: keyof StoreModuleMutations<Types> & string
  ): boolean {
    if (this.module.mutations[type]) {
      return true;
    } else {
      const error = new Error(
        `Mutation '${type}' is undefined. Did you register it by calling 'createResource' ?`
      );
      console.error(error);
      throw error;
    }
  }
}
