import StoreModule from '@/store/core/store-module';
import { userEmailPopulateConfig } from '@/lib/helpers/populate';

import type { ApiSchema } from '#/core-api';
import type { Get } from '@/store/types/core-api';

declare global {
  /**
   * Populated Estimation
   */
  type Estimation = DeepMerge<ApiSchema['rental.Estimation'], { id: string }>;
}

export interface EstimationStoreTypes {
  ESTIMATION: [Get<'/trips/estimation/{ID}', Estimation>];
}

const estimationModule = new StoreModule<EstimationStoreTypes>();
estimationModule.createResourceAndAction({
  type: 'ESTIMATION',
  requestEndPoint: '/trips/estimation/:id',
  permission: 'gateway.trip.estimation.query',
  transform: data => data.estimation,
  populate: [userEmailPopulateConfig()],
});

export default estimationModule;
