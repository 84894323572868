/*
 * Exports i18n-iso-countries with registered locales
 */
import countries from 'i18n-iso-countries';
import countriesEn from 'i18n-iso-countries/langs/en.json';
import countriesFr from 'i18n-iso-countries/langs/fr.json';

import type { Alpha3Code } from 'i18n-iso-countries';

countries.registerLocale(countriesEn);
countries.registerLocale(countriesFr);

/**
 * export default countries module
 */
export default countries;

const countryNames = countries.getNames('en', { select: 'official' });
/**
 * export formatted country entries in the form `{ key: 'FRA (France)', value: 'FRA' }`
 */
export const countryEntries = Object.entries(countryNames).map(
  ([alpha2code, name]) => {
    const alpha3code = countries.alpha2ToAlpha3(alpha2code) as Alpha3Code;
    return {
      key: alpha3code + ' (' + name + ')',
      value: alpha3code,
    };
  }
);
