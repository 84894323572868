/* eslint-disable @typescript-eslint/no-explicit-any */
import StoreModule from '@/store/core/store-module';
import { datacenterConfig } from '@/config';

import type { ISODateString } from '@fifteen/sdk';

declare global {
  interface DatacenterHistoryResponse<T = any> {
    count: number;
    total: number;
    page: number;
    has_more: boolean;
    items: DatacenterHistoryItem<T>[];
  }

  interface DatacenterHistoryChange {
    field: string;
    old_value: any;
    new_value: any;
  }

  interface DatacenterHistoryAddition {
    field: string;
    new_value: any;
  }

  interface DatacenterHistoryDeletion {
    field: string;
    old_value: any;
  }

  interface DatacenterHistoryItem<T = any> {
    changed?: DatacenterHistoryChange[];
    added?: DatacenterHistoryAddition[];
    removed?: DatacenterHistoryDeletion[];
    timestamp: string;
    collected: T;
  }
}

type AnyGetConfig = {
  method: 'GET';
  payload: any;
  response: any;
  data: any;
};

export interface DatacenterBikeHistoryTypes {
  BIKE_HISTORY_DATA: [AnyGetConfig];
  BIKE_HISTORY_UPDATE_DATA: [AnyGetConfig];
}

const historyScopes = ['DataHistory', 'FieldHistory', 'Map'] as const;

export type DatacenterHistoryOrder = 'ASC' | 'DESC';

type HistoryGetConfig<T = any> = {
  method: 'GET';
  scopes: typeof historyScopes;
  payload: {
    id: string;
    params: {
      page?: number;
      limit?: number;
      from: ISODateString;
      to: ISODateString;
      mode: 'diff';
      order?: DatacenterHistoryOrder;
      fields: string[];
      collect?: string[];
    };
  };
  response: DatacenterHistoryResponse<T> | null;
  data: DatacenterHistoryResponse<T> | null;
};

export interface DatacenterHistoryStoreTypes {
  BIKES_HISTORY: [HistoryGetConfig];
  STATION_HISTORY: [HistoryGetConfig];
  FASTENER_HISTORY: [HistoryGetConfig];
  TRIP_HISTORY: [HistoryGetConfig];
  BOOKING_HISTORY: [HistoryGetConfig];
  USER_HISTORY: [HistoryGetConfig];
  ROLE_HISTORY: [HistoryGetConfig];
  AREA_HISTORY: [HistoryGetConfig];
  RENTAL_HISTORY: [HistoryGetConfig];
  OFFER_HISTORY: [HistoryGetConfig];
  BENEFIT_HISTORY: [HistoryGetConfig];
  TICKET_HISTORY: [HistoryGetConfig];
  ENTITY_HISTORY: [HistoryGetConfig];
}

export type HistoryStoreType = keyof DatacenterHistoryStoreTypes;
export type BaseStoreTypeWithHistory =
  HistoryStoreType extends `${infer S}_HISTORY` ? S : never;

export interface DatacenterStoreTypes
  extends DatacenterBikeHistoryTypes,
    DatacenterHistoryStoreTypes {}

const datacenterModule = new StoreModule<DatacenterStoreTypes>();
datacenterModule
  .createAction({
    type: 'BIKE_HISTORY_DATA',
    requestApiConfig: datacenterConfig,
    requestEndPoint: '/api/v1/bike_history',
    requestMethod: 'GET',
    longRequest: true,
    requestOptions: () => ({
      params: {
        action: 'load',
        hstart: '',
        hend: '',
        constraints: '',
        area: 'all',
      },
    }),
    transform: data => data.data,
  })
  .createAction({
    type: 'BIKE_HISTORY_UPDATE_DATA',
    requestApiConfig: datacenterConfig,
    requestEndPoint: '/api/v1/bike_history',
    requestMethod: 'GET',
    longRequest: true,
    requestOptions: () => ({
      params: {
        action: 'detail',
      },
    }),
  });

// Item history settings
const modelNames = [
  'bike',
  'station',
  'fastener',
  'trip',
  'booking',
  'user',
  'role',
  'area',
  'rental',
  'offer',
  'benefit',
  'offer_policy',
  'entity',
  'ticket',
] as const;

modelNames.forEach(modelName => {
  datacenterModule.createResourceAndAction({
    type: `${modelName.toUpperCase()}_HISTORY` as keyof DatacenterHistoryStoreTypes,
    scopes: historyScopes,
    requestApiConfig: datacenterConfig,
    requestEndPoint: '/api/v1/history/' + modelName + '/:id',
    permission: 'gateway.' + modelName + '.get_history',
    longRequest: true,
    requestOptions: () => ({
      timeout: datacenterConfig.historyDataTimeout,
    }),
  });
});

export default datacenterModule;
