<template lang="pug">
.ZDisplayField(:class="dataValueClasses")
  .ZDisplayField__prependSlots(v-if="item.prependSlot?.length")
    template(v-for="(entry, i) in item.prependSlot")
      component(
        :is="entry.component.name"
        v-if="entry.component"
        :key="`component-${i}`"
        v-bind="entry.component.props"
      ) {{ entry.component.content }}
  .ZDisplayField__itemWrapper(
    v-if="!isArrayValue(item.val)"
    :class="{ 'ZDisplayField__itemWrapper--contextMenu': addContextMenuClass }"
    @contextmenu="e => handleContextMenu(e, item)"
  )
    component.ZDisplayField__item(
      :is="rootRouterLinkObject ? 'router-link' : 'div'"
      v-if="showItem(item)"
      v-bind=`rootRouterLinkObject ? {
        depressed: true,
        round: true,
        small: !large,
      } : {}`
      :class=`{
        'ZDisplayField__item--chip ZDisplayField__item--link': rootRouterLinkObject,
        'ZDisplayField__item--flex': !rootRouterLinkObject,
        'ZDisplayField__item--lighter' : item.lighter || item.disabled
      }`
      :to="wrapRoute(rootRouterLinkObject)"
    )
      ZIconColorValue.ZDisplayField__value(
        :dense="dense"
        :icon="getItemProperty(item, 'icon')"
        :icon-slot="getItemProperty(item, 'iconSlot')"
        :opacity="item.opacity"
        :size="item.iconSize === 'small' ? 'small' : 'normal'"
        :color="getItemProperty(item, 'rounded') ? '' : getItemProperty(item, 'color')"
        :text-color="getItemProperty(item, 'textColor')"
        :loading="item.loading"
        :error="item.error"
        :empty="item.empty"
        :hint="item.hint"
        :value="!isNullish(item) && (item.val?.__data || item.val)"
        :no-max-width="noMaxWidth"
        :large-text="large"
        :badge="getItemProperty(item, 'badge')"
        :last-updated="lastUpdated || (item.isTimestamp && item.val)"
        :warning="item.warning"
        :meta-info="getItemProperty(item, 'metaInfo')"
        :bold-text="item.boldText"
        :outline-delay="!rootRouterLinkObject"
        :wrap="item.wrap"
        :unit="item.unit"
        ellipsis
      )
    template(v-else-if="showEmpty")
      .ZDisplayField__item--empty –

  //- array of values
  template(v-else)
    //- display array of objects in a sub table with headers as object keys
    template(v-if="item.subTable")
      VDataTable.ZDisplayField__subTable(
        :headers="subTableHeaders"
        :items="subTableFields"
        :hide-headers="!subTableHeaders || !subTableHeaders.length"
        hide-actions
        :no-data-text="''"
        :pagination.sync="pagination"
        :total-items="Infinity"
      )
        template(#items="{ item: slotItem, index }")
          tr
            template(v-if="item.subTable === 'rawlist'")
              td.ZDisplayField__subTableDataCell.ZDisplayField__subTableDataCell--noPadding(
                v-if="slotItem"
                :key="`${slotItem}-${index}`"
                nowrap
                :class="{ 'ZDisplayField__subTableDataCell--contextMenu': addContextMenuClass }"
                @contextmenu="e => handleContextMenu(e, item)"
                v-html="slotItem"
              )

            template(v-else-if="item.subTable === 'list'")
              td.ZDisplayField__subTableDataCell(
                v-if="slotItem"
                :key="`${slotItem}-${index}`"
                nowrap
              )
                ZDisplayField(
                  :item="slotItem"
                  no-wrap
                  no-max-width
                  @contextmenu="emit('contextmenu', $event)"
                )

            template(
              v-for="{ value: key } in subTableHeaders"
              v-else
            )
              td.ZDisplayField__subTableDataCell(
                :key="`${key}-${index}`"
                nowrap
              )
                ZDisplayField(
                  v-if="Array.isArray(slotItem)"
                  :item="slotItem.find(item => item.key === key)"
                  no-wrap
                  no-max-width
                )
                ZDisplayField(
                  v-else
                  :item="slotItem[key]"
                  no-wrap
                  no-max-width
                )

    //- display array of values in chips
    template(v-else)
      .ZDisplayField__array(
        :class=`{
          'ZDisplayField__array--noWrap': noWrap,
          'ZDisplayField__array--forceMultiline': forceMultiline || item.forceMultiline,
          'ZDisplayField__array--dataContextMenu': addContextMenuClass && typeof item.val[0] !== 'object'
        }`
      )
        template(v-for="(val, i) in (item.val ?? []).filter(val => val)")
          VHover(:key="`val-${i}`")
            template(#default="{ hover }")
              .ZDisplayField__item.ZDisplayField__item--flex(
                :ref="`val-${i}`"
                :class=`{
                  'ZDisplayField__item--lighter': (getItemValProperty(item, val, 'lighter', i)) || (getItemValProperty(item, val, 'disabled', i)),
                  'ZDisplayField__item--dataContextMenu': addContextMenuClass && typeof val === 'object'
                }`
                @contextmenu="e => handleContextMenu(e, typeof val === 'object' ? val : item)"
              )
                transition(name="z-fade")
                  .ZDisplayField__category(
                    v-if="val.category"
                    v-show="hover"
                  )
                    div {{ val.category }}
                .ZDisplayField__chip.ZDisplayField__chip--deeperKey(
                  v-if="val.key"
                  :small="!large"
                )
                  span(v-html="val.key")
                  span.ZDisplayField__chip__unit(v-if="val.unit") ({{ val.unit }})
                component.ZDisplayField__chip(
                  :is="getRouterLinkObject(val, i) ? 'router-link' : 'div'"
                  :key="`${val.val}-${i}`"
                  :small="!large"
                  :class=`{
                    'ZDisplayField__chip--link': !!getRouterLinkObject(val, i),
                    'ZDisplayField__chip--outline': !!getBorderColor(val),
                    'ZDisplayField__chip--deeperValue': val.key,
                    ['outline--' + getBorderColor(val)]: !!getBorderColor(val),
                  }`
                  :to="wrapRoute(getRouterLinkObject(val, i))"
                )
                  ZIconColorValue.ZDisplayField__value(
                    :dense="dense"
                    :size="dense ? 'extra-small' : getItemValProperty(item, val, 'iconSize', i) !== 'large' ? 'small' : 'normal'"
                    :icon="getItemValProperty(item, val, 'icon', i)"
                    :icon-slot="getItemValProperty(item, val, 'iconSlot', i)"
                    :opacity="getItemValProperty(item, val, 'opacity', i)"
                    :color="getItemValProperty(item, val, 'color', i)"
                    :text-color="getItemValProperty(item, val, 'textColor', i) ?? ''"
                    :loading="item.loading"
                    :error="item.error"
                    :empty="item.empty"
                    :hint="getItemValProperty(item, val, 'hint', i)"
                    :value="getValueOf(val)"
                    :large-text="large"
                    :badge="getItemValProperty(item, val, 'badge', i)"
                    :last-updated="lastUpdated || val.lastUpdated || ((item.isTimestamp || val.isTimestamp) && getValueOf(val))"
                    :warning="item.warning ?? val.warning"
                    :meta-info="getItemValProperty(item, val, 'metaInfo', i) ?? ''"
                    :bold-text="getItemValProperty(item, val, 'boldText', i)"
                    :wrap="item.wrap"
                    :unit="item.unit"
                  )
  ZListMenu.ZDisplayField__contextualMenu(
    v-if="item.menuItems?.length"
    :items="item.menuItems"
    dense
    offset-y
    menu-left
  )

  template(v-if="item.slot")
    VSpacer(v-if="item.slotSpacer")
    .ZDisplayField__slot(
      :class="{ 'ZDisplayField__slot--flexWrap': item.wrapSlots }"
    )
      template(v-for="(entry, i) in item.slot")
        VSpacer(
          v-if="entry.spacer"
          :key="`spacer-${i}`"
        )
        Component(
          :is="entry.component.name"
          v-if="entry.component"
          :key="`component-${i}`"
          :class="entry.component.class"
          v-bind="entry.component.props"
          v-on="entry.component.listeners"
        ) {{ entry.component.content }}
</template>

<style lang="stylus">
.ZDisplayField__subTableDataCell--noPadding
  padding 2px !important

.ZDisplayField__slot--flexWrap
  flex-wrap wrap

.ZDisplayField
  display flex
  align-items center
  margin-left -3px
  height auto
  position relative

  &.column
    .ZDisplayField__array
      flex-direction column
      align-items flex-start

.ZDisplayField__itemWrapper--contextMenu,
.ZDisplayField__subTableDataCell--contextMenu,
.ZDisplayField__array--dataContextMenu,
.ZDisplayField__item--dataContextMenu
  data-context-menu()

  .ZDisplayField__value
    padding-right 3px

  &.ZDisplayField__item--flex
    padding 0

  .v-table__overflow
    overflow visible

.ZDisplayField:not(.disable-select)
  .ZDisplayField__chip
    user-select text

.ZDisplayField--rounded
  justify-content center
  width 24px
  height 24px
  border-radius 12px

  .ZDisplayField__value
    padding-left 0

.ZDisplayField__item--flex
  display flex
  position relative

.ZDisplayField__item--lighter
  opacity 0.4

.ZDisplayField__item--empty
  margin-left 3px

.ZDisplayField--inline
  display inline

.ZDisplayField--inlineFlex
  display inline-flex

.ZDisplayField--inlineBlock
  display inline-block

.ZDisplayField--block
  display block

.ZDisplayField--pre
  white-space pre

.ZDisplayField--fullWidth
  margin-right 3px
  width 100%

.ZDisplayField--subTableList
  width 100%

  .ZDisplayField__subTable
    width 100%

.ZDisplayField__subTable
  table.v-datatable .ZDisplayField
    min-height 27px

  .v-table__overflow
    overflow-x hidden

.ZDisplayField__array
  display flex
  flex-wrap wrap
  align-items center

  &.ZDisplayField__array--noWrap
    flex-wrap nowrap

  &.ZDisplayField__array--forceMultiline
    align-items flex-start
    flex-direction column

  .ZDisplayField__chip
    margin-left 2px
    margin-right 2px

  .ZDisplayField__item--flex
    .ZDisplayField__chip,
    .ZDisplayField__item--chip
      margin-left 2px
      margin-right 2px

    &.ZDisplayField__item--dataContextMenu
      padding 0

  .ZDisplayField__chip--deeperKey
    z-index 2
    margin-right 0 !important
    padding-right 8px
    height 24px
    border-top-right-radius 0
    border-bottom-right-radius 0
    display flex
    gap 2px

  .ZDisplayField__chip--deeperValue:not(:last-child)
    padding-right 8px
    border-right 1px solid rgba(0, 0, 0, 0.12)
    border-radius 0

  .ZDisplayField__chip--deeperValue
    padding-left 8px
    margin-left 0 !important
    border-top-left-radius 0
    border-bottom-left-radius 0

  .ZIconColorValue
    .ZBadge .v-badge .v-badge__badge
      margin-left 4px

.ZDisplayField__item--chip,
.ZDisplayField__chip
  border-radius 16px
  height 24px
  display flex
  margin 2px 0
  padding 0 12px
  min-width auto
  text-transform none
  font-weight normal
  font-size 13px
  white-space nowrap
  align-items center

  &.ZDisplayField__chip--outline
    background-color transparent !important

  .ZIconColorValue__icon
    margin-left -2px

  .ZDisplayField__value
    padding-left 0

  &.ZDisplayField__chip--link,
  &.ZDisplayField__item--link
    text-decoration none
    box-sizing border-box

    &:focus,
    &:hover,
    &:active,
    &:visited
      outline none

.ZDisplayField--dense
  .ZDisplayField__chip
    margin-right 2px
    margin-left 1px
    padding 0 10px
    height 24px

.ZDisplayField--itemWrap
  .ZDisplayField__chip
    height auto

.ZDisplayField__slot
  display flex
  flex 0 0 auto
  max-width 100%
  margin-left 2px

.ZDisplayField__prependSlots
  margin-right 8px

.ZDisplayField__category
  position absolute
  top 2px
  left 0
  right 0
  bottom 0
  display flex
  align-items center
  justify-content center
  font-size 13px
  z-index 3
  border-radius 12px
  height 24px
  font-style italic

.theme--light
  .ZDisplayField__category
    background-color: $colors.grey.lighten-2

  .ZDisplayField__chip,
  .ZDisplayField__item--chip
    color rgba(0, 0, 0, 0.87)
    background-color rgba(0, 0, 0, 0.06)

    &.ZDisplayField__chip--deeperKey
      color rgba(0, 0, 0, 0.87)
      background-color: $colors.grey.lighten-2

  .ZDisplayField__chip--link,
  .ZDisplayField__item--link
    $shadow-color = $colors.grey.lighten-2
    background: $colors.grey.lighten-5
    box-shadow inset 0 0 0 1px $shadow-color
    color: $colors.grey.darken-4

    &:hover,
    &:focus
      background-color alpha($color-info, 0.04)

.theme--dark
  .ZDisplayField__category
    background-color darken($colors.grey.darken-2, 12%)

  .ZDisplayField__chip,
  .ZDisplayField__item--chip
    color rgba(255, 255, 255, 0.87)
    background-color rgba(255, 255, 255, 0.1)

    &.ZDisplayField__chip--deeperKey
      color rgba(255, 255, 255, 0.87)
      background-color rgba(125, 125, 125, 0.16)

  .ZDisplayField__chip--link,
  .ZDisplayField__item--link
    box-shadow inset 0 0 0 1px rgba(255, 255, 255, 0.2)

    &:hover,
    &:focus
      background-color rgba(255, 255, 255, 0.2)

.ZDisplayField__subTable
  .ZDisplayField__value
    padding-left 0

  table
    &.theme--light,
    &.theme--dark
      &.v-table
        tbody,
        thead
          tr:hover:not(.v-datatable__expand-row)
            background transparent

  table.v-table
    background-color transparent

    thead,
    tbody
      td
        white-space pre

      tr,
      td,
      th
        height auto

        &:first-child,
        &:last-child
          padding 6px

        &:not(:nth-child(1))
          padding 6px 6px 6px 12px

  hr
    height 0
    border-top 1px dashed currentColor
    border-bottom 0
    opacity 0.15
    margin 4px 0

.ZDisplayField__contextualMenu
  margin-left -6px
</style>

<script setup lang="ts">
import { useDataValueLink } from './composables/useDataValueLink';

import { useWrapRoute } from '@/composables';
import { isNullish } from '@/lib/utils';
import { mapDisplayFieldsToVDataTableRow } from '@/lib/helpers/tables';

import ZIconColorValue from '@/components/ui/molecules/ZIconColorValue.vue';
import ZListMenu from '@/components/ui/molecules/ZListMenu.vue';

interface ZDisplayFieldProps {
  /**
   * Display field
   */
  item?: DisplayField;
  /**
   * Display the data value larger
   */
  large?: boolean;
  /**
   * Display the data value more densely
   */
  dense?: boolean;
  /**
   * Prevent the data value from wrapping
   */
  noWrap?: boolean;
  /**
   * Force the data value to wrap
   */
  forceMultiline?: boolean;
  /**
   * Remove the max-width from the data value
   */
  noMaxWidth?: boolean;
  /**
   * Hide the last updated timestamp
   */
  hideLastUpdated?: boolean;
  /**
   * Disable the data value selection
   */
  disableSelect?: boolean;
  /**
   * Display the data value as a inline
   */
  inline?: boolean;
  /**
   * Display the data value as a inline flex
   */
  inlineFlex?: boolean;
  /**
   * Display the data value as a block
   */
  block?: boolean;
  /**
   * Display the data value as a pre
   */
  pre?: boolean;
  /**
   * Whether to show empty values
   */
  showEmpty?: boolean;
}

const props = withDefaults(defineProps<ZDisplayFieldProps>(), {
  item: () => ({ key: '', val: null }),
});

const emit = defineEmits<{
  /**
   * Emit the item on which the right click has been made
   */
  (name: 'contextmenu', item: DisplayField): void;
}>();

const { wrapRoute } = useWrapRoute();

interface Pagination {
  sortBy: string;
  descending: boolean;
}
const pagination = ref<Pagination>();

const instance = getCurrentInstance();

const withContextMenu = computed(() => {
  // In Vue 3, use `useAttrs` composable instead.
  const listeners = instance?.proxy.$listeners ?? {};
  return !props.item.noHistory && listeners?.contextmenu;
});

const dataValueClasses = computed(() => {
  return {
    'ZDisplayField--pre': props.pre,
    'ZDisplayField--dense': props.dense,
    'ZDisplayField--inline': props.inline,
    'ZDisplayField--inlineBlock': props.inline && props.block,
    'ZDisplayField--block': props.block,
    'ZDisplayField--inlineFlex': props.inlineFlex,
    'ZDisplayField--fullWidth': props.item.slotSpacer,
    'ZDisplayField--subTableList':
      props.item.subTable === 'rawlist' || props.item.subTable === 'list',
    'ZDisplayField--rounded': props.item.rounded,
    'ZDisplayField--itemWrap': props.item.wrap,
    // Global class
    ['disable-select']: props.disableSelect,
  };
});

const lastUpdated = computed(() => {
  if (props.hideLastUpdated) return '';
  else return props.item.lastUpdated;
});

const subTableHeaders = computed(() => {
  if (!props.item.subTable) return;
  if (props.item.subTable === 'rawlist' || props.item.subTable === 'list') {
    return;
  }

  if (props.item.subTableHeaders) return props.item.subTableHeaders;

  const headerKeys = props.item.val?.map(mapDisplayFieldsToVDataTableRow)[0];
  if (headerKeys) {
    return Object.keys(headerKeys).map(key => ({
      text: key,
      value: key,
      sortable: false,
    }));
  }
});

const addContextMenuClass = computed(() => {
  return (
    withContextMenu.value &&
    !props.item.populated &&
    !props.item.loading &&
    !props.item.error
  );
});

const subTableFields = computed(() => {
  if (!Array.isArray(props.item.val)) return props.item.val;

  if (!pagination.value?.sortBy) return props.item.val;
  return props.item.val
    .map(mapDisplayFieldsToVDataTableRow)
    .sort((itemA, itemB) => {
      const order = pagination.value?.descending ? 1 : -1;

      const valA = (itemA?.[pagination.value?.sortBy ?? ''] as DisplayField)
        ?.val;
      const valB = (itemB?.[pagination.value?.sortBy ?? ''] as DisplayField)
        ?.val;
      if (valA < valB) return order;
      else if (valA > valB) return -1 * order;
      else return 0;
    });
});

const { getRouterLinkObject, rootRouterLinkObject } = useDataValueLink(
  () => props.item
);

function showItem(item?: DisplayField): boolean {
  if (isNullish(item)) return false;

  const isDisabled = Array.isArray(item.val)
    ? item.val.every(val => val?.disabled)
    : item.disabled;

  const hasValue =
    'val' in item && Array.isArray(item.val)
      ? item.val.some(val => val?.val !== '')
      : item.val !== '';

  return !item.loading && !isDisabled && !item.error && hasValue;
}

function getValueOf(val: DisplayField['val']): DisplayField | string {
  if (!val) return '';
  if (!isNullish(val.val)) {
    return val.val;
  }

  if (val?.__data) return val.__data;
  if (val.__originalData) return val.__originalData;

  return val;
}

function getBorderColor(val: DisplayField['val']): Color | Color[] {
  return props.item.borderColor || val.borderColor;
}

type ExtractBaseType<T> = T extends (infer U)[] | infer U | null ? U : never;

function getItemValProperty<K extends keyof DisplayField>(
  item: DisplayField,
  val: DisplayField['val'],
  property: K,
  index: number
): ExtractBaseType<DisplayField[K]> {
  return item[property]?.[index] ?? val[property];
}

function getItemProperty<K extends keyof DisplayField>(
  item: DisplayField,
  property: K
): ExtractBaseType<DisplayField[K]> {
  return item[property];
}

function handleContextMenu(event: Event, item: DisplayField): void {
  if (!withContextMenu.value) return;
  event.preventDefault();

  if (item.populated || item.loading || item.error) return;
  emit('contextmenu', item);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isArrayValue(val: DisplayField['val']): val is any[] {
  return Array.isArray(val) && !(val as I18nArgs).__i18nArgs__;
}
</script>
