import { createSearchRegexString } from '@fifteen/shared-lib';

/**
 * Generates a search regex string from the input string
 * @param input - Input string
 */
export default function generateSearchRegexString(input: string): string {
  return (
    '(' +
    input
      .trim()
      .split(/\s+/) // search on each word
      .filter(word => word) // remove empty words if any
      .map(word => createSearchRegexString(word))
      .join('|') +
    ')'
  );
}
