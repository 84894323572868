<template lang="pug">
ZInputPrice.ZActionControlInputPrice(
  v-show="canShow"
  ref="componentRef"
  v-model="modelValue"
  :style="style"
  :label="label"
  :rules="rules"
  :color="color"
  :disabled="isDisabled"
  :hint="hint"
  :placeholder="placeholder"
  :mask="mask"
  :messages="message"
  :disable-currency-edition="control.disableCurrencyEdition"
  :required="isRequired"
  :hide-details="areDetailsHidden"
  v-bind="additionalProps"
)
</template>

<script setup lang="ts">
import { useActionControl } from './useActionControl';

import ZInputPrice from '@/components/ui/molecules/ZInputPrice.vue';

import type { Price } from '@/components/ui/molecules/ZInputPrice.vue';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Data = any;

interface ZActionControlInputPriceProps {
  /**
   * The control object
   */
  control: ActionControl<'price', keyof Data, Data>;
  /**
   * The parent action 'data' object
   */
  data: Data;
  /**
   * The control’s value
   * @model
   */
  value?: Price;
}

const props = withDefaults(defineProps<ZActionControlInputPriceProps>(), {
  value: undefined,
});

const {
  modelValue,
  generate,
  canShow,
  style,
  label,
  rules,
  color,
  isDisabled,
  hint,
  placeholder,
  mask,
  message,
  areDetailsHidden,
  additionalProps,
} = useActionControl(props);

const componentRef = ref<TemplateRef | null>(null);

const isRequired = computed(() => generate(props.control.required));

function focus(): void {
  componentRef.value?.focus?.();
}

function resetValidation(): void {
  componentRef.value?.resetValidation?.();
}

defineExpose({
  /**
   * Focus the input
   */
  focus,
  /**
   * Reset input validation
   */
  resetValidation,
});
</script>
