<template lang="pug">
component.ZChangeDomain(
  :is="listItem ? 'VListTile' : 'VBtn'"
  :href="routerUrl"
  v-bind="listItem ? {} : btnProps"
  @click.prevent="changeDomain"
)
  template(v-if="listItem")
    VListTileAction
      ZIcon {{ icon }}
    VListTileContent
      VListTileTitle {{ t('menu__domain') }}
  template(v-else)
    span {{ t('menu__domain') }}
    ZIcon.ZChangeDomain__icon(:small="btnProps.small") {{ icon }}
</template>

<style lang="stylus">
.ZChangeDomain__icon
  margin-left 8px
</style>

<script setup lang="ts">
import { useI18n } from '@/composables/plugins';

export interface ZChangeDomainProps {
  /**
   * If true, the component activator will be rendered as a list item instead of a button
   */
  listItem?: boolean;
  /**
   * Props for the button
   */
  btnProps?: Record<string, unknown>;
}

withDefaults(defineProps<ZChangeDomainProps>(), {
  listItem: false,
  btnProps: () => ({}),
});

const { t } = useI18n();

const icon = 'svg:admin_router_logo';
const routerUrl = window.VUE_APP_ROUTER_URL;

function changeDomain(): void {
  window.location.assign(routerUrl);
}
</script>
