<template lang="pug">
VToolbar.ZInputFieldBlock(:flat="flat")
  VTextField(
    ref="textFieldRef"
    v-model="internalValue"
    :type="type ?? 'text'"
    :label="label"
    :rules="rules"
    v-bind="attributes"
    hide-details
    solo
    clearable
    @keypress.enter="emitInput"
  )
  .ZInputFieldBlock__button
    VBtn(
      :color="color"
      :disabled="disabled"
      @click="emitInput"
    ) {{ enterText || t('app__ok') }}
</template>

<style lang="stylus">
.ZInputFieldBlock
  .v-text-field.v-text-field--solo .v-input__control
    min-height 36px

    .v-input__slot
      box-shadow none
      border-radius 8px

.ZInputFieldBlock__button
  margin-left 8px
</style>

<script setup lang="ts" generic="TypeT extends 'number' | 'text' = 'text'">
import { useI18n } from '@/composables/plugins';

type Value = TypeT extends 'number'
  ? number
  : TypeT extends 'text'
  ? string
  : never;

interface ZInputFieldToolbarProps {
  /**
   * The value of the input field.
   */
  value?: Value;
  /**
   * The type of the input field.
   */
  type?: TypeT;
  /**
   * The label of the input field.
   */
  label?: string;
  /**
   * The text to display on the enter button.
   */
  enterText?: string;
  /**
   * The color of the enter button.
   */
  color?: string;
  /**
   * Whether the toolbar is flat.
   */
  flat?: boolean;
  /**
   * The validation rules for the input field.
   */
  rules?: VuetifyRule[];
}

const props = withDefaults(defineProps<ZInputFieldToolbarProps>(), {
  value: undefined,
  type: undefined,
  label: undefined,
  enterText: undefined,
  color: 'primary',
  flat: false,
  rules: () => [],
});

const emit = defineEmits<{
  (name: 'input', value?: Value): void;
}>();

const { t } = useI18n();

const textFieldRef = ref<TemplateRef | null>(null);
const internalValue = ref<Value>();

watchImmediate(
  () => props.value,
  value => (internalValue.value = value)
);

const disabled = computed(() => {
  return props.rules.reduce(
    (isInvalid, rule) => isInvalid || rule(internalValue.value) !== true,
    false
  );
});

const attributes = computed(() =>
  props.type === 'number' ? { pattern: '\\d*' } : {}
);

function emitInput(): void {
  emit('input', internalValue.value);
}

/**
 * Clear the input field, useful for resetting the internal value when it has not been applied.
 */
function clear(): void {
  internalValue.value = undefined;
  // Reset validation to avoid showing any error
  textFieldRef.value?.reset();
}

defineExpose({
  clear,
});
</script>
