/**
 * Return the path on app’s Map with given coordinates
 * @param coordinates - Coordinates array
 */
export function getMapLatLngPath(
  coordinates: Coordinates,
  floatPrecision = 7
): string {
  return (
    '/map/lnglat/' +
    coordinates
      .map(
        coordinate =>
          Math.floor(coordinate * 10 ** floatPrecision) / 10 ** floatPrecision
      )
      .join(',')
      .replace(/\./g, '_')
  );
}
