<template lang="pug">
.ZOptionsButton(:class="{ 'ZOptionsButton--light': light }")
  VBtn.ZOptionsButton__btn.ZOptionsButton__btn--main(
    :color="color"
    :disabled="disabled"
    :loading="loading"
    :light="light"
    @click="emit('click', value)"
  ) {{ (items && items.length && items[value].shortText) || '' }}
    ZIcon(
      v-if="items && items.length && items[value].icon"
      right
    ) {{ items[value].icon }}
  VMenu(
    bottom
    left
    offset-y
    nudge-bottom="4px"
  )
    template(#activator)
      VBtn.ZOptionsButton__btn.ZOptionsButton__btn--select(
        :color="color"
        :disabled="loading || disabled"
        :light="light"
      )
        VIcon mdi-menu-down

        .ZOptionsButton__btn__hint(v-if="hint")
          ZToggleableHint
            span(v-html="hint")
    VCard.ZOptionsButton__menu
      VList
        template(v-for="(item, index) in items")
          VListTile(
            v-if="!trigger || index !== value"
            :key="'option-' + index"
            @click="clickItem(index)"
          )
            VListTileContent
              VListTileTitle
                VIcon.ZOptionsButton__menu__icon(
                  :color="value === index ? color : ''"
                  :style="{ opacity: ~~(!!item.icon || value === index) }"
                ) {{ value === index ? 'mdi-check' : item.icon || 'mdi-circle' }}
                span {{ item.shortText }}
              VListTileSubTitle.ZOptionsButton__menu__subTitle {{ item.text }}
</template>

<style lang="stylus">
.ZOptionsButton
  margin 8px
  display inline-flex

  .ZOptionsButton__btn
    margin 0

    &.ZOptionsButton__btn--main
      margin-right 0
      border-top-right-radius 0 !important
      border-bottom-right-radius 0 !important
      border-right 1px solid alpha(#fff, 25%) !important
      z-index 1

    &.ZOptionsButton__btn--select
      margin-left 0
      border-top-left-radius 0 !important
      border-bottom-left-radius 0 !important
      min-width 0
      padding-left 8px
      padding-right 8px

  &.ZOptionsButton--light
    .ZOptionsButton__btn.ZOptionsButton__btn--main
      border-right 1px solid alpha(#000, 20%) !important

  .v-btn__content
    position static

  .v-menu__content
    box-shadow none
    border-radius 16px

.ZOptionsButton__menu
  border-radius 16px

  .v-list__tile
    padding-left 8px

  .v-list__tile__title
    display flex
    align-items center

.ZOptionsButton__menu__icon
  margin 0 4px

.ZOptionsButton__menu__subTitle
  padding-left 16px
  margin-left 8px

.ZOptionsButton__btn__hint
  position absolute
  top -10px
  right -10px
</style>

<script setup lang="ts">
interface OptionsButtonItem {
  shortText?: string;
  text?: string;
  icon?: string;
}

export interface ZOptionsButtonProps {
  /**
   * The color of the button.
   */
  color?: string;
  /**
   * Whether the button is light.
   */
  light?: boolean;
  /**
   * Whether the button is loading.
   */
  loading?: boolean;
  /**
   * Whether the button is disabled.
   */
  disabled?: boolean;
  /**
   * The items to display in the dropdown.
   */
  items?: OptionsButtonItem[];
  /**
   * The currently selected value.
   */
  value?: number;
  /**
   * Whether to trigger the click event instead of the input event.
   */
  trigger?: boolean;
  /**
   * The hint to display next to the button.
   */
  hint?: string;
}

const props = withDefaults(defineProps<ZOptionsButtonProps>(), {
  color: '',
  disabled: false,
  items: () => [],
  value: 0,
  trigger: false,
  hint: '',
});

const emit = defineEmits<{
  (name: 'click', value: number): void;
  (name: 'input', value: number): void;
}>();

function clickItem(index: number): void {
  if (props.trigger) {
    return emit('click', index);
  }

  emit('input', index);
}
</script>
