<template lang="pug">
VCombobox.ZActionControlCombobox(
  v-show="canShow"
  ref="componentRef"
  v-model="modelValue"
  :style="style"
  :label="label"
  :rules="rules"
  :color="color"
  :disabled="isDisabled"
  :hint="comboboxHint"
  :placeholder="placeholder"
  :mask="mask"
  :messages="message"
  :hide-details="areDetailsHidden"
  :menu-props="{ maxHeight: 310, contentClass: 'ZActionControlCombobox__menu' }"
  :items="items"
  :item-text="control.itemText"
  :item-value="control.itemValue"
  :append-icon="items?.length ? vuetify.icons.dropdown : ''"
  :multiple="control.multiple"
  :return-object="control.returnObject"
  hide-selected
  small-chips
  dense
  v-bind="additionalProps"
  @keypress.enter="emit('enter', $event)"
)
  template(
    v-if="control.withCreateNewItemsHelper"
    #append-outer
  )
    ZTooltip(bottom)
      template(#activator)
        VBtn.ZActionControlCombobox__button(
          icon
          :color="color"
          @click="openCreateNewItemsDialog"
        )
          VIcon mdi-plus
      span {{ tin(control.withCreateNewItemsHelper?.tooltip || control.withCreateNewItemsHelper?.label || '') }}
    VDialog(
      v-model="isCreateNewItemsDialogOpen"
      :max-width="(dialogMaxWidth ?? 0) + 48"
    )
      VCard
        VCardText
          VTextField(
            v-if="!control.multiple"
            ref="createNewItemsInputRef"
            v-model="createNewItemsValue"
            :label="tin(control.withCreateNewItemsHelper?.label ?? '')"
            :rules="isCreateNewItemsDialogOpen ? createNewItemsInputRules : []"
          )
          VTextarea(
            v-else
            ref="createNewItemsInputRef"
            v-model="createNewItemsValue"
            :label="tin(control.withCreateNewItemsHelper?.label ?? '') + t('action__tags_label')"
            :rules="isCreateNewItemsDialogOpen ? createNewItemsInputRules : []"
            :hint="!hasTouch ? t('action__textarea_hint') : ''"
          )
        VCardActions.ZAction__dialog__actions
          VSpacer
          VBtn(
            flat
            color="error"
            @click="cancelCreateNewItems"
          ) {{ t('action__cancel') }}
          VBtn(
            color="primary"
            @click="confirmCreateNewItems"
          ) OK
  template(#selection="{ item }")
    VChip(small)
      span {{ item }}
      sup.ZActionControlCombobox__new(
        v-if="!items.includes(item)"
        :class="color + '--text'"
      ) {{ t('action__new') }}
</template>

<style lang="stylus">
.ZActionControlCombobox__button
  margin 0

.ZActionControlCombobox__new
  margin-left 2px
  margin-right -4px

themed-autocomplete-menu('.ZActionControlCombobox__menu')
</style>

<script setup lang="ts">
import { useActionControl } from './useActionControl';

import { useKeyboard } from '@/composables';
import { useI18n, useVuetify } from '@/composables/plugins';
import { makeMultipleRule } from '@/lib/helpers/rules';
import { check } from '@/lib/utils';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Data = any;

interface ZActionControlComboboxProps {
  /**
   * The control object
   */
  control: ActionControl<'combobox', keyof Data, Data>;
  /**
   * The parent action 'data' object
   */
  data: Data;
  /**
   * The control’s value.
   * Its model is currently `any` due to Vue 2 errors when receiving "null" values. TODO: fix in Vue 3
   * @model
   */
  value?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  /**
   * The max with of the "create new items" dialog
   */
  dialogMaxWidth?: number;
}

const props = withDefaults(defineProps<ZActionControlComboboxProps>(), {
  value: undefined,
  dialogMaxWidth: 420,
});

const emit = defineEmits<{
  /**
   * Emitted when the 'enter' key is pressed.
   */
  (name: 'enter', event: KeyboardEvent): void;
}>();

const { t, tin } = useI18n();
const vuetify = useVuetify();
const hasTouch = check.hasTouch();

const {
  modelValue,
  generate,
  canShow,
  style,
  label,
  rules,
  color,
  isDisabled,
  hint,
  placeholder,
  mask,
  message,
  areDetailsHidden,
  additionalProps,
} = useActionControl(props);

const componentRef = ref<TemplateRef | null>(null);

const comboboxHint = computed(
  () => hint.value || (!hasTouch ? t('action__tags_hint') : '')
);
const items = computed(() => generate(props.control.items ?? []));

const createNewItemsInputRef = ref<TemplateRef | null>(null);

const initialControlValue = ref<object>();
const isCreateNewItemsDialogOpen = ref(false);
const createNewItemsValue = ref('');

/**
 * Open the "create new items" dialog
 */
function openCreateNewItemsDialog(): void {
  initialControlValue.value = modelValue.value;
  if (!props.control.multiple) modelValue.value = '';
  isCreateNewItemsDialogOpen.value = true;
  componentRef.value?.blur?.();
  nextTick(() => {
    createNewItemsInputRef.value?.focus?.();
  });
}

/**
 * Cancel the creation of new items. It resets the control value
 */
function cancelCreateNewItems(): void {
  isCreateNewItemsDialogOpen.value = false;
  modelValue.value = initialControlValue.value;
  createNewItemsValue.value = '';
}

/**
 * Confirm the creation of new items. It updates the control value with the new items
 */
function confirmCreateNewItems(): void {
  let controlValue = modelValue.value;
  if (!props.control.multiple) {
    controlValue = createNewItemsValue.value;
  } else {
    const values = createNewItemsValue.value
      .split('\n')
      .map(v => v.trim())
      .filter(v => !!v);
    controlValue = [...new Set(controlValue.concat(values))];
  }
  modelValue.value = controlValue;
  isCreateNewItemsDialogOpen.value = false;
  createNewItemsValue.value = '';
}

const createNewItemsInputRules = computed<VuetifyRule[]>(() => {
  const _rules: VuetifyRule[] = [
    (v: string | object) =>
      !generate(props.control.items)?.includes(v) ||
      t('action__already_exists'),
    ...generate(props.control.rules ?? []),
  ];
  return props.control.multiple
    ? _rules.map(rule => makeMultipleRule(rule))
    : _rules;
});

useKeyboard({
  onKeyup: handleKeyup,
});
/**
 * Keyup event handler. Validates "new items" dialog when Enter is pressed,
 * or cancels the "new items" creation if open when Escape is pressed
 * @param event - The keyboard event
 */
function handleKeyup(
  event: KeyboardEvent,
  pressedKeys: Map<string, boolean>
): void {
  if (pressedKeys.get('Shift')) return;
  if (event.key === 'Enter') {
    // If the "new items" dialog is open, confirm
    if (isCreateNewItemsDialogOpen.value) {
      return confirmCreateNewItems();
    }
  } else if (event.key === 'Escape') {
    // If the "new items" dialog is open, cancel
    if (isCreateNewItemsDialogOpen.value) {
      return cancelCreateNewItems();
    }
  }
}

function focus(): void {
  componentRef.value?.focus?.();
}

function resetValidation(): void {
  componentRef.value?.resetValidation?.();
}

defineExpose({
  /**
   * Focus the input
   */
  focus,
  /**
   * Reset input validation
   */
  resetValidation,
});
</script>
