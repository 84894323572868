<template lang="pug">
VDialog(
  v-model="isOpen"
  content-class="ZFieldHistory"
)
  VCard
    VToolbar(dense)
      .ZFieldHistory__field {{ field }}
      VSpacer
      span {{ t('focused_history__updates_last_period', { total, period: periodText }) }}
      ZTooltip(bottom)
        template(#activator)
          VBtn(
            v-if="viewInHistoryRoute"
            :to="viewInHistoryRoute.fullPath"
            icon
            left
            @click="isOpen = false"
          )
            VIcon mdi-open-in-app
        span {{ t('focused_history__view_in_history') }}
      VBtn.ZFieldHistory__closeButton(
        icon
        @click="isOpen = false"
      )
        VIcon(small) mdi-close
    VCardText.ZFieldHistory__loaderWrapper(v-if="loading")
      div {{ t('focused_history__loading_updates_for_last_period', { period: periodText }) }}
      VProgressCircular.ZFieldHistory__loader(
        indeterminate
        :size="22"
        :width="2"
      )
    ZScrollable.ZFieldHistory__table(relative)
      ZDataHistoryTable(
        v-if="!loading && modelName && items.length"
        :model-name="modelName"
        :loading="loading"
        :error="fetchError"
        :page-size="limit"
        :hide-fields="!hasMultipleEntries"
        :items="items"
        :prefix="field"
      )
    VCardText.ZFieldHistory__noData(v-if="!loading && !items.length")
      div {{ t('focused_history__no_updates_for_last_period', { period: periodText }) }}
      VBtn.ZFieldHistory__addPeriod(@click="addPeriod")
        VIcon mdi-plus
        span {{ basePeriodText }}
</template>

<style lang="stylus">
.ZFieldHistory
  width fit-content
  overflow hidden
  min-width 224px

  .v-btn.ZFieldHistory__closeButton
    margin-right -8px

scrollbar-theme('.ZFieldHistory')

.ZFieldHistory__field
  border-radius 16px
  height 32px
  background-color rgba(128, 128, 128, 0.25)
  display flex
  align-items center
  padding 0 16px
  margin-right 24px
  margin-left -8px !important

.ZFieldHistory__table
  max-height calc(100vh - 96px)

.ZFieldHistory__noData,
.ZFieldHistory__loaderWrapper
  text-align center

.ZFieldHistory__addPeriod
  margin-bottom 0

.ZFieldHistory__loader
  margin-top 16px
  margin-bottom 4px
</style>

<script setup lang="ts">
import { useI18n } from '@/composables/plugins';
import { useFormatDuration, useVModelProxy, useAlert } from '@/composables';
import { join } from '@/lib/utils';
import store from '@/store';

import ZDataHistoryTable from '@/components/domain/model/ZDataHistoryTable.vue';

import type { HistoryRequestSettings } from '@/composables/useFieldHistory';
import type { HistoryStoreType } from '@/store/modules/datacenter';

export interface ZFieldHistoryProps {
  /**
   * The hide / show state
   * @model
   */
  value: boolean;
  /**
   * The data item for which we want to query history
   */
  item?: DisplayField | null;
  /**
   * The model name
   */
  modelName: ModelName;
  /**
   * Settings to perform request to datacenter history API
   */
  requestSettings: HistoryRequestSettings;
}

const props = withDefaults(defineProps<ZFieldHistoryProps>(), {
  item: null,
});

defineEmits<{
  (name: 'input'): void;
}>();

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const alert = useAlert();

const actions = ['added', 'removed', 'changed'] as const;
const basePeriod = 10 * 60 * 1000; // 10 minutes in ms
const { formattedDuration: formattedBasePeriod } = useFormatDuration(
  basePeriod / 1000,
  { rounded: true }
);
const basePeriodText = computed(() => formattedBasePeriod.value.text);

const isOpen = useVModelProxy({ props });
const period = ref(basePeriod);
const fetchError = ref(false);
const loading = ref(false);
const limit = 10;

const selectedId = computed(() => props.requestSettings.selectedId);
const storeType = computed(() => props.requestSettings.storeType);
const keyRef = computed(() => props.item?.ref);
const key = computed(() => props.item?.key);
const field = computed(() => join(keyRef.value, key.value));
const historyStoreType = computed<HistoryStoreType>(
  () => `${storeType.value}_HISTORY`
);

const data = computed(() => {
  return store.getters[historyStoreType.value]('FieldHistory');
});
const items = computed(() => {
  return data.value?.items ?? [];
});

const total = computed(() => {
  return data.value?.total ?? 0;
});

const { formattedDuration: formattedPeriod } = useFormatDuration(
  () => period.value / 1000,
  { rounded: true }
);
const periodText = computed(() => formattedPeriod.value.text);

const hasMultipleEntries = computed(() => {
  // check if items have multiple different keys entries
  return (
    [
      ...new Set(
        // get all the updated keys
        items.value.reduce<string[]>((keys, item) => {
          return keys.concat(
            // for all possible update actions
            actions.reduce<string[]>((updatedFields, action) => {
              if (!item) return updatedFields;
              return updatedFields.concat(
                (item[action] ?? []).map(({ field }) => field)
              );
            }, [])
          );
        }, [])
      ),
    ].length > 1 // and check that length is greater than 1
  );
});
const viewInHistoryRoute = computed(() => {
  // avoid vue-router complaining if route subsection param "history" is not available
  if (!isOpen.value) return null;
  const now = Date.now();
  const fromDate = new Date(now - period.value).toISOString();
  const toDate = new Date(now).toISOString();
  const newRoute = {
    name: route.name!,
    params: { ...route.params, subsection: 'history' },
    query: {
      ...route.query,
      fields: field.value,
      range: (fromDate + ',' + toDate).replace(/:/g, '_'),
    },
  };
  return router.match(newRoute);
});

async function getHistory(): Promise<void> {
  // do nothing if history is not shown or no proper request settings given
  if (
    !isOpen.value ||
    !props.item ||
    !key.value ||
    !selectedId.value ||
    !historyStoreType.value
  ) {
    return;
  }
  fetchError.value = false;
  // get item history
  if (!loading.value) {
    loading.value = true;
    const now = Date.now();
    const [error] = await to(
      store.dispatch(`GET_${historyStoreType.value}`, {
        id: selectedId.value,
        scope: 'FieldHistory',
        params: {
          page: 1,
          limit,
          from: new Date(now - period.value).toISOString(),
          to: new Date(now).toISOString(),
          mode: 'diff',
          fields: [field.value, '-' + join(field.value, 'last_updated')],
        },
      })
    );
    // if we fail loading history data, tell it
    if (error) {
      alert.error = error;
      fetchError.value = true;
    }
    loading.value = false;
  }
}

function addPeriod(): void {
  period.value += basePeriod;
  getHistory();
}

watch(isOpen, newVal => {
  if (newVal) {
    getHistory();
  } else {
    // reset when dialog is fully closed
    setTimeout(() => {
      // reset period
      period.value = basePeriod;
      // reset fetched data
      if (!historyStoreType.value) return;
      store.commit(historyStoreType.value, {
        scope: 'FieldHistory',
        data: null,
      });
    }, 500);
  }
});

onMounted(() => {
  getHistory();
});
</script>
