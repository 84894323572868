import {
  i18n,
  createImprovedTranslate,
  createTranslateIfNeeded,
  loadLanguage,
} from '@/plugins/i18n';

import type { ControlVueI18n } from '@/plugins/i18n';
import type { Path, LocaleMessageObject, Values } from 'vue-i18n';

// We need to override the type of the t, tc, te and tin functions to change its return type
export interface UseI18nReturn
  extends Omit<ControlVueI18n, 'loadLanguage' | 't' | 'tc' | 'te'> {
  loadLanguage: (lang: string, data: LocaleMessageObject) => void;
  t: (keyOrI18nArgs: string | I18nArgs, values?: Values) => string;
  tc: (key: Path, choice?: number, values?: Values) => string;
  te: (key: Path) => boolean;
  tin: (keyOrI18nArgs: string | I18nArgs, values?: Values) => string;
}

export function useI18n(): UseI18nReturn {
  return reactiveComputed(() => ({
    ...(i18n as Omit<ControlVueI18n, 't' | 'tc' | 'te' | 'locale'>),
    loadLanguage,
    t: createImprovedTranslate(i18n.t.bind(i18n)),
    tc: i18n.tc.bind(i18n),
    te: i18n.te.bind(i18n),
    tin: createTranslateIfNeeded(i18n.t.bind(i18n)),
    locale: i18n.locale,
  }));
}
