import StoreModule from '@/store/core/store-module';

import type { ApiSchema } from '#/core-api';
import type { Get, Post, Patch } from '@/store/types/core-api';

declare global {
  /**
   * Scope
   */
  type Scope = DeepMerge<ApiSchema['auth.Scope'], { id: string }>;
}

const scopesScopes = ['Scopes', 'App'] as const;

export interface ScopeStoreTypes {
  SCOPES: [
    Get<'/auth/scopes', Scope[], typeof scopesScopes>,
    Post<'/auth/scopes'>,
  ];
  SCOPES_COUNT: [Get<'/auth/scopes/count', void, typeof scopesScopes>];
  SCOPE: [Get<'/auth/scopes/{Id}', Scope>, Patch<'/auth/scopes/{Id}'>];
}

const scopesModule = new StoreModule<ScopeStoreTypes>();
scopesModule
  .createResourceAndAction({
    type: 'SCOPES',
    requestEndPoint: '/auth/scopes',
    scopes: scopesScopes,
    transform: data => data.scopes,
  })
  .createResourceAndAction({
    type: 'SCOPES_COUNT',
    requestEndPoint: '/auth/scopes/count',
    scopes: scopesScopes,
  })
  .createAction({
    type: 'SCOPES',
    requestEndPoint: '/auth/scopes',
    requestMethod: 'POST',
  })
  .createResourceAndAction({
    type: 'SCOPE',
    requestEndPoint: '/auth/scopes/:id',
  })
  .createAction({
    type: 'SCOPE',
    requestEndPoint: '/auth/scopes/:id',
    requestMethod: 'PATCH',
  });

export default scopesModule;
