import {
  TripRate,
  TripStatus,
  TripType,
  BikeStateSource,
  EntityProductType,
  Risk,
  SuspiciousReason,
} from '#/core-api/enums';

import {
  ExtraneousForceEndTripErrorCode,
  ForceEndedTripErrorCode,
} from '@/enums/errors';
import { TripProductError } from '@/models/trip/enums';
import { defaultEnumDisplayProperties } from '@/lib/utils';

export function tripType(type?: TripType): DisplayProperties {
  switch (type) {
    case TripType.Undefined:
      return {
        message: 'trips__undefined_type',
      };
    case TripType.Sharing:
      return {
        message: 'trips__sharing',
        class: 'secondary_darker',
        icon: 'mdi-share-variant',
      };
    case TripType.Leasing:
      return {
        message: 'trips__leasing',
        class: { light: 'other', dark: 'other_lighter' },
        icon: 'svg:leasing_contract',
      };
    default:
      return defaultEnumDisplayProperties(type);
  }
}

export function tripStatus(status?: TripStatus): DisplayProperties {
  switch (status) {
    case TripStatus.NotSet:
      return {
        message: 'trips__undefined_status',
        class: 'secondary',
      };
    case TripStatus.Started:
      return {
        message: 'trips__started',
        class: 'success',
        icon: 'mdi-play-circle',
      };
    case TripStatus.Ended:
      return {
        message: 'trips__ended',
        class: 'accent',
      };
    case TripStatus.Paused:
      return {
        message: 'trips__paused',
        class: 'disabled',
        icon: 'mdi-pause-circle',
      };
    case TripStatus.NeedsPayment:
      return {
        message: 'trips__needs_payment',
        class: 'warning',
        icon: 'svg:coin_euro_alert',
      };
    case TripStatus.BillingPendingValidation:
      return {
        message: 'trips__billing_pending_validation',
        class: 'warning',
        icon: 'mdi-cash-clock',
      };
    case TripStatus.BillingPendingInformation:
      return {
        message: 'trips__billing_pending_information',
        class: 'warning',
        icon: 'mdi-progress-question',
      };
    default:
      return defaultEnumDisplayProperties(status);
  }
}

export function tripRate(rate?: TripRate): DisplayProperties {
  switch (rate) {
    case TripRate.UndefinedRate:
      return {
        message: 'trips__undefined_rate',
      };
    case TripRate.Bad:
      return {
        message: 'trips__bad',
      };
    case TripRate.Ok:
      return {
        message: 'trips__good',
      };
    case TripRate.Top:
      return {
        message: 'trips__top',
      };
    default:
      return defaultEnumDisplayProperties(rate);
  }
}

export function tripLockSource(
  lockSource?: BikeStateSource
): DisplayProperties {
  switch (lockSource) {
    case BikeStateSource.SourceUnknown:
      return {
        message: 'trips__lock_source__unknown',
      };
    case BikeStateSource.SourceBle:
      return {
        message: 'trips__lock_source__ble',
      };
    case BikeStateSource.SourceLwm2M:
      return {
        message: 'trips__lock_source__lwm2m',
      };
    case BikeStateSource.SourceAutolockTimeout:
      return {
        message: 'trips__lock_source__autolock_timeout',
      };
    case BikeStateSource.SourceAutolockStack:
      return {
        message: 'trips__lock_source__autolock_stack',
      };
    case BikeStateSource.SourceRfid:
      return {
        message: 'trips__lock_source__rfid',
      };
    case BikeStateSource.SourceAutolockStackMaintenance:
      return {
        message: 'trips__lock_source__autolock_stack_maintenance',
      };
    case BikeStateSource.SourcePauseInactivity:
      return {
        message: 'trips__lock_source__pause_inactivity',
      };
    case BikeStateSource.SourceAutolockStackFallback:
      return {
        message: 'trips__lock_source__autolock_stack_fallback',
      };
    case BikeStateSource.SourceAutolockDocked:
      return {
        message: 'trips__lock_source__autolock_docked',
      };
    default:
      return defaultEnumDisplayProperties(lockSource);
  }
}

export function pickupDepositType(type?: EntityProductType): DisplayProperties {
  switch (type) {
    case EntityProductType.UndefinedProductType:
      return {
        message: 'trips__unknown_type',
      };
    case EntityProductType.Vehicle:
      return {
        message: 'trips__bike',
      };
    case EntityProductType.Station:
      return {
        message: 'trips__station',
      };
    default:
      return defaultEnumDisplayProperties(type);
  }
}

/**
 * Force end trip error codes created by mobile apps
 */
export const mobileAppsForceEndTripErrorCodeList = [
  ExtraneousForceEndTripErrorCode.ImpossibleToUnlock,
  ExtraneousForceEndTripErrorCode.ImpossibleToLock,
  ExtraneousForceEndTripErrorCode.FailSafeMode,
  ExtraneousForceEndTripErrorCode.BikeCriticalUserReport,
  ExtraneousForceEndTripErrorCode.EmergencyEndTrip,
];
/**
 * Force end trip error codes created by the backend
 */
export const backendForceEndTripErrorCodeList = [
  ExtraneousForceEndTripErrorCode.InvalidBikeState,
  ExtraneousForceEndTripErrorCode.SoftUnlockTooLong,
];
/**
 * Force end trip error codes that are directly written by the bike
 */
export const bikeForceEndTripErrorCodeList = [
  ExtraneousForceEndTripErrorCode.BikeTripEndOnAutoLock,
];
/**
 * Force end trip error codes that are created by the "Control" front-end
 */
export const controlForceEndTripErrorCodeList = [
  ForceEndedTripErrorCode.FullStation,
  ForceEndedTripErrorCode.StackNotDetected,
  ForceEndedTripErrorCode.CouldNotEndDespiteStackDetection,
  ForceEndedTripErrorCode.DischargedVehicle,
  ForceEndedTripErrorCode.NoElectricalAssistance,
  ForceEndedTripErrorCode.MechanicalProblem,
  ForceEndedTripErrorCode.ForgotToEndTrip,
  ForceEndedTripErrorCode.NoNetwork,
  ForceEndedTripErrorCode.AbusivePause,
  ForceEndedTripErrorCode.AbandonedBike,
  ForceEndedTripErrorCode.DischargedPhone,
  ForceEndedTripErrorCode.Other,
];

export function tripError(
  code: ExtraneousForceEndTripErrorCode | ForceEndedTripErrorCode
): DisplayProperties & {
  hint?: string;
  requiresComment?: boolean;
} {
  switch (code) {
    /**
     * mobile apps
     */
    case ExtraneousForceEndTripErrorCode.ImpossibleToUnlock:
      return {
        message: 'trips__error__impossible_to_unlock',
      };
    case ExtraneousForceEndTripErrorCode.ImpossibleToLock:
      return {
        message: 'trips__error__impossible_to_lock',
      };
    case ExtraneousForceEndTripErrorCode.FailSafeMode:
      return {
        message: 'trips__error__fail_safe_mode',
      };
    case ExtraneousForceEndTripErrorCode.BikeCriticalUserReport:
      return {
        message: 'trips__error__bike_critical_user_report',
      };
    case ExtraneousForceEndTripErrorCode.EmergencyEndTrip:
      return {
        message: 'trips__error__emergency_end_trip',
      };
    /**
     * backend
     */
    case ExtraneousForceEndTripErrorCode.InvalidBikeState:
      return {
        message: 'trips__error__ended_by_backend',
      };
    case ExtraneousForceEndTripErrorCode.SoftUnlockTooLong:
      return {
        message: 'trips__error__soft_unlock_ended_by_backend',
      };
    /**
     * bike (embedded)
     */
    case ExtraneousForceEndTripErrorCode.BikeTripEndOnAutoLock:
      return {
        message: 'trips__error__trip_end_on_bike_auto_lock',
      };
    /**
     * web-app
     */
    case ForceEndedTripErrorCode.DischargedPhone:
      return {
        message: 'trips__error__discharged_phone',
      };
    case ForceEndedTripErrorCode.NoNetwork:
      return {
        message: 'trips__error__no_network',
        hint: 'trips__error__no_network_hint',
      };
    case ForceEndedTripErrorCode.ErrorSyncAppBackend:
      return {
        message: 'trips__error__error_sync_app_backend',
        hint: 'trips__error__error_sync_app_backend_hint',
      };
    case ForceEndedTripErrorCode.ForgotToEndTrip:
      return {
        message: 'trips__error__forgot_to_end_trip',
      };
    case ForceEndedTripErrorCode.MisunderstoodProcess:
      return {
        message: 'trips__error__misunderstood_process',
        requiresComment: true,
      };
    case ForceEndedTripErrorCode.BikeDroppedInForbiddenArea:
      return {
        message: 'trips__error__bike_dropped_in_forbidden_area',
      };
    case ForceEndedTripErrorCode.BikeDroppedOutsideServiceArea:
      return {
        message: 'trips__error__bike_dropped_outside_service_area',
      };
    case ForceEndedTripErrorCode.Other:
      return {
        message: 'trips__error__other',
        requiresComment: true,
      };
    case ForceEndedTripErrorCode.CouldNotContactUser:
      return {
        message: 'trips__error__could_not_contact_user',
        hint: 'trips__error__could_not_contact_user_hint',
        requiresComment: true,
      };
    case ForceEndedTripErrorCode.BikeDroppedInCriticalArea:
      return {
        message: 'trips__error__bike_dropped_in_critical_area',
      };
    case ForceEndedTripErrorCode.StackNotDetected:
      return {
        message: 'trips__error__stack_not_detected',
        hint: 'trips__error__stack_not_detected_hint',
      };
    case ForceEndedTripErrorCode.LostBLEConnection:
      return {
        message: 'trips__error__lost_ble_connection',
      };
    case ForceEndedTripErrorCode.TechnicalIssuePreventingUserToEndTrip:
      return {
        message: 'trips__error__technical_issue_preventing_user_to_end_trip',
        requiresComment: true,
      };
    case ForceEndedTripErrorCode.AbusivePause:
      return {
        message: 'trips__error__abusive_pause',
      };
    case ForceEndedTripErrorCode.FullStation:
      return {
        message: 'trips__error__full_station',
      };
    case ForceEndedTripErrorCode.CouldNotEndDespiteStackDetection:
      return {
        message: 'trips__error__could_not_end_despite_stack_detection',
        hint: 'trips__error__could_not_end_despite_stack_detection_hint',
      };
    case ForceEndedTripErrorCode.DischargedVehicle:
      return {
        message: 'trips__error__discharged_vehicle',
        hint: 'trips__error__discharged_vehicle_hint',
      };
    case ForceEndedTripErrorCode.NoElectricalAssistance:
      return {
        message: 'trips__error__no_electrical_assistance',
      };
    case ForceEndedTripErrorCode.MechanicalProblem:
      return {
        message: 'trips__error__mechanical_problem',
      };
    case ForceEndedTripErrorCode.AbandonedBike:
      return {
        message: 'trips__error__abandoned_bike',
      };
    default:
      return defaultEnumDisplayProperties(
        code,
        'trips__error__unknown_trip_error'
      );
  }
}

export function tripProductError(code: TripProductError): DisplayProperties {
  switch (code) {
    case TripProductError.StackedMaintenanceButNoMaintenanceRequired:
      return {
        message: 'stacked_maintenance_but_no_maintenance_required',
      };
    case TripProductError.StackedNormalButMaintenanceRequired:
      return {
        message: 'stacked_normal_but_maintenance_required',
      };
    case TripProductError.StackedFosBikeAlone:
      return {
        message: 'stacked_fos_bike_alone',
      };
    case TripProductError.StackedUnknownError:
      return {
        message: 'stacked_unknown_error',
      };
    case TripProductError.StackLimitReached:
      return {
        message: 'stack_limit_reached',
      };
    case TripProductError.HeavyLockUnlockImpossible:
      return {
        message: 'heavy_lock_unlock_impossible',
      };
    case TripProductError.HeavyLockLockImpossible:
      return {
        message: 'heavy_lock_lock_impossible',
      };
    default:
      return defaultEnumDisplayProperties(
        code,
        'trips__error__unknown_product_error'
      );
  }
}

export function tripRisk(risk?: Risk): DisplayProperties {
  switch (risk) {
    case Risk.Unknown:
      return {
        message: 'trips__risk__unknown',
        icon: 'mdi-help-circle',
        class: 'secondary',
      };
    case Risk.Suspicious:
      return {
        message: 'trips__risk__suspicious',
        icon: 'mdi-alert',
        class: 'warning',
      };
    case Risk.SuspiciousUnchecked:
      return {
        message: 'trips__risk__suspicious_unchecked',
        icon: 'mdi-alert-minus',
        class: 'disabled_alt1',
      };
    case Risk.Cleared:
      return {
        message: 'trips__risk__cleared',
        icon: 'mdi-check-circle',
        class: 'success',
      };
    case Risk.Malicious:
      return {
        message: 'trips__risk__malicious',
        icon: 'mdi-alert-circle',
        class: 'error',
      };
    case Risk.UnderInvestigation:
      return {
        message: 'trips__risk__under_investigation',
        icon: 'mdi-magnify',
      };
    case Risk.PendingAutomaticEvaluation:
      return {
        message: 'trips__risk__pending_automatic_evaluation',
        icon: 'mdi-timer-sand',
      };
    case Risk.NotSuspicious:
      return {
        message: 'trips__risk__not_suspicious',
      };
    default:
      return defaultEnumDisplayProperties(risk);
  }
}

export function tripSuspiciousReasons(
  suspiciousReason?: SuspiciousReason
): DisplayProperties {
  switch (suspiciousReason) {
    case SuspiciousReason.Unknown:
      return {
        message: 'trips__suspicious_reason__unknown',
      };
    case SuspiciousReason.MaxDuration:
      return {
        message: 'trips__suspicious_reason__max_duration',
      };
    case SuspiciousReason.TripErrors:
      return {
        message: 'trips__suspicious_reason__trip_errors',
      };
    case SuspiciousReason.LockSources:
      return {
        message: 'trips__suspicious_reason__lock_sources',
      };
    case SuspiciousReason.MaxDistance:
      return {
        message: 'trips__suspicious_reason__max_distance',
      };
    case SuspiciousReason.EndOutsideStation:
      return {
        message: 'trips__suspicious_reason__end_outside_station',
      };
    default:
      return defaultEnumDisplayProperties(suspiciousReason);
  }
}
