/**
 * Check if an item has coordinates as expected (location.coordinates).
 * @param item - The item to check
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function hasCoordinates(item?: any): boolean {
  return (
    item?.location?.coordinates?.length === 2 &&
    item.location.coordinates[0] !== null &&
    item.location.coordinates[0] !== undefined &&
    !Number.isNaN(item.location.coordinates[0]) &&
    item.location.coordinates[1] !== null &&
    item.location.coordinates[1] !== undefined &&
    !Number.isNaN(item.location.coordinates[1])
  );
}
