<template lang="pug">
VMenu.ZInputDatePicker(
  ref="menuRef"
  v-model="isMenuOpen"
  :return-value="value"
  :close-on-content-click="false"
  :min-width="minWidth"
  :max-width="maxWidth"
  :left="left"
  :right="right"
  v-bind="menuProps"
  :nudge-top="16"
  :disabled="disabled"
)
  template(#activator)
    VTextField(
      ref="inputRef"
      :append-icon="appendIcon"
      :label="label"
      :rules="rules"
      :color="themeColor(color)"
      :disabled="disabled"
      :value="displayedDate"
      :hint="hint"
      :messages="messages"
      v-bind="textFieldProps"
      clearable
      @click:clear="clear"
    )
  VToolbar(
    v-if="timePicker"
    dense
    flat
  )
    VIcon.ZInputDatePicker__icon mdi-clock
    span.ZInputDatePicker__text {{ displayedDate }}
    VSpacer
    VBtn(
      icon
      small
      @click="clear"
    )
      VIcon(small) mdi-close
  VDatePicker.ZInputDatePicker__date(
    ref="datePickerRef"
    v-model="inputDate"
    :color="themeColor(color)"
    :allowed-dates="allowedDates"
    :show-current="false"
    :max="maxDate || todayDate"
    :min="minDate || '1920-01-01'"
    v-bind="datePickerProps"
  )
  VTimePicker.ZInputDatePicker__time(
    v-if="timePicker"
    ref="timePickerRef"
    v-model="inputTime"
    :color="themeColor(color)"
    :width="240"
    format="24hr"
    header-color="transparent"
    :scrollable="smAndUp"
    :disabled="!inputDate"
    full-width
  )
  .ZInputDatePicker__actions(:class="{ 'theme--dark': isDark }")
    VBtn.ZInputDatePicker__button.ZInputDatePicker__button--right(
      flat
      @click="clear"
    ) {{ t('app__cancel') }}
    VBtn.ZInputDatePicker__button.ZInputDatePicker__button--left(
      flat
      color="error"
      @click="reset"
    ) {{ t('app__clear') }}
    VSpacer
    VBtn.ZInputDatePicker__button(
      :disabled="timePicker ? !inputTime : !inputDate"
      :color="themeColor(color)"
      @click="update"
    ) {{ t('app__done') }}
</template>

<style lang="stylus">
.ZInputDatePicker__date,
.ZInputDatePicker__time
  box-shadow none

.ZInputDatePicker__icon
  margin-right 8px

.ZInputDatePicker__text
  margin-right 4px

.ZInputDatePicker__actions
  display flex
  background white

.ZInputDatePicker__actions.theme--dark
  background: $colors.grey.darken-3

.ZInputDatePicker__time.theme--light .v-time-picker-title__time
  color black

.ZInputDatePicker__time
  text-align center

  .v-time-picker-title
    display flex
    justify-content center

  .v-time-picker-title__time .v-picker__title__btn,
  .v-time-picker-title__time span
    font-size 36px
    height 36px

  .v-picker__title
    width 100%
    display flex
    justify-content center
    padding-top 4px
    padding-bottom 8px

  .v-picker__body
    background transparent

  .v-time-picker-clock__container
    max-width 240px
    padding 8px 16px 16px

.v-btn.ZInputDatePicker__button--left
  margin-left 0

.v-btn.ZInputDatePicker__button--right
  margin-right 0
</style>

<script setup lang="ts">
import { dateToDisplay, timeToDisplay } from '@/lib/utils/format-time-range';
import { useI18n } from '@/composables/plugins';
import {
  useDarkTheme,
  useVModelProxy,
  useVuetifyBreakpoints,
  useColors,
} from '@/composables';

export interface ZInputDatePickerProps {
  /**
   * The date chosen
   * @model
   */
  value?: string | null;
  /**
   * The smallest width that the text-field can have
   */
  minWidth?: string;
  /**
   * The biggest width that the text-field can have
   */
  maxWidth?: string;
  /**
   * The menu will be placed on the left
   */
  left?: boolean;
  /**
   * The menu will be placed on the right
   */
  right?: boolean;
  /**
   * The native props that the menu takes
   */
  menuProps?: object;
  /**
   * The smallest date that the value can have
   */
  minDate?: string;
  /**
   * The biggest date that the value can have
   */
  maxDate?: string;
  /**
   * A selected icon in the text-field
   */
  appendIcon?: string;
  /**
   * A label above the text-field
   */
  label?: string;
  /**
   * Rules for the date
   */
  rules?: VuetifyRule[];
  /**
   * The text-field is disabled if the value of this prop is true
   */
  disabled?: boolean;
  /**
   * We start by choosing the year in the date-picker if the value of this prop is true
   */
  birthdate?: boolean;
  /**
   * We use a time-picker if the value of this prop is true
   */
  timePicker?: boolean;
  /**
   * A hint under the text-field when it is focused and when prop messages doesn't exist
   */
  hint?: string;
  /**
   * A message under the text-field
   */
  messages?: string;
  /**
   * The native props that the text-field takes
   */
  textFieldProps?: object;
  /**
   * Color of the component
   */
  color?: Color;
  /**
   * Selectable dates
   */
  allowedDates?: ((date: string) => boolean) | null;
  /**
   * The native props that the date-picker takes
   */
  datePickerProps?: object;
  /**
   * Whether the menu is open
   * @sync
   */
  menuOpen?: boolean;
}

const props = withDefaults(defineProps<ZInputDatePickerProps>(), {
  value: null,
  minWidth: undefined,
  maxWidth: undefined,
  left: false,
  right: false,
  menuProps: () => ({}),
  minDate: undefined,
  maxDate: undefined,
  appendIcon: undefined,
  label: '',
  rules: () => [],
  color: 'primary',
  disabled: false,
  birthdate: false,
  time: false,
  hint: '',
  messages: '',
  textFieldProps: () => ({}),
  allowedDates: null,
  datePickerProps: () => ({}),
  menuOpen: false,
});

defineEmits<{
  (name: 'update:menuOpen', value: boolean): void;
  (name: 'input', value: string | null): void;
}>();

const todayDate = new Date().toISOString().substring(0, 10);

const { t } = useI18n();
const { isDark } = useDarkTheme();
const { smAndUp } = useVuetifyBreakpoints();
const { themeColor } = useColors();

const menuRef = ref<TemplateRef | null>(null);
const inputRef = ref<TemplateRef | null>(null);
const datePickerRef = ref<TemplateRef | null>(null);
const timePickerRef = ref<TemplateRef | null>(null);

const modelValue = useVModelProxy({ props });
const isMenuOpen = useVModelProxy({ props, propName: 'menuOpen' });

const modelDate = ref<string | null>(null);
const modelTime = ref<string | null>(null);
const inputDate = ref<string | null>(null);
const inputTime = ref<string | null>(null);

function getDate(value?: string | null): string | null {
  return value ? dateToDisplay(value) : null;
}

function getTime(value?: string | null): string | null {
  return value ? timeToDisplay(value) : null;
}

function getDisplayedDate(
  date?: string | null,
  time?: string | null
): string | null {
  if (!date) return '';
  if (!time) return date;
  return `${date}, ${time}`;
}

function getReturnedDate(
  date?: string | null,
  time?: string | null
): string | null {
  if (!date) return null;
  if (!time) return date;
  return new Date(`${date} ${time}`).toISOString();
}

const displayedDate = computed(() => {
  const date = inputDate.value ?? modelDate.value;
  const time = props.timePicker ? (inputTime.value ?? modelTime.value) : null;
  return getDisplayedDate(date, time);
});

watchImmediate(modelValue, value => {
  modelDate.value = getDate(value);
  modelTime.value = getTime(value);
});

function reset(): void {
  if (props.birthdate) setDatePickerYear();
  inputDate.value = null;
  inputTime.value = null;
}

function clear(): void {
  isMenuOpen.value = false;
  inputDate.value = null;
  inputTime.value = null;
  reset();
}

function update(): void {
  modelValue.value = getReturnedDate(inputDate.value, inputTime.value);
  menuRef.value?.save(modelValue.value);
  clear();
}

function setDatePickerYear(): void {
  if (!datePickerRef.value) return;
  datePickerRef.value.activePicker = 'YEAR';
}

watch(isMenuOpen, (isOpen: boolean) => {
  if (isOpen) {
    inputDate.value = modelDate.value;
    inputTime.value = modelTime.value;
    // When birthdate mode is set, we start by choosing the year
    if (props.birthdate) nextTick(setDatePickerYear);
  }
});

function focus(): void {
  inputRef.value?.focus?.();
}

function resetValidation(): void {
  inputRef.value?.resetValidation?.();
}

defineExpose({
  /**
   * Focus the input
   */
  focus,
  /**
   * Reset input validation
   */
  resetValidation,
});
</script>
