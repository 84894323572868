import StoreModule from '@/store/core/store-module';
import { rentalFields } from '@/models/fields';
import {
  userEmailPopulateConfig,
  scopePopulateConfig,
} from '@/lib/helpers/populate';

import type { ApiSchema } from '#/core-api';
import type { PopulatedField } from '@/store/core/populate';
import type { Get, Post, Patch } from '@/store/types/core-api';

declare global {
  /**
   * Populated Rental
   */
  type Rental = DeepMerge<
    ApiSchema['rental.Rental'],
    {
      id: string;
      user?: PopulatedField<
        ApiSchema['rental.Rental']['user_id'],
        ApiSchema['user.User']['email']
      >;
      billing_user?: PopulatedField<
        ApiSchema['rental.Rental']['billing_user_id'],
        ApiSchema['user.User']['email']
      >;
      scope?: PopulatedField<
        ApiSchema['rental.Rental']['scope_id'],
        ApiSchema['auth.Scope']['name']
      >;
      benefit?: PopulatedField<
        ApiSchema['rental.Rental']['benefit_id'],
        ApiSchema['payment.Benefit']['name']
      >;
    }
  >;
}

const rentalsScopes = ['Rentals', 'UserRentals', 'BikeRentals'] as const;

export interface RentalStoreTypes {
  RENTALS: [Get<'/rentals', Rental[], typeof rentalsScopes>];
  RENTALS_COUNT: [Get<'/rentals/count', void, typeof rentalsScopes>];
  RENTAL: [Get<'/rentals/{Id}', Rental>, Patch<'/rentals/{ID}'>];
  RENTAL_REFUND: [Post<'/rentals/{ID}/refund'>];
  RENTAL_END: [Post<'/rentals/{ID}/end_rental'>];
  RENTAL_LINK_BIKE: [Post<'/rentals/{ID}/link_bike/{SerialNumber}'>];
  RENTAL_UNLINK_BIKE: [Post<'/rentals/{ID}/unlink_bike/{SerialNumber}'>];
}

const rentalModule = new StoreModule<RentalStoreTypes>();
rentalModule
  .createResourceAndAction({
    type: 'RENTALS',
    requestEndPoint: '/rentals',
    permission: 'gateway.rental.list',
    scopes: rentalsScopes,
    requestOptions: () => ({
      freeze: true,
      params: {
        fields: rentalFields,
      },
    }),
    populate: [
      userEmailPopulateConfig(),
      userEmailPopulateConfig({
        dataField: 'billing_user_id',
        asKey: 'billing_user',
      }),
    ],
    transform: data => data.rentals,
  })
  .createResourceAndAction({
    type: 'RENTALS_COUNT',
    requestEndPoint: '/rentals/count',
    scopes: rentalsScopes,
  })
  .createResourceAndAction({
    type: 'RENTAL',
    requestEndPoint: '/rentals/:id',
    permission: 'gateway.rental.query',
    populate: [
      userEmailPopulateConfig(),
      userEmailPopulateConfig({
        dataField: 'billing_user_id',
        asKey: 'billing_user',
      }),
      scopePopulateConfig('scope_id', 'scope'),
      {
        dataField: 'benefit_id',
        dataType: String,
        actionType: 'GET_BENEFITS',
        queryField: 'id',
        responseFields: [{ key: 'name', as: 'benefit' }],
      },
    ],
  })
  .createAction({
    type: 'RENTAL',
    requestEndPoint: '/rentals/:id',
    requestMethod: 'PATCH',
    preventStore: true,
    permission: 'gateway.rental.patch',
  })
  .createAction({
    type: 'RENTAL_REFUND',
    requestEndPoint: '/rentals/:id/refund',
    requestMethod: 'POST',
    permission: 'gateway.rental.refund',
  })
  .createAction({
    type: 'RENTAL_END',
    requestEndPoint: '/rentals/:id/end_rental',
    requestMethod: 'POST',
  })
  .createAction({
    type: 'RENTAL_LINK_BIKE',
    requestEndPoint: '/rentals/:id/link_bike/:serial_number',
    requestMethod: 'POST',
  })
  .createAction({
    type: 'RENTAL_UNLINK_BIKE',
    requestEndPoint: '/rentals/:id/unlink_bike/:serial_number',
    requestMethod: 'POST',
  });

export default rentalModule;
