import { version } from '@/../package.json';
// show version
document.querySelector('.splash-version').textContent = version;
// update class based on context
if (/ZoovMaintenance/.test(window.navigator.userAgent)) {
  document
    .getElementById('loading-splash')
    .classList.add('fifteen-maintenance');
} else {
  document.getElementById('loading-splash').classList.add('FifteenControl');
}
// log version
if (window.VUE_APP_VERSION_NAME) {
  const color = window.VUE_APP_VERSION_NAME.split(' ')[0];
  console.log(
    '%s %c%s',
    version,
    `color: ${color}`,
    window.VUE_APP_VERSION_NAME
  );
} else {
  console.log(version);
}
