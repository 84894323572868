import { useDarkTheme } from '@/composables/useDarkTheme';
import theme from '@/themes';

import type { ThemeColor } from '@/themes';

interface UseColorsReturn {
  /**
   * Theme color helper, returns the theme color name (based on dark theme preference)
   */
  themeColor: (color?: Color | string) => string | undefined;
  /**
   * Returns the theme color value (based on dark theme preference)
   */
  getColor: (color?: Color | string) => string | undefined;
}

export function useColors(): UseColorsReturn {
  const { isDark } = useDarkTheme();

  /**
   * Theme color helper, returns the theme color name based on dark theme preference
   */
  function themeColor(color?: Color | string): string | undefined {
    if (typeof color === 'object') {
      return color[isDark.value ? 'dark' : 'light'];
    }
    return color;
  }

  /**
   * Returns the theme color value based on dark theme preference
   */
  function getColor(color?: Color | string): string | undefined {
    const themeColorName = themeColor(color) as ThemeColor;
    return theme[themeColorName] ?? themeColor(color);
  }

  return {
    themeColor,
    getColor,
  };
}
