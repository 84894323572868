import { TicketType } from '@/store/types/core-api/enums';
import StoreModule from '@/store/core/store-module';
import { issueFields } from '@/models/fields';
import {
  internalEmailPopulateConfig,
  issueAreasPopulateConfig,
  issueCategoriesPopulateConfig,
  issueScopesPopulateConfig,
} from '@/lib/helpers/populate';

import type { ApiSchema } from '#/core-api';
import type { PopulatedField } from '@/store/core/populate';
import type { Get, Post, Patch, Delete } from '@/store/types/core-api';

declare global {
  /**
   * Ticket of type issue
   */
  type Issue = DeepMerge<
    ApiSchema['ticket.Ticket'],
    {
      id: string;
      type: TicketType.Issue;
      creator?: PopulatedField<
        ApiSchema['ticket.Ticket']['created_by'],
        ApiSchema['user.User']['email']
      >;
      updater?: PopulatedField<
        ApiSchema['ticket.Ticket']['updated_by'],
        ApiSchema['user.User']['email']
      >;
      areas?: PopulatedField<
        ApiSchema['ticket.Ticket']['selector_ids'],
        ApiSchema['area.ServiceableArea']['label']
      >[];
      scopes?: PopulatedField<
        ApiSchema['ticket.Ticket']['selector_ids'],
        ApiSchema['auth.Scope']['name']
      >[];
      categories?: PopulatedField<
        ApiSchema['ticket.Ticket']['category_ids'],
        ApiSchema['ticket.Category']
      >[];
    }
  >;
}

const issuesScopes = [
  'Issues',
  'Vehicles',
  'Bikes',
  'Stations',
  'Fasteners',
] as const;

export type IssuesScope = (typeof issuesScopes)[number];

export interface IssueStoreTypes {
  ISSUES: [Get<'/tickets/tickets', Issue[], typeof issuesScopes>];
  ISSUE: [
    Get<'/tickets/tickets/{Id}', Issue>,
    Post<'/tickets/tickets'>,
    Patch<'/tickets/tickets/{Id}'>,
    Delete<'/tickets/tickets/{Id}'>,
  ];
  ISSUES_COUNT: [Get<'/tickets/tickets/count', void, typeof issuesScopes>];
  ISSUE_CATEGORIES: [
    Get<'/tickets/categories', ApiSchema['ticket.Category'][]>,
  ];
}

const issuesModule = new StoreModule<IssueStoreTypes>();
issuesModule
  .createResourceAndAction({
    type: 'ISSUES',
    requestEndPoint: '/tickets/tickets',
    requestOptions: () => {
      return {
        params: {
          sort: '-created_at',
          fields: issueFields,
          query: { type: TicketType.Issue },
        },
      };
    },
    populate: [
      internalEmailPopulateConfig({
        dataField: 'created_by',
      }),
      internalEmailPopulateConfig({
        dataField: 'updated_by',
        asKey: 'updater',
      }),
      issueCategoriesPopulateConfig(),
      issueAreasPopulateConfig(),
      issueScopesPopulateConfig(),
    ],
    scopes: issuesScopes,
    withGlobalUsageQuery: true,
    transform: data => data.tickets,
  })
  .createResourceAndAction({
    type: 'ISSUE',
    requestEndPoint: '/tickets/tickets/:id',
    populate: [
      internalEmailPopulateConfig({
        dataField: 'created_by',
      }),
      internalEmailPopulateConfig({
        dataField: 'updated_by',
        asKey: 'updater',
      }),
      issueCategoriesPopulateConfig(),
      issueAreasPopulateConfig(),
      issueScopesPopulateConfig(),
    ],
  })
  .createResourceAndAction({
    type: 'ISSUES_COUNT',
    requestEndPoint: '/tickets/tickets/count',
    scopes: issuesScopes,
    withGlobalUsageQuery: true,
  })
  .createAction({
    type: 'ISSUE',
    requestMethod: 'POST',
    requestEndPoint: '/tickets/tickets',
  })
  .createAction({
    type: 'ISSUE',
    requestMethod: 'PATCH',
    requestEndPoint: '/tickets/tickets/:id',
  })
  .createAction({
    type: 'ISSUE',
    requestMethod: 'DELETE',
    requestEndPoint: '/tickets/tickets/:id',
  })
  .createResourceAndAction({
    type: 'ISSUE_CATEGORIES',
    requestEndPoint: '/tickets/categories',
    requestOptions: () => {
      return {
        params: {
          fields: ['id', 'i18n_name', 'name'],
        },
      };
    },
    transform: data => data.categories,
  });

export default issuesModule;
