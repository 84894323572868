<template lang="pug">
VTextField.ZInputUint(
  :value="inputValue"
  v-bind="textFieldProps"
  @input="handleInput"
)
</template>

<script setup lang="ts">
import { VTextField } from 'vuetify/lib/components/VTextField';

// Ensure that the inputs of number type are casted into unsigned integer numbers,
// by extending the VTextField component. We define only the 'value' prop because
// we handle it, and all the VTextField are available and retrieved from attrs.
export interface ZInputUintProps {
  /**
   * The input value
   * @model
   */
  value?: number | null;
}

const props = defineProps<ZInputUintProps>();

const emit = defineEmits<{
  (event: 'input', value?: number | null): void;
}>();

const attrs = useAttrs();
const textFieldProps = computed(() => ({
  ...attrs,
  type: 'number',
  pattern: '\\d*',
}));

const inputValue = ref();
watchImmediate(
  () => props.value,
  value => (inputValue.value = value)
);

function handleInput(value: string): void {
  const castedValue =
    // Cast the input value to an integer, but empty input returns undefined
    value === '' ? undefined : Math.abs(Math.round(Number(value)));

  inputValue.value = castedValue;
  emit('input', castedValue);
}
</script>
