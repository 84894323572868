import {
  getCardInfo,
  luhnCheck,
} from '@fifteen/design-system-vue/utils/credit-cards';

import { useI18n } from '@/composables/plugins';

import type { Price } from '@/components/ui/molecules/ZInputPrice.vue';

const { t } = useI18n();
const numericRegex = /^[-+]?\d+(\.\d+)?$/;

function isEmpty(value: unknown): boolean {
  return ([null, undefined, ''] as unknown[]).includes(value);
}

export const idRule: VuetifyRule = value => {
  return /^[a-z0-9]+$/.test(value) || t('rules__invalid_id');
};

export const hexaRule: VuetifyRule = value => {
  return /^[a-f0-9]+$/i.test(value) || t('rules__invalid_hexa');
};

export const stripeIdRule: VuetifyRule = value => {
  return /^[a-zA-Z0-9_]+$/.test(value) || t('rules__invalid_id');
};

export const serialNumberRule: VuetifyRule = value => {
  return /^[1-9]{1}[0-9]*$/.test(value) || t('rules__invalid_serial_number');
};

export const versionRule: VuetifyRule = value => {
  return /^([0-9]+\.*){1,2}[0-9]*$/.test(value) || t('rules__invalid_version');
};

export const requiredRule: VuetifyRule = value => {
  return !isEmpty(value) || t('rules__required');
};

export const priceRequiredRule: VuetifyRule<Price> = value => {
  return (
    (!isEmpty(value.amount) && !isEmpty(value.currency)) || t('rules__required')
  );
};

export const arrayRequiredRule: VuetifyRule = value => {
  return (Array.isArray(value) && !!value.length) || t('rules__required');
};

export const numberRule: VuetifyRule = value => {
  return /^[0-9]+$/.test(value) || t('rules__invalid_number');
};

export const twoDigitFloatNumberRule: VuetifyRule = value => {
  return (
    /^[0-9]+(\.[0-9]{0,2})?$/.test(value) ||
    t('rules__invalid_two_digit_float_number')
  );
};

export const noRoundBracketsRule: VuetifyRule = value => {
  return !/[\(\)]/.test(value) || t('rules__no_round_brackets');
};

export const percentageRule: VuetifyRule = value => {
  return (value >= 0 && value <= 100) || t('rules__invalid_percentage');
};

export const langRule: VuetifyRule = value => {
  return /^[a-zA-Z]{2}$/.test(value) || t('rules__invalid_lang_code');
};

export const nonZeroNumberRule: VuetifyRule = value => {
  return value > 0 || t('rules__non_zero_number');
};

export const nonZeroPriceRule: VuetifyRule<Price> = value => {
  return Number(value.amount) > 0 || t('rules__non_zero_price');
};

export const typeRule: (
  type:
    | NumberConstructor
    | DateConstructor
    | BooleanConstructor
    | StringConstructor
    | unknown
) => VuetifyRule = type => {
  return value => {
    switch (type) {
      case Number:
        return !isNaN(value) || t('rules__invalid_number');
      case Date:
        return (
          /[0-9]{4}-[0-9]{2}-[0-9]{2}/.test(value) || t('rules__invalid_date')
        );
      case Boolean:
        return (
          value === true || value === 'false' || t('rules__invalid_boolean')
        );
      case String:
      default:
        return typeof value === 'string' || t('rules__invalid_string');
    }
  };
};

export const domainsRule: VuetifyRule = domains => {
  if (!Array.isArray(domains)) return false;

  const invalidDomains = domains.filter(
    domain =>
      typeof domain !== 'string' ||
      !/(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}/.test(domain as string)
  );
  const s = invalidDomains.length > 1 ? 's' : '';
  return (
    !invalidDomains.length ||
    `Invalid domain name${s}: ${invalidDomains.join(', ')}`
  );
};

export const coordinatesPairRule: VuetifyRule = value => {
  if (typeof value !== 'string') return false;

  const coordinates: string[] = value.split(',');
  return (
    (coordinates.length === 2 &&
      coordinates.reduce<boolean>((valid, coordinate) => {
        return valid && /^[+-]?\d+(\.\d+)?$/.test(coordinate.trim());
      }, true)) ||
    'Invalid coordinates pair: ' + coordinates
  );
};

// array of entries rule (such as in the case of checkboxes)
export const makeArrayRule: VuetifyRuleBuilder = rule => {
  return function _arrayRule(arrayV) {
    if (!Array.isArray(arrayV)) {
      return t('rules__invalid_array_value');
    }
    return arrayV.reduce((result, value) => {
      const test = rule(value);
      if (test !== true) result = test + ': ' + value;
      return result;
    }, true);
  };
};

// multiple entries rule, used in a textarea with newline (\n) separator
export const makeMultipleRule: VuetifyRuleBuilder = (
  rule,
  separator = '\n'
) => {
  return function _multipleRule(textContent) {
    if (typeof textContent !== 'string') {
      return t('rules__invalid_multiple_structure');
    }
    return textContent
      .split(separator)
      .reduce<boolean | string>((result, value) => {
        const trimmed = value.trim();
        if (trimmed !== '') {
          const test = rule(trimmed);
          if (test !== true) result = test + ': ' + value;
        }
        return result;
      }, true);
  };
};

export const cardNumberRule: VuetifyRule = (value: string) => {
  if (typeof value !== 'string' || !value) return false;

  const isValidLuhn = luhnCheck(value);

  // card number length check
  const card = getCardInfo(value);
  const spacelessValue = value.replace(/\s/g, '');

  const isValidLength = !card || card.lengths.includes(spacelessValue.length);

  return (isValidLuhn && isValidLength) || t('rules__invalid_card_number');
};

export const cronRule: VuetifyRule = (value: string) => {
  if (!value) return true;
  const regex =
    /^((@(annually|yearly|monthly|weekly|daily|hourly|reboot))|(@every (\d+(ns|us|µs|ms|s|m|h))+)|((((\d+,)+\d+|(\d+(\/|-)\d+)|\d+|\*) ?){5}))$/;
  return regex.test(value) || t('rules__invalid_cron');
};

export const hasMinimalLength: (length: number) => VuetifyRule =
  length => value => {
    return (
      value?.length >= length ||
      t('rules__must_have_minimal_length', { length })
    );
  };

export const hasNumericValue: VuetifyRule = value => {
  return /\d/.test(value) || t('rules__must_have_numeric_value');
};

export const hasUpperCaseValue: VuetifyRule = value => {
  return /[A-Z]/.test(value) || t('rules__must_have_upper_case_value');
};

export const hasLowerCaseValue: VuetifyRule = value => {
  return /[a-z]/.test(value) || t('rules__must_have_lower_case_value');
};

export const hasSpecialCharacter: VuetifyRule = value => {
  return /[^\w\d]/.test(value) || t('rules__must_have_special_character');
};

export const latitudeRule: VuetifyRule = (value: unknown) => {
  const valueStr = typeof value === 'number' ? value.toString() : value;

  if (typeof valueStr !== 'string' || !numericRegex.test(valueStr)) {
    return t('rules__invalid_latitude');
  }

  const numValue = parseFloat(valueStr);
  if (Number.isNaN(numValue)) {
    return t('rules__invalid_latitude');
  }
  if (numValue < -90 || numValue > 90) {
    return t('rules__latitude_out_of_range');
  }

  return true;
};

export const longitudeRule: VuetifyRule = (value: unknown) => {
  const valueStr = typeof value === 'number' ? value.toString() : value;

  if (typeof valueStr !== 'string' || !numericRegex.test(valueStr)) {
    return t('rules__invalid_longitude');
  }

  const numValue = parseFloat(valueStr);
  if (Number.isNaN(numValue)) {
    return t('rules__invalid_longitude');
  }
  if (numValue < -180 || numValue > 180) {
    return t('rules__longitude_out_of_range');
  }

  return true;
};
