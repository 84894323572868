import StoreModule from '@/store/core/store-module';
import { dataOperationsConfig } from '@/config';

import type { Get } from '@/store/types/operations-api';

export interface OperationsStoreTypes {
  DATA_OPERATIONS_OPERATIONS: [Get<'/operations'>];
  DATA_OPERATIONS_STATION_OCCUPANCY: [Get<'/station-occupancy'>];
}

const dataOperationsModule = new StoreModule<OperationsStoreTypes>();
dataOperationsModule
  .createResourceAndAction({
    type: 'DATA_OPERATIONS_OPERATIONS',
    requestApiConfig: dataOperationsConfig,
    requestEndPoint: '/operations',
    withGlobalUsageQuery: false,
    requestOptions: () => ({
      freeze: true,
    }),
  })
  .createResourceAndAction({
    type: 'DATA_OPERATIONS_STATION_OCCUPANCY',
    requestApiConfig: dataOperationsConfig,
    requestEndPoint: '/station-occupancy',
    withGlobalUsageQuery: false,
    requestOptions: () => ({
      freeze: true,
    }),
  });

export default dataOperationsModule;
