<template lang="pug">
VSnackbar.ZUpdateServiceWorkerPrompt(
  v-if="hasMadeChoice"
  v-model="showUpdatePrompt"
  :timeout="0"
  :color="isDark ? 'neutral' : 'white'"
  transition="slide-x-reverse-transition"
  auto-height
  multi-line
  right
  bottom
)
  h3.ZUpdateServiceWorkerPrompt__title {{ t('app__update_title') }}
  p {{ t('app__update_message', { version: newVersionAvailable }) }}
  .ZUpdateServiceWorkerPrompt__actions
    VBtn.ZUpdateServiceWorkerPrompt__btn(
      color="primary"
      :disabled="isUpdateSuccess"
      :loading="isUpdatingServiceWorker"
      :icon="isUpdateSuccess"
      @click="updateServiceWorker"
    ) {{ isUpdateSuccess ? '' : t('app__update_button_text') }}
      ZIcon(:right="!isUpdateSuccess") {{ isUpdateSuccess ? 'mdi-cloud-check' : 'mdi-cloud-refresh' }}
    VBtn.ZUpdateServiceWorkerPrompt__btn.ZUpdateServiceWorkerPrompt__btn--skip(
      flat
      @click="toggleUpdatePrompt"
    ) {{ t('app__update_skip') }}
</template>

<style lang="stylus">
.ZUpdateServiceWorkerPrompt
  .theme--light &
    color black

  .theme--dark &
    color white

  .v-snack__content
    display flex
    align-items flex-start
    flex-direction column

.ZUpdateServiceWorkerPrompt__title
  margin-bottom 16px

.ZUpdateServiceWorkerPrompt__actions
  display flex
  justify-content space-between
  gap 16px
  width 100%

.ZUpdateServiceWorkerPrompt__btn
  margin-left 0 !important
  padding 8px 16px !important

  &--skip,
  .theme--light &
    color black

  .theme--dark &
    color white
</style>

<script setup lang="ts">
import {
  useCookieConsent,
  useDarkTheme,
  useServiceWorkerPrompt,
} from '@/composables';
import { useI18n } from '@/composables/plugins';

const { t } = useI18n();
const { isDark } = useDarkTheme();

const { hasMadeChoice } = useCookieConsent();

const {
  isUpdatingServiceWorker,
  showUpdatePrompt,
  toggleUpdatePrompt,
  updateServiceWorker,
  isUpdateSuccess,
  newVersionAvailable,
} = useServiceWorkerPrompt();
</script>
