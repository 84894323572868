<template lang="pug">
.FifteenControl
  VApp(:dark="isDark")
    ZInitializationSteps(
      :value="showInitializationSteps"
      :error="isError"
      :steps="isLoggedIn ? initSteps : []"
      @logout="handleLogout"
      @retry="initApp"
      @hook:mounted="hideSplashScreen"
    )
    template(v-if="!isLoggedIn")
      RouterView(name="login")

    template(v-else)
      ZOverlay(
        v-bind="store.getters.overlay.props"
        @click="store.getters.overlay.onClick"
      )
      ZNavigationDrawer(
        v-model="navDrawerOpen"
        :items="navDrawerItems"
      )

      VToolbar.App__toolbar(
        :dense="mdAndDown"
        :height="52"
      )
        VToolbarSideIcon(
          :show-logo="!mdAndDown"
          @click.stop="navDrawerOpen = !navDrawerOpen"
        )
        ZAppLogo(
          v-show="mdAndUp"
          :variant="isDark ? 'light' : 'dark'"
          :width="80"
        )
        VChip.App__envChip(
          color="fifteen_control"
          small
          disabled
          outline
        ) {{ env }}
        VSpacer(v-if="xsOnly")
        Privilege(permission="admin.app.global_usage")
          ZUsagePicker(
            v-model="areaUsageIds"
            :icon="areaIcon"
            :items="areaUsageItems"
            :all-selected-text="t('app__all_areas')"
            item-key="id"
            item-text="label"
          )
        ZVehicleScanner(
          v-show="smAndDown"
          ref="bikeScanner"
          v-model="isBikeScannerOpen"
        )
        VSpacer
        ZUserMenu(@logout="handleLogout")

      ZFieldHistory(
        v-if="history.modelName && history.requestSettings"
        v-model="history.show"
        :item="history.item"
        :model-name="history.modelName"
        :request-settings="history.requestSettings"
      )

      VContent.App__mainContent(
        v-if="isAppInitialized"
        @click.native="navDrawerOpen = false"
      )
        KeepAlive
          RouterView(@logout="handleLogout")

    ZAlert(
      :error.sync="alert.error"
      :message.sync="alert.message"
      :actions.sync="alert.actions"
      :icon="alert.icon"
      :type="alert.type"
      @closed="alert.icon = null"
    )

    ZToggleableHintContent

    ZStatusSnackbar(
      v-model="appStatus.show"
      :icon="appStatus.icon"
      :content="appStatus.content"
      :progress="appStatus.progress"
      :progress-color="appStatus.progressColor"
      :color="appStatus.color"
      :action-text="appStatus.action?.text"
      :action-function="appStatus.action?.function"
    )
      template(#action)
        VBtn(
          v-if="isOffline"
          small
          dark
          flat
          @click="offlineRetry"
        ) {{ t('app__retry') }}

    ZUpdateServiceWorkerPrompt

  ZLegalModal(
    v-model="showTermsAndConditions"
    type="terms-and-conditions"
  )
  ZLegalModal(
    v-model="showPrivacyPolicy"
    type="privacy-policy"
  )
</template>

<style lang="stylus">
html,
body
  scroll-behavior smooth
  position fixed
  overflow hidden
  width 100vw
  height 100vh

.FifteenControl
  .App__toolbar
    z-index 10
    user-select none
    box-shadow none
    background-color transparent

    .ZAppLogo
      transform translateY(4px)
      margin-right 8px

    +media-up-md()
      .v-toolbar__content
        padding 0 16px

  .App__updateCard
    text-align center
    border-radius 16px

    h3
      width 100%

    .v-card__title,
    .v-card__actions
      flex-direction column
      align-items center

.App__envChip
  font-weight 500
  text-transform uppercase

.App__mainContent
  .v-content__wrap
    border 1px solid $colors.grey.lighten-3
    border-radius 12px
    margin 8px
    margin-left 0
    margin-top 0
    overflow hidden

    +media-down-md()
      margin inherit

.theme--dark
  .App__mainContent
    .v-content__wrap
      border 1px solid $colors.grey.darken-2
</style>

<script setup lang="ts">
import piwa from 'piwa';
import toSnakeCase from 'to-snake-case';
import * as Sentry from '@sentry/vue';

import { ServiceableAreaStatus } from '#/core-api/enums';

import { version as packageVersion } from '@/../package.json';
import { isNullish, uniqueKeyFromLabel } from '@/lib/utils';
import AppError from '@/lib/classes/app-error';
import { appConfig, apiConfig, debugConfig } from '@/config';
import { areaIcon } from '@/config/icons';
import { AppErrorCode } from '@/enums/errors';
import { useI18n, usePreferences, usePrivileges } from '@/composables/plugins';
import {
  useAlert,
  useQueryParameter,
  useUserTracking,
  useVuetifyBreakpoints,
  useDarkTheme,
  useLegalDocs,
  useStoreState,
  useAuth,
  useAppStatus,
  useFieldHistory,
  useRuntimeConfig,
  useNotification,
  useNavigation,
} from '@/composables';
import ZVehicleScanner from '@/models/vehicle/components/ZVehicleScanner.vue';
import store from '@/store';

import ZLegalModal from '@/components/domain/ZLegalModal.vue';
import ZNavigationDrawer from '@/components/ui/organisms/ZNavigationDrawer.vue';
import ZUserMenu from '@/components/ui/organisms/ZUserMenu.vue';
import ZAlert from '@/components/ui/molecules/ZAlert.vue';
import ZToggleableHintContent from '@/components/ui/molecules/ZToggleableHintContent.vue';
import ZUsagePicker from '@/components/ui/molecules/ZUsagePicker.vue';
import ZOverlay from '@/components/ui/molecules/ZOverlay.vue';
import ZStatusSnackbar from '@/components/ui/molecules/ZStatusSnackbar.vue';
import ZInitializationSteps from '@/components/ui/organisms/ZInitializationSteps.vue';
import ZFieldHistory from '@/components/domain/ZFieldHistory.vue';
import ZUpdateServiceWorkerPrompt from '@/components/ui/molecules/ZUpdateServiceWorkerPrompt.vue';

import type { InitializationStep } from '@/components/ui/organisms/ZInitializationSteps.vue';

const isDev = import.meta.env.MODE === 'development';

const { t } = useI18n();
const { notify } = useNotification();
const privileges = usePrivileges();
const route = useRoute();
const router = useRouter();

const history = useFieldHistory();

const { userId, userRoles, userEmail } = useAuth();
const { fetchRuntimeConfig } = useRuntimeConfig();
const alert = useAlert();
const appStatus = useAppStatus();
const { preferences } = usePreferences();
const { mdAndDown, smAndDown, xsOnly, mdAndUp } = useVuetifyBreakpoints();
const { isDark } = useDarkTheme();
const { showTermsAndConditions, showPrivacyPolicy } = useLegalDocs();

const version = ref(packageVersion);
const navDrawerOpen = ref(false);
const appTitle = 'Fifteen Control';
const areaUsageItems = ref<(Area & { key: string })[]>();
const initializing = ref(false);
const user = ref<LoginUser>();
const areQueryParametersActive = ref(false);
const initSteps = ref<InitializationStep[]>([
  {
    name: 'environment',
    label: t('app__init_steps__environment'),
    status: null,
  },
  {
    name: 'user',
    label: t('app__init_steps__user'),
    status: null,
  },
  {
    name: 'areas',
    label: t('app__init_steps__areas'),
    status: null,
  },
  {
    name: 'privileges',
    label: t('app__init_steps__permissions'),
    status: null,
  },
]);

const isAppInitialized = useStoreState('isAppInitialized');
const isBikeScannerOpen = useStoreState('bikeScannerOpen');

const areaUsageIds = useQueryParameter<string[]>({
  name: 'area',
  type: Array,
  default: [],
  showDefault: true,
  activated: true,
  active: areQueryParametersActive,
  get: () => {
    return store.getters.usage({ name: 'area' }) ?? [];
  },
  set: value => {
    // case of no area allowed we should notify user
    if (!areaUsageItems.value?.length) {
      return;
    }

    store.commit('usage', { name: 'area', value });
  },
  encode: value => {
    if (!Array.isArray(value)) return value;

    if (!value.length) {
      return 'all';
    }

    return value
      .map(
        // get corresponding area key
        id =>
          ((areaUsageItems.value ?? []).find(area => area.id === id) || {}).key
      )
      .join(',');
  },
  decode: value => {
    if (!areaUsageItems.value) return [];

    if (
      areaUsageItems.value.length === 1 &&
      !isNullish(areaUsageItems.value[0].id)
    ) {
      return [areaUsageItems.value[0].id];
    }

    if (value === 'all') {
      return [];
    }

    // find area ids from keys
    const keys = value.split(',');
    const _areaIds = keys
      .map(
        key =>
          ((areaUsageItems.value ?? []).find(area => area.key === key) || {}).id
      )
      // remove non-existing area ids (undefined)
      .filter((id): id is string => !isNullish(id));

    return _areaIds;
  },
  onReady() {
    if (!areaUsageIds.value.length) {
      areaUsageIds.value =
        preferences.globalAreaUsage === '*' || !preferences.globalAreaUsage
          ? []
          : (preferences.globalAreaUsage?.split(',') ?? []);
    }
  },
});

onMounted(() => {
  useUserTracking();

  const welcomeMessage = t('app__welcome', { version: version.value });

  // greetings notification on app mount
  if (isDev) {
    console.log(welcomeMessage);
  }

  notify({
    message: welcomeMessage,
    new: false,
  });
});

const { items: navDrawerItems } = useNavigation();

const isLoggedIn = computed(() => store.getters['APP_LOGGED_IN']);

const initReady = computed(() => isLoggedIn.value);

const isRefreshingToken = computed(() => store.getters.refreshingToken);
const isSlowServer = computed(() => store.getters.slowServer);
const isOffline = computed(() => store.getters.offline);

const isError = computed(() =>
  initSteps.value.some(step => step.status === 'error')
);

const showInitializationSteps = computed(() =>
  isLoggedIn.value ? initializing.value || isError.value : false
);

const env = computed(() => store.getters['ENV']?.label);

async function initApp(): Promise<void> {
  initializing.value = true;
  resetStepsStatus();
  setDocumentTitle();

  Sentry.setUser({
    id: userId.value,
    roles: userRoles.value,
    email: userEmail.value,
  });
  Sentry.setContext('Environment', {
    env: env.value,
  });

  await until(isLoggedIn).toBe(true);

  await Promise.all([
    initRuntimeConfig(),
    initUserData(),
    initFleetProductVersions(),
    (async () => {
      await fetchAreas();
      return initPrivileges();
    })(),
  ]);

  areQueryParametersActive.value = true;
  initializing.value = false;
  // go to the correct route if we were in 403
  if (route.name === '4xx Error') {
    router.push(
      store.state.redirectRoute || appConfig.loginDefaultRedirectRoute
    );
  }

  isAppInitialized.value = true;
}

function setDocumentTitle(): void {
  if (!route) return;
  const pathStr = route.path.substring(1);
  const firstPathStr = (pathStr || '').split('/')[0];
  const translatedStr = firstPathStr
    ? String(parseInt(firstPathStr)) === firstPathStr
      ? parseInt(firstPathStr)
      : t(`${toSnakeCase(firstPathStr)}__menu_name`)
    : '';
  document.title = (translatedStr ? translatedStr + ' – ' : '') + appTitle;
  ' – ' + env.value;
}

async function initRuntimeConfig(): Promise<void> {
  const environmentStep = getInitStep('environment');

  const { error } = await piwa(fetchRuntimeConfig());

  if (error) {
    environmentStep.status = 'error';
    areaUsageItems.value = [];
  }
  environmentStep.status = 'ok';
}

async function initUserData(): Promise<void> {
  const userStep = getInitStep('user');
  const { error, data: _user } = await piwa(store.dispatch('GET_LOGIN_USER'));

  if (error) {
    userStep.status = 'error';
    areaUsageItems.value = [];
    throw new AppError(AppErrorCode.InternalError);
  }

  if (_user) {
    user.value = _user;
  }

  userStep.status = 'ok';
}

/**
 * Get areas as a global usage and add a unique key identifier
 */
async function fetchAreas(): Promise<void> {
  const areasStep = getInitStep('areas');

  const [error, areas] = await to(
    store.dispatch('GET_AREAS', {
      scope: 'App',
      preventPopulate: true,
      preventGlobalUsageQuery: true,
      params: {
        limit: apiConfig.areaResponseLimit,
        fields: ['id', 'label', 'required_role', 'status', 'country_code'],
        query: { status: { $ne: ServiceableAreaStatus.Deleted } },
      },
    })
  );

  if (error) {
    areasStep.status = 'error';
    areaUsageItems.value = [];
    throw new Error();
  }

  areaUsageItems.value = (areas ?? []).map((area, index) => ({
    ...area,
    key: uniqueKeyFromLabel({
      label: area.label,
      mapKey: 'areas',
      reset: index === 0,
    }),
  }));

  if (areaUsageItems.value?.length === 0) alertNoData();

  areasStep.status = 'ok';
}

async function initFleetProductVersions(): Promise<void> {
  const [error] = await to(store.dispatch('GET_FLEET_PRODUCT_VERSIONS'));

  if (error) {
    alert.error = new AppError(AppErrorCode.NoProductVersionFound);
  }
}

/**
 *  Initialize user privileges on app, which relies on areas
 */
async function initPrivileges(): Promise<void> {
  await fetchUserPrivileges();

  const _privileges = store.getters['AUTH_PRIVILEGES'] ?? [];

  const debugPrivileges = Number(window.VUE_APP_DEBUG)
    ? debugConfig.permissions.map(permission => ({
        permission,
        selector_id: '*',
      }))
    : [];

  privileges.setPrivileges([..._privileges, ...debugPrivileges]);
  privileges.setAreas((areaUsageItems?.value ?? []).map(({ id }) => id));
  privileges.init();
}

async function fetchUserPrivileges(): Promise<void> {
  const privilegesStep = getInitStep('privileges');

  const [error] = await to(store.dispatch('GET_AUTH_PRIVILEGES'));
  if (error) {
    privilegesStep.status = 'error';
    return;
  }

  privilegesStep.status = 'ok';
}

function resetAreas(): void {
  store.commit('AREAS', { scope: 'App', data: null });
}

function offlineRetry(): void {
  store.getters.offlineRetry();
}

async function handleLogout(): Promise<void> {
  initializing.value = false;
  resetStepsStatus();

  // delete store cache
  store.cache.clear();
  // clear bike listing
  store.commit('bikeListing', []);
  // dispatch logout action
  await store.dispatch('POST_LOGOUT');
  // destroy privileges module
  privileges.destroy();
  // reset areas data
  resetAreas();
  areQueryParametersActive.value = false;
  areaUsageItems.value = undefined;

  router.push('/login');
}

function alertNoData(): void {
  alert.error = new AppError(AppErrorCode.NoDataAvailable);
}

function getInitStep(name: InitializationStep['name']): InitializationStep {
  return initSteps.value.find(initStep => initStep.name === name)!;
}

function resetStepsStatus(): void {
  initSteps.value = initSteps.value.map(initStep => ({
    ...initStep,
    status: null,
  }));
}

function hideSplashScreen(): void {
  const splashScreenElement = document.getElementById('loading-splash');

  setTimeout(() => {
    if (splashScreenElement) {
      splashScreenElement.style.display = 'none';
    }
  }, 200);
}

watchImmediate(initReady, _initReady => {
  if (_initReady) initApp();
});

watch(
  () => route.path,
  (toRoutePath, fromRoutePath) => {
    // close nav drawer when route changes
    navDrawerOpen.value = false;
    // dismiss alert, if any, when route changes section
    if (toRoutePath.split('/')[1] !== fromRoutePath.split('/')[1]) {
      alert.message = '';
      alert.error = null;
    }
    // set the new document title
    setDocumentTitle();
  }
);

watch(areaUsageIds, () => privileges.selectAreas(areaUsageIds.value));

watch(isRefreshingToken, _isRefreshingToken => {
  if (!_isRefreshingToken) {
    return (appStatus.show = false);
  }

  appStatus.progress = Infinity;
  appStatus.show = true;
  appStatus.color = 'other';
  appStatus.icon = '';
  appStatus.content = t('app__status_refreshing_token');
  appStatus.action = undefined;
});

watch(isOffline, _isOffline => {
  if (!_isOffline) {
    return (appStatus.show = false);
  }

  appStatus.progress = 0;
  appStatus.show = true;
  appStatus.color = 'grey';
  appStatus.icon = 'mdi-cloud-off-outline';
  appStatus.content = t('app__status_offline');
  appStatus.action = undefined;
});

watch(isSlowServer, _isSlowServer => {
  if (!_isSlowServer) {
    return (appStatus.show = false);
  }

  appStatus.progress = Infinity;
  appStatus.show = true;
  appStatus.color = 'warning_alt1';
  appStatus.icon = '';
  appStatus.content = t('app__status_waiting_server');
  appStatus.action = undefined;
});
</script>
