<template lang="pug">
ZSvgIcon.ZIcon(
  v-if="parsedIconName"
  :class="classes"
  :svg-markup="svgMarkup"
  :spin="isLoading"
  v-bind="props"
)
</template>

<script setup lang="ts">
import camelCase from 'camelcase';

import { useVuetify } from '@/composables/plugins';
import svgIcons from '@/assets/img/icons';
import { mdiConfig } from '@/config';

import ZSvgIcon from '@/components/ui/atoms/ZSvgIcon.vue';

export interface ZIconProps {
  /**
   * Whether the icon should be displayed small
   */
  small?: boolean;
  /**
   * Whether the icon should be displayed large
   */
  large?: boolean;
  /**
   * Size of the icon, can be a number or a string
   */
  size?: number | string | null;
  /**
   * Color of the icon
   */
  color?: Color;
  /**
   * Whether the icon should be aligned to the right
   */
  right?: boolean;
  /**
   * Whether the icon should be self-colored
   */
  selfColor?: boolean;
}

// We do not set defaults because they are handled by ZSvgIcon
const props = defineProps<ZIconProps>();

const slots = useSlots();
const vuetify = useVuetify();

function getRawIconName(): string | undefined {
  return slots.default?.()?.[0]?.text;
}

const rawIconName = ref(getRawIconName());

const isVuetifyReferenceIcon = computed(() =>
  rawIconName.value?.startsWith('$vuetify.icons.')
);
const parsedIconName = computed(() => {
  const vuetifyIconKey = rawIconName.value?.split('.').pop();
  return isVuetifyReferenceIcon.value && vuetifyIconKey
    ? (vuetify.icons[vuetifyIconKey] as string)
    : rawIconName.value;
});

const isSvgIcon = computed(() => parsedIconName.value?.startsWith('svg:'));
const isMdiIcon = computed(() => parsedIconName.value?.startsWith('mdi-'));

onUpdated(() => {
  rawIconName.value = getRawIconName();
});

const svgMarkup = computed(() => {
  if (!parsedIconName.value) return;
  if (isSvgIcon.value) {
    const svgIconName = parsedIconName.value.replace('svg:', '');
    if (!(svgIconName in svgIcons)) {
      throw new Error(
        `Unknown SVG icon: '${svgIconName}'. Did you define it in 'src/assets/img/icons.ts' file?`
      );
    }
    return svgIcons[svgIconName as keyof typeof svgIcons];
  }
  if (isMdiIcon.value) {
    const mdiIconName = camelCase(parsedIconName.value);
    if (!(mdiIconName in mdiConfig)) {
      throw new Error(
        `Unknown MDI icon: '${mdiIconName}'. Did you export it in 'src/config/mdi.ts' file?`
      );
    }
    const path = mdiConfig[mdiIconName as keyof typeof mdiConfig];
    return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" data-name="${parsedIconName.value}"><path d="${path}"/></svg>`;
  }
});
const isLoading = computed(() => parsedIconName.value === 'mdi-loading');

const classes = {
  // add v-icon to static classes to ensure vuetify compatibility
  'v-icon': true,
};
</script>
