import StoreModule from '@/store/core/store-module';
import { bookingFields } from '@/models/fields';
import {
  userEmailPopulateConfig,
  areasPopulateConfig,
} from '@/lib/helpers/populate';

import type { ApiSchema } from '#/core-api';
import type { PopulatedField } from '@/store/core/populate';
import type { Get } from '@/store/types/core-api';

declare global {
  /**
   * Populated Booking
   */
  type Booking = DeepMerge<
    ApiSchema['rental.Booking'],
    {
      id: string;
      user?: PopulatedField<
        ApiSchema['rental.Booking']['user_id'],
        ApiSchema['user.User']['email']
      >;
      area?: PopulatedField<
        ApiSchema['rental.Booking']['area_id'],
        ApiSchema['area.ServiceableArea']['label']
      >;
      resulting_trip?: PopulatedField<
        ApiSchema['rental.Booking']['id'],
        ApiSchema['rental.Trip']['id']
      >;
      resulting_trip_status?: PopulatedField<
        ApiSchema['rental.Booking']['id'],
        ApiSchema['rental.Trip']['status']
      >;
    }
  >;
}

const bookingsScopes = [
  'Bookings',
  'BikeBookings',
  'UserBookings',
  'RentalBookings',
  'VehicleBookings',
] as const;

export interface BookingStoreTypes {
  BOOKINGS: [Get<'/trips/bookings', Booking[], typeof bookingsScopes>];
  BOOKINGS_COUNT: [Get<'/trips/bookings/count', void, typeof bookingsScopes>];
  BOOKING: [Get<'/trips/booking/{ID}', Booking>];
}

const bookingModule = new StoreModule<BookingStoreTypes>();
bookingModule
  .createResourceAndAction({
    type: 'BOOKINGS',
    requestEndPoint: '/trips/bookings',
    scopes: bookingsScopes,
    withGlobalUsageQuery: true,
    requestOptions: () => ({
      freeze: true,
      params: {
        fields: bookingFields,
      },
    }),
    populate: [userEmailPopulateConfig(), areasPopulateConfig()],
    transform: data => data.bookings,
  })
  .createResourceAndAction({
    type: 'BOOKINGS_COUNT',
    requestEndPoint: '/trips/bookings/count',
    scopes: bookingsScopes,
    withGlobalUsageQuery: true,
  })
  .createResourceAndAction({
    type: 'BOOKING',
    requestEndPoint: '/trips/booking/:id',
    transform: data => data.booking,
    populate: [
      userEmailPopulateConfig(),
      {
        dataField: 'id',
        dataType: String,
        actionType: 'GET_TRIPS',
        queryField: 'booking_id',
        responseFields: [
          { key: 'id', as: 'resulting_trip' },
          { key: 'status', as: 'resulting_trip_status' },
        ],
      },
      areasPopulateConfig(),
    ],
  });

export default bookingModule;
