import equal from 'fast-deep-equal';

import routes from '@/router/routes';

import type { Route } from 'vue-router';
import type { ActionType } from '@/store';

export interface UsePrivilegesReturn {
  /**
   * Set privileges for the 'privileges' module
   * @param privileges - The privileges array
   */
  setPrivileges: (privileges: Privilege[]) => void;
  /**
   * Set the available app's area ids for 'privileges' module
   * @param areaIds - The area ids
   */
  setAreas: (areaIds: string[]) => void;
  /**
   * Set the current selected area ids in the current app state
   * @param areaIds - The area ids
   */
  selectAreas: (areaIds: string[]) => void;
  /**
   * Set the module ready state to true and resolve waiting promises registered
   * by onReady hook, or call callbacks
   */
  init: () => void;
  /**
   * Set the module ready state to false and remove all the data
   */
  destroy: () => void;
  /**
   * Register callback on ready event
   */
  onReady: (callback?: () => void) => Promise<void>;
  /**
   * Checks against the given role bindings and areas whether permission is granted
   * @param permission - The permission name
   */
  isGranted: (permission?: string | null) => boolean | undefined;
  /**
   * Checks if action is granted based on a given store type, where permission name
   * is retrieved from the store permission-registry
   * @param type - The store type
   */
  isGrantedAction: (type: ActionType) => boolean | undefined;
  /**
   * Checks if route access is granted based on a given route name, where permission name
   * is retrieved from the routes list meta permission
   * @param name - The route name
   */
  isGrantedRoute: (name: Route['name']) => boolean | undefined;
}

/**
 * Utility to merge strings of concatenated selector_ids (separated with a coma ',') handling the case of wildcard '*'
 * @param ids1 - The first area chain
 * @param ids2 - The second area chain
 */
function mergeSelectorIds(ids1?: string, ids2?: string): string {
  if (ids1 === '*' || ids2 === '*') {
    return '*';
  } else {
    const selectors = [
      ...(ids1 ? ids1.split(',') : []),
      ...(ids2 ? ids2.split(',') : []),
    ];
    // remove duplicate
    return [...new Set(selectors)].join(',');
  }
}

// Shared state
const privileges = ref<Privilege[]>([]);
const areaIds = ref<string[]>([]);
const selectedAreaIds = ref<string[]>([]);
const isReady = ref(false);

/** Registry that holds the mapping between store types and permissions */
export const actionTypesPermissionsRegistry = new Map<ActionType, string>();

/**
 * Composable to construct a final permissions list for given roleBindings and available areas list,
 * and check whether the permission exists on a given selected area.
 * @example
 * ```ts
 * const { setPrivileges, setAreas, selectAreas, isGranted } = usePrivileges();
 * // initialization
 * setPrivileges(privileges) // pass privileges
 * setAreas(areaIds) // pass available areas
 * selectAreas(areaIds) // select the current working areas
 * // usage
 * isGranted(permission) // check if permission is granted
 * ```
 */
export function usePrivileges(): UsePrivilegesReturn {
  function isGranted(permission?: string | null): boolean | undefined {
    // first of all, if permission is null, undefined or empty string, it is always granted
    if (!permission) return true;
    //
    const privilege = privileges.value.find(p => p.permission === permission);
    // if no privilege found
    if (!privilege) return false;
    // no area available, should not grant anything
    if (!areaIds.value.length) return false;
    // if privilege.selector_id is missing, we cannot grant anything
    const selectorId = privilege.selector_id;
    if (!selectorId) return false;
    // permission always granted for wildcard selector_id
    if (selectorId === '*') return true;

    // check if privilege selector_id matches selected ones:
    // first, if no area is selected, it means that we are in "all" mode,
    // so use the available area ids list
    const finalSelectedAreaIds = selectedAreaIds.value.length
      ? selectedAreaIds.value
      : areaIds.value;
    // selected areas must *all* be included in privilege.selector_id set
    const privilegeAreaIds = selectorId.split(',');
    // compute intersection
    const intersection = finalSelectedAreaIds.filter((areaId: string) =>
      privilegeAreaIds.includes(areaId)
    );
    // if intersection is empty, no permission
    if (!intersection.length) return false;
    // if intersection is not strictly equal to selectedAreaIds, it means
    // that the current area selection may lead to an inconsistent permission state
    // so we do not grant permission, however we should explicitly prompt user to
    // use another selection of areas. In this case, undefined is used. It is always
    // falsy and has a correct semantic meaning (cannot define permission)
    if (!equal(intersection, finalSelectedAreaIds)) return undefined;
    // finally grant permission
    return true;
  }

  function setPrivileges(inputPrivileges: Privilege[]): void {
    // concatenated privileges for each roles
    privileges.value = inputPrivileges.reduce<Privilege[]>(
      (_privileges, privilege) => {
        // multiple occurrences of privileges with the same permission name
        // can be found here, because the are declared by role. They can
        // operate on different selector_id, so we must concat the given selector_id
        // or use '*' if at least one occurrence has the wildcard '*',
        // so find similar privilege
        const similarPrivilege = _privileges.find(
          ({ permission }) => permission === privilege.permission
        );
        // update it if it exists
        if (similarPrivilege) {
          similarPrivilege.selector_id = mergeSelectorIds(
            similarPrivilege.selector_id,
            privilege.selector_id
          );
          return _privileges;
        }
        // or use it
        else {
          return _privileges.concat(privilege);
        }
      },
      []
    );
  }

  function setAreas(inputAreaIds: string[]): void {
    areaIds.value = inputAreaIds;
  }

  function selectAreas(areaIds: string[]): void {
    selectedAreaIds.value = areaIds;
  }

  function init(): void {
    isReady.value = true;
  }

  function destroy(): void {
    isReady.value = false;
    privileges.value = [];
    areaIds.value = [];
    selectedAreaIds.value = [];
  }

  async function onReady(callback?: () => void): Promise<void> {
    await until(isReady).toBe(true);
    callback?.();
  }

  function isGrantedAction(type: ActionType): boolean | undefined {
    const permission = actionTypesPermissionsRegistry.get(type);
    return isGranted(permission);
  }

  function isGrantedRoute(name: Route['name']): boolean | undefined {
    const route = routes.find(r => r.name === name);
    const permission =
      route && 'meta' in route ? route.meta.permission : undefined;
    return isGranted(permission);
  }

  return {
    setPrivileges,
    setAreas,
    selectAreas,
    init,
    destroy,
    onReady,
    isGranted,
    isGrantedAction,
    isGrantedRoute,
  };
}
