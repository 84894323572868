import { distance } from '@turf/distance';

import {
  LostStatus,
  VehicleMaintenanceState,
  EntityLockedStatus,
  BikeMaintenanceState,
  BikeStatus,
} from '#/core-api/enums';

import store from '@/store';
import { delayFromNow } from '@/lib/utils';
import { appConfig } from '@/config';
import { vehicleLostStatus } from '@/models/vehicle/mappers/display';
import { connectivityBadge } from '@/models/shared/helpers';
import { VehicleProductType } from '@/models/vehicle/enums';

import type { ApiSchema } from '#/core-api';
import type theme from '@/themes/theme';
import type { UseI18nReturn } from '@/composables/plugins';

const WAREHOUSE_RADIUS = 200; // meters

/**
 * Check whether a vehicle is in a warehouse based on its maintenance state
 * @param maintenanceState - The vehicle’s maintenance state
 */
export function isVehicleInWarehouse(
  maintenanceState?: VehicleMaintenanceState
): boolean {
  return (
    maintenanceState ===
      VehicleMaintenanceState.MaintenanceStateInWarehouse_1 ||
    maintenanceState ===
      VehicleMaintenanceState.MaintenanceStateInWarehouse_3 ||
    maintenanceState ===
      VehicleMaintenanceState.MaintenanceStateInWarehouseExit ||
    maintenanceState ===
      VehicleMaintenanceState.MaintenanceStateInWarehouse_2 ||
    maintenanceState ===
      VehicleMaintenanceState.MaintenanceStateInWarehouse_4 ||
    maintenanceState ===
      VehicleMaintenanceState.MaintenanceStateInWarehouseEntry ||
    maintenanceState === VehicleMaintenanceState.MaintenanceStateRecycled ||
    maintenanceState === VehicleMaintenanceState.MaintenanceStateToRecycle ||
    maintenanceState ===
      VehicleMaintenanceState.MaintenanceStateInWarehousePreExit
  );
}

/**
 * Check whether a vehicle is located in a warehouse based on their coordinates
 * @param vehicleCoordinates - The vehicle’s coordinates
 * @param warehouseCoordinates - The warehouse’s coordinates
 */
export function isVehicleLocalizedInWarehouse(
  vehicleCoordinates?: Coordinates | number[],
  warehouseCoordinates?: Coordinates | number[]
): boolean {
  // Compute distance between bike and warehouse
  const distanceToWarehouse =
    warehouseCoordinates && vehicleCoordinates
      ? distance(warehouseCoordinates, vehicleCoordinates)
      : Infinity;

  return distanceToWarehouse <= WAREHOUSE_RADIUS / 1000;
}

/**
 * Check whether a vehicle is in a truck based on its maintenance state
 * @param maintenanceState - The vehicle’s maintenance state
 */
export function isVehicleInTruck(
  maintenanceState: VehicleMaintenanceState
): boolean {
  return (
    maintenanceState ===
      VehicleMaintenanceState.MaintenanceStateInTruckForReallocation ||
    maintenanceState ===
      VehicleMaintenanceState.MaintenanceStateInTruckToWarehouse
  );
}

/**
 * Determines whether a warning should be raised based on the last update time of a vehicle
 * @param value - The last update time
 * @param threshold - The threshold after which the warning should be raised, in milliseconds
 * @param maintenanceState - The vehicle’s maintenance state
 */
export function lastUpdateWarning(
  value?: string,
  threshold: number = Infinity,
  maintenanceState?: VehicleMaintenanceState
): boolean {
  if (!value) return false;
  const delay = delayFromNow(value);
  const shouldPing = !!maintenanceState && vehicleShouldPing(maintenanceState);

  return (
    shouldPing &&
    threshold !== null &&
    threshold !== undefined &&
    delay >= threshold
  );
}

/**
 * Determines whether a vehicle should ping based on its maintenance state
 * @param maintenanceState - The vehicle’s maintenance state
 */
export function vehicleShouldPing(
  maintenanceState: VehicleMaintenanceState
): boolean {
  return (
    maintenanceState ===
      VehicleMaintenanceState.MaintenanceStateInWarehouseExit ||
    maintenanceState ===
      VehicleMaintenanceState.MaintenanceStateInTruckForReallocation ||
    vehicleIsOutside(maintenanceState)
  );
}

/**
 * Determines the status of a vehicle
 * @param vehicle - The vehicle
 */
export function vehicleStatus(vehicle: Vehicle): BikeStatus {
  switch (true) {
    case vehicle?.is_reserved:
      return BikeStatus.Booked;
    case vehicle?.is_rented &&
      vehicle?.vehicle_state?.lock_info?.status === EntityLockedStatus.Unlocked:
      return BikeStatus.InUse;
    case vehicle?.is_rented &&
      vehicle?.vehicle_state?.lock_info?.status === EntityLockedStatus.Locked:
      return BikeStatus.Paused;
    default:
      return BikeStatus.Available;
  }
}

/**
 * Check whether a vehicle is a bike based on its product type id
 * @param productTypeId - The product type id
 */
export function isProductTypeBike(productTypeId?: number): boolean {
  return productTypeId === VehicleProductType.Bike;
}

/**
 * Check whether a product is a bike, based on its version id
 * @param productVersionId - The product version id
 */
export function isBike(productVersionId?: number): boolean {
  const productVersions: ApiSchema['fleet.ProductVersion'][] =
    store.getters['FLEET_PRODUCT_VERSIONS'] ?? [];
  const productVersion = productVersions?.find(
    product => product.id === productVersionId
  );
  return isProductTypeBike(productVersion?.product_type_id);
}

/**
 * Check whether a vehicle is an omni based on its product type id
 * @param productTypeId - The product type id
 */
export function isProductTypeOmni(productTypeId?: number): boolean {
  return productTypeId === VehicleProductType.Omni;
}

/**
 * Check whether a product is an omni, based on its version id
 * @param productVersionId - The product version id
 */
export function isOmni(productVersionId?: number): boolean {
  if (!productVersionId) return false;

  const fleetProducts: ApiSchema['fleet.ProductVersion'][] =
    store.getters['FLEET_PRODUCT_VERSIONS'] ?? [];
  const product = fleetProducts?.find(
    product => product.id === productVersionId
  );

  return isProductTypeOmni(product?.product_type_id);
}

/**
 * Check whether a vehicle is outside based on its maintenance state
 * @param maintenanceState - The vehicle’s maintenance state
 */
export function vehicleIsOutside(
  maintenanceState: VehicleMaintenanceState
): boolean {
  return (
    maintenanceState === VehicleMaintenanceState.MaintenanceStateInOrder ||
    vehicleNeedsFieldOrWarehouse(maintenanceState)
  );
}

/**
 * Check whether a vehicle needs maintenance, based on its maintenance state
 * @param maintenanceState - The vehicle’s maintenance state
 */
export function vehicleNeedsFieldOrWarehouse(
  maintenanceState: VehicleMaintenanceState
): boolean {
  return (
    maintenanceState ===
      VehicleMaintenanceState.MaintenanceStateOutOfOrderNeedsMaintenance ||
    maintenanceState ===
      VehicleMaintenanceState.MaintenanceStateInOrderNeedsMaintenance ||
    maintenanceState ===
      VehicleMaintenanceState.MaintenanceStateOutOfOrderNeedsWarehouse
  );
}

/**
 * Check whether a vehicle is out of order, based on its maintenance state
 * @param maintenanceState - The vehicle’s maintenance state
 */
export function isVehicleOutOfOrder(
  maintenanceState: VehicleMaintenanceState
): boolean {
  return (
    maintenanceState >=
    VehicleMaintenanceState.MaintenanceStateOutOfOrderNeedsMaintenance
  );
}

/**
 * Check whether a vehicle is lost, based on its lost status
 * @param vehicle - The vehicle
 */
export function isLost(vehicle: Vehicle): boolean {
  return vehicle.lost_info?.status === LostStatus.Lost;
}

/**
 * Check whether a vehicle is being searched, based on its lost status
 * @param vehicle - The vehicle
 */
export function isInSearch(vehicle: Vehicle): boolean {
  return vehicle.lost_info?.status === LostStatus.InSearch;
}

/**
 * Compute a high-order priority for a vehicle, based on several criteria.
 * 0. No priority
 * 1. Highest priority: unlocked and in-field bikes while not in trip
 * 2. In search bikes
 * 3. – _Not computed here_ –
 * 4. Vehicles that have not been in trip for a long time
 * 5. Vehicles that need to be sent to the warehouse
 * 6. Vehicles that need field maintenance
 */
export function computePriority(vehicle: Vehicle): number {
  // priority sign only on vehicles that are not lost
  if (!isLost(vehicle)) {
    // highest priority, not in trip vehicles with a lock_info.status unlock
    // and in_field, need_field_maintenance or need_warehouse
    if (
      vehicleIsOutside(
        vehicle.maintenance_state ??
          VehicleMaintenanceState.MaintenanceStateUnknown
      ) &&
      unlockWarning(
        vehicle.vehicle_state?.lock_info ?? {},
        vehicle.is_rented,
        vehicle.extra_properties?.last_ended_trip_time ?? '',
        vehicle.soft_unlock_timeout?.__data
      )
    ) {
      return 1;
    } else if (isInSearch(vehicle)) {
      return 2;
    } else if (
      vehicle.maintenance_state ===
      VehicleMaintenanceState.MaintenanceStateOutOfOrderNeedsWarehouse
    ) {
      return 5;
    } else if (
      vehicle?.maintenance_state ===
        VehicleMaintenanceState.MaintenanceStateInOrderNeedsMaintenance ||
      vehicle?.maintenance_state ===
        VehicleMaintenanceState.MaintenanceStateOutOfOrderNeedsMaintenance
    ) {
      return 6;
    } else if (!isVehicleOutOfOrder(vehicle.maintenance_state ?? 0)) {
      // in order, in free floating, but with a last-ended-trip of a long time ago
      if (
        vehicleStatus(vehicle) === BikeStatus.Available && // only 'available' vehicles, not booked, in trip, nor paused
        !vehicle.station_id && // not in station = free floating
        vehicle.extra_properties
      ) {
        for (let i = 2; i >= 0; i--) {
          const noTripLimit = appConfig.noTripSince[i] * 60 * 60 * 1000;
          if (
            vehicle.extra_properties.last_ended_trip_time &&
            delayFromNow(vehicle.extra_properties.last_ended_trip_time) >
              noTripLimit &&
            vehicle.extra_properties.last_maintenance_state_update &&
            delayFromNow(
              vehicle.extra_properties.last_maintenance_state_update
            ) > noTripLimit
          ) {
            return 4 + 0.1 * (i + 1);
          }
        }
      }
    }
  }
  return 0;
}

/**
 * Check whether a warning should be raised for an unlocked vehicle
 * @param lockInfo – The vehicle’s `lock_info` object
 * @param isRented – Whether the vehicle is rented
 * @param lastEndedTripTime – The last ended trip "ended_at" timestamp
 * @param softUnlockTimeout – The delay before a soft unlocked vehicle re-locks itself, in seconds. Defaults to the timeout defined in appConfig
 */
export function unlockWarning(
  lockInfo: ApiSchema['entity.LockInfo'],
  isRented?: boolean,
  lastEndedTripTime?: string,
  softUnlockTimeout?: number
): boolean {
  if (!lockInfo) return false;
  const lastUpdatedAt = lockInfo.updated_at;

  const lockInfoStatus = lockInfo.status;

  const softUnlockTimeoutInMs = softUnlockTimeout
    ? softUnlockTimeout * 1000
    : appConfig.lastLockInfoWarningLimit;

  const lastUpdatedAtDate = lastUpdatedAt
    ? new Date(lastUpdatedAt).getTime()
    : 0;
  const lastEndedTripDate = lastEndedTripTime
    ? new Date(lastEndedTripTime).getTime()
    : 0;

  const delayFromLastEndedTrip = lastUpdatedAtDate - lastEndedTripDate;
  const delayFromLastUpdate = new Date().getTime() - lastUpdatedAtDate;

  const isUnlockedAndNotInTrip =
    !isRented &&
    lockInfoStatus === EntityLockedStatus.Unlocked &&
    delayFromLastEndedTrip > 0;

  const isSoftUnlockedAndTimeoutExceeded =
    lockInfoStatus === EntityLockedStatus.SoftUnlocked &&
    delayFromLastUpdate > softUnlockTimeoutInMs;

  if (isUnlockedAndNotInTrip || isSoftUnlockedAndTimeoutExceeded) {
    return true;
  }

  return false;
}

export function mapVehicleMaintenanceStateToBikeMaintenanceState(
  vehicleMaintenanceState: VehicleMaintenanceState
): BikeMaintenanceState {
  switch (vehicleMaintenanceState) {
    case VehicleMaintenanceState.MaintenanceStateInOrder:
      return BikeMaintenanceState.InField;
    case VehicleMaintenanceState.MaintenanceStateOutOfOrderNeedsMaintenance:
    case VehicleMaintenanceState.MaintenanceStateInOrderNeedsMaintenance:
      return BikeMaintenanceState.NeedFieldMaintenance;
    case VehicleMaintenanceState.MaintenanceStateRecycled:
      return BikeMaintenanceState.Recycled;
    case VehicleMaintenanceState.MaintenanceStateOutOfOrderNeedsWarehouse:
      return BikeMaintenanceState.NeedWarehouse;
    case VehicleMaintenanceState.MaintenanceStateInWarehouse_1:
      return BikeMaintenanceState.InWarehouseLvl1;
    case VehicleMaintenanceState.MaintenanceStateInWarehouse_3:
      return BikeMaintenanceState.InWarehouseLvl2;
    case VehicleMaintenanceState.MaintenanceStateToSetup:
      return BikeMaintenanceState.ToSetup;
    case VehicleMaintenanceState.MaintenanceStateInWarehouseExit:
      return BikeMaintenanceState.InWarehouseExitZone;
    case VehicleMaintenanceState.MaintenanceStateInTruckForReallocation:
      return BikeMaintenanceState.InTruckForReallocation;
    case VehicleMaintenanceState.MaintenanceStateInWarehouse_2:
      return BikeMaintenanceState.InWarehouseLvl1Plus;
    case VehicleMaintenanceState.MaintenanceStateInWarehouse_4:
      return BikeMaintenanceState.InWarehouseLvl3;
    case VehicleMaintenanceState.MaintenanceStateToRecycle:
      return BikeMaintenanceState.ToRecycle;
    case VehicleMaintenanceState.MaintenanceStateInTruckToWarehouse:
      return BikeMaintenanceState.InTruckToWarehouse;
    case VehicleMaintenanceState.MaintenanceStateInWarehouseEntry:
      return BikeMaintenanceState.EntryZone;
    case VehicleMaintenanceState.MaintenanceStateInWarehousePreExit:
      return BikeMaintenanceState.PreExitZone;
    default:
      return BikeMaintenanceState.NotSet;
  }
}
/**
 * Returns the color of the vehicle's icon's inner color
 * @param vehicle - The vehicle
 */
export function vehicleIconInnerColor(vehicle: Vehicle): keyof typeof theme {
  switch (true) {
    case vehicle?.is_reserved:
      return 'warning';
    case vehicle?.is_rented &&
      vehicle?.vehicle_state?.lock_info?.status === EntityLockedStatus.Unlocked:
      return 'success';
    case vehicle?.is_rented &&
      vehicle?.vehicle_state?.lock_info?.status === EntityLockedStatus.Locked:
      return 'disabled';
    default:
      return 'primary';
  }
}

/**
 * Represents a macro-state, based mostly on the vehicle's maintenance state.
 */
export enum VehicleClusteredState {
  Unknown,
  ToDeploy,
  InService,
  Reallocation,
  FieldActionNeeded,
  WarehouseMaintenance,
  SignificantMaintenance,
  Unusable,
  InSearch,
  Lost,
}

/**
 * Return the clustered state of a vehicle, to be use in data-visualization,
 * so that vehicles can be grouped by "categories".
 * @param vehicle - The vehicle
 */
export function getVehicleClusteredState(
  vehicle: Vehicle
): VehicleClusteredState {
  const maintenanceState = vehicle.maintenance_state ?? 0;

  switch (true) {
    default:
    case maintenanceState === VehicleMaintenanceState.MaintenanceStateUnknown:
      return VehicleClusteredState.Unknown;
    case isInSearch(vehicle):
      return VehicleClusteredState.InSearch;
    case isLost(vehicle):
      return VehicleClusteredState.Lost;
    case maintenanceState === VehicleMaintenanceState.MaintenanceStateToDeploy:
      return VehicleClusteredState.ToDeploy;
    case maintenanceState <
      VehicleMaintenanceState.MaintenanceStateOutOfOrderNeedsMaintenance:
      return VehicleClusteredState.InService;
    case maintenanceState ===
      VehicleMaintenanceState.MaintenanceStateInWarehouseExit ||
      maintenanceState ===
        VehicleMaintenanceState.MaintenanceStateInTruckForReallocation:
      return VehicleClusteredState.Reallocation;
    case maintenanceState ===
      VehicleMaintenanceState.MaintenanceStateOutOfOrderNeedsMaintenance ||
      maintenanceState ===
        VehicleMaintenanceState.MaintenanceStateOutOfOrderNeedsWarehouse:
      return VehicleClusteredState.FieldActionNeeded;
    case maintenanceState ===
      VehicleMaintenanceState.MaintenanceStateInTruckToWarehouse ||
      maintenanceState ===
        VehicleMaintenanceState.MaintenanceStateInWarehouseEntry ||
      maintenanceState ===
        VehicleMaintenanceState.MaintenanceStateInWarehouse_1 ||
      maintenanceState ===
        VehicleMaintenanceState.MaintenanceStateInWarehousePreExit:
      return VehicleClusteredState.WarehouseMaintenance;
    case maintenanceState ===
      VehicleMaintenanceState.MaintenanceStateInWarehouse_2 ||
      maintenanceState ===
        VehicleMaintenanceState.MaintenanceStateInWarehouse_3 ||
      maintenanceState ===
        VehicleMaintenanceState.MaintenanceStateInWarehouse_4:
      return VehicleClusteredState.SignificantMaintenance;
    case maintenanceState ===
      VehicleMaintenanceState.MaintenanceStateToRecycle ||
      maintenanceState === VehicleMaintenanceState.MaintenanceStateRecycled:
      return VehicleClusteredState.Unusable;
  }
}

/**
 * Represents the state of a vehicle regarding the different service stages.
 */
export enum VehicleServiceState {
  Unknown,
  AvailableForRent,
  Rented,
  Reserved,
  LowBattery,
  OutOfOrder,
  Lost,
}

/**
 * Return the service state of a vehicle, to be use in data-visualization,
 * so that vehicles can be grouped in the different stages of the service.
 * @param vehicle - The vehicle
 */
export function getVehicleServiceState(vehicle: Vehicle): VehicleServiceState {
  switch (true) {
    case isLost(vehicle):
      return VehicleServiceState.Lost;
    default:
    case isVehicleOutOfOrder(vehicle.maintenance_state ?? 0):
      return VehicleServiceState.OutOfOrder;
    case vehicle.is_rented:
      return VehicleServiceState.Rented;
    case vehicle.is_reserved:
      return VehicleServiceState.Reserved;
    case vehicle.is_low_battery:
      return VehicleServiceState.LowBattery;
    case !vehicle.is_low_battery:
      return VehicleServiceState.AvailableForRent;
  }
}

export enum VehicleMaintenanceStateAdditionalState {
  Lost = 999,
}
/**
 * Augmented VehicleMaintenanceState with "Lost" state, used for data-visualization,
 * so that lost vehicles can be excluded in this specific `Lost` state.
 */
export type VehicleMaintenanceStateAugmented =
  | VehicleMaintenanceState
  | VehicleMaintenanceStateAdditionalState;
export const VehicleMaintenanceStateAugmented = {
  ...VehicleMaintenanceState,
  ...VehicleMaintenanceStateAdditionalState,
};

/**
 * Return the augmented maintenance state of a vehicle, to be used in data-visualization,
 * so that vehicles can be grouped in the different maintenance states.
 * @param vehicle - The vehicle
 */
export function getVehicleMaintenanceStateAugmented(
  vehicle: Vehicle
): VehicleMaintenanceStateAugmented {
  switch (true) {
    case isLost(vehicle):
      return VehicleMaintenanceStateAugmented.Lost;
    default:
      return vehicle.maintenance_state as VehicleMaintenanceStateAugmented;
  }
}

type VehicleLostInfo = NonNullable<Vehicle['lost_info']>;

/**
 * Return the bike’s lost status or "in search", which displays the search counts if any.
 * @param lostInfo - The bike’s lost info field
 * @param maxSearches - The maximum number of searches allowed for a bike
 */
export function lostStatusAndSearchText(
  lostInfo: VehicleLostInfo,
  t: UseI18nReturn['t'],
  maxSearches = 3
): string {
  const counts = Array.isArray(lostInfo.search_history)
    ? lostInfo.search_history.length
    : 0;
  const append =
    lostInfo.status === LostStatus.InSearch && counts
      ? `: ${counts}/${maxSearches}`
      : '';
  return (
    t(
      vehicleLostStatus(lostInfo.status ?? LostStatus.LoststatusNotSet).message
    ) + append
  );
}

/**
 * Return a badge based on the bike’s lost status
 * @param lostInfo - The bike’s lost info field
 */
export function lostStatusBikeBadge(
  lostInfo: VehicleLostInfo,
  t: UseI18nReturn['t']
): Badge {
  return {
    text: lostStatusAndSearchText(lostInfo, t),
    color:
      vehicleLostStatus(lostInfo.status ?? LostStatus.LoststatusNotSet).class ??
      '',
    darkText: lostInfo.status === LostStatus.InSearch,
    relative: true,
  };
}

/**
 * Compute the bike badge
 */
export function vehicleBadge(
  vehicle: Vehicle | undefined,
  t: UseI18nReturn['t']
): Badge {
  if (!vehicle) return {};
  const lostInfo = vehicle.lost_info ?? {};
  const connectivityInfo = vehicle.vehicle_state?.connectivity_info ?? {};

  return lostInfo.status === LostStatus.InSearch ||
    lostInfo.status === LostStatus.Lost
    ? lostStatusBikeBadge(lostInfo, t)
    : connectivityBadge(connectivityInfo);
}
