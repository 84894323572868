import { TargetType } from '#/core-api/enums';

import StoreModule from '@/store/core/store-module';
import { benefitFields } from '@/models/fields';
import {
  areasPopulateConfig,
  benefitTargetPopulateConfig,
} from '@/lib/helpers/populate';
import { addOfferCategory } from '@/models/offer/helpers';

import type { ApiSchema } from '#/core-api';
import type { OfferCategory } from '@/models/offer/enums';
import type { PopulatedField } from '@/store/core/populate';
import type { Get, Post, Patch, Delete } from '@/store/types/core-api';

declare global {
  /**
   * Populated Benefit
   */
  type Benefit = DeepMerge<
    ApiSchema['payment.Benefit'],
    {
      id: string;
      areas?: PopulatedField<
        ApiSchema['payment.Benefit']['area_ids'],
        ApiSchema['area.ServiceableArea']['label'][]
      >;
      target_user?: PopulatedField<
        NonNullable<ApiSchema['payment.Benefit']['target']>['id'],
        ApiSchema['user.User']['email']
      >;
      target_area?: PopulatedField<
        NonNullable<ApiSchema['payment.Benefit']['target']>['id'],
        ApiSchema['area.ServiceableArea']['label']
      >;
      target_station?: PopulatedField<
        NonNullable<ApiSchema['payment.Benefit']['target']>['id'],
        ApiSchema['station.Station']['label']
      >;
      category?: OfferCategory;
    }
  >;
}

const benefitsScopes = [
  'Benefits',
  'UserBenefits',
  'AreaBenefits',
  'OfferBenefits',
] as const;

export interface BenefitStoreTypes {
  BENEFITS: [
    Get<'/payments/benefits', Benefit[], typeof benefitsScopes>,
    Post<'/payments/benefits'>,
  ];
  BENEFITS_COUNT: [
    Get<'/payments/benefits/count', void, typeof benefitsScopes>,
  ];
  BENEFIT: [
    Get<'/payments/benefits/{ID}', Benefit>,
    Patch<'/payments/benefits/{ID}'>,
    Delete<'/payments/benefits/{ID}'>,
  ];
  BENEFIT_RESUME: [Post<'/payments/benefits/{ID}/resume'>];
  BENEFIT_CANCEL: [Post<'/payments/benefits/{ID}/cancel'>];
  BENEFIT_ADJUST_LAST_PAYMENT: [
    Post<'/payments/benefits/{ID}/adjust_last_payment'>,
  ];
  BENEFIT_REGULARIZE: [Post<'/payments/benefits/{ID}/regularize'>];
  BENEFIT_VALIDATE: [Post<'/payments/benefits/{ID}/validate'>];
}

const benefitsModule = new StoreModule<BenefitStoreTypes>();
benefitsModule
  .createResourceAndAction({
    type: 'BENEFITS',
    requestEndPoint: '/payments/benefits',
    scopes: benefitsScopes,
    requestOptions: () => ({
      freeze: true,
      params: {
        fields: benefitFields,
      },
    }),
    populate: [
      areasPopulateConfig({
        withWarehouse: false,
        dataField: 'area_ids',
        asKey: 'areas',
      }),
      benefitTargetPopulateConfig({
        target: TargetType.User,
      }),
      benefitTargetPopulateConfig({
        target: TargetType.Area,
      }),
      benefitTargetPopulateConfig({
        target: TargetType.Station,
      }),
    ],
    transform: data => (data.benefits ?? []).map(addOfferCategory),
  })
  .createAction({
    type: 'BENEFITS',
    requestEndPoint: '/payments/benefits',
    requestMethod: 'POST',
    permission: 'gateway.benefit.add',
  })
  .createResourceAndAction({
    type: 'BENEFITS_COUNT',
    requestEndPoint: '/payments/benefits/count',
    scopes: benefitsScopes,
  })
  .createResourceAndAction({
    type: 'BENEFIT',
    requestEndPoint: '/payments/benefits/:id',
    requestOptions: () => ({
      headers: { 'accept-language': '' }, // so that i18n fields are fully returned
    }),
    transform: data => addOfferCategory(data.benefit as Benefit),
    populate: [
      areasPopulateConfig({
        withWarehouse: false,
        dataField: 'area_ids',
        asKey: 'areas',
      }),
      benefitTargetPopulateConfig({
        target: TargetType.User,
      }),
      benefitTargetPopulateConfig({
        target: TargetType.Area,
      }),
      benefitTargetPopulateConfig({
        target: TargetType.Station,
      }),
    ],
  })
  .createAction({
    type: 'BENEFIT',
    requestEndPoint: '/payments/benefits/:id',
    requestMethod: 'PATCH',
  })
  .createAction({
    type: 'BENEFIT_RESUME',
    requestEndPoint: '/payments/benefits/:id/resume',
    requestMethod: 'POST',
    permission: 'gateway.benefit.resume',
  })
  .createAction({
    type: 'BENEFIT_CANCEL',
    requestEndPoint: '/payments/benefits/:id/cancel',
    requestMethod: 'POST',
    permission: 'gateway.benefit.cancel',
  })
  .createAction({
    type: 'BENEFIT_ADJUST_LAST_PAYMENT',
    requestEndPoint: '/payments/benefits/:id/adjust_last_payment',
    requestMethod: 'POST',
    permission: 'gateway.benefit.adjust_last_payment',
  })
  .createAction({
    type: 'BENEFIT_REGULARIZE',
    requestEndPoint: '/payments/benefits/:id/regularize',
    requestMethod: 'POST',
    permission: 'gateway.benefit.regularize',
  })
  .createAction({
    type: 'BENEFIT_VALIDATE',
    requestEndPoint: '/payments/benefits/:id/validate',
    requestMethod: 'POST',
    permission: 'gateway.benefit.validate',
  });

export default benefitsModule;
