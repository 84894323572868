import { useHead } from '@unhead/vue';

import { keysOf } from '@/lib/utils';
import store from '@/store';

import type { AxeptioCookiesChoices } from '@/types/axeptio';

const choices = ref<AxeptioCookiesChoices>({});
const isInit = ref(false);
const hasMadeChoice = ref(false);

/**
 * Handle user tracking consent
 */
export function useCookieConsent(): {
  choices: Ref<AxeptioCookiesChoices>;
  hasMadeChoice: Ref<boolean>;
} {
  const locale = computed(() => store.getters.locale);

  if (!isInit.value) {
    isInit.value = true;

    window.axeptioSettings = {
      clientId: window.VUE_APP_AXEPTIO_CLIENT_ID,
      cookiesVersion: `control-${locale.value}`,
    };
    (window._axcb = window._axcb || []).push(axeptio => {
      axeptio.on('cookies:complete', _choices => (choices.value = _choices));
    });

    useHead({
      script: [
        {
          async: true,
          defer: true,
          src: '//static.axept.io/sdk-slim.js',
        },
      ],
    });

    watch(locale, value => {
      window.axeptioSDK?.setCookiesVersion(`control-${value}`);
    });

    watchOnce(choices, newChoices => {
      if (Object.keys(newChoices).length > 0) {
        hasMadeChoice.value = true;
      }
    });

    watch(choices, (newChoices, oldChoices) => {
      // Reload window if user has disabled tracking for a vendor
      if (
        keysOf(choices.value).some(key => oldChoices[key] && !newChoices[key])
      ) {
        window.location.reload();
        return;
      }
    });
  }

  return { choices, hasMadeChoice };
}
