export default function toggleScrollListener(
  value: boolean,
  handler: EventListener
): void {
  const applicationWrap = document.querySelector('.application--wrap');

  if (!applicationWrap) return;

  const scrollableElements = Array.from(
    document.querySelectorAll('.scrollable')
  ).concat(window as unknown as Element);

  if (value) {
    applicationWrap.addEventListener('wheel', handler, { passive: true });
    applicationWrap.addEventListener('touchmove', handler, { passive: true });
    scrollableElements.forEach(el => {
      el.addEventListener('scroll', handler, { passive: true });
    });
  } else {
    // @ts-expect-error - Some overload are not containing wheel
    applicationWrap.removeEventListener('wheel', handler, { passive: true });
    // @ts-expect-error - Some overload are not containing touchmove
    applicationWrap.removeEventListener('touchmove', handler, {
      passive: true,
    });
    scrollableElements.forEach(el => {
      // @ts-expect-error - Some overload are not containing scroll
      el.removeEventListener('scroll', handler, { passive: true });
    });
  }
}
