import store from '@/store';

export interface UseAuthReturn {
  /**
   * User ID, extracted from JWT
   */
  userId: ComputedRef<string | undefined>;
  /**
   * User email, extracted from JWT
   */
  userEmail: ComputedRef<string | undefined>;
  /**
   * User roles, extracted from JWT
   */
  userRoles: ComputedRef<string[]>;
  /**
   * User data, fetched from the API
   */
  user: ComputedRef<LoginUser | undefined>;
}

export function useAuth(): UseAuthReturn {
  const userId = computed(() => store.getters['LOGIN_DATA']?.id);
  const userEmail = computed(() => store.getters['LOGIN_DATA']?.email);
  const userRoles = computed(() => store.getters['LOGIN_DATA']?.roles ?? []);

  const user = computed(() => store.getters['LOGIN_USER']);

  return {
    userId,
    userEmail,
    userRoles,
    user,
  };
}
