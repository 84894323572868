import { wrap$eq } from '@/lib/utils';

/**
 * Create a query for a given filter and its value
 * @param filter - The filter, which must not be a `'multiple'` filter
 * @param valueOverride - The value to use instead of the filter's value
 */
export default function buildQueryValue<Key, Type extends FilterType>(
  filter: Filter<Key, Type, false>,
  valueOverride?: Type,
  wrap?: boolean
): MongoQuery {
  const { config, value: filterValue, operator } = filter;

  // If filter has negation, we will wrap 'naked' value with $eq, or if it is desired from arguments
  const wrapValue = wrap ?? config.negation;
  // Get the value
  const value = valueOverride ?? filterValue;

  switch (config.type) {
    case Number:
      const castedValue = Number(value) * (config.factor ?? 1);
      if (operator) {
        return { [operator]: castedValue };
      } else {
        if (castedValue === 0) return { $in: [0, null] };
        return wrap$eq(castedValue, wrapValue);
      }
    case Boolean:
      return wrap$eq(value as FilterType, wrapValue);
    case Date: {
      const asTimestamp = config.queryDateAsTimestamp;
      const asString = config.queryDateAsString;
      const inSeconds = config.queryDateInSeconds;
      let dateStart, dateEnd;
      // single date input (this may not be used any longer)
      if (typeof value === 'string') {
        const dateArray = value.split('-');
        const year = Number(dateArray[0]);
        const month = Number(dateArray[1]);
        const day = Number(dateArray[2]);
        // retrieve start and end as dates
        dateStart = new Date(year, month - 1, day);
        dateEnd = new Date(year, month - 1, day + 1);
      }
      // date range
      else if (value instanceof Object && value.from && value.to) {
        dateStart = new Date(value.from);
        dateEnd = new Date(value.to);
      }
      // nothing we can handle yet
      else {
        return {};
      }
      // return formatted query based on query options
      let dateStartQuery: MongoQuery, dateEndQuery: MongoQuery;
      if (asTimestamp) {
        const factor = inSeconds ? 1000 : 1;
        const start = Math.floor(dateStart.getTime() / factor);
        const end = Math.floor(dateEnd.getTime() / factor);
        if (asString) {
          dateStartQuery = String(start);
          dateEndQuery = String(end);
        } else {
          dateStartQuery = start;
          dateEndQuery = end;
        }
      } else {
        if (asString) {
          dateStartQuery = dateStart.toISOString();
          dateEndQuery = dateEnd.toISOString();
        } else {
          dateStartQuery = { $date: dateStart.toISOString() };
          dateEndQuery = { $date: dateEnd.toISOString() };
        }
      }
      return {
        $gte: dateStartQuery,
        $lt: dateEndQuery,
      };
    }
    case String:
    default:
      if (config.exactMatch) {
        // match string exactly
        return wrap$eq(value as FilterType, wrapValue);
      } else {
        // query regex
        const beginWith = config.beginWith ? '^' : '';
        const endWith = config.endWith ? '$' : '';
        const outQuery: MongoQuery = {
          $regex: beginWith + value + endWith,
        };
        if (!config.caseSensitive) {
          outQuery.$options = 'i';
        }
        return outQuery;
      }
  }
}
