import { toValue } from '@vueuse/core';

import { usePrivileges } from '@/composables/plugins';

import type { RawLocation } from 'vue-router';

interface UseDataValueLinkReturn {
  /**
   * Get the router link object for the given value
   * @param val - The value to get the router link object for
   * @param index - The index of the value in the array
   * @returns - The router link object
   */
  getRouterLinkObject: (
    val: DisplayField['val'],
    index?: number
  ) => RawLocation | undefined;
  /**
   * The root router link object
   */
  rootRouterLinkObject: ComputedRef<RawLocation | undefined>;
}

export function useDataValueLink(
  item: MaybeRefOrGetter<DisplayField>
): UseDataValueLinkReturn {
  const { isGrantedRoute } = usePrivileges();
  const router = useRouter();
  const route = useRoute();

  const _item = computed(() => toValue(item));

  function getRouterLinkBaseParams(
    linkName: string,
    val: DisplayField['val']
  ): Record<string, string> {
    if (val.link?.params) return val.link.params;
    switch (linkName) {
      case 'Bike':
        return { serial_number: String(val) };
      case 'Role':
        return { name: String(val) };
      case 'User':
      case 'Offer':
        if (val.__populated) return { id: String(val.__originalData) };
        break;
      case 'Bike.Issue':
      case 'Station.Issue':
      case 'Fastener.Issue':
      case 'Benefit':
        if (val.__populated) return val.__linkParams;
        break;
      case 'Map.Station.Fastener':
        return { fastenerId: val.__originalData };
      case 'Map.Station.Bike':
        return { nearbyFreefloatingBikeSerialNumber: String(val) };
      case 'Map.Station.Vehicle':
        return { nearbyFreefloatingVehicleId: String(val) };
      case 'Map.Station.Fastener.Bike':
        return { fastenedBikeSerialNumber: String(val) };
      case 'Map.Station.Fastener.Vehicle':
        return { fastenedVehicleId: String(val) };
      case 'Scope':
        return { id: val.__originalData ?? val };
    }
    return { id: String(val) };
  }

  function getRouterLinkObject(
    val: DisplayField,
    index?: number
  ): RawLocation | undefined {
    if (
      (_item.value.disabled &&
        (index === undefined || _item.value.disabled?.[index ?? 0])) ||
      val?.disabled
    ) {
      return;
    }

    const finalLinkName =
      _item.value.link?.name ??
      ((typeof val === 'object' && val?.link?.name) || null);

    if (!finalLinkName || !val) return;
    if (!isGrantedRoute(finalLinkName ?? '')) return;
    const baseParams = getRouterLinkBaseParams(finalLinkName ?? '', val);

    const params = {
      ...baseParams,
      ...linkParams.value,
    };

    return {
      name: finalLinkName,
      // @ts-expect-error - Params with object value is used, which is not described in VueRouter interface
      params,
      query: {
        ...router.currentRoute.query,
        ...(typeof _item.value.link?.query === 'function'
          ? _item.value.link?.query(val, index ?? 0)
          : _item.value.link?.query),
      },
    };
  }

  const linkParams = computed(() => {
    const linkParams = (_item.value as DisplayField).link?.params ?? {};

    if (!linkParams.length) return linkParams;

    return Object.fromEntries(
      Object.entries(linkParams).map(([key, val]) => [
        key,
        typeof val === 'object' && val?.current
          ? route.params[key] || val.fallback || 'temp'
          : val,
      ])
    );
  });

  const rootRouterLinkObject = computed(() => {
    return getRouterLinkObject(_item.value.val);
  });

  return { getRouterLinkObject, rootRouterLinkObject };
}
