import type { ApiSchema } from '#/core-api';

export const fields = [
  'created_at',
  'area_id',
  'created_by',
  'entity_type',
  'extra_properties',
  'id',
  'is_low_battery',
  'is_rented',
  'is_reserved',
  'location',
  'lost_info',
  'maintenance_state',
  'mileage',
  'parking_id',
  'product_version_id',
  'station_id',
  'updated_at',
  'updated_by',
  'vehicle_state',
  'parking',
  // Temporary workaround as cross_models.Vehicle contains circular references that breaks with RemoveNestedArrays recursive generic
] satisfies (
  | ModelField<'entity.Vehicle'>
  | FlattenedKey<{ parking: ApiSchema['entity.Parking'] }>
)[];
