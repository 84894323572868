import { getErrorTranslationKey } from '@/lib/helpers/errors';

import type { AppErrorCode } from '@/enums/errors';

type TranslationOptions = {
  key?: string;
  args?: Record<string, string | number>;
};

export default class AppError extends Error {
  code: AppErrorCode;
  translationArgs?: Record<string, string | number>;

  constructor(code: AppErrorCode, translationOptions?: TranslationOptions) {
    super();
    this.code = code;
    this.message = translationOptions?.key ?? getErrorTranslationKey(code);
    this.translationArgs = translationOptions?.args;
  }
}
