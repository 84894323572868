/* eslint-disable @typescript-eslint/no-explicit-any */
import { toValue } from '@vueuse/core';
import equal from 'fast-deep-equal';
import copy from 'fast-copy';

import type VueRouter from 'vue-router';
import type { MaybeRefOrGetter } from '@vueuse/core';
import type { WatchStopHandle } from 'vue';

type RuntimeType<TypeT> = TypeT extends string
  ? StringConstructor
  : TypeT extends number
    ? NumberConstructor
    : TypeT extends boolean
      ? BooleanConstructor
      : TypeT extends any[]
        ? ArrayConstructor
        : TypeT extends object
          ? ObjectConstructor
          : never;

export interface UseQueryParameterOptions<TypeT> {
  /**
   * Query parameter name shown in the URL
   */
  name: string;
  /**
   * Type can be either `Boolean`, `Number`, `String`, `Array` or `Object`
   */
  type: RuntimeType<TypeT>;
  /**
   * Default value
   */
  default: TypeT | (() => TypeT);
  /**
   * Show query parameter even if it is equal to default value
   */
  showDefault?: boolean;
  /**
   * Scope name, used to prefix the query parameter in the URL
   */
  scope?: MaybeRefOrGetter<string | undefined>;
  /**
   * Whether to base64 encode the value in the URL
   */
  base64?: boolean;
  /**
   * Hook to reactively check whether query parameter is active or not
   */
  active?: MaybeRefOrGetter<boolean>;
  /**
   * Optionally you can define a getter...
   */
  get?: () => TypeT;
  /**
   * ...and a setter, for example to use a store as the source of truth
   */
  set?: (value: TypeT) => void;
  /**
   * Optional hook called when the query parameter value is ready
   */
  onReady?: () => void;
  /**
   * Optionally you can define custom encoding function
   * to encode its value into the URL query parameter
   */
  encode?: (value: TypeT | undefined) => string | undefined;
  /**
   * Optionally you can define custom decoding function
   * to decode the URL query parameter into its value
   */
  decode?: (value: string) => TypeT | void;
  /**
   * If the reactive query parameter is not used under the router view that defines keep-alive mechanism, it is already activated
   * @default false
   */
  activated?: boolean;
}

/**
 * Default encoding function
 * @param value - Value to encode
 * @param type - Runtime type of the value
 * @param base64 - Whether to base64 encode the value or not
 */
function defaultEncode<TypeT>(
  value: TypeT | undefined,
  type: RuntimeType<TypeT>,
  base64?: boolean
): string | undefined {
  if (value === undefined) return undefined;

  switch (type) {
    case Object: {
      // Remove quotes on keys to have a denser object string representation
      const encoded = JSON.stringify(value).replace(/"([^(")"]+)":/g, '$1:');
      if (base64) return btoa(encoded);
      return encoded;
    }
    case Array: {
      const encoded = (value as Array<string>).join(',');
      if (base64) return btoa(encoded);
      return encoded;
    }
    case Boolean: {
      // Transform to 0 or 1 as a string
      return String(~~Boolean(value));
    }
    case String: {
      // Still, make sure to have a string
      const encoded = String(value);
      if (base64) return btoa(encoded);
      return encoded;
    }
    case Number: {
      // Cast any number to string
      return String(value);
    }
  }
}

/**
 * Default decoding function
 * @param value - Value to decode
 * @param type - Runtime type of the value
 * @param base64 - Whether to base64 decode the value or not
 */
function defaultDecode<TypeT>(
  value: string | undefined,
  type: RuntimeType<TypeT>,
  base64?: boolean
): TypeT | undefined {
  if (value === undefined) return undefined;

  switch (type) {
    case Object: {
      let decodedString = value;
      if (base64) decodedString = atob(decodedString);
      // Make sure that the provided object string has quotes on keys
      decodedString = decodedString.replace(
        /((?:")?([a-zA-Z0-9-_]+)(?:")?):/g,
        '"$2":'
      );
      return JSON.parse(decodedString);
    }
    case Array: {
      let decodedString = value;
      if (decodedString === '') return [] as TypeT;
      if (base64) decodedString = atob(decodedString);
      // Split to make it an array again
      return value.split(',') as TypeT;
    }
    case Boolean: {
      return Boolean(Number(value)) as TypeT;
    }
    case String: {
      if (base64) return atob(value) as TypeT;
      return value as TypeT;
    }
    case Number: {
      return Number(value) as TypeT;
    }
  }
}

const _queue = new WeakMap<VueRouter, Map<string, any>>();

export function useQueryParameter<TypeT>(
  options: UseQueryParameterOptions<TypeT>
): Ref<TypeT> {
  const name = computed(() => {
    const scope = toValue(options.scope);
    return scope ? `${scope}_${options.name}` : options.name;
  });

  const defaultValue = toValue(options.default);

  function encode(value: TypeT | undefined): string | undefined {
    return equal(value, defaultValue) && !options.showDefault
      ? undefined
      : options.encode
        ? // We encode a deep copy of the value to avoid potential reactivity side effects introduced by the
          // external `encode` method that could potentially mutate reactive references stored deeply in the value
          options.encode(copy(value))
        : defaultEncode(value, options.type, options.base64);
  }

  function decode(value: string): TypeT {
    return value !== undefined
      ? ((options.decode
          ? options.decode(value)
          : defaultDecode(value, options.type, options.base64)) ?? defaultValue)
      : defaultValue;
  }

  const route = useRoute();
  const router = useRouter();
  const isRouterReady = ref(false);
  router.onReady(() => {
    isRouterReady.value = true;
  });

  if (!_queue.has(router)) _queue.set(router, new Map());
  const _queriesQueue = _queue.get(router)!;

  function updateQuery(value?: string): void {
    _queriesQueue.set(name.value, value);

    nextTick(async () => {
      if (_queriesQueue.size === 0) return;

      const newQueries = Object.fromEntries(_queriesQueue.entries());
      _queriesQueue.clear();

      await until(isRouterReady).toBe(true);

      const { params, query, hash } = route;

      router.replace({
        params,
        query: { ...query, ...newQueries },
        hash,
      });
    });
  }

  let query: any = route.query[name.value];

  let _trigger: () => void;

  let _ref: Ref<TypeT> | WritableComputedRef<TypeT>;

  if ((options.get && !options.set) || (!options.get && options.set)) {
    throw new Error('You must either define both `get` and `set` or none');
  }
  if (options.get && options.set) {
    _ref = computed({
      get: options.get,
      set: options.set,
    });
    _ref.value = decode(query);
  } else {
    _ref = ref(decode(query)) as Ref<TypeT>;
  }

  watchOnce(isRouterReady, isReady => {
    if (isReady) {
      query = route.query[name.value];
      _ref.value = decode(query);

      const initialEncodedValue = encode(_ref.value);
      if (query !== initialEncodedValue) updateQuery(initialEncodedValue);

      options.onReady?.();
    }
  });

  const isActivated = ref(options.activated ?? false);
  const isActive = computed(
    () => isActivated.value && (options.active ? toValue(options.active) : true)
  );
  onActivated(() => {
    isActivated.value = true;
  });
  onDeactivated(() => {
    isActivated.value = false;
  });

  const proxy = customRef<TypeT>((track, trigger) => {
    _trigger = trigger;
    return {
      get() {
        track();
        if (_ref.value !== undefined) return _ref.value;

        return decode(query);
      },
      set(value) {
        _ref.value = value;

        if (!isActive.value) return;

        const encodedValue = encode(value);
        if (query === encodedValue) return;
        query = encodedValue;

        trigger();

        updateQuery(encodedValue);
      },
    };
  });

  function watchRouteQuery(): WatchStopHandle {
    return watch(
      () => route.query[name.value],
      value => {
        query = value;
        _trigger();
      },
      { flush: 'sync' }
    );
  }

  let routeQueryWatchStop = watchRouteQuery();

  watch(name, () => {
    query = route.query[name.value] as any;
    routeQueryWatchStop();
    routeQueryWatchStop = watchRouteQuery();
  });

  function syncRouteQuery(reset?: boolean): void {
    if (!isRouterReady.value) return;

    if (isActive.value) {
      const encodedValue = encode(_ref.value);
      if (query !== encodedValue) updateQuery(encodedValue);
    } else if (reset) {
      updateQuery(undefined);
    }
  }
  watch(isActive, () => syncRouteQuery(true));
  watch(route, () => syncRouteQuery(false));

  return proxy;
}
