<template lang="pug">
VMenu.ZUsagePicker(
  offset-y
  :close-on-content-click="false"
  :absolute="xs"
  :nudge-width="menuWidth"
  :position-x="positionX"
  :position-y="positionY"
  :disabled="isDisabled"
  content-class="ZUsagePicker__menu"
  :class="{ 'ZUsagePicker--disabled': isDisabled }"
  @input="onMenuChange"
)
  template(#activator)
    .ZUsagePicker__activator
      VBtn.ZUsagePicker__button(
        flat
        :right="xs"
        :icon="xs"
        :disabled="isDisabled"
      )
        template(v-if="!xs")
          VIcon.ZUsagePicker__icon(v-if="icon") {{ icon }}
          span {{ selectedUsageText }}
        VBadge.ZUsagePicker__badge(
          v-else
          color="info"
          overlap
          :value="selectedKeys.length > 0"
        )
          template(#badge)
            span {{ selectedKeys.length }}
          VIcon.ZUsagePicker__icon(v-if="icon") {{ icon }}
  ZCheckGroupAll.ZUsagePicker__checkGroupAll(
    ref="checkGroupAllRef"
    v-model="selectedKeys"
    :items="items"
    :select-all-text="allSelectedText"
    :select-by-text="t('app__filter_by')"
    :item-key="itemKey"
    :item-text="itemText"
  )
  VToolbar.ZUsagePicker__reset(
    v-if="items.length > 1"
    flat
    dense
  )
    VBtn(
      block
      flat
      @click="useSettings"
    ) {{ t('app__use_default_area_settings') }}
</template>

<style lang="stylus">
.ZUsagePicker__activator
  position relative

  span
    max-width 400px
    overflow hidden
    text-overflow ellipsis

    +media-down-sm()
      max-width 200px
      overflow hidden

    +media-down-xs()
      overflow visible

.ZUsagePicker__button
  margin-left 0
  margin-right 0

  +media-up-xs()
    .v-btn__content
      justify-content space-between

.ZUsagePicker__hint
  color alpha(black, 0.75)

.ZUsagePicker--disabled
  .v-menu__activator.v-menu__activator--disabled *
    cursor not-allowed

.ZUsagePicker__badge
  width 24px
  height 24px

.ZUsagePicker__icon
  padding-left 1px
  margin-bottom 1px

  +media-up-xs()
    margin-right 6px

.ZUsagePicker__menu
  display flex
  flex-direction column
  border-radius 16px
  box-shadow $raised-element !important

.ZUsagePicker__checkGroupAll
  max-height calc(100vh - 160px)

.ZUsagePicker__reset
  .v-toolbar__content
    justify-content center

  &.theme--dark.v-toolbar
    background-color: $colors.grey.darken-3

  &.theme--light.v-toolbar
    background-color white

  .theme--light.v-btn
    color: $colors.grey.darken-1

  .theme--dark.v-btn
    color: $colors.grey.lighten-1
</style>

<script setup lang="ts">
import { useI18n, usePreferences } from '@/composables/plugins';
import { useVModelProxy, useVuetifyBreakpoints } from '@/composables';

import ZCheckGroupAll from '@/components/ui/molecules/ZCheckGroupAll.vue';

import type { Item } from '@/components/ui/molecules/ZCheckGroupAll.vue';

export interface ZUsagePickerProps {
  /**
   * The value of the usage picker.
   */
  value?: string[];
  /**
   * The text to display when all usages are selected.
   */
  allSelectedText?: string;
  /**
   * The icon to display in the button.
   */
  icon?: string;
  /**
   * The items to display in the usage picker.
   */
  items?: Item[];
  /**
   * The key to use for the items.
   */
  itemKey?: string;
  /**
   * The text to use for the items.
   */
  itemText?: string;
  /**
   * Whether the usage picker is disabled.
   */
  disabled?: boolean;
}

const props = withDefaults(defineProps<ZUsagePickerProps>(), {
  value: () => [],
  allSelectedText: 'All',
  icon: undefined,
  items: () => [],
  itemKey: 'key',
  itemText: 'text',
  disabled: false,
});

defineEmits<{
  (name: 'input', value: string[]): void;
}>();

const positionY = 46;
const menuWidth = 320;

const { t } = useI18n();
const { xs } = useVuetifyBreakpoints();
const { width: windowWidth } = useWindowSize();
const { preferences } = usePreferences();

const checkGroupAllRef = ref<InstanceType<typeof ZCheckGroupAll> | null>(null);

const selectedKeys = useVModelProxy({ props });

const positionX = computed(() => (windowWidth.value - menuWidth) / 2);
const isDisabled = computed(() => props.disabled || !props.items.length);
const isSelectedAll = computed(() => {
  return (
    props.items.length > 1 &&
    (selectedKeys.value.length === props.items.length ||
      !selectedKeys.value.length)
  );
});
const selectedUsageText = computed(() => {
  if (isSelectedAll.value) {
    return props.allSelectedText;
  } else {
    return props.items
      .filter(item => selectedKeys.value.includes(item[props.itemKey]))
      .map(item => item[props.itemText])
      .join(', ');
  }
});

function onMenuChange(value: boolean): void {
  if (!value && checkGroupAllRef.value) {
    setTimeout(() => checkGroupAllRef.value?.clearInput(), 500);
  }
  if (!value && isSelectedAll.value) {
    setTimeout(() => (selectedKeys.value = []), 500);
  }
}

function useSettings(): void {
  const prefKeys = preferences.globalAreaUsage;
  const convertedKeys = prefKeys === '*' ? [] : (prefKeys ?? '').split(',');
  selectedKeys.value = convertedKeys;
}
</script>
