<template lang="pug">
VMenu.ZListMenu(
  v-model="isOpen"
  bottom
  :left="menuLeft || left"
  :right="menuRight || right"
  :offset-y="offsetY"
)
  template(#activator)
    VBtn.ZListMenu__activator(
      ref="activatorRef"
      flat
      :left="left"
      :right="right"
      icon
      :color="color"
      small
      @click="onClick"
    )
      VIcon(:small="small") mdi-dots-vertical
  VList.ZListMenu__list(
    dense
    :class="{ 'ZListMenu__list--denser': dense }"
  )
    template(v-for="(item, i) in items")
      ZAction(
        v-if="item.actionSettings"
        :key="'item-' + i"
        :action-settings="formatActionSettings(item.actionSettings)"
      )
      VListTile(
        v-else
        :key="'item-' + i"
        :class="{ 'with-icon': item.icon, [item.color + '--text']: item.color }"
        :disabled="item.disabled"
        @click="item.actionFunction"
      )
        VListTileAction
          ZIcon.mr-1(
            v-if="item.icon"
            :small="dense"
          ) {{ item.icon }}
        VListTileContent
          VListTileTitle {{ item.text }}
          VListTileSubTitle(v-if="item.hint")
            small {{ item.hint }}
    slot(:close="close")
</template>

<style lang="stylus">
.ZListMenu__activator.v-btn
  margin-top 0
  margin-bottom 0

.ZListMenu__list.v-list--dense
  .v-list__tile__action
    min-width 36px

  .v-list__tile--disabled
    .ZSvgIcon,
    .v-icon
      color: $colors.grey.base !important
      opacity 0.75

  &.ZListMenu__list--denser
    .v-list__tile
      padding 0 12px
      height 32px

    .v-list__tile,
    .action .v-list__tile
      .v-list__tile__action
        min-width 24px

    .with-icon
      .v-list__tile
        padding-left 8px

  .theme--dark&
    .v-list__tile--disabled .v-list__tile__sub-title
      color rgba(255, 255, 255, 0.35)

  .theme--light&
    .v-list__tile--disabled .v-list__tile__sub-title
      color rgba(0, 0, 0, 0.35)
</style>

<script setup lang="ts">
import { toggleScrollListener } from '@/lib/utils';

import ZAction from '@/components/ui/molecules/ZAction.vue';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface ListMenuItem<T extends ActionData<any> = ActionData> {
  /**
   * The action settings
   */
  actionSettings?: ActionSettings<T>;
  /**
   * The icon
   */
  icon?: string;
  /**
   * The color of the icon
   */
  color?: string;
  /**
   * Whether the item is disabled
   */
  disabled?: boolean;
  /**
   * The text of the item
   */
  text?: string;
  /**
   * The hint of the item
   */
  hint?: string;
  /**
   * The action function
   */
  actionFunction?: () => void;
}

export interface ZListMenuProps {
  /**
   * The list of items
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, vue/require-prop-comment
  items?: ListMenuItem<ActionData<any>>[];
  /**
   * The color of the menu
   */
  color?: string;
  /**
   * Whether the menu is dense
   */
  dense?: boolean;
  /**
   * Whether the menu activator button has the `left` prop
   */
  left?: boolean;
  /**
   * Whether the menu activator button has the `right` prop
   */
  right?: boolean;
  /**
   * Whether the menu is on the left
   */
  menuLeft?: boolean;
  /**
   * Whether the menu is on the right
   */
  menuRight?: boolean;
  /**
   * Offset the menu vertically
   */
  offsetY?: boolean;
  /**
   * Whether to stop propagation
   */
  stop?: boolean;
  /**
   * Display the menu icon small
   */
  small?: boolean;
}

const props = withDefaults(defineProps<ZListMenuProps>(), {
  items: () => [],
  color: '',
});

const isOpen = ref(false);
const activatorRef = ref();

function close(): void {
  isOpen.value = false;
}

function onClick(event: MouseEvent): void {
  if (!props.stop) return;
  event.stopPropagation();
  isOpen.value = true;
}

function formatActionSettings(
  settings: ListMenuItem['actionSettings']
): Record<string, unknown> {
  return {
    ...settings,
    iconProps: { small: props.dense },
    onTrigger: (self: ActionSelf) => {
      close();
      if (settings?.onTrigger) return settings?.onTrigger(self);
    },
  };
}

watch(isOpen, () => {
  toggleScrollListener(isOpen.value, close);
  if (activatorRef.value) {
    activatorRef.value?.$el.blur();
  }
});
</script>
