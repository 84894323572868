import toSnakeCase from 'to-snake-case';

import { useI18n, usePrivileges } from './plugins';

import routes from '@/router/routes';

import type { RouteConfig } from 'vue-router';

export type NavigationItem = RouteConfig & { displayedName: string };

export interface UseNavigationReturn {
  items: ComputedRef<NavigationItem[]>;
}

export function useNavigation(): UseNavigationReturn {
  const { isGranted } = usePrivileges();
  const { t } = useI18n();

  const items = computed(() =>
    routes
      .filter(route => {
        return (
          'meta' in route &&
          'showInNav' in route.meta &&
          route.meta.showInNav &&
          isGranted(route.meta.permission)
        );
      })
      .map(route => ({
        ...route,
        displayedName: t(`${toSnakeCase(route.name)}__menu_name`),
      }))
  );
  return {
    items,
  };
}
