import { HardwareBoardType, HardwareProjectType } from '#/core-api/enums';

export function bikeHardwareProjectType(
  type: HardwareProjectType
): DisplayProperties {
  switch (type) {
    case HardwareProjectType.ProjectUnknown:
      return {
        message: 'None',
      };
    case HardwareProjectType.ProjectMain01:
      return {
        message: 'Main01',
      };
    case HardwareProjectType.ProjectController01:
      return {
        message: 'Controller01',
      };
    case HardwareProjectType.ProjectMain02:
      return {
        message: 'Main02',
      };
    case HardwareProjectType.ProjectController02:
      return {
        message: 'Controller02',
      };
    case HardwareProjectType.ProjectZoovbox01:
      return {
        message: 'Zoovbox01',
      };
    case HardwareProjectType.ProjectMainbox01:
      return {
        message: 'Mainbox01',
      };
    case HardwareProjectType.ProjectFlex:
      return {
        message: 'Flex',
      };
    case HardwareProjectType.ProjectController03:
      return {
        message: 'Controller03',
      };
    case HardwareProjectType.ProjectRfid01:
      return {
        message: 'Rfid01',
      };
    case HardwareProjectType.ProjectHeavyLock01:
      return {
        message: 'Lock01',
      };
    case HardwareProjectType.ProjectMain04:
      return {
        message: 'Main04',
      };
    case HardwareProjectType.ProjectRfid02:
      return {
        message: 'Rfid02',
      };
    case HardwareProjectType.ProjectTestStation:
      return {
        message: 'TestStation',
      };
    case HardwareProjectType.ProjectDynamo01:
      return {
        message: 'Dynamo01',
      };
    default:
      return {
        message: 'Unknown type: ' + type,
      };
  }
}

export function bikeHardwareProjectBoardType(
  type: HardwareBoardType
): DisplayProperties {
  switch (type) {
    case HardwareBoardType.UnknownBoard:
      return {
        message: 'None',
      };
    case HardwareBoardType.Cpu:
      return {
        message: 'CPU',
      };
    case HardwareBoardType.Comm:
      return {
        message: 'Comm',
      };
    case HardwareBoardType.Bridge:
      return {
        message: 'Bridge',
      };
    case HardwareBoardType.BridgeTop:
      return {
        message: 'Bridge Top',
      };
    case HardwareBoardType.Power:
      return {
        message: 'Power',
      };
    case HardwareBoardType.ZoovboxMain:
      return {
        message: 'Zoovbox Main',
      };
    case HardwareBoardType.ZoovboxTouch:
      return {
        message: 'Zoovbox Touch',
      };
    case HardwareBoardType.EdockMain:
      return {
        message: 'Edock Main',
      };
    case HardwareBoardType.EdockDetect:
      return {
        message: 'Edock Detect',
      };
    case HardwareBoardType.FlexCom:
      return {
        message: 'Flex Com',
      };
    case HardwareBoardType.Rfid:
      return {
        message: 'Rfid',
      };
    case HardwareBoardType.HeavyLock:
      return {
        message: 'Heavy Lock',
      };
    case HardwareBoardType.Dynamo:
      return {
        message: 'Dynamo',
      };
    default:
      return {
        message: 'Unknown type: ' + type,
      };
  }
}
