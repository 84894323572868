import destr from 'destr';

import { objectRemoveKeys } from '@/lib/utils';

/**
 * Check if the display field key is matching fully or partially a field
 * @param displayField
 * @param field
 * @returns True if the display field key is matching the field
 */
export function isDisplayFieldKeyMatchingField(
  displayField: DisplayField,
  field: string
): boolean {
  return !!new RegExp(`(^|\\.)${field}($|\\.)`).exec(displayField.key);
}

/**
 * Deeply format history data with a use of a recursive call to formatField for each field
 * @param entries - History data fields
 * @param key - The key against which we format the fields
 * @param modelName - The model or sub model name
 */
export function deepFormatHistoryData(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  entries: any[],
  key: string,
  modelName: ExtendedModelName | undefined,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  format: (formatOptions: DisplayFieldOptions, data: any) => DisplayField[]
): Partial<DisplayField>[] {
  const output = entries
    .map(entry => {
      const keys = entry.field.split('.');
      const firstKey = keys[0];
      let lastKey = keys.pop();
      let isArray = false;
      // While last key is a number => The update occurred on an array. Use the previous key targeting
      // the array, and transform the entry as an array so that it is properly handled by formatter
      while (/^\d+$/.test(lastKey)) {
        isArray = true;
        lastKey = keys.pop();
      }
      // Get final value
      const value =
        firstKey === 'metadata' // transtype value if it is from metadata
          ? destr(entry[key])
          : isArray && ![null, undefined].includes(entry[key])
            ? [entry[key]]
            : entry[key];

      return format(lastKey ? { [lastKey]: value } : {}, {
        modelName,
        ref: keys.join('.'),
        context: 'history-deep-formatting',
      });
    })
    .map(item => objectRemoveKeys(Object.values(item)[0] ?? {}, ['key']));

  return output;
}
