<template lang="pug">
VRadioGroup.ZActionControlRadioGroup(
  v-show="canShow"
  ref="componentRef"
  v-model="modelValue"
  :style="style"
  :label="label"
  :rules="rules"
  :disabled="isDisabled"
  :hint="hint"
  :messages="message"
  :hide-details="areDetailsHidden"
  v-bind="additionalProps"
)
  VRadio(
    v-for="radio in radios"
    :key="`${String(control.key)}-radio-${radio.value}`"
    :color="themeColor(radio.color) || color"
    :disabled="radio.disabled"
    :value="control.type === Boolean ? String(radio.value) : radio.value"
    v-bind="radio.props"
  )
    template(#label)
      component(
        :is="radio.hint ? 'ZTooltip' : 'span'"
        right
        hint
      )
        // eslint-disable-next-line vue/no-deprecated-slot-attribute
        .ZActionControlRadioGroup__radio(slot="activator")
          ZIcon.ZActionControlRadioGroup__radio__icon(
            v-if="radio.icon"
            small
            :color="generate(control.disabled) || radio.disabled ? '' : themeColor(radio.color)"
          ) {{ radio.icon }}
          span {{ tin(radio.label ?? '') }}
        span(v-html="tin(radio.hint ?? '')")
</template>

<style lang="stylus">
.ZActionControlRadioGroup
  .v-radio.v-radio--is-disabled
    .v-label
      pointer-events all
      cursor default

  &.v-input--radio-group--row
    .v-label
      width 100%

    .v-radio
      margin-right 14px
      padding-top 6px

.ZActionControlRadioGroup__radio
  display flex
  align-items center
  flex 1 1 auto
  max-width 100%

.ZActionControlRadioGroup__radio__icon
  margin-right 8px

  +media-down-xxs()
    display none
</style>

<script setup lang="ts">
import { useActionControl } from './useActionControl';

import { useI18n } from '@/composables/plugins';
import { useColors } from '@/composables';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Data = any;

interface ZActionControlRadioGroupProps {
  /**
   * The control object
   */
  control: ActionControl<'radioGroup', keyof Data, Data>;
  /**
   * The parent action 'data' object
   */
  data: Data;
  /**
   * The control’s value
   * @model
   */
  value?: boolean | string | number;
}

const props = withDefaults(defineProps<ZActionControlRadioGroupProps>(), {
  value: false,
});

const {
  modelValue,
  generate,
  canShow,
  style,
  label,
  rules,
  color,
  isDisabled,
  hint,
  message,
  areDetailsHidden,
  additionalProps,
} = useActionControl(props);

const componentRef = ref<TemplateRef | null>(null);

const { themeColor } = useColors();
const { tin } = useI18n();

const radios = computed(() => generate(props.control.radios));

function focus(): void {
  componentRef.value?.focus?.();
}

function resetValidation(): void {
  componentRef.value?.resetValidation?.();
}

defineExpose({
  /**
   * Focus the input
   */
  focus,
  /**
   * Reset input validation
   */
  resetValidation,
});
</script>
