<!-- eslint-disable vue/multi-word-component-names -->
<template lang="pug">
.Privilege(
  v-if="isComponentVisible"
  :class="classes"
)
  slot
</template>

<style lang="stylus">
.Privilege--inconsistent
  opacity 0.45
  cursor not-allowed

  > *
    pointer-events none
</style>

<script setup lang="ts">
import { usePrivileges } from '@/composables/plugins';

export interface PrivilegeProps {
  /**
   * The permission to check.
   */
  permission: string;
}

const props = defineProps<PrivilegeProps>();

const { isGranted } = usePrivileges();
const isPermissionGranted = computed(() => isGranted(props.permission));
const isComponentVisible = computed(() => {
  return isPermissionGranted.value === undefined || isPermissionGranted.value;
});

const classes = computed(() => ({
  'Privilege--inconsistent': isPermissionGranted.value === undefined,
}));
</script>
