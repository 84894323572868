export const fields = [
  'allowed_targets',
  'area_ids',
  'available_from',
  'available_until',
  'benefit_validity',
  'code',
  'create_rental',
  'created_at',
  'cumulative',
  'currency',
  'details',
  'i18n_summary',
  'i18n_title',
  'id',
  'metadata',
  'name',
  'obtention_method_type',
  'pack_offer_ids',
  'paused_at_validation',
  'pending_validation_at_creation',
  'plan',
  'pre_auth_amount',
  'price',
  'rental_config',
  'restricted_visibility',
  'multiple_per_target',
  'rules',
  'rules_refill_cycle',
  'scope_id',
  'summary',
  'title',
  'type',
  'updated_at',
  'extra_properties',
] satisfies ModelField<'payment.Offer'>[];
