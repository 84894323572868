import merge from 'deepmerge';
import { createSearchRegexString } from '@fifteen/shared-lib';

import {
  booleanFalseQuery,
  stringNotExistsQuery,
  buildQueryValue,
  isNullish,
} from '@/lib/utils';

function isSubqueryFilter(filter: Filter): boolean {
  return !!filter.config.subquery;
}

export function buildQueries(filters: Filter[]): {
  query: MongoQuery;
  subqueries: Subqueries;
} {
  const query = filters
    .filter(filter => !isSubqueryFilter(filter))
    .reduce<MongoQuery>((query, filter) => {
      const { config } = filter;

      // If build query function is provided in filter config, the query formatting logic is handled filter side.
      if (config.buildQuery) {
        return merge(query, {
          $and: [config.buildQuery(filter)],
        });
      }

      return buildQueryFromFilter(filter, query);
    }, {});

  const subqueries = filters
    .filter(isSubqueryFilter)
    .reduce<Subqueries>((subqueries, filter) => {
      const { config } = filter;
      const [modelName, ...keys] = [...config.key.split('.')];

      const unprefixedKeyFilter: Filter = {
        ...filter,
        config: { ...filter.config, key: keys.join('.') },
      };

      const builtQuery = config.buildQuery
        ? merge(subqueries, {
            $and: [config.buildQuery(unprefixedKeyFilter)],
          })
        : buildQueryFromFilter(
            unprefixedKeyFilter,
            subqueries[modelName]?.query
          );

      subqueries[modelName] = {
        // The query object in subqueries has to be stringified
        query: JSON.stringify(builtQuery),
      };

      return subqueries;
    }, {});
  return { query, subqueries };
}

export function buildQueryFromFilter<Type extends FilterType>(
  filter: Filter,
  query: MongoQuery = {}
): MongoQuery {
  const { config, populatedValue, value, empty, negation } = filter;

  const isPopulated = !isNullish(populatedValue);

  const queryKey = config.populate
    ? isPopulated
      ? config.populate?.responseField.as
      : (config.populate?.queryField ?? config.queryField)
    : config.queryField || config.key;

  if (!queryKey) return query;

  const queryValue = isPopulated ? populatedValue : value;

  // handle specific boolean cases
  if (config.type === Boolean && !value) {
    // false boolean (find also non existing entries)
    if (!query.$and) query.$and = [];
    query.$and.push(booleanFalseQuery(queryKey, config.negation));
  }
  // handle all the other cases
  else {
    // if filter is a request on empty field
    if (config.empty && empty) {
      if (config.type === String) {
        if (!query.$and) query.$and = [];
        query.$and.push(stringNotExistsQuery(queryKey, negation));
      } else {
        query[queryKey] = { $exists: 0 };
      }
    }
    // handle array of values by type
    else if (Array.isArray(queryValue)) {
      // if it is a string without exact match (regex), compose regex
      if (config.type === String && !config.exactMatch) {
        const captureGroup =
          '(' +
          (queryValue ?? [])
            .map(val =>
              config.diacriticsSensitive
                ? (val as string)
                : createSearchRegexString(val as string)
            )
            .join('|') +
          ')';
        query[queryKey] = buildQueryValue(filter, captureGroup as Type, false);
      } else {
        const _queryValue = queryValue.flatMap(val =>
          Number(val) === 0 && config.type === Number
            ? config.exactlyAll
              ? 0
              : [0, null]
            : buildQueryValue(filter, val as Type, false)
        );
        if (config.exactlyAll) {
          query[queryKey] = { $all: _queryValue, $size: queryValue.length };
        } else {
          query[queryKey] = { $in: _queryValue };
        }
      }
    }
    // handle non array by type
    else {
      query[queryKey] = buildQueryValue(filter);
    }
  }

  // handle negation
  if (negation) {
    // if queryKey entry exists, negate it
    if (query[queryKey]) {
      let negateOperator = '$not';
      const isExactString = config.type === String && config.exactMatch;
      if (!Array.isArray(queryValue) && isExactString) {
        negateOperator = '$ne';
      }
      query[queryKey] = { [negateOperator]: query[queryKey] };
    }
  }

  return query;
}
