import StoreModule from '@/store/core/store-module';

import type { JSONSchema4 } from '#/core-api';
import type { Get } from '@/store/types/core-api';
import type { OverridePayload } from '@/store/types/helpers/store-types';

declare global {
  /**
   * Metadata registry interface.
   */
  type MetadataRegistry = JSONSchema4;

  type MetadataRegistryModel =
    | 'issue/issue'
    | 'user/user'
    | 'rental/rental'
    | 'payment/offer'
    | 'payment/benefit'
    | 'station/urban_furniture'
    | 'entity/vehicle'
    | 'entity/point_of_interest'
    | 'ticket/ticket';
  type MetadataField = 'metadata' | 'extra_properties';
}

export interface MetadataRegistryStoreTypes {
  METADATA_REGISTRY: [
    OverridePayload<
      Get<'/metadata/{ModelName}', MetadataRegistry>,
      { path: string; model_name: never }
    >,
  ];
}

const metadataRegistryModule = new StoreModule<MetadataRegistryStoreTypes>();
metadataRegistryModule.createAction({
  type: 'METADATA_REGISTRY',
  requestMethod: 'GET',
  requestEndPoint: '/metadata/:path',
  transform: data => data.registry,
});

export default metadataRegistryModule;
