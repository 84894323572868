import {
  TicketStatus,
  TripStatus,
  TargetType,
  BikeStatus,
  OfferType,
  BookingStatus,
  TicketTarget,
} from '#/core-api/enums';

import type { ApiSchema } from '#/core-api';
import type {
  PopulateConfig,
  PopulatedField,
  PopulatedFieldArray,
} from '@/store/core/populate';

interface InternalEmailPopulateConfigOptions
  extends Pick<
    PopulateConfig,
    'dataField' | 'onCollectionNames' | 'condition'
  > {
  withWarehouse?: boolean;
  withSoftUnlockTimeout?: boolean;
  asKey?: string;
  inject?: boolean;
  name?: string;
}

export function internalEmailPopulateConfig({
  dataField,
  onCollectionNames,
  asKey,
  inject,
  name,
  condition,
}: InternalEmailPopulateConfigOptions): PopulateConfig {
  return {
    permission: 'gateway.user.list',
    name,
    dataField,
    dataType: String,
    actionType: 'GET_USERS',
    queryField: 'id',
    responseFields: [{ key: 'email', as: asKey || 'creator', inject }],
    cacheTimeout: 24 * 60 * 60 * 1000, // emails are unlikely to change
    ...(onCollectionNames && { onCollectionNames }),
    condition,
  };
}

interface UserEmailPopulateConfigOptions {
  withWarehouse?: boolean;
  withSoftUnlockTimeout?: boolean;
  dataField?: PopulateConfig['dataField'];
  asKey?: string;
  inject?: boolean;
}

export function userEmailPopulateConfig({
  dataField,
  asKey,
  inject,
}: UserEmailPopulateConfigOptions = {}): PopulateConfig {
  return {
    permission: 'gateway.user.read.gdpr_fields',
    dataField: dataField || 'user_id',
    dataType: String,
    actionType: 'GET_USERS',
    queryField: 'id',
    responseFields: [{ key: 'email', as: asKey || 'user', inject }],
    cacheTimeout: 24 * 60 * 60 * 1000, // emails are unlikely to change
  };
}

interface AreaPopulateConfigOptions {
  withWarehouse?: boolean;
  withSoftUnlockTimeout?: boolean;
  dataField?: PopulateConfig['dataField'];
  asKey?: string;
}

export function areasPopulateConfig({
  withWarehouse,
  withSoftUnlockTimeout,
  dataField,
  asKey,
}: AreaPopulateConfigOptions = {}): PopulateConfig {
  return {
    name: 'area',
    dataField: dataField || 'area_id',
    dataType: String,
    actionType: 'GET_AREAS',
    queryField: 'id',
    responseFields: [
      { key: 'label', as: asKey || 'area' },
      ...(withWarehouse ? [{ key: 'warehouse', as: 'warehouse' }] : []),
      ...(withSoftUnlockTimeout ? [{ key: 'soft_unlock_timeout' }] : []),
    ],
    cacheTimeout: 24 * 60 * 60 * 1000, // areas are unlikely to change
  };
}

export function offersPopulateConfig({
  dataField,
}: {
  dataField: PopulateConfig['dataField'];
}): PopulateConfig {
  return {
    name: 'offers',
    dataField: dataField,
    dataType: String,
    actionType: 'GET_OFFERS',
    queryField: 'id',
    responseFields: [{ key: 'name', as: 'offers' }],
  };
}

export function issueCategoriesPopulateConfig(): PopulateConfig {
  return {
    name: 'categories',
    dataField: 'category_ids',
    dataType: String,
    actionType: 'GET_ISSUE_CATEGORIES',
    queryField: 'id',
    responseFields: [{ key: ['id', 'i18n_name', 'name'], as: 'categories' }],
  };
}

export function issueAreasPopulateConfig(): PopulateConfig {
  return {
    name: 'issue-areas',
    dataField: 'selector_ids',
    dataType: String,
    actionType: 'GET_AREAS',
    queryField: 'id',
    responseFields: [{ key: 'label', as: 'areas' }],
    cacheTimeout: 24 * 60 * 60 * 1000, // areas are unlikely to change
    condition: issue => !issue?.selector_ids?.includes('*'),
  };
}
export function issueScopesPopulateConfig(): PopulateConfig {
  return {
    name: 'issue-scopes',
    dataField: 'selector_ids',
    dataType: String,
    actionType: 'GET_AUTH_SCOPES',
    queryField: 'id',
    responseFields: [{ key: 'name', as: 'scopes' }],
    cacheTimeout: 24 * 60 * 60 * 1000, // scopes are unlikely to change
    condition: issue => !issue?.selector_ids?.includes('*'),
  };
}

export function stationsPopulateConfig({
  dataField,
}: {
  dataField?: PopulateConfig['dataField'];
} = {}): PopulateConfig {
  return {
    name: 'station',
    dataField: dataField || 'station_id',
    dataType: String,
    actionType: 'GET_STATIONS',
    queryField: 'id',
    responseFields: [{ key: 'label', as: 'station' }],
    cacheTimeout: 60 * 1000, // station labels are less likely to change
  };
}

export function inUsePopulateConfig(): PopulateConfig {
  return {
    permission: 'gateway.user.read.customer_fields',
    dataField: 'id',
    dataType: String,
    actionType: 'GET_TRIPS',
    queryField: 'user_id',
    responseFields: [
      { key: 'id', as: 'ongoing_trip' },
      { key: 'started_at', as: 'ongoing_trip_started_at' },
      { key: 'vehicle_id', as: 'used_vehicle' },
    ],
    additionalQuery: {
      status: { $in: [TripStatus.Started, TripStatus.Paused] },
    },
  };
}

export type FleetProductPopulatedType<OriginalData> = {
  environment?: PopulatedField<
    OriginalData,
    NonNullable<ApiSchema['fleet.Product']['environment']>['display_name']
  >;
  target_firmware?: PopulatedField<
    OriginalData,
    NonNullable<ApiSchema['fleet.Product']['deploy_group']>['firmware']
  >;
  deploy_group?: PopulatedField<
    OriginalData,
    ApiSchema['fleet.Product']['deploy_group']
  >;
  sim?: PopulatedField<OriginalData, ApiSchema['fleet.Product']['sim']>;
  target_config_crc?: PopulatedField<
    OriginalData,
    ApiSchema['fleet.Product']['config_crc']
  >;
  generated_product_config?: PopulatedField<
    OriginalData,
    ApiSchema['fleet.Product']['generated_product_config']
  >;
};

export function fleetProductPopulateConfig({
  dataField,
}: {
  dataField: string;
}): PopulateConfig {
  return {
    name: 'fleet_product',
    dataField,
    dataType: Number,
    actionType: 'GET_FLEET_PRODUCT',
    queryField: 'serial_number',
    asPathParameter: true,
    responseFields: [
      { key: 'environment.display_name', as: 'environment' },
      { key: 'deploy_group.firmware', as: 'target_firmware' },
      { key: 'deploy_group' },
      { key: 'sim' },
      { key: 'config_crc', as: 'target_config_crc' },
      { key: 'generated_product_config' },
    ],
  };
}

export function scopePopulateConfig(
  dataField = 'scope_ids',
  responseFieldAs = 'scopes'
): PopulateConfig {
  return {
    dataField,
    dataType: String,
    actionType: 'GET_AUTH_SCOPE',
    queryField: 'id',
    asPathParameter: true,
    responseFields: [{ key: 'name', as: responseFieldAs }],
    cacheTimeout: 24 * 60 * 60 * 1000,
  };
}

export function payAsYouGoPopulateConfig(): PopulateConfig {
  return {
    name: 'benefit',
    dataField: 'id',
    dataType: String,
    actionType: 'GET_BENEFITS',
    queryField: 'target.id',
    responseIsArray: true,
    responseFields: [
      { key: 'name', as: 'pay_as_you_go' },
      { key: 'id', as: 'pay_as_you_go_id' },
    ],
    additionalQuery: { type: OfferType.SharingPricing },
  };
}

export function bookingPopulateConfig({
  dataField,
  responseFields,
}: {
  dataField: PopulateConfig['dataField'];
  responseFields?: PopulateConfig['responseFields'];
}): PopulateConfig {
  return {
    name: 'booking',
    dataField: dataField,
    dataType: String,
    actionType: 'GET_BOOKINGS',
    queryField: 'pickup.id',
    responseFields: responseFields ?? [
      { key: 'id', as: 'current_booking' },
      { key: 'booked_at', as: 'current_booking_booked_at' },
      { key: 'user_id', as: 'booked_by' },
    ],
    additionalQuery: { status: BookingStatus.Pending },
  };
}

export type FastenedBikesPopulatedType =
  (ApiSchema['station.FastenedProduct'] & {
    [Key in
      | 'maintenance_state'
      | 'out_of_order'
      | 'status'
      | 'battery_community']: PopulatedField<
      ApiSchema['station.FastenedProduct']['serial_number'],
      ApiSchema['bike.Bike'][Key]
    >;
  })[];

export type FastenedVehiclesPopulatedType =
  (ApiSchema['station.FastenedProduct'] & {
    [Key in
      | 'maintenance_state'
      | 'is_rented'
      | 'is_reserved'
      | 'vehicle_state']: PopulatedField<
      ApiSchema['station.FastenedProduct']['vehicle_id'],
      ApiSchema['entity.Vehicle'][Key]
    >;
  })[];

export function vehiclesPopulateConfig(
  {
    dataField,
    onCollectionNames,
    responseFields,
  }: {
    onCollectionNames?: PopulateConfig['onCollectionNames'];
    dataField?: PopulateConfig['dataField'];
    responseFields: PopulateConfig['responseFields'];
  } = {
    onCollectionNames: ['products'],
    dataField: 'vehicle_id',
    responseFields: [
      { key: 'maintenance_state' },
      { key: 'is_rented' },
      { key: 'is_reserved' },
      { key: 'vehicle_state' },
    ],
  }
): PopulateConfig {
  return {
    onCollectionNames,
    dataField,
    dataType: String,
    actionType: 'GET_VEHICLES',
    queryField: 'id',
    responseFields,
  };
}

export const stationNbBookedBikesPopulateConfig: PopulateConfig = {
  name: 'nb_booked_bikes',
  dataField: 'id',
  dataType: String,
  queryField: 'station_id',
  actionType: 'GET_BIKES_COUNT',
  responseFields: [{ key: 'count', as: 'nb_booked_bikes' }],
  isCount: true,
  additionalQuery: { status: BikeStatus.Booked },
};

export const stationNbReservedVehiclesPopulateConfig: PopulateConfig = {
  name: 'nb_reserved_vehicles',
  dataField: 'id',
  dataType: String,
  queryField: 'station_id',
  actionType: 'GET_VEHICLES_COUNT',
  responseFields: [{ key: 'count', as: 'nb_reserved_vehicles' }],
  isCount: true,
  additionalQuery: { is_rented: true },
};

const stationFastenedBikesFields = [
  'serial_number',
  'status',
  'maintenance_state',
  'lock_info',
  'out_of_order',
  'lwm2m_info',
  'battery_community',
  'station_id',
  'fastener_id',
] satisfies (keyof ApiSchema['bike.Bike'])[];

const stationFastenedVehiclesFields = [
  'id',
  'maintenance_state',
  'is_rented',
  'is_reserved',
  'vehicle_state',
  'station_id',
] satisfies (keyof ApiSchema['entity.Vehicle'])[];

export type StationFastenedBikesPopulatedType = {
  fastened_bikes?: PopulatedFieldArray<
    ApiSchema['station.Station']['id'],
    Pick<
      ApiSchema['bike.Bike'],
      (typeof stationFastenedBikesFields)[number]
    > & { downplay?: boolean }
  >;
};

export type StationFastenedVehiclesPopulatedType = {
  fastened_vehicles?: PopulatedFieldArray<
    ApiSchema['station.Station']['id'],
    Pick<
      ApiSchema['entity.Vehicle'],
      (typeof stationFastenedVehiclesFields)[number]
    > & { downplay?: boolean }
  >;
};

export const stationFastenedBikesPopulateConfig: PopulateConfig = {
  name: 'fastened_bikes',
  dataField: 'id',
  dataType: String,
  actionType: 'GET_BIKES',
  queryField: 'station_id',
  additionalQuery: { fastener_id: { $exists: true, $ne: '' } },
  responseFields: [
    {
      key: stationFastenedBikesFields,
      as: 'fastened_bikes',
    },
  ],
  responseIsArray: true,
};

export const stationFastenedVehiclesPopulateConfig: PopulateConfig = {
  name: 'fastened_vehicles',
  dataField: 'id',
  dataType: String,
  actionType: 'GET_VEHICLES',
  queryField: 'station_id',
  additionalQuery: { parking_id: { $exists: true, $ne: '' } },
  responseFields: [
    {
      key: stationFastenedVehiclesFields,
      as: 'fastened_vehicles',
    },
  ],
  responseIsArray: true,
};

const stationFastenersFields = [
  'id',
  'label',
  'serial_number',
  'type',
  'capacity',
  'products',
  'location',
  'status',
  'products',
  'maintenance_state',
  'lwm2m_info',
  'allowed_in_order_status',
  'station_id',
  'virtual_area_encoded',
] satisfies (keyof ApiSchema['station.Fastener'])[];

export type StationFastenersPopulatedType = {
  fasteners?: PopulatedFieldArray<
    ApiSchema['station.Station']['id'],
    Pick<ApiSchema['station.Fastener'], (typeof stationFastenersFields)[number]>
  >;
};

export const stationFastenersPopulateConfig: PopulateConfig = {
  name: 'fasteners',
  dataField: 'id',
  dataType: String,
  actionType: 'GET_FASTENERS',
  queryField: 'station_id',
  responseFields: [
    {
      key: stationFastenersFields,
      as: 'fasteners',
    },
  ],
  responseIsArray: true,
};

export function issueTargetPopulateConfig({
  target,
}: {
  target: TicketTarget;
}): PopulateConfig {
  const targetPopulateConfig: Pick<
    PopulateConfig,
    'actionType' | 'responseDataName' | 'responseFields'
  > = (() => {
    switch (target) {
      default:
      case TicketTarget.Parking:
        return {
          actionType: 'GET_FASTENERS',
          responseFields: [{ key: 'label', as: 'target_fastener' }],
        };
      case TicketTarget.Station:
        return {
          actionType: 'GET_STATIONS',
          responseFields: [{ key: 'label', as: 'target_station' }],
        };
    }
  })();
  return {
    dataField: 'target_id',
    dataType: String,
    queryField: 'id',
    cacheTimeout: 60 * 1000, // station or fastener labels are not likely to change
    condition: (issue: Issue) => issue.target_model === target,
    ...targetPopulateConfig,
  };
}

export function openIssuesPopulateConfig({
  dataField,
}: {
  dataField: PopulateConfig['dataField'];
}): PopulateConfig {
  return {
    name: 'open_issues',
    dataField,
    dataType: String,
    actionType: 'GET_ISSUES',
    queryField: 'target_id',
    responseFields: [{ key: ['id', 'label', 'severity'], as: 'open_issues' }],
    responseIsArray: true,
    additionalQuery: { status: TicketStatus.Open },
  };
}

export function benefitTargetPopulateConfig({
  target,
}: {
  target: TargetType;
}): PopulateConfig {
  const targetPopulateConfig: Pick<
    PopulateConfig,
    'actionType' | 'responseDataName' | 'responseFields' | 'permission'
  > = (() => {
    switch (target) {
      default:
      case TargetType.User:
        return {
          actionType: 'GET_USERS',
          responseFields: [{ key: 'email', as: 'target_user' }],
          permission: 'gateway.user.read.gdpr_fields',
        };
      case TargetType.Area:
        return {
          actionType: 'GET_AREAS',
          responseFields: [{ key: 'label', as: 'target_area' }],
        };
      case TargetType.Station:
        return {
          actionType: 'GET_STATIONS',
          responseFields: [{ key: 'label', as: 'target_station' }],
        };
    }
  })();
  return {
    dataField: 'target.id',
    dataType: String,
    queryField: 'id',
    cacheTimeout: 60 * 1000, // user emails, areas or fastener labels are not likely to change
    condition: (benefit: ApiSchema['payment.Benefit']) =>
      benefit.target?.target_type === target,
    ...targetPopulateConfig,
  };
}

export function ongoingTripPopulateConfig({
  dataField,
}: {
  dataField: PopulateConfig['dataField'];
}): PopulateConfig {
  return {
    name: 'trip',
    dataField,
    dataType: String,
    actionType: 'GET_TRIPS',
    queryField: 'vehicle_id',
    responseFields: [
      { key: 'id', as: 'ongoing_trip' },
      { key: 'started_at', as: 'ongoing_trip_started_at' },
      { key: 'user_id', as: 'used_by' },
    ],
    additionalQuery: {
      status: { $in: [TripStatus.Started, TripStatus.Paused] },
    },
  };
}

export function destinationTripPopulateConfig(): PopulateConfig {
  return {
    dataField: 'destination.station_id',
    dataType: String,
    actionType: 'GET_STATIONS',
    queryField: 'id',
    responseFields: [{ key: 'label', as: 'destination.label' }],
    condition: ({ destination }) => destination?.station_id,
  };
}
export function pickupTripPopulateConfig(): PopulateConfig {
  return {
    dataField: 'pickup.parking_id',
    dataType: String,
    actionType: 'GET_PARKINGS',
    queryField: 'id',
    responseFields: [
      {
        key: ['label', 'station_id', 'parking_type'],
        as: 'pickup.parking',
      },
    ],
    condition: ({ pickup }) => pickup?.parking_id,
  };
}
export function depositTripPopulateConfig(): PopulateConfig {
  return {
    dataField: 'deposit.parking_id',
    dataType: String,
    actionType: 'GET_PARKINGS',
    queryField: 'id',
    responseFields: [
      {
        key: ['label', 'station_id', 'parking_type'],
        as: 'deposit.parking',
      },
    ],
    condition: ({ deposit }) => deposit?.parking_id,
  };
}
