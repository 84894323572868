import { watchImmediate } from '@vueuse/core';
import { useHead } from '@unhead/vue';

import { useCookieConsent } from '@/composables';

const isInit = ref(false);

/**
 * User tracking, using third-party services
 */
export function useUserTracking(): void {
  const { choices } = useCookieConsent();
  const isClarityConsented = computed(() => choices.value.clarity);

  if (!isInit.value) {
    watchImmediate(isClarityConsented, () => {
      if (window.VUE_APP_CLARITY_CLIENT_ID) {
        useHead({
          script: [
            {
              type: 'text/javascript',
              children: `
  (function(c,l,a,r,i,t,y){
      c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
      t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
      y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
  })(window, document, "clarity", "script", "${window.VUE_APP_CLARITY_CLIENT_ID}");
`,
            },
          ],
        });
      }

      isInit.value = true;
    });
  }

  if (window.VUE_APP_PIRSCH_TRACKING_CODE) {
    useHead({
      script: [
        {
          src: 'https://api.pirsch.io/pa.js',
          defer: true,
          id: 'pianjs',
          'data-code': window.VUE_APP_PIRSCH_TRACKING_CODE,
        },
      ],
    });
  }
}
