/**
 * Datacenter error codes (range 11000-11999)
 */
export enum DatacenterErrorCode {
  InternalServerError = 11001,
  MalformedRequest,
  ResourceForbidden,
  NotFound,
  InvalidToken,
  AuthenticationError,
}

/**
 * Data operations error codes (range 12000-12999)
 */
export enum DataOperationsErrorCode {
  InternalError = 12000,
  AuthentificationError = 12001,
  MongoUpsertError = 12002,
  ValidationError = 12003,
}

/**
 * These codes are specific for Force ended trips. They are not mapped to error
 * messages because they are never displayed in app, they are set by the app as trip’s
 * error code in a force-end trip action.
 */
export enum ForceEndedTripErrorCode {
  DischargedPhone = 9002,
  NoNetwork = 9003,
  ErrorSyncAppBackend = 9004,
  ForgotToEndTrip = 9005,
  MisunderstoodProcess = 9006,
  BikeDroppedInForbiddenArea = 9007,
  BikeDroppedOutsideServiceArea = 9008,
  Other = 9009,
  CouldNotContactUser = 9010,
  BikeDroppedInCriticalArea = 9011,
  StackNotDetected = 9012,
  LostBLEConnection = 9013,
  TechnicalIssuePreventingUserToEndTrip = 9014,
  AbusivePause = 9015,
  FullStation = 9052,
  CouldNotEndDespiteStackDetection = 9053,
  DischargedVehicle = 9054,
  NoElectricalAssistance = 9055,
  MechanicalProblem = 9056,
  AbandonedBike = 9057,
}

/**
 * Error codes for errors handled by the App
 */
export enum AppErrorCode {
  InternalError = 9000,

  // App errors
  NoDataAvailable = 9016,
  WrongYamlFileExtension = 9017,
  FileTooLarge = 9018,
  InvalidQrCode = 9019,
  BatchActionCancelled = 9020,
  BatchActionAllFailed = 9021,
  InvalidOrEmptyYamlFile = 9022,
  MissingPermissionsInYamlFile = 9023,
  InvalidGeoJson = 9024,
  NoTargetFirmwareFound = 9025,
  NoProductVersionFound = 9026,
  UnknownProvider = 9027,
  StripeError = 9028,
  SetGeolocationError = 9029,
  InvalidHexColorFormat = 9030,
  InvalidConversionStringToBoolean = 9031,
  FormattingFailed = 9032,
  MetricsFailedToFetchData = 9033,
  UpdateFilterPresetFailed = 9034,
  NoScopeName = 9035,
  UnknownScope = 9036,

  // Monext
  MonextChallengeFailed = 9037,
  MonextChallengeErrored = 9038,
  MonextChallengeTimeout = 9039,
  MonextNoSecretInResponse = 9040,
  MonextGetTokenError = 9041,
  MonextNoPaymentIdInResponse = 9052,

  // Payment action
  PaymentActionFailed = 9042,
  PaymentActionUnexpectedStatus = 9043,

  // Request
  RequestManuallyCancelled = 9044,
  RequestNoResponseReceived = 9045,
  RequestSetup = 9046,
  RequestDataUndefinedInResponse = 9047,
  RequestUnhandledRequestMethod = 9048,
  RequestUndefinedToken = 9049,
  RequestTimeout = 9050,
  RequestErrorStringType = 9051,
}

/**
 * Other error codes that are created by mobile apps, backend or embedded (found in force-end trip errors)
 */
export enum ExtraneousForceEndTripErrorCode {
  // Mobile Apps
  ImpossibleToUnlock = 10,
  ImpossibleToLock = 12,
  FailSafeMode = 20,
  BikeCriticalUserReport = 30,
  EmergencyEndTrip = 31,

  // Backend
  InvalidBikeState = 4242,
  SoftUnlockTooLong = 4243,

  // Bike
  BikeTripEndOnAutoLock = 10100,
}
