import type { ApiSchema } from '#/core-api';

type AutodiagError = ApiSchema['product.AutoDiagError'];

export function autodiagErrorsToFilter(
  errors: AutodiagError[]
): NonNullable<StringFilterConfig<string, 'multiple', 'empty'>['predefined']> {
  const groupedErrorsByMaster = errors.reduce<Record<string, AutodiagError[]>>(
    (acc, error) => {
      const key = error.master as string;

      if (!acc[key]) {
        acc[key] = [];
      }

      acc[key].push(error);

      return acc;
    },
    {}
  );

  return Object.entries(groupedErrorsByMaster).reduce<
    NonNullable<StringFilterConfig<string>['predefined']>
  >((acc, [master, errorList]) => {
    return acc.concat([
      {
        category: `<b>${errorList[0].type}</b>[${master}]`,
        selectable: true,
      },
      ...errorList.map(error => ({
        key: error.name,
        hint: error.description,
        value: `${master}***${error.name}`,
      })),
    ]);
  }, []);
}
