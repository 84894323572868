export const fields = [
  'id',
  'area_id',
  'type',
  'user_id',
  'vehicle_id',
  'departure',
  'pickup',
  'deposit',
  'destination',
  // TODO: deprecated, used for old "bike history", remove when bike history is migrated to vehicle history
  'bike_location_updates',
  'route',
  'distance',
  'duration',
  'calories_burned',
  'carbon_footprint',
  'metadata',
  'bill',
  'status',
  'soft_unlock',
  'rate',
  'last_product_error',
  'last_end_verify_error',
  'started_at',
  'ended_at',
  '_created',
  'errors',
  'estimation_id',
  'booking_id',
  'support_info',
  'out_of_order_declared_at',
  'rental_id',
  'geolocation_opt_in',
  'geolocation_info_cleared_at',
  'start_confirmed_by_vehicle_state',
  'vehicle_lock_source',
  'pause_until',
  'suspicious_reasons',
  'risk',
] satisfies ModelField<'rental.Trip'>[];
