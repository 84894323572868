export const fields = [
  'id',
  'status',
  'phone',
  'email',
  'firstname',
  'lastname',
  'birthdate',
  'sex',
  'address',
  'favorite_places',
  'roles',
  'mileage',
  'carbon_footprint',
  '_created',
  'metadata',
  'settings',
  'customer_info',
  'areas',
  'referral_code',
  'referred_by',
  '_anonymized_at',
  'scope_ids',
  'firebase_instance_id',
  'firebase_registration_token',
  'rfid_tags',
  'locale',
  'opt_ins',
  'extra_properties',
  'last_activity',
] satisfies ModelField<'user.User'>[];
