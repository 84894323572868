// From Fifteen Design System
import {
  house as fds__house,
  briefcase as fds__briefcase,
  bookmark as fds__bookmark,
  star as fds__star,
} from '@fifteen/design-system-vue/icons';
import {
  visa as fds__visa,
  americanExpress as fds__american_express,
  dinersClub as fds__diners_club,
  discover as fds__discover,
  jcb as fds__jcb,
  mastercard as fds__mastercard,
  unionpay as fds__unionpay,
} from '@fifteen/design-system-vue/icons/credit-cards';

// misc
import home_entry from './misc/home-entry.svg';
import home_exit from './misc/home-exit.svg';
import home_1_plus from './misc/home-1+.svg';
import estimated_route from './misc/timeline-dashed.svg';
import coin_euro_alert from './misc/coin-euro-alert.svg';
import coin from './misc/coin.svg';
import renew_circle from './misc/autorenew-circle.svg';
import wrench_circle from './misc/wrench-circle.svg';
import intervention_zone from './misc/intervention-zone.svg';
import cash_refund from './misc/cash-refund.svg';
import expand_selection from './misc/expand-selection.svg';
import lte from './misc/LTE-vec.svg';
import sync_distant from './misc/cloud-sync-network.svg';
import sync_bluetooth from './misc/cloud-sync-bluetooth.svg';
import firmware_update from './misc/firmware-update.svg';
import signal_1 from './misc/signal-1.svg';
import signal_2 from './misc/signal-2.svg';
import signal_3 from './misc/signal-3.svg';
import signal_4 from './misc/signal-4.svg';
import signal_1_2g from './misc/signal-1-2g.svg';
import signal_2_2g from './misc/signal-2-2g.svg';
import signal_3_2g from './misc/signal-3-2g.svg';
import signal_4_2g from './misc/signal-4-2g.svg';
import signal_1_4g from './misc/signal-1-4g.svg';
import signal_2_4g from './misc/signal-2-4g.svg';
import signal_3_4g from './misc/signal-3-4g.svg';
import signal_4_4g from './misc/signal-4-4g.svg';
import subscription from './misc/subscription.svg';
import card_check from './misc/card-check.svg';
import sim_check from './misc/sim-check.svg';
import equal_circle from './misc/equal-circle.svg';
import dollar_clock from './misc/dollar-clock.svg';
import toy_brick_edit from './misc/toy-brick-edit.svg';
import rental_config from './misc/rental-config.svg';
import paypal from './misc/paypal.svg';
import google_pay from './misc/google-pay.svg';
import apple_pay from './misc/apple-pay.svg';
// areas
import earth_cog from './areas/earth_cog.svg';
import heatmap from './areas/heatmap.svg';
import unallowed_shape_edit from './areas/unallowed-shape-edit.svg';
import unallowed_shape_add from './areas/unallowed-shape-add.svg';
import unallowed_shape_remove from './areas/unallowed-shape-remove.svg';
import policy_shape_edit from './areas/policy-shape-edit.svg';
import policy_shape_add from './areas/policy-shape-add.svg';
import policy_shape_remove from './areas/policy-shape-remove.svg';
import intervention_zone_edit from './areas/intervention-zone-edit.svg';
import intervention_zone_add from './areas/intervention-zone-add.svg';
import intervention_zone_remove from './areas/intervention-zone-remove.svg';
// bikes
import zoov_bike_plus from './bikes/zoov-bike-plus.svg';
import zoov_bike_ping from './bikes/zoov-bike-ping.svg';
import zoov_bike_remove from './bikes/zoov-bike-remove.svg';
import zoov_bike_check from './bikes/zoov-bike-check.svg';
import zoov_bike_off from './bikes/zoov-bike-off.svg';
import zoov_bike_marker from './bikes/zoov-bike-marker.svg';
// leasing
import leasing_contract from './leasings/lease.svg';
import pending_pickup from './leasings/pending-pickup.svg';
import pending_delivery from './leasings/pending-delivery.svg';
// stations
import fastener_stack from './stations/fastener-stack.svg';
import fastener_stack_active from './stations/fastener-stack-check.svg';
import fastener_stack_inactive from './stations/fastener-stack-off.svg';
import fastener_dock from './stations/fastener-dock.svg';
import fastener_virtual from './stations/fastener-virtual.svg';
import fastener from './stations/fastener.svg';
import fastener_ping from './stations/fastener-ping.svg';
import fastener_add from './stations/fastener-add.svg';
import fastener_marker from './stations/fastener-marker.svg';
import station from './stations/station.svg';
import station_check from './stations/station-check.svg';
import station_off from './stations/station-off.svg';
import station_multiple from './stations/station-multiple.svg';
// battery
import battery_charging from './batteries/battery-charging-outline-variant.svg';
import battery_check from './batteries/battery-check.svg';
import battery_lock from './batteries/battery-lock.svg';
import battery_unlock from './batteries/battery-unlock.svg';
import battery_marker from './batteries/battery-marker.svg';
import battery_limit from './batteries/battery-limit.svg';
// locks
import lock_check from './locks/lock-check.svg';
import lock_open_alert from './locks/lock-open-alert.svg';
import lock_bluetooth from './locks/lock-bluetooth-outline.svg';
import lock_open_bluetooth from './locks/lock-open-bluetooth-outline.svg';
import lock_network from './locks/lock-network.svg';
import lock_open_network from './locks/lock-open-network.svg';
import lock_open_sale from './locks/lock-open-sale.svg';
// offer policies
import offer_policy_add from './offer-policies/offer-policy-add.svg';
import offer_policy_edit from './offer-policies/offer-policy-edit.svg';
// logos
import control_logo from './logos/control-logo.svg';
import datacenter_logo from './logos/datacenter-logo.svg';
import admin_router_logo from './logos/admin-router-logo.svg';
import angels_logo from './logos/angels-logo.svg';
// utils / actions
import copy_gps from './actions/copy-gps.svg';
import copy_list from './actions/copy-list.svg';
import account_open from './actions/account-open.svg';
import bell_open from './actions/bell-open-outline.svg';

export default {
  home_entry,
  home_exit,
  home_1_plus,
  estimated_route,
  coin_euro_alert,
  coin,
  renew_circle,
  wrench_circle,
  intervention_zone,
  cash_refund,
  expand_selection,
  lte,
  sync_distant,
  sync_bluetooth,
  dollar_clock,
  firmware_update,
  unallowed_shape_edit,
  unallowed_shape_add,
  unallowed_shape_remove,
  policy_shape_edit,
  policy_shape_add,
  policy_shape_remove,
  intervention_zone_edit,
  intervention_zone_add,
  intervention_zone_remove,
  signal_1,
  signal_2,
  signal_3,
  signal_4,
  signal_1_2g,
  signal_2_2g,
  signal_3_2g,
  signal_4_2g,
  signal_1_4g,
  signal_2_4g,
  signal_3_4g,
  signal_4_4g,
  subscription,
  card_check,
  sim_check,
  equal_circle,
  toy_brick_edit,
  rental_config,
  paypal,
  google_pay,
  apple_pay,

  earth_cog,
  heatmap,

  zoov_bike_plus,
  zoov_bike_ping,
  zoov_bike_remove,
  zoov_bike_check,
  zoov_bike_off,
  zoov_bike_marker,

  leasing_contract,
  pending_pickup,
  pending_delivery,

  fastener_stack,
  fastener_stack_active,
  fastener_stack_inactive,
  fastener_dock,
  fastener_virtual,
  fastener,
  fastener_ping,
  fastener_add,
  fastener_marker,
  station,
  station_check,
  station_off,
  station_multiple,

  battery_charging,
  battery_check,
  battery_lock,
  battery_unlock,
  battery_marker,
  battery_limit,

  lock_check,
  lock_open_alert,
  lock_bluetooth,
  lock_open_bluetooth,
  lock_network,
  lock_open_network,
  lock_open_sale,

  offer_policy_add,
  offer_policy_edit,

  control_logo,
  datacenter_logo,
  admin_router_logo,
  angels_logo,

  copy_gps,
  copy_list,
  account_open,
  bell_open,

  fds__house,
  fds__briefcase,
  fds__bookmark,
  fds__star,
  fds__visa,
  fds__american_express,
  fds__diners_club,
  fds__discover,
  fds__jcb,
  fds__mastercard,
  fds__unionpay,
};
