import { BenefitStatus, TargetType } from '#/core-api/enums';

import {
  areaIcon,
  bikeIcon,
  roleIcon,
  stationIcon,
  userIcon,
} from '@/config/icons';
import { defaultEnumDisplayProperties } from '@/lib/utils';

export function benefitStatus(status?: BenefitStatus): DisplayProperties {
  switch (status) {
    case BenefitStatus.NotSet:
      return {
        message: 'benefits__status__not_set',
        class: 'secondary',
      };
    case BenefitStatus.Active:
      return {
        message: 'benefits__status__active',
        class: 'success',
      };
    case BenefitStatus.NeedsPayment:
      return {
        message: 'benefits__status__needs_payment',
        class: 'warning',
        icon: 'svg:coin_euro_alert',
      };
    case BenefitStatus.BillingPendingValidation:
      return {
        message: 'benefits__status__billing_pending_validation',
        class: 'warning',
        icon: 'mdi-cash-clock',
      };
    case BenefitStatus.PendingFirstPayment:
      return {
        message: 'benefits__status__pending_first_payment',
        class: 'warning',
        icon: 'svg:coin',
      };
    case BenefitStatus.Ended:
      return {
        message: 'benefits__status__ended',
        class: 'error',
        icon: 'mdi-close-circle',
      };
    case BenefitStatus.Paused:
      return {
        message: 'benefits__status__paused',
        class: 'disabled',
        icon: 'mdi-pause-circle',
      };
    case BenefitStatus.PendingValidation:
      return {
        message: 'benefits__status__pending_validation',
        class: 'disabled_alt1',
        icon: 'mdi-clock',
      };
    default:
      return defaultEnumDisplayProperties(status);
  }
}

export function benefitTargetType(type?: TargetType): DisplayProperties {
  switch (type) {
    case TargetType.Undefined:
      return {
        message: 'benefits__target_type__undefined_type',
        icon: 'mdi-help',
      };
    case TargetType.User:
      return {
        message: 'benefits__target_type__user',
        icon: userIcon,
      };
    case TargetType.Role:
      return {
        message: 'benefits__target_type__role',
        icon: roleIcon,
      };
    case TargetType.Station:
      return {
        message: 'benefits__target_type__station',
        icon: stationIcon,
      };
    case TargetType.Bike:
      return {
        message: 'benefits__target_type__bike',
        icon: bikeIcon,
      };
    case TargetType.Area:
      return {
        message: 'benefits__target_type__area',
        icon: areaIcon,
      };
    default:
      return defaultEnumDisplayProperties(type);
  }
}
