<template lang="pug">
VCheckbox.ZActionControlCheckbox(
  v-show="canShow"
  ref="componentRef"
  v-model="modelValue"
  :style="style"
  :label="label"
  :rules="rules"
  :color="color"
  :disabled="isDisabled"
  :hint="hint"
  :messages="message"
  :hide-details="areDetailsHidden"
  v-bind="additionalProps"
)
</template>

<style lang="stylus">
.ZActionControlCheckbox.v-input--checkbox
  .v-input__control
    margin-left -3px
    padding-top 8px

  .v-messages
    margin-left 3px
</style>

<script setup lang="ts">
import { useActionControl } from './useActionControl';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Data = any;

interface ZActionControlCheckboxProps {
  /**
   * The control object
   */
  control: ActionControl<'checkbox', keyof Data, Data>;
  /**
   * The parent action 'data' object
   */
  data: Data;
  /**
   * The control’s value
   * @model
   */
  value?: boolean;
}

const props = withDefaults(defineProps<ZActionControlCheckboxProps>(), {
  value: false,
});

const {
  modelValue,
  canShow,
  style,
  label,
  rules,
  color,
  isDisabled,
  hint,
  message,
  areDetailsHidden,
  additionalProps,
} = useActionControl(props);

const componentRef = ref<TemplateRef | null>(null);

function focus(): void {
  componentRef.value?.focus?.();
}

function resetValidation(): void {
  componentRef.value?.resetValidation?.();
}

defineExpose({
  /**
   * Focus the input
   */
  focus,
  /**
   * Reset input validation
   */
  resetValidation,
});
</script>
