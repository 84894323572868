<template lang="pug">
VDialog.ZLegalModal(
  v-model="isOpen"
  max-width="800px"
  content-class="ZLegalModal__dialog"
  persistent
  no-click-animation
)
  VCard.ZLegalModal__card
    VBtn.ZLegalModal__close(
      light
      icon
      @click="isOpen = false"
    )
      VIcon mdi-close
    .ZLegalModal__center(v-if="loading")
      FLoader(
        color="primary--light-1"
        :width="40"
      )
    iframe(
      v-if="legalPageUrl"
      :src="legalPageUrl"
      @load="loaded = true"
    )
    .ZLegalModal__center(v-else) {{ t('app__legal_content_not_available') }}
</template>

<style lang="stylus">
.ZLegalModal
  &.v-overlay__content
    margin-top 24px

    > .v-card
      border-radius 24px
      box-shadow none

.ZLegalModal__dialog
  overflow hidden

.ZLegalModal__card
  height 100vh
  width 100%

  iframe
    border 0
    height 100%
    width 100%

    +media-down-xs()
      margin 0 -64px
      width calc(100% + 2 * 64px)

.ZLegalModal__center
  position absolute
  top 50%
  left 50%
  transform translate(-50%, -50%)

.ZLegalModal__close.v-btn
  position absolute
  top 16px
  right 16px
  z-index 1
</style>

<script setup lang="ts">
import { withQuery } from 'ufo';

import { useVModelProxy } from '@/composables';
import { useI18n } from '@/composables/plugins';
import store from '@/store';

export interface ZLegalModalProps {
  /**
   * Handle open state of the dialog
   * @model
   */
  value: boolean;
  /**
   * Type of the legal content
   */
  type: 'terms-and-conditions' | 'privacy-policy';
}

const props = defineProps<ZLegalModalProps>();

const isOpen = useVModelProxy({ props });

const { t } = useI18n();

const legalPageConfig = computed<MaybeLocalizedString>(() => {
  switch (props.type) {
    case 'terms-and-conditions':
      return window.VUE_APP_TERMS_AND_CONDITIONS_URL;
    case 'privacy-policy':
      return window.VUE_APP_PRIVACY_POLICY_URL;
  }
});

const locale = computed(() => store.getters.locale);

const legalPageUrl = computed(() => {
  const url =
    typeof legalPageConfig.value === 'string'
      ? legalPageConfig.value
      : (legalPageConfig.value?.[locale.value] ??
        legalPageConfig.value?.['en']);

  return url ? withQuery(url, { embedded: 'true' }) : null;
});

const loaded = ref(false);
const loading = computed(
  () => isOpen.value && !!legalPageUrl.value && !loaded.value
);
</script>
