import type { BenefitFilter } from '@/models/benefit/filters';
import type { OfferFilter } from '@/models/offer/filters';
import type { RentalFilter } from '@/models/rental/filters';
import type { AreaFilter } from '@/models/area/filters';
import type { TripFilter } from '@/models/trip/filters';
import type { UserFilter } from '@/models/user/filters';
import type { IssueFilter } from '@/models/ticket/issue/filters';
import type { VehicleFilter } from '@/models/vehicle/filters';

export const vehiclesExtraPropertiesFiltersConfig: VehicleFilter[] = [
  {
    key: 'extra_properties.last_ended_trip_id',
    type: String,
  },
  {
    key: 'extra_properties.last_ended_trip_time',
    type: String,
  },
  {
    key: 'extra_properties.last_maintenance_state_update',
    type: String,
  },
  {
    key: 'extra_properties.last_maintenance_state_updater',
    type: String,
  },
  {
    key: 'extra_properties.rated_at',
    type: Date,
    queryDateAsString: true,
  },
  {
    key: 'extra_properties.rating',
    type: String,

    multiple: true,
    selectAll: true,
    predefined: ['bad', 'good', 'very_good', 'reviewed'].map(value => ({
      key: value,
      value,
    })),
  },
];

export const issuesExtraPropertiesFiltersConfig: IssueFilter[] = [
  {
    key: 'extra_properties.closure_reason',
    type: String,

    multiple: true,
    selectAll: true,
    predefined: ['no-problem', 'repaired'].map(value => ({
      key: value,
      value,
    })),
  },
  {
    key: 'extra_properties.creation_phase',
    type: String,

    multiple: true,
    selectAll: true,
    predefined: ['in_trip', 'post_trip', 'outside_trip'].map(value => ({
      key: value,
      value,
    })),
  },
  {
    key: 'extra_properties.firmware_version',
    type: String,
  },
  {
    key: 'extra_properties.pipeline_id',
    type: String,
  },
  {
    key: 'extra_properties.pipeline_name',
    type: String,
  },
  {
    key: 'extra_properties.pipeline_process_id',
    type: String,
  },
  {
    key: 'extra_properties.pipeline_version',
    type: Number,
  },
  {
    key: 'extra_properties.previous_maintenance_state',
    type: Number,
  },
  {
    key: 'extra_properties.previous_out_of_order',
    type: Boolean,
  },
  {
    key: 'extra_properties.source',
    type: String,

    multiple: true,
    selectAll: true,
    predefined: [
      'angels',
      'automations',
      'control',
      'end-user-app',
      'null',
    ].map(value => ({
      key: value,
      value,
    })),
  },
  {
    key: 'extra_properties.targeted_maintenance_state',
    type: Number,
  },
  {
    key: 'extra_properties.targeted_out_of_order',
    type: Boolean,
  },
];

export const usersExtraPropertiesFiltersConfig: UserFilter[] = [
  {
    key: 'extra_properties.gratification_points',
    type: Number,
  },
  {
    key: 'extra_properties.kiosk_id',
    type: String,
  },
  {
    key: 'extra_properties.pincode',
    type: String,
  },
  {
    key: 'extra_properties.receipt',
    type: String,
  },
  {
    key: 'extra_properties.transaction_id',
    type: String,
  },
  {
    key: 'metadata.CANCEL_CAUSE',
    type: String,
  },
  {
    key: 'metadata.CUSTOMER_ID',
    type: String,
  },
  {
    key: 'metadata.CustomerIDJCD',
    type: String,
  },
  {
    key: 'metadata.PARTNER_PRODUCT_TYPE',
    type: String,
  },
  {
    key: 'metadata.PARTNER_USER_ID',
    type: String,
  },
  {
    key: 'metadata.SUBSCRIPTION_ACTIVATION_DATE',
    type: String,
  },
  {
    key: 'metadata.SUBSCRIPTION_END_DATE',
    type: String,
  },
  {
    key: 'metadata.endDateJCD',
    type: String,
  },
  {
    key: 'metadata.gratification_points',
    type: Number,
  },
  {
    key: 'metadata.kiosk_id',
    type: String,
  },
  {
    key: 'metadata.pincode',
    type: String,
  },
  {
    key: 'metadata.receipt',
    type: String,
  },
  {
    key: 'metadata.reduced_offer',
    type: String,
  },
  {
    key: 'metadata.transaction_id',
    type: String,
  },
  {
    key: 'metadata.userOrigin',
    type: String,
  },
  {
    key: 'metadata.user_origin',
    type: String,
  },
];

export const tripsExtraPropertiesFiltersConfig: TripFilter[] = [
  {
    key: 'extra_properties.bike_firmware_version',
    type: String,
  },
  {
    key: 'extra_properties.device_app_version',
    type: String,
  },
  {
    key: 'extra_properties.device_manufacturer',
    type: String,
  },
  {
    key: 'extra_properties.device_model',
    type: String,
  },
  {
    key: 'extra_properties.device_os',
    type: String,
  },
  {
    key: 'extra_properties.device_os_version',
    type: String,
  },
];

export const areasExtraPropertiesFiltersConfig: AreaFilter[] = [
  {
    key: 'extra_properties.intervention_zones.encoded_polyline',
    type: String,
  },
  {
    key: 'extra_properties.intervention_zones.label',
    type: String,
  },
];

export const rentalsExtraPropertiesFiltersConfig: RentalFilter[] = [
  {
    key: 'extra_properties.bike_name',
    type: String,
  },
  {
    key: 'extra_properties.state_of_play_end',
    type: Boolean,
  },
  {
    key: 'extra_properties.state_of_play_start',
    type: Boolean,
  },
  {
    key: 'extra_properties.user_first_pairing_done',
    type: Boolean,
  },
];

export const offersExtraPropertiesFiltersConfig: OfferFilter[] = [
  {
    key: 'extra_properties.cta_url',
    type: String,
  },
  {
    key: 'extra_properties.displayed_in_sharing_app',
    type: Boolean,
  },
  {
    key: 'extra_properties.highlight',
    type: Boolean,
  },
  {
    key: 'extra_properties.is_mid_term_subscription',
    type: Boolean,
  },
  {
    key: 'extra_properties.referral_target',
    type: String,

    multiple: true,
    selectAll: true,
    predefined: ['referrer', 'referee'].map(value => ({
      key: value,
      value,
    })),
  },
];

export const benefitsExtraPropertiesFiltersConfig: BenefitFilter[] = [
  {
    key: 'extra_properties.cta_url',
    type: String,
  },
  {
    key: 'extra_properties.displayed_in_sharing_app',
    type: Boolean,
  },
  {
    key: 'extra_properties.highlight',
    type: Boolean,
  },
  {
    key: 'extra_properties.is_cancel_requested',
    type: Boolean,
  },
  {
    key: 'extra_properties.is_mid_term_subscription',
    type: Boolean,
  },
  {
    key: 'extra_properties.referral_target',
    type: String,

    multiple: true,
    selectAll: true,
    predefined: ['referrer', 'referee'].map(value => ({
      key: value,
      value,
    })),
  },
];
