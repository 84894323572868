import {
  TicketTarget,
  FastenerStatus,
  FastenerMaintenanceState,
} from '#/core-api/enums';

import { idRule } from '@/lib/helpers/rules';
import { useI18n } from '@/composables/plugins';
import {
  getAreaFilterConfig,
  getHasIssueWithStatusFilter,
} from '@/models/shared/filters';
import {
  fastenerStatus,
  fastenerMaintenanceState,
} from '@/models/fastener/mappers/display';
import { vehicleIcon } from '@/config/icons';

const { t } = useI18n();

export type StationFilter = ModelFilter<'station.Station'>;

export function getStaticFilters(): StationFilter[] {
  return [
    {
      key: 'id',
      type: String,
      multiple: true,
      rules: [idRule],
      exactMatch: true,
    },
    {
      key: 'label',
      type: String,
      multiple: true,
      empty: true,
      diacriticsSensitive: false,
    },
    {
      key: 'nearby_freefloating_vehicle_ids',
      label: t('app__vehicle_id'),
      icon: vehicleIcon,
      type: String,
      multiple: true,
      empty: true,
    },
    {
      key: '_created',
      type: Date,
    },
    {
      key: 'out_of_order',
      type: Boolean,
    },
    {
      key: 'maintenance_state',
      type: Number,
      predefined: [
        FastenerMaintenanceState.NotSet,
        FastenerMaintenanceState.InField,
        FastenerMaintenanceState.NeedMaintenanceInOrder,
        FastenerMaintenanceState.NeedMaintenanceOutOfOrder,
        FastenerMaintenanceState.UnderMaintenance,
      ].map(value => ({
        key: fastenerMaintenanceState(value).message,
        value,
        color: fastenerMaintenanceState(value).class,
        icon: fastenerMaintenanceState(value).icon,
      })),
      multiple: true,
    },
    {
      key: 'status',
      type: Number,
      predefined: [
        FastenerStatus.NotSet,
        FastenerStatus.Active,
        FastenerStatus.Inactive,
      ].map(value => ({
        key: fastenerStatus(value).message,
        value,
        color: fastenerStatus(value).class,
        icon: fastenerStatus(value).icon,
      })),
      multiple: true,
    },
    {
      key: 'urban_furnitures.id',
      multiple: true,
      type: String,
      empty: true,
      buildQuery: filter => {
        const { empty, negation, value } = filter;

        if (empty) {
          return { 'urban_furnitures.id': { $exists: negation } };
        }

        const operation = negation ? '$nin' : '$in';
        return { 'urban_furnitures.id': { [operation]: value } };
      },
    } satisfies StringFilterConfig<
      ModelField<'station.Station'>,
      'multiple',
      'empty'
    >,
    getHasIssueWithStatusFilter(TicketTarget.Station) as StationFilter,
  ];
}

export async function getAsyncFilters(): Promise<
  StringFilterConfig<ModelField<'station.Station'>, 'multiple'>[]
> {
  return [getAreaFilterConfig()];
}
