<template lang="pug">
.ZInputPrice
  VTextField(
    ref="amountInputRef"
    :value="amount"
    :label="label ?? t('app__amount')"
    :rules="computedRules"
    :color="color"
    :hint="hint"
    :messages="messages"
    :disabled="disabled"
    :required="required"
    type="number"
    @input="handleInputAmount"
  )
  ZInputCurrency.ZInputPrice__currency(
    :value="currency"
    :label="t('app__currency')"
    :disabled="disableCurrencyEdition"
    @input="handleInputCurrency"
  )
</template>

<style lang="stylus">
.ZInputPrice
  display flex
  align-items baseline
  gap 8px

  .ZToggleableHint
    left -8px
    margin-right -8px

.ZInputPrice__currency
  margin-right 0
  flex 0 0 auto
</style>

<script setup lang="ts">
import { twoDigitFloatNumberRule } from '@/lib/helpers/rules';
import { formatPaymentInput, isNullish } from '@/lib/utils';
import { useI18n } from '@/composables/plugins';

import ZInputCurrency from '@/components/ui/molecules/ZInputCurrency.vue';

export type Price = {
  amount?: number;
  currency?: CurrencyCode;
};

interface ZInputPriceProps {
  /**
   * The value (amount and currency)
   * @model
   */
  value?: Price;
  /**
   * The input label, overrides `app__amount`
   */
  label?: string | null;
  /**
   * The hint text, passed to the v-text-field
   */
  hint?: string;
  /**
   * The messages, passed to the v-text-field
   */
  messages?: string | string[];
  /**
   * Disabled state
   */
  disabled?: boolean;
  /**
   * Required state
   */
  required?: boolean;
  /**
   * Additional rules for the amount input
   */
  rules?: VuetifyRule<Price>[];
  /**
   * Color of the input
   */
  color?: string;
  /**
   * Disable the currency input
   */
  disableCurrencyEdition?: boolean;
}

const emit = defineEmits<{
  (name: 'input', value: Price): void;
}>();

const props = withDefaults(defineProps<ZInputPriceProps>(), {
  value: () => ({}),
  label: null,
  hint: '',
  messages: () => [],
  disabled: false,
  required: false,
  rules: () => [],
  color: 'primary',
  disableCurrencyEdition: false,
});

const { t } = useI18n();

const amount = ref<string>();
const currency = ref<CurrencyCode>();
const amountInputRef = ref<TemplateRef | null>(null);

const price = computed<Price>(() => ({
  amount: getNumberAmount(amount.value),
  currency: currency.value,
}));

function getNumberAmount(value?: string): number | undefined {
  return value === '' || isNullish(value)
    ? undefined
    : // Convert to cents and round to avoid floating point errors
      Math.round(Number(value) * 100);
}

function getFormattedAmount(value?: string | number): string | undefined {
  return value === '' || isNullish(value)
    ? undefined
    : formatPaymentInput(value, 2);
}

function handleInputAmount(value: string): void {
  amount.value = getFormattedAmount(value);
  emit('input', price.value);
}

function handleInputCurrency(value: CurrencyCode): void {
  currency.value = value;
  emit('input', price.value);
}

const computedRules = computed(() => [
  ...(props.rules ?? []).map(
    rule => (v: number) => rule({ amount: v, currency: currency.value })
  ),
  ...(props.required
    ? [
        twoDigitFloatNumberRule,
        () => !!currency.value || t('app__currency_required'),
      ]
    : [(v: string | number) => !v || twoDigitFloatNumberRule(v)]),
]);

watchImmediate(
  () => props.value,
  value => {
    amount.value = getFormattedAmount(value.amount);
    currency.value = value.currency;
  }
);

function focus(): void {
  amountInputRef.value?.focus?.();
}

function resetValidation(): void {
  amountInputRef.value?.resetValidation?.();
}

defineExpose({
  /**
   * Focus the input
   */
  focus,
  /**
   * Reset input validation
   */
  resetValidation,
});
</script>
