/* eslint-disable @typescript-eslint/no-explicit-any */
/*
 * Filters that are shared between different models
 */
import {
  FastenerType,
  IssueTarget,
  TicketStatus,
  EntityProductType,
} from '#/core-api/enums';

import join from '@/lib/utils/join-props';
import { buildQueryFromFilter } from '@/lib/utils';
import store from '@/store';
import { pickupDepositType } from '@/models/trip/mappers/display';
import { issueStatus } from '@/models/ticket/issue/mappers/display';
import { fastenerType } from '@/models/fastener/mappers/display';
import { idRule } from '@/lib/helpers/rules';
import { autodiagErrorsToFilter } from '@/models/shared/helpers/autodiags';
import { appConfig } from '@/config';
import { areaIcon, issueIcon } from '@/config/icons';

import type { TicketTarget } from '#/core-api/enums';
import type { ApiSchema } from '#/core-api';

type Lwm2MInfoFilter = FilterConfig<
  FlattenedKey<{
    lwm2m_info?: ApiSchema['product.Lwm2MInfo'];
  }>
>;

/**
 * Filters for lwm2m_info
 */
export function getLwm2mInfoFilters(): Lwm2MInfoFilter[] {
  return [
    {
      key: 'lwm2m_info.last_contact',
      type: Date,
    },
    {
      key: 'lwm2m_info.last_register',
      type: Date,
    },
    {
      key: 'lwm2m_info.last_update',
      type: Date,
    },
    {
      key: 'lwm2m_info.connected',
      type: Boolean,
    },
    {
      key: 'lwm2m_info.lte_info.access_technology',
      type: String,
      empty: true,
      predefined: [
        'Not registered',
        'Unknown',
        '2G',
        '4G - Cat-M1',
        '4G - NB-IoT',
      ].map(value => ({
        key: value,
        value,
      })),
    },
  ];
}

type PickupDepositFilter = FilterConfig<
  FlattenedKey<
    { pickup?: ApiSchema['rental.Entity'] } & {
      deposit?: ApiSchema['rental.Entity'];
    }
  >
>;

/**
 * Filters for pickup and deposit
 * @param key - Desired key
 * @returns Filters for the provided key
 */
export function getPickupDepositFilters<Key>(
  key: Key extends 'pickup' | 'deposit' ? Key : never
): PickupDepositFilter[] {
  return [
    {
      key: `${key}.id`,
      type: String,
      multiple: true,
      empty: true,
    },
    {
      key: `${key}.product_type`,
      type: Number,
      text: `${key}.product_type`,
      predefined: [EntityProductType.Vehicle, EntityProductType.Station].map(
        value => ({
          key: pickupDepositType(value).message,
          value,
        })
      ),
    },
    {
      key: `${key}.entity_type`,
      type: Number,
      multiple: true,
      empty: true,
      predefined: [
        FastenerType.Dock,
        FastenerType.Stack,
        FastenerType.Virtual,
      ].map(value => ({
        icon: fastenerType(value).icon,
        key: fastenerType(value).message,
        value,
      })),
    },
    {
      key: `${key}.parent_id`,
      type: String,
      multiple: true,
      rules: [idRule],
      exactMatch: true,
      empty: true,
    },
  ];
}

/**
 * Return area filter based on stored areas
 * @param options - Options object
 * @param options.key - Final area key of the filter
 * @param options.empty - Allow the filter to be empty
 * @returns The filter settings object
 */
export function getAreaFilterConfig<
  Key extends string = 'area_id',
  Empty extends boolean = false,
>({
  key = 'area_id' as Key,
  empty,
}: {
  key?: Key;
  empty?: Empty;
} = {}): StringFilterConfig<
  Key,
  'multiple',
  Empty extends true ? 'empty' : false
> {
  const areas = store.getters['AREAS']('App') ?? [];

  return {
    key,
    text: 'area',
    label: 'Area',
    icon: areaIcon,
    type: String,
    autocomplete: true,
    exactMatch: true,
    multiple: true,
    empty: empty as (Empty extends true ? 'empty' : false) extends 'empty'
      ? true
      : false,
    predefined: (areas ?? []).map(({ label, id }) => ({
      key: label,
      value: id ?? '',
    })),
  };
}

/**
 * Get the "has open issue(s) with status" filter, based on the target
 * @param target - The issue target type
 */
export function getHasIssueWithStatusFilter(
  target: IssueTarget.Bike | TicketTarget
): NumberFilterConfig<string, 'multiple'> {
  const targetIdField = (() => {
    switch (target) {
      case IssueTarget.Bike:
        return 'serial_number';
      default:
        return 'id';
    }
  })();
  return {
    key: 'open_issues',
    text: 'data__filter__has_open_issues_with_status',
    icon: issueIcon,
    type: Number,
    multiple: true,
    predefined: [TicketStatus.Open, TicketStatus.Closed].map(v => ({
      key: issueStatus(v).message,
      value: v,
    })),
    populate: {
      actionType: 'GET_ISSUES',
      queryField: 'status',
      additionalQuery: { target },
      responseField: { key: 'target_id', as: targetIdField },
      type: String,
    },
  } satisfies NumberFilterConfig<string, 'multiple'>;
}

/**
 * Get the metadata filters for a given model and field
 * @param model - The model name regarding the registry (<service>/<model>, _e.g._ `bike/bike`)
 * @param field - The field holding the metadata definition (defaults to `appConfig.defaultMetadataField`)
 */
export async function getMetadataFilters(
  model: MetadataRegistryModel,
  field: MetadataField = appConfig.defaultMetadataField
): Promise<StringFilterConfig<string, false, 'empty'>[]> {
  const [error, registry] = await to(
    store.cache.dispatch('GET_METADATA_REGISTRY', {
      path: encodeURIComponent(`${model}/${field}`),
    })
  );
  if (error || !registry) return [];
  return Object.keys(registry.properties ?? {}).map(key => ({
    key: join(field, key),
    type: String,
    empty: true,
  }));
}

export async function getAutoDiagsFilters(
  modelName: 'bike' | 'fastener'
): Promise<StringFilterConfig<string, 'multiple', 'empty'>[]> {
  const storeType =
    modelName === 'fastener'
      ? 'GET_FASTENERS_AUTODIAG_DEFINITION'
      : 'GET_BIKES_AUTODIAG_DEFINITION';

  const [_, response] = await to(store.cache.dispatch(storeType));

  const errors = response?.errors ?? [];

  return [
    {
      key: 'auto_diags.flags.name',
      text: 'auto_diags',
      type: String,
      maxWidth: '540px',
      multiple: true,
      empty: true,
      exactMatch: true,
      predefined: autodiagErrorsToFilter(errors),

      buildQuery: filter => {
        const { empty, value, negation } = filter;
        if (!empty && Array.isArray(value)) {
          const groups = value.reduce<Record<string, string[]>>(
            (groups, value) => {
              const fragments = value.split('***');

              const [master, errorName] = fragments;
              groups[master] = (groups[master] ?? []).concat(errorName);
              return groups;
            },
            {}
          );

          const queries: MongoQuery = Object.entries(groups).reduce(
            (queries, [master, name]) => {
              // use default filter formatter with value override
              const queryName = buildQueryFromFilter({
                ...filter,
                value: name,
              });
              // and target specific flag type using id
              const queryMaster = {
                'auto_diags.flags.master': negation ? { $ne: master } : master,
              };
              // add the combination to the all queries
              return queries.concat({
                [negation ? '$or' : '$and']: [queryMaster, queryName],
              } as MongoQuery);
            },
            []
          );
          return { [negation ? '$and' : '$or']: queries };
        } else {
          // for 'none' or 'not none', default formatter handles it properly
          return buildQueryFromFilter(filter as Filter);
        }
      },
    } satisfies StringFilterConfig<string, 'multiple', 'empty'>,
  ];
}
