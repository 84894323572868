<script lang="ts">
import mergeActionSettings from '@/lib/helpers/merge-action-settings';

import type { InjectionKey, PropType } from 'vue';

export interface ZActionProvider {
  actionSettings?: Ref<Partial<ActionSettings> | undefined>;
  actionSettingsOverride?: Ref<Partial<ActionSettings> | undefined>;
}

export const injectionKey = Symbol(
  'actionProvider'
) as InjectionKey<ZActionProvider>;

export default defineComponent({
  name: 'ZActionProvider',
  props: {
    /**
     * Action settings that will be passed to underlying ZAction components using provide/inject.
     * All the action settings form nested ZActionProvider components will be merged together.
     */
    actionSettings: {
      type: Object as PropType<Partial<ActionSettings> | undefined>,
      default: () => ({}),
    },
    /**
     * Action settings that will override any other action settings.
     * If nested, the action settings will be overridden by the parent ZActionProvider.
     */
    actionSettingsOverride: {
      type: Object as PropType<Partial<ActionSettings>> | undefined,
      default: () => ({}),
    },
  },
  setup(props) {
    const actionProvider = inject(injectionKey, { actionSettings: ref({}) });
    provide(injectionKey, {
      actionSettings: computed(() =>
        mergeActionSettings(
          actionProvider.actionSettings?.value ?? {},
          props.actionSettings ?? {}
        )
      ),
      actionSettingsOverride: computed(() => ({
        ...props.actionSettingsOverride,
        ...actionProvider.actionSettingsOverride?.value,
      })),
    });
  },
  render(h) {
    return h('div', { class: 'ZActionProvider' }, [this.$slots.default]);
  },
});
</script>
