<template lang="pug">
.ZLastUpdateDelay(
  v-if="danger || (lastUpdated && delayText)"
  :class="classes"
)
  z-icon.ZLastUpdateDelay__icon(:size="iconSize") {{ icon }}
  span.ZLastUpdateDelay__text {{ delayText }}
</template>

<style lang="stylus">
.ZLastUpdateDelay
  display flex
  margin-top 1px
  margin-left 8px
  font-size 12px
  font-size 11px
  line-height 1

.ZLastUpdateDelay__icon
  margin-right 1px
  height 11px

.ZLastUpdateDelay__text
  padding-top 1px

.ZLastUpdateDelay--outline
  margin-left 6px
  padding-top 3px
  padding-right 6px
  padding-bottom 3px
  padding-left 5px
  margin-bottom 1px
  border 1px solid alpha($colors.grey.base, 0.4)
  border-radius 20px
  text-align center

  &.ZLastUpdateDelay--danger
    padding-right 3px
    padding-left 3px

    .ZLastUpdateDelay__icon
      margin-right 0

.ZLastUpdateDelay--warning,
.ZLastUpdateDelay--danger
  font-weight 500
  color $color-error !important
  border-color alpha($color-error, 0.4)

.theme--dark
  .ZLastUpdateDelay
    color: $colors.grey.lighten-1

.theme--light
  .ZLastUpdateDelay
    color: $colors.grey.darken-1
</style>

<script setup lang="ts">
import { useFormatLastUpdatedDelay } from '@/composables';

interface ZLastUpdateDelayProps {
  /**
   * Last update date
   */
  value?: LastUpdatedDate;
  /**
   * Whether to display a border around the date
   */
  outline?: boolean;
  /**
   * Whether to display in a warning color style
   */
  warning?: boolean;
  /**
   * Whether to display in a danger color style
   */
  danger?: boolean;
}

const props = withDefaults(defineProps<ZLastUpdateDelayProps>(), {
  value: null,
  outline: false,
  warning: false,
  danger: false,
});

const restartLastUpdateCacheControlTimer = ref(true);
const lastUpdateCacheControlTimer = ref<NodeJS.Timeout | null>(null);
const lastUpdateCacheTime = ref(Date.now());

const classes = computed(() => ({
  'ZLastUpdateDelay--outline': props.outline,
  'ZLastUpdateDelay--warning': props.warning,
  'ZLastUpdateDelay--danger': props.danger,
}));

const lastUpdated = computed(() => {
  if (!props.value) {
    return null;
  } else if (typeof props.value === 'object') {
    return props.value._last_updated_at;
  } else {
    return props.value;
  }
});

const { formattedDelay, update } = useFormatLastUpdatedDelay(lastUpdated);

const delay = computed(() => {
  if (props.danger) return null;
  return formattedDelay.value;
});

const delayText = computed(() => {
  return delay.value?.text;
});

const icon = computed(() => {
  return props.danger
    ? 'mdi-exclamation'
    : delay.value?.future
      ? 'mdi-arrow-right'
      : 'mdi-clock-outline';
});

const iconSize = computed(() => {
  return props.danger ? '12px' : '11px';
});

function setLastUpdateCacheControlTimer(): void {
  if (!formattedDelay.value) return;
  update();
  // Interval delay depend on the delay between lastUpdated value and now,
  // as we only display rounded values (seconds if seconds only, minutes if > 1min, hours if >1h, etc)
  // so it is useless to update it every seconds or even every minutes if not required.
  // Hence we compute it accordingly. As interval may changes, we simulate it by using a timeout
  // that we restart over and over. Each time, the interval delay is properly computed.
  const { days, hours, minutes } = formattedDelay.value;
  let intervalDelay;
  // if days, we don't even need to update anything
  if (days) return;
  // every hour
  else if (hours) intervalDelay = 60 * 60 * 1000;
  // every min
  else if (minutes) intervalDelay = 60 * 1000;
  // every 10 sec
  else intervalDelay = 10000;

  lastUpdateCacheControlTimer.value = setTimeout(() => {
    // update cache time to invalidate cache

    lastUpdateCacheTime.value = Date.now();
    // start timeout again
    if (restartLastUpdateCacheControlTimer.value) {
      setLastUpdateCacheControlTimer();
    }
  }, intervalDelay);
}
function clearLastUpdateCacheControlTimer(): void {
  if (!lastUpdateCacheControlTimer.value) return;

  clearTimeout(lastUpdateCacheControlTimer.value);
}

watch(
  () => props.value,
  () => {
    if (lastUpdated.value) {
      clearLastUpdateCacheControlTimer();
      setLastUpdateCacheControlTimer();
    } else {
      restartLastUpdateCacheControlTimer.value = false;
      clearLastUpdateCacheControlTimer();
    }
  }
);

onBeforeMount(() => {
  if (lastUpdated.value) {
    setLastUpdateCacheControlTimer();
  }
});

onActivated(() => {
  if (lastUpdated.value && !lastUpdateCacheControlTimer.value) {
    restartLastUpdateCacheControlTimer.value = true;
    setLastUpdateCacheControlTimer();
  }
});

onDeactivated(() => {
  restartLastUpdateCacheControlTimer.value = false;
  clearLastUpdateCacheControlTimer();
});

onBeforeUnmount(() => {
  restartLastUpdateCacheControlTimer.value = false;
  clearLastUpdateCacheControlTimer();
});
</script>
