<template lang="pug">
.ZAppNotifications
  VListTile(
    v-if="listItem"
    @click="handleClick"
  )
    VListTileAction
      VBadge(
        color="error"
        overlap
        :value="newNotificationsNum > 0"
      )
        template(#badge)
          span {{ newNotificationsNum }}
        VIcon mdi-bell
    VListTileContent
      VListTileTitle {{ t('menu__errors_notif') }}

  VBtn(
    v-else
    icon
    @click.stop="open = true"
  )
    VBadge(
      color="error"
      overlap
      :value="newNotificationsNum > 0"
    )
      template(#badge)
        span {{ newNotificationsNum }}
      VIcon mdi-bell

  ZDialog(
    v-model="open"
    width="1200px"
  )
    template(#toolbar)
      VToolbarTitle.ZAppNotifications__toolbarTitle {{ t('menu__errors_notif') }}
    ZScrollable(off)
      VCard.ZAppNotifications__wrapper
        VCard.ZAppNotifications__card.elevation-0
          VBtn.ZAppNotifications__card__clear(
            v-if="notifications.length"
            icon
            small
            @click="clearNotifications"
          )
            VIcon(small) mdi-delete
          VSubheader(v-if="notifications.length === 0") {{ t('app__nothing _yet') }}
          VDataTable.ZAppNotifications__table(
            v-else
            :headers="headers"
            :items="formattedNotifications"
            :pagination="pagination"
            hide-actions
          )
            template(#items="{ item }")
              tr(:class="item.new ? 'notification__new' : ''")
                td
                  ZIcon(:class="`${item.colorClass}--text`") {{ item.iconName }}
                td {{ item.title }}
                td.ZAppNotifications__message
                  span(
                    v-if="!item.error && !item.arguments"
                    v-html="item.message"
                  )
                  VExpansionPanel.elevation-0(v-else)
                    VExpansionPanelContent.ZAppNotifications__expansionPanel
                      template(#header)
                        ZErrorMessage(
                          v-if="item.error"
                          :error="item.error"
                        )
                        span(v-else) {{ item.message }}
                      pre(
                        v-for="(arg, i) in item.arguments"
                        :key="i"
                      ) {{ (typeof arg !== 'string' ? JSON.stringify(arg, null, 2) : arg) + '\n' }}
                td {{ item.route }}
                td {{ item.formattedTimestamp }}
</template>

<style lang="stylus">
.v-badge__badge
  width 20px
  height 20px
  font-size 12px

.ZAppNotifications__wrapper
  display flex
  justify-content center
  min-height 100%

  .ZAppNotifications__card
    position relative
    width 100%

    .ZAppNotifications__card__clear
      position absolute
      top 8px
      left 12px
      z-index 1
      min-width 0

.ZAppNotifications__table
  padding-bottom 150px

  table.v-table thead tr
    height 48px

  .v-datatable__progress
    position absolute
    top 0
    left 0

.ZAppNotifications__toolbarTitle
  margin-left 4px

.ZAppNotifications__message
  min-width 450px

.ZAppNotifications__expansionPanel
  &.v-expansion-panel__container
    background-color rgba(0, 0, 0, 0) !important

  .v-expansion-panel__body
    margin-bottom 16px

  .v-expansion-panel__header
    padding-left 0

  pre
    font-size 11px
    line-height 11px

.notification__new
  &:nth-child(2n)
    background-color alpha($color-warning, 0.1)

  &:nth-child(2n+1)
    background-color alpha($color-warning, 0.16)
</style>

<script setup lang="ts">
import dateFormat from 'dateformat';

import { useI18n } from '@/composables/plugins';
import store from '@/store';

import ZErrorMessage from '@/components/ui/atoms/ZErrorMessage.vue';
import ZDialog from '@/components/ui/molecules/ZDialog.vue';

import type { Notification } from '@/store/modules/notifications';

type FormattedNotification = Partial<
  Notification & {
    iconName: string;
    colorClass: string;
    title: string;
    formattedTimestamp: string;
  }
>;

export interface ZAppNotificationsProps {
  /**
   * If true, the component activator will be rendered as a list item instead of a button
   */
  listItem?: boolean;
}

withDefaults(defineProps<ZAppNotificationsProps>(), {
  listItem: false,
});

const emit = defineEmits<{
  (name: 'click'): void;
}>();

const headers = [
  { text: '', value: 'icon', sortable: false },
  { text: 'Service', value: 'service' },
  { text: 'Message', value: 'message', sortable: false },
  { text: 'Route', value: 'route' },
  { text: 'Timestamp', value: 'timestamp' },
];

const { t } = useI18n();

const open = computed<boolean>({
  get() {
    return store.getters['notifications/open'];
  },
  set(value) {
    store.commit('notifications/open', value);
  },
});

const notifications = computed<Notification[]>(
  () => store.getters['notifications/items']
);

const pagination = computed(() => ({
  page: 1,
  rowsPerPage: notifications.value.length,
  sortBy: 'timestamp',
  descending: true,
}));

const newNotificationsNum = computed<number>(() => {
  return notifications.value.reduce((total, notification) => {
    if (notification.new) return total + 1;
    else return total;
  }, 0);
});

const formattedNotifications = computed<FormattedNotification[]>(() => {
  return notifications.value.map(notification => {
    const formattedNotification: FormattedNotification = {
      ...notification,
      iconName: '',
      colorClass: '',
      title: notification.service,
      formattedTimestamp: dateFormat(
        notification.timestamp,
        'yyyy-mm-dd, HH:MM:ss'
      ),
    };
    // set notif icon name and color
    switch (notification.type) {
      default:
      case 'info':
        formattedNotification.iconName = 'mdi-information';
        formattedNotification.colorClass = 'info';
        break;
      case 'error':
        formattedNotification.iconName = 'mdi-alert-octagon';
        formattedNotification.colorClass = 'error';
        break;
      case 'warning':
        formattedNotification.iconName = 'mdi-alert';
        formattedNotification.colorClass = 'warning';
        break;
      case 'not_allowed':
        formattedNotification.iconName = 'mdi-cancel';
        formattedNotification.colorClass = 'error';
        break;
      case 'success':
        formattedNotification.iconName = 'mdi-check-circle';
        formattedNotification.colorClass = 'success';
        break;
      case 'expiry':
        formattedNotification.iconName = 'mdi-clock-alert';
        formattedNotification.colorClass = 'warning_alt1';
        break;
      case 'renew':
        formattedNotification.iconName = 'svg:renew_circle';
        formattedNotification.colorClass = 'success';
        break;
    }
    return formattedNotification;
  });
});

function handleClick(): void {
  emit('click');
  open.value = true;
}

function clearNotifications(): void {
  store.commit('notifications/clear');
}

watch(open, value => {
  // if we close the dialog, pass new notifs to false
  if (value === false) {
    formattedNotifications.value.forEach(
      notification => (notification.new = false)
    );
  }
});
</script>
