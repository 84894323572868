import { RoleStatus } from '#/core-api/enums';

import { roleIcon } from '@/config/icons';
import { defaultEnumDisplayProperties } from '@/lib/utils';

export function roleStatus(status?: RoleStatus): DisplayProperties {
  switch (status) {
    case RoleStatus.NotSet:
      return {
        message: 'roles__active',
        class: 'success',
      };
    case RoleStatus.RoleSoftDeleted:
      return {
        message: 'roles__soft_deleted',
        class: 'disabled',
        icon: 'mdi-close-circle',
      };
    default:
      return defaultEnumDisplayProperties(status);
  }
}

export function roleName(name: string): DisplayProperties {
  if (/web/i.test(name)) {
    return {
      message: name,
      icon: 'mdi-web',
    };
  }
  return {
    message: name,
    icon: roleIcon,
    class: 'disabled',
  };
}
