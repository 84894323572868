<template lang="pug">
.ZDataHistoryTable
  ZDataTable(
    :items="historyDisplayFields"
    :headers="tableHeaders"
    :page-size="pageSize"
    :loading="loading"
    :disabled="disabled"
    :error="error"
    :table-overflow-auto="smAndDown"
    dense
    no-max-width
    no-hint
  )
</template>

<script setup lang="ts">
import { apiConfig } from '@/config';
import { mapDisplayFieldsToVDataTableRow } from '@/lib/helpers/tables';
import { useVuetifyBreakpoints, useDisplayFields } from '@/composables';

import ZDataTable from '@/components/domain/model/ZDataTable.vue';

import type { DataTableItem } from '@/components/domain/model/ZDataTable.vue';

export interface ZDataHistoryTableProps {
  /**
   * The model name
   */
  modelName: ModelName;
  /**
   * The history items
   */
  items?: Record<string, any>[]; // eslint-disable-line @typescript-eslint/no-explicit-any
  /**
   * Number of item displayed per page
   */
  pageSize?: number;
  /**
   * Set table loading state
   */
  loading?: boolean;
  /**
   * Disable the table
   */
  disabled?: boolean;
  /**
   * Set table error state
   */
  error?: boolean;
  /**
   * Display the `before` column in data table
   */
  before?: boolean;
  /**
   * Display the `raw` column in data table
   */
  raw?: boolean;
  /**
   * Hide the `fields` column in data table
   */
  hideFields?: boolean;
  /**
   * The base field on which data has been filtered, to be removed for denser display purpose
   */
  prefix?: string | null;
}

const props = withDefaults(defineProps<ZDataHistoryTableProps>(), {
  items: () => [],
  pageSize: apiConfig.historyDataLimit,
  loading: false,
  disabled: false,
  error: false,
  before: false,
  raw: false,
  hideFields: false,
  prefix: null,
});

const { smAndDown } = useVuetifyBreakpoints();

const tableHeaders = computed<VuetifyTableHeader[]>(() => {
  const headers: VuetifyTableHeader[] = [
    { text: 'timestamp', value: 'timestamp', sortable: false },
    { text: 'action', value: 'action', sortable: false },
  ];
  if (!props.hideFields) {
    headers.push({
      text: 'fields',
      value: 'fields',
      sortable: false,
    });
  }
  if (props.before) {
    headers.push({
      text: 'before',
      value: 'before',
      sortable: false,
    });
  }
  headers.push({
    text: props.before ? 'after' : 'data',
    value: props.before ? 'after' : 'data',
    sortable: false,
  });
  if (props.raw) {
    headers.push({
      text: '(raw)',
      value: 'raw_value',
      sortable: false,
    });
  }
  return headers;
});

const { format } = useDisplayFields(() => ({
  modelName: props.modelName,
  historyOptions: {
    before: props.before,
    raw: props.raw,
    hideFields: props.hideFields,
  },
  ignoreKeysCleaning: true,
}));

const historyDisplayFields = computed<DataTableItem[]>(() => {
  return (
    props.items
      // flatten each entry into a single modification
      .reduce<Array<object>>((acc, entry) => {
        const { changed, removed, added, timestamp } = entry ?? {};
        if (changed) acc.push({ timestamp, changed });
        if (removed) acc.push({ timestamp, removed });
        if (added) acc.push({ timestamp, added });
        return acc;
      }, [])
      .map((entry: object) => format(entry))
      .map(mapDisplayFieldsToVDataTableRow)
      .map(row => {
        if (
          row.fields &&
          typeof row.fields === 'object' &&
          'val' in row.fields &&
          row.fields?.val
        ) {
          const prefixRegex = new RegExp('^' + props.prefix + '.');
          row.fields.val = row.fields.val.map((field: string) => {
            return field.replace(prefixRegex, '');
          });
        }
        return row as DataTableItem;
      })
  );
});
</script>
