import type ZMultiInput from '@/components/ui/molecules/ZMultiInput.vue';
import type ZInputDelay from '@/components/ui/molecules/ZInputDelay.vue';
import type ZInputPrice from '@/components/ui/molecules/ZInputPrice.vue';
import type ZInputPhoneNumber from '@/components/ui/molecules/ZInputPhoneNumber.vue';
import type ZInputDatePicker from '@/components/ui/molecules/ZInputDatePicker.vue';
import type { AlertAction } from '@/components/ui/molecules/ZAlert.vue';
import type { Price } from '@/components/ui/molecules/ZInputPrice.vue';
import type { InputDelayPeriod } from '@/components/ui/molecules/ZInputDelay.vue';
import type { ExtractPropTypes, WritableComputedRef } from 'vue';
import type { MaybeRef } from '@vueuse/core';

type InferProps<T extends { props?: object }> = Partial<
  ExtractPropTypes<T['props']>
>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyResponse = any;
type MaybeError = Error | unknown;
type MaybePromise<T> = Promise<T> | T;

export type MaybeActionSuccessFactory<
  TypeT,
  DataT extends object = object,
  ExecResponseT = AnyResponse,
> = ((self: ActionSelf<DataT>, response: ExecResponseT) => TypeT) | TypeT;

export type MaybeActionSelfFactory<TypeT, DataT extends object = object> =
  | ((self: ActionSelf<DataT>) => TypeT)
  | TypeT;

export type MaybeActionDataFactory<TypeT, DataT extends object = object> =
  | ((data: ActionData<DataT>) => TypeT)
  | TypeT;

// Internal types used to build final Control types
interface ActionControlBaseType<ControlTypeT extends ActionControlType> {
  /**
   * Defines the input type for the action control.
   */
  controlType: ControlTypeT;
}

interface ActionControlBaseKeyValue<
  KeyT extends keyof DataT = never,
  DataT extends object = object,
> {
  /**
   * Key of the control. Will be added to the action `data`.
   */
  key: KeyT;
  /**
   * Initial value of the control. Due to Vuetify’s inputs versatility, we must
   * allow the types `string[]` and `string` for the `value` property.
   * You can use a ref or a writable computed ref to bind the value. A readonly ref or computed will not work.
   */
  value?: MaybeActionDataFactory<
    | MaybeRef<DataT[KeyT] | string[] | string>
    | WritableComputedRef<DataT[KeyT] | string[] | string>,
    DataT
  >;
}

interface ActionControlCommon<DataT extends object = object> {
  /**
   * Color of the control.
   */
  color?: Color;
  /**
   * Display the control conditionally based on this method returned value.
   */
  condition?: (data: ActionData<DataT>) => boolean;
  /**
   * Whether the control is disabled.
   */
  disabled?: MaybeActionDataFactory<boolean, DataT>;
  /**
   * Automatically focus the control when the action dialog opens.
   */
  focus?: boolean;
  /**
   * Hint text of the control.
   */
  hint?: string;
  /**
   * Label of the input.
   */
  label?: string;
  /**
   * Messages for the control. You can use `%v` interpolation key in the string to display the control value.
   */
  messages?: MaybeActionDataFactory<string | string[], DataT>;
  /**
   * Hide the icon for the displayed `messages`.
   */
  noMessagesIcon?: boolean;
  /**
   * Whether the control has granted privilege(s) to be displayed.
   */
  privilege?: boolean | undefined | null;
  /**
   * Additional props to bind to the component.
   * @see https://v15.vuetifyjs.com/en
   */
  props?: VuetifyProps;
  /**
   * Unit for the control value, displayed after the label.
   */
  unit?: string;
  /**
   * Width of the control, used only if `flex` mode is on. Use percentage is a good idea.
   */
  width?: MaybeActionDataFactory<string | number, DataT>;
}

interface ActionControlValidatable<DataT extends object = object> {
  /**
   * Whether the control is required.
   */
  required?: MaybeActionDataFactory<boolean, DataT>;
  /**
   * Validation rules for the control.
   */
  rules?: MaybeActionDataFactory<VuetifyRule[], DataT>;
}

interface ActionControlHandleable<
  KeyT extends keyof DataT = never,
  DataT extends object = object,
> {
  /**
   * Function to handle the input value changes and transform it.
   * @param value - The input value.
   */
  handle?: (value: DataT[KeyT]) => DataT[KeyT];
  /**
   * Whether the control input text is automatically capitalized.
   */
  autocapitalize?: boolean;
}

interface ActionControlAssistable<DataT extends object = object> {
  /**
   * Mask for the control input.
   */
  mask?: MaybeActionDataFactory<string | string[], DataT>;
  /**
   * Placeholder text in the control input.
   */
  placeholder?: MaybeActionDataFactory<string, DataT>;
}

type ActionControlAutocompleteItem = {
  icon?: string;
  info?: string;
  subText?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

interface ActionControlAutocompleteLike<
  KeyT extends keyof DataT = never,
  DataT extends object = object,
> {
  /**
   * Available items in the autocomplete list.
   */
  items?: MaybeActionDataFactory<
    (DataT[KeyT] | ActionControlAutocompleteItem | string)[],
    DataT
  >;
  /**
   * Field used to display the item text.
   * @default 'text'
   */
  itemText?: string;
  /**
   * Field used as the item value.
   * @default 'value'
   */
  itemValue?: string;
  /**
   * Whether the control allows multiple values.
   */
  multiple?: boolean;
  /**
   * Whether the input value is returned as the full item object.
   */
  returnObject?: boolean;
  /**
   * Text to display when no items are found.
   */
  noDataText?: string;
}

declare global {
  /**
   * Represents the data carried by an action. All keys of input object are made to optional.
   */
  type ActionData<DataT extends object = object> = {
    [K in keyof DataT]: DataT[K] | undefined;
  };
  /**
   * References to the action control’s inputs.
   */
  type ActionRefs<DataT extends object = object> = {
    [K in keyof DataT]: HTMLInputElement & {
      focus?: () => void;
      resetValidation?: () => void;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [key: string]: any;
    };
  };
  /**
   * ZAction component instance.
   */
  interface ActionSelf<DataT extends object = object> {
    /**
     * Get a given control value by key
     */
    getData<Key extends keyof DataT>(key: Key): DataT[Key];
    /**
     * Set a given control’s value by key
     */
    setData<Key extends keyof DataT>(key: Key, value: DataT[Key]): boolean;
    /**
     * Get a given control’s template ref by key
     */
    getRef<Key extends keyof DataT>(key: Key): TemplateRef | undefined;
    /**
     * Reset form validation
     */
    resetValidation(): void;
    /**
     * Trigger the action
     */
    trigger({
      data,
      bypassDialog,
    }: {
      data?: Partial<DataT>;
      bypassDialog?: boolean;
    }): Promise<void>;
    /**
     * Cancel the action
     */
    cancel(): void;
    /**
     * The action’s progress value, between `0` and `100`.
     */
    progress: number;
    /**
     * The action’s state whether the dialog is kept open or not.
     */
    keepDialog: boolean;
    /**
     * The data carried by the action
     */
    data: ActionData<DataT>;
    /**
     * The action control refs
     */
    refs: ActionRefs<DataT>;
  }
  /**
   * Available types of input for the action controls.
   */
  type ActionControlType =
    | 'slot'
    | 'switch'
    | 'checkbox'
    | 'slider'
    | 'radioGroup'
    | 'input'
    | 'uint'
    | 'textarea'
    | 'multiInput'
    | 'delay'
    | 'price'
    | 'combobox'
    | 'autocomplete'
    | 'phone'
    | 'date';

  type ActionSeparatorType = 'sep';

  interface ActionControlSlot<DataT extends object = object>
    extends ActionControlValidatable<DataT>,
      Pick<ActionControlCommon<DataT>, 'condition'> {
    /**
     * Name of the opened slot.
     */
    name: string;
  }

  interface ActionControlSeparator<DataT extends object = object>
    extends Pick<
        ActionControlCommon<DataT>,
        'color' | 'disabled' | 'condition' | 'privilege'
      >,
      ActionControlValidatable<DataT> {
    /**
     * Defines the input type for the action separator.
     */
    controlType: ActionSeparatorType;
    /**
     * Whether the separator is text only.
     */
    textOnly?: boolean;
    /**
     * Text of the separator.
     */
    text?: MaybeActionDataFactory<string, DataT>;
    /**
     * CSS class for the text of the separator.
     */
    textClass?: string;
    /**
     * Whether the separator should be displayed as a hint.
     */
    isHint?: boolean;
    /**
     * Prepends an icon to the separator text.
     */
    icon?: string;
    /**
     * Choose the icon color.
     */
    iconColor?: string;
  }

  interface ActionControlSwitch<DataT extends object = object>
    extends ActionControlCommon<DataT>,
      ActionControlValidatable<DataT> {}

  interface ActionControlCheckbox<DataT extends object = object>
    extends ActionControlCommon<DataT>,
      ActionControlValidatable<DataT> {}

  interface ActionControlSlider<DataT extends object = object>
    extends ActionControlCommon<DataT>,
      ActionControlValidatable<DataT> {
    /**
     * Prepends an icon to the slider.
     */
    icon?: string;
    /**
     * Minimum value of the slider.
     * @default 0
     */
    min?: number;
    /**
     * Maximum value of the slider.
     * @default 100
     */
    max?: number;
  }

  /**
   * Radio options for an action’s radio-group control.
   */
  interface ActionRadio<TypeT = string> {
    /**
     * Value of the radio button.
     */
    value: TypeT;
    /**
     * Label for the radio button. Can be a translation key or translation arguments.
     */
    label?: string | I18nArgs;
    /**
     * Icon for the radio button.
     */
    icon?: string;
    /**
     * Color of the radio bullet.
     */
    color?: Color;
    /**
     * Whether the radio is disabled.
     */
    disabled?: boolean;
    /**
     * Hint text for the radio button.
     */
    hint?: string;
    /**
     * Additional properties for the radio button.
     * @see https://v15.vuetifyjs.com/en/components/selection-controls
     */
    props?: VuetifyProps;
  }

  interface ActionControlRadioGroup<
    KeyT extends keyof DataT = never,
    DataT extends object = object,
  > extends ActionControlCommon<DataT>,
      ActionControlValidatable<DataT> {
    /**
     * Type of the values. We must fill 'Boolean' if the values are booleans for the radio-group to work properly.
     * @default StringConstructor
     */
    type?: StringConstructor | NumberConstructor | BooleanConstructor;
    /**
     * Radio items of the radio-group control.
     */
    radios?: MaybeActionDataFactory<ActionRadio<DataT[KeyT]>[], DataT>;
  }

  interface ActionControlInput<
    KeyT extends keyof DataT = never,
    DataT extends object = object,
  > extends ActionControlCommon<DataT>,
      ActionControlValidatable<DataT>,
      ActionControlHandleable<KeyT, DataT>,
      ActionControlAssistable<DataT> {
    /**
     * Icon to append to the text field.
     */
    appendIcon?: MaybeActionDataFactory<string, DataT>;
    /**
     * CSS classes to apply to the appended icon. All Vue's class bindings are supported.
     */
    appendIconClasses?: string | string[] | Record<string, boolean>;
    /**
     * Whether the input is a password field.
     */
    password?: boolean;
    /**
     * Type of the value, so that the value is casted to the given type.
     * @default StringConstructor
     */
    type?: StringConstructor | NumberConstructor;
  }

  interface ActionControlUint<
    KeyT extends keyof DataT = never,
    DataT extends object = object,
  > extends ActionControlCommon<DataT>,
      ActionControlValidatable<DataT>,
      ActionControlHandleable<KeyT, DataT>,
      ActionControlAssistable<DataT> {}

  interface ActionControlTextarea<
    KeyT extends keyof DataT = never,
    DataT extends object = object,
  > extends ActionControlCommon<DataT>,
      ActionControlValidatable<DataT>,
      ActionControlHandleable<KeyT, DataT>,
      ActionControlAssistable<DataT> {}

  interface ActionControlMultiInput<
    KeyT extends keyof DataT = never,
    DataT extends object = object,
  > extends ActionControlCommon<DataT>,
      ActionControlValidatable<DataT>,
      ActionControlHandleable<KeyT, DataT> {
    /**
     * Whether the multi-input is for i18n purposes. It will fill the value with all the available locales.
     */
    i18n?: boolean;
    /**
     * Handler to transform the input key whenever it changes.
     */
    keyHandler?: (key: string) => string;
    /**
     * Available keys in the keys list.
     */
    keyItems?: string[];
    /**
     * Label of the keys to define what they represent, _e.g._ "Locale".
     */
    keyLabel?: string;
    /**
     * Rules to validate a new key in the control.
     */
    keyRules?: VuetifyRule[];
    /**
     * The initial selected key in the control.
     */
    selectedKey?: MaybeRef<string | undefined>;
    /**
     * Whether the multi-input text field is displayed as a textarea.
     */
    textarea?: boolean;
    /**
     * Additional props to bind to the component.
     */
    props?: InferProps<typeof ZMultiInput>;
    /**
     * Handler called whenever a new key is added.
     * @param key - The new added key.
     */
    handleAddKey?: (key: string) => void;
  }

  interface ActionControlDelay<
    KeyT extends keyof DataT = never,
    DataT extends object = object,
  > extends ActionControlCommon<DataT>,
      ActionControlValidatable<DataT>,
      ActionControlHandleable<KeyT, DataT> {
    /**
     * Default period of the delay input.
     * @default 'd'
     */
    defaultPeriod?: InputDelayPeriod;
    /**
     * Available period items.
     * @default ['d', 'w', 'm', 'y']
     */
    periodItems?: InputDelayPeriod[];
    /**
     * Additional props to bind to the component.
     */
    props?: InferProps<typeof ZInputDelay>;
  }

  interface ActionControlPrice<
    KeyT extends keyof DataT = never,
    DataT extends object = object,
  > extends ActionControlCommon<DataT>,
      ActionControlValidatable<DataT>,
      ActionControlHandleable<KeyT, DataT> {
    /**
     * Additional props to bind to the component.
     */
    props?: InferProps<typeof ZInputPrice>;
    /**
     * Disable currency input
     */
    disableCurrencyEdition?: boolean;
    /**
     * Narrow value type as we know it's a Price
     */
    value?: Price;
  }

  interface ActionControlCombobox<
    KeyT extends keyof DataT = never,
    DataT extends object = object,
  > extends ActionControlCommon<DataT>,
      ActionControlValidatable<DataT>,
      ActionControlHandleable<KeyT, DataT>,
      ActionControlAssistable<DataT>,
      ActionControlAutocompleteLike<KeyT, DataT> {
    /**
     * Displays a button to open a dialog helper to create new items.
     */
    withCreateNewItemsHelper?: {
      /**
       * The label of the new items field in the dialog.
       */
      label: string;
      /**
       * A tooltip for the new items button. If not provided, `label` will be used.
       */
      tooltip?: string;
    };
  }

  interface ActionControlAutocomplete<
    KeyT extends keyof DataT = never,
    DataT extends object = object,
  > extends ActionControlCommon<DataT>,
      ActionControlValidatable<DataT>,
      ActionControlHandleable<KeyT, DataT>,
      ActionControlAssistable<DataT>,
      ActionControlAutocompleteLike<KeyT, DataT> {
    /**
     * Whether the control uses chips to display the selected items.
     */
    chips?: boolean;
    /**
     * Whether the control is loading.
     */
    loading?: boolean;
    /**
     * Do not apply filtering when searching. Useful when data is being filtered server side.
     */
    noFilter?: boolean;
    /**
     * Make the input unclearable.
     */
    unclearable?: boolean;
    /**
     * Search async method to construct autocomplete items dynamically
     */
    search?: (
      value: string
    ) => Promise<(ActionControlAutocompleteItem | string)[]>;
    /**
     * Text message of the search.
     * @default Translated 'action__start_typing_to_search'
     */
    searchText?: string;
    /**
     * Initial value of the autocomplete search input.
     */
    searchInput?: string;
  }

  interface ActionControlPhone<
    KeyT extends keyof DataT = never,
    DataT extends object = object,
  > extends ActionControlCommon<DataT>,
      ActionControlValidatable<DataT>,
      ActionControlHandleable<KeyT, DataT> {
    /**
     * Additional props to bind to the component.
     */
    props?: InferProps<typeof ZInputPhoneNumber>;
  }

  interface ActionControlDate<DataT extends object = object>
    extends ActionControlCommon<DataT>,
      ActionControlValidatable<DataT> {
    /**
     * Allowed dates for a date picker control.
     * @param date - ISO 8601 date string (YY-mm-dd or YY-mm)
     * @param data - Action data.
     */
    allowedDates?: (date: string, data: ActionData<DataT>) => boolean;
    /**
     * Whether the control only allows future dates.
     */
    onlyFutureDates?: boolean;
    /**
     * Whether to use a date-time control.
     */
    dateTime?: boolean;
    /**
     * Minimum date for a date picker control, in ISO 8601 format.
     */
    minDate?: MaybeActionDataFactory<string, DataT>;
    /**
     * Maximum date for a date picker control, in ISO 8601 format.
     */
    maxDate?: MaybeActionDataFactory<string, DataT>;
    /**
     * Icon to display instead of the default one.
     * @default 'mdi-calendar'
     */
    icon?: string;
    /**
     * Additional props to bind to the component.
     */
    props?: InferProps<typeof ZInputDatePicker>;
  }

  type ActionControl<
    ControlTypeT extends ActionControlType,
    KeyT extends keyof DataT = never,
    DataT extends object = object,
  > = ActionControlBaseType<ControlTypeT> &
    ActionControlBaseKeyValue<KeyT, DataT> &
    (ControlTypeT extends 'slot'
      ? ActionControlSlot<DataT>
      : ControlTypeT extends 'switch'
        ? ActionControlSwitch<DataT>
        : ControlTypeT extends 'checkbox'
          ? ActionControlCheckbox<DataT>
          : ControlTypeT extends 'radioGroup'
            ? ActionControlRadioGroup<KeyT, DataT>
            : ControlTypeT extends 'slider'
              ? ActionControlSlider<DataT>
              : ControlTypeT extends 'input'
                ? ActionControlInput<KeyT, DataT>
                : ControlTypeT extends 'uint'
                  ? ActionControlUint<KeyT, DataT>
                  : ControlTypeT extends 'textarea'
                    ? ActionControlTextarea<KeyT, DataT>
                    : ControlTypeT extends 'multiInput'
                      ? ActionControlMultiInput<KeyT, DataT>
                      : ControlTypeT extends 'delay'
                        ? ActionControlDelay<KeyT, DataT>
                        : ControlTypeT extends 'price'
                          ? ActionControlPrice<KeyT, DataT>
                          : ControlTypeT extends 'combobox'
                            ? ActionControlCombobox<KeyT, DataT>
                            : ControlTypeT extends 'autocomplete'
                              ? ActionControlAutocomplete<KeyT, DataT>
                              : ControlTypeT extends 'phone'
                                ? ActionControlPhone<KeyT, DataT>
                                : ControlTypeT extends 'date'
                                  ? ActionControlDate<DataT>
                                  : never);

  type ActionSeparator<
    SeparatorTypeT extends ActionSeparatorType,
    DataT extends object = object,
  > = SeparatorTypeT extends 'sep' ? ActionControlSeparator<DataT> : never;

  /**
   * Step of an action.
   */
  interface ActionStep<DataT extends object = object> {
    /**
     * The title of the step.
     */
    title: string;
    /**
     * A hint for the step.
     */
    hint?: string;
    /**
     * Color of the step.
     */
    color?: Color;
    /**
     * Force applying the "solo" style (style when there is only one single step).
     */
    solo?: boolean;
    /**
     * Applies the `flex` style for the step: the controls will be in a flex container.
     */
    flex?: boolean;
    /**
     * Whether the step is not active.
     */
    inactive?: MaybeActionDataFactory<boolean, DataT>;
    /**
     * Defines if the step is complete, based on the action data.
     * @param data - Action data.
     */
    complete?: MaybeActionDataFactory<boolean | undefined, DataT>;
    /**
     * Text displayed in the summary of the step.
     * @param data - Action data.
     */
    summary?: MaybeActionDataFactory<string | undefined, DataT>;
    /**
     * The step’s controls.
     */
    controls?: (
      | ActionControl<ActionControlType, keyof DataT, DataT>
      | ActionSeparator<ActionSeparatorType, DataT>
    )[];
  }

  type ExecConfirmationInputType = 'text' | 'number' | 'password';

  /**
   * Confirmation popup for an action, displayed when hitting the action execution button.
   */
  interface ActionExecConfirmation<
    InputTypeT extends ExecConfirmationInputType = ExecConfirmationInputType,
  > {
    /**
     * Whether the action confirmation popup is active and will be displayed when hitting the action execution button.
     */
    active?: boolean;
    /**
     * The displayed text of the action confirmation popup.
     */
    text: string;
    /**
     * Optionally, some content displayed as a summary of the action in the confirmation popup.
     */
    recap?: string;
    /**
     * Optionally, an input field that requires to be filled with the correct `target` value to confirm the action.
     */
    input?: {
      /**
       * Supported input types
       */
      type: InputTypeT;
      /**
       * The input label
       */
      label: string;
      /**
       * The input target value, which value must be matched with the input value to confirm the action.
       */
      target: InputTypeT extends 'number' ? number : string;
    };
  }
  /**
   * Represents the settings for an action.
   */
  interface ActionSettings<
    DataT extends object = object,
    ExecResponseT = AnyResponse,
  > {
    /**
     * The main action title. Will be overridden by `dialogTitle` property if present.
     * In case `isListItem` is `true`, this title will be overridden by `listItemText` property if present.
     * @default 'Action'
     */
    text?: string;
    /**
     * A subtext displayed below the main title when `isListItem` is `true`.
     * @default ''
     */
    subText?: string;
    /**
     * The icon for the action.
     * @default 'mdi-wrench'
     */
    icon?: string;
    /**
     * Whether the action is displayed as round button with an icon only, the action title being rendered as a tooltip.
     * @default false
     */
    isIcon?: boolean;
    /**
     * Whether the action is displayed as a floating action button (FAB).
     * @default false
     */
    isFab?: boolean;
    /**
     * Whether the action is displayed as a list item. Should be used in a `VList` component.
     * @default false
     */
    isListItem?: boolean;
    /**
     * The text for the list item, overriding `text` and `dialogTitle` properties.
     * @default ''
     */
    listItemText?: string;
    /**
     * The color for the action button.
     * @default ''
     */
    color?: Color;
    /**
     * Title of the dialog
     */
    dialogTitle?: string;
    /**
     * The color for the dialog.
     * @default 'fifteen_control'
     */
    dialogColor?: Color;
    /**
     * The maximum width for the dialog in pixels.
     * @default 400
     */
    dialogMaxWidth?: number;
    /**
     * Disables the confirmation button in the action dialog.
     */
    disabledConfirm?: MaybeActionDataFactory<boolean, DataT>;
    /**
     * Additional properties for the action button.
     * @see https://v15.vuetifyjs.com/en/components/buttons
     * @default {}
     */
    btnProps?: VuetifyProps;
    /**
     * A hint for the action button.
     * @default ''
     */
    btnHint?: string;
    /**
     * Whether to use flex layout.
     * @default false
     */
    flex?: boolean;
    /**
     * Additional properties for the action icon.
     * @see https://v15.vuetifyjs.com/en/components/icons
     * @default {}
     */
    iconProps?: VuetifyProps;
    /**
     * A success message. Can be a factory function that returns a string, or a tuple [<translation key>, <translation data>].
     * @default null
     */
    successMessage?: MaybeActionSuccessFactory<string | I18nArgs, DataT> | null;
    /**
     * Actions displayed in the success alert. Can be a factory function that returns an array {@link AlertAction}.
     * @default null
     */
    successActions?: MaybeActionSuccessFactory<AlertAction[], DataT> | null;
    /**
     * The icon to display in the success alert
     */
    successIcon?: string;
    /**
     * Whether the action is disabled (_i.e._ shaded and not clickable).
     * @default false
     */
    disabled?: boolean;
    /**
     * Whether the action is hidden.
     * @default false
     */
    hidden?: boolean;
    /**
     * Whether the action has granted privilege(s) to be performed.
     * @default null
     */
    privilege?: boolean | undefined | null;
    /**
     * Confirmation executed.
     * @default null
     */
    execConfirmation?: MaybeActionSelfFactory<
      ActionExecConfirmation,
      DataT
    > | null;
    /**
     * Executed when the action is called. If an error is returned, an alert is displayed and the action ends.
     * @param self - The action component instance.
     */
    execFunc: (
      self: ActionSelf<DataT>
    ) => MaybePromise<
      | MaybeError
      | [MaybeError]
      | [MaybeError, undefined]
      | [null, ExecResponseT]
      | void
    >;
    /**
     * Executed when triggering the action, _i.e._ when the action button is clicked. If an error is returned, an alert is displayed and the action stops.
     * @param self - The action component instance.
     */
    onTrigger?: (
      self: ActionSelf<DataT>
    ) => MaybePromise<MaybeError | [MaybeError] | void>;
    /**
     * Executed when the action dialog is opened, right after `onTrigger` succeeded.
     * @param self - The action component instance.
     */
    onOpen?: (self: ActionSelf<DataT>) => void;
    /**
     * Executed on error. A boolean can be returned to control whether the error is displayed in an alert.
     * @param error - The caught error.
     */
    onError?: (error: Error) => boolean | void;
    /**
     * Executed on success.
     * @param self - The action component instance.
     * @param response - The response returned by `execFunc`
     */
    onSuccess?: (self: ActionSelf<DataT>, response: ExecResponseT) => void;
    /**
     * Executed on cancellation.
     */
    onCancel?: () => void;
    /**
     * Executed on the end. It will be always called at the end, even if the action is cancelled, aborted or failed.
     */
    onEnd?: (self: ActionSelf<DataT>) => void;
    /**
     * Executed whenever _any_ control’s input value changes, _i.e._ when `data` is updated.
     * @param data - Action updated data.
     * @param refs - Template references to the action control’s inputs.
     * @param normalizedStepIndex - The current step index, starting at `0`, if the action is a stepper.
     */
    onInput?: (
      data: ActionData<DataT>,
      refs?: ActionRefs<DataT>,
      normalizedStepIndex?: number
    ) => void;
    /**
     * Executed when the step is changed.
     * @param currentStepIndex - The current step index. Beware that this index starts at `1`.
     */
    onChangeStep?: (currentStepIndex: number, data: ActionData<DataT>) => void;
    /**
     * For a file input, executed when dropping a file.
     * @param result - Received file content.
     * @param data - Action updated data.
     */
    onDrop?:
      | ((result: FileReader['result'], data: ActionData<DataT>) => void)
      | null;
    /**
     * Action controls.
     * @default []
     */
    controls?: (
      | ActionControl<ActionControlType, keyof DataT, DataT>
      | ActionSeparator<ActionSeparatorType, DataT>
    )[];
    /**
     * When using a steps array, the action is displayed as a stepper.
     */
    steps?: ActionStep<DataT>[];
    /**
     * Whether to reset control’s inputs values when the action ends.
     * @default false
     */
    resetControls?: boolean;
    /**
     * Whether to display a dialog with additional content and required user interactions, such as controls, steps, and confirmation button.
     * @default true
     */
    withDialog?: boolean;
    /**
     * Whether to show progress bar when the `execFunc` is called. In order to set the `progress` value, you must update it from `self` argument of `execFunc`.
     * @example
     * execFunc: self => {
     *   // do async stuff and update progress
     *   self.progress = 50;
     * }
     * @default false
     */
    withProgress?: boolean;
    /**
     * Enable the confirmation of the action using the `Enter` key.
     * @default true
     */
    confirmKeyEnter?: boolean;
    /**
     * The content text of the dialog.
     * @default null
     */
    dialogContent?: string | null;
    /**
     * Props to bind to the dialog component.
     */
    dialogProps?: VuetifyProps;
    /**
     * Clip path CSS property to clip the dialog overlay.
     */
    dialogClippedOverlay?: string;
    /**
     * Whether to keep the dialog open when action is finalized. This will select the mode "confirm" of the confirmation options-button instead of "confirm and close".
     * @default false
     */
    keepDialogOpen?: boolean;
    /**
     * The initial step number of the action, when it is a stepper, if it is required to start at a specific step.
     * @default 1
     */
    initialStep?: number;
    /**
     * The text for the cancel button.
     * @default Translated 'action__cancel'
     */
    cancelText?: string;
    /**
     * The text for the confirmation button.
     * @default Translated 'action__confirm'
     */
    confirmText?: string;
    /**
     * An additional dialog displayed when the action is successfully executed. It opens a slot `'success-dialog-content'`.
     * @default null
     */
    successDialog?: {
      /**
       * Indicates whether the dialog is active or not.
       */
      active: boolean;
      /**
       * The text of the title for the dialog.
       */
      title: string;
      /**
       * The maximum width for the dialog. Can be a number (in pixels) or a string (CSS value).
       */
      maxWidth?: number | string;
      /**
       * The icon to be displayed in the dialog.
       */
      icon?: string;
      /**
       * A function called when the dialog is closed.
       */
      onClose?: () => void;
    };
    /**
     * Display this caution message in a red banner to warn the user about sensitive stuff
     */
    caution?: string;
    /**
     * Whether to disable the cancel button in the dialog
     */
    disableCancel?: boolean;
  }
}

/**
 * Type helper to define an action.
 * @param actionSettings - The input settings
 */
export function defineAction<
  DataT extends object = object,
  ExecResponseT = AnyResponse,
>(
  actionSettings: ActionSettings<DataT, ExecResponseT>
): ActionSettings<DataT, ExecResponseT> {
  return actionSettings;
}

/**
 * Type helper to define an action control.
 * @param actionControl - The input control config
 */
export function defineControl<
  ControlTypeT extends ActionControlType | ActionSeparatorType,
  KeyT extends keyof DataT = never,
  DataT extends object = object,
>(
  actionControl: ControlTypeT extends ActionSeparatorType
    ? ActionSeparator<ControlTypeT, DataT>
    : ControlTypeT extends ActionControlType
      ? ActionControl<ControlTypeT, KeyT, DataT>
      : never
): ControlTypeT extends ActionSeparatorType
  ? ActionSeparator<ControlTypeT, DataT>
  : ControlTypeT extends ActionControlType
    ? ActionControl<ControlTypeT, KeyT, DataT>
    : never {
  return actionControl;
}

/**
 * Type helper to define an action step.
 * @param actionStep - The input step config
 */
export function defineStep<DataT extends object = object>(
  actionStep: ActionStep<DataT>
): ActionStep<DataT> {
  return actionStep;
}
