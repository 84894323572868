import { AccessTechnology, SystemState } from '#/core-api/enums';

import type { ApiSchema } from '#/core-api';

/**
 * Compute signal icon based quality of signal
 * @param qualityOfSignal - Value of the signal quality, which is expressed as a percentage
 * @param accessTechnology - Technology used by the entity to communicate. (Can be 2G or 4G)
 */
export function signalPowerIcon(
  qualityOfSignal: number,
  accessTechnology?: AccessTechnology
): string {
  if (!accessTechnology) return 'mdi-signal-off';
  const is2G = accessTechnology === AccessTechnology._2G;
  switch (true) {
    case qualityOfSignal > 75:
      return is2G ? 'svg:signal_4_2g' : 'svg:signal_4_4g';
    case 75 >= qualityOfSignal && qualityOfSignal > 50:
      return is2G ? 'svg:signal_3_2g' : 'svg:signal_3_4g';
    case 50 >= qualityOfSignal && qualityOfSignal > 25:
      return is2G ? 'svg:signal_2_2g' : 'svg:signal_2_4g';
    case 25 >= qualityOfSignal:
      return is2G ? 'svg:signal_1_2g' : 'svg:signal_1_4g';
  }
  return 'mdi-signal-off';
}

/**
 * Compute the signal badge for the given entity's connectivity info
 * @param connectivityInfo - The entity connectivity info
 */
export function connectivityBadge(
  connectivityInfo: ApiSchema['entity.ConnectivityInfo'] = {}
): Badge {
  const connected = connectivityInfo.connected;

  const icon = !connected
    ? 'mdi-signal-off'
    : signalPowerIcon(
        connectivityInfo.quality_of_signal ?? 0,
        connectivityInfo?.access_technology
      );

  return {
    icon,
    color: 'transparent',
    iconColor: icon === 'mdi-signal-off' ? 'grey' : 'success',
    relative: true,
    dense: true,
  };
}

/**
 * Check whether the given sim can be activated
 * @param sim - The sim to check
 */
export function isSimActivatable(sim?: ApiSchema['fleet.Sim']): boolean {
  // TODO: remove casting when the API is updated
  return (
    Number(sim?.current_state?.state) === SystemState.Inventory ||
    Number(sim?.current_state?.state) === SystemState.Suspended
  );
}
