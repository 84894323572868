export interface UseKeyboardOptions {
  onKeyup?: (key: KeyboardEvent, pressedKeys: Map<string, boolean>) => void;
  onKeydown?: (key: KeyboardEvent, pressedKeys: Map<string, boolean>) => void;
}

export interface UseKeyboardReturn {
  /**
   * Map of pressed keys
   */
  pressedKeys: Map<string, boolean>;
}

// Singleton map to store the pressed state of the keys
const pressedKeys = new Map<string, boolean>();

/**
 * Binds keydown and keyup events to the window object and provides a map of pressed keys
 * @param options - Options
 */
export function useKeyboard(options: UseKeyboardOptions): UseKeyboardReturn {
  function handleKeydown(event: KeyboardEvent): void {
    pressedKeys.set(event.key, true);
    options.onKeydown?.(event, pressedKeys);
  }

  function handleKeyup(event: KeyboardEvent): void {
    options.onKeyup?.(event, pressedKeys);
    pressedKeys.set(event.key, false);
  }

  useEventListener('keydown', handleKeydown, true);
  useEventListener('keyup', handleKeyup, true);

  return {
    pressedKeys,
  };
}
