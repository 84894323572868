import { apiConfig } from '@/config';
import StoreModule from '@/store/core/store-module';
import { offerFields } from '@/models/fields';
import { areasPopulateConfig } from '@/lib/helpers/populate';
import { addOfferCategory } from '@/models/offer/helpers';

import type { ApiSchema } from '#/core-api';
import type { OfferCategory } from '@/models/offer/enums';
import type { PopulatedField } from '@/store/core/populate';
import type { Get, Post, Patch, Delete } from '@/store/types/core-api';

declare global {
  /**
   * Populated Offer
   */
  type Offer = DeepMerge<
    ApiSchema['payment.Offer'],
    {
      id: string;
      areas?: PopulatedField<
        ApiSchema['payment.Offer']['area_ids'],
        ApiSchema['area.ServiceableArea']['label'][]
      >;
      category?: OfferCategory;
    }
  >;
}

const offersScopes = ['Offers', 'App'] as const;

export interface OfferStoreTypes {
  OFFERS: [
    Get<'/payments/offers', Offer[], typeof offersScopes>,
    Post<'/payments/offers'>,
  ];
  OFFERS_COUNT: [Get<'/payments/offers/count', void, typeof offersScopes>];
  OFFERS_ALL: [Get<'/payments/offers', Offer[], typeof offersScopes>];
  OFFER: [
    Get<'/payments/offers/{ID}', Offer>,
    Patch<'/payments/offers/{ID}'>,
    Delete<'/payments/offers/{ID}'>,
  ];
  OFFER_SYNC_BENEFITS: [Post<'/payments/offers/{ID}/sync'>];
}

const offersModule = new StoreModule<OfferStoreTypes>();
offersModule
  .createResourceAndAction({
    type: 'OFFERS',
    requestEndPoint: '/payments/offers',
    scopes: offersScopes,
    requestOptions: () => ({
      params: {
        fields: offerFields,
      },
    }),
    populate: [
      areasPopulateConfig({
        withWarehouse: false,
        dataField: 'area_ids',
        asKey: 'areas',
      }),
    ],
    transform: data => (data.offers ?? []).map(addOfferCategory),
  })
  .createResourceAndAction({
    type: 'OFFERS_COUNT',
    requestEndPoint: '/payments/offers/count',
    scopes: offersScopes,
  })
  .aliasAction({
    fromType: 'OFFERS',
    toType: 'OFFERS_ALL',
    requestMethod: 'GET',
    requestOptions: () => ({
      preventPopulate: true,
      params: { limit: apiConfig.offerResponseLimit },
    }),
  })
  .createResourceAndAction({
    type: 'OFFER',
    requestEndPoint: '/payments/offers/:id',
    requestOptions: () => ({
      headers: { 'accept-language': '' }, // so that i18n fields are fully returned
    }),
    transform: data => addOfferCategory(data.offer as Offer),
    populate: [
      areasPopulateConfig({
        withWarehouse: false,
        dataField: 'area_ids',
        asKey: 'areas',
      }),
    ],
  })
  .createAction({
    type: 'OFFERS',
    requestEndPoint: '/payments/offers',
    requestMethod: 'POST',
  })
  .createAction({
    type: 'OFFER',
    requestEndPoint: '/payments/offers/:id',
    requestMethod: 'PATCH',
  })
  .createAction({
    type: 'OFFER',
    requestEndPoint: '/payments/offers/:id',
    requestMethod: 'DELETE',
    permission: 'gateway.offer.delete',
  })
  .createAction({
    type: 'OFFER_SYNC_BENEFITS',
    requestEndPoint: '/payments/offers/:id/sync',
    requestMethod: 'POST',
    permission: 'gateway.offer.sync_benefits',
  });

export default offersModule;
