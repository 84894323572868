<template lang="pug">
.ZVehicleIcon
  svg(viewBox="0 0 16 16")
    circle(
      cx="8"
      cy="8"
      r="7"
      :fill="outerColor"
    )
    circle(
      cx="8"
      cy="8"
      r="3"
      :fill="innerColor"
    )
</template>

<style lang="stylus">
.ZVehicleIcon
  min-width 16px
  min-height 16px
  max-width 16px
  max-height 16px
</style>

<script setup lang="ts">
import { vehicleMaintenanceState } from '@/models/vehicle/mappers/display';
import {
  isVehicleOutOfOrder,
  vehicleIconInnerColor,
} from '@/models/vehicle/helpers';
import theme from '@/themes';

export interface ZVehicleIconProps {
  /**
   * Vehicle item
   */
  vehicle: Vehicle;
}

const props = defineProps<ZVehicleIconProps>();

const outerColor = computed(
  () =>
    theme[
      vehicleMaintenanceState(props.vehicle.maintenance_state)
        .class as keyof typeof theme
    ]
);

const innerColor = computed(() => {
  return theme[
    props.vehicle?.maintenance_state &&
    isVehicleOutOfOrder(props.vehicle.maintenance_state)
      ? 'error'
      : vehicleIconInnerColor(props?.vehicle)
  ];
});
</script>
