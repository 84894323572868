/**
 * Natively format a number alongside its currency in a given language using Intl
 * @param locale - The locale string
 * @param number - The amount in cents
 * @param currency - The currency
 */
export default function formatCurrencyNumber(
  locale: string,
  amount: number = 0,
  currency?: CurrencyCode | string
): string {
  const normalizedAmount = amount / 100;
  try {
    // We try to format the amount using Intl in best effort...
    return Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
    }).format(normalizedAmount);
  } catch {
    // ...if it fails because the currency is not supported,
    // we fallback to a simple format and avoid throwing an error
    return `${normalizedAmount} ${currency ?? ''}`;
  }
}
