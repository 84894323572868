<template lang="pug">
.ZCheckGroupAll
  VToolbar.elevation-0(
    v-if="items.length === 1"
    :height="36"
  )
    h3 {{ t('areas__menu_name') }}
  VList.ZCheckGroupAll__radios-list(v-else)
    VRadioGroup(
      v-model="selectMode"
      hide-details
    )
      VListTile(@click="selectMode = 'all'")
        VRadio(
          :color="color"
          value="all"
        )
          template(#label)
            span(:class="selectMode === 'all' ? color + '--text' : ''") {{ selectAllText }}
      VListTile.ZCheckGroupAll__areas
        VTextField.ZCheckGroupAll__select-by(
          v-model="selectByInput"
          :label="selectByText"
          solo
          hide-details
          clearable
          @focus="isSelectByInputFocused = true"
          @blur="isSelectByInputFocused = false"
        )
          template(#prepend)
            VIcon(:color="selectMode === 'by' ? color : ''") {{ selectMode === 'by' ? 'mdi-radiobox-marked' : 'mdi-radiobox-blank' }}
  VList.ZCheckGroupAll__checkboxes-list(
    dense
    :class="selectMode === 'all' && !isSelectByInputFocused ? 'ZCheckGroupAll__checkboxes-list--disabled-like' : ''"
  )
    VListTile(
      v-for="(item, i) in filteredItems"
      :key="i"
      @click="items.length > 1 ? select(item[itemKey]) : selectOnly(item[itemKey])"
    )
      VListTileContent
        VFlex.align-center
          template(v-if="items.length > 1")
            VIcon.ZCheckGroupAll__checkbox-icon(
              :color="selectedStates[item[itemKey]] ? color : ''"
            )
              | {{ selectedStates[item[itemKey]] ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline' }}
            span.ZCheckGroupAll__label(
              :class="selectedStates[item[itemKey]] ? 'ZCheckGroupAll__item--selected' : ''"
              v-html="matched(item[itemText])"
            )
            VSpacer
            VBtn.ZCheckGroupAll__select-only-btn(
              :class="{ marked: isSolo(item[itemKey]), 'mr-0': items.length === 1 }"
              flat
              icon
              @click.stop="selectOnly(item[itemKey])"
            )
              VIcon(:color="isSolo(item[itemKey]) ? color : ''")
                | {{ isSolo(item[itemKey]) ? 'mdi-radiobox-marked' : 'mdi-radiobox-blank' }}
          template(v-else)
            h3(
              :class="selectedStates[item[itemKey]] ? color + '--text' : ''"
              v-html="matched(item[itemText])"
            )
</template>

<style lang="stylus">
.ZCheckGroupAll
  display flex
  flex-direction column
  width 100%

  .v-text-field.v-text-field--solo .v-label
    top calc(50% - 15px)

  .v-toolbar__content
    padding 0 16px

.ZCheckGroupAll
  .theme--dark.v-list.ZCheckGroupAll__radios-list
    background-color lighten($colors.grey.darken-4, 10%)

  .theme--light.v-list.ZCheckGroupAll__radios-list
    background-color: $colors.grey.lighten-4

.v-list.ZCheckGroupAll__radios-list
  margin-top 0

  .v-input--selection-controls
    margin-top 0
    padding-top 0

    .v-input__control
      width 100%

.ZCheckGroupAll__label
  line-height 1

.ZCheckGroupAll__areas
  height 56px
  display flex
  align-items center

  .v-list__tile
    min-height 40px !important
    height 40px !important

  .v-text-field.v-text-field--solo .v-input__prepend-outer,
  .v-text-field.v-text-field--solo .v-input__append-outer
    margin-top 0

.ZCheckGroupAll__select-by
  display flex
  align-items center

  .v-label
    padding 6px 0 8px

.ZCheckGroupAll__checkbox-icon
  margin 0 16px

.v-list.ZCheckGroupAll__checkboxes-list
  max-height calc(100vh - 166px)
  overflow auto
  transition opacity 0.3s

  &.v-list--dense .v-list__tile
    font-size 16px
    margin 1px

  .flex
    width 100%

  .ZCheckGroupAll__select-only-btn:not(.marked)
    opacity 0.7
    transition opacity 0.3s

  &.theme--light .ZCheckGroupAll__select-only-btn:not(.marked)
    color rgba(0, 0, 0, 0.7)

  .v-input--selection-controls
    padding-left 8px

    &.theme--dark
      opacity 0.7
      transition opacity 0.3s

    .v-input--selection-controls__input
      height 32px

    &.v-input--is-label-active
      opacity 1

    .theme--dark.v-label
      color #fff

  &.ZCheckGroupAll__checkboxes-list--disabled-like
    .ZCheckGroupAll__checkbox-icon,
    .ZCheckGroupAll__label,
    .ZCheckGroupAll__select-only-btn
      opacity 0.4

  .v-list__tile__content
    overflow visible

  .v-list__tile:hover
    .ZCheckGroupAll__checkbox-icon,
    .ZCheckGroupAll__label,
    .ZCheckGroupAll__select-only-btn
      opacity 1

.theme--dark
  .ZCheckGroupAll__item--selected
    color $color-primary

    .ZCheckGroupAll__item--matched
      color $color-primary

.theme--light
  .ZCheckGroupAll__item--selected
    color $color-info

    .ZCheckGroupAll__item--matched
      color $color-info

.ZCheckGroupAll__item--matched
  background-color alpha($colors.grey.base, 15%)

.theme--light
  .ZCheckGroupAll__item--matched
    color darken($color-warning_alt1, 25%)

.theme--dark
  .ZCheckGroupAll__item--matched
    color $color-warning_alt1

scrollbar-theme('.ZCheckGroupAll')
</style>

<script setup lang="ts">
import { useVModelProxy } from '@/composables';
import { useI18n } from '@/composables/plugins';
import { generateSearchRegexString } from '@/lib/utils';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Item = Record<string, any>;

export interface ZCheckGroupAllProps {
  /**
   * The selected item (full item or only keys, whether `returnObject` is true or false).
   */
  value?: (string | Item)[];
  /**
   * The items to be displayed.
   */
  items?: Item[];
  /**
   * The text to be displayed for the "Select all" option.
   */
  selectAllText?: string;
  /**
   * The text to be displayed for the "Select by" option.
   */
  selectByText?: string;
  /**
   * The color of the component.
   */
  color?: string;
  /**
   * The key of the item to be used for selection.
   */
  itemKey?: string;
  /**
   * The text of the item to be used for display.
   */
  itemText?: string;
  /**
   * Whether to return the selected items as objects or not.
   */
  returnObject?: boolean;
}

const props = withDefaults(defineProps<ZCheckGroupAllProps>(), {
  value: () => [],
  items: () => [],
  selectAllText: 'All',
  selectByText: 'Select',
  color: 'primary',
  itemKey: 'key',
  itemText: 'text',
  returnObject: false,
});

const checkGroupValue = useVModelProxy({ props });

const { t } = useI18n();

type SelectMode = 'all' | 'by';

const selectMode = ref<SelectMode>('all');
const selectByInput = ref('');
const isSelectByInputFocused = ref(false);
const delaySelection = ref(true);

const filteredItems = computed(() => {
  if (!selectByInput.value) return props.items;
  const filterRegex = new RegExp(
    generateSearchRegexString(selectByInput.value),
    'ig'
  );
  return props.items.filter(item => filterRegex.test(item[props.itemText]));
});

const selectedItems = computed<Item[]>({
  get: () => {
    if (props.returnObject) return props.value as Item[];
    return props.items.filter(item =>
      props.value.includes(item[props.itemKey])
    );
  },
  set: value => {
    checkGroupValue.value = props.returnObject
      ? value
      : value.map(item => item[props.itemKey]);
  },
});

const selectedStates = computed(() => {
  if (delaySelection.value) return {};
  return selectedItems.value.reduce<Record<string, boolean>>((states, item) => {
    states[item[props.itemKey]] = true;
    return states;
  }, {});
});

function computeSelectMode(): void {
  selectMode.value =
    props.items.length > 1 && isSelectedAll.value ? 'all' : 'by';
}

function select(key: string): void {
  if (selectedStates.value[key]) {
    selectedItems.value = selectedItems.value.filter(
      item => key !== item[props.itemKey]
    );
  } else {
    selectedItems.value = selectedItems.value.concat(
      props.items.find(item => key === item[props.itemKey]) ?? []
    );
  }
}

function selectOnly(key: string): void {
  selectedItems.value = props.items.filter(item => key === item[props.itemKey]);
}

function isSolo(key: string): boolean {
  return (
    selectedStates.value[key] && Object.keys(selectedStates.value).length === 1
  );
}

function matched(text: string): string {
  if (!selectByInput.value) return text;
  const filterRegex = new RegExp(
    generateSearchRegexString(selectByInput.value),
    'ig'
  );
  return text.replace(
    filterRegex,
    '<span class="ZCheckGroupAll__item--matched">$1</span>'
  );
}

const isSelectedAll = computed(() => {
  return (
    props.items.length > 1 &&
    (selectedItems.value.length === props.items.length ||
      !selectedItems.value.length)
  );
});

watch([() => props.items, () => props.value, isSelectedAll], computeSelectMode);
watch(selectedItems, (newVal, oldVal) => {
  if (oldVal.length > newVal.length && newVal.length === 0) {
    if (props.items.length > 1) selectMode.value = 'all';
    else selectMode.value = 'by';
  }
  if (oldVal.length === 0 && newVal.length > oldVal.length) {
    selectMode.value = 'by';
  }
});
watch(selectMode, newValue => {
  if (newValue === 'all') selectedItems.value = [];
});

onMounted(() => {
  computeSelectMode();
  if (props.items.length === 1) selectedItems.value = props.items;
  setTimeout(() => (delaySelection.value = false), 1000);
});

function clearInput(): void {
  selectByInput.value = '';
}

defineExpose({
  clearInput,
});
</script>
