import { useVuetify } from './plugins';

interface UseBreakPointsReturn {
  xs: ComputedRef<boolean>;
  sm: ComputedRef<boolean>;
  md: ComputedRef<boolean>;
  lg: ComputedRef<boolean>;
  xsOnly: ComputedRef<boolean>;
  smOnly: ComputedRef<boolean>;
  mdOnly: ComputedRef<boolean>;
  lgOnly: ComputedRef<boolean>;
  smAndDown: ComputedRef<boolean>;
  mdAndDown: ComputedRef<boolean>;
  lgAndDown: ComputedRef<boolean>;
  smAndUp: ComputedRef<boolean>;
  mdAndUp: ComputedRef<boolean>;
  lgAndUp: ComputedRef<boolean>;
  width: ComputedRef<number>;
  height: ComputedRef<number>;
}

/**
 * Composable for css breakpoints
 */
export function useVuetifyBreakpoints(): UseBreakPointsReturn {
  const vuetify = useVuetify();

  const xsOnly = computed(() => {
    return vuetify.breakpoint.xsOnly;
  });
  const smOnly = computed(() => {
    return vuetify.breakpoint.smOnly;
  });
  const mdOnly = computed(() => {
    return vuetify.breakpoint.mdOnly;
  });
  const lgOnly = computed(() => {
    return vuetify.breakpoint.lgOnly;
  });

  const xs = computed(() => {
    return vuetify.breakpoint.xs;
  });
  const sm = computed(() => {
    return vuetify.breakpoint.sm;
  });
  const md = computed(() => {
    return vuetify.breakpoint.md;
  });
  const lg = computed(() => {
    return vuetify.breakpoint.lg;
  });

  const smAndDown = computed(() => {
    return vuetify.breakpoint.smAndDown;
  });

  const mdAndDown = computed(() => {
    return vuetify.breakpoint.mdAndDown;
  });

  const lgAndDown = computed(() => {
    return vuetify.breakpoint.lgAndDown;
  });

  const smAndUp = computed(() => {
    return vuetify.breakpoint.smAndUp;
  });

  const mdAndUp = computed(() => {
    return vuetify.breakpoint.mdAndUp;
  });

  const lgAndUp = computed(() => {
    return vuetify.breakpoint.lgAndUp;
  });

  const width = computed(() => {
    return vuetify.breakpoint.width;
  });

  const height = computed(() => {
    return vuetify.breakpoint.width;
  });

  return {
    xs,
    sm,
    md,
    lg,
    xsOnly,
    smOnly,
    mdOnly,
    lgOnly,
    smAndDown,
    mdAndDown,
    lgAndDown,
    smAndUp,
    mdAndUp,
    lgAndUp,
    width,
    height,
  };
}
