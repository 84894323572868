<script lang="ts">
import type { VNode } from 'vue';

export interface ZVnodeRendererProps {
  vnode: VNode;
}

export default defineComponent<ZVnodeRendererProps>({
  name: 'ZVnodeRenderer',
  props: {
    /**
     * The vnode to render
     */
    vnode: {
      type: Object as PropType<VNode>,
      required: true,
    },
  },
  setup(props) {
    return () => props.vnode;
  },
});
</script>
