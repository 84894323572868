import StoreModule from '@/store/core/store-module';

interface Env {
  label: string;
  gatewayUrl: string;
  datacenterUrl: string;
}

export interface EnvStoreTypes {
  ENV: [{ data: Env }];
}

const envModule = new StoreModule<EnvStoreTypes>();
envModule.createResource({
  type: 'ENV',
  defaultValue: {
    label: window.VUE_APP_DEPLOYMENT_LABEL,
    gatewayUrl: window.VUE_APP_GATEWAY_URL,
    datacenterUrl: window.VUE_APP_DATACENTER_URL,
  },
});

export default envModule;
