import StoreModule from '@/store/core/store-module';
import { areasPopulateConfig } from '@/lib/helpers/populate';
import { pointOfInterestFields } from '@/models/fields';

import type { ApiSchema } from '#/core-api';
import type { PopulatedField } from '@/store/core/populate';
import type { Get, Post, Patch, Delete } from '@/store/types/core-api';

declare global {
  /**
   * Populated Vehicle
   */
  type PointOfInterest = DeepMerge<
    ApiSchema['entity.PointOfInterest'],
    {
      id: string;
      area?: PopulatedField<
        ApiSchema['entity.PointOfInterest']['area_id'],
        ApiSchema['area.ServiceableArea']['label']
      >;
    }
  >;
}

const pointsOfInterestScopes = [
  'PointsOfInterest',
  'Map',
  'App',
  'Other',
] as const;

export interface PointOfInterestStoreTypes {
  POINTS_OF_INTEREST: [
    Get<
      '/entities/points_of_interest',
      PointOfInterest[],
      typeof pointsOfInterestScopes
    >,
    Post<'/entities/points_of_interest'>,
  ];
  POINTS_OF_INTEREST_COUNT: [
    Get<
      '/entities/points_of_interest/count',
      void,
      typeof pointsOfInterestScopes
    >,
  ];
  POINT_OF_INTEREST: [
    Get<'/entities/points_of_interest/{Id}', PointOfInterest>,
    Patch<'/entities/points_of_interest/{Id}'>,
    Delete<'/entities/points_of_interest/{Id}'>,
  ];
}

const pointsOfInterestModule = new StoreModule<PointOfInterestStoreTypes>();
pointsOfInterestModule
  .createResourceAndAction({
    type: 'POINTS_OF_INTEREST',
    requestEndPoint: '/entities/points_of_interest',
    scopes: pointsOfInterestScopes,
    withGlobalUsageQuery: true,
    requestOptions: () => ({
      freeze: true,
      params: {
        fields: pointOfInterestFields,
      },
    }),
    populate: [areasPopulateConfig()],
    transform: data => data.points_of_interest,
  })
  .createResourceAndAction({
    type: 'POINTS_OF_INTEREST_COUNT',
    requestEndPoint: '/entities/points_of_interest/count',
    scopes: pointsOfInterestScopes,
    withGlobalUsageQuery: true,
  })
  .createResourceAndAction({
    type: 'POINT_OF_INTEREST',
    requestEndPoint: '/entities/points_of_interest/:id',
    populate: [areasPopulateConfig()],
  })
  .createAction({
    type: 'POINTS_OF_INTEREST',
    requestEndPoint: '/entities/points_of_interest',
    requestMethod: 'POST',
    permission: 'gateway.point_of_interest.add',
  })
  .createAction({
    type: 'POINT_OF_INTEREST',
    requestEndPoint: '/entities/points_of_interest/:id',
    requestMethod: 'PATCH',
    permission: 'gateway.point_of_interest.edit',
  })
  .createAction({
    type: 'POINT_OF_INTEREST',
    requestEndPoint: '/entities/points_of_interest/:id',
    requestMethod: 'DELETE',
    permission: 'gateway.point_of_interest.delete',
  });

export default pointsOfInterestModule;
