import type { Location, RawLocation } from 'vue-router';

interface UseWrapRouteReturn {
  wrapRoute: (targetRoute?: RawLocation) => RawLocation | undefined;
}

export function useWrapRoute(): UseWrapRouteReturn {
  const route = useRoute();

  /**
   * Wrap a route with the desired query parameters
   */
  function wrapRoute(targetRoute?: RawLocation): RawLocation | undefined {
    if (!route) return targetRoute;
    if (!targetRoute) return targetRoute;

    const currentQuery = route.query || {};
    const query = {
      ...(targetRoute as Location).query,
      ...currentQuery,
    };

    return typeof targetRoute === 'string'
      ? { path: targetRoute, query }
      : {
          ...targetRoute,
          query,
        };
  }

  return { wrapRoute };
}
