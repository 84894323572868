import StoreModule from '@/store/core/store-module';
import { tripFields } from '@/models/fields';
import {
  userEmailPopulateConfig,
  areasPopulateConfig,
  destinationTripPopulateConfig,
  pickupTripPopulateConfig,
  depositTripPopulateConfig,
} from '@/lib/helpers/populate';

import type { ApiSchema } from '#/core-api';
import type { PopulatedField } from '@/store/core/populate';
import type { Get, Patch, Post } from '@/store/types/core-api';

declare global {
  /**
   * Populated Trip
   */
  type Trip = DeepMerge<
    ApiSchema['rental.Trip'],
    {
      id: string;
      user?: PopulatedField<
        ApiSchema['rental.Trip']['user_id'],
        ApiSchema['user.User']['email']
      >;
      area?: PopulatedField<
        ApiSchema['rental.Trip']['area_id'],
        ApiSchema['area.ServiceableArea']['label']
      >;
      deposit?: {
        label?: PopulatedField<
          NonNullable<ApiSchema['rental.Trip']['deposit']>['id' | 'parent_id'],
          ApiSchema['station.Fastener' | 'station.Station']['label']
        >;
      };
      pickup?: {
        label?: PopulatedField<
          NonNullable<ApiSchema['rental.Trip']['pickup']>['id' | 'parent_id'],
          ApiSchema['station.Fastener' | 'station.Station']['label']
        >;
        parking?: PopulatedField<
          NonNullable<ApiSchema['rental.Trip']['pickup']>['parking_id'],
          ApiSchema['entity.Parking']
        >;
      };
    }
  >;
}

const tripsScopes = [
  'Trips',
  'DashboardOngoingTrips',
  'DashboardEndedTrips',
  'UserTrips',
  'BikeTrips',
  'VehicleTrips',
  'RentalTrips',
] as const;

export interface TripStoreTypes {
  TRIPS: [Get<'/trips', Trip[], typeof tripsScopes>];
  TRIPS_COUNT: [Get<'/trips/count', void, typeof tripsScopes>];
  TRIP: [Get<'/trips/{ID}', Trip>];
  TRIP_RISK: [Patch<'/trips/{ID}/risk'>];
  TRIP_ESTIMATE: [Post<'/trips/estimate'>];
  TRIP_BOOK: [Post<'/trips/{ID}/book'>];
  TRIP_START: [Post<'/trips/start'>];
  TRIP_CANCEL: [Post<'/trips/{ID}/cancel'>];
  TRIP_END: [Post<'/trips/{ID}/end_verify'>];
  TRIP_END_VALIDATE: [Post<'/trips/{ID}/end_validate'>];
  TRIP_PAUSE: [Post<'/trips/{ID}/pause'>];
  TRIP_RESUME: [Post<'/trips/{ID}/resume'>];
  TRIP_REFUND: [Post<'/trips/{ID}/refund'>];
  TRIP_BATTERY_STATE_UPDATES: [
    Get<
      '/trips/{ID}/battery_state_updates',
      Required<ApiSchema['rental.TripBatteryStateUpdate']>[]
    >,
  ];
  TRIP_LOCATION_UPDATES: [
    Get<
      '/trips/{ID}/location_updates',
      Required<ApiSchema['rental.TripLocationUpdate']>[]
    >,
  ];
}

const tripsModule = new StoreModule<TripStoreTypes>();
tripsModule
  .createResourceAndAction({
    type: 'TRIPS',
    requestEndPoint: '/trips',
    scopes: tripsScopes,
    withGlobalUsageQuery: true,
    requestOptions: () => ({
      freeze: true,
      params: {
        fields: tripFields,
      },
    }),
    populate: [
      userEmailPopulateConfig(),
      destinationTripPopulateConfig(),
      pickupTripPopulateConfig(),
      depositTripPopulateConfig(),
      areasPopulateConfig(),
    ],
    transform: data => data.trips,
  })
  .createResourceAndAction({
    type: 'TRIPS_COUNT',
    requestEndPoint: '/trips/count',
    scopes: [...tripsScopes, 'Dashboard'],
    withGlobalUsageQuery: true,
  })
  .createResourceAndAction({
    type: 'TRIP',
    requestEndPoint: '/trips/:id',
    transform: data => data.trip,
    populate: [
      userEmailPopulateConfig(),
      areasPopulateConfig(),
      destinationTripPopulateConfig(),
      pickupTripPopulateConfig(),
      depositTripPopulateConfig(),
    ],
  })
  .createAction({
    type: 'TRIP_RISK',
    requestEndPoint: '/trips/:id/risk',
    requestMethod: 'PATCH',
  })
  .createAction({
    type: 'TRIP_ESTIMATE',
    requestEndPoint: '/trips/estimate',
    requestMethod: 'POST',
    permission: 'gateway.trip.estimate',
  })
  .createAction({
    type: 'TRIP_BOOK',
    requestEndPoint: '/trips/:id/book',
    requestMethod: 'POST',
    permission: 'gateway.trip.book',
  })
  .createAction({
    type: 'TRIP_START',
    requestEndPoint: '/trips/start',
    requestMethod: 'POST',
    permission: 'gateway.trip.start',
  })
  .createAction({
    type: 'TRIP_CANCEL',
    requestEndPoint: '/trips/:id/cancel',
    requestMethod: 'POST',
    permission: 'gateway.trip.cancel',
  })
  .createAction({
    type: 'TRIP_END',
    requestEndPoint: '/trips/:id/end_verify',
    requestMethod: 'POST',
    permission: 'gateway.trip.end',
  })
  .createAction({
    type: 'TRIP_END_VALIDATE',
    requestEndPoint: '/trips/:id/end_validate',
    requestMethod: 'POST',
    permission: 'gateway.trip.end_validate',
  })
  .createAction({
    type: 'TRIP_PAUSE',
    requestEndPoint: '/trips/:id/pause',
    requestMethod: 'POST',
    permission: 'gateway.trip.pause',
  })
  .createAction({
    type: 'TRIP_RESUME',
    requestEndPoint: '/trips/:id/resume',
    requestMethod: 'POST',
    permission: 'gateway.trip.resume',
  })
  .createAction({
    type: 'TRIP_REFUND',
    requestEndPoint: '/trips/:id/refund',
    requestMethod: 'POST',
    permission: 'gateway.trip.refund',
  })
  .createResourceAndAction({
    type: 'TRIP_BATTERY_STATE_UPDATES',
    requestEndPoint: '/trips/:id/battery_state_updates',
    transform: data => data.updates,
  })
  .createResourceAndAction({
    type: 'TRIP_LOCATION_UPDATES',
    requestEndPoint: '/trips/:id/location_updates',
    transform: data => data.updates,
  });

export default tripsModule;
