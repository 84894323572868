import { DeliveryPlaceType, LastUpdatedSource } from '#/core-api/enums';

import { bikeIcon } from '@/config/icons';

export function lastUpdatedSource(
  source?: LastUpdatedSource | 'placeholder'
): DisplayProperties {
  switch (source) {
    default:
      return {
        message: `shared__${String(source)}`,
      };
    case LastUpdatedSource.NotSet:
      return {
        message: 'shared__source_not_set',
        class: 'secondary',
      };
    case LastUpdatedSource.BikeGps:
    case LastUpdatedSource.BikeWifi:
    case LastUpdatedSource.Bike:
      return {
        message: 'shared__bike',
        class: 'primary',
        icon: bikeIcon,
      };
    case LastUpdatedSource.AppZoov:
      return {
        message: 'shared__app',
        class: 'accent',
        icon: 'mdi-cellphone',
      };
    case LastUpdatedSource.WebappZoovAdmin:
      return {
        message: 'shared__fifteen_control',
        class: 'primary',
        icon: 'svg:control_logo',
      };
    case LastUpdatedSource.AppZoovMaintenance:
      return {
        message: 'shared__fifteen_maintenance',
        class: 'disabled',
        icon: 'svg:control_logo',
      };
    case LastUpdatedSource.Angels:
      return {
        message: 'shared__angels',
        class: 'angels',
        icon: 'svg:angels_logo',
      };
    case LastUpdatedSource.Discard:
      return {
        message: 'shared__discarded',
        class: 'error',
        icon: 'mdi-undo',
      };
    case 'placeholder':
      return {
        message: '',
        class: 'transparent',
      };
  }
}

export function deliveryPlaceType(type?: DeliveryPlaceType): DisplayProperties {
  switch (type) {
    default:
    case DeliveryPlaceType.Undefined:
      return {
        message: 'place__undefined',
      };
    case DeliveryPlaceType.DeliveryBattery:
      return {
        message: 'place__delivery_battery',
        class: 'primary',
        icon: 'svg:battery_marker',
      };
    case DeliveryPlaceType.DeliveryProduct:
      return {
        message: 'place__delivery_product',
        class: 'primary',
        icon: 'svg:zoov_bike_marker',
      };
    case DeliveryPlaceType.ReturnProduct:
      return {
        message: 'place__return_product',
        class: 'error',
        icon: 'mdi-undo-variant',
      };
  }
}
