import {
  AccessTechnology,
  EntityLockedStatus,
  LockOperationType,
  LockSource,
  LostStatus,
  UnlockOperationType,
  VehicleMaintenanceState,
  AssistLevel,
  AssistState,
  GatewayVehicleFileType,
  PlayType,
  StackCategory,
} from '#/core-api/enums';

import { appConfig } from '@/config';
import {
  VehicleClusteredState,
  VehicleMaintenanceStateAugmented,
  VehicleServiceState,
} from '@/models/vehicle/helpers';
import { defaultEnumDisplayProperties } from '@/lib/utils';

export function vehiclePriority(priority?: number): DisplayProperties & {
  opacity?: number;
  small?: boolean;
} {
  switch (priority) {
    default:
    case 0:
      return {
        message: '',
      };
    case 1:
      return {
        message: 'vehicles__unlocked',
        class: 'error_alt3',
        opacity: 1,
        icon: 'mdi-rhombus',
      };
    case 2:
      return {
        message: 'vehicles__in_search',
        class: 'warning_alt1',
        opacity: 1,
        icon: 'mdi-rhombus',
      };
    case 3:
      return {
        message: 'vehicles__low_battery',
        class: 'warning_alt1',
        opacity: 1,
        icon: 'mdi-rhombus',
      };
    case 4:
    case 4.1:
    case 4.2:
    case 4.3:
      const index = Number(String(priority).split('.')[1]) - 1;
      return {
        message:
          priority === 4
            ? 'vehicles__no_trip_for_a_while'
            : 'vehicles__no_trip_for_' + appConfig.noTripSince[index] + 'h',
        class: 'error_alt1',
        opacity: 0.6 + index * 0.2,
        small: true,
        icon: 'mdi-rhombus',
      };
    case 5:
      return {
        message: 'vehicles__need_warehouse',
        class: 'error',
        opacity: 1,
        small: true,
        icon: 'mdi-rhombus',
      };
    case 6:
      return {
        message: 'vehicles__need_field_maintenance',
        class: 'warning',
        opacity: 1,
        small: true,
        icon: 'mdi-rhombus',
      };
  }
}

export function vehicleLockedStatus(
  lockedStatus?: EntityLockedStatus,
  warn?: boolean
): DisplayProperties {
  switch (lockedStatus) {
    default:
    case EntityLockedStatus.NotSet:
      return {
        message: 'vehicles__locked_status_not_set',
        class: 'secondary',
      };
    case EntityLockedStatus.Locked:
      return {
        message: 'vehicles__locked',
        class: 'success',
        icon: 'mdi-lock',
      };
    case EntityLockedStatus.Unlocked:
      return {
        message: 'vehicles__unlocked',
        class: warn ? 'error' : 'grey',
        icon: warn ? 'svg:lock_open_alert' : 'mdi-lock-open',
      };
    case EntityLockedStatus.SoftUnlocked:
      return {
        message: 'vehicles__soft_unlocked',
        class: warn ? 'error' : 'grey',
        icon: 'mdi-lock-open-minus',
      };
  }
}

export const vehicleMaintenanceStateList = [
  VehicleMaintenanceState.MaintenanceStateInOrder,
  VehicleMaintenanceState.MaintenanceStateInOrderNeedsMaintenance,
  VehicleMaintenanceState.MaintenanceStateOutOfOrderNeedsMaintenance,
  VehicleMaintenanceState.MaintenanceStateOutOfOrderNeedsWarehouse,
  VehicleMaintenanceState.MaintenanceStateInTruckToWarehouse,
  VehicleMaintenanceState.MaintenanceStateInTruckForReallocation,
  VehicleMaintenanceState.MaintenanceStateInWarehouseEntry,
  VehicleMaintenanceState.MaintenanceStateInWarehouse_1,
  VehicleMaintenanceState.MaintenanceStateInWarehouse_2,
  VehicleMaintenanceState.MaintenanceStateInWarehouse_3,
  VehicleMaintenanceState.MaintenanceStateInWarehouse_4,
  VehicleMaintenanceState.MaintenanceStateInWarehousePreExit,
  VehicleMaintenanceState.MaintenanceStateInWarehouseExit,
  VehicleMaintenanceState.MaintenanceStateToRecycle,
  VehicleMaintenanceState.MaintenanceStateRecycled,
  VehicleMaintenanceState.MaintenanceStateToSetup,
  VehicleMaintenanceState.MaintenanceStateToDeploy,
];

export function vehicleMaintenanceState(
  maintenanceState?: VehicleMaintenanceState
): DisplayProperties & {
  chartColor?: string;
} {
  switch (maintenanceState) {
    case VehicleMaintenanceState.MaintenanceStateUnknown:
      return {
        message: 'vehicles__unknown',
        class: 'secondary',
        chartColor: 'secondary',
      };
    case VehicleMaintenanceState.MaintenanceStateInOrder:
      return {
        message: 'vehicles__in_order',
        class: 'primary',
        chartColor: 'primary',
        icon: 'mdi-check-circle',
      };
    case VehicleMaintenanceState.MaintenanceStateInOrderNeedsMaintenance:
      return {
        message: 'vehicles__in_order_needs_maintenance',
        class: 'warning',
        chartColor: 'warning',
        icon: 'mdi-alert-circle',
      };
    case VehicleMaintenanceState.MaintenanceStateOutOfOrderNeedsMaintenance:
      return {
        message: 'vehicles__out_of_order_needs_maintenance',
        class: 'error',
        chartColor: 'warning_alt1_darker',
        icon: 'mdi-alert-circle',
      };
    case VehicleMaintenanceState.MaintenanceStateOutOfOrderNeedsWarehouse:
      return {
        message: 'vehicles__out_of_order_needs_warehouse',
        class: 'error',
        chartColor: 'error',
        icon: 'mdi-home-alert',
      };
    case VehicleMaintenanceState.MaintenanceStateInTruckToWarehouse:
      return {
        message: 'vehicles__in_truck_to_warehouse',
        class: 'other_alt1',
        chartColor: 'other_alt1',
        icon: 'mdi-ambulance',
      };
    case VehicleMaintenanceState.MaintenanceStateInTruckForReallocation:
      return {
        message: 'vehicles__in_truck_for_reallocation',
        class: 'disabled',
        chartColor: 'disabled',
        icon: 'mdi-truck-delivery',
      };
    case VehicleMaintenanceState.MaintenanceStateInWarehouseEntry:
      return {
        message: 'vehicles__in_warehouse_entry_zone',
        class: 'accent',
        chartColor: 'accent_darker2',
        icon: 'svg:home_entry',
      };
    case VehicleMaintenanceState.MaintenanceStateInWarehouse_1:
      return {
        message: 'vehicles__in_warehouse_level_1',
        class: 'accent',
        chartColor: 'accent_darker',
        icon: 'mdi-home-floor-1',
      };
    case VehicleMaintenanceState.MaintenanceStateInWarehouse_2:
      return {
        message: 'vehicles__in_warehouse_level_2',
        class: 'accent',
        chartColor: 'accent',
        icon: 'svg:home_1_plus',
      };
    case VehicleMaintenanceState.MaintenanceStateInWarehouse_3:
      return {
        message: 'vehicles__in_warehouse_level_3',
        class: 'accent_alt1',
        chartColor: 'accent_alt1',
        icon: 'mdi-home-floor-2',
      };
    case VehicleMaintenanceState.MaintenanceStateInWarehouse_4:
      return {
        message: 'vehicles__in_warehouse_level_4',
        class: 'accent_alt1',
        chartColor: 'accent_alt1_lighter2',
        icon: 'mdi-home-floor-3',
      };
    case VehicleMaintenanceState.MaintenanceStateInWarehousePreExit:
      return {
        message: 'vehicles__in_warehouse_pre_exit_zone',
        class: { light: 'other', dark: 'other_lighter' },
        chartColor: 'other_darker',
        icon: 'svg:home_exit',
      };
    case VehicleMaintenanceState.MaintenanceStateInWarehouseExit:
      return {
        message: 'vehicles__in_warehouse_exit_zone',
        class: { light: 'other', dark: 'other_lighter' },
        chartColor: 'other',
        icon: 'svg:home_exit',
      };
    case VehicleMaintenanceState.MaintenanceStateToRecycle:
      return {
        message: 'vehicles__to_recycle',
        class: { light: 'disabled_alt1_darker', dark: 'disabled_alt1' },
        chartColor: 'disabled_alt1',
        icon: 'mdi-recycle',
      };
    case VehicleMaintenanceState.MaintenanceStateRecycled:
      return {
        message: 'vehicles__recycled',
        class: 'success_alt1_darker',
        chartColor: 'success_alt1_darker',
        icon: 'mdi-recycle',
      };
    case VehicleMaintenanceState.MaintenanceStateToSetup:
      return {
        message: 'vehicles__to_setup',
        class: 'secondary_darker',
        chartColor: 'secondary_darker',
        icon: 'mdi-cog',
      };
    case VehicleMaintenanceState.MaintenanceStateToDeploy:
      return {
        message: 'vehicles__to_deploy',
        class: 'secondary_darker',
        chartColor: 'secondary_darker2',
        icon: 'mdi-rocket-launch',
      };
    default:
      return defaultEnumDisplayProperties(maintenanceState);
  }
}

/** @deprecated */
export function vehicleStatus(vehicle: Vehicle): DisplayProperties {
  const {
    is_reserved: isReserved,
    is_rented: isRented,
    vehicle_state: vehicleState,
  } = vehicle;
  const lockStatus = vehicleState?.lock_info?.status;

  switch (true) {
    case isReserved:
      return {
        message: 'vehicles__booked',
        class: 'warning',
      };
    case isRented && lockStatus === EntityLockedStatus.Unlocked:
      return {
        message: 'vehicles__in_trip',
        class: 'success',
        icon: 'mdi-play-circle',
      };
    case isRented && lockStatus === EntityLockedStatus.Locked:
      return {
        message: 'vehicles__in_pause',
        class: 'disabled',
        icon: 'mdi-pause-circle',
      };
    default:
      return {
        message: 'vehicles__available',
        class: 'primary',
      };
  }
}

export function vehicleLostStatus(lostStatus: LostStatus): DisplayProperties {
  switch (lostStatus) {
    case LostStatus.LoststatusNotSet:
      return {
        message: 'vehicles__lost_status_not_set',
        class: 'secondary',
      };
    case LostStatus.Tracked:
      return {
        message: 'vehicles__tracked',
        class: 'primary',
        icon: 'mdi-crosshairs-gps',
      };
    case LostStatus.InSearch: {
      return {
        message: 'vehicles__in_search',
        class: 'warning_alt1',
        icon: 'mdi-eye-outline',
      };
    }
    case LostStatus.Lost:
      return {
        message: 'vehicles__lost',
        class: 'error',
        icon: 'mdi-map-marker-off',
      };
    default:
      return defaultEnumDisplayProperties(lostStatus);
  }
}

export function vehicleLockOperationType(
  operationType: LockOperationType
): DisplayProperties {
  switch (operationType) {
    case LockOperationType.LockOperationUnknown:
      return {
        message: 'vehicles__lock_operation_unknown',
        class: 'secondary',
      };
    case LockOperationType.LockOperationPause:
      return {
        message: 'vehicles__lock_operation_pause',
        class: 'primary',
      };
    case LockOperationType.LockOperationEnd:
      return {
        message: 'vehicles__lock_operation_end',
        class: 'primary',
      };
    case LockOperationType.LockOperationForceEnd:
      return {
        message: 'vehicles__lock_operation_force_end',
        class: 'primary',
      };
    default:
      return defaultEnumDisplayProperties(operationType);
  }
}

export function vehicleUnlockOperationType(
  operationType: UnlockOperationType
): DisplayProperties {
  switch (operationType) {
    case UnlockOperationType.UnlockOperationUnknown:
      return {
        message: 'vehicles__unlock_operation_unknown',
        class: 'secondary',
      };
    case UnlockOperationType.UnlockOperationStart:
      return {
        message: 'vehicles__unlock_operation_start',
        class: 'primary',
      };
    case UnlockOperationType.UnlockOperationResume:
      return {
        message: 'vehicles__unlock_operation_resume',
        class: 'primary',
      };
    case UnlockOperationType.UnlockOperationSoftUnlock:
      return {
        message: 'vehicles__unlock_operation_soft_unlock',
        class: 'primary',
      };
    case UnlockOperationType.UnlockOperationMaintenanceUnlock:
      return {
        message: 'vehicles__unlock_operation_maintenance_unlock',
        class: 'primary',
      };
    default:
      return defaultEnumDisplayProperties(operationType);
  }
}

export function vehicleClusteredState(
  clusteredState: VehicleClusteredState
): DisplayProperties {
  switch (clusteredState) {
    case VehicleClusteredState.Unknown:
      return {
        message: 'vehicles__unknown',
        class: 'secondary',
      };
    case VehicleClusteredState.ToDeploy:
      return {
        message: 'vehicles__to_deploy',
        class: 'secondary_darker',
      };
    case VehicleClusteredState.InService:
      return {
        message: 'vehicles__in_service',
        class: 'primary',
      };
    case VehicleClusteredState.Reallocation:
      return {
        message: 'vehicles__reallocation',
        class: 'disabled',
      };
    case VehicleClusteredState.FieldActionNeeded:
      return {
        message: 'vehicles__field_action_needed',
        class: 'error_darker',
      };
    case VehicleClusteredState.WarehouseMaintenance:
      return {
        message: 'vehicles__warehouse_maintenance',
        class: 'accent',
      };
    case VehicleClusteredState.SignificantMaintenance:
      return {
        message: 'vehicles__significant_maintenance',
        class: 'accent_alt1',
      };
    case VehicleClusteredState.Unusable:
      return {
        message: 'vehicles__unusable',
        class: 'disabled_alt1',
      };
    case VehicleClusteredState.InSearch:
      return {
        message: 'vehicles__in_search',
        class: 'warning_alt1',
      };
    case VehicleClusteredState.Lost:
      return {
        message: 'vehicles__lost',
        class: {
          light: 'disabled_lighter2',
          dark: 'disabled_darker2',
        },
      };
    default:
      return defaultEnumDisplayProperties(clusteredState);
  }
}

export function vehicleServiceState(
  serviceState: VehicleServiceState
): DisplayProperties {
  switch (serviceState) {
    case VehicleServiceState.Unknown:
      return {
        message: 'vehicles__unknown',
        class: 'secondary',
      };
    case VehicleServiceState.Rented:
      return {
        message: 'vehicles__rented',
        class: 'success',
      };
    case VehicleServiceState.Reserved:
      return {
        message: 'vehicles__reserved',
        class: 'warning',
      };
    case VehicleServiceState.AvailableForRent:
      return {
        message: 'vehicles__available_for_rent',
        class: 'primary',
      };
    case VehicleServiceState.LowBattery:
      return {
        message: 'vehicles__low_battery',
        class: 'error_alt1',
      };
    case VehicleServiceState.OutOfOrder:
      return {
        message: 'vehicles__out_of_order',
        class: 'error',
      };
    case VehicleServiceState.Lost:
      return {
        message: 'vehicles__lost',
        class: {
          light: 'disabled_lighter2',
          dark: 'disabled_darker2',
        },
      };
    default:
      return defaultEnumDisplayProperties(serviceState);
  }
}

export function vehicleMaintenanceStateAugmented(
  maintenanceStateAugmented: VehicleMaintenanceStateAugmented
): DisplayProperties & { chartColor?: string } {
  if (maintenanceStateAugmented === VehicleMaintenanceStateAugmented.Lost) {
    return {
      message: 'vehicles__lost',
      class: {
        light: 'disabled_lighter2',
        dark: 'disabled_darker2',
      },
    };
  }
  return vehicleMaintenanceState(
    maintenanceStateAugmented as VehicleMaintenanceState
  );
}

export function vehicleAccessTechnology(
  accessTechnology: AccessTechnology
): DisplayProperties {
  switch (accessTechnology) {
    case AccessTechnology.NotSet:
      return {
        message: 'vehicles__not_set',
      };
    case AccessTechnology._2G:
      return {
        message: 'vehicles__2g',
      };
    case AccessTechnology._4G:
      return {
        message: 'vehicles__4g',
      };
    case AccessTechnology._4GCatM1:
      return {
        message: 'vehicles__4g_cat_m1',
      };
    case AccessTechnology._4GNbIot:
      return {
        message: 'vehicles__4g_nb_iot',
      };
    default:
      return defaultEnumDisplayProperties(accessTechnology);
  }
}

export function vehicleLockedSource(lockSource: LockSource): DisplayProperties {
  switch (lockSource) {
    case LockSource.Unknown:
      return {
        message: 'vehicles__unknown',
      };
    case LockSource.Bluetooth:
      return {
        message: 'vehicles__bluetooth',
      };
    case LockSource.Lte:
      return {
        message: 'vehicles__lte',
      };
    case LockSource.AutolockTimeout:
      return {
        message: 'vehicles__autolock_timeout',
      };
    case LockSource.AutolockStack:
      return {
        message: 'vehicles__autolock_stack',
      };
    case LockSource.RfidBypass:
      return {
        message: 'vehicles__rfid_bypass',
      };
    case LockSource.AutolockStackMaintenance:
      return {
        message: 'vehicles__autolock_stack_maintenance',
      };
    case LockSource.PauseInactivity:
      return {
        message: 'vehicles__pause_inactivity',
      };
    case LockSource.AutolockStackFallback:
      return {
        message: 'vehicles__autolock_stack_fallback',
      };
    case LockSource.AutolockDocked:
      return {
        message: 'vehicles__autolock_docked',
      };
    case LockSource.HeavyLockFailure:
      return {
        message: 'vehicles__heavy_lock_failure',
      };
    case LockSource.Rfid:
      return {
        message: 'vehicles__rfid',
      };
    case LockSource.AutolockNotUnstacked:
      return {
        message: 'vehicles__autolock_not_unstacked',
      };
    case LockSource.Manual:
      return {
        message: 'vehicles__manual',
      };
    default:
      return defaultEnumDisplayProperties(lockSource);
  }
}

export function vehicleFileType(
  fileType: GatewayVehicleFileType
): DisplayProperties {
  switch (fileType) {
    case GatewayVehicleFileType.Unknown:
      return {
        message: 'vehicles__file_type__unknown',
      };
    case GatewayVehicleFileType.Logs:
      return {
        message: 'vehicles__file_type__logs',
      };
    case GatewayVehicleFileType.RawData:
      return {
        message: 'vehicles__file_type__raw_data',
      };
    default:
      return defaultEnumDisplayProperties(fileType);
  }
}

export function vehiclePlayType(playType: PlayType): DisplayProperties {
  switch (playType) {
    case PlayType.None:
      return { message: 'none' };
    case PlayType.Success:
      return { message: 'success' };
    case PlayType.UserError:
      return { message: 'user error' };
    case PlayType.VehicleError:
      return { message: 'vehicle error' };
    case PlayType.Unlock:
      return { message: 'unlock' };
    case PlayType.SoftUnlock:
      return { message: 'soft unlock' };
    case PlayType.Lock:
      return { message: 'lock' };
    case PlayType.Start:
      return { message: 'start' };
    case PlayType.End:
      return { message: 'end' };
    case PlayType.Pause:
      return { message: 'pause' };
    case PlayType.Resume:
      return { message: 'resume' };
    case PlayType.Search:
      return { message: 'search' };
    case PlayType.AreaExit:
      return { message: 'area exit' };
    case PlayType.AreaEntrance:
      return { message: 'area entrance' };
    case PlayType.SpeedLimitDown:
      return { message: 'speed limit down' };
    case PlayType.SpeedLimitUp:
      return { message: 'speed limit up' };
    case PlayType.LowBattery:
      return { message: 'low battery' };
    case PlayType.SearchLight:
      return { message: 'search light' };
    case PlayType.SearchSound:
      return { message: 'search sound' };
    default:
      return defaultEnumDisplayProperties(playType);
  }
}

export function vehicleAssistLevel(
  assistLevel: AssistLevel
): DisplayProperties {
  switch (assistLevel) {
    case AssistLevel.Unknown:
      return {
        message: 'unknown',
      };
    case AssistLevel.Low:
      return {
        message: 'low',
      };
    case AssistLevel.Medium:
      return {
        message: 'medium',
      };
    case AssistLevel.High:
      return {
        message: 'high',
      };
    default:
      return defaultEnumDisplayProperties(assistLevel);
  }
}

export function vehicleAssistState(
  assistState: AssistState
): DisplayProperties {
  switch (assistState) {
    case AssistState.Unknown:
      return {
        message: 'unknown',
      };
    case AssistState.On:
      return {
        message: 'on',
      };
    case AssistState.Off:
      return {
        message: 'off',
      };
    default:
      return defaultEnumDisplayProperties(assistState);
  }
}

export function vehicleStackCategory(
  stackCategory: StackCategory
): DisplayProperties {
  switch (stackCategory) {
    case StackCategory.NotSet:
      return {
        message: 'vehicles__stack_category__not_set',
      };
    case StackCategory.Vehicle:
      return {
        message: 'vehicles__stack_category__vehicle',
      };
    case StackCategory.ElectricStack:
      return {
        message: 'vehicles__stack_category__electric_stack',
      };
    case StackCategory.PassiveStack:
      return {
        message: 'vehicles__stack_category__passive_stack',
      };
    case StackCategory.PassiveMaintenanceStack:
      return {
        message: 'vehicles__stack_category__passive_maintenance_stack',
      };
    default:
      return defaultEnumDisplayProperties(stackCategory);
  }
}
