/* eslint-disable @typescript-eslint/explicit-function-return-type */

/*
 * defines all app routes, which component to be each route view,
 * and associated navigation icon for navigation drawer
 */
// sections
const Login = () => import('@/components/pages/Login.vue');
const Dashboard = () => import('@/components/pages/Dashboard.vue');
const Map = () => import('@/components/pages/Map.vue');
const Bikes = () => import('@/components/pages/Bikes.vue');
const Vehicles = () => import('@/components/pages/Vehicles.vue');
const Issues = () => import('@/components/pages/Issues.vue');
const Stations = () => import('@/components/pages/Stations.vue');
const Fasteners = () => import('@/components/pages/Fasteners.vue');
const PointsOfInterest = () =>
  import('@/components/pages/PointsOfInterest.vue');
const Users = () => import('@/components/pages/Users.vue');
const Roles = () => import('@/components/pages/Roles.vue');
const Bookings = () => import('@/components/pages/Bookings.vue');
const Trips = () => import('@/components/pages/Trips.vue');
const Areas = () => import('@/components/pages/Areas.vue');
const Rentals = () => import('@/components/pages/Rentals.vue');
const Offers = () => import('@/components/pages/Offers.vue');
const OfferPolicies = () => import('@/components/pages/OfferPolicies.vue');
const Scopes = () => import('@/components/pages/Scopes.vue');
const Benefits = () => import('@/components/pages/Benefits.vue');
// 404
const ErrorPage = () => import('@/components/pages/ErrorPage.vue');

import { validateLogin } from '@/router/guards';
import { getLocalPreferences } from '@/lib/helpers/get-local-preferences';
import { appConfig } from '@/config';
import store from '@/store';
import * as icons from '@/config/icons';

import type {
  NavigationGuard,
  RawLocation,
  Route,
  RouteConfig,
} from 'vue-router';

// pass props helper
function routeProps(route: Route): object {
  return route.params;
}

function redirectItem(
  toRoute: Route,
  name: string,
  key: string,
  subsection: string
): RawLocation {
  if (toRoute.params[key]) {
    return `/${name}/${toRoute.params[key]}/${subsection}`;
  } else {
    return `/${toRoute.params.path}/${subsection}`;
  }
}

const storePreviousRoute: NavigationGuard = (_, fromRoute, next) => {
  // do not store when coming from Bike.Issue to avoid looping on navigation
  if (fromRoute.name === 'Bike.Issue') return next();
  store.commit('previousRoute', fromRoute.path);
  return next();
};

// at this time, store preferences are not populated
// so get directly entry in local storage
const localPreferences = getLocalPreferences();

export type RouteName = (typeof routes)[number]['name'];

const routes = [
  // login
  {
    path: '/login',
    name: 'Login',
    components: { login: Login },
    beforeEnter: validateLogin,
  },
  /*
   * sections
   */
  // dashboard
  {
    path: '/dashboard',
    name: 'Dashboard',
    meta: {
      showInNav: true,
      icon: icons.dashboardIcon,
      permission: 'admin.route.dashboard',
    },
    component: Dashboard,
    props: routeProps,
  },
  {
    path: '/dashboard/:tabName(realtime|daily)',
    name: 'Dashboard.Tab',
    meta: {
      permission: 'admin.route.dashboard',
    },
    redirect: '/dashboard',
  },
  // map
  {
    path: '/map',
    name: 'Map',
    component: Map,
    meta: {
      showInNav: true,
      icon: icons.mapIcon,
      permission: 'admin.route.map',
    },
    props: routeProps,
  },
  {
    path: '/map/:modelName(bike|station|warehouse|vehicle|point_of_interest)/:id',
    name: 'Map.Item',
    meta: {
      permission: 'admin.route.map',
    },
    component: Map,
    props: routeProps,
  },
  {
    path: '/map/station/:id/fastener/:fastenerId',
    name: 'Map.Station.Fastener',
    meta: {
      permission: 'admin.route.map',
    },
    component: Map,
    props: route => {
      return {
        modelName: 'station',
        ...routeProps(route),
      };
    },
  },
  {
    path: '/map/station/:id/bike/:nearbyFreefloatingBikeSerialNumber',
    name: 'Map.Station.Bike',
    meta: {
      permission: 'admin.route.map',
    },
    redirect: toRoute =>
      `/map/station/${toRoute.params.id}/vehicle/${toRoute.params.nearbyFreefloatingBikeSerialNumber}`,
  },
  {
    path: '/map/station/:id/vehicle/:nearbyFreefloatingVehicleId',
    name: 'Map.Station.Vehicle',
    meta: {
      permission: 'admin.route.map',
    },
    component: Map,
    props: route => {
      return {
        modelName: 'station',
        ...routeProps(route),
      };
    },
  },
  {
    path: '/map/station/:id/fastener/:fastenerId/bike/:fastenedBikeSerialNumber',
    name: 'Map.Station.Fastener.Bike',
    meta: {
      permission: 'admin.route.map',
    },
    component: Map,
    props: route => {
      return {
        modelName: 'station',
        ...routeProps(route),
      };
    },
  },
  {
    path: '/map/station/:id/fastener/:fastenerId/vehicle/:fastenedVehicleId',
    name: 'Map.Station.Fastener.Vehicle',
    meta: {
      permission: 'admin.route.map',
    },
    component: Map,
    props: route => {
      return {
        modelName: 'station',
        ...routeProps(route),
      };
    },
  },
  {
    path: '/map/lnglat/:coordinates([-0-9_]+,[-0-9_]+)',
    name: 'Map.LngLat',
    meta: {
      permission: 'admin.route.map',
    },
    component: Map,
    props: routeProps,
  },
  // bikes
  {
    path: '/bikes',
    name: 'Bikes',
    component: Bikes,
    meta: {
      showInNav: true,
      icon: icons.bikeIcon,
      permission: 'admin.route.bikes',
      sepBefore: true,
    },
    props: routeProps,
  },
  {
    path: '/bikes/:serial_number',
    name: 'Bike',
    meta: {
      permission: 'admin.route.bike',
    },
    redirect: toRoute =>
      redirectItem(
        toRoute,
        'bikes',
        'serial_number',
        localPreferences.bikesDefaultOpenTab || 'data'
      ),
  },
  {
    path: '/bikes/:serial_number/bike_history',
    name: 'Bike.History',
    component: Bikes,
    props: routeProps,
  },
  {
    path: '/bikes/:serial_number/:subsection(issues|data|bike_info|bookings|trips|history)',
    name: 'Bike.Subsection',
    meta: {
      permission: 'admin.route.bike',
    },
    component: Bikes,
    props: routeProps,
    beforeEnter: storePreviousRoute,
  },
  {
    path: '/bikes/:serial_number/issue/:issue_id',
    name: 'Bike.Issue',
    redirect: toRoute => redirectItem(toRoute, 'issues', 'issue_id', 'data'),
  },
  // vehicles
  {
    path: '/vehicles',
    name: 'Vehicles',
    component: Vehicles,
    meta: {
      showInNav: true,
      icon: icons.vehicleIcon,
      permission: 'admin.route.vehicles',
    },
    props: routeProps,
  },
  {
    path: '/vehicles/:id',
    name: 'Vehicle',
    meta: {
      permission: 'admin.route.vehicle',
    },
    redirect: toRoute => redirectItem(toRoute, 'vehicles', 'id', 'data'),
  },
  {
    path: '/vehicles/:id/:subsection(data|trips|issues|bookings|product|history)',
    name: 'Vehicle.Subsection',
    meta: {
      permission: 'admin.route.vehicle',
    },
    component: Vehicles,
    props: routeProps,
    beforeEnter: storePreviousRoute,
  },
  {
    path: '/vehicles/:id/bike_history',
    name: 'Vehicle.History',
    component: Vehicles,
    props: routeProps,
  },
  // stations
  {
    path: '/stations',
    name: 'Stations',
    component: Stations,
    meta: {
      showInNav: true,
      icon: icons.stationIcon,
      permission: 'admin.route.stations',
    },
    props: routeProps,
  },
  {
    path: '/stations/:id',
    name: 'Station',
    meta: {
      permission: 'admin.route.station',
    },
    redirect: toRoute => redirectItem(toRoute, 'stations', 'id', 'data'),
  },
  {
    path: '/stations/:id/:subsection(issues|data|location|fasteners|urban_furnitures|history)',
    name: 'Station.Subsection',
    meta: {
      permission: 'admin.route.station',
    },
    component: Stations,
    props: routeProps,
    beforeEnter: storePreviousRoute,
  },
  {
    path: '/stations/:id/shape',
    name: 'Station.Shape',
    redirect: '/stations/:id/location',
  },
  {
    path: '/stations/:id/issue/:issue_id',
    name: 'Station.Issue',
    redirect: toRoute => redirectItem(toRoute, 'issues', 'issue_id', 'data'),
  },
  // fasteners
  {
    path: '/fasteners',
    name: 'Fasteners',
    component: Fasteners,
    meta: {
      showInNav: true,
      icon: icons.fastenerIcon,
      permission: 'admin.route.fasteners',
    },
    props: routeProps,
  },
  {
    path: '/fasteners/:id',
    name: 'Fastener',
    meta: {
      permission: 'admin.route.fastener',
    },
    redirect: toRoute => redirectItem(toRoute, 'fasteners', 'id', 'data'),
  },
  {
    path: '/fasteners/:id/:subsection(issues|data|sim|shape|history)',
    name: 'Fastener.Subsection',
    meta: {
      permission: 'admin.route.fastener',
    },
    component: Fasteners,
    props: routeProps,
    beforeEnter: storePreviousRoute,
  },
  {
    path: '/fasteners/:id/issue/:issue_id',
    name: 'Fastener.Issue',
    redirect: toRoute => redirectItem(toRoute, 'issues', 'issue_id', 'data'),
  },
  {
    path: '/bookings/:id',
    name: 'Booking',
    meta: {
      permission: 'admin.route.booking',
    },
    redirect: toRoute => redirectItem(toRoute, 'bookings', 'id', 'data'),
  },
  {
    path: '/bookings/:id/:subsection(data|estimation|estimated_route|history)',
    name: 'Booking.Subsection',
    meta: {
      permission: 'admin.route.booking',
    },
    component: Bookings,
    props: routeProps,
    beforeEnter: storePreviousRoute,
  },
  {
    path: '/users',
    name: 'Users',
    component: Users,
    meta: {
      showInNav: true,
      icon: icons.userIcon,
      permission: 'admin.route.users',
      sepBefore: true,
      areaIndependent: true,
    },
    props: routeProps,
  },
  {
    path: '/users/:id',
    name: 'User',
    meta: {
      permission: 'admin.route.user',
      areaIndependent: true,
    },
    redirect: toRoute => redirectItem(toRoute, 'users', 'id', 'data'),
  },
  {
    path: '/users/:id/:subsection(data|billing|bookings|trips|benefits|rentals|apikeys|history)',
    name: 'User.Subsection',
    meta: {
      permission: 'admin.route.user',
      areaIndependent: true,
    },
    component: Users,
    props: routeProps,
    beforeEnter: storePreviousRoute,
  },
  {
    path: '/users/:id/:deprecated(notes)',
    name: 'User.Deprecated.Redirect',
    meta: {
      permission: 'admin.route.user',
      areaIndependent: true,
    },
    redirect: toRoute => redirectItem(toRoute, 'users', 'id', 'data'),
  },
  {
    path: '/trips',
    name: 'Trips',
    component: Trips,
    meta: {
      showInNav: true,
      icon: icons.tripIcon,
      permission: 'admin.route.trips',
    },
    props: routeProps,
  },
  {
    path: '/trips/:id',
    name: 'Trip',
    meta: {
      permission: 'admin.route.trip',
    },
    redirect: toRoute => redirectItem(toRoute, 'trips', 'id', 'data'),
  },
  {
    path: '/trips/:id/:subsection(data|charts|history|estimation|route)',
    name: 'Trip.Subsection',
    meta: {
      permission: 'admin.route.trip',
    },
    component: Trips,
    props: routeProps,
    beforeEnter: storePreviousRoute,
  },
  {
    path: '/rentals',
    name: 'Rentals',
    component: Rentals,
    meta: {
      showInNav: true,
      icon: icons.rentalIcon,
      permission: 'admin.route.rentals',
      areaIndependent: true,
    },
    props: routeProps,
  },
  {
    path: '/rentals/:id',
    name: 'Rental',
    meta: {
      permission: 'admin.route.rental',
    },
    redirect: toRoute => redirectItem(toRoute, 'rentals', 'id', 'data'),
  },
  {
    path: '/rentals/:id/:subsection(data|history|rentals|trips|rental_limit|bookings)',
    name: 'Rental.Subsection',
    meta: {
      permission: 'admin.route.rental',
      areaIndependent: true,
    },
    component: Rentals,
    props: routeProps,
    beforeEnter: storePreviousRoute,
  },
  {
    path: '/benefits',
    name: 'Benefits',
    component: Benefits,
    meta: {
      showInNav: true,
      icon: icons.benefitIcon,
      permission: 'admin.route.benefits',
      areaIndependent: true,
    },
    props: routeProps,
  },
  {
    path: '/benefits/:id',
    name: 'Benefit',
    meta: {
      permission: 'admin.route.benefit',
      areaIndependent: true,
    },
    redirect: toRoute => redirectItem(toRoute, 'benefits', 'id', 'data'),
  },
  {
    path: '/benefits/:id/:subsection(data|places|history)',
    name: 'Benefit.Subsection',
    meta: {
      permission: 'admin.route.benefit',
      areaIndependent: true,
    },
    component: Benefits,
    props: routeProps,
    beforeEnter: storePreviousRoute,
  },
  {
    path: '/bookings',
    name: 'Bookings',
    component: Bookings,
    meta: {
      showInNav: true,
      icon: icons.bookingIcon,
      permission: 'admin.route.bookings',
    },
    props: routeProps,
  },
  {
    path: '/areas',
    name: 'Areas',
    component: Areas,
    meta: {
      showInNav: true,
      icon: icons.areaIcon,
      permission: 'admin.route.areas',
      areaIndependent: true,
      sepBefore: true,
    },
    props: routeProps,
  },
  {
    path: '/areas/:id',
    name: 'Area',
    meta: {
      permission: 'admin.route.area',
      areaIndependent: true,
    },
    redirect: toRoute => redirectItem(toRoute, 'areas', 'id', 'data'),
  },
  {
    path: '/areas/:id/:subsection(data|shape|benefits|history)',
    name: 'Area.Subsection',
    meta: {
      permission: 'admin.route.area',
      areaIndependent: true,
    },
    component: Areas,
    props: routeProps,
    beforeEnter: storePreviousRoute,
  },
  // scopes
  {
    path: '/scopes',
    name: 'Scopes',
    component: Scopes,
    meta: {
      showInNav: true,
      icon: icons.scopeIcon,
      permission: 'admin.route.scope',
      areaIndependent: true,
    },
    props: routeProps,
  },
  {
    path: '/scopes/:id',
    name: 'Scope',
    props: routeProps,
    redirect: toRoute => redirectItem(toRoute, 'scopes', 'id', 'data'),
  },
  {
    path: '/scopes/:id/:subsection(data)',
    name: 'Scope.Subsection',
    meta: {
      permission: 'admin.route.scope',
    },
    component: Scopes,
    props: routeProps,
    beforeEnter: storePreviousRoute,
  },
  {
    path: '/offers',
    name: 'Offers',
    component: Offers,
    meta: {
      showInNav: true,
      icon: icons.offerIcon,
      permission: 'admin.route.offers',
      areaIndependent: true,
    },
    props: routeProps,
  },
  {
    path: '/offers/:id',
    name: 'Offer',
    meta: {
      permission: 'admin.route.offer',
      areaIndependent: true,
    },
    redirect: toRoute => redirectItem(toRoute, 'offers', 'id', 'data'),
  },
  {
    path: '/offers/:id/:subsection(data|rules|rental_config|benefits|history)',
    name: 'Offer.Subsection',
    meta: {
      permission: 'admin.route.offer',
      areaIndependent: true,
    },
    component: Offers,
    props: routeProps,
    beforeEnter: storePreviousRoute,
  },
  {
    path: '/offer-policies',
    name: 'OfferPolicies',
    component: OfferPolicies,
    meta: {
      showInNav: true,
      icon: icons.offerPolicyIcon,
      permission: 'admin.route.offer_policies',
      areaIndependent: true,
    },
    props: routeProps,
  },
  {
    path: '/offer-policies/:id',
    name: 'OfferPolicy',
    meta: {
      permission: 'admin.route.offer_policy',
      areaIndependent: true,
    },
    redirect: toRoute => redirectItem(toRoute, 'offer-policies', 'id', 'data'),
  },
  {
    path: '/offer-policies/:id/:subsection(data|history)',
    name: 'OfferPolicy.Subsection',
    meta: {
      permission: 'admin.route.offer_policy',
      areaIndependent: true,
    },
    component: OfferPolicies,
    props: routeProps,
    beforeEnter: storePreviousRoute,
  },
  {
    path: '/points-of-interest',
    name: 'PointsOfInterest',
    component: PointsOfInterest,
    meta: {
      showInNav: true,
      icon: icons.pointOfInterestIcon,
      permission: 'admin.route.point_of_interest',
    },
    props: routeProps,
  },
  {
    path: '/points-of-interest/:id',
    name: 'PointOfInterest',
    meta: {
      permission: 'admin.route.point_of_interest',
    },
    redirect: toRoute =>
      redirectItem(toRoute, 'points-of-interest', 'id', 'data'),
  },
  {
    path: '/points-of-interest/:id/:subsection(data|location|history)',
    name: 'PointOfInterest.Subsection',
    meta: {
      permission: 'admin.route.point_of_interest',
    },
    component: PointsOfInterest,
    props: routeProps,
    beforeEnter: storePreviousRoute,
  },
  {
    path: '/issues',
    name: 'Issues',
    component: Issues,
    meta: {
      showInNav: true,
      icon: icons.issueIcon,
      permission: 'admin.route.issues',
      sepBefore: true,
    },
    props: routeProps,
  },
  {
    path: '/issues/:id',
    name: 'Issue',
    props: routeProps,
    redirect: toRoute => redirectItem(toRoute, 'issues', 'id', 'data'),
  },
  {
    path: '/issues/:id/:subsection(data|activity|history)',
    name: 'Issue.Subsection',
    meta: {
      permission: 'admin.route.issue',
    },
    component: Issues,
    props: routeProps,
    beforeEnter: storePreviousRoute,
  },

  {
    path: '/roles',
    name: 'Roles',
    component: Roles,
    meta: {
      showInNav: true,
      icon: icons.roleIcon,
      permission: 'admin.route.roles',
      areaIndependent: true,
    },
    props: routeProps,
  },
  {
    path: '/roles/:name',
    name: 'Role',
    meta: {
      permission: 'admin.route.role',
      areaIndependent: true,
    },
    redirect: toRoute => redirectItem(toRoute, 'roles', 'name', 'data'),
  },
  {
    path: '/roles/:name/:subsection(data|privileges|history)',
    name: 'Role.Subsection',
    meta: {
      permission: 'admin.route.role',
      areaIndependent: true,
    },
    component: Roles,
    props: routeProps,
    beforeEnter: storePreviousRoute,
  },
  // redirects and 4xx
  {
    path: '/:code(4[0-9]{2})',
    name: '4xx Error',
    component: ErrorPage,
    props: true,
  },
  {
    path: '/',
    name: 'Root',
    redirect:
      localPreferences.defaultLandingPage ||
      appConfig.loginDefaultRedirectRoute,
  },
  {
    path: '*',
    name: 'Every other',
    redirect: '/404',
  },
] as const satisfies readonly RouteConfig[];

export default [...routes];
