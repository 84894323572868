/**
 * Create a query for a string field that does not exist
 * @param queryKey - The key of the query
 * @param negation - Whether to negate the query
 * @returns
 */
export default function stringNotExistsQuery(
  queryKey: string,
  negation = false
): MongoQuery {
  const operator = negation ? '$nor' : '$or';
  return {
    // we consider empty strings as 'not existing' field
    [operator]: [
      { [queryKey]: { $exists: 0 } },
      { [queryKey]: '' },
      { [queryKey]: { $size: 0 } },
    ],
  };
}
