import { PointOfInterestType } from '@/models/point-of-interest/enums';
import { defaultEnumDisplayProperties } from '@/lib/utils';

export function pointOfInterestType(
  type?: PointOfInterestType
): DisplayProperties {
  switch (type) {
    case PointOfInterestType.Unknown:
      return {
        message: 'points_of_interest__unknown_type',
        icon: 'mdi-help',
        class: 'secondary',
      };
    case PointOfInterestType.BikeHouse:
      return {
        message: 'points_of_interest__bike_house',
        icon: 'mdi-home',
        class: 'primary',
      };
    case PointOfInterestType.BikeLocker:
      return {
        message: 'points_of_interest__bike_locker',
        icon: 'mdi-lock',
        class: 'primary',
      };
    default:
      return defaultEnumDisplayProperties(type);
  }
}
