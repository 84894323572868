interface UseLegalDocs {
  /**
   * Whether to show terms and conditions popup
   */
  showTermsAndConditions: WritableComputedRef<boolean>;
  /**
   * Whether to show privacy policy popup
   */
  showPrivacyPolicy: WritableComputedRef<boolean>;
}

/**
 * Returns computed properties to manage legal docs visibility
 */
export function useLegalDocs(): UseLegalDocs {
  const router = useRouter();
  const route = useRoute();

  return {
    showTermsAndConditions: computed({
      get() {
        return Boolean(route.query.terms_and_conditions);
      },
      set(value) {
        router.push({
          query: value ? { terms_and_conditions: String(1) } : {},
        });
      },
    }),
    showPrivacyPolicy: computed({
      get() {
        return Boolean(route.query.privacy_policy);
      },
      set(value) {
        router.push({ query: value ? { privacy_policy: String(true) } : {} });
      },
    }),
  };
}
