import ZIcon from '@/components/ui/atoms/ZIcon.vue';

/**
 * Check if context is related to history
 * @param context - The context string
 */
export function isHistory(context?: string): boolean {
  if (!context) return false;
  return /^history/.test(context);
}

/**
 * Permission helper that check whether the user cannot read the given data field
 */
export function cannotReadData(field: string): boolean {
  // battery community
  return (
    /battery_community/.test(field) &&
    !/(state_of_charge|state_of_health|serial_number)$/.test(field)
  );
}

/**
 * Determine an icon and a color for history action based on its name
 * @param key - The history action name
 */
export function historyActionStyle(
  name: 'changed' | 'added' | 'removed' | 'modified' | 'deleted'
): {
  icon: string;
  color: string;
} {
  switch (name) {
    default:
      return { icon: '', color: '' };
    case 'changed':
      return { icon: 'mdi-update', color: 'warning' };
    case 'added':
      return { icon: 'mdi-plus', color: 'success' };
    case 'removed':
      return { icon: 'mdi-minus', color: 'error' };
  }
}

export const rentalLimitIcon: Slot = {
  component: {
    name: ZIcon,
    content: 'mdi-hand-back-left',
    props: {
      small: true,
    },
  },
};
