import { RentalStatus, RentalType } from '#/core-api/enums';

import { defaultEnumDisplayProperties } from '@/lib/utils';

export function rentalStatus(status?: RentalStatus): DisplayProperties {
  switch (status) {
    case RentalStatus.Undefined:
      return {
        message: 'rentals__status_not_set',
        class: 'secondary',
        icon: 'mdi-help-circle',
      };
    case RentalStatus.Active:
      return {
        message: 'rentals__active',
        class: 'primary',
      };
    case RentalStatus.PendingPreAuth:
      return {
        message: 'rentals__pending_pre_auth',
        class: 'warning',
      };
    case RentalStatus.Ended:
      return {
        message: 'rentals__ended',
        class: 'accent',
      };
    case RentalStatus.NeedsPayment:
      return {
        message: 'rentals__needs_payment',
        class: 'warning',
        icon: 'svg:coin_euro_alert',
      };
    case RentalStatus.BillingPendingValidation:
      return {
        message: 'rentals__billing_pending_validation',
        class: 'warning',
        icon: 'mdi-cash-clock',
      };
    case RentalStatus.PendingEnd:
      return {
        message: 'rentals__pending_end',
        class: 'accent',
        icon: 'mdi-clock',
      };
    case RentalStatus.BenefitNeedsPayment:
      return {
        message: 'rentals__benefit_needs_payment',
        class: 'warning',
        icon: 'svg:coin_euro_alert',
      };
    case RentalStatus.BenefitPaused:
      return {
        message: 'rentals__paused',
        class: 'disabled',
        icon: 'mdi-pause-circle',
      };
    case RentalStatus.BillingPendingInformation:
      return {
        message: 'rentals__billing_pending_information',
        class: 'warning',
        icon: 'mdi-progress-question',
      };
    default:
      return defaultEnumDisplayProperties(status);
  }
}

export function rentalType(type?: RentalType): DisplayProperties {
  switch (type) {
    case RentalType.Undefined:
      return {
        message: 'rentals__type_not_set',
        class: 'secondary',
        icon: 'mdi-help',
      };
    case RentalType.Sharing:
      return {
        message: 'rentals__sharing',
        class: 'secondary_darker',
        icon: 'mdi-share-variant',
      };
    case RentalType.Leasing:
      return {
        message: 'rentals__leasing',
        class: { light: 'other', dark: 'other_lighter' },
        icon: 'svg:leasing_contract',
      };
    default:
      return defaultEnumDisplayProperties(type);
  }
}
