<template lang="pug">
ZInputPhoneNumber.ZActionControlInputUint(
  v-show="canShow"
  ref="componentRef"
  v-model="modelValue"
  :style="style"
  :label="label"
  :rules="rules"
  :color="color"
  :disabled="isDisabled"
  :hint="hint"
  :messages="message"
  check-number-validity
  v-bind="additionalProps"
)
</template>

<script setup lang="ts">
import { useActionControl } from './useActionControl';

import ZInputPhoneNumber from '@/components/ui/molecules/ZInputPhoneNumber.vue';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Data = any;

interface ZActionControlInputPhoneNumberProps {
  /**
   * The control object
   */
  control: ActionControl<'phone', keyof Data, Data>;
  /**
   * The parent action 'data' object
   */
  data: Data;
  /**
   * The control’s value
   * @model
   */
  value?: string;
}

const props = withDefaults(defineProps<ZActionControlInputPhoneNumberProps>(), {
  value: undefined,
});

const {
  modelValue,
  canShow,
  style,
  label,
  rules,
  color,
  isDisabled,
  hint,
  message,
  additionalProps,
} = useActionControl(props);

const componentRef = ref<TemplateRef | null>(null);

function focus(): void {
  componentRef.value?.focus?.();
}

function resetValidation(): void {
  componentRef.value?.resetValidation?.();
}

defineExpose({
  /**
   * Focus the input
   */
  focus,
  /**
   * Reset input validation
   */
  resetValidation,
});
</script>
