<template lang="pug">
.ZActionControlSeparator(v-show="canShow")
  .ZActionControlSeparator__divider(v-if="!control.textOnly")
  .ZActionControlSeparator__text(:class="textClasses")
    VIcon(
      v-if="(icon || isHint) && text"
      :color="iconColor"
      small
    ) {{ icon || (isHint && 'mdi-information') }}
    div(
      v-if="text"
      :class="[control.textClass]"
      v-html="text"
    )
</template>

<style lang="stylus">
.ZActionControlSeparator
  width 100%

.ZActionControlSeparator__divider
  background-color: $colors.grey.base
  margin-top 16px
  margin-bottom 8px
  margin-left -16px
  width calc(100% + 2 * 16px)
  height 1px
  opacity 0.2

.ZActionControlSeparator__text
  display flex
  align-items flex-start
  line-height 16px
  margin 8px 0
  line-height 1.25
  white-space pre-line

  .v-icon
    margin-right 4px

  &--hint
    inline-hint-color()

  &--disabled
    opacity 0.65
    pointer-events none
    cursor default
</style>

<script setup lang="ts">
import { useActionControl } from './useActionControl';

import { useI18n } from '@/composables/plugins';
import { useColors } from '@/composables';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Data = any;

interface ZActionControlTextareaProps {
  /**
   * The control object
   */
  control: ActionControlSeparator<Data>;
  /**
   * The parent action 'data' object
   */
  data: Data;
  /**
   * The control’s value
   * @model
   */
  value?: string;
}

const props = withDefaults(defineProps<ZActionControlTextareaProps>(), {
  value: '',
});

const { tin } = useI18n();
const { generate, canShow, isDisabled, color, icon } = useActionControl(props);
const isHint = computed(() => props.control.isHint);
const text = computed(() => tin(generate(props.control.text) ?? ''));

const { themeColor } = useColors();
const iconColor = computed(() => themeColor(props.control.iconColor));

const textClasses = computed(() => ({
  'ZActionControlSeparator__text--hint': isHint.value,
  [color.value + '--text']: color.value,
  'ZActionControlSeparator__text--disabled': isDisabled.value,
}));
</script>
