import {
  FastenerMaintenanceState,
  FastenerStatus,
  PowerSource,
  FastenerType,
} from '#/core-api/enums';

/**
 * Computes the color of the fastener icon based on its status and maintenance state
 * @param status - The status of the fastener
 * @param maintenanceState - The maintenance state of the fastener
 */
export function fastenerIconColor(
  status: FastenerStatus,
  maintenanceState: FastenerMaintenanceState
): string {
  if (status === FastenerStatus.Inactive) {
    return 'disabled';
  }

  switch (maintenanceState) {
    case FastenerMaintenanceState.InField:
      return 'primary';
    case FastenerMaintenanceState.NeedMaintenanceInOrder:
      return 'warning';
    case FastenerMaintenanceState.NeedMaintenanceOutOfOrder:
    case FastenerMaintenanceState.UnderMaintenance:
      return 'error';
    default:
      return 'primary';
  }
}

/**
 * Return the power sources that are relevant for a given fastener type
 * @param type - The fastener type
 */
export function availablePowerSourceByType(type: FastenerType): PowerSource[] {
  switch (type) {
    default:
    case FastenerType.Stack:
      return [
        PowerSource.None,
        PowerSource.Battery,
        PowerSource.Grid,
        PowerSource.Sector,
      ];
    case FastenerType.Virtual:
      [PowerSource.None];
    case FastenerType.Dock:
      return [PowerSource.None, PowerSource.Grid, PowerSource.Sector];
  }
}
