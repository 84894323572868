// main API config

export const endpoint = window.VUE_APP_GATEWAY_URL;
export const refreshDelay = 2 * 60 * 1000;
export const idleTimeout = 10 * 60 * 1000;
export const timeout = 15 * 1000;
export const syncTimeout = 15 * 1000;
export const slowServerTimeout = 7 * 1000;
export const historyDataLimit = 100;
export const timeoutAttempts = 1;
export const refreshTokenRetryNum = 3;
export const refreshTokenRetryDelay = 2000;
export const responseLimit = 10;
export const populateResponseLimit = 10000;
export const issueResponseLimit = 10000;
export const areaResponseLimit = 10000;
export const mapResponseLimit = 10000;
export const stationResponseLimit = 10000;
export const roleResponseLimit = 10000;
export const offerResponseLimit = 10000;
export const virtualFastenersResponseLimit = 100000;
export const allResponseLimit = 1000000;
export const countResponseLimit = 10000;
export const maxSimultaneousRequests = 7;
export const defaultOtpLength = 4;
