import StoreModule from '@/store/core/store-module';

import type { ApiSchema } from '#/core-api';
import type { Get, Post } from '@/store/types/core-api';

declare global {
  /**
   * Partial Fleet Product
   */
  type FleetProduct = Pick<
    ApiSchema['fleet.Product'],
    'deploy_group' | 'environment' | 'sim'
  >;
}

export interface FleetStoreTypes {
  FLEET_PRODUCT: [Get<'/fleet/product/{SerialNumber}', FleetProduct>];
  FLEET_PRODUCT_VERSIONS: [Get<'/fleet/product_versions', void>];
  FLEET_SETUP: [Post<'/fleet/setup', void>];
}

const fleetModule = new StoreModule<FleetStoreTypes>();
fleetModule
  .createResourceAndAction({
    type: 'FLEET_PRODUCT',
    requestEndPoint: '/fleet/product/:serial_number',
    permission: 'gateway.fleet.product',
    requestOptions: () => ({
      params: {
        relations: [
          'DeployGroup.Firmware',
          'DeployGroup.ProductConfig',
          'Environment',
          'Sim',
          'DeployGroup',
        ],
        fields: ['serial_number'],
      },
    }),
  })
  .createResourceAndAction({
    type: 'FLEET_PRODUCT_VERSIONS',
    requestEndPoint: '/fleet/product_versions',
    permission: 'gateway.fleet.product_versions',
  })
  .createAction({
    type: 'FLEET_SETUP',
    requestMethod: 'POST',
    requestEndPoint: '/fleet/setup',
    permission: 'gateway.fleet.setup',
  });

export default fleetModule;
