<template lang="pug">
.ZErrorMessage
  b(v-if="errorCode") {{ `${errorCode} - ` }}
  span(
    v-if="te(errorMessage)"
    v-html="t(errorMessage, errorTranslationArgs)"
  )
  span(v-else) {{ errorMessage }}
</template>

<script setup lang="ts">
import { type RequestError } from '@/composables/useRequest';
import { useI18n } from '@/composables/plugins';

import type AppError from '@/lib/classes/app-error';

export interface ZErrorMessageProps {
  /**
   * The incoming error
   */
  error?: Error | AppError | RequestError | null;
}

const props = withDefaults(defineProps<ZErrorMessageProps>(), {
  error: null,
});

const { t, te } = useI18n();

const errorMessage = computed(() =>
  props.error instanceof Error
    ? props.error.message
    : 'error__data_display_error'
);

const errorCode = computed(() => {
  if (!props.error) return;
  if ('code' in props.error && props.error.code) return props.error.code;
});

const errorTranslationArgs = computed(() => {
  if (!props.error) return;
  if ('translationArgs' in props.error && props.error.translationArgs) {
    return props.error.translationArgs;
  }
});
</script>
