/* eslint-disable import/no-named-as-default */
import Vue from 'vue';
// import vuetify plugin
import Vuetify from 'vuetify/lib';
// import directives
import { Ripple, Resize } from 'vuetify/lib/directives';
import VApp from 'vuetify/lib/components/VApp';
import VAvatar from 'vuetify/lib/components/VAvatar';
import VAlert from 'vuetify/lib/components/VAlert';
import VBadge from 'vuetify/lib/components/VBadge';
import VDivider from 'vuetify/lib/components/VDivider';
import VNavigationDrawer from 'vuetify/lib/components/VNavigationDrawer';
import VFooter from 'vuetify/lib/components/VFooter';
import VList from 'vuetify/lib/components/VList';
import VBtn from 'vuetify/lib/components/VBtn';
import VGrid from 'vuetify/lib/components/VGrid';
import VCard from 'vuetify/lib/components/VCard';
import VMenu from 'vuetify/lib/components/VMenu';
import VToolbar from 'vuetify/lib/components/VToolbar';
import VTooltip from 'vuetify/lib/components/VTooltip';
import VSelect from 'vuetify/lib/components/VSelect';
import VDataTable from 'vuetify/lib/components/VDataTable';
import VPagination from 'vuetify/lib/components/VPagination';
import VDialog from 'vuetify/lib/components/VDialog';
import VBreadcrumbs from 'vuetify/lib/components/VBreadcrumbs';
import VChip from 'vuetify/lib/components/VChip';
import VForm from 'vuetify/lib/components/VForm';
import VTextField from 'vuetify/lib/components/VTextField';
import VTextarea from 'vuetify/lib/components/VTextarea';
import VRadioGroup from 'vuetify/lib/components/VRadioGroup';
import VCheckbox from 'vuetify/lib/components/VCheckbox';
import VSwitch from 'vuetify/lib/components/VSwitch';
import VAutocomplete from 'vuetify/lib/components/VAutocomplete';
import VCombobox from 'vuetify/lib/components/VCombobox';
import VSlider from 'vuetify/lib/components/VSlider';
import VRangeSlider from 'vuetify/lib/components/VRangeSlider';
import VProgressCircular from 'vuetify/lib/components/VProgressCircular';
import VProgressLinear from 'vuetify/lib/components/VProgressLinear';
import VExpansionPanel from 'vuetify/lib/components/VExpansionPanel';
import VSubheader from 'vuetify/lib/components/VSubheader';
import VDatePicker from 'vuetify/lib/components/VDatePicker';
import VTimePicker from 'vuetify/lib/components/VTimePicker';
import VTimeline from 'vuetify/lib/components/VTimeline';
import VSnackbar from 'vuetify/lib/components/VSnackbar';
import VHover from 'vuetify/lib/components/VHover';
import VTabs from 'vuetify/lib/components/VTabs';
import VTabItem from 'vuetify/lib/components/VTabs/VTabItem';
import VStepper from 'vuetify/lib/components/VStepper';
import VInput from 'vuetify/lib/components/VInput';

import theme from '@/themes';

// import our custom icon wrapper instead
import VIcon from '@/components/ui/atoms/ZIcon.vue';

import type { VuetifyObject } from 'vuetify';

interface VuetifyPlugin {
  $vuetify: VuetifyObject;
}

declare module 'vue/types/vue' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface Vue extends VuetifyPlugin {}
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface VueConstructor extends VuetifyPlugin {}
}

// Disable ripple effect globally
VBtn.options.props.ripple.default = false;

//...and plug them in Vue
Vue.use(Vuetify, {
  components: {
    VApp,
    VAvatar,
    VAlert,
    VBadge,
    VDivider,
    VNavigationDrawer,
    VFooter,
    VList,
    VBtn,
    VIcon,
    VGrid,
    VCard,
    VMenu,
    VToolbar,
    VTooltip,
    VSelect,
    VDataTable,
    VPagination,
    VDialog,
    VBreadcrumbs,
    VChip,
    VForm,
    VTextField,
    VTextarea,
    VRadioGroup,
    VCheckbox,
    VSwitch,
    VAutocomplete,
    VCombobox,
    VSlider,
    VRangeSlider,
    VProgressCircular,
    VProgressLinear,
    VExpansionPanel,
    VSubheader,
    VDatePicker,
    VTimePicker,
    VTimeline,
    VSnackbar,
    VHover,
    VTabs,
    VTabItem,
    VStepper,
    VInput,
  },
  directives: { Ripple, Resize },
  // our custom theme.
  // vuetify will generate 'vuetify-theme-stylesheet',
  // a CSS file bloated with dirty !important statements...
  theme: theme,
  // custom icons
  iconfont: 'mdi',
});
