/* eslint-disable @typescript-eslint/no-explicit-any */
import convert from 'convert-units';
import dateFormat from 'dateformat';
import * as Sentry from '@sentry/vue';
import destr from 'destr';

import {
  LostInfoStatus,
  type BenefitStatus,
  TargetType,
  FastenerMaintenanceState,
  FastenerStatus,
  type FastenerAllowedInOrderStatus,
  FastenerType,
  BikeStatus,
  StackProductCategory,
  ServiceableAreaStatus,
  OfferType,
  TripStatus,
  LostStatus,
  VehicleMaintenanceState,
  type SuspiciousReason,
  type BikeStateSource,
  ParkingType,
} from '#/core-api/enums';

import { deepFormatHistoryData } from './helpers.js';
import {
  cannotReadData,
  historyActionStyle,
  isHistory,
  rentalLimitIcon,
} from './utils';

import {
  bikeMaintenanceState,
  bikeStatus,
  bikeLockedStatus,
  bikeOutOfOrder,
  bikePriority,
  bikeLostStatus,
  bikeAccessibility,
  bikeStackProductCategory,
  bikeStacked,
  bikeStackedNextBike,
  bikeDockChargerState,
  bikeDockCategory,
  bikeDocked,
  bikePowerSavingMode,
} from '@/models/bike/mappers/display/bike';
import { appConfig, mdiConfig } from '@/config';
import { omniAlarmType } from '@/models/omni/mappers/display';
import {
  vehicleLockedSource,
  vehicleLockedStatus,
  vehicleMaintenanceState,
  vehiclePriority,
  vehicleStackCategory,
} from '@/models/vehicle/mappers/display';
import {
  bikeStateTripState,
  bikeStateLockState,
  bikeStateSource,
  bikeStateMaintenanceState,
} from '@/models/bike/mappers/display/bike-state';
import { rentalStatus, rentalType } from '@/models/rental/mappers/display';
import {
  issueStatus,
  issueSeverity,
  issueTarget,
  issueSource,
  issueType,
} from '@/models/ticket/issue/mappers/display';
import {
  benefitValidityPresets,
  rulesRefillCyclePresets,
} from '@/models/offer/helpers';
import { roleStatus, roleName } from '@/models/role/mappers/display';
import formatDuration from '@/lib/utils/format-duration';
import {
  fastenerStatus,
  fastenerAvailability,
  fastenerType,
  fastenerPowerSource,
  fastenerMaintenanceState,
  fastenerAllowedInOrderStatus,
} from '@/models/fastener/mappers/display';
import {
  tripType,
  tripRate,
  tripStatus,
  tripLockSource,
  tripProductError,
  tripRisk,
  tripSuspiciousReasons,
} from '@/models/trip/mappers/display';
import {
  userStatus,
  userSex,
  deviceOs,
  optIns,
} from '@/models/user/mappers/display';
import {
  lastUpdatedSource,
  deliveryPlaceType,
} from '@/models/shared/helpers/shared';
import {
  batteryManufacturer,
  batteryStatus,
  batteryType,
} from '@/models/battery/mappers/display';
import { bookingStatus } from '@/models/booking/mappers/display';
import { areaPolicyType, areaStatus } from '@/models/area/mappers/display';
import { areaPolicyText } from '@/models/area/helpers';
import {
  offerType,
  offerObtentionMethodType,
  offerCategory,
  ruleType,
} from '@/models/offer/mappers/display';
import { offerPolicyType } from '@/models/offer-policy/mappers/display';
import { pointOfInterestType } from '@/models/point-of-interest/mappers/display';
import {
  benefitStatus,
  benefitTargetType,
} from '@/models/benefit/mappers/display';
import { batteryFields } from '@/models/fields';
import { formatPaymentMethod } from '@/lib/helpers/format-payment-method';
import countries from '@/lib/helpers/countries';
import {
  capitalize,
  roundNumber,
  objectPickKeys,
  objectRemoveKeys,
  versionString,
  noop,
  isNullish,
  keysOf,
  delayFromNow,
  formatCurrencyNumber,
  getRawSvgIcon,
  obfuscate,
} from '@/lib/utils';
import join from '@/lib/utils/join-props';
import store from '@/store';
import {
  unlockWarning,
  computePriority,
  lastUpdateWarning,
  lostStatusAndSearchText,
  bikeBadge,
  batteryBadge,
} from '@/models/bike/helpers';
import {
  unlockWarning as vehicleUnlockWarning,
  computePriority as computeVehiclePriority,
  vehicleBadge,
} from '@/models/vehicle/helpers';
import { lteSignalBadge } from '@/lib/helpers/lte';
import { issueTargetModelRouteName } from '@/models/ticket/issue/helpers';
import { apiKeyStatus } from '@/models/api-key/mappers/display';
import icons from '@/assets/img/icons';
import { productType } from '@/models/shared/helpers/product';
import AppError from '@/lib/classes/app-error';
import { getProductVersionName } from '@/lib/helpers/product';
import { dateFormats } from '@/config/masks';
import { benefitTargetRouteName } from '@/models/benefit/helpers';
import { fastenerIconColor } from '@/models/fastener/helpers';
import { type ProductVersion } from '@/enums/product';
import { AppErrorCode } from '@/enums/errors';
import { entityType } from '@/models/shared/helpers/entity';
import { getBatteryColor, getBatteryIcon } from '@/models/battery/helpers';
import { simSystemState } from '@/models/shared/mappers/display';
import ZOpenIssuesBadge from '@/models/ticket/issue/components/ZOpenIssuesBadge.vue';
import ZVehicleIcon from '@/models/vehicle/components/ZVehicleIcon.vue';
import {
  fastenerIcon,
  rentalIcon,
  stationIcon,
  tripIcon,
} from '@/config/icons';
import {
  bikeHardwareProjectBoardType,
  bikeHardwareProjectType,
} from '@/models/bike/mappers/display/bike-board-info';
import { gdprProneFields } from '@/lib/helpers/gdpr';

import ZDisplayField from '@/components/domain/model/ZDisplayField';
import ZOpenPageButton from '@/components/ui/molecules/ZOpenPageButton.vue';
import ZIcon from '@/components/ui/atoms/ZIcon.vue';
import ZBadge from '@/components/ui/molecules/ZBadge.vue';
import ZLocateOnMapButton from '@/components/ui/molecules/ZLocateOnMapButton.vue';
import ZOpenButton from '@/components/ui/molecules/ZOpenButton.vue';
import ZDirectionButton from '@/components/ui/molecules/ZDirectionButton.vue';

import type { ApiSchema } from '#/core-api';
import type { PopulatedField } from '@/store/core/populate';
import type { RouteName } from '@/router/routes';
import type { UseI18nReturn, Preferences } from '@/composables/plugins';

const {
  mdiAtv,
  mdiBicycle,
  mdiCellphone,
  mdiClockAlert,
  mdiTab,
  mdiOpenInNew,
} = mdiConfig;

interface FormatDisplayFieldOptions {
  key: string;
  object: any | undefined;
  prefix?: string;
  historyOptions?: HistoryOptions;
}

interface HistoryEntry {
  field: string;
  new_value: string;
  old_value: string;
}

interface CreateFormatFieldsOptions {
  format(data: object, formatOptions: DisplayFieldOptions): DisplayField[];
  userId?: string;
  preferences: Preferences;
  userPrivileges: {
    canOpenUserPage: boolean;
    canReadBikeBatteryCommunityFull: boolean;
    canReadGdprFields: boolean;
  };
  i18n: UseI18nReturn;
  formatOptions: DisplayFieldOptions;
}

interface CreateFormatDisplayFieldsReturn {
  /**
   * Format display field
   * @returns
   */
  formatField: (options: FormatDisplayFieldOptions) => DisplayField[];
}

/**
 * Format field depending on its key
 * @param options - format display field options
 */
export function createFormatFields({
  format,
  userId: loggedInUserId,
  preferences,
  userPrivileges,
  i18n,
  formatOptions,
}: CreateFormatFieldsOptions): CreateFormatDisplayFieldsReturn {
  const { keyMenu, modelName, context, parent, ref, category } =
    formatOptions ?? {};

  let canReadGdprFields = false;

  // reusable i18n content
  const openTabTextHtml =
    `<em>${i18n.t('app__open_tab')}</em>` +
    getRawSvgIcon(mdiTab, '14px', {
      'margin-left': '4px',
      'margin-bottom': '2px',
    });

  const appViewShapeText =
    `<em>${i18n.t('app__view_shape')}&nbsp;&nbsp;` +
    getRawSvgIcon(mdiTab, '14px', {
      'margin-bottom': '2px',
    });

  function formatField(options: FormatDisplayFieldOptions): DisplayField[] {
    const { key, object, prefix, historyOptions } = options;
    if (!object) return [];

    const displayFields: DisplayField[] = [];

    const { raw, before, hideFields } = historyOptions ?? {};

    // check if value exists
    let exists = !isNullish(object[key]);

    /**
     * Format output helper
     */
    function _formatField({
      key,
      value,
      rawValue,
      hideRaw,
      rawOnly,
      unit,
      color,
      borderColor,
      textColor,
      boldText,
      rounded,
      icon,
      iconSize,
      iconSlot,
      opacity,
      link,
      slot,
      wrapSlots,
      slotSpacer,
      prependSlot,
      prefix,
      populated,
      loading,
      error,
      empty,
      hint,
      lastUpdated,
      isTimestamp,
      subTable,
      subTableHeaders,
      extract,
      userId,
      badge,
      keyBadge,
      metaInfo,
      expand,
      lighter,
      warning,
      danger,
      tdProps,
      noHistory,
      wrap,
      forceMultiline,
      disabled,
    }: AddDisplayFieldOptions): DisplayField {
      const outputKey = prefix ? join(prefix, key) : key;
      const isGdprProne = gdprProneFields.some(key => key === outputKey);

      const processedIconSize =
        iconSize === ''
          ? undefined
          : iconSize
            ? iconSize
            : icon || color
              ? 'small'
              : undefined;

      let prevUserIdCheck: string | undefined | null = null;

      const formattedRawValue = JSON.stringify(rawValue, null, 2);

      // check for gdpr-prone permissions, only call it once

      if (
        loggedInUserId &&
        (canReadGdprFields === null || prevUserIdCheck !== userId)
      ) {
        prevUserIdCheck = userId;
        canReadGdprFields =
          (loggedInUserId === userId || userPrivileges.canReadGdprFields) ??
          false;
      }

      let outputValue = value;
      let outputRawValue = formattedRawValue;
      if (isGdprProne && !canReadGdprFields) {
        outputValue = obfuscate(value);
        outputRawValue = '<em>hidden</em>';
        icon = '';
        color = '';
      }

      // Set menu items for matching key
      const menuItems = keyMenu?.[key];

      const displayField: DisplayField = {
        key: outputKey,
        unit,
        ref,
        val: outputValue,
        raw: hideRaw
          ? undefined
          : ![null, undefined].includes(rawValue)
            ? outputRawValue
            : '',
        rawOnly,
        color,
        borderColor,
        textColor,
        boldText,
        rounded,
        icon,
        iconSize: processedIconSize,
        iconSlot,
        opacity: opacity ?? 1,
        link: loading ? null : link,
        slot,
        wrapSlots,
        slotSpacer,
        prependSlot,
        populated,
        lastUpdated,
        isTimestamp,
        subTable,
        subTableHeaders,
        extract,
        loading,
        error,
        empty,
        hint,
        badge,
        keyBadge,
        metaInfo,
        expand,
        lighter,
        warning,
        danger,
        menuItems,
        tdProps,
        noHistory,
        category,
        wrap,
        forceMultiline,
        disabled,
      };

      Object.keys(displayField).forEach(key => {
        const typedKey = key as keyof typeof displayField;
        if (displayField[typedKey] === undefined) delete displayField[typedKey];
      });

      return displayField;
    }

    /**
     * Append display field function which stacks prefixes
     * in order to recursively use 'formatDisplayField' function and to wrap _formatField with `populated` value
     */
    function addDisplayField(settings: AddDisplayFieldOptions): void {
      settings.lastUpdated = !isNullish(settings.lastUpdated)
        ? settings.lastUpdated
        : exists && value.last_updated;
      settings.rawValue =
        context === 'last-updated' ? null : (settings.rawValue ?? value);
      settings.prefix = prefix
        ? prefix + (settings.prefix ? '.' + settings.prefix : '')
        : settings.prefix;
      settings.populated = settings.populated ?? populated;
      settings.loading = settings.loading ?? loading;
      settings.error = settings.error ?? error;
      settings.empty = settings.empty ?? empty;
      // pass current user id for ownership permissions check
      if (modelName === 'user') settings.userId = object.id;

      const newDisplayField = _formatField(settings);
      displayFields.push(newDisplayField);
    }

    /**
     * Recursively deeper field formatter based on ref and prefix
     */
    function deeperFormatField(
      config: Omit<DisplayFieldOptions, 'ref' | 'parent'>,
      data: object
    ): DisplayField[] {
      return format(data, {
        ref: join(ref, prefix, key),
        parent: object,
        ...config,
      });
    }

    /**
     * Transforms an object (with deeper key-values) into an array of outputs
     * @param options - Configuration
     */
    function addObjectDisplayFields(
      options: Pick<
        Partial<AddDisplayFieldOptions>,
        'value' | 'extract' | 'prefix' | 'wrap' | 'lastUpdated'
      > &
        Pick<Partial<DisplayFieldOptions>, 'modelName' | 'context'> = {}
    ): void {
      if (options.value) {
        const deeperFormatting = deeperFormatField(
          {
            modelName: options.modelName ?? modelName,
            context: options.context ?? context,
          },
          // remove last_updated field as it is already handled in a dedicated way
          objectRemoveKeys(options.value, ['last_updated'])
        );

        const objectToArray = deeperFormatting.reduce<any[]>(
          (array, item: any) => {
            // Flatten nested object
            if (Array.isArray(item.val)) {
              const atLeastOneObjectSubItem = item.val.some(
                (subItem: any) => typeof subItem === 'object'
              );

              // Use prefixes if it is not an 'extracted' property
              if (atLeastOneObjectSubItem && !item.extract) {
                item.val.forEach((subItem: any) => {
                  subItem.key = join(item.key, subItem.key);
                  array.push(subItem);
                });
              } else {
                array.push({
                  ...item,
                  val: atLeastOneObjectSubItem ? item.val : item.val.join(', '),
                });
              }
            } else {
              array.push(item);
            }
            return array;
          },
          []
        );

        // output all items in the key place
        addDisplayField({
          key,
          value: exists ? objectToArray.filter(item => !item.extract) : '',
          prefix: options.prefix,
          wrap: options.wrap,
          lastUpdated: options.lastUpdated,
        });
        // however the 'extracted' ones will have a dedicated key-val entry
        // which uses its own lastUpdated (if provided).
        objectToArray
          .filter(item => item.extract)
          .forEach(item => {
            addDisplayField({
              ...item,
              value: item.val,
              lastUpdated: options.lastUpdated ?? item.lastUpdated,
            });
          });
      } else {
        addDisplayField({
          key,
          value: '',
          lastUpdated: options.lastUpdated,
        });
      }
    }

    /**
     * Add child display fields for the given field options, used to recursively format child fields
     * @param options - Format display field options
     */
    function addChildDisplayFields(options: FormatDisplayFieldOptions): void {
      displayFields.push(...formatField(options));
    }

    // check if it is a populated value
    let isArray = Array.isArray(object[key]);
    const itemIfArray = isArray && object[key].length && object[key][0];
    const populated = exists
      ? itemIfArray
        ? itemIfArray.__populated
        : !!object[key].__populated
      : false;

    // display loading populated data
    const loading = exists
      ? itemIfArray
        ? itemIfArray.__loading
        : !!object[key].__loading
      : false;

    // display error populated data
    const error = exists
      ? itemIfArray
        ? itemIfArray.__error
        : object[key].__error
      : null;

    // display empty populated data
    const empty = exists
      ? itemIfArray
        ? itemIfArray.__empty
        : !!object[key].__empty
      : false;

    // set value
    const value = exists
      ? populated && !isArray
        ? object[key].__injected
          ? object[key][object[key].__injected]
          : object[key].__data
        : object[key]
      : null;
    if (isNullish(value)) exists = false;

    // finally deduce if value is an array
    isArray = Array.isArray(value);

    try {
      switch (key) {
        case '__validator__':
          noop();
          break;

        case 'location':
        case 'departure':
        case 'estimated_location':
        case 'last_phone_location':
        case 'warehouse': {
          // departure and arrival entries are not the same in the case of an
          // estimation and bookings vs in the case of a trip
          if (
            key === 'departure' &&
            (modelName === 'estimation' || modelName === 'booking')
          ) {
            addChildDisplayFields({
              key: 'location',
              object: value,
              prefix: key,
            });

            break;
          }

          /* prettier-ignore */
          const design = {
            location: { icon: 'mdi-map-marker-check', color: 'success' },
            estimated_location: { icon: 'mdi-map-marker-radius', color: 'warning' },
            last_phone_location: { icon: 'mdi-cellphone', color: 'accent' },
            warehouse: { icon: 'mdi-home-map-marker', color: 'accent' },
            departure: { icon: 'mdi-human-handsdown', color: 'primary' },
          };
          switch (prefix) {
            case 'pickup':
              design.location.icon = 'mdi-map-marker-up';
              if (modelName === 'estimation' || modelName === 'booking') {
                design.location.color = 'warning';
              }
              break;
            case 'deposit':
              design.location.icon = 'mdi-map-marker-down';
              if (modelName === 'estimation' || modelName === 'booking') {
                design.location.color = 'warning';
              }
              break;
            case 'departure':
              design.location = design.departure;
              break;
          }
          const order = preferences.latLngOrder ? [1, 0] : [0, 1];
          const units = ['lng °', 'lat °'];
          // get the warehouse location or just the value
          const val = exists
            ? key === 'warehouse'
              ? value.location
              : value
            : '';
          const slot = [];
          if (context !== 'map' && exists && val?.coordinates?.length === 2) {
            slot.push({
              component: {
                name: ZLocateOnMapButton,
                props: {
                  coordinates: val.coordinates,
                },
              },
            });
            slot.push({
              component: {
                name: ZDirectionButton,
                props: { coordinates: val.coordinates },
              },
            });
          }
          addDisplayField({
            key,
            value:
              exists && val?.coordinates?.length === 2
                ? `<div>
                      <span>${roundNumber(val.coordinates[order[0]], 4)},</span>
                      <span style="margin-left: 3px;">${roundNumber(
                        val.coordinates[order[1]],
                        4
                      )}</span>
                    </div>`
                : '',
            unit: (units[order[0]] + ', ' + units[order[1]]).replace(
              / /g,
              '&nbsp;'
            ),
            slot,
            color:
              exists && val?.coordinates?.length === 2
                ? design[key as keyof typeof design].color || ''
                : '',
            icon:
              exists && val?.coordinates?.length === 2
                ? design[key as keyof typeof design].icon || ''
                : '',
            iconSize: key === 'warehouse' ? '' : 'small',
            lastUpdated: exists ? val?.updated_at : '',
          });
          break;
        }

        case 'estimated_distance':
        case 'distance':
        case 'max_distance':
        case 'mileage': {
          const targetUnit = value > 1000 ? 'km' : 'm';
          const converted = convert(value).from('m').to(targetUnit); // prettier-ignore
          const floats = context === 'metrics' ? 0 : 1;
          if (context === 'list' || context === 'metrics') {
            addDisplayField({
              key,
              value: exists ? roundNumber(converted, floats) : '',
              unit: targetUnit,
            });
          } else {
            addDisplayField({
              key,
              value: exists
                ? roundNumber(converted, floats) + ' ' + targetUnit
                : '',
            });
          }
          break;
        }

        case 'pickup':
        case 'deposit':
          const isNearbyParking =
            exists &&
            value.parking?.__data?.parking_type === ParkingType.Nearby;

          addDisplayField({
            key,
            value: exists ? value.parking?.__data?.label : '',
            icon: exists ? (isNearbyParking ? stationIcon : fastenerIcon) : '',
            link: exists
              ? isNearbyParking
                ? {
                    name: 'Station',
                    params: { id: value.parking?.__data?.station_id },
                  }
                : {
                    name: 'Fastener',
                    params: { id: value.parking_id },
                  }
              : null,
          });

          if (exists && context !== 'summary') {
            addChildDisplayFields({
              key: 'location',
              object: value,
              prefix: key,
            });
          }

          if (context === 'summary') {
            addDisplayField({
              key,
              value: exists && value.parking_id ? value.parking_id : '',
              icon: fastenerType(value?.entity_type).icon,
              link:
                exists && value.parking_id
                  ? { name: 'Fastener', params: { id: value.parking_id } }
                  : null,
            });
          }

          break;

        case 'destination':
          addDisplayField({
            key,
            value: exists ? value.label.__data : '',
            icon: exists ? stationIcon : '',
            link: exists
              ? {
                  name: 'Station',
                  params: { id: value.station_id },
                }
              : null,
          });

          addChildDisplayFields({
            key: 'station_id',
            object: value,
            prefix: key,
          });

          if (exists && context !== 'summary') {
            addChildDisplayFields({
              key: 'location',
              object: value,
              prefix: key,
            });

            break;
          }

          addDisplayField({
            key,
            value: exists && value?.station_id ? value.station_id : '',
            icon: stationIcon,
            link:
              exists && value?.station_id
                ? {
                    name: 'Station',
                    params: { id: value.station_id },
                  }
                : null,
          });
          break;

        case 'bike_out_of_order':
        case 'out_of_order':
          addDisplayField({
            key,
            value: bikeOutOfOrder(value).message,
            color: bikeOutOfOrder(value).class,
          });
          break;

        case 'search_history':
        case 'lost_info': {
          if (key === 'lost_info') {
            addDisplayField({
              key: 'status',
              prefix: key,
              value: exists ? lostStatusAndSearchText(value, i18n.t) : '',
            });
          }
          const searchHistory = exists
            ? key === 'lost_info'
              ? value.search_history
              : value
            : [];
          const deeperFormatting = (searchHistory ?? []).map((entry: any) => {
            return deeperFormatField(
              {
                context: 'bike_search_history',
              },
              entry
            );
          });
          addDisplayField({
            key: 'search_history',
            prefix: key,
            value: exists ? deeperFormatting : '',
            rawValue: exists ? searchHistory : '',
            lastUpdated:
              exists && searchHistory
                ? {
                    source: 'placeholder',
                    _last_updated_at:
                      searchHistory[searchHistory.length - 1].timestamp,
                  }
                : '',
            subTable: true,
          });
          break;
        }

        case 'bike_lost_status':
          addDisplayField({
            key,
            value: exists ? bikeLostStatus(value).message : '',
          });
          break;

        case 'auto_diags':
        case 'autodiag_flags':
        case 'flags': {
          const flags = exists && key === 'auto_diags' ? value.flags : value;
          if (context === 'summary' || context === 'map') {
            addDisplayField({
              key,
              value:
                exists && Array.isArray(flags)
                  ? flags.map(flag => ({
                      key: flag.type,
                      val: flag.name,
                      category: flag.master,
                      lastUpdated: flag.changed_at,
                      metaInfo: flag.is_persistent
                        ? getRawSvgIcon(
                            mdiClockAlert,
                            '16px',
                            { 'margin-bottom': '1px' },
                            'error--text'
                          )
                        : '',
                    }))
                  : '',
            });
          } else {
            const deeperFormatting = ((exists && flags) || []).map(
              (flag: any) => {
                return deeperFormatField(
                  {
                    context: 'auto_diags',
                    modelName,
                  },
                  flag
                );
              }
            );
            addDisplayField({
              key,
              value: exists ? deeperFormatting : '',
              rawValue: exists ? value : '',
              subTable: true,
              subTableHeaders: [
                { text: 'type', value: 'type', sortable: false },
                { text: 'master', value: 'master', sortable: false },
                { text: 'name', value: 'name', sortable: false },
                { text: 'description', value: 'description', sortable: false },
              ],
            });
          }
          break;
        }

        case 'minimum_bike_battery':
          addDisplayField({
            key,
            value: exists ? value : '',
            unit: '%',
          });
          break;

        case 'has_electrical_link':
          addDisplayField({
            key,
            value: value ? i18n.t('app__yes') : '',
            color: value ? 'success' : '',
            icon: value ? 'mdi-lightning-bolt-circle' : '',
          });
          break;

        case 'bypass_area_policies':
        case 'is_critical_area':
        case 'is_full':
          addDisplayField({
            key,
            value: value ? i18n.t('app__yes') : '',
          });
          break;

        /** @deprecated */
        case 'is_maintenance':
          addDisplayField({
            key,
            value: value ? i18n.t('app__yes') : '',
            icon: value ? 'mdi-cog' : '',
            color: value ? 'warning' : '',
          });
          break;

        case 'is_low_battery':
          addDisplayField({
            key,
            value: value ? i18n.t('app__yes') : '',
            icon: value ? 'mdi-battery-alert-variant-outline' : 'mdi-battery',
            color: value ? 'warning' : 'success',
          });
          break;

        case 'allowed_in_order_status':
          addDisplayField({
            key,
            value: exists
              ? value.map(
                  (status: FastenerAllowedInOrderStatus) =>
                    fastenerAllowedInOrderStatus(status).message
                )
              : '',
            icon: exists
              ? value.map(
                  (status: FastenerAllowedInOrderStatus) =>
                    fastenerAllowedInOrderStatus(status).icon
                )
              : '',
            color: exists
              ? value.map(
                  (status: FastenerAllowedInOrderStatus) =>
                    fastenerAllowedInOrderStatus(status).class
                )
              : '',
          });
          break;

        case 'benefits_status_filter':
          addDisplayField({
            key,
            value: exists
              ? value.map(
                  (status: BenefitStatus) => benefitStatus(status).message
                )
              : '',
            icon: exists
              ? value.map((status: BenefitStatus) => benefitStatus(status).icon)
              : '',
            color: exists
              ? value.map(
                  (status: BenefitStatus) => benefitStatus(status).class
                )
              : '',
          });
          break;

        case 'deployed_in_env':
        case 'deployed_in_environment':
          addDisplayField({
            key,
            value: value ? i18n.t('app__yes') : i18n.t('app__no'),
            color: value ? 'success' : 'error',
            icon: value ? 'mdi-rocket-launch' : 'mdi-close-circle',
          });
          break;

        case 'last_maintenance_state':
        case 'maintenance_state':
          switch (modelName) {
            case 'bike':
              if (context === 'bike_state_extended') {
                addDisplayField({
                  key,
                  value: exists ? bikeStateMaintenanceState(value).message : '',
                  color: exists ? bikeStateMaintenanceState(value).class : '',
                  icon: exists ? bikeStateMaintenanceState(value).icon : '',
                });
                break;
              }

              addDisplayField({
                key,
                value: exists ? bikeMaintenanceState(value).message : '',
                color: exists ? bikeMaintenanceState(value).class : '',
                icon: exists ? bikeMaintenanceState(value).icon : '',
              });
              break;
            case 'vehicle':
              addDisplayField({
                key,
                value: exists ? vehicleMaintenanceState(value).message : '',
                color: exists ? vehicleMaintenanceState(value).class : '',
                icon: exists ? vehicleMaintenanceState(value).icon : '',
              });
              break;
            case 'station':
              addDisplayField({
                key,
                value: exists ? fastenerMaintenanceState(value).message : '',
                color: exists ? fastenerMaintenanceState(value).class : '',
                icon: exists ? fastenerMaintenanceState(value).icon : '',
              });
              break;
            case 'fastener':
              addDisplayField({
                key,
                value: exists ? fastenerMaintenanceState(value).message : '',
                color: exists ? fastenerMaintenanceState(value).class : '',
                icon: exists ? fastenerMaintenanceState(value).icon : '',
              });
              break;
          }
          break;

        case 'status':
          switch (modelName) {
            case 'bike':
              if (
                context === 'battery' ||
                ref === 'battery_community' ||
                ref === 'battery_backup' ||
                ref === 'battery_user'
              ) {
                addDisplayField({
                  key,
                  value: exists ? batteryStatus(value).message : '',
                });
              } else if (ref === 'lock_info') {
                addDisplayField({
                  key,
                  value: exists ? bikeLockedStatus(value).message : '',
                  icon: exists ? bikeLockedStatus(value).icon : '',
                  color: exists ? bikeLockedStatus(value).class : '',
                });
              } else if (ref === 'lost_info') {
                addDisplayField({
                  key,
                  value: exists
                    ? lostStatusAndSearchText({ status: value }, i18n.t)
                    : '',
                });
              } else {
                addDisplayField({
                  key,
                  value: exists ? bikeStatus(value).message : '',
                  color: exists ? bikeStatus(value).class : '',
                  icon: exists ? bikeStatus(value).icon || '' : '',
                });
              }
              break;
            case 'issue':
              addDisplayField({
                key,
                value: exists ? issueStatus(value).message : '',
                color: exists ? issueStatus(value).class || 'black' : '',
                icon: exists ? issueStatus(value).icon : '',
              });
              break;
            case 'fastener': {
              addDisplayField({
                key,
                value: fastenerStatus(value).message,
                color: fastenerStatus(value).class,
              });
              break;
            }
            case 'trip':
              addDisplayField({
                key,
                value: exists
                  ? object.errors && value === TripStatus.Ended
                    ? i18n.t('trips__ended') +
                      ' (' +
                      object.errors
                        .map((event: any) => event?.code ?? 'force')
                        .join(',') +
                      ')'
                    : tripStatus(value).message
                  : '',
                color: exists
                  ? (object.errors || object.out_of_order_declared_at) &&
                    value === TripStatus.Ended
                    ? 'error'
                    : tripStatus(value).class
                  : '',
                icon: exists
                  ? object.errors && value === TripStatus.Ended
                    ? 'mdi-alert-circle'
                    : object.out_of_order_declared_at &&
                        value === TripStatus.Ended
                      ? 'svg:wrench_circle'
                      : tripStatus(value).icon
                  : '',
              });
              break;
            case 'user':
              const ongoingTripId = object.ongoing_trip?.__data;
              const ongoingTripStartedAt =
                object.ongoing_trip_started_at?.__data;
              const usedVehicle = object.used_vehicle?.__data;

              addDisplayField({
                key,
                value: exists ? userStatus(value).message : '',
                color: exists ? userStatus(value).class : '',
                icon: exists ? userStatus(value).icon : '',
                lastUpdated:
                  context === 'summary' ? ongoingTripStartedAt : null,
                slotSpacer: true,
                slot: ([] as Slot[])
                  .concat(
                    context === 'summary' && usedVehicle
                      ? {
                          component: {
                            name: ZDisplayField,
                            class: 'pl-1',
                            props: {
                              item: {
                                key: 'used_vehicle',
                                val: usedVehicle,
                                link: { name: 'Vehicle' },
                              },
                            },
                          },
                        }
                      : []
                  )
                  .concat(
                    context === 'summary' && ongoingTripId
                      ? {
                          component: {
                            name: ZOpenButton,
                            class: 'pl-1',
                            props: {
                              icon: tripIcon,
                              small: true,
                              link: {
                                name: 'Trip',
                                params: { id: object.ongoing_trip },
                              },
                              tooltip: i18n.t('data__view_trip'),
                            },
                          },
                        }
                      : []
                  ),
              });
              break;
            case 'booking':
              addDisplayField({
                key,
                value: exists ? bookingStatus(value).message : '',
                color: exists ? bookingStatus(value).class : '',
                icon: exists ? bookingStatus(value).icon : '',
              });
              break;
            case 'role':
              addDisplayField({
                key,
                value: roleStatus(value).message,
                color: roleStatus(value).class,
                icon: roleStatus(value).icon,
              });
              break;
            case 'api_key':
              addDisplayField({
                key,
                value: apiKeyStatus(value).message,
                color: apiKeyStatus(value).class,
              });
              break;
            case 'battery':
              addDisplayField({
                key,
                value: batteryStatus(value).message,
              });
              break;
            case 'station':
              addDisplayField({
                key,
                value: fastenerStatus(value).message,
                color: fastenerStatus(value).class,
                icon: fastenerStatus(value).icon,
              });
              break;
            case 'rental':
              addDisplayField({
                key,
                value: rentalStatus(value).message,
                color: rentalStatus(value).class,
                icon: rentalStatus(value).icon,
              });
              break;
            case 'benefit':
              addDisplayField({
                key,
                value: benefitStatus(value).message,
                color: benefitStatus(value).class,
                icon: benefitStatus(value).icon,
              });
              break;
            case 'area':
              addDisplayField({
                key,
                value: areaStatus(value).message,
                color: areaStatus(value).class,
                icon: areaStatus(value).icon,
              });
              break;
            case 'vehicle':
              if (prefix === 'vehicle_state.lock_info') {
                const warnLockInfo = vehicleUnlockWarning(
                  value ?? {},
                  object.is_rented,
                  value?.updated_at ?? '',
                  object?.soft_unlock_timeout?.__data ?? 0
                );
                addDisplayField({
                  key,
                  value: exists ? vehicleLockedStatus(value).message : '',
                  color: exists
                    ? vehicleLockedStatus(value, warnLockInfo).class
                    : '',
                  icon: exists
                    ? vehicleLockedStatus(value, warnLockInfo).icon
                    : '',
                  lastUpdated: value?.updated_at ?? '',
                });
              }
              break;
            default:
              addDisplayField({
                key,
                value: value,
              });
          }
          break;

        case 'type':
          if (context === 'auto_diags') {
            addDisplayField({
              key,
              value: exists ? value : '',
            });
            break;
          }
          switch (modelName) {
            case 'fastener':
              addDisplayField({
                key,
                value: i18n.t(fastenerType(value).message),
                icon: fastenerType(value).icon,
              });
              break;
            case 'trip':
              addDisplayField({
                key,
                value: exists ? tripType(value).message : '',
                color: exists ? tripType(value).class : '',
                icon: exists ? tripType(value).icon : '',
              });
              break;
            case 'offer':
            case 'benefit':
              addDisplayField({
                key,
                value: offerType(value).message,
                color: offerType(value).class,
                icon: offerType(value).icon,
              });
              break;
            case 'offer_policy':
              addDisplayField({
                key,
                value: offerPolicyType(value).message,
                color: offerPolicyType(value).class,
                icon: offerPolicyType(value).icon,
              });
              break;
            case 'point_of_interest':
              addDisplayField({
                key,
                value: pointOfInterestType(value).message,
                color: pointOfInterestType(value).class,
                icon: pointOfInterestType(value).icon,
              });
              break;
            case 'rental':
              addDisplayField({
                key,
                value: rentalType(value).message,
                color: rentalType(value).class,
                icon: rentalType(value).icon,
              });
              break;
            case 'rule':
              addDisplayField({
                key,
                value: ruleType(value).message,
                color: ruleType(value).class,
                icon: ruleType(value).icon,
              });
              break;
            case 'bike_hardware_project_board':
              addDisplayField({
                key,
                value: exists
                  ? bikeHardwareProjectBoardType(value).message
                  : '',
              });
              break;
            case 'battery':
              addDisplayField({
                key,
                value: exists ? batteryType(value).message : '',
              });
              break;
            case 'issue':
              addDisplayField({
                key,
                value: issueType(value).message,
                color: issueType(value).class,
                icon: issueType(value).icon,
              });
              break;
            case 'place':
              addDisplayField({
                key,
                value: exists ? deliveryPlaceType(value).message : '',
                color: exists ? deliveryPlaceType(value).class : '',
                icon: exists ? deliveryPlaceType(value).icon : '',
              });
              break;
            default:
              addDisplayField({
                key,
                value: exists ? value : '',
              });
              break;
          }
          break;

        case 'offers':
          addDisplayField({
            key,
            value: exists ? value : '',
            link: exists ? { name: 'Offer' } : null,
          });
          break;

        case 'soft_unlock':
          addDisplayField({
            key,
            value: value ? i18n.t('app__yes') : '',
          });
          if (modelName === 'trip' && context === 'list') {
            addDisplayField({
              key: '__classes',
              value: value ? ['downplay'] : [],
            });
          }
          break;

        case 'rate':
          addDisplayField({
            key,
            value: exists ? tripRate(value).message : '',
            color: '', // exists ? tripType(value).class : ''
          });
          break;

        case 'lock_source':
          addDisplayField({
            key,
            value: exists ? tripLockSource(value).message : '',
          });
          break;
        case 'lock_sources':
          addDisplayField({
            key,
            value: exists
              ? value.map((source: BikeStateSource) =>
                  i18n.t(tripLockSource(source).message)
                )
              : '',
          });
          break;

        case 'min_soc_start_delta':
          addDisplayField({
            key,
            value: exists ? value : '',
            unit: '%',
            icon: getBatteryIcon(value, false),
            hint: i18n.t('areas__min_soc_start_delta_hint', {
              baseMinSoc: appConfig.baseMinSoc,
            }) as string,
          });
          break;

        case 'state_of_charge_estimated':
        case 'soc_estimated':
        case 'state_of_charge_from_battery':
        case 'soc_from_battery':
        case 'state_of_charge_from_bike': {
          if (exists) {
            addDisplayField({
              key,
              value: exists ? value : '',
              unit: '%',
              color: getBatteryColor(value),
              icon: getBatteryIcon(value),
              iconSize: '',
              lighter: /estimated/.test(key) || /battery/.test(key),
            });
          }
          break;
        }
        case 'state_of_charge_operator':
        case 'state_of_charge':
          const fromBike = object.__fromBike ?? object.state_of_charge_vehicle;
          const update = object.__update;
          const source = update?.lastUpdated?.source;
          const category = object.__category;
          const values = [];
          if (exists) {
            values.push({
              val: value,
              color: getBatteryColor(value),
              icon: getBatteryIcon(value),
            });
          }
          if (![null, undefined, ''].includes(fromBike)) {
            const sourceRawIcon = (() => {
              switch (source) {
                case 1:
                  return getRawSvgIcon(mdiBicycle, '16px', {
                    'margin-bottom': '1px',
                  });
                case 2:
                  return getRawSvgIcon(mdiCellphone, '16px', {
                    'margin-bottom': '1px',
                  });
                case 3:
                  return icons.control_logo;
                default:
                  if (key === 'state_of_charge_operator') {
                    return getRawSvgIcon(mdiAtv, '16px', {
                      'margin-bottom': '1px',
                    });
                  }
              }
            })();
            values.push({
              val: fromBike,
              color: getBatteryColor(fromBike),
              icon: getBatteryIcon(fromBike),
              lastUpdated: update?.lastUpdated,
              warning: update?.warning,
              metaInfo: sourceRawIcon,
            });
          }
          if (exists) {
            if (
              context === 'map' ||
              context === 'summary' ||
              (context !== 'battery' && context !== 'history-deep-formatting')
            ) {
              addDisplayField({
                key,
                value: values,
                unit: '%',
                iconSize: '',
                slotSpacer: true,
                slot: category
                  ? [
                      {
                        component: {
                          name: ZBadge,
                          props: {
                            text: 'cat. ' + category,
                            color: 'auto',
                            relative: true,
                          },
                        },
                      },
                    ]
                  : null,
              });
            } else {
              addDisplayField({
                key,
                value: values[0].val,
                color: values[0].color,
                icon: values[0].icon,
                unit: '%',
                iconSize: '',
              });
            }
          }
          break;

        case 'parking_id':
          addDisplayField({
            key,
            value: exists ? value : '',
            link: {
              name: context === 'map' ? 'Map.Item' : 'Fastener',
              params: { modelName: 'fastener' },
            },
          });
          break;

        case 'station_id':
          addDisplayField({
            key,
            value: exists ? value : '',
            link: {
              name: context === 'map' ? 'Map.Item' : 'Station',
              params: { modelName: 'station' },
            },
          });
          break;

        case 'fastener_id':
          addDisplayField({
            key,
            value: exists ? value : '',
            link: { name: 'Fastener' },
          });
          break;

        case 'station':
          addDisplayField({
            key,
            value: exists ? value : '',
            link: {
              name: context === 'map' ? 'Map.Item' : 'Station',
              params: { modelName: 'station', id: object.station_id },
            },
          });
          break;

        case 'stacked':
          addDisplayField({
            key,
            value: exists ? bikeStacked(value).message : '',
          });
          break;

        case 'stacked_nb':
          addDisplayField({
            key,
            value: exists ? bikeStackedNextBike(value).message : '',
          });
          break;

        case 'charger_state':
          addDisplayField({
            key,
            value: exists ? bikeDockChargerState(value).message : '',
          });
          break;

        case 'docked':
          switch (modelName) {
            case 'bike':
              addDisplayField({
                key,
                value: exists ? bikeDocked(value).message : '',
              });
            case 'vehicle':
              addDisplayField({
                key,
                value: exists && value ? i18n.t('app__yes') : i18n.t('app__no'),
              });
          }
          break;

        case 'dock_category':
          addDisplayField({
            key,
            value: exists ? bikeDockCategory(value).message : '',
          });
          break;

        case 'urban_furnitures': {
          addDisplayField({
            key,
            value: exists ? openTabTextHtml : '',
            link: exists
              ? {
                  name: 'Station.Subsection',
                  params: {
                    id: object.id,
                    subsection: 'urban_furnitures',
                  },
                }
              : null,
          });
          break;
        }

        case 'first_of_stack_category':
          addDisplayField({
            key,
            value: exists ? vehicleStackCategory(value).message : '',
          });
          break;
        case 'first_of_stack_product_category':
        case 'previous_product_category':
          addDisplayField({
            key,
            value: exists ? bikeStackProductCategory(value).message : '',
          });
          break;

        case 'previous_product_sn':
        case 'first_of_stack_sn':
          const productCategory =
            key === 'previous_product_sn'
              ? object.previous_product_category
              : object.first_of_stack_product_category;

          const isBike = productCategory === StackProductCategory.Bike;
          const isFastener = [
            StackProductCategory.ElectricStation,
            StackProductCategory.PassiveMaintenanceStation,
            StackProductCategory.PassiveStation,
          ].includes(productCategory);

          const link: Link | null = exists
            ? isBike
              ? {
                  name: 'Vehicle',
                  params: {
                    id: value,
                  },
                }
              : isFastener && parent.fastener_id
                ? {
                    name: 'Fastener',
                    params: {
                      id: parent.fastener_id,
                    },
                  }
                : null
            : null;

          addDisplayField({
            key,
            value: exists ? value : '',
            link,
          });
          break;

        case 'booking_id':
          addDisplayField({
            key,
            value: exists ? value : '',
            link: { name: 'Booking' },
          });
          break;

        case 'estimation_id':
          addDisplayField({
            key,
            value: exists ? value : '',
            link: exists
              ? {
                  name: (capitalize(modelName ?? '') +
                    '.Subsection') as RouteName,
                  params: {
                    id: object.id,
                    subsection: 'estimation',
                  },
                }
              : null,
          });
          break;

        case 'bill':
          switch (modelName) {
            case 'rental':
              if (exists) {
                if (context !== 'list') {
                  addChildDisplayFields({
                    key: 'payments',
                    object: {
                      payments: (value.payments ?? []).concat({
                        name: 'remaining',
                        ...value.remaining,
                      }),
                    },
                    prefix: key,
                  });
                  addChildDisplayFields({
                    key: 'expenses',
                    object: {
                      expenses: (value.expenses ?? []).concat({
                        name: 'total',
                        ...value.total,
                      }),
                    },
                    prefix: key,
                  });
                } else {
                  addDisplayField({
                    key: 'total',
                    prefix: key,
                    value: value.total
                      ? formatCurrencyNumber(
                          i18n.locale,
                          value.total.amount,
                          value.total.currency
                        )
                      : '',
                  });
                }
              }
              break;
            case 'trip':
              if (context !== 'list') {
                if (exists) {
                  addChildDisplayFields({
                    key: 'expenses',
                    object: {
                      expenses: (value.expenses ?? []).concat({
                        name: 'total',
                        ...value.total,
                      }),
                    },
                    prefix: key,
                  });
                }
                addDisplayField({
                  key: 'pricing',
                  prefix: key,
                  value: exists ? value.pricing?.name : '',
                  link: exists
                    ? { name: 'Benefit', params: { id: value.pricing?.id } }
                    : null,
                });
              } else {
                addDisplayField({
                  key: 'total',
                  prefix: key,
                  value:
                    exists && value.total
                      ? formatCurrencyNumber(
                          i18n.locale,
                          value.total.amount,
                          value.total.currency
                        )
                      : '',
                });
              }

              addDisplayField({
                key: 'discounts',
                prefix: key,
                value: exists
                  ? (value.discounts ?? []).map(
                      ({ name, code, id }: ApiSchema['payment.Benefit']) => ({
                        val: name + (code ? ` (${code})` : ''),
                        link: { name: 'Benefit', params: { id } },
                      })
                    )
                  : '',
              });
              if (context !== 'summary') {
                addDisplayField({
                  key: 'pricing.name',
                  prefix: key,
                  value: exists ? value.pricing?.name : '',
                  link:
                    exists && value.pricing?.id
                      ? {
                          name: 'Benefit',
                          params: { id: value.pricing?.id },
                        }
                      : null,
                });
              }
              break;
          }
          break;

        case 'payments':
        case 'expenses':
          const deeperFormatting = ((exists && value) || []).map(
            (payment: ApiSchema['payment.Price']) => {
              payment.name = payment.name ?? '';
              return deeperFormatField(
                {
                  modelName: 'rule',
                },
                payment
              );
            }
          );

          addDisplayField({
            key,
            value: exists ? deeperFormatting : '',
            rawValue: exists ? value : '',
            subTable: true,
            subTableHeaders: [
              { text: 'name', value: 'name', sortable: false },
              { text: 'amount', value: 'amount', sortable: false },
              { text: 'currency', value: 'currency', sortable: false },
            ],
          });
          break;

        case 'amount':
          addDisplayField({
            key,
            value: exists ? (value / 100).toFixed(2) : '',
          });
          break;

        case 'benefit':
          addDisplayField({
            key,
            value: exists ? value : '',
            link: exists
              ? { name: 'Benefit', params: { id: object.benefit_id } }
              : null,
          });
          break;

        case 'tags': {
          addDisplayField({
            key,
            value: modelName ? value : '',
          });
          break;
        }

        case 'city':
          addDisplayField({
            key,
            value: exists ? value[0].toUpperCase() + value.substring(1) : '',
          });
          break;

        case 'bikes':
          if (modelName === 'warehouse') {
            addDisplayField({
              key,
              value: exists
                ? value.map((val: ApiSchema['bike.Bike']) => val.serial_number)
                : '',
              color: exists
                ? value.map(
                    (val: ApiSchema['bike.Bike']) =>
                      bikeMaintenanceState(val.maintenance_state).class
                  )
                : '',
              icon: exists
                ? value.map(
                    (val: ApiSchema['bike.Bike']) =>
                      bikeMaintenanceState(val.maintenance_state).icon
                  )
                : '',
              link: { name: 'Map.Item', params: { modelName: 'vehicle' } },
            });
          } else {
            addDisplayField({
              key,
              value: exists ? value : '',
            });
          }
          break;

        case 'vehicles':
          if (modelName === 'warehouse') {
            addDisplayField({
              key,
              value: exists
                ? value.map((val: ApiSchema['entity.Vehicle']) => val.id)
                : '',
              color: exists
                ? value.map(
                    (val: ApiSchema['entity.Vehicle']) =>
                      vehicleMaintenanceState(val.maintenance_state).class
                  )
                : '',
              icon: exists
                ? value.map(
                    (val: ApiSchema['entity.Vehicle']) =>
                      vehicleMaintenanceState(val.maintenance_state).icon
                  )
                : '',
              link: { name: 'Map.Item', params: { modelName: 'vehicle' } },
            });
          } else {
            addDisplayField({
              key,
              value: exists ? value : '',
            });
          }
          break;

        case 'used_bike':
          addDisplayField({
            key,
            value: exists ? value : '',
            link: { name: 'Vehicle' },
          });
          break;

        case 'used_vehicle':
          addDisplayField({
            key,
            value: exists ? value : '',
            link: { name: 'Vehicle' },
          });
          break;

        case 'nb_booked_bikes':
          const stationId = object.id;
          addDisplayField({
            key,
            value: exists ? value : 0,
            slot:
              exists && value > 0
                ? [
                    {
                      component: {
                        name: ZOpenPageButton,
                        props: {
                          to: {
                            name: 'Vehicles',
                          },
                          icon: rentalIcon,
                          text: i18n.t('app__open_bikes'),
                        },
                        listeners: {
                          click: () => {
                            const filters = [
                              {
                                key: 'station_id',
                                type: String,
                                value: stationId,
                              },
                              {
                                key: 'status',
                                type: Number,
                                value: BikeStatus.Booked,
                              },
                            ];

                            store.commit('sectionState', {
                              name: 'Vehicles',
                              key: 'filters',
                              value: filters,
                            });

                            store.commit('sectionState', {
                              name: 'Vehicles',
                              key: 'filtersOpen',
                              value: true,
                            });
                          },
                        },
                      },
                    },
                  ]
                : [],
          });
          break;

        case 'is_reserved':
          addDisplayField({
            key,
            value: exists && value ? i18n.t('app__yes') : i18n.t('app__no'),
            color: value ? 'warning' : '',
          });
          break;

        case 'leased':
        case 'is_rented':
          addDisplayField({
            key,
            value: exists && value ? i18n.t('app__yes') : i18n.t('app__no'),
            color: exists && key === 'is_rented' && value ? 'success' : '',
            slot:
              exists && value && context !== 'list'
                ? [
                    {
                      component: {
                        name: ZOpenPageButton,
                        props: {
                          to: {
                            name: 'Rentals',
                          },
                          icon: rentalIcon,
                          text: i18n.t('app__open_bike_rentals'),
                        },
                        listeners: {
                          click: () => {
                            const filters = [
                              {
                                key: 'rented_vehicle_ids',
                                type: Array,
                                negation: false,
                                exactMatch: true,
                                value: [
                                  key === 'leased'
                                    ? String(object.serial_number)
                                    : object.id,
                                ],
                              },
                            ];

                            store.commit('sectionState', {
                              name: 'Rentals',
                              key: 'filters',
                              value: filters,
                            });

                            store.commit('sectionState', {
                              name: 'Rentals',
                              key: 'filtersOpen',
                              value: true,
                            });
                          },
                        },
                      },
                    },
                  ]
                : [],
          });

          break;

        case 'id':
          if (isHistory(context)) {
            addDisplayField({
              key,
              value: exists ? value : '',
            });
            break;
          }
          if (modelName === 'vehicle') {
            const vehicle = object as Vehicle;
            const openIssuesCount = vehicle.open_issues?.length;
            const priority = computeVehiclePriority(vehicle);
            const lostStatus = vehicle.lost_info?.status;
            const slot = [];
            const prependSlot = [];
            if (
              exists &&
              context !== 'map' &&
              context !== 'list' &&
              context !== 'data'
            ) {
              prependSlot.push({
                component: {
                  name: ZOpenIssuesBadge,
                  props: {
                    color: 'grey',
                    count: openIssuesCount,
                  },
                },
              });
              if (priority && lostStatus !== LostStatus.InSearch) {
                slot.push({
                  component: {
                    name: ZIcon,
                    content: vehiclePriority(priority).icon,
                    props: {
                      small: true,
                      color: vehiclePriority(priority).class,
                    },
                  },
                });
              }
            }
            addDisplayField({
              key,
              value: exists ? value : '',
              prependSlot,
              slot,
              badge:
                exists && context === 'map'
                  ? vehicleBadge(vehicle, i18n.t)
                  : null,
            });
          } else {
            addDisplayField({
              key:
                modelName === 'station' && context === 'map'
                  ? 'station id'
                  : key,
              value: exists ? value : '',
            });
          }
          if (modelName === 'trip' && context === 'list') {
            addDisplayField({
              key: '__classes',
              value: object.soft_unlock ? ['downplay'] : [],
            });
          }
          if (modelName === 'area' && context === 'list') {
            addDisplayField({
              key: '__classes',
              value:
                object.status === ServiceableAreaStatus.Deleted
                  ? ['downplay']
                  : [],
            });
          }
          break;

        case 'serial_number':
          if (context === 'battery' || isHistory(context)) {
            addDisplayField({
              key,
              value: exists ? value : '',
            });
          } else {
            const priority = computePriority(object);
            const lostStatus = object.lost_info?.status;
            const openIssuesCount = object.open_issues?.length;
            const slot = [];
            const prependSlot = [];
            if (
              exists &&
              context !== 'map' &&
              context !== 'leased-bike' &&
              context !== 'data'
            ) {
              prependSlot.push({
                component: {
                  name: ZOpenIssuesBadge,
                  props: {
                    color: 'grey',
                    count: openIssuesCount,
                  },
                },
              });
              if (priority && lostStatus !== LostInfoStatus.InSearch) {
                slot.push({
                  component: {
                    name: ZIcon,
                    content: bikePriority(priority).icon,
                    props: {
                      small: true,
                      color: bikePriority(priority).class,
                    },
                  },
                });
              }
            }
            addDisplayField({
              key,
              value: exists ? value : '',
              slot,
              prependSlot,
              link: context === 'leased-bike' ? { name: 'Vehicle' } : null,
              badge:
                exists && context === 'map' ? bikeBadge(object, i18n.t) : null,
            });
          }
          break;

        case 'bike_serial_number':
          addDisplayField({
            key,
            value: exists ? value : '',
            link: { name: 'Vehicle' },
          });
          break;

        case 'vehicle_id':
          addDisplayField({
            key,
            value: exists ? value : '',
            link: { name: 'Vehicle' },
          });
          break;

        case 'bike_name':
          addDisplayField({
            key,
            value: exists ? '<i>' + value + '</i>' : '',
          });
          break;

        case 'bike':
          if (exists && value) {
            const recapBike = objectPickKeys(value, [
              'serial_number',
              'battery_community',
              'maintenance_state',
              'lwm2m_info',
              'status',
              'lock_info',
            ]);
            const formattedData = deeperFormatField(
              {
                modelName: 'bike',
                context: 'leased-bike',
              },
              recapBike
            );
            const coordinates = value.location.coordinates;
            addDisplayField({
              key,
              value: Object.entries(formattedData).map(
                ([key, val]: [string, any]) => ({
                  Property: { key, val: val.key },
                  Value: val,
                })
              ),
              subTable: true,
              slot: coordinates
                ? [
                    {
                      component: {
                        name: ZLocateOnMapButton,
                        props: {
                          coordinates,
                        },
                      },
                    },
                    {
                      component: {
                        name: ZDirectionButton,
                        props: { coordinates },
                      },
                    },
                  ]
                : [],
            });
          } else {
            addDisplayField({ key, value: '' });
          }
          break;

        case 'subscriptions': {
          addDisplayField({
            key,
            value: Array.isArray(value)
              ? (value as unknown as NonNullable<User['subscriptions']>).map(
                  ({ __data: benefit }) => {
                    return {
                      val: benefit?.name,
                      icon: offerType(benefit?.type).icon,
                      color: offerType(benefit?.type).class,
                      link: { name: 'Benefit', params: { id: benefit?.id } },
                      metaInfo: i18n.t(benefitStatus(benefit?.status).message),
                    };
                  }
                )
              : '',
            forceMultiline: context === 'summary',
          });
          break;
        }

        case 'places':
          addDisplayField({
            key,
            value: exists
              ? '<em>open places tab&nbsp;&nbsp;' +
                getRawSvgIcon(mdiTab, '14px', {
                  'margin-bottom': '2px',
                }) +
                '</em>'
              : '',
            link:
              exists && modelName
                ? {
                    name: `${capitalize(modelName)}.Subsection` as RouteName,
                    params: {
                      id: String(object.id),
                      subsection: 'places',
                    },
                  }
                : null,
          });
          break;

        case 'current_booking': {
          const bookedAt = object.current_booking_booked_at?.__data;
          const bookedBy = object.booked_by?.__data;
          addDisplayField({
            key,
            value: bookedAt
              ? [
                  {
                    key: 'booked_at',
                    val: dateFormat(bookedAt, dateFormats.dateTime),
                  },
                ]
              : '',
            link: exists ? { name: 'Booking', params: { id: value } } : null,
            slot:
              bookedBy && userPrivileges.canOpenUserPage
                ? [
                    {
                      component: {
                        name: ZOpenButton,
                        props: {
                          icon: 'svg:account_open',
                          link: { name: 'User', params: { id: bookedBy } },
                          tooltip: i18n.t('data__view_user'),
                        },
                      },
                    },
                  ]
                : null,
          });
          break;
        }

        case 'ongoing_trip': {
          const tripStartedAt = object.ongoing_trip_started_at?.__data;
          const usedBy = object.used_by?.__data;
          if (tripStartedAt) {
            const formattedTripStartedAt = dateFormat(
              tripStartedAt,
              dateFormats.dateTime
            );
            addDisplayField({
              key,
              value:
                context !== 'list'
                  ? formattedTripStartedAt
                  : [
                      {
                        key: 'started_at',
                        val: formattedTripStartedAt,
                      },
                    ],
              link: exists ? { name: 'Trip', params: { id: value } } : null,
              lastUpdated: tripStartedAt,
              slot:
                usedBy && userPrivileges.canOpenUserPage
                  ? [
                      {
                        component: {
                          name: ZOpenButton,
                          props: {
                            icon: 'svg:account_open',
                            link: { name: 'User', params: { id: usedBy } },
                            tooltip: i18n.t('data__view_user'),
                          },
                        },
                      },
                    ]
                  : null,
            });
          } else {
            addDisplayField({ key, value: '' });
          }
          break;
        }

        case 'resulting_trip': {
          const resultingTripStatus = object.resulting_trip_status;
          addDisplayField({
            key,
            value: exists ? value : '',
            link: exists ? { name: 'Trip' } : null,
            color:
              exists && resultingTripStatus
                ? tripStatus(resultingTripStatus.__data).class
                : '',
            icon:
              exists && resultingTripStatus
                ? tripStatus(resultingTripStatus.__data).icon
                : '',
          });
          break;
        }

        case 'last_maintenance_state_updater':
          const lastUpdated = exists
            ? object.last_maintenance_state_update
            : null;
          const date = lastUpdated
            ? dateFormat(lastUpdated, dateFormats.dateTime)
            : '';
          const mapOrSummary = context === 'map' || context === 'summary';
          addDisplayField({
            key: 'last_maintenance_state_updater',
            value: exists
              ? mapOrSummary
                ? [{ key: value, val: date }]
                : value
              : '',
            lastUpdated:
              exists && mapOrSummary
                ? object.last_maintenance_state_update
                : null,
            extract: mapOrSummary,
          });
          break;

        case 'last_ended_trip_id': {
          addDisplayField({
            key,
            value: exists ? value : '',
            link: exists ? { name: 'Trip' } : null,
          });
          const tripEndedAt = object.last_ended_trip_time;
          const formattedTripEndedAt = dateFormat(
            tripEndedAt,
            dateFormats.dateTime
          );
          addDisplayField({
            key,
            value: exists ? formattedTripEndedAt : '',
            link: exists ? { name: 'Trip', params: { id: value } } : null,
            lastUpdated: tripEndedAt,
            extract: true,
            populated: true,
          });
          break;
        }

        case 'favorite_places': {
          const iconMap: Record<number, { icon: string; color: string }> = {
            1: { icon: 'svg:fds__house', color: 'success' },
            2: { icon: 'svg:fds__briefcase', color: 'primary' },
            3: { icon: 'svg:fds__bookmark', color: 'warning' },
          };
          addDisplayField({
            key,
            value: exists
              ? value.map(
                  ({
                    address_full: addressFull,
                  }: ApiSchema['user.User_Place']) => addressFull
                )
              : '',
            icon: exists
              ? value.map(({ icon }: ApiSchema['user.User_Place']) =>
                  icon ? iconMap[icon].icon : ''
                )
              : '',
            color: exists
              ? value.map(({ icon }: ApiSchema['user.User_Place']) =>
                  icon ? iconMap[icon].color : ''
                )
              : '',
          });
          break;
        }

        // TODO: display nothing to avoid data pollution, remove when bike history no-longer uses it
        case 'bike_location_updates':
          break;

        // routes
        case 'estimated_route':
        case 'route':
          if (modelName === 'accesses') {
            addDisplayField({
              key,
              value: exists ? value : '',
            });
            break;
          }

          if (isHistory(context)) {
            switch (key) {
              case 'estimated_route':
              case 'route':
                addDisplayField({
                  key,
                  value: exists
                    ? '</em>' + i18n.t('app__view_raw') + '</em>'
                    : '',
                });
                break;
            }
          } else {
            addDisplayField({
              key,
              value: exists
                ? '<em>view in route tab&nbsp;&nbsp;' +
                  getRawSvgIcon(mdiTab, '14px', {
                    'margin-bottom': '2px',
                  }) +
                  '</em>'
                : '',
              link: exists
                ? {
                    name:
                      context === 'bookings'
                        ? 'Booking.Subsection'
                        : 'Trip.Subsection',
                    params: {
                      id:
                        modelName === 'estimation'
                          ? { current: true }
                          : String(object.id),
                      subsection:
                        context === 'bookings' ? 'estimated-route' : 'route',
                    },
                  }
                : null,
            });
            if (exists && value.cycling1 && value.cycling1.distance) {
              addChildDisplayFields({
                key: 'distance',
                object: value.cycling1,
                prefix: 'route.cycling1',
              });
            }
          }
          break;

        case 'place':
          addDisplayField({
            key,
            value: exists ? value.address_full : '',
          });
          break;

        // dates & times
        case '_created':
        case 'created':
        case 'attached_at':
        case 'booked_at':
        case 'started_at':
        case 'canceled_at':
        case 'ended_at':
        case 'expired_at':
        case 'expires_at':
        case 'last_received':
        case 'last_ended_trip_time':
        case 'last_maintenance_state_update':
        case 'last_contact':
        case 'last_ble_contact':
        case 'last_register':
        case 'last_update':
        case 'starting_at':
        case 'ending_at':
        case 'timestamp':
        case 'last_updated_at':
        case '_last_updated_at':
        case '_last_received':
        case '_updated':
        case 'next_rules_refill':
        case 'out_of_order_declared_at':
        case 'last_packet':
        case 'last_use':
        case 'created_at':
        case 'updated_at':
        case 'available_from':
        case 'available_until':
        case 'active_from':
        case 'active_until':
        case 'last_validated':
        case 'geolocation_info_cleared_at':
        case 'last_activity':
        case 'rated_at':
        case 'pause_until':
          // _created or timestamp is handled elsewhere for history data
          if (
            (key === '_created' || key === 'timestamp') &&
            isHistory(context)
          ) {
            return [];
          }
          addDisplayField({
            key,
            value: exists ? dateFormat(value, dateFormats.dateTime) : '',
            isTimestamp: true,
          });
          break;

        // Timestamp in ms
        case 'sent_at_timestamp':
          addDisplayField({
            key,
            value: exists ? dateFormat(value, dateFormats.dateTime) : '',
            isTimestamp: true,
          });
          break;

        // Timestamp in seconds
        case 'last_start':
        case 'last_end':
        case 'trip_timestamp':
        case 'lock_timestamp':
        case 'maintenance_timestamp':
        case 'last_change_timestamp':
        case 'manufacturing_date':
          addDisplayField({
            key,
            value:
              exists && value
                ? dateFormat(value * 1000, dateFormats.dateTime)
                : '',
            isTimestamp: true,
          });
          break;

        case '_last_updated':
        case 'last_updated':
          switch (modelName) {
            case 'issue':
              addDisplayField({
                key,
                value: exists ? dateFormat(value, dateFormats.dateTime) : '',
                isTimestamp: true,
              });
              break;
            // handle `last_updated` generic structure
            default: {
              let date, source;
              if (typeof value === 'string') {
                date = value;
              } else {
                date = value?._last_updated_at;
                source = value?.source;
              }
              const existsNotDefault =
                exists && date !== '0001-01-01T00:00:00Z';
              addDisplayField({
                key,
                value: existsNotDefault
                  ? dateFormat(date, dateFormats.dateTime)
                  : '',
                color:
                  existsNotDefault && source
                    ? lastUpdatedSource(source).class
                    : '',
                icon:
                  existsNotDefault && source
                    ? lastUpdatedSource(source).icon
                    : '',
                isTimestamp: true,
              });
              break;
            }
          }
          break;

        case 'last_phone_contact': {
          addDisplayField({
            key,
            value: exists ? dateFormat(value, dateFormats.dateTime) : '',
            color: exists ? 'accent' : '',
            icon: exists ? 'mdi-cellphone' : '',
          });
          break;
        }
        case 'last_thief': {
          addDisplayField({
            key,
            value: exists ? dateFormat(value, dateFormats.dateTime) : '',
            warning: exists ? delayFromNow(value) < 60 * 1000 : false,
          });
          break;
        }

        case 'roles':
          addDisplayField({
            key,
            value: exists ? value : '',
            color: exists
              ? value.map((name: string) => roleName(name).class)
              : '',
            icon: exists
              ? value.map((name: string) => roleName(name).icon)
              : '',
            link: exists ? { name: 'Role' } : null,
          });
          break;

        case 'scopes':
        case 'scope_ids':
          addDisplayField({
            key,
            value,
            link: exists ? { name: 'Scope' } : null,
          });
          break;

        case 'required_role':
          addDisplayField({
            key,
            value: exists ? value : '',
            color: exists && value ? roleName(value).class : '',
            icon: exists && value ? roleName(value).icon : '',
          });
          break;

        case 'sex':
          addDisplayField({
            key,
            value: userSex(value).message,
          });
          break;

        case 'address': {
          switch (modelName) {
            case 'point_of_interest':
              addDisplayField({
                key,
                value: value?.join(', ') ?? '',
              });
              break;
            case 'user':
            default:
              addDisplayField({
                key,
                value: value?.postal_address?.address_full ?? '',
              });
              break;
          }
          break;
        }

        case 'birthdate':
          addDisplayField({
            key,
            value: exists ? dateFormat(value, dateFormats.date) : '',
          });
          break;

        case 'price':
          if (
            context === 'plan' ||
            modelName === 'rule' ||
            modelName === 'offer' ||
            modelName === 'benefit'
          ) {
            addDisplayField({
              key,
              value: exists
                ? formatCurrencyNumber(
                    i18n.locale,
                    value.amount,
                    value.currency
                  )
                : '',
            });
          } else {
            addDisplayField({
              key,
              value: exists ? formatCurrencyNumber(i18n.locale, value) : '',
            });
          }
          break;

        case 'pre_auth_amount':
        case 'threshold_amount':
        case 'wallet':
          if (modelName === 'rental_limit') {
            const isThresholdAmountReached = value?.current >= value?.limit;
            const textClass = `${
              isThresholdAmountReached ? 'error' : 'success'
            }--text`;

            const thresholdAmount = formatCurrencyNumber(
              i18n.locale,
              value?.current,
              value?.currency ?? object.__currency
            );

            const thresholdLimit = formatCurrencyNumber(
              i18n.locale,
              value?.limit,
              value?.currency ?? object.__currency
            );

            addDisplayField({
              key,
              value: exists
                ? `<span class="${textClass}">${thresholdAmount}</span> / ${thresholdLimit}`
                : '',
              slot: isThresholdAmountReached ? [rentalLimitIcon] : [],
            });
            break;
          }

          addDisplayField({
            key,
            value: exists
              ? formatCurrencyNumber(
                  i18n.locale,
                  value,
                  object.__currency ?? object.currency
                )
              : '',
          });
          break;

        case 'trips_limit':
          if (modelName === 'rental_limit') {
            const isTripsLimitReached = value?.current >= value?.limit;
            const textClass = `${
              isTripsLimitReached ? 'error' : 'success'
            }--text`;

            addDisplayField({
              key,
              value: `<span class="${textClass}">${
                value?.current ?? 0
              }</span> / ${value?.limit ?? 0}`,
              slot: isTripsLimitReached ? [rentalLimitIcon] : [],
            });
          }
          break;

        case 'rental_duration':
          if (!value) break;

          if (value?.isInfiniteDuration) {
            addDisplayField({
              key,
              value: `<span class="success--text">${i18n.t(
                'rental_limit__infinite_duration'
              )}</span>`,
            });
            break;
          }

          const isDurationLimitReached = value?.current >= value?.limit;

          const textClass = `${
            isDurationLimitReached ? 'error' : 'success'
          }--text`;

          const currentRentalDuration = formatDuration(
            Math.min(value?.current, value?.limit) / 1000,
            {
              rounded: isDurationLimitReached,
              roundedUp: isDurationLimitReached,
            }
          ).text;

          const limitRentalDuration = formatDuration(value?.limit / 1000, {
            rounded: true,
            roundedUp: true,
          }).text;

          addDisplayField({
            key,
            value: `<span class="${textClass}">${currentRentalDuration}</span> / ${limitRentalDuration}`,
            slot: isDurationLimitReached ? [rentalLimitIcon] : [],
          });
          break;

        case 'payment_methods': {
          const defaultPaymentMethod = object.default_payment_method;
          addDisplayField({
            key,
            value: exists
              ? value.map(
                  (
                    paymentMethod: ApiSchema['payment.PaymentMethod'],
                    index: number
                  ) =>
                    formatPaymentMethod(
                      paymentMethod,
                      index,
                      defaultPaymentMethod,
                      canReadGdprFields
                    )
                )
              : '',
            forceMultiline: true,
          });
          break;
        }

        case 'default_payment_method':
          if (context !== 'billing') {
            addDisplayField({
              key,
              value: exists ? value : '',
              rawOnly: true,
            });
          }
          break;

        // durations
        case 'beginning':
        case 'ending':
        case 'estimated_duration':
        case 'minimum_trip_duration':
        case 'suspicious_duration':
        case 'max_duration':
        case 'duration': {
          const convertedValue = exists
            ? modelName === 'rule'
              ? value * 60
              : modelName === 'duracron' ||
                  modelName === 'rental_config' ||
                  modelName === 'area'
                ? value / 10 ** 9
                : value
            : '';
          if (context === 'metrics') {
            addDisplayField({
              key,
              value: exists
                ? formatDuration(convertedValue, { unbreakable: true }).text
                : '',
            });
          } else {
            addDisplayField({
              key,
              value: exists
                ? formatDuration(convertedValue, { abv: true }).text
                : '',
            });
          }
          break;
        }

        case 'carbon_footprint': {
          const targetUnit = context === 'metrics' ? 'kg' : 'g';
          const converted = convert(value).from('g').to(targetUnit); // prettier-ignore
          const floats = context === 'metrics' ? 0 : 1;
          addDisplayField({
            key,
            value: exists ? roundNumber(converted, floats) : '',
            unit: context === 'metrics' ? 'kg' : 'g.&nbsp;CO2',
          });
          break;
        }
        case 'calories_burned':
          addDisplayField({
            key,
            value: exists ? roundNumber(value, 1) : '',
            unit: 'cal',
          });
          break;

        case 'user':
        case 'billing_user':
          if (context === 'bike_search_history') {
            addDisplayField({
              key,
              value: exists
                ? value.__injected
                  ? value[value.__injected]
                  : value
                : '',
            });
          } else {
            addDisplayField({
              key,
              value:
                object[key]?.__data ||
                object[key]?.__originalData ||
                object[`${key}_id`] ||
                '',
              link: {
                name: 'User',
                params: {
                  id:
                    object[key]?.__originalData ||
                    value ||
                    // fallback to user_id or billing_user_id if no data
                    object[key + '_id'],
                },
              }, // link based on __originalData
            });
          }
          break;

        case 'user_id':
        case 'billing_user_id':
        case 'updater':
        case 'creator':
          addDisplayField({
            key,
            value: exists ? value : '',
            link: {
              name: 'User',
              params: { id: object?.[key]?.__originalData || value }, // link based on __originalData if populated
            },
          });
          break;

        case 'referred_by':
          addDisplayField({
            key,
            value: exists ? value : '',
            link: exists
              ? {
                  name: 'User',
                  params: { id: object[key].__originalData || value }, // link base on __originalData if populated
                }
              : null,
          });
          break;

        case 'booked_by':
        case 'updated_by':
        case 'created_by':
        case 'used_by':
          addDisplayField({
            key,
            value: exists ? value : '', // pass __data only
            link: { name: 'User' }, // link base on __data
          });
          break;

        case 'open_issues':
          if (context === 'map') {
            addDisplayField({
              key,
              color:
                exists && isArray
                  ? value.map(
                      (entry: PopulatedField) =>
                        issueSeverity(entry.__data.severity).class
                    )
                  : '',
              icon:
                exists && isArray
                  ? value.map(
                      (entry: PopulatedField) =>
                        issueSeverity(entry.__data.severity).icon
                    )
                  : '',
              value:
                exists && isArray
                  ? value.map((entry: PopulatedField) => {
                      entry.__linkParams = {
                        ...(modelName === 'bike'
                          ? { serial_number: String(object.serial_number) }
                          : { id: object.id }),
                        issue_id: entry.__data.id,
                      };
                      entry.__data = entry.__data.label;
                      return entry;
                    })
                  : '',
              link: exists
                ? { name: `${capitalize(modelName ?? '')}.Issue` as RouteName }
                : null,
              forceMultiline: true,
              populated: true,
            });
          } else {
            addDisplayField({
              key,
              value: exists ? value.length : '',
              populated: true,
            });
          }
          break;

        case 'priority':
          switch (modelName) {
            case 'vehicle': {
              const priority = computeVehiclePriority(object);
              addDisplayField({
                key,
                value: vehiclePriority(priority).message,
                color: vehiclePriority(priority).class,
                icon: vehiclePriority(priority).icon,
                opacity: vehiclePriority(priority).opacity,
                populated: true,
              });
              break;
            }

            case 'bike':
              const priority = computePriority(object);
              addDisplayField({
                key,
                value: bikePriority(priority).message,
                color: bikePriority(priority).class,
                icon: bikePriority(priority).icon,
                opacity: bikePriority(priority).opacity,
                populated: true,
              });
              break;
          }

        case 'errors':
          switch (modelName) {
            case 'trip':
              addDisplayField({
                key,
                value: exists
                  ? context === 'list'
                    ? value.map(
                        (error: ApiSchema['rental.Error']) =>
                          (error.code
                            ? error.code
                            : `(<em>${i18n.t('app__no_error_code')}</em>)`) +
                          ' – ' +
                          (error.message
                            ? error.message
                            : `(<em>${i18n.t('app__no_error_message')}</em>)`)
                      )
                    : value.length
                  : '',
                color: exists
                  ? context === 'list'
                    ? value.map(() => 'error')
                    : ''
                  : '',
                icon: exists
                  ? context === 'list'
                    ? value.map(() => 'mdi-alert-circle')
                    : ''
                  : '',
              });
              break;
          }
          break;

        case 'is_persistent':
          addDisplayField({
            key,
            value: value,
            icon: value ? 'mdi-clock-alert' : '',
            color: value ? 'error' : '',
          });
          break;

        case 'target_model':
          addDisplayField({
            key,
            value: issueTarget(value).message,
            color: issueTarget(value).class,
            icon: issueTarget(value).icon,
          });
          break;

        case 'software_version': {
          let badge: Badge | null = null;
          const loading =
            object.target_firmware && object.target_firmware.__loading;
          const targetFwVersion =
            object.target_firmware && object.target_firmware.__data;
          if (exists && targetFwVersion) {
            const versionStr = versionString(value);
            const targetVersionStr = versionString(targetFwVersion);
            const outdated = versionStr !== targetVersionStr;
            badge = outdated
              ? {
                  text: `update to ${targetVersionStr}`,
                  color: 'error',
                  relative: true,
                }
              : null;
          }
          addDisplayField({
            key,
            value: exists ? versionString(value) : '',
            badge,
            loading,
          });
          break;
        }

        case 'firmware_version':
        case 'target_firmware':
          addDisplayField({
            key,
            value: exists ? versionString(value) : '',
          });
          break;

        case 'target_config_crc':
        case 'product_config_crc': {
          let badge = null;
          const loading =
            object.target_config_crc && object.target_config_crc.__loading;
          const targetConfigCrc =
            object.target_config_crc && object.target_config_crc.__data;
          if (exists && targetConfigCrc) {
            const outdated = targetConfigCrc !== value;
            badge = outdated
              ? {
                  text: `update to ${targetConfigCrc.toString(16)}`,
                  color: 'error',
                  relative: true,
                }
              : null;
          }
          addDisplayField({
            key,
            value: exists ? value.toString(16) : '',
            badge,
            loading,
          });
          break;
        }

        case 'entity_type':
          addDisplayField({
            key,
            value: exists ? entityType(value).message : '',
            icon: exists ? entityType(value).icon : '',
          });
          break;

        case 'firmware_info':
          addDisplayField({
            key: 'firmware_info.version',
            value: value?.version ?? '',
            lastUpdated: value?.updated_at ?? '',
          });
          break;

        case 'trip_info':
          addDisplayField({
            key: 'trip_info.in_trip',
            value:
              exists && value.in_trip ? i18n.t('app__yes') : i18n.t('app__no'),
            lastUpdated: value?.updated_at ?? '',
          });
          break;

        case 'battery_info':
          const __update = {
            lastUpdated: value?.updated_at ?? '',
            warning: lastUpdateWarning(
              value?.updated_at,
              preferences.mapLwm2mUpdateAlert,
              object.__maintenanceState
            ),
          };

          const defaultedValue: NonNullable<
            Vehicle['vehicle_state']
          >['battery_info'] = {
            state_of_charge_operator: 0,
            state_of_charge_vehicle: 0,
            state_of_health: 0,
            state_of_charge_cutoff: 0,
            ...value,
          };

          if (
            context !== 'map' &&
            context !== 'summary' &&
            context !== 'list'
          ) {
            addObjectDisplayFields({
              context: 'battery',
              value: exists ? defaultedValue : null,
            });
          } else {
            addChildDisplayFields({
              key: 'state_of_charge_operator',
              object: { ...defaultedValue, __update },
              prefix: 'vehicle_state.battery_info',
            });
          }
          break;

        case 'alarm_info':
          addObjectDisplayFields({
            value: exists ? value : null,
          });
          break;

        case 'alarm_type':
          addDisplayField({
            key,
            value: exists ? omniAlarmType(value).message : '',
          });
          break;

        case 'rfid_info':
        case 'dynamo_group_0':
        case 'dynamo_group_1':
        case 'dynamo_group_2':
        case 'lock_function_settings':
          addObjectDisplayFields({
            value: exists ? value : null,
          });
          break;

        case 'stationing_info':
          const stationingInfo: NonNullable<
            Vehicle['vehicle_state']
          >['stationing_info'] = value ?? {};

          addChildDisplayFields({
            key: 'stack_info',
            object: stationingInfo,
            prefix: 'vehicle_state',
          });
          addChildDisplayFields({
            key: 'dock_info',
            object: stationingInfo,
            prefix: 'vehicle_state',
          });

          break;

        case 'stack_info':
          addObjectDisplayFields({
            prefix: '',
            value: exists ? value : {},
          });
          break;

        case 'dock_info':
          addObjectDisplayFields({
            prefix: '',
            value: exists ? value : {},
          });
          break;

        case 'location_info':
          const locationInfo: NonNullable<
            Vehicle['vehicle_state']
          >['location_info'] = value ?? {};

          addChildDisplayFields({
            key: 'location',
            object: locationInfo,
            prefix: 'vehicle_state',
          });
          break;

        case 'speed_info':
          const speedInfo: NonNullable<Vehicle['vehicle_state']>['speed_info'] =
            value ?? {};

          // assist_speed_limit is in decikm/h => divide by 10 to get km/h
          const assistSpeedLimit = (speedInfo?.assist_speed_limit ?? 0) / 10;

          addDisplayField({
            key,
            value: assistSpeedLimit,
            unit: 'km/h',
            lastUpdated: speedInfo?.updated_at ?? '',
          });
          break;

        case 'theft_info':
          const theftInfo: NonNullable<Vehicle['vehicle_state']>['theft_info'] =
            (exists && value) ?? {};

          addDisplayField({
            key,
            value: theftInfo?.last_theft_at
              ? dateFormat(theftInfo?.last_theft_at, dateFormats.dateTime)
              : '',
            warning: theftInfo?.last_theft_at
              ? delayFromNow(theftInfo?.last_theft_at) < 60 * 1000
              : false,
            lastUpdated: theftInfo?.last_theft_at
              ? { source: 1, _last_updated_at: theftInfo?.last_theft_at }
              : null,
          });
          break;

        case 'vehicle_state':
          const vehicleState: Vehicle['vehicle_state'] & {
            __maintenanceState?: VehicleMaintenanceState;
          } = exists
            ? {
                ...value,
                // We have to forward the maintenance state for the battery_info warning
                __maintenanceState: object.maintenance_state,
              }
            : {};

          if (context === 'map') {
            addChildDisplayFields({
              key: 'firmware_info',
              object: vehicleState,
              prefix: 'vehicle_state',
            });
            addChildDisplayFields({
              key: 'battery_info',
              object: vehicleState,
              prefix: 'vehicle_state',
            });

            addChildDisplayFields({
              key: 'connectivity_info',
              object: vehicleState,
              prefix: 'vehicle_state',
            });
            addChildDisplayFields({
              key: 'theft_info',
              object: vehicleState,
              prefix: 'vehicle_state',
            });
            addChildDisplayFields({
              key: 'lock_info',
              object: vehicleState,
              prefix: 'vehicle_state',
            });
          } else {
            addChildDisplayFields({
              key: 'firmware_info',
              object: vehicleState,
              prefix: 'vehicle_state',
            });
            addChildDisplayFields({
              key: 'trip_info',
              object: vehicleState,
              prefix: 'vehicle_state',
            });
            addChildDisplayFields({
              key: 'battery_info',
              object: vehicleState,
              prefix: 'vehicle_state',
            });
            addChildDisplayFields({
              key: 'lock_info',
              object: vehicleState,
              prefix: 'vehicle_state',
            });
            addChildDisplayFields({
              key: 'connectivity_info',
              object: vehicleState,
              prefix: 'vehicle_state',
            });
            addChildDisplayFields({
              key: 'stationing_info',
              object: vehicleState,
              prefix: 'vehicle_state',
            });
            addChildDisplayFields({
              key: 'theft_info',
              object: vehicleState,
              prefix: 'vehicle_state',
            });
            addChildDisplayFields({
              key: 'location_info',
              object: vehicleState,
              prefix: 'vehicle_state',
            });
            addChildDisplayFields({
              key: 'speed_info',
              object: vehicleState,
              prefix: 'vehicle_state',
            });
          }
          break;

        case 'lock_info':
          const lockInfo: NonNullable<Vehicle['vehicle_state']>['lock_info'] =
            value ?? {};

          if (modelName === 'vehicle') {
            addChildDisplayFields({
              key: 'status',
              object: lockInfo,
              prefix: 'vehicle_state.lock_info',
            });
            if (context !== 'summary') {
              addChildDisplayFields({
                key: 'source',
                object: lockInfo,
                prefix: 'vehicle_state.lock_info',
              });
            }
          } else if (modelName === 'bike') {
            const warn = unlockWarning(
              exists ? value : null,
              object.status,
              object.metadata?.last_ended_trip_time,
              object.soft_unlock_timeout?.__data
            );
            addDisplayField({
              key,
              value: exists ? bikeLockedStatus(value.status).message : '',
              color: exists ? bikeLockedStatus(value.status, warn).class : '',
              icon: exists ? bikeLockedStatus(value.status, warn).icon : '',
            });
          }
          break;

        case 'scan_info':
          if (exists && value.last_updated) value.last_updated.source = 3;
          if (context === 'summary' || context === 'map') {
            const date =
              exists && value.last_updated
                ? dateFormat(
                    value.last_updated._last_updated_at,
                    dateFormats.dateTime
                  )
                : '';
            const user =
              exists && value.user_id
                ? value.user_id.__populated
                  ? value.user_id.email || value.user_id.__originalData
                  : value.user_id
                : '';
            addDisplayField({
              key: 'last_scanned_by',
              value: user
                ? [{ key: user ? user : '', val: date ? date : '' }]
                : '',
              populated: true,
            });
          } else {
            addDisplayField({
              key,
              value:
                exists && value.user_id
                  ? value.user_id.__populated
                    ? [
                        {
                          key: 'last_scanned_by',
                          originalKey: 'user_id',
                          ref: 'scan_info',
                          val: value.user_id.email,
                        },
                      ]
                    : [
                        {
                          key: 'user_id',
                          val: value.user_id,
                        },
                      ]
                  : '',
              link:
                exists && value.user_id
                  ? {
                      name: 'User',
                      params: {
                        id: value.user_id.__populated
                          ? value.user_id.__originalData
                          : value.user_id,
                      },
                    }
                  : null,
            });
          }
          break;

        case 'product':
          addDisplayField({
            key,
            value: exists ? productType(value).message : '',
          });
          break;

        case 'nearby_freefloating_vehicle_ids': {
          const isLoading = value?.__loading;
          const dataValue = ((value ?? []) as PopulatedField<number>[]).map(
            item => item.__data ?? item
          );

          addDisplayField({
            key,
            value: exists
              ? dataValue.map(vehicle => vehicle?.id ?? vehicle)
              : '',
            disabled: exists
              ? dataValue.map(({ downplay }) => !!downplay)
              : false,
            iconSlot: exists
              ? dataValue.map(vehicle => ({
                  component: {
                    name: ZVehicleIcon,
                    props: {
                      vehicle: {
                        maintenance_state:
                          vehicle?.maintenance_state ??
                          VehicleMaintenanceState.MaintenanceStateInOrder,
                        is_rented: vehicle?.is_rented,
                        is_reserved: vehicle?.is_reserved,
                      },
                      small: true,
                    },
                  },
                }))
              : null,
            badge: exists
              ? dataValue.map(vehicle => {
                  return batteryBadge(
                    vehicle?.vehicle_state?.battery_info
                      ?.state_of_charge_operator,
                    isLoading,
                    !preferences.themeDark
                  );
                })
              : null,
            link: {
              name: context === 'map' ? 'Map.Station.Vehicle' : 'Vehicle',
              params: context === 'map' ? { id: object.id } : {},
            },
          });
          break;
        }

        case 'products': {
          const capacity = object.capacity;
          const capacityText = capacity ? ' / ' + capacity : '';
          const products:
            | NonNullable<Fastener['products']>
            | (ApiSchema['entity.Vehicle'] & { position?: number })[] =
            value ?? [];

          const count = products.length;
          const quantityText = `${count}${capacityText}`;

          const valueSortedByProductPosition = products
            .slice() // create a copy and avoid mutation of the original array
            .sort((productA, productB) =>
              (productA.position ?? 0) > (productB.position ?? 0)
                ? 1
                : (productA.position ?? 0) < (productB.position ?? 0)
                  ? -1
                  : 0
            );

          type PopulatedBikeKey =
            | 'maintenance_state'
            | 'out_of_order'
            | 'status'
            | 'battery_community';

          type PopulatedVehicleKey =
            | 'id'
            | 'is_reserved'
            | 'is_rented'
            | 'maintenance_state'
            | 'vehicle_state';

          type FlattenedPopulatedProduct =
            ApiSchema['station.FastenedProduct'] &
              Pick<ApiSchema['entity.Vehicle'], PopulatedVehicleKey> &
              Pick<ApiSchema['bike.Bike'], PopulatedBikeKey> & {
                downplay?: boolean;
              };

          let isLoading = false;
          const flattenedDataValue = valueSortedByProductPosition?.map(
            product => {
              // For each key in product, if the value is a populated object, return the populated data
              // otherwise return the value. We cast as "any" as Typescript is not able to understand this.
              const dataProduct: any = {};
              keysOf(product).forEach(key => {
                if ((product as any)[key]?.__loading) isLoading = true;
                else isLoading = false;
                dataProduct[key] = (product as any)[key]?.__populated
                  ? (product as any)[key]?.__data
                  : product[key];
              });
              return dataProduct as FlattenedPopulatedProduct;
            }
          );

          if (
            context === 'summary' ||
            context === 'map' ||
            context === 'data'
          ) {
            addDisplayField({
              key,
              value: exists
                ? flattenedDataValue.map(
                    ({ id, vehicle_id: vehicleId }) => id ?? vehicleId
                  )
                : '',
              color: exists
                ? flattenedDataValue.map(
                    ({ maintenance_state: maintenanceState }) =>
                      vehicleMaintenanceState(maintenanceState).class ?? ''
                  )
                : '',
              iconSlot: exists
                ? flattenedDataValue.map(bikeOrVehicle => ({
                    component: {
                      name: ZVehicleIcon,
                      props: {
                        vehicle: bikeOrVehicle,
                        small: true,
                      },
                    },
                  }))
                : null,
              badge: exists
                ? flattenedDataValue.map(({ vehicle_state: vehicleState }) =>
                    batteryBadge(
                      vehicleState?.battery_info?.state_of_charge_operator,
                      isLoading,
                      !preferences.themeDark
                    )
                  )
                : null,
              link:
                context === 'map'
                  ? {
                      name: 'Map.Station.Fastener.Vehicle',
                      params: {
                        id: object.station_id,
                        fastenerId: object.id,
                      },
                    }
                  : { name: 'Vehicle' },
              disabled: exists
                ? flattenedDataValue.map(({ downplay }) => !!downplay)
                : false,
              metaInfo:
                exists && (object.type ?? 0) === FastenerType.Stack
                  ? flattenedDataValue.map(({ position }) => position ?? '')
                  : null,
              forceMultiline: true,
              ...(context === 'summary'
                ? {
                    slotSpacer: true,
                    slot: [
                      {
                        component: {
                          name: 'span',
                          content: quantityText,
                        },
                      },
                    ],
                  }
                : {}),
            });
          } else {
            if (isHistory(context)) {
              const deeperFormatting = exists
                ? deeperFormatField(
                    {
                      modelName,
                      context,
                    },
                    value[0]
                  )
                : '';
              addDisplayField({
                key,
                value: exists ? [deeperFormatting] : '',
                subTable: true,
                subTableHeaders: [
                  {
                    text: 'serial_number',
                    value: 'serial_number',
                    sortable: false,
                  },
                  { text: 'position', value: 'position', sortable: false },
                  {
                    text: 'last_updated_at',
                    value: 'last_updated_at',
                    sortable: false,
                  },
                  {
                    text: 'attached_at',
                    value: 'attached_at',
                    sortable: false,
                  },
                ],
              });
            } else {
              addDisplayField({
                key,
                value: quantityText,
              });
            }
          }

          break;
        }

        case 'availability':
          addDisplayField({
            key,
            value: fastenerAvailability(value).message,
            color: fastenerAvailability(value).class,
            icon: fastenerAvailability(value).icon,
          });
          break;

        case 'accessibility':
          addDisplayField({
            key,
            value: bikeAccessibility(value).message,
          });
          break;

        // history
        // case '_created' already handled previously
        case 'modified':
        case 'changed':
        case 'added':
        case 'deleted':
        case 'removed': {
          // clean data in order to hide what cannot be read
          const cleanEntries: HistoryEntry[] = exists
            ? (value as HistoryEntry[]).reduce<HistoryEntry[]>(
                (
                  entries,
                  { field, new_value: newValue, old_value: oldValue }
                ) => {
                  // filter out `_created` entries
                  if (field.split('.').pop() === '_created') return entries;
                  // filter out what cannot be read
                  if (cannotReadData(field)) {
                    return entries;
                  } else {
                    return entries.concat({
                      field,
                      old_value: oldValue,
                      new_value: newValue,
                    });
                  }
                },
                [] as HistoryEntry[]
              )
            : [];

          // after the removal of _created entries, updates arrays may be empty
          // so check if cleanEntries is not empty, otherwise stop here
          if (!cleanEntries.length) return [];
          addDisplayField({
            key: 'timestamp',
            value: exists
              ? dateFormat(
                  object._created || object.timestamp,
                  dateFormats.dateTime
                )
              : '',
            hideRaw: true,
          });
          addDisplayField({
            key: 'action',
            value: key,
            icon: historyActionStyle(key).icon,
            color: historyActionStyle(key).color,
            hideRaw: true,
          });
          if (!hideFields) {
            addDisplayField({
              key: 'fields',
              value: cleanEntries.map(({ field }) => field),
              subTable: 'rawlist',
              tdProps: { style: { 'padding-right': 0 } },
              hideRaw: true,
            });
          }

          // iterative formatting
          const oldValuesFormatting = deepFormatHistoryData(
            cleanEntries,
            'old_value',
            modelName,
            format
          );
          const newValuesFormatting = deepFormatHistoryData(
            cleanEntries,
            'new_value',
            modelName,
            format
          );

          if (before) {
            addDisplayField({
              key: 'before',
              value: oldValuesFormatting,
              subTable: 'list',
              subTableHeaders: [],
              tdProps: { style: { padding: 0 } },
              hideRaw: true,
            });
            addDisplayField({
              key: 'after',
              value: newValuesFormatting,
              subTable: 'list',
              subTableHeaders: [],
              tdProps: { style: { padding: 0 } },
              hideRaw: true,
            });
          } else {
            addDisplayField({
              key: 'data',
              value:
                key === 'removed' ? oldValuesFormatting : newValuesFormatting,
              subTable: 'list',
              subTableHeaders: [],
              tdProps: { style: { padding: 0 } },
              hideRaw: true,
            });
          }
          // raw changes
          if (raw) {
            addDisplayField({
              key: 'raw_value',
              value: '<pre>' + JSON.stringify(cleanEntries, null, 2) + '</pre>',
              hideRaw: true,
            });
          }
          break;
        }

        case 'last_product_error':
          addDisplayField({
            key,
            value: exists
              ? [
                  `${
                    value.last_product_error_code ??
                    `<em>${i18n.t('app__no_error_code')}</em>`
                  } – ${
                    tripProductError(value.last_product_error_code).message
                  } (${dateFormat(value.timestamp)})`,
                ]
              : '',
            color: exists ? ['error'] : '',
            lastUpdated: exists ? value.timestamp : '',
            icon: exists ? ['mdi-alert-circle'] : '',
          });
          break;

        case 'last_end_verify_error':
          addDisplayField({
            key,
            value: exists
              ? [
                  `${
                    value.last_end_verify_error_code ??
                    `<em>${i18n.t('app__no_error_code')}</em>`
                  } – ${
                    value.last_end_verify_error_message ??
                    `<em>${i18n.t('app__no_error_message')}</em>`
                  } (${dateFormat(value.timestamp)})`,
                ]
              : '',
            color: exists ? ['error'] : '',
            lastUpdated: exists ? value.timestamp : '',
            icon: exists ? ['mdi-alert-circle'] : '',
          });
          break;

        case 'url':
          addDisplayField({
            key,
            value: exists
              ? `<a href="${value}" title="${value}" target="_blank">${i18n.t(
                  'app__open_link'
                )} ${getRawSvgIcon(mdiOpenInNew, '14px', {
                  'margin-left': '4px',
                  'margin-bottom': '2px',
                })}</a>`
              : '',
          });
          break;

        case 'extra_properties':
          const extraProperties = (exists && value) || {};
          if (
            modelName === 'issue' &&
            (context === 'summary' || context === 'list')
          ) {
            addChildDisplayFields({
              key: 'source',
              object: extraProperties,
              prefix: key,
            });
          }

          if (modelName === 'point_of_interest') {
            // format specific point of interest extra properties
            addChildDisplayFields({
              key: 'url',
              object: extraProperties,
              prefix: key,
            });

            addChildDisplayFields({
              key: 'type',
              object: extraProperties,
              prefix: key,
            });
            addChildDisplayFields({
              key: 'address',
              object: extraProperties,
              prefix: key,
            });
          } else {
            addObjectDisplayFields({
              // Transform { string: string } object into correct types because
              // extra_properties model in base is a map string => string
              value: Object.entries(extraProperties).reduce(
                (parsed, [key, value]) => {
                  parsed[key] = destr(value);
                  return parsed;
                },
                {} as Record<string, any>
              ),
            });
          }
          break;

        case 'metadata':
          let metadata = (exists && value) || {};

          if (modelName === 'fastener') {
            addChildDisplayFields({
              key: 'batteries',
              object: metadata,
              prefix: key,
            });
            metadata = objectRemoveKeys(metadata, ['batteries']);
          }

          if (modelName === 'issue' && context === 'summary') {
            addDisplayField({
              key: 'firmware_version',
              value: metadata.firmware_version ?? '',
            });
            const maintenanceState = Number(metadata?.maintenance_state ?? 0);
            addDisplayField({
              key: 'maintenance_state',
              value: maintenanceState
                ? bikeMaintenanceState(maintenanceState).message
                : '',
              icon: maintenanceState
                ? bikeMaintenanceState(maintenanceState).icon
                : '',
              color: maintenanceState
                ? bikeMaintenanceState(maintenanceState).class
                : '',
            });
          } else {
            addObjectDisplayFields({
              // Transform { string: string } object into correct types because
              // metadata model in base is a map string => string
              value: Object.entries(metadata).reduce<Record<string, any>>(
                (parsed, [key, value]) => {
                  parsed[key] = destr(value);
                  return parsed;
                },
                {} as Record<string, any>
              ),
            });
          }
          break;

        case 'rfid_tags':
          addDisplayField({
            key,
            value: exists
              ? (value as NonNullable<User['rfid_tags']>).map(tag => {
                  const card =
                    typeof tag.rfid_product_id === 'string'
                      ? tag.rfid_product_id
                      : (tag.rfid_product_id?.card ??
                        tag.rfid_product_id?.__originalData);
                  return {
                    key: card,
                    val: tag.printed_id ?? tag.uid,
                  };
                })
              : [],
          });
          break;

        case 'customer_info':
        case 'lwm2m_info':
          if (context === 'leased-bike') break;

          if (
            context === 'map' ||
            context === 'summary' ||
            context === 'list'
          ) {
            addDisplayField({
              prefix: key,
              key: 'last_contact',
              value:
                exists && value.last_contact
                  ? dateFormat(value.last_contact, dateFormats.dateTime)
                  : '',
              lastUpdated: exists ? value.last_contact : null,
              warning: exists
                ? lastUpdateWarning(
                    value.last_contact,
                    preferences.mapLwm2mUpdateAlert,
                    object.maintenance_state
                  )
                : false,
              danger: !exists,
            });
          } else {
            addObjectDisplayFields({
              value: exists ? objectRemoveKeys(value, ['lte_info']) : null,
            });
            // format and add new 'lwm2m_info.lte_info' entry in case of 'lwm2m_info'
            if (exists && key === 'lwm2m_info') {
              addChildDisplayFields({
                key: 'lte_info',
                object: value,
                prefix: key,
              });
            }
          }
          break;

        case 'lte_info':
          // As 'lte_info' cased is accessed by a recursive formatDisplayField with a prefix,
          // addObjectDisplayFields will add this global prefix which will be displayed twice
          // so we must override the prefix with empty string
          addObjectDisplayFields({ value, prefix: '' });
          break;

        case 'support_info':
          addDisplayField({
            prefix: key,
            key: 'category',
            value: exists && value.category ? value.category : '',
          });
          addDisplayField({
            prefix: key,
            key: 'tags',
            value: exists && value.tags ? value.tags : '',
          });
          break;

        case 'connectivity_info':
          const connectivityInfo: NonNullable<
            Vehicle['vehicle_state']
          >['connectivity_info'] = value ?? {};
          if (context === 'list' || context === 'summary') {
            addChildDisplayFields({
              key: 'last_contact',
              object: connectivityInfo,
              prefix: 'vehicle_state.connectivity_info',
            });
            addChildDisplayFields({
              key: 'deployed_in_environment',
              object: connectivityInfo ?? {},
              prefix: 'vehicle_state',
            });
            addChildDisplayFields({
              key: 'last_contact',
              object: connectivityInfo ?? {},
              prefix: 'vehicle_state',
            });
            addChildDisplayFields({
              key: 'last_ble_contact',
              object: connectivityInfo ?? {},
              prefix: 'vehicle_state',
            });
            addChildDisplayFields({
              key: 'connected',
              object: connectivityInfo ?? {},
              prefix: 'vehicle_state',
            });
            addChildDisplayFields({
              key: 'quality_of_signal',
              object: connectivityInfo ?? {},
              prefix: 'vehicle_state',
            });
          } else {
            addObjectDisplayFields({
              prefix: '',
              value: exists ? value : null,
            });
          }
          break;

        case 'connected':
          addDisplayField({
            key,
            value: value ? i18n.t('app__yes') : i18n.t('app__no'),
            color: value ? 'success' : '',
          });
          break;

        case 'deploy_group':
          if (context === 'summary' || context === 'map') {
            addDisplayField({
              key,
              value: exists && value ? value.name : '',
            });
          } else {
            addObjectDisplayFields({
              value: exists
                ? objectRemoveKeys(value, ['serial_number', 'firmware'])
                : null,
            });
          }
          break;

        case 'tiers': {
          const deeperFormatting = ((exists && value) || []).map(
            (tier: ApiSchema['payment.Tiers']) => {
              return deeperFormatField(
                {
                  context: 'plan',
                  modelName,
                },
                { ...tier, interval: object.interval }
              );
            }
          );
          addDisplayField({
            key,
            value: exists ? deeperFormatting : '',
            rawValue: exists ? value : '',
            subTable: true,
            subTableHeaders: [
              { text: 'price', value: 'price', sortable: false },
              { text: 'up_to', value: 'up_to', sortable: false },
            ],
          });
          break;
        }

        case 'up_to':
          addDisplayField({
            key,
            value: exists
              ? value + ' ' + object.interval + (value > 1 ? 's' : '')
              : '',
          });
          break;

        case 'rules':
        case 'reference_rules':
        case 'remaining_rules': {
          const deeperFormatting = ((exists && value) || []).map(
            (rule: ApiSchema['payment.Rule']) => {
              return deeperFormatField(
                {
                  modelName: 'rule',
                },
                rule
              );
            }
          );
          addDisplayField({
            key,
            value: exists ? deeperFormatting : '',
            rawValue: exists ? value : '',
            subTable: true,
            subTableHeaders: [
              { text: 'name', value: 'name', sortable: false },
              { text: 'type', value: 'type', sortable: false },
              { text: 'duration', value: 'duration', sortable: false },
              { text: 'quantity', value: 'quantity', sortable: false },
              ...(object.modelName === OfferType.SharingPricing
                ? [{ text: 'price', value: 'price', sortable: false }]
                : []),
              {
                text: 'user_maximum',
                value: 'user_maximum',
                sortable: false,
              },
            ],
          });
          break;
        }

        case 'areas':
          switch (modelName) {
            case 'issue':
              const issueAreas: NonNullable<Issue['areas']> = value ?? [];
              addDisplayField({
                key,
                value: issueAreas.map(area => area.__data),
              });
              break;

            case 'user':
              addDisplayField({
                key,
                value: exists
                  ? ((value as User['areas']) ?? []).map(area => ({
                      key: (area.label && area.label.__data) || area.id,
                      val: area.count,
                      ref: 'areas',
                      lastUpdated: area.last_updated_at,
                    }))
                  : '',
              });
              break;

            case 'offer':
            case 'benefit':
              const areas = (value ?? []).map(
                (area: PopulatedField<string>) => area.__data
              );
              addDisplayField({
                key,
                value: context === 'list' ? areas[0] : areas,
                slot:
                  context === 'list' && areas.length > 1
                    ? [
                        {
                          component: {
                            name: ZBadge,
                            props: {
                              text: '+' + (areas.length - 1),
                              color: 'info',
                              relative: true,
                            },
                          },
                        },
                      ]
                    : null,
              });
              break;
          }
          break;

        case 'device_os':
          addDisplayField({
            key,
            value: exists ? value : '',
            icon: exists ? deviceOs(value).icon : '',
            color: exists ? deviceOs(value).class : '',
          });
          break;

        case 'coordinates':
          addDisplayField({
            key,
            value: exists
              ? isArray
                ? value.map((coords: [number, number] | string | undefined) =>
                    Array.isArray(coords) ? coords.join(', ') : coords
                  )
                : value
              : '',
          });
          break;

        case 'critical_area':
        case 'critical_coverage_area':
        case 'critical_area_encoded':
        case 'geo_areas_unallowed':
        case 'encoded_coordinates':
        case 'virtual_area_encoded':
          if (isHistory(context)) {
            addDisplayField({
              key,
              value: exists
                ? value.modelName === 'Polygon'
                  ? (value.coordinates[0] || []).map(
                      (c: [number, number] | undefined) => (c ?? []).join(', ')
                    )
                  : value
                : '',
            });
          } else {
            addDisplayField({
              key,
              value: exists
                ? key === 'geo_areas_unallowed'
                  ? [
                      {
                        key: value.length,
                        val: appViewShapeText,
                      },
                    ]
                  : appViewShapeText
                : '',
              link: exists
                ? {
                    name: (capitalize(modelName ?? '') +
                      '.Subsection') as RouteName,
                    params: {
                      id: String(object.id),
                      subsection: 'shape',
                    },
                  }
                : null,
            });
          }
          break;

        case 'policies':
          addDisplayField({
            key,
            value: exists
              ? value.map((policy: ApiSchema['area.Policy']) => ({
                  key: areaPolicyType(policy.type).message,
                  val: areaPolicyText(policy),
                  color: areaPolicyType(policy.type).class,
                  icon: areaPolicyType(policy.type).icon,
                }))
              : '',
            link: exists
              ? {
                  name: 'Area.Subsection',
                  params: {
                    id: String(object.id),
                    subsection: 'shape',
                  },
                  query: (_, index) => ({ policy: String(index) }),
                }
              : null,
          });
          break;

        case 'generated_product_config':
          addDisplayField({
            key,
            value: exists ? `</em>${i18n.t('app__view_raw')}</em>` : '',
            rawValue: exists ? JSON.parse(value) : {},
          });
          break;

        case 'settings':
          addDisplayField({
            key,
            value: exists ? `</em>${i18n.t('app__view_raw')}</em>` : '',
            rawValue: exists ? value : {},
          });
          break;

        case 'updates':
        case 'stack':
        case 'dock':
        case 'constraints':
        case 'power_transfer':
        case 'power_saving':
          addObjectDisplayFields({ value });
          break;

        case 'max_silent_period':
          addDisplayField({
            key,
            value: exists ? value : '',
            unit: 's',
          });
          break;

        case 'mode':
          if (modelName === 'bike') {
            addDisplayField({
              key,
              value: exists ? bikePowerSavingMode(value).message : '',
              icon: exists ? bikePowerSavingMode(value).icon : '',
              color: exists ? bikePowerSavingMode(value).class : '',
            });
          } else {
            addDisplayField({
              key,
              value: exists ? value : '',
            });
          }
          break;

        case 'trip_timestamps':
        case 'bike_state':
        case 'sources':
          addObjectDisplayFields({
            value,
            prefix: '',
            context: 'bike_state_extended',
          });
          break;

        case 'bike_state_extended':
          addObjectDisplayFields({
            value: exists
              ? objectRemoveKeys(value, [
                  'bike_state',
                  'sources',
                  'trip_timestamps',
                ])
              : null,
            context: 'bike_state_extended',
          });

          if (exists) {
            addChildDisplayFields({
              key: 'bike_state',
              object: value,
              prefix: key,
            });
            addChildDisplayFields({
              key: 'sources',
              object: value,
              prefix: key,
            });
            addChildDisplayFields({
              key: 'trip_timestamps',
              object: value,
              prefix: key,
            });
          }
          break;

        case 'lock_state':
          addDisplayField({
            key,
            value: exists ? bikeStateLockState(value).message : '',
          });
          break;

        case 'trip_state':
          addDisplayField({
            key,
            value: exists ? bikeStateTripState(value).message : '',
          });
          break;

        case 'risk':
          addDisplayField({
            key,
            value: exists ? tripRisk(value).message : '',
            icon: exists ? tripRisk(value).icon : '',
            color: exists ? tripRisk(value).class : '',
          });

          break;

        case 'suspicious_reasons':
          addDisplayField({
            key,
            value: exists
              ? value.map(
                  (suspiciousReason: SuspiciousReason) =>
                    tripSuspiciousReasons(suspiciousReason).message
                )
              : '',
          });
          break;

        case 'trip':
        case 'lock':
        case 'maintenance':
          addDisplayField({
            key,
            value: exists ? bikeStateSource(value).message : '',
          });
          break;

        case 'vehicle_lock_source':
          addDisplayField({
            key,
            value: exists ? vehicleLockedSource(value).message : '',
          });
          break;

        case 'source':
          if (context === 'bike_state_extended') {
            addDisplayField({
              key,
              value: exists ? value : '',
            });
          } else {
            switch (modelName) {
              case 'bike':
                addDisplayField({
                  key,
                  value: exists ? bikeStateSource(value).message : '',
                });
                break;
              case 'vehicle':
                if (prefix === 'vehicle_state.lock_info') {
                  addDisplayField({
                    key,
                    value: exists ? vehicleLockedSource(value).message : '',
                  });
                }
                break;
              case 'issue':
                addDisplayField({
                  key,
                  value: issueSource(value).message,
                  icon: issueSource(value).icon,
                });
                break;
              default:
                addDisplayField({
                  key,
                  value: exists ? value : '',
                });
                break;
            }
          }
          break;

        case 'categories':
          const categories: Issue['categories'] = value;
          addDisplayField({
            key,
            value: exists
              ? (categories ?? []).map(
                  category =>
                    category?.__data?.i18n_name?.[i18n.locale] ??
                    category?.__data?.name
                )
              : '',
          });
          break;

        case 'data':
          addDisplayField({
            key,
            value: exists
              ? value.map((entry: ApiSchema['bike.Data']) => ({
                  ...entry,
                  val: entry.value,
                }))
              : '',
            noHistory: true,
          });
          break;

        case 'battery_community':
        case 'battery_backup':
        case 'battery_user': {
          // force presence of state_of_charge field if object exists and presence of last_updated
          // because backend would not send fields with the value 0, set the other fields to 'null'
          const ensuredValue: Record<string, any> = {};
          const hideLastUpdated =
            !userPrivileges.canReadBikeBatteryCommunityFull;

          const _batteryFields = userPrivileges.canReadBikeBatteryCommunityFull
            ? batteryFields
            : ['state_of_charge', 'state_of_health', 'serial_number'];

          _batteryFields
            .filter(field => field !== 'last_updated')
            .forEach(field => {
              const v = ['state_of_charge', 'manufacturer_id'].includes(field)
                ? 0
                : null;
              ensuredValue[field] = exists
                ? ![null, undefined].includes(value[field])
                  ? value[field]
                  : v
                : v;
            });

          if (
            context !== 'list' &&
            context !== 'summary' &&
            context !== 'map'
          ) {
            addObjectDisplayFields({
              value: ensuredValue,
              context: 'battery',
              ...(hideLastUpdated ? { lastUpdated: null } : {}),
            });
          } else {
            const lastUpdated = value?.last_updated ?? {};
            const __update = {
              lastUpdated,
              warning: lastUpdateWarning(
                lastUpdated._last_updated_at,
                preferences.mapLwm2mUpdateAlert,
                object.maintenance_state
              ),
            };
            const __fromBike = ensuredValue.state_of_charge_from_bike;
            const __category = ensuredValue.category;

            addChildDisplayFields({
              key: 'state_of_charge',
              object: { ...ensuredValue, __update, __fromBike, __category },
              prefix: key,
            });
          }
          break;
        }

        case 'voltage':
          addDisplayField({
            key,
            unit: 'mV',
            value,
          });
          break;

        case 'batteries':
          // Parse batteries which comes from metadata and is a stringified JSON
          // but we cannot 100% rely on that, so make sure to show raw data if fail to parse
          try {
            const batteries = JSON.parse(value);
            // We expect an array
            if (Array.isArray(batteries)) {
              if (context === 'summary' || context === 'map') {
                addDisplayField({
                  key,
                  unit: '%',
                  value: batteries
                    .filter(
                      ({ state_of_charge: stateOfCharge }) =>
                        ![null, undefined].includes(stateOfCharge)
                    )
                    .map(({ state_of_charge: stateOfCharge }) => ({
                      val: stateOfCharge,
                      color: getBatteryColor(stateOfCharge),
                      icon: getBatteryIcon(stateOfCharge),
                    })),
                });
              } else {
                const deeperFormatting = batteries.map(battery => {
                  return deeperFormatField(
                    {
                      context: 'battery',
                      modelName: 'battery',
                    },
                    battery
                  );
                });
                addDisplayField({
                  key,
                  value: exists ? deeperFormatting : '',
                  rawValue: exists ? value : '',
                  subTable: true,
                });
              }
            } else {
              addDisplayField({
                key,
                value: batteries,
              });
            }
          } catch (e) {
            addDisplayField({
              key,
              value: exists ? value : '',
            });
          }
          break;

        case 'manufacturer_id':
          addDisplayField({
            key,
            value: exists ? batteryManufacturer(value).message : '',
          });
          break;

        case 'sim':
          addDisplayField({
            key,
            value: exists ? openTabTextHtml : '',
            link: exists
              ? modelName === 'bike'
                ? {
                    name: 'Bike.Subsection',
                    params: {
                      serial_number: String(object.serial_number),
                      subsection: 'bike_info',
                    },
                  }
                : {
                    name: (capitalize(modelName ?? '') +
                      '.Subsection') as RouteName,
                    params: {
                      id: object.id,
                      subsection: 'sim',
                    },
                  }
              : null,
          });
          break;

        case 'bike_info': {
          addDisplayField({
            key,
            value: exists
              ? context === 'map'
                ? `<em>${i18n.t('bikes__open_bike_card')}</em>`
                : isHistory(context)
                  ? `<em>${i18n.t('app__view_raw')}</em>`
                  : openTabTextHtml
              : '',
            link:
              exists && context !== 'map'
                ? {
                    name: 'Bike.Subsection',
                    params: {
                      serial_number: String(object.serial_number),
                      subsection: 'bike_info',
                    },
                  }
                : null,
          });
          break;
        }

        case 'hardware': {
          const rawValue: ApiSchema['bike.BikeInfo'] = exists ? value : {};
          if (Array.isArray(rawValue.projects)) {
            (rawValue.projects || []).forEach(project => {
              project.boards?.forEach(board => {
                if (board.serial_id) {
                  // @ts-expect-error ignore remapping number to string
                  board.serial_id = board.serial_id.toString(16);
                }
                if (board.hw_rev_id) {
                  // @ts-expect-error ignore remapping number to string
                  board.hw_rev_id = board.hw_rev_id.toString(16);
                }
              });
            });
          }
          addDisplayField({
            key: 'hardware.board_info',
            value: exists
              ? context === 'map'
                ? '<em>' + i18n.t('bikes__open_bike_card') + '</em>'
                : isHistory(context)
                  ? '<em>' + i18n.t('app__view_raw') + '</em>'
                  : openTabTextHtml
              : '',
            link:
              exists && context !== 'map'
                ? {
                    name: 'Bike.Subsection',
                    params: {
                      serial_number: String(object.serial_number),
                      subsection: 'board_info',
                    },
                  }
                : null,
            rawValue,
          });
          break;
        }

        case 'projects':
          if (exists && isArray) {
            const boardInfoProjects: NonNullable<
              ApiSchema['bike.Hardware']['board_info']
            >['projects'] = value;

            (boardInfoProjects ?? []).forEach((project, index: number) => {
              addDisplayField({
                prefix: join(key, index),
                key: 'type',
                value:
                  exists && project.type
                    ? bikeHardwareProjectType(project.type).message
                    : '',
                rawValue: exists ? project.type : '',
              });

              const deeperFormatting = project.boards?.map(board => {
                return deeperFormatField(
                  {
                    modelName: 'bike_hardware_project_board',
                    context,
                  },
                  board
                );
              });

              addDisplayField({
                prefix: join(key, index),
                key: 'boards',
                value: exists && project.boards ? deeperFormatting : '',
                rawValue: exists ? project.boards : '',
                subTable: true,
              });
            });
          } else {
            addDisplayField({ key, value: exists ? value : '' });
          }
          break;

        case 'serial_id':
        case 'hw_rev_id':
          addDisplayField({
            key,
            value: exists ? value.toString(16) : '',
          });
          break;

        case 'name': {
          switch (modelName) {
            case 'role':
              addDisplayField({
                key,
                value: exists ? value : '',
                color: exists ? roleName(value).class : '',
                icon: exists ? roleName(value).icon : '',
              });
              break;
            default:
              addDisplayField({
                key,
                value: exists ? value : '',
              });
              break;
          }
          break;
        }

        case 'description':
          if (context === 'auto_diags') {
            addDisplayField({
              key,
              value: (exists ? value : '') + ' &nbsp;',
              lastUpdated: object.changed_at,
              metaInfo: object.is_persistent
                ? getRawSvgIcon(
                    mdiClockAlert,
                    '16px',
                    { 'margin-bottom': '1px' },
                    'error--text'
                  )
                : '',
            });
          } else {
            addDisplayField({
              key,
              value: exists ? value : '',
            });
          }
          break;

        case 'domains':
          addDisplayField({
            key,
            value: exists
              ? !context || context !== 'summary'
                ? value.length
                : value
              : '',
          });
          break;

        case 'auto_add_users':
          addDisplayField({
            key,
            value: value ? i18n.t('app__yes') : i18n.t('app__no'),
            icon: value ? 'mdi-check' : '',
          });
          break;

        case 'country_code':
          const countryName = exists
            ? countries.getName(value, i18n.locale, { select: 'official' })
            : '';
          addDisplayField({
            key,
            value: exists ? value : '',
          });
          addDisplayField({
            key: 'country',
            value: countryName,
            populated: true,
          });
          break;

        case 'free_floating_not_allowed':
          addDisplayField({
            key,
            value: value ? 'not allowed' : '',
            icon: value ? 'svg:zoov_bike_off' : '',
            color: value ? 'error' : '',
          });
          break;

        case 'is_archived':
          addDisplayField({
            key,
            value: value ? 'true' : '',
            color: value ? 'grey' : '',
            icon: exists ? 'mdi-archive' : '',
          });
          break;

        case 'documents':
          addDisplayField({
            key,
            value: exists
              ? typeof value === 'string'
                ? value
                    .split(',')
                    .filter(key => key) // remove empty strings
                    .map(key => i18n.t('pricings__' + key))
                    .join(', ')
                : value
              : '',
          });
          break;

        case 'pay_as_you_go':
          // Inject link params to value
          const injectedValue = (value ?? []).map(
            (payAsYouGo: Area['pay_as_you_go'], index: number) => ({
              ...payAsYouGo,
              __linkParams: { id: object.pay_as_you_go_id?.[index]?.__data },
            })
          );

          addDisplayField({
            key,
            value: exists ? injectedValue : '',
            link: exists ? { name: 'Benefit' } : null,
          });
          break;

        case 'force_lock':
        case 'cumulative':
        case 'restricted_visibility':
        case 'paused_at_validation':
        case 'pending_validation_at_creation':
        case 'start_confirmed_by_vehicle_state':
        case 'multiple_per_target':
        case 'is_paused':
        case 'end_outside_station':
          addDisplayField({
            key,
            value: exists
              ? value
                ? i18n.t('app__yes')
                : i18n.t('app__no')
              : '',
          });
          break;

        case 'country':
        case '__update':
        case '__fromBike':
        case '__currency':
          break;

        case 'powermeter':
          if (value) {
            const formattedValue = {
              current: value.current + ' mA',
              energy: value.energy + ' Wh',
              frequency: value.frequency + ' Hz',
              voltage: value.voltage / 1000 + ' V',
            };
            addObjectDisplayFields({ value: formattedValue });
          } else {
            addDisplayField({
              key,
              value: '',
            });
          }
          break;

        case 'power_source':
          addDisplayField({
            key,
            value: exists ? fastenerPowerSource(value).message : '',
          });
          break;

        case 'allowed_product_versions':
          addDisplayField({
            key,
            value: exists
              ? value.map(
                  (product: ProductVersion) => productType(product).message
                )
              : '',
          });
          break;

        case 'product_version_id':
          addDisplayField({
            key,
            value: exists ? getProductVersionName(value) : '',
          });
          break;

        case 'fasteners':
          // TODO : Clean this any type
          const flattenedValue: ApiSchema['station.Fastener'][] = (
            value ?? []
          ).map((item: any) => item.__data ?? item);

          addDisplayField({
            key,
            value:
              exists && isArray
                ? flattenedValue.map(({ id, label }) => ({
                    __originalData: id,
                    __data: label,
                  }))
                : '',
            icon:
              exists && isArray
                ? flattenedValue.map(
                    ({ type }) =>
                      fastenerType(type ?? FastenerType.Stack).icon ?? ''
                  )
                : '',
            color:
              exists && isArray
                ? flattenedValue.map(
                    ({ status, maintenance_state: maintenanceState }) =>
                      fastenerIconColor(
                        status ?? FastenerStatus.NotSet,
                        maintenanceState ?? FastenerMaintenanceState.NotSet
                      )
                  )
                : '',
            badge:
              exists && isArray
                ? flattenedValue.map(
                    ({ serial_number: serialNumber, lwm2m_info: lwm2mInfo }) =>
                      serialNumber ? lteSignalBadge(lwm2mInfo) : null
                  )
                : null,
            link: exists
              ? {
                  name: 'Map.Station.Fastener',
                  params: { id: object.id },
                }
              : null,
            metaInfo:
              exists && isArray
                ? flattenedValue.map(({ products, capacity, type }) => {
                    return type !== FastenerType.Dock
                      ? `${products?.length ?? 0}/${capacity ?? 0}`
                      : '';
                  })
                : '',
          });
          break;

        case 'suspicious_trip_params':
          addObjectDisplayFields({
            value: exists ? objectRemoveKeys(value, ['triggers']) : null,
          });
          addChildDisplayFields({
            key: 'triggers',
            object: value,
            prefix: key,
          });
          break;

        case 'triggers':
          addObjectDisplayFields({
            value: exists ? (value ?? {}) : {},
          });
          break;

        case 'trip_cooldown':
          addObjectDisplayFields({
            value: exists
              ? objectRemoveKeys(value, ['pricing_whitelist'])
              : null,
          });
          addDisplayField({
            key: 'pricing_whitelist',
            prefix: key,
            value: exists ? (value.pricing_whitelist ?? []) : [],
            link: { name: 'Offer' },
          });
          break;

        case 'bookings':
          addDisplayField({
            key,
            value: openTabTextHtml,
            link: {
              name: 'Rental.Subsection',
              params: {
                id: String(object.id),
                subsection: 'bookings',
              },
            },
          });
          break;

        case 'trips':
          addDisplayField({
            key,
            value: openTabTextHtml,
            link: {
              name: 'Rental.Subsection',
              params: {
                id: String(object.id),
                subsection: 'trips',
              },
            },
          });
          break;

        case 'rented_bikes':
          addDisplayField({
            key,
            value: exists ? value : '',
            link: { name: 'Vehicle' },
          });
          break;

        case 'rented_vehicle_ids':
          addDisplayField({
            key,
            value: exists ? value : '',
            link: { name: 'Vehicle' },
          });
          break;

        case 'rental_id':
          addDisplayField({
            key,
            value: exists ? value : '',
            link: { name: 'Rental' },
          });
          break;

        case 'target_id':
          addDisplayField({
            key,
            value: exists ? value : '',
            link: { name: issueTargetModelRouteName(object.target_model) },
            icon: issueTarget(object.target_model).icon,
          });
          break;

        case 'target':
          switch (modelName) {
            case 'benefit': {
              const populatedTargetLabel = (() => {
                switch (value?.target_type) {
                  default:
                  case TargetType.User:
                    return object.target_user?.__data ?? value?.id;
                  case TargetType.Role:
                  case TargetType.Bike:
                    return value?.id;
                  case TargetType.Station:
                    return object.target_station?.__data ?? value?.id;
                  case TargetType.Area:
                    return object.target_area?.__data ?? value?.id;
                }
              })();
              addDisplayField({
                key,
                value: exists ? populatedTargetLabel : '',
                icon: exists ? benefitTargetType(value.target_type).icon : '',
                link: {
                  name: benefitTargetRouteName(value),
                  params: { id: object.target?.id },
                },
              });
              break;
            }
          }
          break;

        case 'severity':
          addDisplayField({
            key,
            value: exists ? issueSeverity(value).message : '',
            color: exists ? issueSeverity(value).class : '',
            icon: exists ? issueSeverity(value).icon : '',
          });
          break;

        case 'obtention_method_type':
          addDisplayField({
            key,
            value: exists ? offerObtentionMethodType(value).message : '',
          });
          break;

        case 'category':
          if (context === 'battery' || modelName === 'bike') {
            addDisplayField({
              key,
              value: exists ? value : '',
            });
          } else {
            addDisplayField({
              key,
              value: exists ? offerCategory(value).message : '',
              color: exists ? offerCategory(value).class : '',
              icon: exists ? offerCategory(value).icon : '',
              populated: true,
            });
          }
          break;

        case 'plan': {
          if (exists) {
            addObjectDisplayFields({
              value: objectRemoveKeys(value, ['tiers']),
            });
            addChildDisplayFields({
              key: 'tiers',
              object: value,
              prefix: key,
            });
          } else {
            addDisplayField({ key, value: '' });
          }
          break;
        }

        case 'label':
          {
            const openIssuesCount = object.open_issues?.length;
            const prependSlot =
              exists &&
              context !== 'map' &&
              context !== 'summary' &&
              modelName === 'issue'
                ? [
                    {
                      component: {
                        name: ZOpenIssuesBadge,
                        props: {
                          color: 'grey',
                          count: openIssuesCount,
                        },
                      },
                    },
                  ]
                : [];
            addDisplayField({
              key,
              value: exists ? value : '',
              prependSlot,
            });
          }
          break;

        case 'accesses':
          {
            const deeperFormatting = ((exists && value) || []).map(
              ({ method, route }: ApiSchema['user.APIKeyAccess']) => {
                return deeperFormatField(
                  {
                    modelName: 'accesses',
                  },
                  { method, route }
                );
              }
            );

            addDisplayField({
              key,
              value: exists ? deeperFormatting : '',
              rawValue: exists ? value : '',
              subTable: true,
              subTableHeaders: [
                { text: 'method', value: 'method', sortable: false },
                { text: 'route', value: 'route', sortable: false },
              ],
            });
          }
          break;

        case 'offer':
          addDisplayField({
            key,
            value: object.name,
            link: { name: 'Offer', params: { id: object.offer_id } },
          });
          break;

        case 'allowed_targets':
          addDisplayField({
            key,
            value: exists
              ? value.map(
                  (target: TargetType) => benefitTargetType(target).message
                )
              : '',
            icon: exists
              ? value.map(
                  (target: TargetType) => benefitTargetType(target).icon
                )
              : '',
          });
          break;

        case 'details':
          {
            const deeperFormatting = (
              ((exists && value) || []) as Required<
                ApiSchema['payment.Benefit']
              >['details']
            ).map(detail => {
              return deeperFormatField(
                {
                  modelName: 'i18n_content',
                },
                detail.i18n_content ?? {}
              );
            });

            addDisplayField({
              key,
              value: exists ? deeperFormatting : '',
              rawValue: exists ? value : '',
              subTable: true,
            });
          }
          break;

        case 'benefit_validity':
        case 'rules_refill_cycle':
          const presets = (() => {
            switch (key) {
              case 'benefit_validity':
                return benefitValidityPresets.value;
              case 'rules_refill_cycle':
                return rulesRefillCyclePresets.value;
            }
          })();

          const matchingPreset = exists
            ? presets.find(
                preset =>
                  (preset.cron ?? '') === (value.cron ?? '') &&
                  (preset.duration ?? 0) * 10 ** 9 === (value.duration ?? 0) &&
                  (preset.durationMonths ?? 0) === (value.duration_months ?? 0)
              )
            : null;

          addObjectDisplayFields({
            value: {
              ...(matchingPreset && { preset: matchingPreset.label }),
              ...value,
            },
            wrap: true,
            modelName: 'duracron',
          });
          break;

        case 'opt_ins':
          {
            const deeperFormatting = (
              ((exists && value) || []) as Required<
                ApiSchema['user.User']
              >['opt_ins']
            ).map(optIn => {
              return deeperFormatField(
                {
                  modelName: 'opt_in',
                },
                {
                  ...optIn,
                  type: optIns(optIn.type).message,
                  validated: optIn.validated ?? false,
                }
              );
            });

            addDisplayField({
              key,
              value: exists ? deeperFormatting : '',
              rawValue: exists ? value : '',
              subTable: true,
              subTableHeaders: [
                {
                  text: 'type',
                  value: 'type',
                  sortable: true,
                },
                {
                  text: 'last_validated',
                  value: 'last_validated',
                  sortable: true,
                },
                {
                  text: 'validated',
                  value: 'validated',
                  sortable: true,
                },
              ],
            });
          }
          break;

        case 'rental_config':
          addDisplayField({
            key,
            value: exists ? openTabTextHtml : '',
            link:
              exists && context !== 'map'
                ? {
                    name: 'Offer.Subsection',
                    params: {
                      id: String(object.id),
                      subsection: 'rental_config',
                    },
                  }
                : null,
          });
          break;

        case 'geolocation_opt_in':
          addDisplayField({
            key,
            value: value ? i18n.t('app__yes') : i18n.t('app__no'),
            color: value ? 'success' : 'error',
            icon: value ? 'mdi-map-marker-check' : 'mdi-map-marker-off',
          });
          break;

        case 'default':
        case 'validated':
          addDisplayField({
            key,
            value: value ? i18n.t('app__yes') : i18n.t('app__no'),
            color: value ? 'success' : 'error',
            icon: value ? 'mdi-check' : 'mdi-close',
          });
          break;

        case 'i18n_title':
          if (context !== 'summary' && context !== 'data') {
            const entries = Object.entries(
              (exists ? value : {}) as NonNullable<Offer['i18n_title']>
            );
            addDisplayField({
              key,
              value: exists ? value[preferences.lang as string] : '',
              slot:
                exists && entries.length > 1
                  ? [
                      {
                        component: {
                          name: ZBadge,
                          props: {
                            text: '+' + (entries.length - 1),
                            color: 'info',
                            relative: true,
                          },
                        },
                      },
                    ]
                  : null,
              expand:
                exists && entries.length > 1
                  ? entries.map(([key, val]) => ({
                      prefix: key,
                      val,
                    }))
                  : null,
            });
          } else {
            addObjectDisplayFields({ value, wrap: true });
          }
          break;

        case 'target_state':
        case 'current_state':
          addObjectDisplayFields({ value });
          break;

        case 'state':
          if (modelName === 'sim') {
            addDisplayField({
              key,
              value: exists ? simSystemState(value).message : '',
              color: exists ? simSystemState(value).class : '',
            });
            break;
          }
          addDisplayField({
            key,
            value: exists ? value : '',
          });
          break;

        default:
          // first switch on true to match with logical expressions such as regex
          switch (true) {
            case /^i18n_/.test(key):
              addObjectDisplayFields({ value, wrap: true });
              break;

            // then finally default to default output
            default:
              addDisplayField({
                key,
                value: exists ? value : '',
              });
              break;
          }
          break;
      }
    } catch (error) {
      // output raw data and an error
      addDisplayField({
        key,
        value: exists ? value : '',
        error: new AppError(AppErrorCode.FormattingFailed),
      });
      // log error if in debug mode
      if (Number(window.VUE_APP_DEBUG) === 1) {
        console.error(error);
      }
      // send error to Sentry error tracker
      Sentry.withScope(scope => {
        scope.setLevel('warning');
        scope.setContext('Formatting', {
          metadata: {
            modelName,
            context,
            options,
            key,
            value,
          },
        });
        Sentry.captureException(error);
      });
    }

    return displayFields;
  }

  return {
    formatField,
  };
}
