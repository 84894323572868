<template lang="pug">
component.ZMiniChip(
  :is="props.to ? 'router-link' : 'div'"
  :class="classes"
  v-bind="{ ...(props.to ? { to: props.to } : {}) }"
)
  slot
</template>

<style lang="stylus">
.ZMiniChip
  background-color rgba(170, 170, 170, 0.15)
  padding-top 1px
  padding-bottom 1px
  border-radius 20px
  font-size 10px
  text-align center
  display inline-flex
  align-items center
  padding-left 10px
  padding-right 10px
  color: $colors.grey.darken-2
  text-wrap nowrap
  height 16px

  .v-icon:first-child,
  .ZSvgIcon:first-child
    margin-left -3px
    margin-right 2px

  &--large
    font-size 11px

  &--dense
    padding-left 6px
    padding-right 6px

    .v-icon:first-child
      margin-left -2px

.theme--dark .ZMiniChip,
.v-tooltip__content .ZMiniChip
  color darken($colors.grey.lighten-2, 10%)

a.ZMiniChip
  text-decoration none
  transition color 0.2s ease

a.ZMiniChip:hover
  color: $colors.grey.darken-3

.theme--dark a.ZMiniChip:hover
  color: $colors.grey.lighten-2

.theme--light .ZMiniChip.ZMiniChip--hint,
.ZMiniChip.ZMiniChip--hint .theme--light.v-icon
  color darken($color-disabled_alt1, 50%)

.theme--dark .ZMiniChip.ZMiniChip--hint,
.ZMiniChip.ZMiniChip--hint .theme--dark.v-icon,
.v-tooltip__content .ZMiniChip.ZMiniChip--hint,
.v-tooltip__content .ZMiniChip.ZMiniChip--hint .theme--light.v-icon
  color $color-disabled_alt1
</style>

<script setup lang="ts">
import type { RawLocation } from 'vue-router';

export interface ZMiniChipProps {
  /**
   * Makes the chip display denser
   */
  dense?: boolean;
  /**
   * Makes the chip display larger
   */
  large?: boolean;
  /**
   * Classes to be applied to the chip
   */
  classes?: string;
  /**
   * The 'to' prop can be a string or a route record, used for routing
   */
  to?: RawLocation | null;
  /**
   * Determines if the chip is a hint
   */
  isHint?: boolean;
}

const props = withDefaults(defineProps<ZMiniChipProps>(), {
  dense: false,
  large: false,
  classes: '',
  to: null,
  isHint: false,
});

const classes = computed(() => ({
  'ZMiniChip--dense': props.dense,
  'ZMiniChip--large': props.large,
  'ZMiniChip--hint': props.isHint,
}));
</script>
