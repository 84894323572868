<template lang="pug">
ZSwipe(
  allowed-directions="x"
  @throwout="close"
)
  VSnackbar.ZStatusSnackbar(
    v-model="show"
    :color="color"
    :timeout="0"
    :class="{ 'ZStatusSnackbar--light': isLight }"
    bottom
  )
    .ZStatusSnackbar__contentLeft
      VProgressCircular(
        v-if="progress === Infinity"
        indeterminate
        :size="14"
        :width="2"
        :color="progressColor"
      )
      VIcon.ZStatusSnackbar__contentLeft__icon(
        v-if="icon"
        small
      ) {{ icon }}
      span(v-html="content")
      slot
    .ZStatusSnackbar__contentRight
      .ZStatusSnackbar__action(v-if="actionText && actionFunction")
        VBtn(
          dark
          @click="actionFunction"
        ) {{ actionText }}
      VBtn(
        icon
        left
        :ripple="!isTouchDevice"
        @click="close"
      )
        VIcon(small) mdi-close
    VProgressLinear(
      v-if="progress !== Infinity"
      :value="progress"
      :height="4"
      :color="progress < 100 ? progressColor : 'success'"
    )
</template>

<style lang="stylus">
.ZStatusSnackbar
  &.ZStatusSnackbar--light
    color: $colors.grey.darken-4

  .ZStatusSnackbar__contentRight
    display flex

    .ZStatusSnackbar__action
      margin-right -8px

  .v-snack__content
    position relative

    .v-btn:first-of-type
      margin-left 0

    .v-btn
      color currentColor

  .v-snack__wrapper
    max-width none

  .v-progress-circular
    margin-right 12px
    margin-left -8px

  .v-progress-linear
    position absolute
    bottom -14px
    left 0

.ZStatusSnackbar__contentLeft__icon
  margin-right 8px
</style>

<script setup lang="ts">
import Color from 'color';

import theme from '@/themes';
import { check } from '@/lib/utils';

import ZSwipe from '@/components/ui/molecules/ZSwipe.vue';

export interface ZStatusSnackbarProps {
  /**
   * Whether the snackbar is visible.
   */
  value: boolean;
  /**
   * The icon to display.
   */
  icon?: string;
  /**
   * The content to display.
   */
  content?: string;
  /**
   * The progress value.
   */
  progress?: number;
  /**
   * The color of the snackbar.
   */
  color?: string;
  /**
   * The color of the progress bar.
   */
  progressColor?: string;
  /**
   * The text of the action button.
   */
  actionText?: string;
  /**
   * The function to call when the action button is clicked.
   */
  actionFunction?: () => void;
}

const props = withDefaults(defineProps<ZStatusSnackbarProps>(), {
  icon: '',
  content: '',
  progress: Infinity,
  color: '',
  progressColor: 'primary',
  actionText: '',
  actionFunction: () => {},
});

const emit = defineEmits<{
  (name: 'update:value', value: boolean): void;
}>();

const showCount = ref(0);
const showCountInterval = ref<NodeJS.Timeout>();
const minDelayInMs = ref(1500);
const throttledShow = ref(false);
const isTouchDevice = computed(() => check.hasTouch());

const show = computed({
  get() {
    return throttledShow.value;
  },
  set(value) {
    if (value) {
      throttledShow.value = value;
      showCount.value = 0;
      if (showCountInterval.value) clearInterval(showCountInterval.value);
      showCountInterval.value = setInterval(() => {
        showCount.value++;
        showCountIntervalCallback();
      }, 100);
    } else {
      showCountIntervalCallback();
    }
  },
});

const isLight = computed(() => {
  const color = Color(theme[props.color]);
  const brightness =
    (color.red() * 299 + color.green() * 587 + color.blue() * 114) / 1000;
  return theme[props.color] && brightness > 140;
});

function close(): void {
  if (showCountInterval.value) clearInterval(showCountInterval.value);
  throttledShow.value = false;
  emit('update:value', false);
}

function showCountIntervalCallback(): void {
  if (showCount.value >= minDelayInMs.value / 100 && !props.value) {
    close();
  }
}

watch(
  () => props.value,
  () => {
    show.value = props.value;
  }
);

onMounted(() => {
  show.value = props.value;
  throttledShow.value = props.value;
});
</script>
