import {
  BikeStateSource,
  LockSource,
  OfferType,
  Risk,
  SuspiciousReason,
  TripRate,
  TripStatus,
  TripType,
} from '#/core-api/enums';
import { tripsExtraPropertiesFiltersConfig } from '#/filters/extra-properties';

import store from '@/store';
import {
  tripStatus,
  tripType,
  tripRate,
  tripError,
  tripLockSource,
  controlForceEndTripErrorCodeList,
  mobileAppsForceEndTripErrorCodeList,
  backendForceEndTripErrorCodeList,
  bikeForceEndTripErrorCodeList,
  tripProductError,
  tripSuspiciousReasons,
  tripRisk,
} from '@/models/trip/mappers/display';
import { TripProductError } from '@/models/trip/enums';
import { productType } from '@/models/shared/helpers/product';
import { vehicleLockedSource } from '@/models/vehicle/mappers/display';
import { offerType } from '@/models/offer/mappers/display';
import { serialNumberRule, idRule, versionRule } from '@/lib/helpers/rules';
import {
  getAreaFilterConfig,
  getPickupDepositFilters,
} from '@/models/shared/filters';
import { ProductVersion } from '@/enums/product';
import { useI18n } from '@/composables/plugins';
import {
  benefitIcon,
  offerIcon,
  rentalIcon,
  roleIcon,
  userIcon,
} from '@/config/icons';

import type { GetFiltersParams } from '@/composables';

const { t, tin } = useI18n();

type ErrorCodeEntry =
  | {
      key: string;
      value: number;
    }
  | {
      category: string;
      selectable: true;
    };

function getErrorCodeEntry(code: number): ErrorCodeEntry {
  return {
    key: `${code} – ${tin(tripError(code).message)}`,
    value: code,
  };
}

type ExtraKey = 'user_email' | 'with_errors';

export type TripFilter = ModelFilter<'rental.Trip'> | FilterConfig<ExtraKey>;

export function getStaticFilters(): TripFilter[] {
  return [
    {
      key: 'id',
      type: String,
      multiple: true,
      rules: [idRule],
      exactMatch: true,
    },
    {
      key: 'type',
      type: Number,
      predefined: [TripType.Sharing, TripType.Leasing].map(value => ({
        key: tripType(value).message,
        value,
      })),
    },
    {
      key: 'status',
      type: Number,
      multiple: true,
      predefined: [
        TripStatus.Started,
        TripStatus.Ended,
        TripStatus.Paused,
        TripStatus.NeedsPayment,
        TripStatus.BillingPendingValidation,
      ].map(value => ({
        key: tripStatus(value).message,
        value,
      })),
    },
    {
      key: 'user_id',
      type: String,
      icon: userIcon,
      multiple: true,
      empty: true,
      rules: [idRule],
    },
    {
      key: 'rental_id',
      type: String,
      icon: rentalIcon,
      multiple: true,
      empty: true,
      exactMatch: true,
      rules: [idRule],
    },
    {
      key: 'user_email',
      text: 'user.email',
      icon: userIcon,
      permission: ['gateway.user.list', 'gateway.user.read.gdpr_fields'],
      type: String,
      multiple: true,
      populate: {
        actionType: 'GET_USERS',
        queryField: 'email',
        responseField: { key: 'id', as: 'user_id' },
        type: String,
      },
    },
    {
      key: 'vehicle_id',
      type: String,
      empty: true,
      multiple: true,
      rules: [serialNumberRule],
    },

    {
      key: 'rate',
      type: Number,
      empty: true,
      multiple: true,
      predefined: [TripRate.Bad, TripRate.Ok, TripRate.Top].map(value => ({
        key: tripRate(value).message,
        value,
      })),
    },
    {
      key: 'soft_unlock',
      type: Boolean,
    },
    {
      key: 'bill.total.amount',
      text: 'bill.total',
      type: Number,
      operators: true,
      isCurrency: true,
      factor: 100,
      empty: true,
      label: t('app__amount'),
    },
    {
      key: 'calories_burned',
      type: Number,
      operators: true,
      defaultUnit: 'cal',
      baseUnit: 'cal',
      empty: true,
    },
    {
      key: 'carbon_footprint',
      type: Number,
      operators: true,
      units: ['g', 'kg'],
      defaultUnit: 'g',
      baseUnit: 'g',
      empty: true,
    },
    {
      key: 'duration',
      type: Number,
      operators: true,
      units: ['s', 'min', 'h'],
      defaultUnit: 'min',
      baseUnit: 'ns',
      empty: true,
    },
    {
      key: 'distance',
      type: Number,
      operators: true,
      units: ['m', 'km'],
      defaultUnit: 'm',
      baseUnit: 'm',
      empty: true,
    },
    {
      key: 'bike_serial_number',
      type: Number,
      empty: true,
      multiple: true,
      rules: [serialNumberRule],
    },
    {
      key: 'estimation_id',
      type: String,
      multiple: true,
      empty: true,
    },
    {
      key: 'booking_id',
      type: String,
      multiple: true,
      empty: true,
    },
    ...(getPickupDepositFilters('pickup') as TripFilter[]),
    ...(getPickupDepositFilters('deposit') as TripFilter[]),
    {
      key: 'errors.code',
      type: Number,
      multiple: true,
      empty: true,
      maxWidth: '520px',
      predefined: [
        {
          category: t('trips__force_end_error_category__mobile_apps'),
          selectable: true,
        },
        ...mobileAppsForceEndTripErrorCodeList.map(getErrorCodeEntry),
        {
          category: t('trips__force_end_error_category__backend'),
          selectable: true,
        },
        ...backendForceEndTripErrorCodeList.map(getErrorCodeEntry),
        {
          category: t('trips__force_end_error_category__control'),
          selectable: true,
        },
        ...controlForceEndTripErrorCodeList.map(getErrorCodeEntry),
        {
          category: t('trips__force_end_error_category__bike'),
          selectable: true,
        },
        ...bikeForceEndTripErrorCodeList.map(getErrorCodeEntry),
      ],
    } satisfies NumberFilterConfig<
      ModelField<'rental.Trip'>,
      'multiple',
      'empty'
    >,
    {
      key: 'metadata.bike_out_of_order',
      type: String,
      predefined: [
        {
          key: 'trips__filter_bike_out_of_order',
          value: 'true',
        },
        {
          key: 'trips__filter_bike_in_order',
          value: 'false',
        },
      ],
    },
    {
      key: 'metadata.max_speed',
      type: Number,
      label: 'trips__filter_max_speed_label',
      empty: true,
      operators: true,
      buildQuery: filter => {
        const { operator, negation, config, value } = filter;

        let op = operator;
        if (!op) return;

        // case of equality, we can compare as strings directly
        if (op === '$eq') {
          // invert operator regarding negation
          if (negation) op = '$ne';
          return { [config.key]: { [op]: String(value) } };
        }
        // case of inequalities, we need to cast data into number
        else {
          // invert operator regarding negation
          if (negation) {
            if (op === '$gte') op = '$lt';
            else if (op === '$lte') op = '$gt';
          }
          return {
            $and: [
              { [config.key]: { $exists: 1 } },
              {
                $expr: {
                  [op]: [{ $toDouble: '$' + config.key }, Number(value)],
                },
              },
            ],
          };
        }
      },
    } satisfies NumberFilterConfig<ModelField<'rental.Trip'>, false, 'empty'>,
    {
      key: 'metadata.env',
      type: String,
      multiple: true,
      empty: true,
    },
    {
      key: 'metadata.bike_firmware_version',
      type: String,
      multiple: true,
      empty: true,
      label: '<major> . <minor> . <bugfix>',
      rules: [versionRule],
    },
    {
      key: 'metadata.bike_product_version_id',
      type: String,
      multiple: true,
      empty: true,
      predefined: [
        ProductVersion.Zoov02,
        ProductVersion.Zoov03,
        ProductVersion.Proto,
      ].map(value => ({
        key: productType(value).message,
        value: String(value),
      })),
    },
    {
      key: 'metadata.device_app_environment',
      type: String,
      multiple: true,
      empty: true,
      predefined: ['debug', 'alpha', 'beta', 'prod'].map(appEnv => ({
        key: appEnv,
        value: appEnv,
      })),
    },
    {
      key: 'metadata.device_app_version',
      type: String,
      multiple: true,
      empty: true,
      label: '<major> . <minor> . <bugfix>',
      rules: [versionRule],
    },
    {
      key: 'metadata.device_manufacturer',
      type: String,
      multiple: true,
      empty: true,
    },
    {
      key: 'metadata.device_model',
      type: String,
      multiple: true,
      empty: true,
    },
    {
      key: 'metadata.device_os',
      type: String,
      multiple: true,
      empty: true,
    },
    {
      key: 'metadata.device_os_version',
      type: String,
      multiple: true,
      empty: true,
      label: '<major> . <minor> . <bugfix>',
      rules: [versionRule],
    },
    {
      key: 'last_end_verify_error.last_end_verify_error_code',
      type: Number,
      multiple: true,
      empty: true,
    },
    {
      key: 'last_product_error.last_product_error_code',
      type: Number,
      multiple: true,
      empty: true,
      predefined: [
        TripProductError.StackedMaintenanceButNoMaintenanceRequired,
        TripProductError.StackedNormalButMaintenanceRequired,
        TripProductError.StackedFosBikeAlone,
        TripProductError.StackedUnknownError,
        TripProductError.StackLimitReached,
        TripProductError.HeavyLockUnlockImpossible,
        TripProductError.HeavyLockLockImpossible,
      ].map(code => ({
        key: `${code} <small><em>(${
          tripProductError(code).message
        })</em></small>`,
        value: code,
      })),
    },
    {
      key: 'errors.message',
      type: String,
      multiple: true,
      empty: true,
    },
    {
      key: 'with_errors',
      text: 'trips__filter_with_errors',
      type: Boolean,
      buildQuery: filter => {
        const { value } = filter;
        if (!value) return {};

        return { errors: { $exists: ~~value } };
      },
    } satisfies BooleanFilterConfig<ExtraKey>,
    {
      key: 'support_info.category',
      type: String,
      multiple: true,
      empty: true,
    },
    {
      key: 'support_info.tags',
      type: String,
      multiple: true,
      empty: true,
    },
    {
      key: 'started_at',
      type: Date,
    },
    {
      key: 'ended_at',
      type: Date,
    },
    {
      key: '_created',
      type: Date,
    },
    {
      key: 'out_of_order_declared_at',
      type: Date,
    },
    {
      key: 'pause_until',
      type: Date,
      allDates: true,
    },
    {
      key: 'lock_source',
      type: Number,
      multiple: true,
      predefined: [
        BikeStateSource.SourceUnknown,
        BikeStateSource.SourceBle,
        BikeStateSource.SourceLwm2M,
        BikeStateSource.SourceAutolockTimeout,
        BikeStateSource.SourceAutolockStack,
        BikeStateSource.SourceRfid,
        BikeStateSource.SourceAutolockStackMaintenance,
        BikeStateSource.SourcePauseInactivity,
        BikeStateSource.SourceAutolockStackFallback,
        BikeStateSource.SourceAutolockDocked,
      ].map(value => ({
        key: tripLockSource(value).message,
        value,
      })),
    },
    {
      key: 'vehicle_lock_source',
      type: Number,
      multiple: true,
      selectAll: true,
      predefined: [
        LockSource.Bluetooth,
        LockSource.Lte,
        LockSource.AutolockTimeout,
        LockSource.AutolockStack,
        LockSource.RfidBypass,
        LockSource.AutolockStackMaintenance,
        LockSource.PauseInactivity,
        LockSource.AutolockStackFallback,
        LockSource.AutolockDocked,
        LockSource.HeavyLockFailure,
        LockSource.Rfid,
        LockSource.AutolockNotUnstacked,
        LockSource.Manual,
      ].map(value => ({
        key: vehicleLockedSource(value).message,
        color: vehicleLockedSource(value).class,
        icon: vehicleLockedSource(value).icon,
        value,
      })),
    },
    {
      key: 'geolocation_opt_in',
      type: Boolean,
    },
    {
      key: 'geolocation_info_cleared_at',
      type: Date,
    },
    {
      key: 'risk',
      type: Number,
      multiple: true,
      selectAll: true,
      predefined: [
        Risk.Suspicious,
        Risk.SuspiciousUnchecked,
        Risk.Cleared,
        Risk.Malicious,
        Risk.UnderInvestigation,
      ].map(value => ({
        key: tripRisk(value).message,
        value,
      })),
    },
    {
      key: 'suspicious_reasons',
      type: Number,
      multiple: true,
      selectAll: true,
      predefined: [
        SuspiciousReason.MaxDuration,
        SuspiciousReason.TripErrors,
        SuspiciousReason.LockSources,
        SuspiciousReason.MaxDistance,
        SuspiciousReason.EndOutsideStation,
      ].map(value => ({
        key: tripSuspiciousReasons(value).message,
        value,
      })),
    },
    ...tripsExtraPropertiesFiltersConfig,
  ];
}

export async function getAsyncFilters({
  privileges,
  preferences,
}: GetFiltersParams): Promise<TripFilter[]> {
  return Promise.all([
    getAreaFilterConfig<ModelField<'rental.Trip'>, true>({
      key: 'area_id',
      empty: true,
    }),
    ...(await getRolesFilters({ preferences, privileges })),
    ...(await getOffersFilters()),
  ]);
}

async function getRolesFilters({
  privileges,
}: GetFiltersParams): Promise<TripFilter[]> {
  if (privileges && privileges.isGranted('gateway.user.role.list')) {
    return [];
  }

  const [error, allRoles] = await to(
    store.cache.dispatch('GET_ROLES_ALL', { scope: 'App' })
  );

  if (error) {
    return [];
  }

  return [
    {
      key: 'metadata.required_role',
      type: String,
      label: 'Roles',
      icon: roleIcon,
      autocomplete: true,
      exactMatch: true,
      multiple: true,
      empty: true,
      predefined: (allRoles ?? []).map(({ name }) => ({
        key: name ?? '',
        value: name ?? '',
      })),
    } satisfies StringFilterConfig<
      ModelField<'rental.Trip'>,
      'multiple',
      'empty'
    >,
    {
      key: 'metadata.user_roles',
      type: String,
      label: 'Roles',
      icon: roleIcon,
      autocomplete: true,
      exactMatch: false,
      multiple: true,
      empty: true,
      predefined: (allRoles ?? []).map(({ name }) => ({
        key: name ?? '',
        value: name ?? '',
      })),
    } satisfies StringFilterConfig<
      ModelField<'rental.Trip'>,
      'multiple',
      'empty'
    >,
  ];
}

async function getOffersFilters(): Promise<TripFilter[]> {
  const [error, allOffers] = await to(
    store.cache.dispatch('GET_OFFERS_ALL', { scope: 'App' })
  );

  if (error) return [];

  let previousOfferType: OfferType | undefined;

  const sortedAndCategorizedOfferItems = (allOffers ?? [])
    .sort((offerA, offerB) => {
      const textA = offerA.code ?? offerA.name;
      const textB = offerB.code ?? offerB.name;
      if (!textA || !textB) return 0;

      if (textA > textB) return 1;
      else if (textA < textB) return -1;
      else return 0;
    })
    .sort((offerA, offerB) => {
      if (!offerA.type || !offerB.type) return 0;
      if (offerA.type > offerB.type) return 1;
      else if (offerA.type < offerB.type) return -1;
      else return 0;
    })
    .map(({ id, code, name, type }) => {
      if (previousOfferType !== type) {
        previousOfferType = type;
        return {
          category: offerType(type).message,
          selectable: true,
          type,
        };
      }
      return {
        key: name + (code ? ` (${code})` : ''),
        value: id,
        type,
      };
    });

  return [
    {
      key: 'bill.pricing.offer_id',
      text: 'bill.pricing',
      type: String,
      icon: offerIcon,
      autocomplete: true,
      exactMatch: true,
      multiple: true,
      predefined: sortedAndCategorizedOfferItems.filter(
        ({ type }) =>
          type === OfferType.SharingPricing || type === OfferType.LeasingPricing
      ),
    } satisfies StringFilterConfig<ModelField<'rental.Trip'>, 'multiple'>,
    {
      key: 'bill.discounts.offer_id',
      text: 'bill.discounts',
      icon: benefitIcon,
      type: String,
      autocomplete: true,
      exactMatch: true,
      multiple: true,
      empty: true,
      predefined: sortedAndCategorizedOfferItems.filter(
        ({ type }) => type === OfferType.Discount
      ),
    } satisfies StringFilterConfig<
      ModelField<'rental.Trip'>,
      'multiple',
      'empty'
    >,
  ];
}
