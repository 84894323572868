<template lang="pug">
.ZSwipe(
  ref="swipeRef"
  :class="swipeClasses"
  :style="swipeStyles"
)
  slot
</template>

<style lang="stylus">
.ZSwipe
  position relative
  display flex
  align-items center
  z-index 300
  left 0
  right 0

  &--fixed
    position fixed
</style>

<script setup lang="ts">
const swipeRef = ref<HTMLElement | null>(null);

export interface ZSwipeProps {
  /**
   * Allowed directions for swipe
   */
  allowedDirections?: 'x' | 'y' | 'xy';
  /**
   * The minimum swipe distance in px for the swipe to be considered
   */
  minDistance?: number;
  /**
   * Whether the swipe should be fixed or relative
   */
  isFixed?: boolean;
}

const props = withDefaults(defineProps<ZSwipeProps>(), {
  allowedDirections: 'xy',
  minDistance: 25,
  isFixed: false,
});

const emit = defineEmits<{
  (name: 'throwout'): void;
}>();

const isHorizontalSwipeAllowed = computed(() => {
  return props.allowedDirections === 'x' || props.allowedDirections === 'xy';
});

const isVerticalSwipeAllowed = computed(() => {
  return props.allowedDirections === 'y' || props.allowedDirections === 'xy';
});

const translateX = ref(0);
const translateY = ref(0);

const { lengthX, lengthY } = useSwipe(swipeRef, {
  passive: true,
  threshold: 0,
  onSwipe() {
    translateX.value = isHorizontalSwipeAllowed.value ? lengthX.value * -1 : 0;
    translateY.value = isVerticalSwipeAllowed.value ? lengthY.value : 0;
  },
  onSwipeEnd() {
    if (
      (Math.abs(lengthX.value) >= props.minDistance &&
        isHorizontalSwipeAllowed.value) ||
      (Math.abs(lengthY.value) >= props.minDistance &&
        isVerticalSwipeAllowed.value)
    ) {
      emit('throwout');
    }

    // Reset the transformation after swipe ends, after waiting for a possible animation to finish
    setTimeout(() => {
      translateX.value = 0;
      translateY.value = 0;
    }, 1000);
  },
});

const swipeStyles = computed(() => {
  return {
    transform: `translate(${translateX.value}px, ${translateY.value}px)`,
  };
});

const swipeClasses = computed(() => {
  return {
    'ZSwipe--fixed': props.isFixed,
  };
});
</script>
