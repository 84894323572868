<template lang="pug">
.ZAppLogo(
  :style="style"
  v-html="logoSvg"
)
</template>

<style lang="stylus">
.ZAppLogo
  display block
  width var(--ZAppLogo--width)
  height auto

  svg
    fill var(--ZAppLogo--fill)
</style>

<script setup lang="ts">
import logoLightSvg from '@/assets/img/vectors/app-logo-light.svg';
import logoDarkSvg from '@/assets/img/vectors/app-logo.svg';
import theme from '@/themes';

export interface ZAppLogoProps {
  /**
   * Width of the logo in pixels
   */
  width?: number;
  /**
   * Override the color of "Fifteen" text in the logo
   */
  color?: string | null;
  /**
   * Variant used
   */
  variant?: 'light' | 'dark';
}

const props = withDefaults(defineProps<ZAppLogoProps>(), {
  width: 60,
  color: null,
  variant: 'light',
});

const logoColor = computed(
  () => props.color || (props.variant === 'light' ? '' : theme.fifteen_control)
);

const logoSvg = computed(() =>
  props.variant === 'light' ? logoLightSvg : logoDarkSvg
);

const style = computed(() => ({
  '--ZAppLogo--width': `${props.width}px`,
  '--ZAppLogo--fill': logoColor.value,
}));
</script>
