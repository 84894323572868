/*
 * Simple JWT parser.
 * https://stackoverflow.com/questions/38552003/how-to-decode-jwt-token-in-javascript
 */
export default function parseJwt<T extends object>(token: string): T {
  if (!token) return {} as T;
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  try {
    const data = JSON.parse(atob(base64)) as T;
    return data;
  } catch (e) {
    return {} as T;
  }
}
