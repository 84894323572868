export const fields = [
  'id',
  'selector_ids',
  'type',
  'status',
  'target_model',
  'target_id',
  'content',
  'label',
  'category_ids',
  'extra_properties',
  'severity',
  'updated_by',
  'updated_at',
  'created_at',
  'created_by',
] satisfies ModelField<'ticket.Ticket'>[];
