export interface UseDrawControlsReturn {
  /**
   * Shared ref to the draw controls instance
   */
  drawControls: Ref<MapboxDraw | null>;
}

const drawControls = ref<MapboxDraw | null>(null);

export function useDrawControls(): UseDrawControlsReturn {
  return {
    drawControls
  };
}
