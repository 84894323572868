import { usePreferences } from '@/composables/plugins';

export interface UseDarkThemeReturn {
  /**
   * Whether theme is dark
   */
  isDark: ComputedRef<boolean>;
  /**
   * Vuetify global theme class
   */
  themeClass: ComputedRef<string>;
}

/**
 * Retrieves whether theme is dark
 */
export function useDarkTheme(): UseDarkThemeReturn {
  const { preferences } = usePreferences();
  const isDark = computed(() => preferences.themeDark);
  const themeClass = computed(
    () => `theme--${isDark.value ? 'dark' : 'light'}`
  );

  return {
    isDark,
    themeClass,
  };
}
