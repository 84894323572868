/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { isCancel as _isCancel } from 'axios';
import merge from 'deepmerge';
import * as Sentry from '@sentry/vue';
import { ApiErrorCode } from '@fifteen/sdk';

import { DatacenterErrorCode, AppErrorCode } from '@/enums/errors';
import { useNotification } from '@/composables';
import AppError from '@/lib/classes/app-error';
import { paramsSerializer } from '@/lib/utils';

import type { AxiosRequestConfig, AxiosError, AxiosResponse } from 'axios';

/**
 * We define this helper so that it explicitly returns a boolean instead of `value is Cancel` defined by
 * axios `isCancel`, which makes TS > 5.5 narrow too much the axiosError in else case below and resolved
 * it to `never` because it thinks that the error is always Cancel since it extends the Cancel interface.
 * @see https://github.com/axios/axios/issues/5153
 */
function isCancel(error: AxiosError): boolean {
  return _isCancel(error);
}

// eslint-disable-next-line import/no-named-as-default-member
const CancelToken = axios.CancelToken;
const service = 'modules/request';

export class RequestError extends Error {
  code?: number | string | null;
  detail?: string | null;
  status?: number | null;

  constructor(error: AxiosError<ApiErrorData>) {
    const errorData = error.response?.data;
    const message = errorData?.message ?? error.message;
    super(message);

    if (errorData) {
      this.code = errorData?.code;
      this.detail = 'detail' in errorData ? errorData?.detail : null;
    } else {
      this.code = error.code;
      this.detail = null;
    }
    this.status = error.response?.status ?? null;
  }
}

export type RequestConfig = Pick<
  AxiosRequestConfig,
  'timeout' | 'params' | 'headers' | 'auth'
> & {
  /**
   * Endpoint URL
   */
  endpoint: string;
  /**
   * Log errors to console
   * @default false
   */
  logErrors?: boolean;
};

interface ExtendedAxiosRequestConfig extends AxiosRequestConfig {
  /**
   * Metadata sent to the Sentry error tracker
   */
  metadata?: Record<string, string>;
}

type ExtendedAxiosResponse<T = any> = Omit<AxiosResponse<T>, 'config'> & {
  config: ExtendedAxiosRequestConfig;
};

interface RequestResponse<T = any> {
  error: AxiosError<ApiErrorData | string> | null;
  response: ExtendedAxiosResponse<T> | undefined;
  options: ExtendedAxiosRequestConfig;
}

export type RequestOptions = Omit<
  AxiosRequestConfig,
  'url' | 'method' | 'data'
>;

type GetRequestFn = {
  <T = any>(url: string, options?: RequestOptions): Promise<T | undefined>;
};
type UpdateRequestFn = {
  <T = any>(
    url: string,
    data: AxiosRequestConfig['data'],
    options?: RequestOptions
  ): Promise<T | undefined>;
};

export interface UseRequestReturn {
  get: GetRequestFn;
  post: UpdateRequestFn;
  patch: UpdateRequestFn;
  put: UpdateRequestFn;
  delete: UpdateRequestFn;
  cancel: Ref<((message?: string) => void) | null>;
}

/**
 * Axios wrapper that exposes a request instance of axios with checks on the request
 * and the response to add error handling and logging, providing simplified interface
 */
export function useRequest(apiConfig: RequestConfig): UseRequestReturn {
  const { notify } = useNotification();

  const config: AxiosRequestConfig = {
    baseURL: apiConfig.endpoint,
    timeout: apiConfig.timeout,
    params: apiConfig.params,
    headers: {
      'Content-Type': 'application/json',
      ...(apiConfig.headers || {}),
    },
    paramsSerializer,
  };
  let logErrors: boolean;

  const axiosInstance = axios.create();

  const cancel = ref<((message?: string) => void) | null>(null);

  async function send<T = any>({
    url,
    method,
    data,
    options,
  }: {
    /**
     * Axios request 'url' option, corresponding to the API path relative to the baseURL
     */
    url: AxiosRequestConfig['url'];
    /**
     * Axios request 'method' option
     */
    method: AxiosRequestConfig['method'];
    /**
     * Axios request 'data' option, corresponding to the request body
     */
    data?: AxiosRequestConfig['data'];
    /**
     * Additional request options
     */
    options?: RequestOptions;
  }): Promise<T | undefined> {
    // we merge options ourselves because axios is not working properly with default options
    const mergedOptions: ExtendedAxiosRequestConfig = merge(
      config,
      options || {}
    );

    // Set other options
    mergedOptions.url = url;
    mergedOptions.method = method;
    if (data) mergedOptions.data = data;

    // Create a cancel token
    mergedOptions.cancelToken = new CancelToken(cancelFunction => {
      cancel.value = message => cancelFunction(message);
    });

    return handleResponse(await _request(mergedOptions));
  }

  async function _request(
    options: ExtendedAxiosRequestConfig
  ): Promise<RequestResponse> {
    const [error, response] = await to<
      ExtendedAxiosResponse,
      AxiosError<ApiErrorData>
    >(axiosInstance.request(options));
    return { error, response, options };
  }

  async function get<T = any>(
    url: string,
    options?: RequestOptions
  ): Promise<T | undefined> {
    return send<T>({ url, method: 'GET', options });
  }

  async function post<T = any>(
    url: string,
    data: AxiosRequestConfig['data'],
    options?: RequestOptions
  ): Promise<T | undefined> {
    return send<T>({ url, method: 'POST', data, options });
  }

  async function patch<T = any>(
    url: string,
    data: AxiosRequestConfig['data'],
    options?: RequestOptions
  ): Promise<T | undefined> {
    return send<T>({ url, method: 'PATCH', data, options });
  }

  async function put<T = any>(
    url: string,
    data: AxiosRequestConfig['data'],
    options?: RequestOptions
  ): Promise<T | undefined> {
    return send<T>({ url, method: 'PUT', data, options });
  }

  async function _delete<T = any>(
    url: string,
    data: AxiosRequestConfig['data'],
    options?: RequestOptions
  ): Promise<T | undefined> {
    return send<T>({ url, method: 'DELETE', data, options });
  }

  /**
   * Handle potential errors of an axios request, or return the response data
   * @param requestResponse - Request response
   * @throws {AxiosError<ApiErrorData> | AppError}
   */

  function handleResponse<T = any>({
    error: axiosError,
    response,
    options,
  }: RequestResponse<T>): T | { silentCancel: true } {
    // The error sent to notification plugin and logger plugin
    // and thrown if it is an AppError
    let error: AxiosError<ApiErrorData> | ApiErrorData | AppError;

    // The arguments sent to notification plugin (used to display the pertinent error object)
    let notificationArguments: object[] | undefined;

    // server responded with an HTTP error
    if (axiosError) {
      const errorData = axiosError.response?.data;
      // handle the case of axios cancel
      if (isCancel(axiosError)) {
        // if message is 'silent', do not notify error, do not throw error
        if (axiosError.message === 'silent') {
          return { silentCancel: true };
        } else {
          error = new AppError(AppErrorCode.RequestManuallyCancelled);
        }
      } else {
        error =
          typeof errorData === 'string'
            ? new AppError(AppErrorCode.RequestErrorStringType, {
                args: { errorData },
              })
            : (errorData ??
              (axiosError.request
                ? new AppError(AppErrorCode.RequestNoResponseReceived)
                : new AppError(AppErrorCode.RequestSetup)));

        notificationArguments = axiosError.response
          ? [axiosError.response]
          : axiosError.request
            ? [options]
            : [error];
      }

      const isTokenExpiry =
        typeof errorData !== 'string' &&
        (errorData?.code === ApiErrorCode.AuthInvalidToken ||
          errorData?.code === DatacenterErrorCode.InvalidToken);

      const type = isTokenExpiry ? 'expiry' : 'error';

      if (isTokenExpiry) notificationArguments = undefined;

      // Log error unless it is a token expiry, if we need to.
      if (logErrors && !isTokenExpiry) console.error(error.message, error);

      notify({
        type,
        error,
        service,
        message: error.message,
        arguments: notificationArguments,
        debug: !isTokenExpiry,
        // if it is a token expiry, do not log a new error
        new: !isTokenExpiry,
      });

      // do not send request error to tracker (to save quota)
      // and throw error
      if (error instanceof AppError) throw error;
      throw new RequestError(axiosError as AxiosError<ApiErrorData>);
    } else {
      if (response?.data === undefined) {
        error = new AppError(AppErrorCode.RequestDataUndefinedInResponse);

        if (logErrors) console.error(error.message, error);

        notify({
          type: 'error',
          error,
          service,
          message: error.message,
          arguments: [response],
        });

        // send request error to tracker
        Sentry.withScope(scope => {
          scope.setContext('request', {
            metadata: options.metadata,
          });
          Sentry.captureException(error);
        });
        throw error;
      } else {
        return response.data;
      }
    }
  }

  return {
    get,
    post,
    patch,
    put,
    delete: _delete,
    cancel,
  };
}
