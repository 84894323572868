import { toValue } from '@vueuse/core';
import { isNullish } from '@fifteen/shared-lib';

import { createFormatFields } from './createFormatFields';
import { isDisplayFieldKeyMatchingField } from './helpers';

import { useFieldPermissions, useAuth } from '@/composables';
import { useI18n, usePreferences, usePrivileges } from '@/composables/plugins';

import type { MaybeRefOrGetter } from '@vueuse/core';

export interface UseDisplayFieldsReturn {
  /**
   * The formatted display fields
   */
  displayFields: Ref<DisplayField[]>;
  /**
   * Format data so that it is more human readable when used with the component ZDisplayField
   * @param formatOptions - Format options
   * @returns The formatted display fields
   */
  format: (data: object, options?: DisplayFieldOptions) => DisplayField[];
}

export function useDisplayFields(
  options?: MaybeRefOrGetter<DisplayFieldOptions>,
  data?: MaybeRefOrGetter<object>
): UseDisplayFieldsReturn {
  const _data = data;
  const _options = options ?? {};

  const { isGranted } = usePrivileges();
  const { userId } = useAuth();
  const { preferences } = usePreferences();
  const i18n = useI18n();
  const { checkPermission } = useFieldPermissions('user');

  const displayFields = shallowRef<DisplayField[]>([]);

  const userPrivileges = {
    canOpenUserPage: isGranted('admin.route.user') ?? false,
    canReadBikeBatteryCommunityFull:
      isGranted('gateway.bike.read.battery_community.all') ?? false,
    canReadGdprFields: isGranted('gateway.user.read.gdpr_fields') ?? false,
  };

  // When locale change, formatted values change aswell and need to be updated
  watchImmediate([() => toValue(data), () => i18n.locale], () => {
    displayFields.value = format();
  });

  /**
   * Format data so that it is more human readable when used with the component ZDisplayField
   * @param formatOptions - Format options
   */
  function format(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data?: any,
    options?: DisplayFieldOptions
  ): DisplayField[] {
    const resolvedOptions = options ?? toValue(_options) ?? {};
    const {
      fields: _fields,
      ignoreKeysCleaning,
      omitFields = [],
    } = resolvedOptions;

    const dataToFormat = data ?? toValue(_data) ?? {};

    const { formatField } = createFormatFields({
      formatOptions: resolvedOptions,
      userId: userId.value,
      preferences,
      userPrivileges,
      i18n,
      format,
    });

    const fields = _fields?.length ? _fields : Object.keys(dataToFormat);

    const keptFields = fields
      .map(field => (field.includes('.') ? field.split('.')[1] : field))
      .filter(field => !isNullish(field) && !omitFields.includes(field));

    const formattableFields = [
      ...new Set(fields.map(field => field.split('.')[0])),
    ].filter(field => !isNullish(field) && field !== '');

    const _displayFields = (formattableFields ?? [])
      .filter(checkPermission)
      .flatMap(field =>
        formatField({
          key: field,
          object: dataToFormat,
          historyOptions: resolvedOptions.historyOptions,
        })
      );

    const sortedDisplayFields = ignoreKeysCleaning
      ? _displayFields
      : _displayFields
          .filter(displayField =>
            keptFields.some(
              field =>
                isDisplayFieldKeyMatchingField(displayField, field) ||
                displayField.key === '__classes'
            )
          )
          // Sort to ensure that display fields respect `keptFields` order
          .sort((a, b) => {
            const aIndex =
              keptFields.findIndex(field =>
                isDisplayFieldKeyMatchingField(a, field)
              ) ?? 0;
            const bIndex =
              keptFields.findIndex(field =>
                isDisplayFieldKeyMatchingField(b, field)
              ) ?? 0;
            return aIndex - bIndex;
          });

    displayFields.value = sortedDisplayFields;
    return sortedDisplayFields;
  }

  return { displayFields, format };
}
