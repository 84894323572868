type JoinPropsArg = string | number | boolean | null | undefined;

export default function join(...args: JoinPropsArg[]): string {
  return args
    .slice()
    .map((value: JoinPropsArg) =>
      typeof value === 'number' ? String(value) : value
    )
    .filter(
      (value: JoinPropsArg) =>
        (typeof value !== 'number' &&
          ![null, undefined, '', false].includes(value)) ||
        value === 0
    )
    .join('.');
}
