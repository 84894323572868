/**
 * Capitalize the first letter of a text
 * @param text - The text to capitalize
 *
 * @example
 * capitalize('my name is toto') // => 'My name is toto'
 */
export default function capitalize<T extends string>(text: T): Capitalize<T> {
  return (
    text && typeof text === 'string'
      ? text.charAt(0).toUpperCase() + text.slice(1)
      : text
  ) as Capitalize<T>;
}
