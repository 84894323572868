<template lang="pug">
.ZMultiInput(:class="{ 'ZMultiInput--dense': dense }")
  .ZMultiInput__label(
    v-if="label"
    :class="labelClasses"
  ) {{ label }}
  .ZMultiInput__header
    .ZMultiInput__keysList
      span.ZMultiInput__emptyText(v-if="!keys.length") {{ emptyText }}
      transition(name="z-fade")
        .ZMultiInput__tabs(v-if="!!keys.length")
          VTab.ZMultiInput__tab(
            v-for="key in keys"
            :key="key"
            :class="tabClasses(key)"
            @click="handleTabClick(key)"
          ) {{ key }}
      .ZMultiInput__addButtonContainer
        ZTooltip(
          left
          :disabled="disabled"
        )
          template(#activator)
            VBtn.ZMultiInput__addButton(
              icon
              :class="{ 'ZMultiInput__addButton--dense': dense }"
              :small="dense"
              :color="color"
              :disabled="disabled"
              @click="dialogOpen = true"
            )
              VIcon mdi-plus
          span {{ i18n ? t('app__multi_input_add_language') : t('app__multi_input_add', { label: computedKeyLabel }) }}
    slot(name="header-actions")
    VDialog(
      v-model="dialogOpen"
      :max-width="320"
    )
      VCard
        VCardText
          VForm(
            ref="newKeyFormRef"
            lazy-validation
            @submit.prevent
          )
            component(
              :is="computedKeyItems.length ? 'VAutocomplete' : 'VTextField'"
              ref="newKeyInputRef"
              :value="newKey"
              :label="capitalize(computedKeyLabel)"
              :color="color"
              :rules="keyRules"
              :items="computedKeyItems"
              validate-on-blur
              @input="handleInputKey"
              @keyup.enter="confirmAddKey"
            )
        VCardActions.ZMultiInput__dialog__actions
          VBtn(
            flat
            color="error"
            @click="cancelAddKey"
          ) {{ t('app__cancel') }}
          VBtn(
            color="accent"
            @click="confirmAddKey"
          ) {{ t('app__add') }}
  component.ZMultiInput__input(
    :is="textarea ? 'v-textarea' : 'v-text-field'"
    v-show="modelSelectedKey"
    ref="inputRef"
    v-model="inputValue"
    :color="color"
    :hide-details="hideDetails"
    :hint="hint"
    :messages="messages"
    :rules="rules"
    :autocapitalize="autocapitalize"
    :disabled="disabled"
    v-bind="textarea ? { rows, autoGrow, rowsHeight: 32 } : {}"
    validate-on-blur
  )
</template>

<style lang="stylus">
.ZMultiInput
  margin-top 4px

.ZMultiInput__header
  display flex
  align-items center
  margin-bottom 8px

.ZMultiInput__keysList
  position relative
  padding-right 48px
  min-height 48px
  height 100%
  display flex
  align-items center
  border-radius 16px
  flex-grow 1
  overflow hidden

  .v-tabs__wrapper
    border-radius 16px

.ZMultiInput__tabs
  min-height 48px
  height 100%

.ZMultiInput__tab
  height 48px

.ZMultiInput--dense
  .ZMultiInput__keysList,
  .ZMultiInput__tabs
    min-height 36px
    height 100%

  .ZMultiInput__tab
    height 36px

.ZMultiInput__keysList,
.ZMultiInput__tab--active
  .theme--dark &
    background-color rgba(255, 255, 255, 0.085)

  .theme--light &
    background-color rgba(0, 0, 0, 0.085)

.ZMultiInput__tabs .v-tabs__bar
  &.theme--dark,
  &.theme--light
    background-color transparent

.ZMultiInput__emptyText
  margin-left 12px

.ZMultiInput__tabs
  width 100%

.ZMultiInput__label
  font-size 12px
  margin-bottom 4px
  transition color 0.15s

.ZMultiInput__addButtonContainer
  position absolute
  right 0
  z-index 2
  top 0

.v-btn.ZMultiInput__addButton--dense
  margin 4px

.ZMultiInput__dialog__actions
  display flex
  justify-content flex-end
  flex-wrap wrap

  .theme--dark &
    background lighten($colors.grey.darken-3, 7%)

  .theme--light &
    background: $colors.grey.lighten-3

.ZMultiInput__input
  &.v-text-field
    margin-top 0
    padding-top 0

  .v-messages__message
    line-height 1.25
</style>

<script setup lang="ts">
import { set } from 'vue';

import { i18nConfig } from '@/config';
import { useI18n } from '@/composables/plugins';
import { capitalize, exclude } from '@/lib/utils';
import { useVModelProxy } from '@/composables';

export interface ZMultiInputProps {
  /**
   * The value of the multi-input
   * @model
   */
  value?: Record<string, string>;
  /**
   * The label of the multi-input
   */
  label?: string;
  /**
   * Whether the input is a textarea
   */
  textarea?: boolean;
  /**
   * The selected key
   * @sync
   */
  selectedKey?: string | null;
  /**
   * The label of the button to add a new key
   */
  keyLabel?: string | null;
  /**
   * The rules for the new key input
   */
  keyRules?: VuetifyRule[];
  /**
   * The list of predefined keys, making the input component an autocomplete if not empty
   */
  keyItems?: string[] | null;
  /**
   * A handler for the new key input that transforms the input key
   */
  keyHandler?: (key: string) => string;
  /**
   * The disabled state
   */
  disabled?: boolean;
  /**
   * The input color
   */
  color?: string;
  /**
   * Denser tabs
   */
  dense?: boolean;
  /**
   * The v-text-field or v-textarea hide-details prop
   */
  hideDetails?: boolean;
  /**
   * The v-text-field or v-textarea hint prop
   */
  hint?: string;
  /**
   * The v-text-field or v-textarea messages prop
   */
  messages?: string | string[];
  /**
   * The v-text-field or v-textarea rules prop
   */
  rules?: VuetifyRule[];
  /**
   * The input or textarea autocapitalize prop
   */
  autocapitalize?: string | null;
  /**
   * The textarea auto-grow prop
   */
  autoGrow?: boolean;
  /**
   * The textarea rows prop
   */
  rows?: number | string;
  /**
   * Autofocus the input
   */
  autofocus?: boolean;
  /**
   * I18n mode, set keyItems equal to all available locales, and set keyLabel to `t('app__language')`
   */
  i18n?: boolean;
}

const props = withDefaults(defineProps<ZMultiInputProps>(), {
  value: () => ({}),
  label: undefined,
  textarea: false,
  selectedKey: null,
  keyLabel: null,
  keyRules: () => [],
  keyItems: null,
  keyHandler: (value: string) => value,
  disabled: false,
  color: 'primary',
  dense: false,
  hideDetails: false,
  hint: undefined,
  messages: () => [],
  rules: () => [],
  autocapitalize: null,
  autoGrow: false,
  rows: 2,
  autofocus: false,
  i18n: false,
});

const emit = defineEmits<{
  (name: 'add-key', key: string): void;
  (name: 'input', value: { [key: string]: string }): void;
}>();

const { t } = useI18n();
const { locales } = i18nConfig;

const modelValue = useVModelProxy({ props });
const modelSelectedKey = useVModelProxy({ props, propName: 'selectedKey' });
const dialogOpen = ref(false);
const newKey = ref('');
const isMounted = ref(false);
const preventNextFocus = ref(false);

const inputRef = ref<TemplateRef | null>(null);
const newKeyFormRef = ref<TemplateRef | null>(null);
const newKeyInputRef = ref<TemplateRef | null>(null);

const keys = computed(() => Object.keys(modelValue.value).sort());
const isFocused = computed(() => isMounted.value && inputRef.value?.isFocused);

const isValid = computed(() => {
  if (!isMounted.value) {
    return true;
  }
  if (!inputRef.value?.hasFocused || inputRef.value?.isFocused) {
    return true;
  }
  return inputRef.value?.valid;
});

const labelClasses = computed(() => {
  const color = !isValid.value ? 'error' : isFocused.value ? props.color : '';
  return color + '--text';
});

const computedKeyLabel = computed(() => {
  return props.keyLabel || (props.i18n ? t('app__language') : t('app__key'));
});

const computedKeyItems = computed(() => {
  return exclude(props.keyItems || (props.i18n ? locales : []), keys.value);
});

const emptyText = computed(() => {
  return t('app__multi_input_no_value', {
    label: computedKeyLabel.value,
  });
});

function handleInputKey(value: string): void {
  newKey.value = props.keyHandler(value);
}

const inputValue = computed({
  get() {
    if (!modelSelectedKey.value) return '';
    return modelValue.value[modelSelectedKey.value];
  },
  set(value) {
    if (!modelSelectedKey.value) return;
    modelValue.value[modelSelectedKey.value] = value;
  },
});

watch(
  () => props.value,
  newValue => {
    modelValue.value = newValue;
  }
);

watch(
  () => props.selectedKey,
  newValue => {
    modelSelectedKey.value = newValue;
  }
);

watchImmediate(keys, newVal => {
  // select the first array key if nothing is selected yet
  if (!modelSelectedKey.value && newVal.length) {
    // do it the next tick for reactivity issues
    if (!props.autofocus) preventNextFocus.value = true;
    setTimeout(() => (modelSelectedKey.value = newVal[0]));
  }
});

watchDeep(modelValue, newVal => {
  emit('input', newVal);
});

watch(modelSelectedKey, () => {
  if (preventNextFocus.value) {
    preventNextFocus.value = false;
    return;
  }
  focus();
});

watch(dialogOpen, newVal => {
  if (newVal) focus();
});

onMounted(() => {
  isMounted.value = true;
});

function handleTabClick(key: string): void {
  modelSelectedKey.value = key;
  focus();
}

function cancelAddKey(): void {
  dialogOpen.value = false;
  newKey.value = '';
}

function confirmAddKey(): void {
  newKeyInputRef.value?.blur();
  if (newKeyFormRef.value?.validate()) {
    dialogOpen.value = false;
    const copy = modelValue.value;
    set(copy, newKey.value, '');
    modelValue.value = copy;
    modelSelectedKey.value = newKey.value;
    emit('add-key', newKey.value);
    newKey.value = '';
  }
}

function isValidValue(key: string | null): boolean {
  if (!key) return true;
  return props.rules.reduce(
    (valid, rule) => valid && rule(modelValue.value[key]) === true,
    true
  );
}

function tabClasses(key: string): Record<string, boolean> {
  return {
    'ZMultiInput__tab--active': key === modelSelectedKey.value,
    'ZMultiInput__tab--error': !isValidValue(key) && !inputRef.value?.isFocused,
  };
}

function focus(): void {
  inputRef.value?.focus?.();
}

function resetValidation(): void {
  inputRef.value?.resetValidation?.();
}

defineExpose({
  /**
   * Focus the input
   */
  focus,
  /**
   * Reset input validation
   */
  resetValidation,
});
</script>
