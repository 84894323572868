import type { CreateLayerOptions } from '@/models/area/map/types';

export function createLayers({
  color,
  opacity = 1,
  maxzoom,
  outlined,
}: CreateLayerOptions): GeoBundleLayer[] {
  const fillLayer: GeoBundleLayer = {
    type: 'fill',
    ...(maxzoom ? { maxzoom } : {}),
    paint: {
      'fill-opacity': opacity,
      'fill-color': color,
    },
  };
  const outlineLayer: GeoBundleLayer = {
    type: 'line',
    minzoom: outlined ? 0 : 14,
    ...(maxzoom ? { maxzoom } : {}),
    paint: {
      'line-color': color,
      'line-opacity': outlined ? 1 : ['case', ['get', '_dark'], 0.5, 0.75],
      'line-width': outlined ? 1.5 : 1,
    },
  };
  const selectionLayer: GeoBundleLayer = {
    type: 'line',
    filter: ['==', ['get', '_selected'], true],
    paint: {
      'line-color': color,
      'line-opacity': 1,
      'line-width': 3,
    },
  };
  const textLayer: GeoBundleLayer = {
    type: 'symbol',
    filter: ['has', 'text'],
    layout: {
      'symbol-placement': 'line',
      'text-ignore-placement': true,
      'text-field': ['get', 'text'],
      'text-size': 12,
      'text-offset': [0, 2],
    },
    paint: {
      'text-color': color,
      'text-halo-color': color,
      'text-halo-width': 0.25,
      'text-halo-blur': 0,
    },
  };
  return [fillLayer, outlineLayer, selectionLayer, textLayer];
}
