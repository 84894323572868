<template lang="pug">
.ZToggleableIconBtn(@click="isActive = !isActive")
  VBtn.ZToggleableIconBtn__btn(
    icon
    flat
    :color="isActive ? activeColor : ''"
    :class="classes"
    :disabled="disabled"
    :loading="loading"
    :small="xs"
  )
    slot
</template>

<style lang="stylus">
.ZToggleableIconBtn__btn--active
  background-color alpha($color-primary, 0.4)

  &.ZToggleableIconBtn__btn--disabled
    background-color transparent
</style>

<script setup lang="ts">
import { useVModelProxy, useVuetifyBreakpoints } from '@/composables';

export interface ZToggleableIconBtnProps {
  /**
   * Active state of the toggleable button
   * @model
   */
  value?: boolean;
  /**
   * Color of the button when active
   */
  activeColor?: string;
  /**
   * Whether the button is disabled
   */
  disabled?: boolean;
  /**
   * Whether the button is in a loading state
   */
  loading?: boolean;
}

const props = withDefaults(defineProps<ZToggleableIconBtnProps>(), {
  value: false,
  activeColor: 'primary',
  disabled: false,
  loading: false,
});

const isActive = useVModelProxy({ props });
const { xs } = useVuetifyBreakpoints();

const classes = computed(() => ({
  'ZToggleableIconBtn__btn--active': isActive.value,
  'ZToggleableIconBtn__btn--disabled': props.disabled,
}));
</script>
