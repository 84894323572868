import { BikeLwm2mInfoAccessTechnology } from '@/models/bike/enums/bike';

import type { ApiSchema } from '#/core-api';

/**
 * Compute signal icon based on RSRP (4G) or RxLev (2G)
 * @param signalPower - Value of the received power of the signal (negative).
 * In RxLev if 2G, in RSRP if 4G
 * @param accessTechnology - Technology used by the bike to communicate. (Can be 2G or 4G)
 */
function rsrpSignalIcon(
  signalPower: number,
  accessTechnology?: string
): string {
  if (
    accessTechnology === BikeLwm2mInfoAccessTechnology['4G'] ||
    accessTechnology === BikeLwm2mInfoAccessTechnology['4GIoT']
  ) {
    const rsrp = signalPower;
    switch (true) {
      case rsrp >= -80:
        return 'svg:signal_4_4g';
      case -80 > rsrp && rsrp > -90:
        return 'svg:signal_3_4g';
      case -90 >= rsrp && rsrp > -100:
        return 'svg:signal_2_4g';
      case -100 >= rsrp:
      default:
        return 'svg:signal_1_4g';
    }
  } else if (accessTechnology === BikeLwm2mInfoAccessTechnology['2G']) {
    const rxlev = signalPower;
    switch (true) {
      case rxlev >= -72:
        return 'svg:signal_4_2g';
      case -72 > rxlev && rxlev > -82:
        return 'svg:signal_3_2g';
      case -82 >= rxlev && rxlev > -92:
        return 'svg:signal_2_2g';
      case -92 >= rxlev:
      default:
        return 'svg:signal_1_2g';
    }
  }
  return 'mdi-signal-off';
}

/**
 * Compute the signal badge for the given product's LWM2M info
 * @param lwm2mInfo - The product LWM2M info
 * @returns The badge properties
 */
export function lteSignalBadge(
  lwm2mInfo: ApiSchema['product.Lwm2MInfo'] = {}
): Badge {
  const connected = lwm2mInfo.connected;

  const rsrp = (lwm2mInfo?.lte_info?.rsrp ?? Infinity) * -1;
  const rxlev = (lwm2mInfo?.lte_info?.rxlev ?? Infinity) * -1;

  let signalPower;

  switch (lwm2mInfo?.lte_info?.access_technology) {
    case BikeLwm2mInfoAccessTechnology['2G']:
      signalPower = rxlev;
      break;
    case BikeLwm2mInfoAccessTechnology['4G']:
    case BikeLwm2mInfoAccessTechnology['4GIoT']:
    default:
      signalPower = rsrp;
      break;
  }

  const icon = connected
    ? rsrpSignalIcon(signalPower, lwm2mInfo?.lte_info?.access_technology)
    : 'mdi-signal-off';

  return {
    icon,
    color: 'transparent', // badge bg color
    iconColor: icon === 'mdi-signal-off' ? 'grey' : 'success',
    relative: true,
    dense: true,
  };
}
